import React  from "react";
import {useBuildingDispatch, useBuildingState} from "./store";
import {useOutBuildingDispatch, useOutBuildingState} from "./outer-buildings";

export default function BuildingForOuter(){

   const outBuildingState = useOutBuildingState()
   const outBuildingDispatch = useOutBuildingDispatch()

   const{
       roof,
       accommodationRoomQty,
       ceiling,
       commercialRoomQty,
       walls,
       condition,
       floors,
       doors,
       windows,
       photoUrl,
       other
   }= outBuildingState

   const state = useBuildingState()
   const dispatch = useBuildingDispatch()
   const handleChange=(e)=>{
       const { name, value } = e.target;
       outBuildingDispatch({ type: "fields",field:name, payload:value });
    }
    const handlesubmit=()=>{
        console.log("the outbuilding state",outBuildingState)

    }
        return(
            <div className={""}>
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <div className={"form-group"}>
                                <label htmlFor="#roof" className={"control-label" }>Roof</label>
                                <input
                                   style={{width:'100%'}}
                                    id="#roof"
                                   defaultValue={roof}
                                    placeholder="Roof"
                                    name ="roof"
                                   className={"form-control"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className={"col-sm-6"}>
                            <div className={"form-group"}>
                                <label htmlFor="#accommodationRoomQty" className={"control-label" }>Accommodation Room Qty</label>
                                <input
                                   style={{width:'100%'}}
                                    id="#accommodationRoomQty"
                                   defaultValue={accommodationRoomQty}
                                    placeholder="Accommodation Room Qty"
                                    name ="accommodationRoomQty"
                                   className={"form-control"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"row"} >
                        <div className={"col-sm-6"}>
                            <div className={"form-group"}>
                                <label htmlFor="#ceiling" className={"control-label" }>Ceiling</label>
                                <input
                                   style={{width:'100%'}}
                                    id="#ceiling"
                                   defaultValue={ceiling}
                                    placeholder="Ceiling"
                                    name ="ceiling"
                                   className={"form-control"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className={"col-sm-6"}>
                            <div className={"form-group"}>
                                <label htmlFor="#commercialRoomQty" className={"control-label" }>Commercial Room Qty</label>
                                <input
                                   style={{width:'100%'}}
                                    id="#commercialRoomQty"
                                   defaultValue={commercialRoomQty}
                                    placeholder="Commercial Room Qty"
                                    name ="commercialRoomQty"
                                   className={"form-control"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <div className={"form-group"}>
                                <label htmlFor="#walls" className={"control-label" }>Walls</label>
                                <input
                                   style={{width:'100%'}}
                                    id="#walls"
                                   defaultValue={walls}
                                    placeholder="Walls"
                                    name ="walls"
                                   className={"form-control"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className={"col-sm-6"}>
                            <div className={"form-group"}>
                                <label htmlFor="#condition" className={"control-label" }>Condition</label>
                                <input
                                   style={{width:'100%'}}
                                    id="#condition"
                                   defaultValue={condition}
                                    placeholder="Condition"
                                    name ="condition"
                                   className={"form-control"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                            <div className={"col-sm-6"}>
                                        <div className={"form-group"}>
                                            <label htmlFor="#floors" className={"control-label" }>Floors</label>
                                            <input
                                                style={{width:'100%'}}
                                                id="#floors"
                                                defaultValue={floors}
                                                placeholder="Floors"
                                                name ="floors"
                                                className={"form-control"}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={"form-group"} >
                                            <label htmlFor="#doors" className={"control-label" }>Doors</label>
                                            <input
                                                style={{width:'100%'}}
                                                id="#doors"
                                                defaultValue={doors}
                                                placeholder="Doors"
                                                name ="doors"
                                                className={"form-control"}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className={"form-group"} >
                                            <label htmlFor="#doors" className={"control-label" }>Windows</label>
                                            <input
                                                style={{width:'100%'}}
                                                id="#windows"
                                                defaultValue={windows}
                                                placeholder="Windows"
                                                className={"form-control"}
                                                name ="windows"
                                                onChange={handleChange}
                                            />
                                        </div>

                            </div>
                            <div className={"col-sm-6"}>

                                <div className={"form-group"} >
                                    <label htmlFor="#other" className={"control-label" }>Other</label>
                                    <textarea
                                        rows={8}
                                        style={{width:'100%',resize:'none'}}
                                        id="#other"
                                        defaultValue={other}
                                        placeholder="Other"
                                        name ="other"
                                        className={"form-control"}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                    </div>
                <button onClick={handlesubmit}>Add Building</button>
                </div>
        )
}