import * as React from "react";
import Wait from "../../../wait";
import TransactionPopup from "../../transaction_popup";
import TPCreateService from "../../../../../services/tp/tp_create";
import PopDetail from "../../../popdetail";
import TpInfoPane from "../tp_info";
import TxReadService from "../../../../../services/transaction/transaction_read";
import GeneralSelect from "../../../../../utils/GeneralSelect";
import TPReadService from "../../../../../services/tp/tp_read";

const partiesOptions = {
    'endpoint': 'tp/read/list',
    'value': 'tpDrawingId',
    'label': 'tpDrawingNumber',
    'placeholder': 'Select TP Drawing Number'
}
export default class AddTpInfoPane extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zip: {},
            selectedOption: '',
            clearable: true,
            regions: [],
            districts: [],
            councils: [],
            localities: [],
            wards: [],
            subwards: [],
            hamlets: [],

            tpDrawingId: 0,
            typeOfPlan: '',
            acceptedBy: '',
            adjoiningTpDrawingNumber: '',
            approvedNumber: '',
            approvedBy: '',
            approvedDateDateStr: '',
            basemap: '',
            qualityCheckedBy: '',
            qualityCheckedDate: '',
            scale: '',
            title: '',
            tpDrawingNumber: '',
            districtId: 0,
            localityId: 0,
            regionId: 0,
            wardId: 0,
            amendedTransactionId: '',
            dataSource: '',
            lotType: '',
            councilId: 0,
            mtaaId: 0,
            designedBy: '',
            supervisor: '',
            checkedByRas: '',
            preparedBy: '',

            parcel: null,
            building: null,
            point: null,
            contours: null,
            road: null,
            power: null,
            water: null,
            railways: null,
            pond: null,
            show_select_tp: 'none',
            show_locality: 'none',
            show_info: 'none',
            show_map: 'none',
            in_queue: false,
        }
        this.saveTP = this.saveTP.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.regionChange = this.regionChange.bind(this)
        this.districtChange = this.districtChange.bind(this)
        this.councilChange = this.councilChange.bind(this)
        this.wardChange = this.wardChange.bind(this)
        this.generalSelect = this.generalSelect.bind(this)
    }
    componentDidMount() {
        console.log('getRegions...........')
        setTimeout(() => {
            TxReadService.getRegions().then(d => {
                try {
                    this.setState({ regions: d.data.result })
                } catch (e) {
                    console.log(e)
                }
            })
        }, 500)
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'typeOfPlan') {
            this.setState({
                show_select_tp: e.target.value === 'Amendment Plan' ? 'inherit' : 'none',
                show_locality: e.target.value !== 'Amendment Plan' ? 'inherit' : 'none',
                show_info: e.target.value !== 'Amendment Plan' ? 'inherit' : 'none'
            })
        }
    }
    handleChangeFile = e => {
        this.setState({ [e.target.name]: e.target.files[0] })
    }
    regionChange = e => {
        try {
            console.log("district loaded")
            this.setState({ regionId: e.target.value })
            TxReadService.getDistrictsByRegId(e.target.value).then(d => {
                try {
                    this.setState({ districts: d.data.result })
                    this.setState({ councils: [] })
                    this.setState({ localities: [] })
                    this.setState({ wards: [] })
                    this.setState({ hamlets: [] })
                } catch (e) {
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
    districtChange = e => {
        try {
            this.setState({ districtId: e.target.value })
            TxReadService.getCouncilsByDistrictId(e.target.value).then(d => {
                try {
                    if (d.data.result.length >= 1) {
                        this.setState({ councils: d.data.result })
                        this.setState({ localities: [] })
                        this.setState({ wards: [] })
                        this.setState({ hamlets: [] })
                    }
                } catch (e) {

                }
            })
        } catch (e) {
            console.log(e)
        }
    }
    councilChange = e => {
        try {
            this.setState({ councilId: e.target.value })
            TxReadService.getLocalitiesByCouncilId(e.target.value).then(d => {
                try {
                    if (d.data.result.length >= 1) {
                        this.setState({ localities: d.data.result })
                    }
                } catch (e) {

                }
            })
        } catch (e) {
            console.log(e)

        }
        try {
            this.setState({ wardId: e.target.value })
            TxReadService.getWardsByCouncilId(e.target.value).then(d => {
                try {
                    if (d.data.result.length >= 1) {
                        this.setState({ wards: d.data.result })
                        this.setState({ hamlets: [] })
                    }
                } catch (e) {
                }
            })
        } catch (e) {
            console.log(e)
        }

    }
    wardChange = e => {
        try {
            this.setState({ wardId: e.target.value })
            TxReadService.getSubwardsByWardId(e.target.value).then(d => {
                try {
                    if (d.data.result.length >= 1) {
                        this.setState({ subwards: d.data.result })
                    }
                } catch (e) {

                }
            })
        } catch (e) {
            console.log(e)
        }
    }
    hamletChange = e => {
        this.setState({ streetId: e.target.value })
        console.log(this)
    }
    localityChange = e => {
        this.setState({ subWardId: e.target.value })
    }
    generalSelect(arr) {
        try {
            //Wait.show('Loading...')
            TPReadService.tpInfo(arr.value).then(r => {
                //Wait.dismiss()


                this.setState({
                    show_map: 'inherit',
                    title: r.data.title,
                    tpDrawingId: arr.value
                    //show_info:'visible'
                })


                /*accepted_by: null
            approved_by: null
            approved_date: null
            basemap: "topo sheet 20227"
            checked_by: null
            council: 19
            district: 12
            mtaa: 544
            prepared_by: null
            region: 3
            scale: "2500"
            supervised_by: null
             : "KIKOMBO LAYOUT PLAN"
            ward: 296*/
            })


        } catch (e) {
            console.log(e)
        }
    }
    render() {
        return (<div>

            <div className={'col col-sm-12'}>
                <span>Type of Detail Plan</span>
                <select style={{ width: '100%', marginBottom: '24px' }} name={'typeOfPlan'} onChange={this.handleChange}>
                    <option value={''}>- Choose One -</option>
                    <option>New Plan</option>
                    <option>Regularization Schemes</option>
                    <option>Redevelopment Schemes</option>
                    <option>Amendment Plan</option>
                </select>

                <div style={{ display: this.state.show_select_tp }}>
                    <GeneralSelect onChanges={this.generalSelect} parameters={partiesOptions} isMulti={false} />
                </div>
            </div>

            <div className={'col col-sm-12'} style={{ display: this.state.show_locality }}>
                <span>TP Location</span>
                <table style={{ width: '100%', marginBottom: '24px' }}>
                    <tbody>
                        <tr>
                            <td>* Region</td>
                            <td>* District</td>
                            <td>* Council</td>
                        </tr>
                        <tr>
                            <td>
                                <select style={{ width: '100%' }} name={'regionId'} onChange={this.regionChange}>
                                    <option value={0}>- Choose Region -</option>
                                    {this.state.regions.map((region) => <option key={region.regionId} value={region.regionId}>{region.regionName}</option>)}
                                </select>
                            </td>
                            <td>
                                <select style={{ width: '100%' }} name={'districtId'} onChange={this.districtChange}>
                                    <option value={0}>- Choose District -</option>
                                    {this.state.districts.map((district) => <option key={district.id} value={district.id}>{district.names}</option>)}
                                </select>
                            </td>
                            <td>
                                <select style={{ width: '100%' }} name={'councilId'} onChange={this.councilChange}>
                                    <option value={0}>- Choose Council -</option>
                                    {this.state.councils.map((council) => <option key={council.councilId} value={council.councilId}>{council.councilName}</option>)}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>* Ward</td>
                            {/*<td>* Street</td>*/}
                            <td>* Location / Mtaa </td>
                        </tr>
                        <tr>
                            <td>
                                <select style={{ width: '100%' }} name={'wardId'} onChange={this.wardChange}>
                                    <option value={0}>- Choose Ward -</option>
                                    {this.state.wards.map((ward) => <option key={ward.wardId} value={ward.wardId}>{ward.wardName}</option>)}
                                </select>
                            </td>
                            {/*<td>
                        <select style={{width:'100%'}} onChange={this.hamletChange}>
                            <option value={0}>- Choose Street -</option>
                            {this.state.hamlets.map((hamlet) => <option key={hamlet.hamletId} value={hamlet.hamletId}>{hamlet.hamletName}</option>)}
                        </select>
                    </td>*/}
                            <td>
                                <select style={{ width: '100%' }} name={'subWardId'} onChange={this.localityChange}>
                                    <option value={0}>- Choose Location -</option>
                                    {this.state.subwards.map((d) => <option key={d.subWardId} value={d.subWardId}>{d.name}</option>)}
                                </select>
                            </td>

                        </tr>

                    </tbody>
                </table>
            </div>
            <div className={'col col-sm-12'} style={{ display: this.state.show_info }}>

                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>TP Title</td>
                            <td>Basemap</td>
                            <td>Scale</td>
                        </tr>
                        <tr>
                            <td><input style={{ width: '100%' }} name={'title'} placeholder={'Eg : TUKUYU TOWN TP'} onChange={this.handleChange} /></td>
                            <td><input style={{ width: '100%' }} name={'basemap'} onChange={this.handleChange} /></td>
                            <td>
                                <select style={{ width: '100%' }} name={'scale'} onChange={this.handleChange}>
                                    <option value={''}>- Choose Scale -</option>
                                    <option>1:1000</option>
                                    <option>1:2500</option>
                                    <option>1:5000</option>
                                    <option>1:10000</option>
                                    <option>1:25000</option>
                                    <option>1:50000</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Prepared By</td>
                            <td>Checked By</td>
                            <td>Supervised By</td>
                        </tr>
                        <tr>
                            <td>
                                <input style={{ width: '100%' }} name={'preparedBy'} onChange={this.handleChange} />
                                {/*<option>- Choose One -</option>
                                <option>Individual</option>
                                <option>Firm</option>
                                <option>Institution</option>
                            </input>*/}
                            </td>
                            <td>
                                <input style={{ width: '100%' }} name={'checkedBy'} onChange={this.handleChange} />
                            </td>
                            <td><input style={{ width: '100%' }} name={'supervisedBy'} onChange={this.handleChange} /></td>
                        </tr>
                        <tr>
                            <td>Approved By</td>
                            <td>Approval Date</td>
                            <td>Accepted By</td>
                        </tr>
                        <tr>
                            <td><input style={{ width: '100%' }} name={'approvedBy'} onChange={this.handleChange} /></td>
                            <td><input style={{ width: '100%' }} name={'approvedDate'} type={'date'} onChange={this.handleChange} /></td>
                            <td><input style={{ width: '100%' }} name={'acceptedBy'} onChange={this.handleChange} /></td>
                        </tr>
                        <tr>
                            <td>Accepted Date</td>
                        </tr>
                        <tr>
                            <td><input style={{ width: '100%' }} name={'acceptedDate'} type={'date'} onChange={this.handleChange} /></td>
                        </tr>


                    </tbody>
                </table>
            </div>

            <div className={'col col-sm-12'} style={{ display: this.state.show_map }}>
                <br />
                <div id={'pop_map'} style={{ width: '100%', height: '40vh', background: '#ccc' }}>

                </div>
            </div>

            <hr />
            <div style={{ textAlign: 'right', width: '100%' }}>
                <button style={{ width: '100px' }} onClick={this.saveTP}>Save</button>
                <button style={{ width: '100px' }} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }


    saveTP() {
        try {
            Wait.show("Saving...")
            TPCreateService.createTP({
                transactionId: TransactionPopup.id(),
                tpDrawingId: this.state.tpDrawingId,
                typeOfPlan: this.state.typeOfPlan,
                acceptedBy: this.state.acceptedBy,
                adjoiningTpDrawingNumber: this.state.adjoiningTpDrawingNumber,
                approvedNumber: this.state.approvedNumber,
                approvedBy: this.state.approvedBy,
                approvedDateDateStr: this.state.approvedDateDateStr,
                basemap: this.state.basemap,
                blockNumber: this.state.blockNumber,
                qualityCheckedBy: this.state.qualityCheckedBy,
                qualityCheckedDate: this.state.qualityCheckedDate,
                scale: this.state.scale,
                title: this.state.title,
                tpDrawingNumber: this.state.tpDrawingNumber,
                districtId: this.state.districtId,
                localityId: this.state.localityId,
                regionId: this.state.regionId,
                wardId: this.state.wardId,
                subWardId: this.state.subWardId,
                amendedTransactionId: this.state.amendedTransactionId,
                dataSource: this.state.dataSource,
                lotType: this.state.lotType,
                councilId: this.state.councilId,
                mtaaId: this.state.mtaaId,
                geom: this.state.zip,
                designedBy: this.state.designedBy,
                supervisor: this.state.supervisor,
                checkedByRas: this.state.checkedByRas,
                preparedBy: this.state.preparedBy
            }).then(d => {
                Wait.dismiss()
                if (d.data.status === 1) {
                    PopDetail.dismiss()
                    TpInfoPane.loadData()
                } else {
                    alert(d.data.message)
                }
            })

        } catch (e) {
            Wait.dismiss()
            console.log(e)
        }
    }

}
