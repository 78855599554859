import API from "../API";

export default class TPReadService {
    static tpListInfo(txId) {
        return API.ax.get(`tp/read/${txId}/info`).catch(e => { console.log(e); })
    }
    static tpList(txId) {
        return API.ax.get(`tp/read/${txId}`).catch(e => { console.log(e); })
    }
    static tpInfo(tpId) {
        return API.ax.get(`tp/read/info/${tpId}`).catch(e => { console.log(e); })
    }
    static readReportSummary(project, stage) {
        return API.ax.get(`tp/read/report/${project}/${stage}/summary`)
    }
    static readReportData(project, stage) {
        return API.ax.get(`tp/read/report/${project}/${stage}/data`)
    }
    static readRemarks(tpId, type) {
        return API.ax.get(`tp/read/remarks/${tpId}/${type}`)
    }
    static getTpByTx(tx) {
        return API.ax.get(`tp/read/${tx}`)
    }
    static readChecklists(code, sid) {
        return API.ax.get(`tp/read/checklist/${sid}/${code}`)
    }
    static getSpatial(txId,type) {
        return API.ax.get(`tp/read/txgeometry/${txId}/${type}`)
    }
    static getTpByRegion(regionID){
        return API.ax.get(`tp/read/region/${regionID}`)
    }
}