import * as React from "react";

import * as LF from "leaflet";
import MobilePopup from "../../mobile_popup";
import UgSpatial from "../../../../maps/ug_spatial";
import MobileReadService from "../../../../../services/mobile/mobile_read";
import Basemap from "../../../../maps/basemap";

export default class PaneParcelMap extends React.Component {
  static self = null;
  static map = null;

  constructor(props) {
    super(props);
    console.log(props.parcel);
    this.state = {
      tp_drawings: [],
      layers: [],
      page_status: "Loading...",
      coordinates: [],
      majirani: [],
    };

    PaneParcelMap.self = this;

    this.loadMap = this.loadMap.bind(this);
    this.loadCoordinates = this.loadCoordinates.bind(this);
  }

  componentDidMount() {
    this.loadMap();
    this.loadCoordinates();
  }

  loadCoordinates(map) {
    try {
      MobileReadService.readParcelMajirani(
        this.props.parcel.id,
        MobilePopup.id()
      ).then((r) => {
        this.setState({ majirani: r.data.results });
      });
      MobileReadService.readCoordinates(this.props.parcel.id).then((r) => {
        try {
          console.log(r.data.results);
          this.setState({
            coordinates: r.data.results,
          });

          r.data.results.forEach((it) => {
            LF.marker([it.x, it.y], {
              icon: LF.divIcon({
                className: "bindTooltipBigRed",
                html: it.point + " " + "(" + it.x + "," + it.y + ")",
                iconSize: [100, 40],
              }),
            }).addTo(map);
          });
        } catch (e) {
          console.log(e);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  loadMap() {
    UgSpatial.clear("map2");

    let ly_group = new LF.FeatureGroup();
    let sat = Basemap.googleSat(),
      hybrid = Basemap.googleHybrid(),
      blank = LF.tileLayer("");

    let map = LF.map("map2", {
      layers: [blank, ly_group],
      attributionControl: false,
      fullscreenControl: true,
      maxZoom: 10000,
    }).setView([-6.085936, 35.711995], 4);

    let ly_vb = UgSpatial.getLayerByName(
      "mob_parcel",
      "project_id in (" + MobilePopup.id() + ")"
    ).addTo(map);
    let ly_mob = UgSpatial.getLayerByName(
      "mob_layers",
      "project_id in (" + MobilePopup.id() + ") and deleted =false"
    ).addTo(map);

    let baseLayers = {
      "<span style='color: gray'>Blank</span>": blank,
      Satellite: sat,
      Hybrid: hybrid,
    };

    try {
      MobileReadService.searchByClaimNo(
        MobilePopup.id(),
        this.props.parcel.claim_no
      ).then((r) => {
        try {
          r.data.results.forEach((rs) => {
            if (rs.claim_no === this.props.parcel.claim_no) {
              console.log(rs.poly);
              let polygon = LF.polygon(rs.poly).addTo(map);
              // polygon.options({html:"hh"})
              console.log(polygon);
              polygon.setStyle({ color: "#000", weight: 8, fillOpacity: 0 });
              // LF.marker(polygon.getCenter(),{
              //     icon:LF.divIcon({
              //         className:'bindTooltipBigRed',
              //         html:'<h5>Jina la mmiliki</h5><p>1233/344/22</p>',
              //         iconSize:[100,40]
              //     })
              // }).addTo(map)
              polygon.bringToFront();
              console.log(rs.poly);
              map.fitBounds(polygon.getBounds());

              let overlays = {
                "Project Layers": ly_mob,
                Label: ly_vb,
                Polygon: polygon,
              };

              LF.control
                .layers(baseLayers, overlays, { position: "bottomleft" })
                .addTo(map);

              this.loadCoordinates(map);
            }
          });
        } catch (e) {
          console.log(e);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div style={{ height: "90%", width: "100%" }} className={"row"}>
        <div
          className={"col col-sm-4"}
          style={{ height: "100%", padding: "24px", overflowY: "auto" }}
        >
          <div style={{ height: "65%", overflowY: "auto" }} className={""}>
            <b>Coordinates</b>

            <table className={"tb"} style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Point</th>
                  <th>X</th>
                  <th>Y</th>
                </tr>
              </thead>
              <tbody>
                {this.state.coordinates.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td>{d.point}</td>
                      <td>{d.x}</td>
                      <td>{d.y}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div style={{ padding: 12, height: "35%", overlow: "scroll" }}>
            <table className={"table table-bordered table-striped"}>
              <tr>
                <th>Owner</th>
                <th>Claim Number</th>
              </tr>
              {this.state.majirani.map((prop, key) => (
                <tr key={key}>
                  <td>{prop.owner_name}</td>
                  <td>
                    {prop.claim_no}{" "}
                    <button
                      onClick={async () => {
                        try {
                            const textarea = document.createElement("textarea");
                            textarea.value = prop.owner_name;
                            document.body.appendChild(textarea);
                            textarea.select();
                            document.execCommand("copy");
                            document.body.removeChild(textarea);
                        //   await navigator.clipboard.writeText(prop.owner_name);
                        //   console.log("Page URL copied to clipboard");
                        } catch (err) {
                          // console.error('Failed to copy: ', err);
                        }
                      }}
                      className="btn btn-info"
                    >
                      Copy
                    </button>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div
          className={"col col-sm-8"}
          style={{ height: "100%" }}
          id={"map2"}
        />
      </div>
    );
  }
}
