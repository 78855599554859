import * as React from "react";
import Wait from "../../../wait";
import MobileCreateService from "../../../../../services/mobile/mobile_create";
import PrintDocumentPane from "../../../transacrions/screen/print_doc";
import MobilePopup from "../../mobile_popup";
import ParcelRemark from "../../../../pane/common/remarks";
import PanePayment from "./pane_payment";
import MobileReadService from "../../../../../services/mobile/mobile_read";

export default class PaneParcelDocument extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            doc_frame: <div>Loading...</div>,
            payments: [],
            documents: []
        }

        this.loadDocument = this.loadDocument.bind(this)
        this.markAsPrinted = this.markAsPrinted.bind(this)
        this.showDocumentAt = this.showDocumentAt.bind(this)
    }

    componentDidMount() {
        this.loadDocument()
    }

    markAsPrinted(action) {
        try {
            Wait.show('Updating...')
            MobileCreateService.addRemark({
                parcelId: this.props.parcel.id,
                stage: action,
                message: ['Marked As Not Printed', 'Marked As Printed'][action - 4]
            }).then(r => {
                try {
                    //document.getElementById('tr_'+d.id).style.display='none'
                    Wait.dismiss()
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    showDocumentAt(data) {
        this.setState({
            doc_frame: <div>
                <div style={{ height: '60vh' }}>
                    <PrintDocumentPane base64={data.document} />
                </div>
                <div style={{ textAlign: 'right', paddingTop: '8px' }}>
                    <button onClick={() => this.markAsPrinted(5)} className={'submit'}>Mark As Printed</button>
                    <button onClick={() => this.markAsPrinted(4)} className={'refresh'}>Mark As Not Printed</button>
                </div>
            </div>
        })

        document.getElementById('rd' + data.name).checked = true
    }
    render() {
        return (<div className={'row'} style={{ height: '90%' }}>
            <div className={'col col-sm-3'} style={{ height: '100%', background: '#ccc' }}>
                <div style={{ padding: '8px' }}>
                    <br />
                    <h3>Documents</h3>
                    <hr />
                    {this.state.documents.map((d, i) => {
                        return (<div key={i} style={{ cursor: 'pointer', padding: '8px' }} onClick={() => this.showDocumentAt(d)}>
                            <input type={'radio'} style={{ marginRight: '8px' }} name={'document'} id={'rd' + d.name} /><b>{d.name}</b>
                        </div>)
                    })}
                </div>
            </div>
            <div className={'col col-sm-9'}>
                {this.state.doc_frame}
            </div>
        </div>)
    }

    loadDocument() {
        try {
            Wait.show('Reading Document....')
            MobileReadService.readParcelInfo(this.props.parcel.id).then(r => {
                Wait.dismiss()
                try {
                    switch (r.data.stage) {
                        case 1:
                            this.setState({ doc_frame: <ParcelRemark parcel={this.props.parcel} is_open={false} /> })
                            break
                        case 2:
                            this.setState({ doc_frame: <div>Not Registered, Please Remark it as "Approved" to Generate Documents</div> })
                            break;
                        case 4:
                            Wait.show('Loading Output...')
                            MobileCreateService.createOutput(MobilePopup.id(), this.props.parcel.id, 'parcel').then(r => {
                                try {
                                    Wait.dismiss()
                                    this.setState({ documents: r.data.results })
                                    console.log(r.data.results)

                                    this.showDocumentAt(r.data.results[0])
                                } catch (e) {
                                    console.log(e)
                                }
                            })
                            break
                        case 6:
                            Wait.show('Loading Output...')
                            MobileCreateService.createOutput(MobilePopup.id(), this.props.parcel.id, 'parcel', 'draft').then(r => {
                                try {
                                    Wait.dismiss()
                                    this.setState({ documents: r.data.results })
                                    console.log(r.data.results)

                                    this.showDocumentAt(r.data.results[0])
                                } catch (e) {
                                    console.log(e)
                                }
                            })
                            break
                        case 7:
                            this.setState({ doc_frame: <div>Not Registered, Please Remark it as "Approved" to Generate Documents</div> })
                            break;
                        default:
                            this.setState({ doc_frame: <PanePayment parcel={this.props.parcel} handler={this.loadDocument} /> })
                            break
                    }
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}