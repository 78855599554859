import * as React from "react";
import Wait from "../../../wait";
import MobileReadService from "../../../../../services/mobile/mobile_read";
import PopDetailsTwo from "../../../PopDetailsTwo";
import MobileUpdateService from "../../../../../services/mobile/mobile_update";

export default class PanePayment extends  React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            payments: []
        }


        PanePayment.self = this
        this.loadPayments = this.loadPayments.bind(this)
        this.markAsPaid = this.markAsPaid.bind(this)
        this.payHandler = this.payHandler.bind(this)
    }

    componentDidMount() {
        this.loadPayments()
    }


    loadPayments(){
        try {
            Wait.show('Loading Payments...')
            MobileReadService.readParcelPayments(this.props.parcel.id).then(r => {
                try {
                    Wait.dismiss()
                    console.log(r.data.results)
                    this.setState({
                        payments:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    markAsPaid(){
        PopDetailsTwo.confirm('Payment Confirmation','Is GePG Portal was paid successfully ?',this.payHandler,this.props.parcel.id,this.noHandler)
    }

    payHandler(id){
        try {
            Wait.show('Updating Payments...')
            MobileUpdateService.updatePayments({
                id: id
            }).then(r => {
                try {
                    Wait.dismiss()
                    PopDetailsTwo.dismiss()
                    document.getElementById('div_paid').style.visibility='invisible'
                    if (this.props.handler !==null){
                        this.props.handler()
                    }else{
                        this.loadPayments()
                    }
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    noHandler(){
        PopDetailsTwo.dismiss()
    }
    render() {
        return(<div className={'col col-sm-12'} >
            <br/>
            <b style={{color:'#f00'}}>No Payment</b>
            <br/>
            <br/>
            <table className={'tb'} style={{width:'100%'}}>
                <thead>
                <tr>
                    <th>Payment Category</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Description</th>
                    <th>Control Number</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.payments.map((d,i)=>{
                        return(<tr key={i}>
                            <td>{d.category}</td>
                            <td><b>{d.amount}</b></td>
                            <td>{d.status}</td>
                            <td>{d.description}</td>
                            <td><button className={'default'}>{d.cno}</button></td>
                        </tr>)
                    })
                }

                </tbody>
            </table>

            <hr/>
            <div style={{textAlign:'center',visibility:this.props.handler !==null?'visible':'hidden'}} id={'div_paid'}>
                <button onClick={this.loadPayments}>Refresh</button>
                <button className={'refresh'} onClick={this.markAsPaid}>Mark As Paid</button>
                <br/><br/>
                <p>Note :  "Mark as Paid" button is temporary, until Gepg Integration</p>
            </div>
        </div>)
    }

}