import * as React from "react";
import PopDetail from "../../../popdetail";

import * as LF from "leaflet";
import "leaflet.utm";

import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'


import uncheck from "../../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import Wait from "../../../wait";
import check from "../../../../../../resource/img/checkbox/checkbox_checked_16.png";
import UgSpatial from "../../../../maps/ug_spatial";
import TransactionPopup from "../../transaction_popup";
import TPReadService from "../../../../../services/tp/tp_read";
import UploadSpatialPane from "../add/upload_spatial";
import API from "../../../../../services/API";
import Basemap from "../../../../maps/basemap";
import PreviewPrintDocument from "../../pops/preview_print_document";
import QueuePane from "../queue_pane";
import UploadSpatialAmendPane from "../add/upload_spatial_amend";
import TPCreateService from "../../../../../services/tp/tp_create";
export default class TpAmendSpatialUnitPane extends React.Component{
    static self = null

    constructor(props) {
        super(props);
        this.state = {
            tp_drawings:[],
            screenName:'',
            can_upload:'inherit',
            in_queue:false,
            remainingTime:'',
            current_tid:0,
            current_use:''
        }
        TpAmendSpatialUnitPane.self = this
        this.toggleCheck = this.toggleCheck.bind(this)
        this.downloadSpatial = this.downloadSpatial.bind(this)
        this.freeze = this.freeze.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.confirmFreeze = this.confirmFreeze.bind(this)
    }

    handleChange=(event)=> {
        this.setState({ [event.target.name]: event.target.value });
    }

    confirmFreeze(id){
        Wait.show('Freezing....')
        try{
            TPCreateService.freeze({
                parcelId:id,
                transactionId:TransactionPopup.id()
            }).then(r=>{
                Wait.dismiss()
                PopDetail.dismiss()
                TpAmendSpatialUnitPane.loadData()
            })
        }catch (e) {
            console.log(e)
        }
    }

    freeze(){
        PopDetail.confirm("Freeze "+this.state.current_tid,"Confirm Freeze Parcel "+this.state.current_tid+" - "+this.state.current_use,
            this.confirmFreeze,this.state.current_tid,PopDetail.dismiss)
    }
    static ids(){ return TpAmendSpatialUnitPane.self.state.ids }

    static loadData(screenName=''){
        Wait.show("Loading TP..."+screenName)

        TPReadService.tpList(TransactionPopup.id()).then(r => {
            Wait.dismiss()
            try {
                if (r.data.in_queue===true){
                    TpAmendSpatialUnitPane.self.setState({
                        in_queue:true
                    })
                    return
                }

                UgSpatial.clear()

                let ids = ''
                r.data.result.forEach(dt=>{ids+=','+dt.id})

                console.log(screenName+"............"+r.data.centroid)

                TpAmendSpatialUnitPane.self.setState({
                    tp_drawings:r.data.result,
                    ids:ids.toString().substring(1),
                    screenName:screenName
                })

                UgSpatial.clear()

                let ly_group = new LF.FeatureGroup();
                let ly_history = new LF.FeatureGroup();
                let ly_tp = new LF.FeatureGroup();
                let sat = Basemap.googleSat(),
                    hybrid = Basemap.googleHybrid(),
                    blank = LF.tileLayer('')

                let map = LF.map('map', {
                    layers: [blank, ly_group,ly_tp],
                    attributionControl: false,
                    fullscreenControl: true,
                    maxZoom:10000
                }).setView([-6.085936, 35.711995], 4)

                let baseLayers = {
                    "<span style='color: gray'>Blank</span>": blank,
                    "Satellite": sat,
                    "Hybrid": hybrid
                }

                let filter =  "DWITHIN(geom,"+r.data.centroid+",1600,meters)"//'transaction_id='+TransactionPopup.id()
                let layername = 'tp_spatial_unit'
                let selected

                UgSpatial.getLayerByName('tp_spatial_unit',"status not in ('Amendment') and DWITHIN(geom,'+r.data.centroid+',1600,meters) and transaction_id not in ("+TransactionPopup.id()+")").addTo(ly_tp)

                try{
                    //
                    let url = API.geo_ilmis+"/ows?cql_filter="+filter+" and status not in ('Amendment')&service=WFS&version=1.0.0&request=GetFeature&typeName="+layername+"&outputFormat=application%2Fjson"
                    console.log("url"+url)
                    API.ax.get(url).then(r=>{
                        Wait.dismiss()
                        try {

                            //console.log(r.data)

                            r.data.features.forEach(p=>{
                                let myLayer = LF.geoJSON(p,{
                                    onEachFeature:(f,l)=>{
                                       //.addTo(ly_group)

                                        //

                                        if ((TransactionPopup.id()===p.properties.transaction_id)){
                                            var marker = LF.marker(l.getBounds().getCenter(),{
                                                icon:LF.divIcon({
                                                    className:'bindTooltipBig',
                                                    html:f.properties.land_use_type.substring(0,1),
                                                    iconSize:[100,40]
                                                })
                                            })
                                            marker.addTo(ly_group)
                                            l.setStyle({
                                                color:f.properties.status==='Amendment'?'#f00':'#088'
                                            })
                                        }else{
                                            var marker = LF.marker(l.getBounds().getCenter(),{
                                                icon:LF.divIcon({
                                                    className:'bindTooltipBig',
                                                    html:f.properties.land_use_type.substring(0,1)+"<br/>"+p.id.split('.')[1],
                                                    iconSize:[100,40]
                                                })
                                            })
                                            marker.addTo(ly_tp)
                                            l.setStyle({
                                                color:f.properties.status==='Amendment'?'#f00':'#008'
                                            })
                                        }

                                    },
                                    coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                                    weight:3,zIndex:2, fillOpacity: 0
                                }).on('dblclick',e=>{



                                }).on('click', function (e) {

                                    if (selected) {e.target.resetStyle(selected)}
                                    selected = e.layer


                                    selected.bringToFront()
                                    selected.setStyle({color: '#000', weight:4})
                                    map.fitBounds(selected.getBounds())

                                    TpAmendSpatialUnitPane.self.setState({
                                        current_tid:parseInt(selected.feature.id.split('.')[1]),
                                        current_use:selected.feature.properties.land_use_type
                                    })

                                })


                                console.log(p.properties.transaction_id+"=>"+(TransactionPopup.id()===p.properties.transaction_id))

                                if ((TransactionPopup.id()===p.properties.transaction_id)){
                                    myLayer.addTo(ly_group)
                                }else{
                                    myLayer.addTo(ly_tp)
                                }


                            })
                            let myLayer = LF.geoJSON(r.data,{coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();}})
                            try{ map.fitBounds(myLayer.getBounds())}catch (e) { }
                        }catch (e){
                            console.log(e)
                        }

                    })

                }catch (e){console.log(e)}

                let ly_vb = UgSpatial.getLayerByName('valuation_block',filter).addTo(map)

                UgSpatial.getLayerByName('tp_spatial_unit',filter+' and status in (\'Amendment\') and transaction_id not in ('+TransactionPopup.id()+')').addTo(ly_history)

                ly_vb.bringToFront()

                let overlays = {
                    'Valuation Blocks':ly_vb,
                    'TP Drawings':ly_tp,
                    'History':ly_history,
                    'Current Layer':ly_group,
                }
                UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)
            }catch (e) {
                console.log(e)
            }
        })
    }


    toggleCheck(i){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            return;
        }
        doc.src=uncheck;
    }

    previewTP(){
        PopDetail.show('Preview TP',<PreviewPrintDocument type={'tp'}/>)
    }

    render() {
        return(this.state.in_queue===true?<QueuePane/>:<div>

            <div className={'row'} style={{marginBottom:'4px'}}>


                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'20px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{display:TransactionPopup.task()==='Print Registered TP'?'inherit':'none'}}>
                            <div>
                                <b>Select Layers</b>
                            </div>
                            <br/>
                            <div>
                                <span><input type={'checkbox'}/> Parcels</span><br/>
                                <span><input type={'checkbox'}/> Buildings</span><br/>
                                <span><input type={'checkbox'}/> Contours</span><br/>
                                <span><input type={'checkbox'}/> Point</span><br/>
                                <span><input type={'checkbox'}/> Ponds</span><br/>
                                <span><input type={'checkbox'}/> Power Lines</span><br/>
                                <span><input type={'checkbox'}/> Railways</span><br/>
                                <span><input type={'checkbox'}/> Rivers</span><br/>
                                <span><input type={'checkbox'}/> Roads</span><br/>
                            </div>
                            <br/>
                            <div style={{textAlign:'center'}}>
                                <button onClick={this.previewTP}>Preview</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>

                    <div className={'row'} style={{background:'#fff',height:'100%',padding:'4px',border:'0.5px solid #efefef'}}>
                        <div className={'col col-sm-12'} style={{height:'40px',lineHeight:'40px'}}>
                            <b>Map</b>

                            <input type={'number'} name={'current_tid'} defaultValue={this.state.current_tid} onChange={this.handleChange}/>

                            <div style={{float:'right'}}>
                                <span style={{cursor:'pointer'}} onClick={()=>TpAmendSpatialUnitPane.loadData()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-refresh screen-action'}/>Refresh
                                </span>
                                <span style={{cursor:'pointer'}} onClick={()=>PopDetail.show('Upload Spatial',
                                    <UploadSpatialAmendPane reference={'tp_spatial_unit'} tp_drawings={this.state.tp_drawings}/>,44)}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-upload screen-action'}/>Upload Spatial
                                </span>

                                <span style={{cursor:'pointer',marginRight:'16px'}} onClick={()=>this.downloadSpatial()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-download screen-action'}/>Download Spatial
                                </span>

                                <span style={{cursor:'pointer'}} onClick={this.freeze}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-times screen-action'}/>Freeze
                                </span>

                            </div>
                        </div>

                        <div className={'col col-sm-12'} style={{height:'92%',border:'1px solid #ccc',padding:0}}>

                            <div className={'col col-sm-12'} style={{background:'#efefef',padding:0,float:'left',height:'100%'}} id={'ramani'}/>

                        </div>

                    </div>
                </div>

            </div>


            <div className={'row'} >

                <div className={'col col-sm-12'} style={{paddingRight:'4px',height:'27vh'}}>
                    <div style={{background:'#fff',padding:'4px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{marginTop:'8px',width:'100%',float:'left'}}>
                            <div className={'tab tab-active no-bg'}><b>Parcels</b></div>
                            <div className={'tab no-bg'}><b>Buildings</b></div>
                            <div className={'tab no-bg'}><b>Contours</b></div>
                            <div className={'tab no-bg'}><b>Point</b></div>
                            <div className={'tab no-bg'}><b>Ponds</b></div>
                            <div className={'tab no-bg'}><b>Power</b></div>
                            <div className={'tab no-bg'}><b>Railways</b></div>
                            <div className={'tab no-bg'}><b>Rivers</b></div>
                            <div className={'tab no-bg'}><b>Roads</b></div>
                        </div>
                        <div style={{borderTop:'1px solid #ccc',float:'left',width:'100%'}}>

                            {this.state.tp_drawings.length > 0?
                                <table className={'tb'} style={{width:'100%'}}>
                                    <thead>
                                    <tr>
                                        <th>PARCEL ID</th>
                                        <th>LOT TYPE</th>
                                        <th>USE</th>
                                        <th>AREA</th>
                                        <th>BLOCK ID</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.tp_drawings[0].parcels.map((d,i)=>{
                                        return(<tr key={i}>
                                            <td>{d.parcelId}</td>
                                            <td>{d.lotType}</td>
                                            <td>{d.use}</td>
                                            <td>{d.area}</td>
                                            <td>{d.blockId}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                                :
                                <div>No data</div>
                            }
                        </div>
                    </div>
                </div>


            </div>







        </div>)
    }

    downloadSpatial() {
        alert('Downloading...')
    }
}
