import React, {useEffect} from 'react'
import {useBuildingDispatch, useBuildingState} from "./store";
import {useForm} from 'react-hook-form'
import PopDetail from "../../components/popups/popdetail";
import API from "../../services/API";
import TransactionPopup from "../../components/popups/transacrions/transaction_popup";
import Wait from "../../components/popups/wait";

export default function ComputationAnalysis(){
    //alternating the sitework and percentage
    const[siteReadOnly,siteReadOnlySet] = React.useState(false);
    const[submitData, submitSet] = React.useState(false)
    const state = useBuildingState();
    const dispatch = useBuildingDispatch()
    const {register, handleSubmit} = useForm()

    const handleChange= e =>{
        const { name, value } = e.target;
        dispatch({ type: name, payload:value });
    }
    const handleChangeNumber= e =>{
        const { name, value } = e.target;
        dispatch({ type: name, payload:parseFloat(value) });
        console.log(name,state[name])
    }
    const handleChangeChecked= e =>{
        const { name, checked } = e.target;
        dispatch({ type: name, payload:checked });
    }

   useEffect(()=>{
        let valueOfMainB=(parseFloat(state.totalAreaOfMainBuilding)*
                         parseFloat(state.constructionRate) *
                        ( 100 -(parseFloat(state.mbDepreciationPercentage)+ parseFloat(state.mbIncompletenessPercentage))))/100
        dispatch({type:"valueOfMainBuilding", payload:valueOfMainB})
    },[state.totalAreaOfMainBuilding,
            state.constructionRate,
            state.mbDepreciationPercentage,
            state.mbIncompletenessPercentage])

        useEffect(()=>{
            let currentRC=parseFloat(state.constructionRate)*parseFloat(state.totalReducedFloorArea)
            dispatch({type:"currentReplacementCost", payload:currentRC})
        },[state.constructionRate,state.totalReducedFloorArea])

        useEffect(()=>{
           // let depreciatedRC=parseFloat(state.valueOfMainBuilding)+parseFloat(state.siteWorksValue)

            let depreciatedRC = state.currentReplacementCost *(1-(state.mbDepreciationPercentage*0.01))
            dispatch({type:"totalDepreciatedReplacementCost", payload:depreciatedRC})
        },[state.currentReplacementCost,state.mbDepreciationPercentage])

        useEffect(()=>{
            let landV = parseFloat(state.totalLandArea)*parseFloat(state.landValueRate)
            dispatch({type:"landValue", payload:landV})
        },[state.totalLandArea,state.landValueRate])

        useEffect(()=>{
            let totalCM=parseFloat(state.landValue)+ parseFloat(state.totalDepreciatedReplacementCost)
            dispatch({type:"totalCurrentMarketValue", payload:totalCM})
        },[state.landValue,state.totalDepreciatedReplacementCost])

        useEffect(()=>{
            let forcedSV=parseFloat(state.totalCurrentMarketValue)*parseFloat(state.forcedSalePercentage)
            dispatch({type:"forcedSaleValue", payload:forcedSV})
        },[state.totalCurrentMarketValue,state.forcedSalePercentage])

    useEffect(()=>{
        let siteWorksV=(parseFloat(state.currentReplacementCost)*parseFloat(state.siteWorksPercentage)*0.01 *
            ( 100 -(parseFloat(state.mbDepreciationPercentage)+ parseFloat(state.mbIncompletenessPercentage))))/100
        dispatch({type:"siteWorksValue", payload:siteWorksV})
    },[state.currentReplacementCost,state.siteWorksPercentage,state.mbDepreciationPercentage,state.mbIncompletenessPercentage])

    useEffect(()=>{
        if(state.siteWorksFormula==='Percentage'){

            dispatch({type:"siteWorksType", payload:''})
            dispatch({type:"isSiteWorkPercent", payload:false})
            dispatch({type:"isFormulaType", payload:true})
            siteReadOnlySet(false)
            }else {
            siteReadOnlySet(true)
            dispatch({type:"siteWorksPercentage", payload:0})
        }

},[state.siteWorksFormula])
    useEffect(( )=>{
        if(state.valuationPartyVu[0] !== undefined){
            if(state.valuationPartyVu[0].hasOwnProperty("partyId")){
                submitSet(true)
            }
        }
    },[state.valuationPartyVu])
    const submit = data =>{

        const toFillter = ['notes','useReducingFactor','landValueRate', 'totalCurrentMarketValue', 'landValue', 'totalReducedFloorArea', 'insurableValue', 'forcedSaleValue'];

        const filtered = Object.keys(state)
            .filter(key => toFillter.includes(key))
            .reduce((obj, key) => {
                obj[key] = state[key];
                return obj;
            }, {});
        //Compensation
        const{
            unexhaustedImpromentsAmount,
            netProfit,
            profitMonths,
            otherValues,
            notesAllowance,
            unexhaustedImpromentsType,
            lostOfProfit,
            totalAllowance,
            noOfRooms,
            months,
            transportationAllowanceAmount,
            totalCropsValue,
            accommodationAllowance,
            disturbancePercentage,
            totalGrave,
            disturbanceAllowance,
            totalCompensation
        } =state
        const compensation ={
            unexhaustedImpromentsAmount,
            netProfit,
            profitMonths,
            otherValues,
            notesAllowance,
            unexhaustedImpromentsType,
            lostOfProfit,
            totalAllowance,
            noOfRooms,
            months,
            transportationAllowanceAmount,
            totalCropsValue,
            accommodationAllowance,
            disturbancePercentage,
            totalGrave,
            disturbanceAllowance,
            totalCompensation}
        //transactionId
        let datas ={...filtered,transactionId:TransactionPopup.id(),valuationPurpose:state.purposeOfValuation}
        let toSend ={...data,transactionId:TransactionPopup.id(),valuationPurpose:state.purposeOfValuation,...compensation}
        console.log("Data ",toSend)
try{

            Wait.show("Saving Valuation Data ...")
  API.ax.post("valuation/valuation-units",toSend)
      .then(d=>{
          //1. valuationPartyVu
          const vuId = d.data.result
          if(state.valuationPartyVu.length > 0){
              console.log("party",state.valuationPartyVu)
              const{partyId:party,valuationNo}=state.valuationPartyVu[0]

              /*let arrays = [];
              arrays.push(party.partyId)*/

              let dataSendParty = {
                  vuId,
                  party,
                  valuationNo
              }
              console.log("Sent People", dataSendParty)
              API.ax.post("valuation/vu-party",dataSendParty)
                  .then(d=>{
                      console.log("People", d.data)
                  })
                  .catch(e=>console.error(e))
          }
          //2. valuationSpatialUnitVu
          const{valuationSpatialUnitVu:spUnit} = state;
          let arr =[]
          /*const sp = spUnit.forEach(({spatialUnitId})=>{
              arr.push(spatialUnitId)
          })*/
          arr.push(spUnit)
         const sendSp ={ spatial:arr,vuId}
         console.log("data to send", sendSp)
          API.ax.post("/valuation/vu-spatial",sendSp)
              .then(d=>console.log("Success",d))
              .catch(e=>console.log(e))

          //3. valuationBuilding
          let building =state.valuationBuilding
          let sendBuilding ={...building, valuationUnitId: vuId}
          console.log("Buildings with vu id",sendBuilding)
          API.ax.post("valuation/buildings",sendBuilding)
              .then(d=>console.log("Building",d.data))
              .catch(e=>console.error(e))

          //4. valuationOuterBuildings
          if(state.valuationOuterBuildings.length > 0){
              let outBuildingArray =state.valuationOuterBuildings
              let sendOutBuilding =outBuildingArray.map(function(el) {
                  let o = Object.assign({}, el);
                  o.valuationUnitId = vuId;
                  return o;
              })
              console.log("valuationOuterBuildings ",sendOutBuilding)
              const datum ={valuationOuterBuildings:sendOutBuilding}
              API.ax.post('/valuation/outer-buildings',datum)
                  .then(d=>console.log("outer building", d.data))
                  .catch(e=>console.error(e))

          }
          //5. valuationGraves /valuation/graves
          if(state.valuationGraves.length >0){
              let gravesArray = state.valuationGraves
              let gravesData = gravesArray.map(function(el){
                  let o = Object.assign({}, el);
                  o.valuationUnitId =vuId;
                  return o;
              })
              console.log("valuationGraves",gravesData)
              const dataGraves ={valuationGraves:gravesData}
              API.ax.post("/valuation/graves",dataGraves)
                  .then(d=>console.log("Graves",d.data))
                  .catch(e=>console.error(e))
          }
          //6.valuationCrops /valuation/crops
          if(state.valuationCrops.length > 0){
              let cropsArray = state.valuationCrops
              let cropsData = cropsArray.map(function (el) {
                  let o =Object.assign({}, el);
                  o.valuationUnitId =vuId;
                  return o;
              })
              console.log("valuationCrops ",cropsData)
              const dataCrops ={valuationCrops:cropsData}
              API.ax.post("/valuation/crops",dataCrops)
                  .then(d=>console.log("Crops", d.data))
                  .catch(e=>console.error(e))
          }
      })
      .catch(e=>console.error(e))

}
catch{}
        dispatch({type:'reset'});
        Wait.dismiss()
        PopDetail.dismiss()

    }

    return(

        <div className={"container-fluid"}>
            <form onSubmit={handleSubmit(submit)}>
            <div className={"row"}>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="propertyCategory" className={"control-label" }>Property Category</label>
                        <select
                            name={"propertyCategory"}
                            className={"form-control"}
                            value={state.propertyCategory}
                            id="propertyCategory"
                            ref={register({ required: true})}
                            onChange={handleChange}
                        >
                            <option value={""}/>
                            <option value={"SS/RS"}>SS/RS</option>
                            <option value={"DS/COM"}>DS/COM</option>
                            <option value={"INDUSTRIAL"}>INDUSTRIAL</option>
                            <option value={"MS/COM-RS"}>MS/COM-RS</option>
                        </select>
                    </div>
                </div>
                <div className={"col-sm-3"}>
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <div className="form-group">
                                <label htmlFor="siteWorksFormula" className={"control-label" }>Siteworks Formula</label>
                                <select
                                    onChange={handleChange}
                                    name={"siteWorksFormula"}
                                    value={state.siteWorksFormula}
                                    ref={register({ required: true})}
                                    className={"form-control"} id="siteWorksFormula">
                                    <option value={""}/>
                                    <option value={"Percentage"}>Percentage</option>
                                    <option value={"No Formula"}>No Formula</option>
                                </select>
                            </div>
                        </div>
                        <div className={"col-sm-6"}>
                            <div className="form-group">
                                <label htmlFor="siteWorksPercentage" className={"control-label" }>Site Works %</label>
                                <input
                                    type="number"
                                    onChange={handleChange}
                                    value={parseFloat(state.siteWorksPercentage)}
                                    readOnly={siteReadOnly}
                                    name={"siteWorksPercentage"}
                                    className={"form-control"}
                                    ref={register({ required: !siteReadOnly, min: 0, max: 100})}
                                    id="siteWorksPercentage"
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"col-sm-3"}>
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <div className="form-group">
                                <label htmlFor="totalLandArea" className={"control-label" }>Land Area</label>
                                <input type="number" onChange={handleChangeNumber} value={parseFloat(state.totalLandArea)} name ={"totalLandArea"} className={"form-control"} ref={register({ required: true})} id="totalLandArea" readOnly={true}/>
                            </div>
                        </div>
                        <div className={"col-sm-6"}>
                            <div className="form-group">
                                <label htmlFor="unit" className={"control-label" }>Unit</label>
                                <input type="text" onChange={handleChange} value={state.unit} name={"unit"} className={"form-control"} id="unit" ref={register({ required: true})} readOnly={true}/>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"col-sm-3"}>
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <div className="form-group">
                                <label htmlFor="forcedSalePercentage" className={"control-label" }>Forced Sale %</label>
                                <input type="number" onChange={handleChangeNumber} value={parseFloat(state.forcedSalePercentage)} name={"forcedSalePercentage"} className={"form-control"} id="forcedSalePercentage" ref={register({ required: true, min: 0, max: 100})}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="useReducingFactor" className={"control-label" }>Use Reducing Factor</label>
                        <input
                            type="checkbox"
                            onChange={handleChangeChecked}
                            name={"useReducingFactor"}
                            style={{height:'45px'}}
                            className={"form-control form-check-input"}
                            id="useReducingFactor"
                            ref={register}
                            checked={state.useReducingFactor}
                        />
                    </div>
                    <br/><br/>
                    <div className="form-group">
                        <label htmlFor="totalAreaOfMainBuilding" className={"control-label" }>Total Area Of Main Building</label>
                        <input type="number" onChange={handleChangeNumber} value={parseFloat(state.totalAreaOfMainBuilding)} name={"totalAreaOfMainBuilding"} className={"form-control"} ref={register({ required: true})} id="totalAreaOfMainBuilding"/>
                    </div>
                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="siteWorksType" className={"control-label" }>Site Works Type</label>
                        <textarea  onChange={handleChange} value={state.siteWorksType} name={"siteWorksType"} ref={register({ required: siteReadOnly})} readOnly={!siteReadOnly} className={"form-control"} id="siteWorksType" style={{resize:"none", height:'107px'}}/>
                    </div>
                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="landValueRate" className={"control-label" }>Land Value Rate</label>
                        <input type="number" onChange={handleChangeNumber} value={parseFloat(state.landValueRate)} name={"landValueRate"} ref={register({ required: true})} className={"form-control"} id="landValueRate"/>
                    </div>
                    <div className="form-group">
                       {/* <label htmlFor="landValueRange" className={"control-label" }>Land Value Range</label>
                        <input type="number" onChange={handleChangeNumber} value={parseFloat(state.landValueRange)} ref={register({ required: true})} name={"landValueRange"} className={"form-control"} id="landValueRange"/>*/}
                    </div>
                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="forcedSaleValue" className={"control-label" }>Forced Sale Value</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} readOnly value={parseFloat(state.forcedSaleValue)} name={"forcedSaleValue"} className={"form-control"} id="forcedSaleValue"/>
                    </div>
                    <div className={"col-sm-6"}>
                    <div className="form-group">
                        <label htmlFor="insurablePercentage" className={"control-label" }>Insurable %</label>
                        <input type="number" onChange={handleChangeNumber} value={parseFloat(state.insurablePercentage)} ref={register({ required: true, min: 0, max: 100})} name={"insurablePercentage"} className={"form-control"} id="insurablePercentage"/>
                    </div>
                </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="constructionRate" className={"control-label" }>Construction Rate</label>
                        <input type="number" onChange={handleChangeNumber} value={parseFloat(state.constructionRate)} ref={register({ required: true})} name={"constructionRate"} className={"form-control"} id="constructionRate"/>
                    </div>

                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="siteWorksValue" className={"control-label" }>Site Works Value</label>
                        <input type="number" onChange={handleChangeNumber} value={parseFloat(state.siteWorksValue)} ref={register({ required: true})} name={"siteWorksValue"} className={"form-control"} id="siteWorksValue"/>
                    </div>
                </div>
                <div className={"col-sm-3"}>

                    <div className="form-group">
                        <label htmlFor="landValueUseType" className={"control-label" }>Land Value Use Type</label>
                        <input type="text" onChange={handleChange} ref={register} value={state.landValueUseType} name={"landValueUseType"} className={"form-control"} id="landValueUseType"/>
                    </div>
                </div>
                <div className={"col-sm-3"}>

                    <div className="form-group">
                        <label htmlFor="insurableValue" className={"control-label" }>Insurable Value</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} value={parseFloat(state.insurableValue)} name={"insurableValue"} className={"form-control"} id="insurableValue"/>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="mbDepreciationPercentage" className={"control-label" }>MB Depreciation %</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true, min: 0, max: 100})} value={parseFloat(state.mbDepreciationPercentage)} name={"mbDepreciationPercentage"} className={"form-control"} id="mbDepreciationPercentage"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="mbIncompletenessPercentage" className={"control-label" }>MB Incompleteness Percentage</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true, min: 0, max: 100})} value={parseFloat(state.mbIncompletenessPercentage)} name={"mbIncompletenessPercentage"} className={"form-control"} id="mbIncompletenessPercentage"/>
                    </div>
                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="totalReducedFloorArea" className={"control-label" }>Total Reduced Floor Area</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} value={parseFloat(state.totalReducedFloorArea)} name={"totalReducedFloorArea"} className={"form-control"} id="totalReducedFloorArea"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="totalValueOfOuterBuilding" className={"control-label" }>Total Value Of Outer Building(s)</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} value={parseFloat(state.totalValueOfOuterBuilding)} name={"totalValueOfOuterBuilding"} className={"form-control"} id="totalValueOfOuterBuilding"/>
                    </div>
                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="landValue" className={"control-label" }>Land Value</label>
                        <input type="number" onChange={handleChangeNumber} ref={register} value={state.landValue} name={"landValue"} className={"form-control"} id="landValue"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="totalDepreciatedReplacementCost" className={"control-label" }>Total Depreciated Replacement Cost</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} value={parseFloat(state.totalDepreciatedReplacementCost)} name={"totalDepreciatedReplacementCost"} className={"form-control"} id="totalDepreciatedReplacementCost"/>
                    </div>
                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="notes" className={"control-label" }>Notes</label>
                        <textarea  onChange={handleChange} ref={register({ required: true})} value={state.notes} name={"notes"} className={"form-control"} id="notes" style={{resize:"none", height:'107px'}}/>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="valueOfMainBuilding" className={"control-label" }>Value Of Main Building</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} value={parseFloat(state.valueOfMainBuilding)} name={"valueOfMainBuilding"} className={"form-control"} id="valueOfMainBuilding"/>
                    </div>

                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="currentReplacementCost" className={"control-label" }>Current Replacement Cost</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} value={parseFloat(state.currentReplacementCost)} name={"currentReplacementCost"} className={"form-control"} id="currentReplacementCost"/>
                    </div>

                </div>
                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="totalCurrentMarketValue" className={"control-label" }>Total Current Market Value</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} value={parseFloat(state.totalCurrentMarketValue)} name={"totalCurrentMarketValue"} className={"form-control"} id="totalCurrentMarketValue"/>
                    </div>

                </div>

                <div className={"col-sm-3"}>
                    <div className="form-group">
                        <label htmlFor="adjustedTotalMarketValue" className={"control-label" }>Adjusted TotalMarket Value</label>
                        <input type="number" onChange={handleChangeNumber} ref={register({ required: true})} value={parseFloat(state.adjustedTotalMarketValue)} name={"adjustedTotalMarketValue"} className={"form-control"} id="adjustedTotalMarketValue"/>
                    </div>

                </div>

            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                {submitData&&<button style={{width:'100px'}} type={'submit'}>Save</button>}
                <button style={{width:'100px'}} onClick={()=>{dispatch({type:'reset'});PopDetail.dismiss();}} className={'default'}>Close</button>
            </div>
            </form>
        </div>
    )
}
