import React, {useState, useEffect} from 'react'
import PopDetail from "../../../popdetail";
import API from "../../../../../services/API";
import GeneralSelect from "../../../../../utils/GeneralSelect";
import Wait from "../../../wait";

function NewIssue({txId, handleSave}) {
    const[detail, detailSet] = useState('')
    const[rejectionStatus, rejectionStatusSet] = useState(0)
    const[rejectionReason, rejectionReasonSet] = useState(0)
    const[reason, reasonSet] = useState('')

    const reasons =
        {
            'endpoint':'transaction/reasons-type',
            'value':'rejectionReasonTypeId',
            'label':'name',
            'placeholder':'Select Reason'
        }

     const reasonChange = event =>{
         reasonSet(event.label)
         rejectionReasonSet(event.value)
     }

     const handleSubmit = () =>{
        let status = rejectionStatus === 1?"Cancelled":
                     rejectionStatus === 3?"Pending":
                     rejectionStatus === 2?"Resolved":''
        const datas = {
            detail,
            "kind":"",
            rejectionReason,
            rejectionStatus,
            transactionId:txId
        }
        console.log("To send",datas)
         handleSave(datas, reason,status)
     }

return(
    <div style={{margin:".5rem"}}>
        <div className={'form-group'}>
            <label htmlFor={'reason'}>* Reason</label>
            <GeneralSelect parameters={reasons} onChanges={reasonChange}/>
        </div>
        <div className={'form-group'}>
            <label htmlFor={'detail'}>* Details </label><br/>
            <textarea
                style={{resize:'none', width:'100%'}}
                className={''}
                id={'detail'}
                name={'detail'}
                rows={5}
                onChange={(e)=>{detailSet(e.target.value)}}
            />
        </div>
        <div className={'form-group'}>
            <label htmlFor={'detail'}>* Status</label>
            <select
                className={'form-control'}
                id={'rejectionStatus'}
                name={'rejectionStatus'}
                onChange={(e)=>{rejectionStatusSet(parseInt(e.target.value))}}
            >
                <option value={''}> --Select-- </option>
                <option value={3}>Pending</option>
                <option value={2}>Resolved</option>
                <option value={1}>Cancelled</option>
            </select>
        </div>
        <hr/>
        <div style={{position:'relative', width:'100%', margin:'2rem'}}>
            <button className={"default"} onClick={()=>{PopDetail.dismiss()}} style={{width:'150px',float:'right'}}>Cancel</button>
            <button onClick={handleSubmit} style={{width:'150px',float:'right'}}> Save</button>
        </div>
    </div>
)
}

export default function Issues ({transactionId}){
    const[txId, txIdSet] = useState(0)
    const[issues, issuesSet] = useState([])
    useEffect(()=>{
        txIdSet(transactionId)
        if(transactionId > 0){
            API.ax.get(`transaction/reasons/${transactionId}`)
                .then(d=>{
                    console.log("Some Reviews so far",d.data.status)
                    if(d.data.status === 1){
                        console.log("before set",d.data.result)
                        issuesSet(d.data.result)
                        console.log("the set",issues)
                    }
                })
                .catch(e=>{console.log("error",e)})
        }

    },[transactionId])

    const handleSave = (data, reason, status) =>{
        console.log("data",data)
        try{
            Wait.show("Saving Issue ...")
            API.ax.post("transaction/reasons",data)
                .then(d=>{
                    Wait.dismiss()
                    console.log("answer", d)
                    let returned = {...d.data.result,reason,status}
                    issuesSet([...issues,returned])
                })
                .catch(e =>{Wait.dismiss();console.log("error",e);})
        }catch (e) {
            Wait.dismiss()
            console.log(e)
        }
        PopDetail.dismiss()
    }
    return(
        <div className={'col col-sm-12'} style={{height:'67vh',paddingRight:'4px'}}>
            <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                <div style={{height:'40px',lineHeight:'40px'}}>
                    <b>Issues</b>

                    <div style={{float:'right'}}>
                                <span onClick={()=>PopDetail.show('New Issue',<NewIssue txId={txId} handleSave={handleSave}/>,72)}>
                                <span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'}
                                       />New
                            </span>
                        <span>
                                <span style={{marginLeft:'16px'}} className={'fa fa-edit screen-action'}
                                      onClick={()=>{}} />Edit
                            </span>
                        <span>
                                <span style={{marginLeft:'16px'}} className={'fa fa-trash screen-action'}
                                      onClick={()=>{}} />Remove
                            </span>

                    </div>
                </div>

                <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                    <thead>
                    <tr>
                        <th>DATE ADDED</th>
                        <th>REASON</th>
                        <th>DETAILS</th>
                        <th>STATUS</th>
                        <th>USER</th>
                    </tr>

                    </thead>
                    <tbody>
                    {
                        issues?issues.map((d,i)=>{
                        return(
                            <tr key={i}>
                                <td>{d.addDatetime}</td>
                                <td>{d.reason}</td>
                                <td>{d.detail}</td>
                                <td>{d.status}</td>
                                <td>{d.userName}</td>
                            </tr>
                        )
                    }):<tr key={8765}><td colSpan={5} style={{textAlign:'center'}}>No issues Yet</td></tr>}
                    </tbody>
                </table>

            </div>
        </div>
    )
}