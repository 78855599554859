import React, {Component} from 'react';
import CompletePane from "./complete";
import PaneAppRtuApprovalWithWarning from "../pops/pane_app_rtu_approval_with_warning";

class WarningPane extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div>
                <div className={'col col-sm-12 pb-3'} style={{height:'70vh',paddingLeft:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Title Validation Results</b>

                        </div>

                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>
                            <tr>
                                <th style={{width:'200px'}}>TYPE</th>
                                <th>MESSAGE</th>
                            </tr>
                            </thead>

                            <tbody>
                            {this.props.warnMessage.map((d,i)=>{
                                    return(<tr key={i}>
                                        <td style={{color:'red'}}>Warning</td>
                                        <td>{d}</td>
                                    </tr>)
                                }
                            )}

                            </tbody>
                        </table>
                        <br/>
                        <p>It is recommended to conduct review of each transaction before making any decision</p>
                        <b className={'text-danger'}>Click Ok to continue if there is no problem with this tittle</b>

                        <button className={'float-right'} onClick={this.props.approveHandler} >OK</button>

                    </div>
                </div>
            </div>
        );
    }
}

export default WarningPane;