import React, {useState} from 'react'
import OtherBuilding from "./otherBuilding";
import Building from "./Building";
import Header from "../../components/popups/transacrions/screen/gro/header";
import BuildingHelper from "./BuildingHelper";
import PopDetailsTwo from "../../components/popups/PopDetailsTwo";

export default function MainBuilding() {
    const [currentTab, currentTabSet] = useState(1)
    return(
        <div style={{height:'100vh'}}>
                <div className={'row'} style={{height:'6vh'}}>
                    <div className={'col col-sm-12'} style={{background:'#fff', padding:'.2rem'}}>
                        <div className={currentTab === 1?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{currentTabSet(1)}}>
                            Main Building
                        </div>
                        <div className={currentTab === 2?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{currentTabSet(2)}}>
                            MB Details
                        </div>
                    </div>
                </div>
                <div className={'row'} style={{height:'94vh', margin:0,overflow:'auto'}}>
                    <div className={'col col-sm-12'} style={{height:'inherit',background:'#fff',padding:'1.5rem', overflow:'auto',border:'0.5px solid #efefef'}}>
                        <div style={currentTab===1?{}:{display:'none'}}>
                            <Building/>
                        </div>
                        <div style={currentTab===2?{}:{display:'none'}}>
                            <OtherBuilding/>
                        </div>
                        <br/>
                        <Header label={"Outer Building"} display={true}>
                            <div>
                                <i
                                    className={'fa fa-plus-circle'}
                                    style={{color:'tomato', fontSize:'25px'}}
                                    onClick={()=>{PopDetailsTwo.show("Add outer Building",<BuildingHelper/>)}}
                                /><strong> New</strong>
                            </div>
                        </Header>
                       {/* <table>
                            <thead className={'tb'}>
                            <tr>
                                <th>Header One</th>
                                <th>Header Two</th>
                                <th>Header Three</th>
                            </tr>
                            </thead>
                        </table>*/}
                    </div>
                </div>
        </div>
    )
}