import React from "react";
import MobileCreateService from "../../../../../services/mobile/mobile_create";
import Wait from "../../../wait";
import PopDetail from "../../../popdetail";
import ProjectConfig from "./project_config";
import MobileUpdateService from "../../../../../services/mobile/mobile_update";

export default class ProjectHamlets extends React.Component{
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.saveHamlet = this.saveHamlet.bind(this)
    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }

    updateHamletAt(i,d){
        let code = document.getElementById('hcode'+i).value.trim().toUpperCase()
        let name = document.getElementById('hname'+i).value.trim().toUpperCase()

        Wait.show('Saving...')
        MobileUpdateService.updateHamlet({
            id: d.id,
            name: name,
            code: code
        }).then(r  =>{
            Wait.dismiss()
            ProjectConfig.loadVillages()
        })

    }

    static popAddHamlet(){
        document.getElementById('pop_add_hamlet').style.display='inherit'
    }

    static hideAddHamlet(){
        document.getElementById('pop_add_hamlet').style.display='none'
    }

    saveHamlet(){
        let code = this.state.hcode.trim().toUpperCase()
        let name = this.state.hname.trim().toUpperCase()

        if (name!==''){
            Wait.show('Saving...')
            MobileCreateService.createHamlet({
                code: code,
                name:name,
                id: this.props.village.id
            }).then( r =>{
                Wait.dismiss()
                try {
                    PopDetail.dismiss()
                    ProjectConfig.loadVillages()
                }catch (e) {
                    console.log()
                }
            })
        }
    }

    render() {
        return(<div style={{width:'100%'}}>

            <div className={'row'} style={{boxShadow:'0 4px 4px #aaa',padding:'8px 16px',backgroundColor:'#efefef'}}>
                <div className={'col col-sm-8'}>
                    <span className={'fa fa fa-bars'} style={{cursor:'pointer', marginTop:'8px'}} onClick={()=>ProjectHamlets.popAddHamlet()}/>
                    <input style={{width:'85%',marginLeft:'24px',backgroundColor:'#fff'}} placeholder={'Search here...'}/>
                    <span className={'fa fa fa-plus-circle'} style={{color:'#008b8b', float:'right',cursor:'pointer', margin:'8px'}}
                          onClick={()=>ProjectHamlets.popAddHamlet()}/>
                </div>
                <div className={'col col-sm-4 fa-btn'}>
                    <button style={{float:'right',width:'80px'}} onClick={()=>PopDetail.dismiss()} className={'default'}>Refresh</button>
                </div>
            </div>

            <div className={'col col-sm-12'} id={'pop_add_hamlet'} style={{display:'none'}}>
                <table style={{margin:'16px',width:'90%'}}>
                    <tbody style={{width:'100%'}}>
                    <tr>
                        <td><b>Hamlet Name :</b></td>
                        <td>
                            <input name={'hname'} style={{width:'100%'}} onChange={this.handleChange}/>
                        </td>
                        <td style={{paddingLeft:'16px'}}><b>Hamlet Code :</b></td>
                        <td>
                            <input name={'hcode'} style={{width:'100%'}} onChange={this.handleChange}/>
                        </td>
                        <td>
                            <button style={{float:'right',marginRight:'16px'}} onClick={()=>this.saveHamlet()}>Save</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <table className={'tb2'}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {this.props.village.hamlets.map((d,i)=>{
                    return(<tr key={i}>
                        <td>
                            {(i+i)}.
                        </td>
                        <td>
                            <input style={{width:'100%'}} type={'text'} defaultValue={d.name} id={'hname'+i}/>
                        </td>
                        <td>
                            <input style={{width:'100%'}} type={'text'} defaultValue={d.code} id={'hcode'+i}/>
                        </td>
                        <td>
                            <button className={'default'} style={{float:'right',marginRight:'16px'}} onClick={()=>this.updateHamletAt(i,d)}>Update</button>
                        </td>
                    </tr>)
                })}
                </tbody>
            </table>
        </div>)
    }
}