import * as React from "react";
import PopDetail from "../../popdetail";
import PaneAssign from "../pops/pane_assign";
import Issues from "./objects/newIssue";
import TransactionPopup from "../transaction_popup";
import LadApproval from "./ladApproval";
import RtuApproval from "./rtuApproval";
import ValuationApproval from "./valuationApproval";
import LadCLApproval from "./ladCLApproval";
import PaneProcessForm1LadApproval from "../pops/pane_process_form1_lad_approval";
import SmdApproval from "./smdApproval";

export default class ReviewPane extends React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            issues:[],
            comments:'',
            transactionId:0,
            assignee:{
                user:'',
                approval:<div></div>
            }
        }
        ReviewPane.self = this
    }

    componentWillUnmount() {
        ReviewPane.self.state.assignee.user =''
    }

    componentDidMount() {
    let txName=this.props.transaction.name;

        if(this.props.transaction.taskName==='Approve Allocation'){
            this.setState({approval:<LadApproval/>});
        }if(this.props.transaction.taskName==='CL Approve Certificate of Occupancy'){

            this.setState({approval:<LadCLApproval/>});
        }

        if(this.props.transaction.taskName==='CL Approve Certificate'){
            this.setState({approval:<LadCLApproval/>});
        }

        if(this.props.transaction.taskName==='Review and Sign Approval'){


            this.setState({approval:<LadCLApproval/>});
        }


        if(this.props.transaction.taskName==='Call for meeting of NLAC'){


            this.setState({approval:<PaneProcessForm1LadApproval/>});
        }
        if(this.props.transaction.taskName==='CL Approve Rectification'){

            this.setState({approval:<LadCLApproval/>});
        }
        if(this.props.transaction.taskName==='Approve Final Documents' &&txName.includes('Valuation')){

            this.setState({approval:<ValuationApproval/>})

        }

        if(this.props.transaction.taskName==='Approve Transaction'){

            this.setState({approval:<SmdApproval/>});
        }

        if(this.props.transaction.taskName==='Approve Survey Instruction'){

            this.setState({approval:<SmdApproval/>});
        }
        if(this.props.transaction.taskName==='Approve Final Documents' && !txName.includes('Valuation')){

            this.setState({approval:<RtuApproval/>})

        }

        if(this.props.transaction.taskName==='RT Approve Certificate'){

            this.setState({approval:<RtuApproval/>})

        }

        if(this.props.transaction.taskName==='Approve Transaction' && TransactionPopup.state().transaction.name === "Update Spatial Information"){

            this.setState({approval:<RtuApproval/>})

        }

        if(this.props.transaction.taskName==='Approve Valuation Reports'){

            this.setState({approval:<ValuationApproval/>})
        }

        setTimeout(()=>{

            console.log("the tx Id",TransactionPopup.id())
            this.setState({transactionId:TransactionPopup.id()})
        },1000)
    }
    handleChange= e =>{
        console.log("comments",e.target.value)
        this.setState({
            comments: e.target.value
        })
    }

    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-12'} style={{height:'27vh',paddingRight:'4px'}}>

                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Review Detail</b>
                        </div>

                        <table style={{width:'100%'}}>
                            <tbody>
                            <tr style={{color:'#888'}}>
                                <td style={{width:'400px'}}>Submit To Officer</td>
                                <td>Instruction to Officer</td>
                            </tr>
                            <tr>
                                <td style={{verticalAlign:'top'}}>
                                    <select style={{width:'97%'}} onClick={()=>PopDetail.show('Submit To',<PaneAssign/>)}>
                                        <option>{ReviewPane.self.state.assignee.user}</option>
                                    </select>

                                </td>
                                <td><textarea style={{width:'100%'}} rows={3} name={'comments'} defaultValue={this.state.comments} onChange={this.handleChange}/></td>
                            </tr>

                            </tbody>
                        </table>

                    </div>
                </div>


            </div>
            <div className={'row'} style={{marginTop:'15px'}}>

                {this.state.approval}
            </div>
            <div className={'row'} style={{marginTop:'10px'}}>

                <Issues transactionId={this.state.transactionId}/>

            </div>



        </div>)
    }
}
