import React from "react";
import Wait from "../../../wait";
import MobileReadService from "../../../../../services/mobile/mobile_read";
import AddDictionaryPane from "./add_dictionary";
import PopDetail from "../../../popdetail";
import MobileDeleteService from "../../../../../services/mobile/mobile_delete";

export default class MobileDictionaryPane extends React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            dictionary:[]
        }

        MobileDictionaryPane.self = this

        this.handleChange = this.handleChange.bind(this)
        MobileDictionaryPane.loadData = MobileDictionaryPane.loadData.bind(this)
        this.delete = this.delete.bind(this)

    }

    handleChange= e =>{ this.setState({[e.target.name]: e.target.value}); }

    static loadData(){
        try {
            Wait.show('Loading....')
            MobileReadService.readTranslateByCategorySubCategory(this.state.category, this.state.sub_category).then(r =>{
                try {
                    Wait.dismiss()
                    console.log(r.data.results)
                    MobileDictionaryPane.self.setState({
                        dictionary:r.data.results
                    })
                    console.log(r.data)
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            Wait.dismiss()
            console.log(e)
        }
    }

    delete(d){
        MobileDeleteService.deleteDictionary(d.id).then(r => {
            MobileDictionaryPane.loadData()
        })
    }
    render() {
        return(<div style={{padding:'24px',width:'100%',height:'80vh',overflow:'auto'}}>
            <div className={'row'}>
                <div className={'col col-sm-5'}>
                    <select onChange={this.handleChange} name={'category'} style={{width:'100%'}}>
                        <option>- Select Category -</option>
                        <option>mobile</option>
                    </select>
                </div>
                <div className={'col col-sm-5'}>
                    <select onChange={this.handleChange} name={'sub_category'} style={{width:'100%'}}>
                        <option>- Select Sub Category -</option>
                        <option>output</option>
                        <option>questionnaire</option>
                        <option>document</option>
                        <option>signatory</option>
                        <option>hamlet</option>
                    </select>
                </div>
               <div className={'col col-sm-2'}>
                   <button className={'default'} onClick={MobileDictionaryPane.loadData}>
                       <span className={'fa fa-filter'}/> Filter
                   </button>
                   <button className={'default'} onClick={()=>PopDetail.show('Add Dictionary',<AddDictionaryPane/>)}>
                       <span className={'fa fa-plus'}/>New
                   </button>
               </div>
            </div>
            <hr/>
            <table className={'tb2'} style={{width:'100%'}}>
                <tbody>
                <tr>
                    <td>ID</td>
                    <td>NAME</td>
                    <td>CATEGORY</td>
                    <td>SUB CATEGORY</td>
                    <td>PARENT ID</td>
                    <td>DESCRIPTION</td>
                    <td>Actions</td>
                </tr>
                {this.state.dictionary.map((d,i)=>{
                    return(<tr key={i}>
                        <td>{d.id}</td>
                        <td>{d.name}</td>
                        <td>{d.category}</td>
                        <td>{d.sub_category}</td>
                        <td>{d.parent}</td>
                        <td>{d.description}</td>
                        <td>
                            <button className={'submit'}>
                                <span className={'fa fa-pencil'}/>Edit
                            </button>
                            <button onClick={()=>this.delete(d)}>
                                <span className={'fa fa-trash'}/>Freeze
                            </button>
                        </td>
                    </tr>)
                })}

                </tbody>
            </table>
        </div>)
    }
}