import * as React from "react";
import Wait from "../../../wait";
import TransactionPopup from "../../transaction_popup";
import SMDCreateService from "../../../../../services/smd/smd_create";
import PopDetail from "../../../popdetail";
import TPReadService from "../../../../../services/tp/tp_read";
import SMDReadService from "../../../../../services/smd/smd_read";
import SurveyInstructionPane from "../spatial-unit/surver_instruction";
import GeneralSelect from "../../../../../utils/GeneralSelect";

const options ={
    'endpoint':'/tp/read/0/info',
    'value':'id',
    'label':'tpDrawingNumber',
    'placeholder':'Select TP Drawing Number'
}

export default class AddSurveyInstructionPane extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            show_save:'inherit',
            tp_drawings:[],
            drawingNumber:'',
            surveyorId:'',
            location:'',
            surveyType:'',
            areaType:'',
            subject:'',
            surveyFor:'',
            numberOfParcel:'',
            instructionType:'',
            registeredSurveyorId:'',
            surveyors:[]
        }
        this.saveInstruction = this.saveInstruction.bind(this)
        this.loadData = this.loadData.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeTp = this.handleChangeTp.bind(this)
    }


    loadData(){
        try {
            Wait.show("Loading TP...")
            TPReadService.tpListInfo(0).then(r => {
                Wait.dismiss()
                try {
                    console.log(r.data)
                    this.setState({
                        tp_drawings:r.data.result
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }


    componentDidMount() {
        this.loadData()
        this.loadSurveyors()
    }
    handleChangeTp= event =>{
        this.setState({"drawingNumber":event.label})
    }
    saveInstruction(){
        try {
            Wait.show("Saving Instructions...")
            let instruction={
                areaType:this.state.areaType,
                drawingNumber:this.state.drawingNumber,
                instructionNumber:'',
                instructionTo:this.state.location,
                instructionType:this.state.instructionType,
                needSurvey: this.state.needSurvey,
                subject:this.state.subject,
                surveyFor:this.state.surveyFor,
                surveyOf:this.state.surveyOf,
                surveyType:this.state.surveyType,
                lacalityId:1,//locality
                surveyorType:this.state.surveyorType,
                registeredSurveyorId:this.state.registeredSurveyorId,
                transactionId:TransactionPopup.id(),
                numberOfParcel:this.state.numberOfParcel}
                console.log("instruction dto", instruction)
            SMDCreateService.addSurveyInstructions(instruction).then(r => {
                Wait.dismiss()
                try{
                    if (r.data.status===1){
                        SurveyInstructionPane.loadData()
                        PopDetail.dismiss()
                    }else{
                        alert(r.data.message)
                    }
                }catch (e) {

                }
            })
            //surveyInstructionId , instructionNumber

        }catch (e) {
            console.log(e)
        }
    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return(<div className={'col col-sm-12'}>


            <table style={{width:'100%'}}>
                <tbody>
                <tr style={{color:'#888'}}>
                    <td colSpan={3}>TP Drawing Number</td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <GeneralSelect onChanges={this.handleChangeTp} parameters={options} />
                    </td>

                </tr>
                <tr style={{color:'#888'}}>
                    <td>Area Type</td>
                    <td>InstructionType</td>
                    <td>Survey Type</td>

                </tr>
                <tr>
                    <td><select name={'areaType'} style={{width:'100%'}} onChange={this.handleChange}>
                        <option>- Select Area Type -</option>
                        <option>Rural</option>
                        <option>Urban</option>
                    </select></td>
                    <td>
                        <input style={{width:'100%'}} name={'instructionType'} onChange={this.handleChange}/>
                    </td>
                    <td>
                        <select name={'surveyType'} style={{width:'100%'}} onChange={this.handleChange}>
                            <option>- Select Survey Type -</option>
                            <option>Plot</option>
                            <option>Farm</option>
                            <option>Unit</option>
                            <option>Village Boundary</option>
                        </select>
                    </td>
                </tr>
                <tr style={{color:'#888'}}>
                    <td>Number of Parcel</td>
                    <td>Subject</td>
                    <td>Survey For</td>
                </tr>
                <tr>
                    <td><input style={{width:'100%'}}  name={'numberOfParcel'} onChange={this.handleChange} type={'number'}/></td>
                    <td><input style={{width:'100%'}} name={'subject'} onChange={this.handleChange}/></td>
                    <td><input style={{width:'100%'}}  name={'surveyFor'} onChange={this.handleChange}/></td>
                </tr>
                <tr style={{color:'#888'}}>
                    <td>Location</td>
                    <td>Surveyor Type</td>
                    <td>Registered Surveyor</td>
                </tr>
                <tr>
                    <td><input readOnly={true} name={'location'} onChange={this.handleChange}/></td>

                    <td>
                        <select name={'surveyorType'} style={{width:'100%'}} onChange={this.handleChange}>
                            <option>- Select Surveyor Type -</option>
                            <option value={"Government"}>Government</option>
                            <option value={"Private"}>Private</option>
                        </select>
                    </td>
                    <td>
                        <select name={'registeredSurveyorId'} style={{width:'100%'}} onChange={this.handleChange}>
                            <option>- Select Registered Number -</option>
                            {this.state.surveyors.map((d,i)=>{
                                return(<option key={i} value={d.id}>{d.name}</option>)
                            })}
                        </select>
                    </td>
                </tr>
                </tbody>
            </table>


            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.saveInstruction}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>

        </div>)
    }

    loadSurveyors() {
        try {
            Wait.show('Loading...')
            let type='prof_type_surveyor'
            SMDReadService.listProfessionals(type).then(r=>{
                Wait.dismiss()
                try {
                    this.setState({
                        surveyors:r.data.result
                    })

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
}
