
import * as React from "react";
import TransactionPopup from "../transaction_popup";
import Wait from "../../wait";
import TxReadService from "../../../../services/transaction/transaction_read";
import PopDetail from "../../popdetail";
import TxCreateService from "../../../../services/transaction/transaction_create";
export default class ComputeRent extends React.Component{
    static self = null
    constructor(props){
        super(props);
        this.state = {
            landProperties: {},
            rent: [],
            showMenu: true,
            gepgMenu: true,
            base64: '',
            partialAmount:0,

        }

        this.sendToGepg=this.sendToGepg.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.sendPartialBill=this.sendPartialBill.bind(this);
        this.createPayment=this.createPayment.bind(this);
        this.generateBatchInvoice=this.generateBatchInvoice.bind(this);
        ComputeRent.self=this

    }


    componentDidMount() {
        /* console.log(Properties.rentData(),"THIS IS UNFAIR ")
         if(Properties.rentData()) {
             this.setState({rent: Properties.rentData()})
         }*/

    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }
    render() {
        return(<div>
            {/*{this.state.showMenu?
                <div>
                    <span onClick={PopDetail.dismiss()} className={'fa fa-times-circle'} style={{marginRight:'24px',fontSize:'22px',color:'#f43',cursor:'pointer',float:'right'}}/>
                    <button className={'fa fa-external-link'} onClick={()=>this.sendToGepg()}>Submit to GePG</button>
                    <button>Check GePG Status</button>
                </div>:<div>{this.state.showMenu}</div>}*/}


            <div>
                <span onClick={()=>PopDetail.dismiss()} className={'fa fa-times-circle'} style={{marginRight:'24px',fontSize:'22px',color:'#f43',cursor:'pointer',float:'right'}}/>
                <button className={'fa fa-external-link'} onClick={()=>this.sendToGepg()}>Submit to GePG</button>
                <button>Check GePG Status</button>
            </div>

            <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                <thead>
                <tr>
                    <th>FINANCIAL YEAR</th>
                    <th>LAND RENT RATE</th>
                    <th>LAND COMPUTATION TYPE</th>
                    <th>TOTAL AREA</th>
                    <th>LAND RENT</th>
                    <th>ARREARS</th>
                    <th>AMOUNT DUE</th>
                </tr>
                </thead>
                <tbody>
                {this.props.rent.map((d, i) => {
                    return(
                        <tr key={i}>
                            <td>{d.rentFinancialYear}</td>
                            <td>{d.rentRate}</td>
                            <td>{d.landrentcomputationtype}</td>
                            <td>{d.legalArea}</td>
                            <td>{d.landRent}</td>
                            <td>{d.rentArrears}</td>
                            <td>{d.amountDue}</td>
                        </tr>)})
                }

                </tbody>
            </table>
        </div>)
    }
    sendPartialBill(){
        let dataParam={
            partialAmount:this.state.partialAmount,
            transactionId:TransactionPopup.id(),
            baUnitId:null
        }
        try {
            Wait.show("Creating Partial Bill ...")
            TxCreateService.createPartialBill(dataParam).then(r => {
                Wait.dismiss()
                try {
                console.log("Partial Bill",r)

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }

    }

    sendToGepg() {
        try {
            Wait.show("Submitting to GEPG ...")
            TxReadService.sendBillToGepg(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        rent:r.data.bill,
                        gepgMenu:true

                    })

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    createPayment() {
        try {
            Wait.show("Generate Bill ...")
            TxReadService.createBillPayment(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        rent:r.data.bill,
                        gepgMenu:true

                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    generateBatchInvoice() {
        try {
            Wait.show("Generate Invoice ...")
            TxReadService.generateRentInvoice(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        base64:r.data.base64,

                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
}
