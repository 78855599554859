import API from "../../API";

export default class ReportCreateService {

    static getExcel(url) {

        return API.ax.get(url, {
            responseType: 'blob',
            'Content-Type': 'application/octet-stream', // or Content-type: "application/vnd.ms-excel"
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            },
            onDownloadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            }
        }).catch((e)=>{console.log(e);})
    }

    static pendingDetails(formData) {

        return API.ax.post(`report/create/pending/details`,formData, {
            responseType: 'blob',
            'Content-Type': 'application/octet-stream', // or Content-type: "application/vnd.ms-excel"
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            },
            onDownloadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            }
        }).catch((e)=>{console.log(e);})
    }

    static pendingDetailsSummary(formData) {
        return API.ax.post(`report/create/pending/summary`,formData, {
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            },
            onDownloadProgress: function(progressEvent) {

                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)

            }
        }).catch((e)=>{console.log(e);})
    }

    static completedTaskByUser(formData) {
        return API.ax.post(`report/create/completed/by/user`,formData, {
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            },
            onDownloadProgress: function(progressEvent) {

                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)

            }
        }).catch((e)=>{console.log(e);})
    }


    static getUserManual() {
        return API.ax.get("report/system-user-manual").catch(e=>{console.log(e);})
    }

    static getGenericReport(param) {
        return API.ax.post("report/ilmis-report",param).catch(e=>{console.log(e);})
    }

    static fianceTransactionDetails(from,to) {
        return API.ax.get("report/recon/export/"+from+"/"+to).catch(e=>{console.log(e);})
    }
}
