import React from "react";
import PopDetail from "../../popdetail";
import PaneApplyRtuApproval from "../pops/pane_apply_rtu_approval";
import TransactionPopup from "../transaction_popup";
import RTReadService from "../../../../services/rt/rt_read";
import PaneAppRtuApprovalWithWarning from "../pops/pane_app_rtu_approval_with_warning";

class RtuApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            decision: 'Accepted',
            rtuSigningOfficer: '',
            applyBtn: <div></div>
        }
        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount() {

        RTReadService.getRtApproval(TransactionPopup.id()).then(d => {
            if (d.data.status === 1) {
                this.setState({ decision: d.data.result.rejectionType, rtuSigningOfficer: d.data.result.username })
            }
        })

        if (TransactionPopup.task() === 'Approve Final Documents') {
            this.setState({ applyBtn: <span><span style={{ marginLeft: '16px' }} className={'fa fa-plus-circle screen-action'} onClick={() => PopDetail.show('Apply Approve ', <PaneApplyRtuApproval decision={this.state.decision} />, 40)} /> Apply</span> })
        }
        if (TransactionPopup.task() === "RT Approve Certificate") {
            this.setState({ applyBtn: <span><span style={{ marginLeft: '16px' }} className={'fa fa-plus-circle screen-action'} onClick={() => PopDetail.show('Apply Approve ', <PaneAppRtuApprovalWithWarning decision={this.state.decision} />, 40)} /> Apply</span> })
            console.log("====================================================================================================")
        }
        if (TransactionPopup.task() === 'Approve Transaction') {
            this.setState({ applyBtn: <span><span style={{ marginLeft: '16px' }} className={'fa fa-plus-circle screen-action'} onClick={() => PopDetail.show('Apply Approve ', <PaneApplyRtuApproval decision={this.state.decision} />, 40)} /> Apply</span> })
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        return (<div>
            <div className={'row'}>
                <div className={'col-sm-4'}><h3>{TransactionPopup.task() === 'Approve Transaction' ? 'Approve Transaction' : 'RTU Registration Approval'}</h3></div>
                <div className={'col-sm-2'}>
                    <span> <span style={{ color: 'red' }}>*</span>{TransactionPopup.task() === 'Approve Transaction' ? 'Final SMD Decision' : 'Final RTU Decision'}</span>
                    <select name={"decision"} defaultValue={this.state.decision} onChange={this.handleChange}>
                        <option value={"Accepted"}>Accepted</option>
                        <option value={"Rejected"}>Rejected</option>
                    </select>
                </div>
                <div className={'col-sm-2'}>

                    <span>{TransactionPopup.task() === 'Approve Transaction' ? 'SMD Signing Officer' : 'RTU Signing Officer'}</span>
                    <input name={'rtuSigningOfficer'} value={sessionStorage.getItem('username')} readOnly={true} />
                </div>
                <div className={'col-sm-4'} style={{ float: 'left' }}>
                    <span>
                        <span style={{ marginLeft: '16px' }} className={'fa fa-check-circle-o screen-action'} onClick={this.validateTask} />Validate
                    </span>
                    {this.state.applyBtn}
                </div>
            </div>
        </div>)
    }
}
export default RtuApproval
