import * as React from "react";
import RTCreateService from "../../../../services/rt/rt_creat";
import TransactionPopup from "../transaction_popup";
import PropertyGRO from "../screen/gro/property_gro";
import PopDetail from "../../popdetail";
import WarningPane from "../screen/warningPane";
import PopDetailsTwo from "../../PopDetailsTwo";
import Wait from "../../wait";
import TxCreateService from "../../../../services/transaction/transaction_create";
import CompletePane from "../screen/complete";
import API from "../../../../services/API";


class PaneAppRtuApprovalWithWarning extends React.Component {
    constructor(props) {
        super(props);
        this.acceptRtuDecision=this.acceptRtuDecision.bind(this)
        this.approveRTU = this.approveRTU.bind(this)
        this.state = {
            selectedTask:{},
            warning:[]
        }
    }

    // static completeApproval(){
    //     return this.approveRTU();
    // }

    render() {
        return(<div>
            <div className={'row'}>
                <h3>By Clicking OK button you accept</h3>

                <button onClick={this.acceptRtuDecision}>OK</button>
            </div>
        </div>)
    }
    acceptRtuDecision(){
        console.log("Baunit property",PropertyGRO.self.state.baunitId)

        if (TransactionPopup.self.state.nextTask.options.length === 1) {
            console.log(TransactionPopup.self.state.nextTask.options[0])
            this.setState({
                selectedTask: TransactionPopup.self.state.nextTask.options[0]
            })
        }

            let txDefName = this.state.selectedTask.taskName
            let dat = {
                transactionId: TransactionPopup.id(),
                taskDefinitionId: TransactionPopup.transaction().txDefId,
                nextTask:txDefName
            }

            console.log("validation", dat)
            Wait.show("Approving....")
            TxCreateService.validatingTask(dat).then(r => {
                console.log("data Validation messages", r)
                Wait.dismiss()

                try {
                    if(r.data.warning.length>0) {
                        this.setState({warning: r.data.warning})
                        console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++",this.state.warning)
                        PopDetail.dismiss()

                        if (this.state.warning.length>0){
                            console.log("checking the task success")
                            PopDetailsTwo.show('Warning ',<WarningPane warnMessage={this.state.warning} approveHandler = {this.approveRTU}/>,70)
                        }
                    }
                    else {
                        this.approveRTU()
                    }
                }catch (e) {
                    console.log(e)
                }
                /* TransactionTable.loadData()
                 TransactionPopup.dismiss()*/
            })
    }

    approveRTU(){
        let datam={
            transactionId:TransactionPopup.id(),
            username:sessionStorage.getItem('username'),
            baunitId:PropertyGRO.self.state.baunit.baunitId,
            decision:this.props.decision,
            taskName:TransactionPopup.task(),
            taskId:TransactionPopup.taskId()

        }

        console.log("rtu approvals",datam)
        console.log("transaction Name ",TransactionPopup.state().transaction.name)
        if(TransactionPopup.state().transaction.name === "Title Data Maintenance"){
            console.log("Here is me")
            API.ax.post('/rt/create/approval',datam)
                .then(d=>{console.log(d.data)})
        }else{
            RTCreateService.saveRtuAcceptance(datam).then(d=>{
            })
        }
        PopDetail.dismiss()
        PopDetailsTwo.dismiss()
    }
}

export default PaneAppRtuApprovalWithWarning;