import * as React from "react";
import PaneAddDocuments from "../../../transacrions/pops/pane_add_documents";
import PopDetailsTwo from "../../../PopDetailsTwo";
export default class LegacyDocumentsPane extends React.Component{

    static self = null
    constructor(props) {
        super(props);
        this.state = {
            listOfDoc:[],
            listOfRequiredDoc:[],
            listOfOutgoingDoc:[],
            current_doc: {},
            doc_frame: <div>Data</div>
        }
        LegacyDocumentsPane.self = this
        this.showDocumentAt = this.showDocumentAt.bind(this)
        this.loadAttachedDocs = this.loadAttachedDocs.bind(this)
    }

    componentDidMount() {
       try {
           this.loadAttachedDocs()
       /* let doc= TransactionPopup.self.state.documents
           this.setState({
               listOfRequiredDoc:doc.listOfRequiredDoc,
               listOfOutgoingDoc:doc.listOfOutgoingDoc,
           })*/
       }catch (e) {
           console.log(e)
       }
    }
    static setState(state_name,state_value){ LegacyDocumentsPane.self.setState({ [state_name]:state_value})}
    static state(){ return LegacyDocumentsPane.self.state }

    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-3'} style={{height:'93vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'47%',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Attached Documents</b>
                        </div>
                        <div style={{width:'100%', marginTop:'8px'}}>
                            {this.state.listOfDoc.map((d,i)=>{
                                return(<div key={i} style={{width:'100%',cursor:'pointer'}} onClick={()=>this.showDocumentAt(d)}>
                                    <div><span className={'fa fa-file'} style={{marginRight:'8px'}}/>{d.documentName}</div>
                                    <div>{d.tag}</div>
                                </div>)
                            })}
                        </div>
                    </div>
                    <div style={{background:'#fff',padding:'24px',height:'47%',marginTop:'4px',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Required Document</b>
                        </div>
                        <div style={{width:'100%', marginTop:'8px'}}>
                            {this.state.listOfRequiredDoc!==null?this.state.listOfRequiredDoc.map((d,i)=>{
                                return(<div key={i} style={{width:'100%',cursor:'pointer'}}>
                                    <div><span className={'fa fa-file'} style={{marginRight:'8px'}}/>{d.documentName}</div>
                                    <div>{d.tag}</div>
                                </div>)
                            }):<div/>}
                        </div>
                    </div>

                </div>

                <div className={'col col-sm-6'} style={{height:'93vh',paddingRight:'4px',paddingLeft:0}}>

                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>

                        <div style={{height:'40px',lineHeight:'40px'}}>

                            <div style={{float:'right'}}>
                                <span>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'} onClick={()=>PopDetailsTwo.show('Add Document',<PaneAddDocuments/>,70)} />Add
                                </span>

                                <span>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-edit screen-action'}
                                      onClick={()=>{}} />Edit
                                </span>

                                <span>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-trash screen-action'}
                                      onClick={()=>{}} />Remove
                                </span>

                                <span>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-paperclip screen-action'}/>Attach
                                </span>

                                <span>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-paperclip screen-action'} onClick={()=>{}} />Detach
                                </span>

                            </div>
                        </div>
                        {this.state.doc_frame}

                    </div>

                </div>

                <div className={'col col-sm-3'} style={{height:'94vh',paddingLeft:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Properties</b>
                        </div>
                        <div style={{width:'100%', marginTop:'8px'}}>
                            <div>Document ID</div>
                            <div><input style={{width:'100%'}} defaultValue={this.state.current_doc.docId} readOnly={true}/></div>
                            <div>Document No</div>
                            <div><input style={{width:'100%'}} defaultValue={this.state.current_doc.docNo} readOnly={true}/></div>
                            <div>Document</div>
                            <div><input style={{width:'100%'}} defaultValue={this.state.current_doc.documentName} readOnly={true}/></div>
                            <div>Issued Date</div>
                            <div><input style={{width:'100%'}} defaultValue={this.state.current_doc.issuedDate} readOnly={true}/></div>
                            <div>
                                <div style={{width:'50%',float:'left'}}>Instrument</div>
                                <div style={{width:'50%',float:'left'}}>FD Number</div>
                            </div>
                            <div>
                                <div style={{width:'50%',float:'left'}}><input type={'checkbox'} defaultValue={this.state.current_doc.instrument} readOnly={true}/></div>
                                <div style={{width:'50%',float:'left'}}><input style={{width:'100%'}} defaultValue={this.state.current_doc.fdNo} readOnly={true}/></div>
                            </div>
                            <div>
                                <div style={{width:'50%',float:'left'}}>Estimated Pages</div>
                                <div style={{width:'50%',float:'left'}}>Scanned Pages</div>
                            </div>
                            <div>
                                <div style={{width:'50%',float:'left'}}><input style={{width:'98%'}} defaultValue={this.state.current_doc.estimated} readOnly={true}/></div>
                                <div style={{width:'50%',float:'left'}}><input style={{width:'100%'}} defaultValue={this.state.current_doc.scannedPages} readOnly={true}/></div>
                            </div>
                            <div>Description</div>
                            <div><textarea style={{width:'100%'}} rows={4} defaultValue={this.state.current_doc.description} readOnly={true}/></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>)
    }

    showDocumentAt(d) {
        console.log(d)
        setTimeout(()=>{
            this.setState({
                current_doc:d,
                doc_frame:<embed style={{height:'100%', width:'100%'}} src={"data:application/pdf;base64, "+d.base64}/>
            })
        },1000)
        this.setState({
            current_doc:'',
            doc_frame:<div></div>
        })
    }

    loadAttachedDocs(){
         /*TxReadService.getAttachedDocts(TransactionPopup.id()).then(d=>{
                console.log("MWakijule",d.data.listOfDoc)
               this.setState({listOfDoc:d.data.listOfDoc})
        })*/
    }
}
