import * as React from "react";
import CROStages from "./stages";
import PopDetail from "../../../popups/popdetail";
import TPReadService from "../../../../services/tp/tp_read";
import MobilePopup from "../../../popups/mobile/mobile_popup";
import KTab from "../../common/tab";
import TPCreateService from "../../../../services/tp/tp_create";
import TxReadService from "../../../../services/transaction/transaction_read";
import Wait from "../../../popups/wait";
import StageHandler from "../../../../componentss/stagehandler";
import DocumentService from "../../../../services/documents/documents";
import { SpatialMapDisplay } from "./mappane.js";
import PrintingLayout from "./printingLayout";
import TxCreateService from "../../../../services/transaction/transaction_create";
export function TownPlanTab() {
  const [activeStage, setActiveStage] = React.useState(0);
  const stages = ["Draft TP", "Approved TP", "Rejected TP"];
  const [tp, setTp] = React.useState([]);
  React.useEffect(() => {
    TxReadService.readTransactionsByProjectAndType(MobilePopup.id(), 69).then(
      (r) => {
        setTp(r.data);
      }
    );
  }, []);
  return (
    <div style={{}}>
      <div>
        <div>
          <button
            onClick={() => {
              PopDetail.show(
                "Add TP Approval Request",
                <TPPane newRequest={true} transactionId={0}/>
              );
            }}
          >
            Add TP Approval Request
          </button>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "20%" }}>
          <CROStages
            type={"tp"}
            changeStage={(prop) => {
              setActiveStage(prop);
            }}
          />
        </div>
        <div
          style={{
            width: "80%",
            maxHeight: window.outerHeight * 0.4,
            overflow: "scroll",
            fontSize: 13,
          }}
        >
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr
                style={{ position: "sticky", top: 0, backgroundColor: "#8f8" }}
              >
                <th>JOB Number</th>
                {/* <th>Created Date</th> */}
                {/* <th>Council</th> */}
                <th>Title</th>
                <th>Current Stage</th>
                <th>TP Drawing Number</th>
                {/* <th>Mtaa</th> */}
                <th>Date</th>
                <th>Created By</th>
              </tr>
            </thead>
            {tp.map((prop, key) => (
              <tr>
                <td
                  onClick={() => {
                    PopDetail.show(
                      "Transaction " + prop.transactionId,
                      <TPPane newRequest={false} transactionId={prop.transactionId} stage={prop.stage}/>
                    );
                  }}
                  style={{ fontWeight: "bold" }}
                >
                  {prop.transactionId}
                </td>
                <td>{prop.title}</td>
                <td>{stages[activeStage]}</td>
                <td>{prop.sp}</td>
                <td>{prop.date}</td>
                <td>{prop.createdBy}</td>
              </tr>
            ))}
            <tr></tr>
          </table>
        </div>
      </div>
    </div>
  );
}
export function TPRemarks({ txId }) {
  const [formData, setFormData] = React.useState();
  const onSubmitRemark = (e) => {
    e.preventDefault();
    TPCreateService.createRemark().then((r) => {
      PopDetail.show("");
    });
  };
  const AddRemark = ({ txId }) => (
    <div>
      <form onSubmit={onSubmitRemark}>
        <div className="row">
          <div className="col-md-6">
            <input type="hidden" value={txId} name="tpId" />
            <select
              onChange={(e) => {
                if (e.target.value == 6 || e.target.value == 38) {
                }
              }}
              className="select2"
              name="status"
              required
            >
              <option value={""}>Choose a remark</option>
              <option value={38}>Commissioner Approval</option>
              <option value={37}>Town Planning Approval</option>
              <option value={36}>Reject</option>
            </select>
          </div>
          <div className="col-md-6">
            <input type="file" name="attachment" />
          </div>
          <div className="col-md-12">
            <textarea id="comments" name="comments" required></textarea>
            <button className="btn">Save Remarks</button>
          </div>
        </div>
      </form>
    </div>
  );
  const [remarks, setRemarks] = React.useState([]);
  React.useEffect(() => {
    TPReadService.readRemarks(txId, "transaction").then((r) => {
      setRemarks(r.data);
    });
  }, []);
  return (
    <div>
      {/* <div style={{ backgroundColor: "#999" }}>
                <button className="btn" onClick={() => { PopDetail.show("Add Remark", <AddRemark />) }}>Add Remark</button>
            </div> */}
      <div>
        <table className="table table-striped table-bordered">
          <thead>
            <th>Remark Description</th>
            <th>User</th>
            <th>Remarked on</th>
          </thead>
          <tbody>
            {remarks.map((prop) => (
              <tr>
                <td>{prop.description}</td>
                <td>{prop.createdBy}</td>
                <td>{prop.dateCreated}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function CreateTP() {
  const [, forceUpdate] = React.useState();
  const [regions, setRegions] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [councils, setCouncils] = React.useState([]);
  const [wards, setWards] = React.useState([]);
  const [streets, setStreets] = React.useState([]);
  const [hamlets, setHamlets] = React.useState([]);
  React.useEffect(() => {
    TxReadService.getRegions().then((r) => {
      setRegions(r.data.result);
    });
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    var formEl = document.forms.createtpform;
    var data = new FormData(formEl);
    data.append("acceptedBy", "kronix");
    data.append("transactionId", 1);
    data.append("tpDrawingId", "");
    data.append("adjoiningTPDrawingNumber", "");
    data.append("scale", "");
    data.append("dataSource", "");
    data.append("lotType", "");
    data.append("blockNumber", "");
    data.append("qualityCheckedBy", "");
    data.append("qualityCheckedDate", "");
    data.append("approvedNumber", "");
    data.append("basemap", "");
    data.append("approvedBy", "");
    data.append("approvedNumber", "");
    data.append("approvedDateDateStr", "");
    data.append("checkedByRas", "");
    data.append("amendedTransactionId", "");
    data.append("tpDrawingNumber", "");
    // data.append("localityId",")
    data.append("mtaaId", data.get("subWardId"));

    Wait.show("Creating drawing");
    TPCreateService.createNewTP(data).then((r) => {
      Wait.dismiss();
    });
  };
  return (
    <div>
      <form id="createtpform" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-12">
            <input type="hidden" value={"new"} name="typeOfPlan" />
            <label>
              <b>Title</b>
              <br />
            </label>
            <input
              required
              type="text"
              name="title"
              placeholder="Title"
              className="form-control mb-3"
            />
          </div>

          <div className="col-md-12">
            <label>
              <b>TP Location</b>
              <br />
            </label>
          </div>
          <div className="col-md-4">
            <label>
              <b>Region</b>
              <br />
            </label>
            <select
              id="regionId"
              required
              name="regionId"
              onChange={(e) => {
                TxReadService.getDistrictsByRegId(e.target.value).then((r) => {
                  setDistricts(r.data.result);
                });
              }}
              className="form-control mb-3"
              placeholder="Region"
            >
              <option value={""}>Choose region</option>
              {regions.map((prop, key) => (
                <option value={prop.regionId}>{prop.regionName}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label>
              <b>District</b>
              <br />
            </label>
            <select
              required
              onChange={(e) => {
                TxReadService.getCouncilsByDistrictId(e.target.value).then(
                  (r) => {
                    setCouncils(r.data.result);
                  }
                );
              }}
              name="districtId"
              className="form-control mb-3"
              placeholder="District"
            >
              <option value={""}>Choose district</option>
              {districts.map((prop) => (
                <option value={prop.id}>{prop.names}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label>
              <b>Council</b>
              <br />
            </label>
            <select
              required
              onChange={(e) => {
                TxReadService.getWardsByCouncilId(e.target.value).then((r) => {
                  setWards(r.data.result);
                });
              }}
              name="councilId"
              className="form-control mb-3"
              placeholder="District"
            >
              <option value={""}>Choose Council</option>
              {councils.map((prop) => (
                <option value={prop.councilId}>{prop.councilName}</option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label>
              <b>Ward</b>
              <br />
            </label>
            <select
              required
              name="wardId"
              onChange={(e) => {
                TxReadService.getSubwardsByWardId(e.target.value).then((r) => {
                  setStreets(r.data.result);
                });
              }}
              className="form-control mb-3"
              placeholder="Ward"
            >
              <option value={""}>Choose ward</option>
              {wards.map((prop) => (
                <option value={prop.wardId}>{prop.wardName}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label>
              <b>Village/Mtaa</b>
              <br />
            </label>

            <select
              required
              name="subWardId"
              className="form-control mb-3"
              placeholder="Street"
              onChange={(e) => {
                TxReadService.getHamletsByWardId(e.target.value).then((r) => {
                  setHamlets(r.data.results);
                });
              }}
            >
              <option value={""}>Choose Village/Mtaa/</option>
              {streets.map((prop) => (
                <option value={prop.subWardId}>{prop.name}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label>
              <b>Hamlet</b>
              <br />
            </label>

            <select
              required
              name="localityId"
              className="form-control mb-3"
              placeholder="Locality"
            >
              <option value={""}>Choose Hamlet/</option>
              {hamlets.map((prop) => (
                <option value={prop.subWardId}>{prop.name}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <input type="hidden" value={"new"} name="typeOfPlan" />
            <label>
              <b>Block</b>
              <br />
            </label>
            <input
              required
              type="text"
              name="block"
              placeholder="Block"
              className="form-control mb-3"
            />
          </div>
          <div className="col-md-12"></div>
          <div className="col-md-4">
            <label>
              <b>Prepared by</b>
              <br />
            </label>
            <input
              required
              type="text"
              name="preparedBy"
              placeholder="Prepared by"
              className="form-control mb-3"
            />
          </div>
          <div className="col-md-4">
            <label>
              <b>Designed by</b>
              <br />
            </label>
            <input
              required
              type="text"
              name="designedBy"
              placeholder="Designed by"
              className="form-control mb-3"
            />
          </div>
          <div className="col-md-4">
            <label>
              <b>Checked by</b>
              <br />
            </label>
            <input
              required
              type="text"
              name="checkedBy"
              placeholder="Checked by"
              className="form-control mb-3"
            />
          </div>
          <div className="col-md-4">
            <label>
              <b>Supervised by</b>
              <br />
            </label>
            <input
              required
              type="text"
              name="supervisor"
              placeholder="Supervised by"
              className="form-control mb-3"
            />
          </div>
          <div className="col-md-12">
            <button className="btn" type="submit">
              Save Request
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default function TPPane({transactionId,stage,newRequest}){
  const [transaction,setTransation] = React.useState(0);
  const [curstage,setStage] = React.useState(0);
  const [isNew,setNew] = React.useState(newRequest);
  const [,forceUpdate] = React.useState();
  const [tp,setTp] = React.useState(null);
  React.useEffect(()=>{
    if(isNew){
      TxCreateService.createTransaction({
        txDefinition: 69,
        isNew: true,
        projectId: MobilePopup.id()
      })
      .then((r) => {
        setTransation(r.data.txId)
        setStage(r.data.stage);
        setNew(false)
      });
    }
    else{
      setStage(stage)
      setTransation(transactionId)
    }
    forceUpdate({})
  },[]);
  const updateTpData = (prop) =>{
    setTp(prop)
  }
  return(
    <div>
      {transaction!==0 && curstage!==0 ? <KTab
          heads={[
            "Process",
            "Spatial Unit",
            "Printing Layout",
            "Attachments",
            "Remarks",
          ]}
          body={[
            <StageHandler
              // newRequest={newRequest}
              // transactionCode={69}
              data={tp}
              updateData={updateTpData}
              curStage={curstage}
              transactionId={transaction}
            />,
            <SpatialMapDisplay
              txId={transaction}
              sptype={"tp"}
              type="tp"
            />,
            <PrintingLayout data={tp} txId={transaction}  sptype={"tp"}
            type="tp"/>,  
            <Documents txId={transaction} />,
            <TPRemarks txId={transaction} />,
          ]}
      />:null}
    </div>
  )
}
export function Documents({ txId }) {
  const [documents, setDocuments] = React.useState([]);

  const [docType, setDocType] = React.useState("");
  const [doc, setDoc] = React.useState("");
  React.useEffect(() => {
    DocumentService.readTxDocuments(txId)
      .then((r) => {
        setDocuments(r.data);
      })
      .catch((e) => {});
  }, []);
  return (
    <div>
      <table className="table table-striped table-bordered table-hover ktable">
        <thead>
          <th>File Name</th>
          <th>Created Date</th>
          <th>Action</th>
        </thead>
        <tbody>
          {documents.map((prop) => (
            <tr>
              <td>
                <b>{prop.name}</b>
              </td>
              <td>{prop.date}</td>
              {/* <td>{prop.user}</td> */}
              <td>
                <ActionButtons docId={prop.id} docname={prop.name} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
function ActionButtons({ viewDoc, docId, docname }) {
  const [doc, setDoc] = React.useState("");
  const [showDoc, setShowDoc] = React.useState(false);
  const downloadDoc = () => {
    if (doc.length < 60) {
      Wait.show("Downloading the Document please wait");
      DocumentService.readDoc(docId).then((r) => {
        setDoc(r.data);
        setShowDoc(true);
        var a = document.createElement("a");
        a.href = "data:image/png;base64," + r.data;
        a.download = docname + ".pdf";
        a.click();
        Wait.dismiss();
      });
    }
  };
  return (
    <div>
      {showDoc && <DocumentsPreview updateDoc={setShowDoc} baseDoc={doc} />}
      <button
        onClick={() => {
          Wait.show("Loading the Document please wait");
          DocumentService.readDoc(docId).then((r) => {
            setDoc(r.data);
            setShowDoc(true);
            Wait.dismiss();
          });
        }}
        className="btn btn-info"
      >
        <i className="fa fa-eye"></i>
        View
      </button>
      <button onClick={downloadDoc} className="btn btn-info">
        <i className="fa fa-download"></i>&nbsp;Download
      </button>
      {/* <a href={`data:application/pdf;base64,${doc}`} className="btn btn-info" download></a> */}
    </div>
  );
}
function DocumentsPreview({ baseDoc, updateDoc }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflow: "auto",
      }}
    >
      <div style={{ height: 40, padding: 5 }}>
        <div style={{ float: "right" }}>
          <button
            className="btn btn-danger"
            onClick={() => {
              updateDoc(false);
            }}
          >
            Close Document
          </button>
        </div>
      </div>
      <embed
        src={`data:application/pdf;base64, ${baseDoc}`}
        style={{ height: "95%", width: "100%" }}
      />
    </div>
  );
}
