import * as React from "react";
import RTCreateService from "../../../../services/rt/rt_creat";
import TransactionPopup from "../transaction_popup";
import PropertyGRO from "../screen/gro/property_gro";
import PopDetail from "../../popdetail";
import API from "../../../../services/API";
import Message from "../screen/gro/person/message";
import Wait from "../../wait";

export default class PaneApplyRtuApproval extends React.Component {
    constructor(props) {
        super(props);
        this.acceptRtuDecision = this.acceptRtuDecision.bind(this)
    }

    render() {
        return (<div>
            <div className={'row'}>
                <h3>By Clicking OK button you accept</h3>
                <button onClick={this.acceptRtuDecision}>OK</button>
            </div>
        </div>)
    }
    acceptRtuDecision = () => {

        console.log("TUUU kkkkk APpVAL")
        let datam = {
            transactionId: TransactionPopup.id(),
            username: sessionStorage.getItem('username'),
            baunitId: TransactionPopup.state().transaction.name === "Update Spatial Information" ? null : PropertyGRO.self.state.baunit.baunitId,
            decision: this.props.decision,
            taskName: TransactionPopup.task(),
            taskId: TransactionPopup.taskId()

        }

        if (TransactionPopup.state().transaction.name ==="Lease Right of Occupancy") {
            API.ax.post('/rt/create/approval-lease', datam)
                .then(d => {
                    console.log(d.data)

                })
        }

        console.log("rtu vvvvv approval", datam)
        if (TransactionPopup.state().transaction.name === "Title Data Maintenance") {

            API.ax.post('/rt/create/approval', datam)
                .then(d => { console.log(d.data) })
        } else if (TransactionPopup.state().transaction.name === "Rectification by LAD") {
            console.log("Here is me")
            API.ax.post('/rt/create/rtApproval/second-registration', datam)
                .then(d => { console.log(d.data) })
        } else if (TransactionPopup.state().transaction.name === "Rectification by LAD") {
            console.log("Here is me")
            API.ax.post('/rt/create/rtApproval/second-registration', datam)
                .then(d => { console.log(d.data) })
        } else if (TransactionPopup.state().transaction.name === "Transfer Right of Occupancy") {
            console.log("Here is me")
            API.ax.post('/rt/create/rtApproval/second-registration', datam)
                .then(d => { console.log(d.data) })
        } else if (TransactionPopup.state().transaction.name ==="Lease Right of Occupancy") {
            API.ax.post('/rt/create/approval-lease', datam)
                .then(d => { console.log(d.data) })
        } else if (TransactionPopup.state().transaction.name ==="TIC Lease Right of Occupancy") {
            console.log("Here is TIC Lease")
            API.ax.post('/rt/create/approval-lease', datam)
                .then(d => { console.log(d.data) })
        } else if (TransactionPopup.state().transaction.name === "Lease Right of Occupancy" ||
            TransactionPopup.state().transaction.name === "TIC Lease Right of Occupancy" ||
            TransactionPopup.state().transaction.name === "Withdraw of Deposit" ||
            TransactionPopup.state().transaction.name === "Caveat" ||
            TransactionPopup.state().transaction.name === "Notice of Deposit"
        ) {

            API.ax.post('/rt/create/approval-lease', datam)
                .then(d => { console.log(d.data) })
        }
        else if (TransactionPopup.state().transaction.name === "Update Spatial Information") {
            Wait.show("Aproving ...")
            API.ax.post('smd/create/registration', datam)
                .then(d => { Wait.dismiss();console.log(d.data) })
                .catch(err => {Wait.dismiss();console.error(err)})
        } else {
            RTCreateService.saveRtuAcceptance(datam).then(d => {
                console.log("RTU Approval results", d)
                if (d.data.Status === 1) {
                    console.log("status Yes", d)
                }
                else if (d.data.Status === 0) {
                    PopDetail.show('Critical Reminder ', <Message faIcon={'harzad'} message={d.data.critical} />, 60)
                    console.log("status no", d)
                }
                else {
                    console.log("Fail to Approve", d)
                }
            })
        }
        PopDetail.dismiss()
    }
}
