import * as React from "react";
import PopDetail from "../../popdetail";
import AddSurveyInstructionPane from "./add/add_surver_instruction";
import AddLoughCoordinate from "./add/add_rough_coordinate";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import PaneUploadCompRates from "../pops/pane_upload_comp_rates";

export default class CompensationRatesUpload extends React.Component{
    static self =null
    constructor(props) {
        super(props);
        this.state = {
            rates:[]
        }
        CompensationRatesUpload.self=this
    }

    render() {
        return(<div >

            <div className={'row'} >

                <div className={'col col-sm-12'} style={{height:'94vh'}}>
                    <div style={{background:'#fff',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{textAlign:'right'}}>
                             <span  style={{marginLeft:'16px',cursor:'pointer'}}>
                                <span className={'fa fa-check-circle screen-action'} />Approve
                            </span>
                            <span  style={{marginLeft:'16px',cursor:'pointer'}}>
                                <span className={'fa fa-times-circle screen-action'}  onClick={()=>PopDetail.show('Upload Csv Compensation Rates',<PaneUploadCompRates/>)}/>Upload
                            </span>
                        </div>

                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>
                            <tr>
                                <th>
                                        <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>

                                </th>
                                <th>ID</th>
                                <th>ITEM</th>
                                <th>CATEGORY</th>
                                <th>UNIT OF MEASURE</th>
                                <th>VALUE</th>
                                <th>PERCENT</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.rates.map((d,i)=>{
                                return(<tr key={i}>
                                    <td>
                                        <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                                    </td>
                                    <td>{d.id}</td>
                                    <td>{d.item}</td>
                                    <td>{d.category}</td>
                                    <td>{d.unitMeasure}</td>
                                    <td>{d.value}</td>
                                    <td>{d.percent}</td>

                                </tr>)
                            })}
                            </tbody>
                        </table>


                    </div>
                </div>



            </div>



        </div>)
    }


}