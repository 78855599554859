import React, {useEffect, useState} from 'react'
import {useForm} from "react-hook-form";
import PopDetail from "../popdetail";
import API from "../../../services/API";
import Wait from "../wait";
import logo from "../../../../resource/img/nembo.png";
import Common from "../../../utils/common";
import PopDetailsTwo from "../PopDetailsTwo";

export const PasswordResetRequest=({user})=>{
    const[error,errorSet] = useState('')
    const{handleSubmit,register}= useForm()
    const submit = data =>{
        console.log("data ",data)
        Wait.show("Password Reset ...")

        API.ax.post("auth/password-reset-request",data)
            .then(d=>{
                Wait.dismiss()
                console.log("the respose ",d.data)
                if(d.data.status === 1) {
                    sessionStorage.clear();
                    window.location.reload();
                }
                else{
                    errorSet(d.data.message)
                }
            })
            .catch(e=>{
                console.log(e);
                Wait.dismiss()
            }
            )
        //sessionStorage.clear();
        //window.location.reload();
    }
    return(
        <form onSubmit={handleSubmit(submit)}>
            <h3>{error}</h3>
            <table style={{width:'100%'}}>
                <tbody>
                <tr>
                    <td><label><span style={{color:"red"}}>*</span>Username</label></td>
                </tr>
                <tr>
                    <td><input name={"userName"} defaultValue={user} readOnly={true} type={"text"} style={{width:"100%"}} ref={register({required:true})}/></td>
                </tr>
                <tr>
                    <td><label><span style={{color:"red"}}>*</span>Check No</label></td>
                </tr>
                <tr>
                    <td><input name={"checkNumber"} type={"number"} style={{width:"100%"}}  ref={register({required:true})}/></td>
                </tr>
                <tr>
                    <td><label><span style={{color:"red"}}>*</span>Phone Number</label></td>
                </tr>
                <tr>
                    <td><input name={"phoneNumber"} type={"number"} style={{width:"100%"}}  ref={register({required:true})}/></td>
                </tr>
                <tr>
                    <td>
                        <button className={"default"} style={{float:"right", width:'200px',marginTop:'.5rem'}} onClick={()=>PopDetail.dismiss()}>Cancel</button>
                        <button style={{float:"right", width:'200px',marginTop:'.5rem'}} type={"submit"}>Submit</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    )
}

export const PasswordReset=({user})=>{
    const[password, passwordSet] = useState('')
    const[password2, password2Set] = useState('')
    const[message, messageSet] = useState('')
    const[error, errorSet] = useState('')
    const[message2, message2Set] = useState('')
    const[isValid, isValidSet] = useState(false)
    const[show, showSet] = useState(false)
    let regularExpression  = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    const{handleSubmit,register}= useForm()

    useEffect(()=>{
        if(password.length > 7 && password.length<17){
            if( !regularExpression.test(password)) {
                messageSet("The Password Should contain alphanumeric characters and a special character")
                isValidSet(false)
            }else {
                messageSet('')
                isValidSet(true)

            }
        }else if(password.length === 0){
            messageSet('')
        }
        else {
            messageSet("Password Length should be between 8 and 16")
            isValidSet(false)
        }
    },[password])

    useEffect(()=>{
        if( password !== password2) {
            message2Set("Password mismatch")
            isValidSet(false)
        }else if(password === password2) {
            if( regularExpression.test(password2)){
                message2Set('')
                isValidSet(true)
            }
        }

    },[password,password2])

    useEffect(()=>{
        if(isValid && regularExpression.test(password2)){
            showSet(true)
        }else{
            showSet(false)
        }
    },[isValid])

    const submit = data =>{
        showSet(false)
        Wait.show("Changing Password ...")
        API.reset(data)
            .then(d=>{
                Wait.dismiss()
                if(d.data.status === 0){errorSet(d.data.message)}
                else if(d.data.status === 1){errorSet(d.data.message);}
            })
            .catch(e=> {
                console.log(e);
                Wait.dismiss()
            })
        console.log("data ",data)
    }
    return(
        <form onSubmit={handleSubmit(submit)}>
            <h4>{error}</h4>
            <table style={{width:'100%'}}>
                <tbody>
                <tr>
                    <td><label><span style={{color:"red"}}>*</span>Username</label></td>
                </tr>
                <tr>
                    <td><input name={"userName"} defaultValue={user} readOnly={true} type={"text"} style={{width:"100%"}}  ref={register({required:true})}/></td>
                </tr>
                <tr>
                    <td><label><span style={{color:"red"}}>*</span>Check No</label></td>
                </tr>
                <tr>
                    <td><input name={"checkNumber"} type={"number"} style={{width:"100%"}}  ref={register({required:true})}/></td>
                </tr>
                <tr>
                    <td><label><span style={{color:"red"}}>*</span>PIN</label></td>
                </tr>
                <tr>
                    <td><input name={"pin"} type={"number"} style={{width:"100%"}} ref={register({required:true})}/></td>
                </tr>
                <tr>
                    <td><label><span style={{color:"red"}}>*</span>Password</label></td>
                </tr>
                <tr>
                    <td>
                        <input name={"password"} type={"password"} style={{width:"100%"}} onChange={(e)=>passwordSet(e.target.value)} ref={register({required:true,pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/})}/>
                        <span style={{color:"red"}}>{message}</span>
                    </td>
                </tr>
                <tr>
                    <td><label><span style={{color:"red"}}>*</span>Confirm Password</label></td>
                </tr>
                <tr >
                    <td>
                        <input type={"password"} style={{width:"100%"}}  onChange={(e)=>password2Set(e.target.value)}/>
                        <span style={{color:"red"}}>{message2}</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button className={"default"} style={{float:"right", width:'200px',marginTop:'.5rem'}} onClick={()=>PopDetail.dismiss()}>Cancel</button>
                        {show &&message2.length ===0 &&<button style={{float:"right", width:'200px',marginTop:'.5rem'}} type={"submit"}>Submit</button>}
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    )

}

export const LoginInside =({done})=>{
    const[message,messageSet] = useState('')
    const{handleSubmit,register}=useForm()
    Wait.dismiss()
    const submit = data => {
        API.loginAlso(data)
            .then(d => {
                console.log("data ", d.data)
                done = true;

                console.log(d)
                if (d.data.status === 1) {
                    sessionStorage.setItem('username', data.username);
                    sessionStorage.setItem('token', d.data.token);
                    sessionStorage.setItem('department', d.data.department);

                    Common.token = sessionStorage.getItem('token')

                } else if (d.data.status === 2) {
                    sessionStorage.clear();
                    window.location.reload();
                }
                //window.location.reload();
                PopDetailsTwo.dismiss()
            })
            .catch(e => {
                messageSet('Invalid Login, Try again')
            })
    }
    return(
        <form onSubmit={handleSubmit(submit)}>
            <table style={{width:'100%'}}>
                <tbody>
                <tr>
                    <td style={{padding:'2vh 0 1vh', textAlign:'center'}}>
                        <img src={logo} style={{height:'10vh'}} alt={'Logo'}/>

                        <p style={{color:'#000'}}><br/>
                            The Ministry of Lands,Housing and Human Settlements Developments
                        </p>
                        <h5>
                            <b>Tanzania ILMIS</b>
                        </h5>
                        <h6 style={{color:'red'}}> Your Session Has Expired, Please login to continue</h6>
                        <p style={{color:'#DF4A36'}}>{message}</p>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign:'center'}}>
                        <input type={'text'}  className={'input'} style={{width:'80%',textAlign:'center'}}
                               placeholder={'Username'} name={'username'} ref={register({required:true})} autoComplete={'off'}/>
                    </td>
                </tr>
                <tr style={{height:'4px'}}/>
                <tr>
                    <td style={{textAlign:'center'}}>
                        <input type={'password'} className={'input'} style={{width:'80%',textAlign:'center'}}
                               placeholder={'Password'} name={'password'}  ref={register({required:true})} autoComplete={'off'}/>
                    </td>
                </tr>
                <tr style={{height:'4px'}}/>
                <tr>
                    <td style={{paddingBottom:'4vh', textAlign:'center'}}>
                        <input className={'btn'} style={{padding:'6px',width:'250px'}} type={'submit'} value={'Login'}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    )
}