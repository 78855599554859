import React from "react";

const store ={
    useReducingFactor:false,
    landValueRate:0,
    forcedSaleValue:0,
    insurableValue:0,
    totalReducedFloorArea:0,
    landValue:0,
    totalCurrentMarketValue:0,
    notes:'',
    purposeOfValuation:'',
    propertyCategory:'',
    siteWorksFormula:'',
    unit:'',
    siteWorksType:'',
    landValueUseType:'',
    siteWorksPercentage:0,
    totalLandArea:0,
    forcedSalePercentage:0,
    totalAreaOfMainBuilding:0,
    constructionRate:0,
    landValueRange:0,
    insurablePercentage:0,
    valueOfMainBuilding:0,
    siteWorksValue:0,
    mbDepreciationPercentage:0,
    mbIncompletenessPercentage:0,
    totalValueOfOuterBuilding:0,
    currentReplacementCost:0,
    totalDepreciatedReplacementCost:0,
    adjustedTotalMarketValue:0,
    isFormulaType:false,
    isSiteWorkPercent:true,

    unexhaustedImprovementsAmount:0,
    marketRentalPerMonth:0,
    netProfit:0,
    profitMonths:0,
    otherValues:0,
    notesAllowance:'',
    unexhaustedImprovementsType:'',
    lostOfProfit:0,
    totalAllowance:0,
    noOfRooms:0,
    months:36,
    transportationAllowanceAmount:0,
    totalCropsValue:0,
    accommodationAllowance:0,
    disturbancePercentage:8,
    totalGraveCosts:0,
    disturbanceAllowance:0,
    totalCompensation:0,
    isReadOnly:false,

    valuationBuilding:{
        valuationBuildingsId:null,
        roof:'',
        accommodationRoomQty:'',
        ceiling:'',
        commercialRoomQty:'',
        walls:'',
        condition:'',
        floors:'',
        doors:'',
        windows:'',
        photoUrl:'',
        other:'',
        bathroomType:'',
        bedroomType:'',
        buildingAgeType:'',
        buildingName:'',
        buildingQuantityType:'',
        numberOfFloors:0,
        toiletType:'',
        wallFinishedType:''
    },
    valuationOuterBuildings:[],

    valuationPartyVu:[],
    valuationSpatialUnitVu:0,
    valuationGraves:[],
    valuationCrops:[],

}
const vStore ={
    valuations:[]
}
const BuildingStateContext = React.createContext()
const BuildingDispatchContext = React.createContext()
const ValuationStateContext = React.createContext()
const ValuationDispatchContext = React.createContext()
function buildingReducer(state, action) {
    switch (action.type) {
        case 'building': {
            const localState={...state}
            const buildingCopy ={...localState.valuationBuilding}
            buildingCopy[action.field]=action.payload
            return {
                ...localState,
                valuationBuilding:buildingCopy
            }
        }
        case 'outBuildings': {
            return {
                ...state,
                valuationOuterBuildings:[...state.valuationOuterBuildings,action.payload]
            }
        }
        case 'party': {
            return {
                ...state,
                valuationPartyVu:[...state.valuationPartyVu,action.payload]
            }
        }
        case 'spUnit': {
            return {
                ...state,
                valuationSpatialUnitVu:action.payload
            }
        }
        case 'grave': {
            return {
                ...state,
                valuationGraves:[...state.valuationGraves,action.payload]
            }
        }
        case 'crop': {
            return {
                ...state,
                valuationCrops:[...state.valuationCrops,action.payload]
            }
        }
        case 'purposeOfValuation': {
            return {
                ...state,
                purposeOfValuation:action.payload
            }
        }
        case 'propertyCategory': {
            return {
                ...state,
                propertyCategory:action.payload
            }
        }
        case 'siteWorksFormula': {
            return {
                ...state,
                siteWorksFormula:action.payload
            }
        }
        case 'siteWorksPercentage': {
            return {
                ...state,
                siteWorksPercentage:action.payload
            }
        }
        case 'totalLandArea': {
            return {
                ...state,
                totalLandArea:action.payload
            }
        }
        case 'landValueRange': {
            return {
                ...state,
                landValueRange:action.payload
            }
        }
        case 'forcedSaleValue': {
            return {
                ...state,
                forcedSaleValue:action.payload
            }
        }
        case 'insurablePercentage': {
            return {
                ...state,
                insurablePercentage:action.payload
            }
        }
        case 'valueOfMainBuilding': {
            return {
                ...state,
                valueOfMainBuilding:action.payload
            }
        }
        case 'siteWorksValue': {
            return {
                ...state,
                siteWorksValue:action.payload
            }
        }
        case 'landValueUseType': {
            return {
                ...state,
                landValueUseType:action.payload
            }
        }
        case 'insurableValue': {
            return {
                ...state,
                insurableValue:action.payload
            }
        }
        case 'mbDepreciationPercentage': {
            return {
                ...state,
                mbDepreciationPercentage:action.payload
            }
        }
        case 'mbIncompletenessPercentage': {
            return {
                ...state,
                mbIncompletenessPercentage:action.payload
            }
        }
        case 'totalReducedFloorArea': {
            return {
                ...state,
                totalReducedFloorArea:action.payload
            }
        }
        case 'totalValueOfOuterBuilding': {
            return {
                ...state,
                totalValueOfOuterBuilding:action.payload
            }
        }
        case 'marketRentalPerMonth': {
            return {
                ...state,
                marketRentalPerMonth:action.payload
            }
        }
        case 'landValue': {
            return {
                ...state,
                landValue:action.payload
            }
        }
        case 'currentReplacementCost': {
            return {
                ...state,
                currentReplacementCost:action.payload
            }
        }
        case 'totalDepreciatedReplacementCost': {
            return {
                ...state,
                totalDepreciatedReplacementCost:action.payload
            }
        }
        case 'totalCurrentMarketValue': {
            return {
                ...state,
                totalCurrentMarketValue:action.payload
            }
        }
        case 'adjustedTotalMarketValue': {
            return {
                ...state,
                adjustedTotalMarketValue:action.payload
            }
        }
        case 'notes': {
            return {
                ...state,
                notes:action.payload
            }
        }
        case 'constructionRate': {
            return {
                ...state,
                constructionRate:action.payload
            }
        }
        case 'unit': {
            return {
                ...state,
                unit:action.payload
            }
        }
        case 'totalAreaOfMainBuilding': {
            return {
                ...state,
                totalAreaOfMainBuilding:action.payload
            }
        }
        case 'forcedSalePercentage': {
            return {
                ...state,
                forcedSalePercentage:action.payload
            }
        }
        case 'siteWorksType': {
            return {
                ...state,
                siteWorksType:action.payload
            }
        }
        case 'landValueRate': {
            return {
                ...state,
                landValueRate:action.payload
            }
        }
        case 'useReducingFactor': {
            return {
                ...state,
                useReducingFactor:action.payload
            }
        }
        case 'isFormulaType': {
            return {
                ...state,
                isFormulaType:action.payload
            }
        }
        case 'isSiteWorkPercent': {
            return {
                ...state,
                isSiteWorkPercent:action.payload
            }
        }


        case 'unexhaustedImprovementsAmount':{
            return {
                ...state,
                unexhaustedImprovementsAmount:action.payload
            }
        }
        case 'netProfit':{
            return{
                ...state,
                netProfit:action.payload
            }
        }
        case 'profitMonths':{
            return{
                ...state,
                profitMonths:action.payload
            }
        }
        case 'otherValues':{
            return{
                ...state,
                otherValues:action.payload
            }
        }
        case 'notesAllowance':{
            return{
                ...state,
                notesAllowance:action.payload
            }
        }
        case 'unexhaustedImprovementsType':{
            return{
                ...state,
                unexhaustedImprovementsType:action.payload
            }
        }
        case 'lostOfProfit':{
            return{
                ...state,
                lostOfProfit:action.payload
            }
        }
        case 'totalAllowance':{
            return{
                ...state,
                totalAllowance:action.payload
            }
        }
        case 'noOfRooms':{
            return{
                ...state,
                noOfRooms:action.payload
            }
        }
        case 'months':{
            return{
                ...state,
                months:action.payload
            }
        }
        case 'transportationAllowanceAmount':{
            return{
                ...state,
                transportationAllowanceAmount:action.payload
            }
        }
        case 'totalCropsValue':{
            return{
                ...state,
                totalCropsValue:action.payload
            }
        }
        case 'accommodationAllowance':{
            return{
                ...state,
                accommodationAllowance:action.payload
            }
        }
        case 'disturbancePercentage':{
            return{
                ...state,
                disturbancePercentage:action.payload
            }
        }
        case 'totalGraveCosts':{
            return{
                ...state,
                totalGraveCosts:action.payload
            }
        }
        case 'disturbanceAllowance':{
            return{
                ...state,
                disturbanceAllowance:action.payload
            }
        }
        case 'totalCompensation':{
            return{
                ...state,
                totalCompensation:action.payload
            }
        }
        case 'newState':{
            return action.payload
        }
        case 'reset':{
            return  store
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}
function valuationReducer( state, action) {
    switch (action.type) {
        case 'addValuations': {
            return {
                ...state,
                valuations:action.payload
            }
        }
        case 'reset':{
            return vStore
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}
function BuildingProvider({children}) {
    const [state, dispatch] = React.useReducer(buildingReducer, store)
    return (
        <BuildingStateContext.Provider value={state}>
            <BuildingDispatchContext.Provider value={dispatch}>
                {children}
            </BuildingDispatchContext.Provider>
        </BuildingStateContext.Provider>
    )
}
function ValuationProvider({children}) {
    const [state, dispatch] = React.useReducer(valuationReducer, vStore)
    return (
        <ValuationStateContext.Provider value={state}>
            <ValuationDispatchContext.Provider value={dispatch}>
                {children}
            </ValuationDispatchContext.Provider>
        </ValuationStateContext.Provider>
    )
}
function useBuildingState() {
    const context = React.useContext(BuildingStateContext)
    if (context === undefined) {
        throw new Error('useBuildingState must be used within a BuildingProvider')
    }
    return context
}
function useValuationState() {
    const context = React.useContext(ValuationStateContext)
    if (context === undefined) {
        throw new Error('useValuationState must be used within a ValuationProvider')
    }
    return context
}
function useBuildingDispatch() {
    const context = React.useContext(BuildingDispatchContext)
    if (context === undefined) {
        throw new Error('useBuildingDispatch must be used within a BuildingProvider')
    }
    return context
}
function useValuationDispatch() {
    const context = React.useContext(ValuationDispatchContext)
    if (context === undefined) {
        throw new Error('useValuationDispatch must be used within a ValuationProvider')
    }
    return context
}


export {BuildingProvider, useBuildingState, useBuildingDispatch, ValuationProvider, useValuationDispatch,useValuationState}