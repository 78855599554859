import * as React from "react";
import PropertyPane from "../screen/spatial-unit/propertyPane";
import Button from "react-bootstrap/Button";
import PopDetail from "../../popdetail";
import PopDetailsTwo from "../../PopDetailsTwo";
import TxCreateService from "../../../../services/transaction/transaction_create";
import TransactionPopup from "../transaction_popup";

export default class PaneSearchProperty extends React.Component{
    static self = null
    constructor(props) {
        super(props);

        this.state = {
            dataToDisplay:[],
            tenure:'',
            districtName:'',
            regionName:'',
            regPlanNumber:'',
            titleNumber:'',
            titleStatus:'Active',
            localityName:'',
            blockNumber:'',
            lotNumber:'',
            ldNumber:'',
            loNumber:'',
            registerFrom:'',
            registerTo:''
        }
        PaneSearchProperty.self = this
    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }

    pushProperty(d) {
console.log("Selected Data",d)
        PropertyPane.self.setState({
            property: d,
        })
        let data={
            baunitIds:d.baunitId,
            transactionId:TransactionPopup.id()
        }
        TxCreateService.createBaUnitTransaction(data).then(d=>{

        })

    }



    render() {
        return (<div style={{height: '100%', overflow: 'auto'}} className={'col col-12'}>
            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <table style={{width: '100%',marginTop:'32px'}} className={'tb'}>
                        <thead>
                        <tr>
                            <th><span>BAUNIT ID</span></th>
                            <th><span>TITLE NUMBER</span></th>
                            <th><span>RIGHT HOLDERS</span></th>
                            <th><span>DISTRICT</span></th>
                            <th><span>LOCATION</span></th>
                            <th><span>BLOCK</span></th>
                            <th><span>LOT NUMBER</span></th>
                            <th><span>USE</span></th>
                            <th><span>SELECT</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.dataToDisplay.map((d, i) => {

                            return (<tr key={i} >
                                <td>{d.baunitId}</td>
                                <td>{d.titleNumber}</td>
                                <td>{d.party}</td>
                                <td>{d.districtName}</td>
                                <td>{d.localityName}</td>
                                <td>{d.blockNumber}</td>
                                <td>{d.lotNumber}</td>
                                <td>{d.landUseName}</td>
                                <td><input type={'checkbox'} onClick={()=>this.pushProperty(d)}/></td>
                            </tr>)
                        })
                        }
                        <tr><Button onClick={PopDetailsTwo.dismiss}>Save</Button></tr>
                        </tbody>
                    </table>
                </div>


               {/* <br/>
                <div className={'col col-sm-12'}>

                    <table style={{width: '100%',marginTop:'32px'}} className={'tb'}>
                        <thead>
                        <tr>
                            <th><span>REGISTRATION NO.</span></th>
                            <th><span>TITLE NUMBER</span></th>
                            <th><span>RIGHT HOLDERS</span></th>
                            <th><span>TENURE</span></th>
                            <th><span>LD NUMBER</span></th>
                            <th><span>ESTATE NAME</span></th>
                            <th><span>STATUS</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.dataToDisplay.map((d, i) => {

                            return (<tr key={i} onClick={this.pushProperty(d)}>
                                <td>{d.recordingNumber}</td>
                                <td>{d.titleNumber}</td>
                                <td>{d.party}</td>
                                <td>{d.tenureTypeName}</td>
                                <td>{d.ldNumber}</td>
                                <td>{d.number}</td>
                                <td>{d.statuscode}</td>

                            </tr>)
                        })
                        }
                        </tbody>

                    </table>
                    <hr/>
                    <div style={{textAlign:'right',width:'100%'}}>
                        <button style={{width:'100px'}} onClick={this.savePerson}>Save</button>
                        <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
                    </div>
                </div>*/}
            </div>
        </div>)

    }





    /*getProperties(){
        try {
            Wait.show("Loading Property...")
            TxCreateService.createGetProperties({
                titleNumber:this.state.titleNumber,
                titleStatus:'Active',
                location:this.state.location,
                block:this.state.block,
                number:this.state.number,
                ldNumber:this.state.ldNumber,
                rightHolder:this.state.rightHolder,
                registerFrom:this.state.registerFrom,
                registerTo:this.state.registerTo
            }).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        dataToDisplay:r.data.properties
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    */

}
