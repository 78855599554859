import * as React from "react";
import PopDetail from "../../popdetail";
import TxReadService from "../../../../services/transaction/transaction_read";
import PaneAddProject from "../../transacrions/pops/pane_add_project";
import MobilePopup from "../mobile_popup";
import PaneAddSignatory from "../pops/pane_add_signatory";
import MobileReadService from "../../../../services/mobile/mobile_read";
import PaneUploadExcel from "../pops/pane_upload_excel";
import Wait from "../../wait";
import MobileDeleteService from "../../../../services/mobile/mobile_delete";
import CommonCreateService from "../../../../services/common/common_create";
import ReportCreateService from "../../../../services/common/reports/report_create";

export default class MobileSummaryPane extends React.Component{

    static self = null

    constructor(props) {
        super(props);
        this.state = {
            signatories:[],
            layers:[],
            payments:[],
            parameters: {},
            projects:[],
            downloads:[]
        }
        /*{name:'Mobile Application',fileType:'.apk',description:'Android Mobile Application',path:'http://154.118.227.28/pakua/ilmis.apk'},
                {name:'Payment Details',fileType:'.xls',description:'Project Payment Details',path:API.base_url+'mobile/read/download/payments/'+MobilePopup.id()},*/
        /*{name:'Area Shapefile',fileType:'.SHP',description:'Polygon',path:''},
        {name:'PAPs',fileType:'.EXCEL',description:'PAPs Name',path:''},
        {name:'Buildings Shapefile',fileType:'.SHP',description:'Polygon',path:''},
        {name:'Crops Shapefile',fileType:'.SHP',description:'Polygon',path:''},
        {name:'Grave Shapefile',fileType:'.SHP',description:'Polygon',path:''},*/

        MobileSummaryPane.self = this

        this.showProjectInfo = this.showProjectInfo.bind(this)
        this.download = this.download.bind(this)
        this.popAddProject = this.popAddProject.bind(this)
    }

    static setState(state_name,state_value){
        try {
            MobileSummaryPane.self.setState({ [state_name]:state_value})
            //TransactionPopup.self.setState({ [state_name]:state_value})
        }catch (e) {
            //console.log(e)
        }
    }

    static state(){
        return MobileSummaryPane.self.state
    }

    componentDidMount() {
        MobileSummaryPane.loadprojectList()
    }

    download(d){

        if(d.name==='Land Rent Values'){
            //alert(d.link+'/'+MobilePopup.id())
            Wait.show('Downloading...')
            try {
                ReportCreateService.getExcel('mobile/read/download/payments/'+MobilePopup.id()).then(r => {
                    try{
                        Wait.dismiss()
                        const url = window.URL.createObjectURL(new Blob([r.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Payment Details.xls'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }catch (e) {
                        console.log(e)
                    }
                })
            }catch (e) {
                console.log(e)
            }
            return
        }
        PopDetail.download(d.name==='Land Rent Values'?d.link+'/'+MobilePopup.id():d.link)
    }

    upgradePayments(){
        PopDetail.show('Upload Payments Excel',<PaneUploadExcel/>,20)
    }

    confirmDelete(id){
        try {
            Wait.show('Removing...')
            MobileDeleteService.deleteSignatory(id).then(_ => {
                Wait.dismiss()
                PopDetail.dismiss()
                MobileSummaryPane.loadprojectList()
            })
        }catch (e) {
            console.log(e)
        }
    }

    noHandler(){
        PopDetail.dismiss()
    }

    deleteSignatory(sign){
        PopDetail.confirm('Delete '+sign.design,sign.name+' will be removed',this.confirmDelete,sign.id,this.noHandler)
    }

    render() {
        return(
            this.props.output!=='LAD Legacy'?
            <div>
                <div className={'row'} style={{marginBottom:'4px'}}>

                    <div className={'col col-sm-6'} style={{height:'47vh',overflow:'auto',paddingRight:'4px'}}>

                        <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                            <div style={{height:'40px',lineHeight:'40px'}}>
                                <b>Available Project</b>
                            </div>

                            <table style={{width:'100%'}}>
                                <tbody>
                                <tr style={{color:'#888'}}>
                                    <td colSpan={3}>Project No</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>

                                        <select id={'sp_projects'} style={{width:'90%'}} onChange={(e)=>this.showProjectInfo(e)}>
                                            <option value={0}>-Select Project-</option>
                                            {this.state.projects.map((d,i)=>{
                                                return(<option key={i} value={d.id}> {d.name} - {d.location}</option>)
                                            })}
                                        </select>

                                        <span onClick={()=>this.popAddProject()} className={'fa fa-plus-circle'}
                                              style={{display:MobilePopup.state().project.is_new===true?'inherit':'none',float:'right',cursor:'pointer',fontSize:'24px',color:'#008b8b'}}/>
                                    </td>
                                </tr>


                                </tbody>
                            </table>

                            <br/>
                            <div>
                                <div style={{height:'40px',lineHeight:'40px'}}>
                                    <b>Parameters</b>
                                </div>

                                <table style={{width:'100%'}}>
                                    <tbody>
                                    <tr style={{color:'#888'}}>
                                        <td>Project Name</td>
                                        <td style={{width:'150px'}}>Target</td>
                                    </tr>
                                    <tr>
                                        <td><input defaultValue={this.state.parameters.name} style={{width:'100%'}} readOnly={true}/></td>
                                        <td><input defaultValue={this.state.parameters.pap} type={'number'} style={{width:'100%'}} readOnly={true}/></td>
                                    </tr>
                                    <tr style={{color:'#888'}}>
                                        <td>Acquiring Authority</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}><input defaultValue={this.state.parameters.authority} style={{width:'100%'}} readOnly={true}/></td>
                                    </tr>
                                    <tr style={{color:'#888'}}>
                                        <td>Project Location</td>

                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <textarea style={{width:'100%'}} readOnly={true} defaultValue={this.state.parameters.location}></textarea>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div className={'col col-sm-6'} style={{height:'47vh',paddingLeft:'4px'}}>
                        <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                            <div style={{height:'40px',lineHeight:'40px'}}>
                                <b>Required Signatories</b>

                                <div style={{float:'right',display:MobilePopup.id()===undefined?'none':'inherit'}}>
                                    <span>
                                        <span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'}
                                              onClick={()=>PopDetail.show('Add Signatory',<PaneAddSignatory/>,60)} />New
                                    </span>
                                    <span>
                                        <span style={{marginLeft:'16px'}} className={'fa fa-search screen-action'}
                                              onClick={()=>{}} />Search
                                    </span>
                                </div>
                            </div>

                            <div style={{width:'100%',height:'90%',overflow:'auto'}}>

                                <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                                    <thead>
                                    <tr>
                                        <th>FULL NAME</th>
                                        <th>DESIGNATION</th>
                                        <th>ACTION</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.signatories.map((d,i)=>{
                                        return(<tr key={i}>
                                            <td><b>{d.name}</b></td>
                                            <td>{d.design} - {d.locality}</td>
                                            <td>
                                                <button className={'default'} style={{float:'right',width:'60px'}} onClick={()=>this.deleteSignatory(d)}>Delete</button>
                                                <button className={'default'} style={{float:'right',width:'60px'}}>Edit</button>
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>

                            </div>



                        </div>
                    </div>

                </div>
                <div className={'row'} style={{marginTop:'4px'}}>

                    <div className={'col col-sm-6'} style={{height:'37vh',paddingRight:'4px'}}>
                        <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                            <div style={{height:'40px',lineHeight:'40px'}}>
                                <b>Downloads</b>

                                <div style={{float:'right'}}>
                                    <span style={{cursor:'pointer'}}>
                                        <span style={{marginLeft:'16px'}} className={'fa fa-download screen-action'}/> Download All
                                    </span>

                                </div>

                            </div>

                            <div style={{width:'100%',height:'100%',overflow:'auto'}}>
                                <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                                    <thead>
                                    <tr>
                                        <th>OUTPUT NAME</th>
                                        <th>FILE TYPE</th>
                                        <th>FILE DESCRIPTION</th>
                                        <th>ACTION</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.downloads.map((d,i)=>{
                                        return(<tr key={i}>
                                            <td><b style={{color:'darkcyan'}}>{d.name}</b></td>
                                            <td>{d.type}</td>
                                            <td>{d.description}</td>
                                            <td>
                                                <button title={d.link} style={{float:'right'}} onClick={()=>this.download(d)}>Download</button>
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>

                    <div className={'col col-sm-6'} style={{height:'37vh',paddingLeft:'4px'}}>
                        <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                            <div style={{height:'40px',lineHeight:'40px'}}>
                                <b>Payments</b>

                                <div style={{float:'right'}}>
                                    <span style={{cursor:'pointer'}} onClick={this.upgradePayments}>
                                        <span style={{marginLeft:'16px'}} className={'fa fa-save screen-action'}/> Save
                                    </span>
                                    <span style={{cursor:'pointer'}} onClick={this.upgradePayments}>
                                        <span style={{marginLeft:'16px'}} className={'fa fa-upload screen-action'}/> Upgrade Excel
                                    </span>

                                </div>

                            </div>

                            <div style={{width:'100%',height:'90%',overflow:'auto'}}>
                                <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                                    <thead>
                                    <tr>
                                        <th style={{position:'sticky',left:0,zIndex:2}}>Description</th>
                                        {this.state.layers.map((d,i)=>{
                                            return(<th key={i}>{d.label}</th>)
                                        })}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.payments.map((d,i)=>{
                                        return(<tr key={i}>
                                            <td style={{position:'sticky',left:0,zIndex:1,background:'#fff',boxShadow:'0 2px 10px #888'}}>
                                                <b><span style={{padding:'8xp'}} className={'fa fa-angle-right'}/>{d.description}</b>
                                            </td>
                                            {d.blocks.map((b,j)=>{
                                                return(
                                                    <td style={{textAlign:'right',color:b.amount!=='.00'?'#484848':'#f00'}} key={j}>
                                                        {b.amount}
                                                        <input type={'number'} defaultValue={b.amount} style={{display:'none'}}/>
                                                    </td>)
                                            })}
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                </div>
            </div>:
                <div>
                    <div className={'row'} style={{marginBottom:'4px'}}>

                        <div className={'col col-sm-12'} style={{height:'94vh',paddingRight:'4px'}}>

                            <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                                <div style={{height:'40px',lineHeight:'40px'}}>
                                    <b>Available Project</b>
                                </div>

                                <table style={{width:'100%'}}>
                                    <tbody>
                                    <tr style={{color:'#888'}}>
                                        <td colSpan={3}>Project No</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>

                                            <select id={'sp_projects_legacy'} style={{width:'90%'}} onChange={(e)=>this.showProjectInfo(e)}>
                                                <option value={0}>-Select Project-</option>
                                                {this.state.projects.map((d,i)=>{
                                                    return(<option key={i} value={d.id}> {d.name} - {d.location}</option>)
                                                })}
                                            </select>

                                            <span onClick={()=>this.popAddProject()} className={'fa fa-plus-circle'} style={{float:'right',cursor:'pointer',fontSize:'24px',color:'#008b8b'}}/>
                                        </td>
                                    </tr>


                                    </tbody>
                                </table>

                                <br/>
                                <div>
                                    <div style={{height:'40px',lineHeight:'40px'}}>
                                        <b>Parameters</b>
                                    </div>

                                    <table style={{width:'100%'}}>
                                        <tbody>
                                        <tr style={{color:'#888'}}>
                                            <td>Project Name</td>
                                            <td style={{width:'150px'}}>Initial Payment</td>
                                        </tr>
                                        <tr>
                                            <td><input defaultValue={this.state.parameters.name} style={{width:'100%'}} readOnly={true}/></td>
                                            <td><input defaultValue={this.state.parameters.pap} type={'number'} style={{width:'100%'}} readOnly={true}/></td>
                                        </tr>
                                        <tr style={{color:'#888'}}>
                                            <td>Acquiring Authority</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><input defaultValue={this.state.parameters.authority} style={{width:'100%'}} readOnly={true}/></td>
                                        </tr>
                                        <tr style={{color:'#888'}}>
                                            <td>Project Location</td>

                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <textarea style={{width:'100%'}} readOnly={true} defaultValue={this.state.parameters.location}></textarea>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                        <div className={'col col-sm-6'} style={{height:'47vh',paddingLeft:'4px'}}>
                            <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                                <div style={{height:'40px',lineHeight:'40px'}}>
                                    <b>Required Signatories</b>

                                    <div style={{float:'right'}}>
                                    <span>
                                        <span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'}
                                              onClick={()=>PopDetail.show('Add Signatory',<PaneAddSignatory/>,60)} />New
                                    </span>
                                        <span>
                                        <span style={{marginLeft:'16px'}} className={'fa fa-search screen-action'}
                                              onClick={()=>{}} />Search
                                    </span>
                                    </div>
                                </div>

                                <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                                    <thead>
                                    <tr>
                                        <th>FULL NAME</th>
                                        <th>PHONE</th>
                                        <th>LOCALITY</th>
                                        <th>DESIGNATION</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.signatories.map((d,i)=>{
                                        return(<tr key={i}>
                                            <td>{d.name}</td>
                                            <td>{d.phone}</td>
                                            <td>{d.locality}</td>
                                            <td>{d.design}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>)
    }

    static loadprojectList() {
        try {
            MobileSummaryPane.self.setState({
                projects:[],
                signatories:[],
                layers:[],
                payments:[],
                parameters: {},
                downloads:[]
            })


            if(MobilePopup.state().project.is_new===true){

                Wait.show('Loading Projects...')
                TxReadService.getProjects().then(r => {
                    Wait.dismiss()
                    //console.log(r.data)
                    try {
                        MobileSummaryPane.self.setState({
                            projects:r.data
                        })
                        let proj = r.data[0]
                        document.getElementById('sp_projects').value = proj.id
                        //document.getElementById('sp_projects').innerHTML = '<option>'+proj.name+'</option>'
                        MobileSummaryPane.cacheProject(proj)
                    }catch (e) {

                    }



                })

            }else{
                Wait.show('Reading Project Info...')
                TxReadService.getProjectInfo(MobilePopup.state().project.projectProjectId).then(r => {
                    Wait.dismiss()
                    try {
                        console.log(r.data)

                        document.getElementById('sp_projects').value = r.data.id
                        document.getElementById('sp_projects').innerHTML = '<option>'+r.data.name+'</option>'
                        MobileSummaryPane.cacheProject(r.data)
                    }catch (e) {
                        console.log(e)
                    }
                })


                try {
                    MobileReadService.readSignatories(MobilePopup.id()).then(r => {
                        try {
                            MobileSummaryPane.self.setState({signatories: r.data.results})
                        }catch (e) {
                        }
                    })
                }catch (e) {
                    console.log(e)
                }

                try {
                    MobileReadService.listDownloads(MobilePopup.id()).then(r => {
                        try {
                            MobileSummaryPane.self.setState({downloads: r.data.results})
                        }catch (e) {
                        }
                    })
                }catch (e) {
                    console.log(e)
                }
                try {
                    MobileReadService.readPaymentSummary(MobilePopup.id()).then(r => {
                        try {
                            console.log(r.data.results)
                            MobileSummaryPane.self.setState({payments: r.data.results})

                            let layers = []

                            r.data.results[0].blocks.forEach(l=>{
                                let ly = {
                                    id:l.lid,
                                    label:l.label
                                }
                                layers.push(ly)
                            })

                            MobileSummaryPane.self.setState({layers: layers})
                        }catch (e) {

                        }
                    })
                }catch (e) {
                    console.log(e)
                }
            }

        }catch (e) {
            console.log(e)
        }


    }

    showProjectInfo(e) {
        this.state.projects.forEach(r=>{
            if(r.id===parseInt(e.target.value)){
                MobileSummaryPane.cacheProject(r)
            }
        })
    }

    popAddProject() {
        this.setState({
            projects:[]
        })

        PopDetail.show('New Project',<PaneAddProject txId={0} source={'mobile'}/>,35)
    }

    static cacheProject(proj) {
        MobileSummaryPane.setState('parameters',{
            name:proj.name,
            pap:proj.pap,
            authority:proj.authority,
            location:proj.location
        })
    }
}