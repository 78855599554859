import axios from 'axios';
import React from 'react'
import jwt_decode from "jwt-decode";
import { LoginInside } from "../components/popups/uma/password-reset";
import Dashboard from "../components/containers/dashboard";
import PopDetailsTwo from "../components/popups/PopDetailsTwo";
//  ilmis napa
// const API_URL = 'http://172.29.21.27:8080/ilmis_back/api/'
//  ilmis test mwanza
// const API_URL = 'http://154.118.227.5555/api/'
// const API_URL = 'http://154.118.227.6363/api/'
// ilmis live mwanza
// const API_URL = "http://10.0.10.17:6363/";
const host = window.location.hostname //ilmis mwanza new
const API_URL = 'https://'+host+'/api/' //ilmis mwanza new
// const API_URL = 'htt+':8080/ilmis_back/api/' //ilmis mwanza new
// const API_URL = 'htt+':8080/ilmis_back/api/' //ilmis mwanza new
// const API_URL = 'http://10.2.81.12:8080/ilmis_back/api/' //ilmis mwanza new
// ilmis test
// const API_URL = 'http://154.118.227.28:6363/api/'
// const API_URL = 'http://154.118.227.28:6363/api/'
// ilmis test private
// const API_URL = 'http://10.0.10.113:6363/api/'
//martin
//const API_URL = 'http://10.0.10.118:6363/ilmis004/'
// ilmis live
// const API_URL = 'http://10.76.100.23:8080/ilmis_back/api/'
//local
// const API_URL = 'http://localhost:6363/api/'
// Local test Server
//  const API_URL = 'http://192.168.1.107:6363/api/'
// const decoded =sessionStorage.getItem("token")? jwt_decode(sessionStorage.getItem("token")):null;

const decoded = sessionStorage.getItem("token") ? jwt_decode(sessionStorage.getItem("token")) : null;
export default class API {
    static no_of_records = 50;
    //static geo_ilmis = 'http://154.118.227.28:8081/geoserver/ilmis'
    //static geo_ilmis = 'http://154.118.227.28:8081/geoserver/mpya' //ilmis test
    static geo_ilmis = 'https://'+host+'/geoserver/ilmis' //ilmis mwanza new
    // static geo_ilmis = 'http://10.76.100.24:8081/geoserver/ilmis' //jar live
    //static geo_ilmis = 'http://172.29.21.27:8081/geoserver/napa' //ilmis NAPA
    static base_url = API_URL
    static ax = axios

    static validToken() {
        const decoded = sessionStorage.getItem("token") ? jwt_decode(sessionStorage.getItem("token")) : null;
        const done = false
        if (decoded !== null) {
            if (Date.now() >= decoded.exp * 1000) {
                sessionStorage.clear();
                PopDetailsTwo.show("Session Expired", <LoginInside done={done} />, 60)
            }
        } else {
            console.log("decoder ", decoded)
            PopDetailsTwo.show("Session Expired", <LoginInside done={done} />, 60)
        }

    }
    static geo = axios.create({
        baseURL: `${API.geo_ilmis}`,
        timeout: 180000 * 3,
        headers: {
            'Authorization': sessionStorage.getItem("token")
        }
    });

    static ramani = axios.create({
        baseURL: `http://10.0.10.118:8000/api/file/create/`,
        timeout: 180000 * 3,
        headers: {
            'Authorization': sessionStorage.getItem("token")
        }
    });

    static login(usn, psw, bId) {
        return this.ax.post(`auth/login`, {
            'username': usn,
            'password': psw,
            'browserId': bId
        }, {
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log("Uploading..." + percentCompleted)
            },
            onDownloadProgress: progressEvent => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log("Downloading..." + percentCompleted)
            },
        }).catch(e => {
            console.log(e);
        })
    }

    static loginAlso(data) {
        return axios.post(`auth/login`, data, {})
            .catch(e => {
                console.log(e);
            })
    }
    static reset(data) {
        return axios.post(`auth/password-reset`, data, {})
            .catch(e => {
                console.log(e);
            })
    }

    static cancel() {
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSource.cancel();
    }
}


axios.defaults.baseURL = API_URL
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("token")
axios.defaults.headers.common['X-Auth'] = sessionStorage.getItem("x-auth")
axios.defaults.timeout.valueOf(86400000)
// Add a request interceptor


axios.interceptors.request.use(config => {
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    setTimeout(() => source.cancel('Timed out after 30s'), 86400000);
    return config;
});
/*
axios.interceptors.request.use(function (config) {
    //console.log('AX starting........')
    return config;
}, function (error) {
    //console.log('AX starting........'+error)
    return Promise.reject(error);
});
*/

axios.interceptors.response.use(function (response) {
    //console.log('AX Responding........'+JSON.stringify(response))
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //console.log('AX Responding........'+error)
    return Promise.reject(error);
});

function isTokenExpired(decoded) {
    const now = Date.now().valueOf() / 1000
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
        Dashboard.logout()
        return true
    }

}
