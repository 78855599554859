import * as React from 'react'
import CROStages from './stages'
import MobileReadService from '../../../../services/mobile/mobile_read';
import MobilePopup from '../../../popups/mobile/mobile_popup';
import DynamicForm from '../../../../componentss/formhandler';
import StageHandler from '../../../../componentss/stagehandler';
import TxReadService from '../../../../services/transaction/transaction_read';
import SMDReadService from '../../../../services/smd/smd_read';
export default function ParcelsPane({ layer }) {
    const [activeStage, setActiveStage] = React.useState(0);
    const [parcels, setParcels] = React.useState([]);
    const [currentPage,setCurrentPage] = React.useState(1);
    const [pages,setPages] = React.useState([]);
    const [noRecords,setNoRec] = React.useState(50);
    React.useEffect(() => {
        SMDReadService.listParcels(MobilePopup.id(),currentPage,noRecords).then((r)=>{
            let p =[]
            setParcels(r.data.results)
            for(let i =1; i<=r.data.pages;i++){
                p.push(i);
            }
            setPages(p);
        });
        // TxReadService.readTransactionsByProject(MobilePopup.id(), 69).then((r) => {

        // })
    }, [currentPage,noRecords])
    return (
        <div style={{ overflowY: "scroll", top: 50, left: 0, bottom: 50,height:window.innerHeight*0.7}}>
            {/* <StageHandler /> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "100%", fontSize: 10 }}>
                    <table className='table table-striped table-hover table-success table-bordered'>
                        <thead>
                            <tr style={{ position: "sticky", top: 0, backgroundColor: "#8f8" }}>
                                <th>ID</th>
                                <th>Plot number</th>
                                <th>Block</th>
                                <th>Locality</th>
                                <th>Use</th>
                                <th>Tp Drawing</th>
                                <th>Area</th>
                                <th>Plan number</th>
                                <th>Registered plan number</th>
                                <th>Fullname</th>
                                <th>Gender</th>
                                <th>Phone</th>
                                <th>ID no</th>
                                <th>Ownership Type</th>
                                <th>Surveyed by</th>
                                <th>Approved by</th>
                                <th>Approved Date</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                parcels.map((prop) => (
                                    <tr className='tb-30'>
                                        <td style={{ color: "#080", fontWeight: "bold" }}>{prop.id}</td>
                                        <td>{prop.plotNum}</td>
                                        <td>{prop.block}</td>
                                        <td>{prop.locality}</td>
                                        <td>{prop.use}</td>
                                        <td>{prop.tpNumber}</td>
                                        <td>{prop.planNumber}</td>
                                        <td>{prop.rpn}</td>
                                        <td>{prop.fullname}</td>
                                        <td>{prop.gender}</td>
                                        <td>{prop.phone}</td>
                                        <td>{prop.nin}</td>
                                        <td>{prop.ownership}</td>
                                        <td>{prop.surveyor}</td>
                                         <td>{prop.approvedBy}</td>
                                         <td>{prop.approvedDate}</td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div style={{height:40}}>

                    </div>
                    <div style={{position:"sticky",bottom:0,left:0,right:0}}>
                        <div className='row'>
                            <div className='col-2'></div>
                            <div className='col-2'>
                                <button disabled={currentPage===1} className='btn btn-block'>Previous Page</button>
                            </div>
                            <div className='col-2'>
                                <select onChange={(e)=>{setNoRec(e.target.value)}} className='form-control'>
                                    <option>Number of records</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                </select>
                            </div>
                            <div className='col-2'>
                                <select className='form-control' onChange={e=>{setCurrentPage(e.target.value)}}>
                                    <option value="Choose Page">Choose Page</option>
                                    {
                                        pages.map((prop)=>(
                                            <option value={prop}>Page {prop}</option>
                                            ))
                                        }
                                </select>
                            </div>
                            <div className='col-2'>
                                <button disabled={currentPage===pages.length-1} className='btn btn-block'>Next Page</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}