import React, {useEffect, useState} from 'react'
import {colourStyles} from "../../../../componentss/valuation-tabs/purpose";
import Select from "react-select";
import Header from "./gro/header";
import PopDetail from "../../popdetail";
import SpatialUnit from "./spatialUnit";
import {useForm} from "react-hook-form";
import API from "../../../../services/API";
import PopDetailsTwo from "../../PopDetailsTwo";
import Message from "./gro/person/message";
import TransactionPopup from "../transaction_popup";
import Wait from "../../wait";
import PropertyGRO from "./gro/property_gro";

const SearchResult =({data, refresh})=>{
    const[baUnitId, dataToSendSet] = useState()
    const[selected, selectedSet] = useState(-1)
    let transactionId = TransactionPopup.id()

    const addBaUnit = () =>{
        console.log("amigo ",baUnitId)
        console.log("transaction ",transactionId)
        let data ={baUnitId,transactionId}
        try {
            Wait.show("Saving Title ...")
            API.ax.post("rt/services/add-baunit", data)
                .then(d => {
                    Wait.dismiss()
                    let {result, message, status} = d.data
                    if (status === 1) {
                        console.log("data ", result)
                        PopDetail.dismiss()
                        refresh(true)
                    } else {
                        PopDetailsTwo.show("Error",<Message action={2} message={message} faIcon={"harzad"}/>,30)
                    }
                })
        }catch (e) {
            console.error(e)
        }
    }
    const highlight ={
        fontWeight:'bold',
        cursor:'pointer'
    }
    const unHighlight = {
        cursor:'pointer'
    }
    return(
        <>
        <div style={{
            height: '240px',
            padding: '.2rem',
            margin: '.2rem',
            overflow: 'auto',
            border: '1px solid #888'}}>
        <table style={{width:"100%"}} className={"tb"}>
            <thead>
                <tr>
                    <th>REGISTRATION NO</th>
                    <th>TITLE NUMBER</th>
                    <th>RIGHT HOLDER</th>
                    <th>TENURE</th>
                    <th>STATUS</th>
                    <th>LD NUMBER</th>
                </tr>
            </thead>
            <tbody>
            {data.map(({registrationNumber,titleNumber,tenure,status,rightHolder,baunitId },i) =>
            <tr style={selected === i?highlight:unHighlight} onClick={()=>{dataToSendSet(baunitId); selectedSet(i)}} key={i}>
                <td>{registrationNumber}</td>
                <td>{titleNumber}</td>
                <td>{rightHolder}</td>
                <td>{tenure}</td>
                <td>{status}</td>
                <td/>
            </tr>)}
            </tbody>
        </table>
            </div>
            {selected >-1?
         <div style={{display:"flex",flexDirection:"row-reverse"}}>
             <button className={"default"} onClick={PopDetail.dismiss} style={{ width:'150px', marginTop:'1rem', marginBottom:'.2rem'}}>Cancel</button>
             <button type={"submit"} onClick={addBaUnit} style={{ width:'150px', marginTop:'1rem', marginBottom:'.2rem'}}>Add</button>
         </div>:''
            }
       </>
    )
}
const Search =({refresh}) =>{
    const[selectedOption] = useState()
    const[tenureId,tenureSet] = useState({})
    const[present, presentSet] = useState(false)
    const[datum, datumset] = useState([])
    const{ handleSubmit,register}=useForm()
    const onChange = data =>{
        console.log("Tenure ",data)
        tenureSet(data)
    }
    const submit = data =>{
        console.log("data ",data)
        presentSet(false)
        let toSend
        if (tenureId.hasOwnProperty("value")){
              toSend ={...data,tenureTypeId:tenureId.value,transactionId:TransactionPopup.id()}
        }else{
            toSend ={...data,tenureTypeId:0,transactionId:TransactionPopup.id()}
        }
        Wait.show("Searching ...")
        API.ax.post("rt/services/search-title",toSend)
            .then(d=>{
                Wait.dismiss()
                let{result, status, message} = d.data
                if(status === 1 && result.length>0){
                    datumset(result)
                    presentSet(true)
                }
                else{
                 PopDetailsTwo.show("Error",<Message action={2} message={"No title Record Found"} faIcon={"harzad"}/>,30)
                }
                console.log("result ",d.data)
            })
    }

    return(
        <>
            <form onSubmit={handleSubmit(submit)}>
            <table style={{width:"100%"}}>
                <tbody>
                <tr>
                    <td>
                        <label>Title Number</label>
                    </td>
                    <td>
                        <label>Tenure</label>
                    </td>
                    <td>
                        <label>Right Holder</label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type={"text"} name={"titleNumber"} style={{width:"100%"}} ref={register}/>
                    </td>
                    <td>
                        <Select
                        value={selectedOption}
                        onChange={onChange}
                        options={options}
                        placeholder={"Select Tenure"}
                        styles ={colourStyles}
                    />
                    </td>
                    <td>
                        <input type={"text"} name={"rightHolder"} style={{width:"100%"}} ref={register}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>District</label>
                    </td>
                    <td>
                        <label>Location</label>
                    </td>
                    <td>
                        <label>Block</label>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type={"text"} name={"district"} style={{width:"100%"}} ref={register}/>
                    </td>
                    <td>
                        <input type={"text"} name={"location"} style={{width:"100%"}} ref={register}/>
                    </td>
                    <td>
                        <input type={"text"} name={"block"} style={{width:"100%"}} ref={register}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Lot Number</label>
                    </td>
                </tr>
                <tr>
                    <td><input type={"text"} name={"lotNumber"} style={{width:"100%"}} ref={register}/></td>
                </tr>
                <tr><td colSpan={3}><button type={"submit"} style={{float:'right', width:'150px', marginTop:'1rem', marginBottom:'.2rem'}}>Search</button></td></tr>
                </tbody>
            </table>
            </form>
            {present &&
                <SearchResult data={datum} refresh={refresh}/>
            }
        </>
    )//<PropertyGRO/>
}
let EditBaUnit = ({baUnit, refresh}) =>{
    const[selectedOption] = useState()
    const[tenureTypeId,tenureSet] = useState(0)
    const[done,doneSet] = useState(false)
    const[baUnitEntity,baUnitEntitySet] = useState({});
    const onChange = data =>{
        console.log("Tenure ",data)
        tenureSet(data.value)
    }
    const {register, handleSubmit} = useForm()

    const submit = data =>{
        Wait.show("Updating ...")
        const{ baUnitId } = baUnitEntity;
        let txId = TransactionPopup.id();
        let dataToSend ={}
        if(tenureTypeId === 0){
            dataToSend = {baUnitId,txId,tenureTypeId:baUnitEntity.tenureTypeId,...data}
        }else {
            dataToSend = {baUnitId,txId,tenureTypeId,...data}
        }
        API.ax.post('transaction/update-baunit',dataToSend)
            .then(d => {
                Wait.dismiss()
                const{ status, message } = d.data
                console.log("Status ",status)
                if( status === 1 ){
                    PopDetail.dismiss()
                    refresh(true)
                }else{
                    PopDetail.show("Error", <Message faIcon={"harzad"} message={message}/>,40)
                }

            })
            .catch(e=>{
                Wait.dismiss()
            })
    }
    useEffect(()=>{
        Wait.show("Fetching Details ...")
        API.ax.get(`transaction/update-baunit/${baUnit}`)
            .then(d=>{
                Wait.dismiss()
                const{status, result} = d.data
                if(status === 1){
                    baUnitEntitySet(result);
                    doneSet(true);
                }
            })
            .catch(e=>Wait.dismiss())
    },[])
    return(
    done && <form onSubmit={handleSubmit(submit)}>
            <table style={{width:'100%'}}>
                <tbody>
                    <tr>
                        <td><span style={{fontWeight:'bold'}}>Title Number</span></td>
                        <td><span style={{fontWeight:'bold'}}>LD Number</span></td>
                        <td><span style={{fontWeight:'bold'}}>LO Number</span></td>
                    </tr>
                    <tr>
                        <td><input type={'text'} name={'titleNumber'} defaultValue={baUnitEntity.titleNumber} style={{width:'100%', height:'38px'}} ref={register({required:true})}/></td>
                        <td><input type={'text'} name={'ldNumber'} defaultValue={baUnitEntity.ldNumber}  style={{width:'100%', height:'38px'}} ref={register({required:true})}/></td>
                        <td><input type={'text'} name={'loNumber'} defaultValue={baUnitEntity.loNumber}  style={{width:'100%', height:'38px'}} ref={register({required:true})}/></td>
                    </tr>
                    <tr>
                        <td><span style={{fontWeight:'bold'}}>Tenure</span></td>
                        <td><span style={{fontWeight:'bold'}}>Legal Area</span></td>
                        <td><span style={{fontWeight:'bold'}}>Legal Area Unit Type</span></td>
                    </tr>
                    <tr>
                        <td>
                            <Select
                                value={selectedOption}
                                onChange={onChange}
                                options={options}
                                placeholder={"Select Tenure"}
                                styles ={colourStyles}
                                defaultValue={baUnitEntity.defaultTenure}
                            /></td>
                        <td><input type={'number'} name={'legalArea'} defaultValue={baUnitEntity.legalArea} style={{width:'100%', height:'38px'}} ref={register({required:true})} step={'.01'}/></td>
                        <td>
                            <select name={'legalAreaUnittype'} defaultValue={baUnitEntity.legalAreaUnittype} style={{width:'100%', height:'38px'}} ref={register({required:true})}>
                                <option value={'ACRES'}>Acres</option>
                                <option value={'ARES'}>Ares</option>
                                <option value={'HA'}>Hectares</option>
                                <option value={'SQF'}>Square Feet</option>
                                <option value={'SQM'}>Square Meters</option>
                            </select>
                        </td>
                    </tr>
                <tr>
                    <td colSpan={3}>
                        <br/><button onClick={PopDetail.dismiss} style={{float:'right', width:'150px'}} className={"default"}>Cancel</button>
                        <button type={'submit'} style={{float:'right', width:'150px'}}>Save</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    )
}
export default function OfficialSearchProperty({writable}){
    let transactionId = TransactionPopup.id();
    const[titleData,titleDataSet] = useState({})
    const[isPresent, isPresentSet] = useState(false)
    const[baUnit,baUnitSet] = useState(0);
    const [spEdit, spEditSet] = useState(false)
    const[tpEdit, tpEditSet] = useState(false)
    const highlight ={
        fontWeight:'bold',
        cursor:'pointer'
    }
    const unHighlight = {
        cursor:'pointer'
    }
    const refresh = data =>{
        if(data === true){
            Wait.show("Refreshing ...")
            API.ax.get(`rt/services/title-details/${transactionId}`)
                .then(d=>{
                    Wait.dismiss()
                    console.log("result ",d.data);
                    let{result, status} = d.data
                    if(status === 1){
                        titleDataSet(result)
                        isPresentSet(true)
                    }
                })
                .catch(e=>Wait.dismiss())
        }
    }
    useEffect(()=>{
        let taskName = TransactionPopup.task()
        console.log("task Name",taskName)
        //Update Parcel Details
        if(taskName === 'Update Parcel Details'){
            spEditSet(true)
        }
        if(taskName === 'Update Land Use'){
            tpEditSet(true)
        }
        API.ax.get(`rt/services/title-details/${transactionId}`)
            .then(d=>{
                console.log("result ",d.data);
                let{result, status} = d.data
                if(status === 1){
                    titleDataSet(result)
                    isPresentSet(true)
                }
            })
    },[])
    return(
        <>
            <Header label={"Properties/Titles"} display={writable} >
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={baUnit > 0?()=>PopDetail.show("Edit Title Details",<EditBaUnit baUnit={baUnit}  refresh={refresh} />, 60):()=>{}}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{}}
                    /><strong> Remove</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-search'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={isPresent?()=>{}:()=>{PopDetail.show("Transaction Object",<Search refresh={refresh}/>,90)}}
                    /><strong> Search</strong>
                </div>
            </Header>
            <table style={{width:"100%"}} className={'tb'}>
                <thead>
                    <tr>
                        <th>TITLE NUMBER</th>
                        <th>TYPE</th>
                        <th>TENURE</th>
                        <th>LD NUMBER</th>
                        <th>TOTAL AREA</th>
                        <th>LAND RENT</th>
                        <th>TOTAL VALUE</th>
                        <th>STATUS</th>
                        <th>REGISTRATION NUMBER</th>
                    </tr>
                </thead>
                <tbody>
                <tr onClick={()=>baUnitSet(titleData.baUnitId)} style={ baUnit > 0 ? highlight: unHighlight }>
                    <td>{titleData.titleNumber}</td>
                    <td>{titleData.type}</td>
                    <td>{titleData.tenure}</td>
                    <td>{titleData.ldNumber}</td>
                    <td>{titleData.totalArea}</td>
                    <td>{titleData.landRent}</td>
                    <td>{titleData.totalValue}</td>
                    <td>{titleData.status}</td>
                    <td>{titleData.registrationNumber}</td>
                </tr>
                </tbody>
            </table>
            <br/>
            {isPresent && <SpatialUnit heading={false} spEdit={spEdit} tpEdit={tpEdit}/>}
            <div style={{display:'none'}}><PropertyGRO/></div>
        </>
    )
}
const options=[
    {
        label:"Right of Occupancy",
        value:1
    },
    {
        label:"Leasehold",
        value:2
    },
    {
        label:"Sublease",
        value:3
    },
    {
        label:"Unit Ownership",
        value:4
    },
    {
        label:"Customary Occupancy",
        value:5
    },
    {
        label:"Village",
        value:6
    },
]