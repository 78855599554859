import React from "react";
import PopDetail from "../../popdetail";
import PopNewIssues from "./add/add_new_issues";

export default class Decision extends React.Component{

    constructor(props) {
        super(props);
        this.checkDecision = this.checkDecision.bind(this)
    }

    checkDecision=(e)=>{
        if(e.target.value==='Reject'){
            this.showRejectPane()
        }
    }

    showRejectPane(){
        PopDetail.show('New Issues',<PopNewIssues/>,60)
    }

    render() {
        return(<div className={'row'}>

            <div className={'col col-sm-12'} style={{marginBottom:'4px'}}>

                <div className={''} style={{height:'87vh', overflow:'auto'}}>

                    <div style={{background:'#fff',height:'100%',paddingRight:'10px',paddingLeft:'20px',paddingTop:'5px',border:'0.5px solid #efefef'}}>


                        <table>
                            <tbody>
                            <tr>
                                <td style={{width:'18vw'}}>
                                    <div style={{height:'40px',lineHeight:'40px'}}>
                                        <b>* Decision</b>
                                    </div>
                                    <select onChange={this.checkDecision} name={'decision'}>
                                        <option>Accepted</option>
                                        <option>Reject</option>
                                    </select>
                                </td>
                                <td style={{width:'57vw'}}>
                                    <div style={{height:'40px',lineHeight:'40px'}}>
                                        <b>* Instructions of Authorized Land Surveyor</b>
                                    </div>
                                    <textarea style={{width:'57vw'}} rows={4}/>
                                </td>
                            </tr>
                            <tr style={{height:'24px'}}/>
                            <tr>
                                <td colSpan={2}>
                                    <div style={{float:'left'}}>
                                        <b>Rejection Reasons</b>
                                    </div>

                                    <div className={'span-btn'} onClick={this.showRejectPane}>
                                        <span className={'fa fa-plus'}/> New
                                    </div>
                                    <div className={'span-btn'}>
                                        <span className={'fa fa-edit'}/> Edit
                                    </div>
                                    <div className={'span-btn'}>
                                        <span className={'fa fa-trash'}/> Remove
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table className={'tb'} style={{width:'100%'}}>
                                        <thead>
                                        <tr>
                                            <th>DATE ADDED</th>
                                            <th>REASON</th>
                                            <th>DETAILS</th>
                                            <th>STATUS</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>


                        <div >

                        </div>
                    </div>
                </div>


            </div>


        </div>)
    }
}