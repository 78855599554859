import React from "react";
import MobileReadService from "../../../../../services/mobile/mobile_read";
import MobilePopup from "../../mobile_popup";
import Wait from "../../../wait";
import PopDetail from "../../../popdetail";
import MobileUpdateService from "../../../../../services/mobile/mobile_update";
import ProjectHamlets from "./project_hamlets";
import LocationMapperPane from "./location_mapper";

export  default class ProjectConfig extends React.Component{

    static self = null
    constructor(props) {
        super(props);

        this.state = {
            councils:[],
            dc_code:'',
            villages:[]
        }
        ProjectConfig.self = this
        this.showDC = this.showDC.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.updateCouncil = this.updateCouncil.bind(this)
        this.updateMapAt = this.updateMapAt.bind(this)
    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }

    componentDidMount() {
        ProjectConfig.loadVillages()
    }

    static loadVillages(){
        Wait.show('Loading...')
        MobileReadService.readProjectAdmin(MobilePopup.id()).then(r => {
            Wait.dismiss()
            try {
                let counc = r.data.results
                ProjectConfig.self.setState({
                    councils:counc,
                    dc_code:counc[0].code,
                    dc_total:counc[0].total,
                    villages:counc[0].villages
                })
            }catch (e) {
                console.log(e)
            }
        })
    }

    noHandler(){ PopDetail.dismiss() }
    updateNowVillage(data){
        try {
            let param = {
                id: data[0],
                name: data[1],
                code: data[2],
                meeting: data[3],
                slp:data[4]
            }
            MobileUpdateService.updateVillageAt(param).then( r =>{
                Wait.dismiss()
                try {
                    if (r.data.status===1){
                        PopDetail.dismiss()
                        ProjectConfig.loadVillages()
                    }else {
                        console.log(r.data)
                    }
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
    updateVillageAt(i,d){
        let name = document.getElementById('name'+i).value
        let code = document.getElementById('code'+i).value
        let meeting = document.getElementById('meeting'+i).value
        let slp = document.getElementById('slp'+i).value

        let data = [d.id,name,code,meeting,slp]



        PopDetail.confirm('Update Locality',d.name+' will be updated to '+'( '+name+', '+code+', '+meeting+' )',this.updateNowVillage,data,this.noHandler,20)
    }

    showDC(){
        let dc = parseInt(document.getElementById('sp_dc').value)



        if (dc > -1){
            let counc = this.state.councils[dc]

            console.log(this.state.councils)

            this.setState({
                dc_code:counc.code,
                dc_total:counc.total,
                villages:counc.villages
            })
        }
    }

    updateCouncil(){

        let dc = parseInt(document.getElementById('sp_dc').value)
        if (dc>-1){
            let counc = this.state.councils[dc]
            let id = counc.id
            let code = this.state.dc_code
            let total = this.state.dc_total

            try {
                Wait.show('Saving..')
                MobileUpdateService.updateCouncil({
                    id: id,
                    project_id: MobilePopup.id(),
                    code: code,
                    total: total
                }).then( r =>{
                    Wait.dismiss()
                    ProjectConfig.loadVillages()
                })
            }catch (e) {
                console.log(e)
            }
        }
    }

    updateMapAt(d,i){

        Wait.show('Updating...')
        MobileUpdateService.updateMapper({
            layer_id: d.id,
            mapped_id: document.getElementById('spmap_' + i).value
        }).then( r =>{
            Wait.dismiss()
            try{
                document.getElementById('spmap_'+i).style.display='none'
                ProjectConfig.loadVillages()
            }catch (e) {
                console.log(e)
            }
        })

    }

    render() {
        return(<div style={{padding:'24px',width:'100%',height:'80vh',overflow:'auto'}}>
            <table style={{width:'100%'}}>
                <tbody>
                <tr style={{border:'1px solid #cccccc',background:'#efefef'}}>
                    <td style={{paddingLeft:'24px'}}><b>Distict Council :</b></td>
                    <td style={{height:'48px'}}>
                        <select name={'dist_council'} style={{width:'100%',background:'#fff'}} onChange={()=>this.showDC()} id={'sp_dc'}>
                            <option value={-1}>- Select One -</option>
                            {this.state.councils.map((d,i)=>{
                                return(<option key={i} value={i}>{d.name}</option>)
                            })}
                        </select>
                    </td>

                    <td style={{paddingLeft:'24px'}}><b>District Code :</b></td>
                    <td>
                        <input type={'text'} name={'dc_code'} style={{width:'100%',background:'#fff'}} defaultValue={this.state.dc_code} onChange={this.handleChange}/>
                    </td>
                    <td style={{paddingLeft:'24px'}}><b>District Total Parcels :</b></td>
                    <td>
                        <input type={'number'} name={'dc_total'} style={{width:'40%',background:'#fff'}} defaultValue={this.state.dc_total} onChange={this.handleChange}/>
                        <button style={{float:'right',marginRight:'16px'}} onClick={()=>this.updateCouncil()}>Update</button>
                    </td>
                </tr>

                <tr>
                    <td colSpan={6}>
                        <table className={'tb2'}>
                            <thead>
                            <tr>
                                <th>Layer Name</th>
                                <th>Mapped To</th>
                                <th>Registration Code</th>
                                <th>Meeting Date</th>
                                <th>PO Box</th>
                                <th>Hamlets</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.villages.map((d,i)=>{
                                return(<tr key={i}>
                                    <td>
                                        <input style={{width:'100%'}} type={'text'} defaultValue={d.name} id={'name'+i}/>
                                    </td>
                                    <td>
                                        {d.mapped}
                                        <span className={'fa fa-angle-down'} onClick={()=>document.getElementById('spmap_'+i).style.display='inherit'}/>

                                        <select defaultValue={d.mapped_id} id={'spmap_'+i} style={{display:'none'}} onChange={()=>this.updateMapAt(d,i)}>
                                            <option value={0}>Choose Village/Street</option>
                                            {d.mapped_option.map((dd,i)=>{
                                                return(<option key={i} value={dd.subWardId}>{dd.name}
                                                </option>)
                                            })}

                                        </select>

                                    </td>
                                    <td>
                                        <input style={{width:'100%'}} type={'text'} defaultValue={d.code} id={'code'+i}/>
                                    </td>
                                    <td>
                                        <input style={{width:'100%'}} type={'date'} defaultValue={d.meeting_date} id={'meeting'+i}/>
                                    </td>
                                    <td>
                                        <input style={{width:'100%'}} type={'text'} defaultValue={d.slp} id={'slp'+i}/>
                                    </td>
                                    <td style={{color:'#008b8b',cursor:'pointer',textAlign:'center'}} onClick={()=>PopDetail.show(d.name,<ProjectHamlets village={d} />)}>
                                        {d.hamlets.map((dd,ii)=>{
                                            return(<span key={ii}><u>{dd.name} ( {dd.code} )</u><br/></span>)
                                        })}
                                        <span className={'fa fa-angle-down'}/>
                                    </td>
                                    <td>
                                        <button className={'default'} style={{float:'right',marginRight:'16px'}} onClick={()=>this.updateVillageAt(i,d)}>Update</button>
                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </td>
                </tr>


                </tbody>
            </table>
        </div>)
    }
}