import React, {useState, useEffect} from 'react'
import Header from "../header";
import PopDetail from "../../../../popdetail";
import TxCreateService from "../../../../../../services/transaction/transaction_create";
import Wait from "../../../../wait";
import TxReadService from "../../../../../../services/transaction/transaction_read";
import {useForm} from "react-hook-form";
import TransactionPopup from "../../../transaction_popup";
import API from "../../../../../../services/API";
import PopDetailsTwo from "../../../../PopDetailsTwo";
import GeneralSelect from "../../../../../../utils/GeneralSelect";
import Message from "./message";
import DemoSign from "./demo-sign";

const AddPerson = ({submit}) =>{
    const { register, handleSubmit } = useForm();

    const[minor, minorSet] = useState(false)
    const[deceased, deceasedSet] = useState(false)
    const[nidaOk, nidaOkSet] = useState(false)
    const[nidaNumber, nidaNumberSet] = useState('')
    const[nidaData, nidaDataSet] = useState({})
    const[isReadOnly, isReadOnlySet] = useState(false)
    const[current, currentSet] = useState(0)
    const[nonParty, nonPartySet] = useState(false)
    const[currentComp, currentCompSet] = useState(<Individual required={true} isReadOnly={false} register={register} minor={minor}/>)
    const[capacity,capacitySet] = useState('')

    const active ={
        cursor:'pointer',
        fontSize: '20px',
        borderTop:'4px solid tomato'
    }
    const tab ={
        cursor:'pointer',
        fontSize: '20px'
    }
    const show = {
        display:'inherit'
    }

    const hide = {
        display: 'none'
    }

    const handleChange = e =>{
        nidaNumberSet(e.target.value)
    }

    useEffect(()=>{
        TxReadService.getPartTypes()
            .then(d =>{
                console.log("Party type",d)
            })
    },[])
    useEffect(()=>{
        TxReadService.getRoles()
            .then(d =>{
                console.log("roles",d)
            })
    },[])
    useEffect(()=>{
        if(nonParty){
            deceasedSet(false)
            minorSet(false)
            nidaOkSet(false)
            currentCompSet(<Association register={register} isReadOnly={false} required={true}/>)
        }
    },[nonParty])
    useEffect(()=>{
        if(minor){
            deceasedSet(false)
            nonPartySet(false)
            nidaOkSet(false)
            currentCompSet(<Individual required={true} isReadOnly={false} register={register} minor={minor}/>)
        }
    },[minor])
    useEffect(()=>{
        if(deceased){
            nonPartySet(false)
            minorSet(false)
            currentCompSet(<Individual required={true} isReadOnly={false} register={register} deceased={true}/>)
        }
    },[deceased])
    useEffect(()=>{
        if(nidaOk){
            nonPartySet(false)
            minorSet(false)
            currentCompSet(<Individual required={true} isReadOnly={true} register={register} data={nidaData}/>)
        }
    },[nidaOk])
    useEffect(()=>{
        if(capacity ==='Individual' && nidaOk){
            currentCompSet(<Individual required={true} isReadOnly={true} register={register} data={nidaData}/>)
        }
        else if(capacity ==='individual' && !nidaOk){
            currentCompSet(<Individual required={true} isReadOnly={false} register={register}/>)
        }
        else if( capacity ==='Association'||
            capacity ==='Institution'||
            capacity ==='Company' ||
            capacity ==='Society' ||
            capacity ==='Trust' ){
            currentCompSet(<Association register={register} isReadOnly={false} required={true}/>)
        }
        else if(capacity ==='Government'){
            currentCompSet(<Government required={true} isReadOnly={false} register={register}/>)
        }
    },[capacity])
    const handleSearch = () =>{
        try {
            if(nidaNumber.trim().length === 20 && !isNaN(nidaNumber)){
                nidaDataSet({})
                nidaOkSet(false)
                Wait.show("Searching...")
                TxCreateService.verifyNida( nidaNumber )
                    .then(d => {
                        Wait.dismiss()
                        console.log("The response", d.data)
                        if (d.data.status !== 0) {
                            nidaDataSet({})

                            const {
                                birthCountry: country,
                                dateOfBirth: birthDate,
                                firstName,
                                middleName,
                                surName: lastName,
                                sex: gender,
                                nationality: citizenship,
                                nin: nationalId,
                                residentRegion: region,
                                residentDistrict: district,
                                signature,
                                photo,
                                residentVillange: village,
                                residentPostalAddress:po_box
                            } = d.data.party;
                            const party={
                                country,
                                birthDate,
                                firstName,
                                middleName,
                                lastName,
                                gender,
                                citizenship,
                                nationalId,
                                region,
                                district,
                                signature,
                                photo,
                                village,
                                po_box
                            }
                            nidaDataSet(party)
                            isReadOnlySet(d.data.status !== 0)
                            nidaOkSet(true)
                            console.log("The party", d.data.party)
                        }
                    })
                    .catch(e =>{
                        Wait.dismiss()
                    })
            }
        }
        catch (e) {

        }

    }

    return(
        <div style={{marginLeft:'1.5rem', marginRight:'1rem'}}>
            <div className={'row'} style={{paddingTop:'.5rem',borderBottom:'1px solid #ccc', width:'100%',textAlign: 'center'}}>
                <table style={{width:'100%'}}>
                    <tbody>
                    <tr>
                        <td><label htmlFor={'nida'}>* Person Nida Id Number</label></td>
                        <td><input style={{width:'100%'}} id={'nida'} onChange={handleChange}/></td>
                        <td><button style={{width:'100%'}} onClick={handleSearch}>Search</button></td>
                        <td><label htmlFor={'minor'}>Minor</label></td>
                        <td><input
                            id={'minor'}
                            style={{width:'30px', height:'30px'}}
                            onChange={(e) =>{ e.target.checked?minorSet(true):minorSet(false)}}
                            checked={minor}
                            type={'checkbox'}/></td>
                        <td><label htmlFor={'deceased'}>Deceased</label></td>
                        <td><input
                            id={'deceased'}
                            style={{width:'30px', height:'30px'}}
                            onChange={(e) =>{ e.target.checked?deceasedSet(true):deceasedSet(false)}}
                            checked={deceased}
                            type={'checkbox'}/></td>
                        <td><label htmlFor={'nonPerson'}>Legal Person</label></td>
                        <td><input
                            id={'nonPerson'}
                            style={{width:'30px', height:'30px'}}
                            onChange={(e) =>{ e.target.checked?nonPartySet(true):nonPartySet(false)}}
                            checked={nonParty}
                            type={'checkbox'}/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {
                (minor || deceased || nidaOk || nonParty) &&
                <form onSubmit={handleSubmit(submit)}>
                    <div className={'row'} style={{paddingTop:'.5rem'}}>
                        <label htmlFor={'capacity'} style={tab}>Capacity</label>
                        <select name={'partyType'} defaultValue={''} ref={register({ required: true})} onChange={(e)=>{capacitySet(e.target.value)}}>
                            <option value={''}/>
                            <option value={'Association'}  style={minor || deceased || nidaOk?hide:{}}>Association</option>
                            <option value={'Government'} style={minor || deceased || nidaOk?hide:{}}>Government</option>
                            <option value={'Individual'} style={nonParty?hide:{}}>Individual</option>
                            <option value={'Institution'} style={minor || deceased || nidaOk?hide:{}}>Institution</option>
                            <option value={'Company'}  style={minor || deceased || nidaOk?hide:{}}>Company</option>
                            <option value={'Society'}  style={minor || deceased || nidaOk?hide:{}}>Society</option>
                            <option value={'Trust'}  style={minor || deceased || nidaOk?hide:{}}>Trust</option>
                        </select>
                        <label htmlFor={'applicantRole'} style={tab}>Applicant Role</label>
                        <select name={'applicantRole'} ref={register({ required: true})}>
                            <option value={''}/>
                            <option value={'Applicant'}> Applicant</option>
                            <option value={'Submitter Only'}>Submitter Only</option>
                        </select>
                    </div>
                    <div className={'row'} style={{gap:'40px',borderBottom:'1px solid #ccc', width:'100%', paddingTop:'.5rem', marginTop:'.5rem'}} >
                        <label
                            style={current===0?active:tab}
                            onClick={()=>{currentSet(0)}}>General</label>
                        <label
                            style={current===1?active:tab}
                            onClick={()=>{currentSet(1)}}>Address</label>
                        <label
                            style={current===2?active:tab}
                            onClick={()=>{currentSet(2)}}>Related Person</label>
                        <label
                            style={current===3?active:tab}
                            onClick={()=>{currentSet(3)}}>Photo & Signature</label>
                    </div>
                    <div className={'row'} style={{width:'100%'}}>
                        <div style={current === 0 ?show:hide}>
                            {currentComp}
                        </div>
                        <table style={current === 1 ?show:hide}>
                            <tbody>
                            <tr>
                                <td><label>Region</label></td>
                                <td><input name={'region'} defaultValue={nidaData?nidaData.region:''} ref={register({required:true})}/></td>
                                <td><label>District</label></td>
                                <td><input name={'district'} defaultValue={nidaData?nidaData.district:''} ref={register({required:true})}/></td>
                                <td><label>Location</label></td>
                                <td><input name={'village'} defaultValue={nidaData?nidaData.village:''} ref={register({required:true})}/></td>
                            </tr>
                            <tr>
                                <td><label>P.O Box</label></td>
                                <td><input name={'po_box'} defaultValue={nidaData?nidaData.po_box:''} ref={register({required:true})}/></td>
                                <td><label>Postal Code</label></td>
                                <td><input name={'postalCode'} readOnly={isReadOnly} defaultValue={'255'} ref={register({required:true})}/></td>
                                <td><label>Country</label></td>
                                <td><input name={'country'} defaultValue={nidaData?nidaData.country:''} ref={register({required:true})}/></td>
                            </tr>
                            <tr>
                                <td><label>City of</label></td>
                                <td><input name={'city'} defaultValue={nidaData?nidaData.region:''} ref={register({required:true})}/></td>
                                <td><label>Physical Address</label></td>
                                <td colSpan={3}><input name={'residentPostalAddress'} style={{width:'100%'}} defaultValue={nidaData?nidaData.po_box:''} ref={register({required:true})}/></td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={current === 2 ?{...show, marginTop:'.5rem'}:hide}>
                            <RelatedPerson isNotReadOnly={false}/>
                        </div>
                        <table style={current === 3 ?show:hide}>
                            <tbody>
                            <tr>
                                <td><label>Picture</label></td>
                                <td><label>Signature</label></td>
                            </tr>
                            <tr>
                                <td><img alt={'photo'} style={{height:'192px',width:'192px',marginRight:'8px',background:'#f7f7f7'}} src={nidaData?'data:image/png;base64,'+nidaData.photo:''}/>
                                </td>
                                <td><img alt={'signature'} style={{height:'192px',width:'300px',marginRight:'8px',background:'#f7f7f7'}} src={nidaData?'data:image/png;base64,'+nidaData.signature:''}/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr/>
                    <button className={"default"} onClick={()=>PopDetail.dismiss()} style={{float:"right", width:'150px',paddingBottom:'.5rem'}}>Cancel</button>
                    <button type={'submit'} style={{float:"right", width:'150px',paddingBottom:'.5rem'}}>Save</button>
                </form>}
        </div>
    )
}

const Government =({data, isReadOnly=true, register= null, required})=>{
    return(
        <table style={{width:'100%'}}>
            <tbody>
            <tr>
                <td colSpan={2}><span style={{color:'red'}}>*</span>Organization Name</td>
                <td colSpan={2}><span style={{color:'red'}}>*</span>Tanzanian Entity</td>
            </tr>
            <tr>
                <td colSpan={2}><input type={"text"} name={"organizationName"} style={{width:'250px'}} readOnly={isReadOnly}  ref={register?register({required:required}):register}/></td>
                {
                    isReadOnly?
                        <td colSpan={2}><input type={"text"} style={{width:'100%'}} name={"tanzaniaEntity"} readOnly={isReadOnly}/></td>
                        :
                        <td colSpan={2}>
                            <select style={{width:'250px'}} name={"tanzaniaEntity"} ref={register?register({required:required}):register}>
                                <option value={""}/>
                                <option value={"tanzanian_answer_yes"}> Yes</option>
                                <option value={"tanzanian_answer_no"}> No</option>
                                <option value={"unknown"}> Unknown</option>
                            </select>
                        </td>
                }
            </tr>
            <tr>
                <td><span style={{color:'red'}}>*</span>Phone</td>
                <td/>
            </tr>
            <tr>
                <td><input type={"text"} style={{width:'250px'}} name={"phone"} readOnly={isReadOnly}  ref={register?register({required:required}):register}/></td>
                <td><label style={{width:'100%'}}>Work</label></td>
            </tr>
            </tbody>
        </table>

    )
}

const Association =({data, register=null, isReadOnly=true, required})=>{
    const[req,reqSet] = useState(true);
    useEffect(()=>{
        if(TransactionPopup.state().transaction.name!=='Grant Right of Occupancy' ||TransactionPopup.state().transaction.name!=='Transfer Right of Occupancy'){
            reqSet(false)
        }
    },[])
    return(
        <table style={{width:'100%'}}>
            <tbody>
            <tr><td><span style={{color:'red'}}>*</span>Organization Name</td></tr>
            <tr><td colSpan={4}><input type={"text"} readOnly={isReadOnly} name={"organizationName"} style={{width:'100%'}}  ref={register?register({required:required}):register}/></td></tr>

            <tr>
                <td colSpan={2}><span style={{color:'red'}}>*</span>Tanzanian Entity</td>
                <td>Date of Registration</td></tr>
            <tr>
                {
                    isReadOnly?
                        <td colSpan={2}><input type={"text"} style={{width:'100%'}} name={"tanzaniaEntity"} readOnly={isReadOnly}/></td>
                        :
                        <td colSpan={2}>
                            <select style={{width:'100%'}} name={"tanzaniaEntity"} ref={register?register({required:required}):register}>
                                <option value={""}/>
                                <option value={"tanzanian_answer_yes"}> Yes</option>
                                <option value={"tanzanian_answer_no"}> No</option>
                                <option value={"unknown"}> Unknown</option>
                            </select>
                        </td>
                }
                <td colSpan={2}><input readOnly={isReadOnly} type={"date"} name={"registrationDate"} style={{width:'100%'}}  ref={register?register({required:req}):register}/></td>
            </tr>
            <tr>
                <td><span style={{color:'red'}}>*</span>ID Document</td>
                <td>ID Doc No</td>
                <td>TIN</td>
            </tr>
            <tr>

                {isReadOnly
                    ?<td><input id={'idDocument'} defaultValue={data?data.idDocument:''} readOnly={isReadOnly}/></td>
                    :<td ><select name={'idDocument'} defaultValue={'nidaId'} ref={register?register({required:req}):register}>
                        <option value={''}/>
                        <option value={'Certificate of Incorporation/Registration'} > Certificate of Incorporation/Registration</option>
                    </select></td>
                }
                <td><input type={"text"} readOnly={isReadOnly} name={"idDocNo"} style={{width:'100%'}}  ref={register?register({required:req}):register}/></td>
                <td colSpan={2}><input readOnly={isReadOnly} name={"tin"} type={"text"} style={{width:'100%'}}  ref={register?register({required:req}):register}/></td>
            </tr>
            <tr>
                <td><span style={{color:'red'}}>*</span>Phone</td>
                <td/>
                <td>Email</td>
            </tr>
            <tr>
                <td><input type={"text"} readOnly={isReadOnly} name={"phone"} style={{width:'100%'}}  ref={register?register({required:req,pattern:{value:/^255[67]{1}[0-9]{2}[0-9]{6}$/,message:"Enter a Valid Phone number"}}):register}/></td>
                <td><label>Work</label></td>
                <td colSpan={2}><input name={"email"} readOnly={isReadOnly} type={"email"} style={{width:'100%'}}  ref={register?register({required:req}):register}/></td>
            </tr>
            <tr>
                <td><label>Constitution/Law/Instrument</label></td>
                <td colSpan={7}><textarea name={'constitution'} rows={5} style={{width:'100%', resize:'none'}} ref={register?register({required:req}):register}/></td>
            </tr>
            </tbody>
        </table>
    )
}

const Individual=({data, isReadOnly=true, register=null, required, nonNidaRead = false, minor = false, deceased=false})=>{
    return(

        <table>
            <tbody>
            <tr>
                <td><label>First Name</label></td>
                <td><input name={'firstName'} type={'text'} readOnly={isReadOnly} defaultValue={data?.firstName}    ref={register?register({required:required}):register}/></td>
                <td><label>Middle Name</label></td>
                <td><input name={'middleName'} type={'text'} readOnly={isReadOnly} defaultValue={data?.middleName} ref={register}/></td>
                <td><label>Last Name</label></td>
                <td><input name={'lastName'} type={'text'} readOnly={isReadOnly} defaultValue={data?.lastName}    ref={register?register({required:required}):register}/></td>
                <td><label>Minor</label></td>
                <td><input name={'minor'} type={'checkbox'} style={{width:'30px', height:'30px'}} readOnly={nonNidaRead} ref={register} checked={minor}/></td>
            </tr>
            <tr>
                <td><label>Gender</label></td>
                <td><input name={'gender'} type={'text'} readOnly={isReadOnly} defaultValue={data?.gender} ref={register}/></td>
                <td><label>Occupation</label></td>
                <td><input name={'occupation'} type={'text'} ref={register} defaultValue={data?data.occupation:''} readOnly={nonNidaRead}/></td>
                <td><label>Birth Date</label></td>
                <td>
                    {
                        minor || deceased?<input name={"birthDate"} type={"date"} ref={register?register({required:true}):register} />
                        :<input name={'birthDate'} defaultValue={data?data.birthDate:''} readOnly={isReadOnly}  ref={register?register({required:required}):register}/>
                    }
                </td>
                <td><label>Incapacitated</label><input name={'incapacitated'} type={'checkbox'} style={{width:'30px', height:'30px'}} readOnly={nonNidaRead} ref={register}/></td>
                <td><label>Deceased</label><input name={'deceased'} type={'checkbox'} style={{width:'30px', height:'30px'}} readOnly={nonNidaRead} ref={register} checked={deceased}/></td>
            </tr>
            <tr>
                <td><label>Citizenship of</label></td>
                <td colSpan={3}><input name={'citizenship'} type={'text'} style={{width:'100%'}} defaultValue={data?data.citizenship:''}  readOnly={isReadOnly} ref={register}/></td>
                <td><label>Nida Id</label></td>
                <td><input id={'nidaId'} name={'nationalId'} type={'text'} readOnly={isReadOnly} defaultValue={data?data.nationalId:''} ref={register}/></td>
                <td><label>TIN</label></td>
                <td><input name={'tin'} readOnly={nonNidaRead} type={'text'} ref={register} defaultValue={data?data.tin:''}/></td>
            </tr>
            <tr>
                <td><label>ID Document</label></td>

                {!isReadOnly && data?.nationalId
                    ?<td><input id={'idDocument'} type={'text'} defaultValue={data?data.idDocument:''} readOnly={isReadOnly}/></td>
                    :data?.nationalId?<td><input name={'identityCardName'} defaultValue={'Nida ID'} readOnly={isReadOnly} ref={register}/></td>
                        :<td ><select name={'identityCardName'} defaultValue={''} ref={register?register({required:required}):register}>
                            <option value={''}/>
                            <option value={'Nida ID'} > Nida ID</option>
                            <option value={'Birth Certificate'}> Birth Certificate</option>
                            <option value={'Birth Certificate'}> Death Certificate</option>
                        </select></td>
                }
                <td><label>ID Doc. No</label></td>
                <td><input name={'idDocNo'} type={'text'} defaultValue={data?data.nationalId:''} readOnly={isReadOnly} ref={register}/></td>
                <td><label>Phone</label></td>
                <td><input name={'phoneNumber'} type={'text'} placeholder={"255*********"} readOnly={nonNidaRead} ref={register?register({required:true,pattern:{value:/^255[67]{1}[0-9]{2}[0-9]{6}$/,message:"Enter a Valid Phone number"}}):register} defaultValue={data?data.phoneNumber:''}/></td>
                <td><label>Email</label></td>
                <td><input name={'email'} type={'email'} readOnly={nonNidaRead} ref={register} defaultValue={data?data.email:''}/></td>
            </tr>
            <tr>
                <td><label>Notes</label></td>
                <td colSpan={7}><textarea name={'notes'} rows={5} style={{width:'100%', resize:'none'}} readOnly={nonNidaRead} ref={register}/></td>
            </tr>
            <tr style={{display:'none'}}>
                <td><input name={'photo'} defaultValue={data?data.photo:''} ref={register}/></td>
                <td><input name={'signature'} defaultValue={data?data.signature:''} ref={register}/></td>
            </tr>
            </tbody>
        </table>
    )
}

const EditPerson = ({data}) =>{
    const{register, handleSubmit} = useForm();
    const[update,updateSet] = useState({
        fullName:"",
        occupation:"",
        phoneNumber:"",
        email:"",
        partyId:0,
        poBox:"",
        tin:"",
        district:"",
        village:"",
        city:"",
        postalCode:""
    })
    const{partyId}= data
    const container = {
        margin:'1rem'
    }
    useEffect(()=>{
        Wait.show("Fetching Person ...")
        API.ax.get(`transaction/update-person/${partyId}`)
            .then(d=>{
                const{status, result, message} = d.data
                if(status === 1){
                    updateSet(result)
                }else{
                    PopDetail.show("Success",<Message message={message} faIcon={"harzad"} action={0}/>, 40)
                }
                Wait.dismiss()
                console.log(d.data)
            })
            .catch(e=>{
                Wait.dismiss()
            })
    },[])
    const submit = theData =>{
        let transactionId = TransactionPopup.id()
        let dataToSend ={partyId,...theData,fullName:update.fullName, transactionId}
        Wait.show("Updating Party...")
        API.ax.post("transaction/update-person",dataToSend)
            .then(d=>{
                Wait.dismiss()
                const{status, message} = d.data
                if(status === 1){
                    PopDetail.show("Success",<Message message={"Update Successful"} faIcon={"success"} action={0}/>,40)
                }else{
                    PopDetail.show("Success",<Message message={message} faIcon={"harzad"} action={0}/>,40)
                }
            })
            .catch(e=>console.log("Error Updating",e))


    }
    return(
        data ?
            <div style={container} >
                <h4 style={{textAlign:'center'}}>Edit Details of <b>{update.fullName}</b></h4><br/>
                <div className={'row'} style={{width:'100%'}}>
                    <form onSubmit={handleSubmit(submit)}>
                        <table style={{textAlign:"center"}}>
                        <tbody>
                        <tr>
                            <td><label>Occupation</label></td>
                            <td><input name={'occupation'} type={'text'} defaultValue={update.occupation} ref={register}/></td>
                            <td><label>Phone Number</label></td>
                            <td><input name={'phoneNumber'} type={'text'} defaultValue={update.phoneNumber} ref={register({required:true, pattern:{value:/^255[67]{1}[0-9]{2}[0-9]{6}$/,message:"Enter a Valid Phone number"}})}/></td>
                            <td><label>Email</label></td>
                            <td><input name={'email'} type={'email'} defaultValue={update.email} ref={register({required:false})}/></td>
                        </tr>
                        <tr>
                            <td><label>P O Box</label></td>
                            <td><input name={'poBox'} type={'text'} defaultValue={update.poBox} ref={register({required:true})} /></td>
                            <td><label>Postal Code</label></td>
                            <td><input name={'postalCode'} type={'text'} defaultValue={update.postalCode} ref={register({required:true})} /></td>
                            <td><label>TIN</label></td>
                            <td><input name={'tin'} type={'text'} defaultValue={update.tin} ref={register({required:false})}/></td>
                        </tr>
                        <tr>
                            <td><label>Location</label></td>
                            <td><input name={'village'} type={'text'} defaultValue={update.village} ref={register({required:true})} /></td>
                            <td><label>District</label></td>
                            <td><input name={'district'} type={'text'} defaultValue={update.district} ref={register({required:true})} /></td>
                            <td><label>Region</label></td>
                            <td><input name={'city'} type={'text'} defaultValue={update.city} ref={register({required:true})}/></td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <br/><button onClick={PopDetail.dismiss} style={{float:'right', width:'150px'}} className={"default"}>Cancel</button>
                                <button type={'submit'} style={{float:'right', width:'150px'}}>Save</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </form>
                </div>
            </div>
            :PopDetail.dismiss()
    )
}

const RelatedPerson = ({isNotReadOnly=false, data}) =>{
    let transactionId = TransactionPopup.id()
    const initialState =[{
        "relationType":"",
        "relatedPerson":"",
        "type":"",
        "address":"",
        "phone":"",
        "email":""
    }]
    const[listParty, listPartySet] = useState(initialState)
    const[act,actSet] = useState(false);
    useEffect(()=>{
        if(data !== undefined){
            let{ partyId } = data
            actSet(true)
            let datum ={partyId,transactionId}
            API.ax.post("/transaction/get-related-person",datum)
                .then(d=>{
                    let{result,status} = d.data
                    if(status === 1){
                        listPartySet(result)
                    }else {
                        listPartySet(initialState)
                    }
                })
            Wait.dismiss()
        }
    },[data])
    const saveRelation = relation =>{
        PopDetailsTwo.dismiss()
        console.log("relation ",relation)

        console.log("People ",data)
        let{ partyId } = data
        if(partyId === relation.relatedPartyId){
            PopDetailsTwo.show("Error",<Message message={"Party and Related Party should not be the same"} faIcon={'harzad'} action={1}/>,30)
        }else{
            Wait.show("Adding Related ....")
            let datum ={...relation,partyId}
            API.ax.post("transaction/add-related-person",datum)
                .catch(e=>{
                    Wait.dismiss()
                    PopDetailsTwo.show("Error",<Message faIcon={"harzad"} message={e} action={1}/>,30)
                })
                .then(d=>{
                    let{ partyId } = data
                    let datum ={partyId,transactionId}
                    API.ax.post("/transaction/get-related-person",datum)
                        .then(d=>{
                            let{result,status} = d.data
                            if(status === 1){
                            listPartySet(result)
                            }else {
                                listPartySet(initialState)
                            }
                        })

                    Wait.dismiss()
                })
        }

    }
    return(
        <div style={{marigi:0, display:'flex', flexDirection:'column'}}>
            <Header display={isNotReadOnly} label={''}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={act?()=>{PopDetailsTwo.show("Related Person",<AddRelated saveRelation ={saveRelation}/>,40)}:()=>{}}
                    /><strong> New</strong>
                </div>{/*
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{}}
                    /><strong> Edit</strong>
                </div>*/}
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{}}
                    /><strong> Remove</strong>
                </div>
            </Header>

            <table className={'table'}>
                <thead>
                <tr>
                    <th>RELATION TYPE</th>
                    <th>RELATED PERSON</th>
                    <th>TYPE</th>
                    <th>ADDRESS</th>
                    <th>PHONE</th>
                    <th>EMAILi</th>
                </tr>
                </thead>
                <tbody>
                {listParty.map(({relationType,relatedPerson,type,address,phone,email},id)=>
                {
                    return(
                        <tr key={id}>
                            <td>{relationType}</td>
                            <td>{relatedPerson}</td>
                            <td>{type}</td>
                            <td>{address}</td>
                            <td>{phone}</td>
                            <td>{email}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

const AddRelated = ({saveRelation}) =>{
    const[relatedPartyId,partyIdSet] = useState(0)
    const[relationType,relationSet] = useState('')
    let transactionId = TransactionPopup.id()

    let endpoint = `transaction/transaction-parties/${transactionId}`
    const parties ={
        endpoint,
        'value':'partyId',
        'label':'fullName',
        'placeholder':'Select Person'
    }
    const relations ={
        'endpoint':'land/party-relation-ype',
        'value':'id',
        'label':'name',
        'placeholder':'Select Relationship'
    }
    const submit =()=>{
        if(relatedPartyId >0 && relationType.length>0){
            let data ={
                relatedPartyId,
                relationType,
                transactionId
            }
            saveRelation( data )
        }
    }
    return(
        <>
            <table style={{width:'100%'}}>
                <tbody>
                <tr><td><span style={{color:'red'}}>*</span> Person</td></tr>
                <tr><td><GeneralSelect parameters={parties} onChanges={(data)=>partyIdSet(data.value)}/></td></tr>
                <tr><td><span style={{color:'red'}}>*</span> ls</td></tr>
                <tr><td><GeneralSelect parameters={relations} onChanges={(data)=>relationSet(data.label)}/></td></tr>
                </tbody>
            </table>
            <hr/>
            <div>
                <button className={"default"} onClick={PopDetail.dismiss} style={{float:"right", width:'150px'}}>Cancel</button>
                <button onClick={submit} style={{float:"right", width:'150px'}}>Save</button>
            </div>
        </>
    )
}

const Remove = () =>{
    return(
        <>
            <p>Remove</p>
        </>
    )
}

const Search = () =>{
    return(
        <>
            <p>Search</p>
        </>
    )
}

const PersonDetails = ({data, writable, refresh}) =>{
    const[current, currentSet] = useState(0)
    const active ={
        cursor:'pointer',
        fontSize: '20px',
        marginRight:'40px',
        borderTop:'4px solid tomato'
    }
    const tab ={
        cursor:'pointer',
        marginRight:'40px',
        fontSize: '20px'
    }
    const show = {
        display:'inherit',
        width:'100%'
    }
    const hide = {
        display: 'none'
    }
    return(
        <div className={'row'} style={{overflow:'auto',fontSize:'12px', margin:0}}>

            <div className={''} style={{margin:'24px 0'}}>
                <span style={{cursor:'pointer', fontSize:'20px'}}>Capacity</span>
                <input style={{margin:'8px'}} type={'text'} readOnly={true} id={'capacity'} defaultValue={data?data.partyTypeName:''}/>
                <span style={{cursor:'pointer', fontSize:'20px'}}>Applicant Role</span>
                <input style={{margin:'8px'}} type={'text'} readOnly={true} id={'applicantRole'} defaultValue={data?data.partyRoleName:''}/>
            </div>
            <div className={''} style={{borderBottom:'1px solid #ccc', width:'100%', marginTop:'.5rem'}} >
                <span
                    style={current===0?active:tab}
                    onClick={()=>{currentSet(0)}}>General</span>
                <span
                    style={current===1?active:tab}
                    onClick={()=>{currentSet(1)}}>Address</span>
                <span
                    style={current===2?active:tab}
                    onClick={()=>{currentSet(2)}}>Related Person</span>
                <span
                    style={current===3?active:tab}
                    onClick={()=>{currentSet(3)}}>Photo & Signature</span>
            </div>
            <div className={''} style={{width:'100%'}}>
                <div style={current === 0 ?{...show, marginTop:'.5rem'}:hide}>
                    <Individual data={data} nonNidaRead={true}/>
                </div>

                <table style={current === 1 ?show:hide}>
                    <tbody>
                    <tr>
                        <td>Region</td>
                        <td><input id={'region'} type={'text'} defaultValue={data?data.city:''} readOnly={true}/></td>
                        <td>District</td>
                        <td><input id={'district'} type={'text'} defaultValue={data?data.district:''} readOnly={true}/></td>
                        <td>Location</td>
                        <td><input id={'village'} type={'text'} defaultValue={data?data.village:''} readOnly={true}/></td>
                    </tr>
                    <tr>
                        <td>P.O Box</td>
                        <td><input id={'pOBox'} type={'text'} defaultValue={data?data.po_box:''} readOnly={true}/></td>
                        <td>Postal Code</td>
                        <td><input id={'postalCode'} type={'text'} defaultValue={data?data.postalCode:''} readOnly={true}/></td>
                        <td>Country</td>
                        <td><input id={'country'} type={'text'} defaultValue={data?data.country:''} readOnly={true}/></td>
                    </tr>
                    <tr>
                        <td>City of</td>
                        <td><input id={'city'} type={'text'} defaultValue={data?data.city:''} readOnly={true}/></td>
                        <td>Physical Address</td>
                        <td colSpan={3}><input type={'text'} style={{width:'100%'}} defaultValue={data?data.po_box:''} readOnly={true}/></td>
                    </tr>
                    </tbody>
                </table>
                <div style={current === 2 ?{...show, marginTop:'.5rem'}:hide}>
                    <RelatedPerson isNotReadOnly={writable} data={data}/>
                </div>
                <table style={current === 3 ?show:hide}>
                    <tbody>
                    <tr>
                        <td><label>Picture</label></td>
                        <td><label>Signature</label></td>
                    </tr>
                    <tr>
                        <td><img alt={'photo'} style={{height:'192px',width:'192px',marginRight:'8px',background:'#f7f7f7'}} src={data?'data:image/png;base64,'+data.photo:''}/>
                        </td>
                        <td><img alt={'signature'}  style={{height:'192px',width:'400px',marginRight:'8px',background:'#f7f7f7'}} src={data?'data:image/png;base64,'+data.signature:''}/></td>
                    </tr>
                    <tr>
                        <td colSpan={2} align={"center"} ><button style={{width:"200px", color:"yellow", outline:"none",padding:".5rem"}}
                                                           onClick={data?()=>PopDetail.show("New Signature",<DemoSign nationalId={data.nationalId} partyId={data.partyId} refresh={refresh}/>,50):()=>{}}>Change Signature</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default function Persons({writable}) {
    const [selected,selectedSet] = useState(-1)
    const [people, peopleSet] =useState([])
    let txId = TransactionPopup.id()
    const submit = data =>{
        const dataSend ={...data,...{transactionId:TransactionPopup.id()}}
        console.log( "PERSONS INFOS  ",dataSend)
        try{
            Wait.show("Saving...")
            TxCreateService.addPerson(dataSend)
                .then(d =>{
                    console.log("response",d)
                    //if(d.data.status === 1){
                        API.ax.get(`transaction/transaction-parties/${txId}`)
                            .then(d=>{
                                Wait.dismiss()
                                const{result, status, message} = d.data
                                if(status === 1){
                                    peopleSet(result)
                                }
                            })
                            .catch(e=>console.error("error",e))
                  //  }
                    PopDetail.dismiss()
                    console.log(people)
                })
                .catch(
                    e =>{console.error(e)
                        Wait.dismiss()
                        PopDetail.dismiss()}
                )
        }catch (e) {
            console.error(e)
            Wait.dismiss()
            PopDetail.dismiss()
        }
    }
    const highlight ={
        fontWeight:'bold',
        cursor:'pointer'
    }
    const unHighlight = {
        cursor:'pointer'
    }

    useEffect(()=>{

        API.ax.get(`transaction/transaction-parties/${txId}`)
            .then(d=>{
                const{result, status, message} = d.data
                if(status === 1){
                    peopleSet(result)
                }
            })
            .catch(e=>console.error("error",e))
    },[])

    const handleRemove =()=>{
        const {partyId} = people[selected]
        const data = {partyId, transactionId:txId}
        Wait.show("Removing Person ...")
        API.ax.post("transaction/remove-person",data)
            .then(d=>{

                const {status} = d.data.status
                if(status === 1){/*
                    let person =people[selected]
                    let newPeople =people.filter(item => item !== person)
                    peopleSet(newPeople);*/

                    API.ax.get(`transaction/transaction-parties/${txId}`)
                        .then(d=>{
                            Wait.dismiss()
                            const{result, status, message} = d.data
                            if(status === 1){
                                peopleSet(result)
                            }
                        })
                        .catch(e=>console.error("error",e))

                    selectedSet(-1);
                }
            })
            .catch(e=>{
                console.log(e);
               })
        Wait.dismiss();

    }
    const refresh = data =>{
        if(data){
            Wait.show("Reloading ...")
            API.ax.get(`transaction/transaction-parties/${txId}`)
                .then(d=>{
                    Wait.dismiss()
                    const{result, status, message} = d.data
                    if(status === 1){
                        peopleSet(result)
                    }
                })
                .catch(e=>console.error("error",e))
        }
    }

    return(
        <div className={''} style={{marginLeft:'10px',padding:'.5rem', width:'100%',background:'#fff'}}>

            <Header display={writable} label={'Persons'}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("New Person",<AddPerson submit={submit}/>)}}
                    /><strong> New / Search</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={selected === -1? ()=>{}:()=>{PopDetail.show("Edit Person",<EditPerson data={people[selected]}/>,50)}}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={selected === -1? ()=>{}:handleRemove}
                    /><strong> Remove</strong>
                </div>
            </Header>

            <div className={''} style={{height:'200px',overflow:'auto', width:'100%'}}>
                <table className={'tb'} style={{width:'100%'}}>
                    <thead>
                    <tr>
                        <th>APPLICANT ROLE</th>
                        <th>NAME</th>
                        <th>TYPE</th>
                        <th>NIDA/ID DOC NO.</th>
                        <th>ADDRESS</th>
                        <th>PHONE</th>
                        <th>EMAIL</th>
                    </tr>
                    </thead>
                    <tbody>
                    {people.map((d,i) =>{
                        return(
                            <tr key={i} style={i === selected ? highlight: unHighlight } onClick={()=>{selectedSet(i)}}>
                                <td>{d.partyRoleName}</td>
                                <td>{d.firstName +' '+ d.middleName +' '+ d.lastName}</td>
                                <td>{d.partyTypeName}</td>
                                <td>{d.nationalId}</td>
                                <td>{d.partAddress}</td>
                                <td>{d.phoneNumber}</td>
                                <td>{d.email}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <PersonDetails data={people[selected]} writable={writable} refresh={refresh}/>
        </div>
    )

}
