import React, {Component} from 'react';
import TransactionPopup from "../transaction_popup";
import ValCreateService from "../../../../services/valuation/val_create";

export default class PaneApplyPvuApproval extends Component {
    constructor(props) {
        super(props);
        this.acceptPVUDecision=this.acceptPVUDecision.bind(this)
    }

    render() {
        return(<div style={{overflow:'none'}}>
            <div className={'row'}>
               <div style={{padding:'30px'}}>
                   <h3>By Clicking OK button you accept</h3>
                   <button onClick={this.acceptPVUDecision}>OK</button>
               </div>
            </div>
        </div>)
    }
    acceptPVUDecision(){
      //  console.log("Baunit property",PropertyGRO.self.state.baunitId)
        let datam={
            transactionId:TransactionPopup.id(),
            username:sessionStorage.getItem('username'),
            baunitId:null,
            decision:this.props.decision,
            taskName:TransactionPopup.task(),
            taskId:TransactionPopup.taskId()

        }

        console.log("pvu approval",datam)
        ValCreateService.savePVUAcceptance(datam).then(d=>{
        })
    }
}


