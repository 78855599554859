import * as React from "react";
import TxCreateService from "../../../../services/transaction/transaction_create";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import TransactionPopup from "../transaction_popup";

export default class PaneAddSpatialUnit extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            party:{},
            comment:''
        }
        this.addComment = this.addComment.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }
    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <div className={'col col-sm-12'}>
                        <span>* Type</span>
                        <select style={{width:'100%',marginBottom:'24px'}}>
                            <option></option>
                        </select>
                    </div>

                    <div className={'col col-sm-12'}>
                        <div className={'tab tab-active no-bg'}>
                            General
                        </div>
                        <div className={'tab no-bg'}>
                            Legacy
                        </div>
                        <div className={'tab no-bg'}>
                            Parcel Neighbours
                        </div>

                    </div>
                    <div className={'col col-sm-12'}>
                        <table style={{width:'100%'}}>
                            <tbody>
                            <tr>
                                <td>* Region</td>
                                <td>* District</td>
                                <td>* Location</td>
                            </tr>
                            <tr>
                                <td><select style={{width:'100%'}}></select></td>
                                <td><select style={{width:'100%'}}></select></td>
                                <td><select style={{width:'100%'}}></select></td>
                            </tr>
                            <tr>
                                <td>Block</td>
                                <td>Number</td>
                                <td>UPD</td>
                            </tr>
                            <tr>
                                <td><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                            </tr>
                            <tr>
                                <td>Ward</td>
                                <td>Sub Ward</td>
                                <td>Reg Plan No</td>
                            </tr>
                            <tr>
                                <td><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                            </tr>
                            <tr>
                                <td>Survey Area</td>
                                <td>Gis Area</td>
                            </tr>
                            <tr>
                                <td><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Address</td>
                                <td>Plan Name/Number</td>
                            </tr>
                            <tr>
                                <td colSpan={2}><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Use Type</td>
                                <td>Tp Drawing No</td>
                            </tr>
                            <tr>
                                <td colSpan={2}><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Group/Class</td>
                                <td>Valuation No</td>
                            </tr>
                            <tr>
                                <td colSpan={2}><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Notes</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                <textarea style={{width:'100%'}}></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>Allocation Status</td>
                                <td>Status</td>
                                <td>Version</td>
                            </tr>
                            <tr>
                                <td><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                                <td><input style={{width:'100%'}}/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.addComment}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }



    addComment(){
        try {
            Wait.show("Saving...")
            TxCreateService.addComment({
                transactionFk:TransactionPopup.txId,
                taskId:TransactionPopup.taskId,
                taskName:TransactionPopup.taskName,
                comment:this.state.comment,

            }).then(d=>{
                Wait.dismiss()
                PopDetail.dismiss()
            })
        }catch (e){
            console.log(e)
        }
    }
}