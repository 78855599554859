import React, {Component} from 'react';
import TxCreateService from "../../../../services/transaction/transaction_create";
import PopDetail from "../../popdetail";
import Wait from "../../wait";
import TransactionPopup from "../transaction_popup";
import Message from "../screen/gro/person/message";
import PopDetailsTwo from "../../PopDetailsTwo";
import PaymentPane from "../screen/payment";

export default class PaneProcessPayment extends Component {
    constructor(props) {
        super(props);
        this.state={
            actionType:'Confirm Payment',
            billId:props.pending.billPaymentId,
            billNumber:props.pending.pid,
            payCntrNum:props.pending.payCntrNum,
            amountPaid:props.pending.billAmt,
            erv:false,
            exempt:false,
            ervNumber:'',
            paidDate:null,
            description:'',
            exemptReason:'',
            confirmation:'inherit',
            exemption:'none',
            cancellation:'none',
            multipartFile:null
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
        this.savePayment =this.savePayment.bind(this)
    }
    handleFileChange= e =>{
        this.setState({
            multipartFile: e.target.files[0]
        })
    }

    handleChange= e =>{
        this.setState({[e.target.name]:e.target.value})
        if(e.target.name==="ervNumber"){
            this.setState({erv:true})
        }
        if(e.target.name==="actionType"){
            if(e.target.value==="Confirm Payment"){
                this.setState({actionType:'Confirm Payment'})
                this.setState({confirmation:'inherit'})
                this.setState({exemption:'none'})
                this.setState({exempt:false})
                this.setState({description:this.state.defaultValue})

            }
            if(e.target.value==="Exempt Payment"){
                this.setState({actionType:'Exempt Payment'})
                this.setState({exemption:'inherit'})
                this.setState({exempt:true})
                this.setState({amountPaid:0.00})
                this.setState({confirmation:'none'})
                this.setState({cancellation:'none'})
            }
            if(e.target.value==="Cancel Payment"){
                this.setState({actionType:'Cancel Payment'})
                this.setState({cancellation:'inherit'})
                this.setState({exempt:false})
                this.setState({amountPaid:0.00})
                this.setState({exemption:'none'})
                this.setState({confirmation:'none'})
            }
        }
    }
    componentDidMount() {

    }

    savePayment=()=> {
        const g2 = new Date("2022-05-01");
        const g1 = new Date(this.state.paidDate)

        console.log("Date g2==>", g2.getTime())
        console.log("Date g1==>", this.state.paidDate)
        console.log("Date g1==>", g1.getTime())
        if (this.state.description == '') {
            PopDetailsTwo.show("Error", <Message faIcon={'harzad'} message={"Fill the Description Fields"}/>, 40)
            PopDetailsTwo.dismiss()
        } else {


        if (g2.getTime() > g1.getTime()) {


            let paymentInfo = {
                billId: this.state.billId,
                actionType: this.state.actionType,
                billNumber: this.state.billNumber,
                controlNumber: this.state.controlNumber,
                paidAmt: this.state.amountPaid,
                exempt: this.state.exempt,
                erv: this.state.erv,
                ervNumber: this.state.ervNumber,
                paidDate: this.state.paidDate,
                description: this.state.description,
                exemptReason: this.state.exemptReason,
            }
            Wait.show("Processing .....")
            if (this.state.multipartFile) {
                const data = new FormData()
                let datum = {
                    "transactionId": TransactionPopup.id(),
                    "documentType": "",
                    "transactionName": "",
                    "name": "Proof of Payment",
                    "documentNo": "",
                    "instrument": true,
                    "documentKind": "",
                    "description": "Proof of Payment"
                };
                data.append('documentFile', this.state.multipartFile)
                data.append('extra', JSON.stringify(datum))
                TxCreateService.addDocument(data).then(d => {
                })
            }

            TxCreateService.saveProcessPayment(paymentInfo).then(d => {
                console.log("payment dada ", d)

                if (d.data.status === 1) {
                    Wait.dismiss()
                    PopDetail.dismiss()

                } else if (d.data.status === 0) {
                    Wait.dismiss()
                    PopDetail.dismiss()
                    PopDetailsTwo.show("Error", <Message faIcon={'harzad'} message={d.data.message}/>, 40)

                } else {
                    Wait.dismiss()
                    PopDetail.dismiss()
                    PopDetailsTwo.show("Error", <Message faIcon={'harzad'} message={"Check Network Connection"}/>, 40)
                }
                //console.log("Payment Status",d.data)


            })
        } else {
            PopDetail.dismiss()
            PopDetailsTwo.show("Error", <Message faIcon={'harzad'} message={"Use Ilmis to Generate Bill."}/>, 40)
        }
    }
    }

    render() {
        return (
            <div>

                <div className={'col col-sm-12'} style={{width:'100%'}} >
                        <div>Action Type</div>
                        <div>
                            <select style={{width:'100%'}} name={"actionType"} onChange={this.handleChange}>
                            <option value={"Confirm Payment"}>Confirm Payment</option>
                            <option value={"Exempt Payment"}>Exempt Payment</option>
                            <option value={"Cancel Payment"}>Cancel Payment</option>
                            </select>
                        </div>
                </div>

                <div  className={'col col-sm-12'} style={{display:this.state.confirmation, width:'100%'}}>
                        <div style={{width:'100%'}}>Bill Number</div>
                        <div style={{width:'100%'}} ><input value={this.state.billNumber} readOnly={true} style={{width:'100%'}}/></div>
                        <div>Control Number</div>
                        <div style={{width:'100%'}}><input name={'controlNumber'} style={{width:'100%'}} onChange={this.handleChange}/></div>
                        <div style={{width:'100%'}}>Amount Paid</div>
                        <div style={{width:'100%'}}><input name={'amountPaid'} readOnly={true} defaultValue={this.state.amountPaid}/></div>
                        <div style={{width:'100%'}}>ERV</div>
                        <div style={{width:'100%'}}><input name={"ervNumber"} onChange={this.handleChange}/></div>
                        <div style={{width:'100%'}}>Paid Date</div>
                        <div style={{width:'100%'}}><input type={'date'} name={"paidDate"} style={{width:'100%'}} max={new Date()} onChange={this.handleChange}/></div>
                        <div style={{width:'100%'}}>Description</div>
                        <div style={{width:'100%'}}><textarea style={{width:'100%'}} cols={3} rows={3} name={"description"} onChange={this.handleChange} required={true}/></div>
                    {TransactionPopup.state().transaction.name !== "Grant Right of Occupancy"?
                           <><div style={{width:'100%'}}>Proof of Payment</div>
                           <div style={{width:'100%'}}><input type={"file"} style={{width:'100%'}} name={"proofPayment"} onChange={this.handleFileChange}/></div></>
                            :''}
                </div>

                <div  className={'col col-sm-12'} style={{display:this.state.exemption,width:'100%'}}>
                    <div style={{width:'100%'}}>Exempt Reason</div>
                    <div style={{width:'100%'}}><textarea style={{width:'100%'}} cols={3} rows={3} name={"exemptReason"} onChange={this.handleChange}/></div>
                    <div style={{width:'100%'}}>Description</div>
                    <div style={{width:'100%'}}><textarea style={{width:'100%'}} cols={3} rows={3} name={"description"} onChange={this.handleChange} required={true}/></div>
                </div>
                <div  className={'col col-sm-12'} style={{display:this.state.cancellation,width:'100%'}}>
                    <div style={{width:'100%'}}>Cancel Reason</div>
                    <div style={{width:'100%'}}><textarea style={{width:'100%'}} cols={3} rows={3} name={"exemptReason"} onChange={this.handleChange}/></div>
                    <div style={{width:'100%'}}>Description</div>
                    <div style={{width:'100%'}}><textarea style={{width:'100%'}} cols={3} rows={3} name={"description"} placeholder={"Please enter reason, why do you process payment manually?"} onChange={this.handleChange} required={true}/></div>
                </div>


                <div style={{width:'100%'}}>
                    <div><button onClick={this.savePayment}>Save</button><button onClick={PopDetail.dismiss}>Close</button></div>
                </div>
            </div>
        );
    }
}
