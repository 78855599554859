import React, {useState} from 'react';
import GeneralValuation from "./generalValuation";
import Select from "react-select";
import {purpose,compenstion} from '../pJson'
import PopDetail from "../../components/popups/popdetail";

export const colourStyles = {
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#DF4A3622" : null,
            color:'black'
        };
    },
    control: ( base ) => ({
        ...base,
        "&:hover": {
            borderColor: "tomato"
        },
        borderColor: "grey",
        boxShadow: 'none'

    })
};

export default function Purpose({isComp}) {
    const[selectedOption] = useState()
    function onChangePurpose(event) {
        console.log("label", event.label)
        PopDetail.dismiss()
        PopDetail.show(`Valuation Purpose: ${event.label}`,<GeneralValuation isComp={isComp} purpose ={event.label}/>)
    }
   return(
       <div style={{width:'50', margin:'auto'}}>
           <Select
               options={isComp?compenstion:purpose}
               placeholder={'Select Valuation Purpose'}
               styles ={colourStyles}
               value={selectedOption}
               onChange={onChangePurpose}/>
       </div>
   )

}
