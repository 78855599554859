import * as React from "react";
import TxCreateService from "../../../../services/transaction/transaction_create";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import TransactionPopup from "../transaction_popup";
import DocumentsPane from "../screen/documents";
import GeneralSelect from "../../../../utils/GeneralSelect";
import LegacyDocumentsPane from "../../mobile/pops/legacy/legacy_documents";
import PopDetailsTwo from "../../PopDetailsTwo";
const options ={
    'endpoint':'transaction/document-types',
    'value':'documentTypeId',
    'label':'name',
    'placeholder':'Select Document Name'
}
export default class PaneAddDocuments extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            party:{},
            description:'',
            documentKind:'',
            documentNo:'',
            documentName:'',
            transactionName:'',
            documentType:'',
            instrument:false,
            transactionId:'',
            multipartFile:null,

        }
        this.addDocument = this.addDocument.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }
    handleChange= e =>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
console.log("checkeddd==>",name,value)
        this.setState({
            [name]: value
        });
    }
    handleFileChange= e =>{
        this.setState({
            multipartFile: e.target.files[0]
        })
    }
    handleSelectChange = event =>{
        this.setState({"documentName":event.label})
    }

    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <table style={{width:'100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width:'200px'}}><span>Description</span></td>
                            <td>
                                <input style={{width:'100%'}} name={"description"} onChange={this.handleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <td><span>Document No</span></td>
                            <td>
                                <input style={{width:'100%'}} name={"documentNo"} onChange={this.handleChange}/>
                            </td>
                        </tr>
                            <tr>
                            <td><span>Document Name</span></td>
                            <td>
                                <GeneralSelect onChanges={this.handleSelectChange} parameters={options}/>
                            </td>
                        </tr>
                        <tr>
                            <td><span>Instrument</span></td>
                            <td>
                                <input style={{width:'50%',marginLeft:'1%',paddingLeft:'1px',float:'left'}} checked={this.state.instrument} type={'checkbox'} name={"instrument"} onChange={this.handleInputChange}/>
                            </td>
                        </tr>
                            <tr>
                            <td><span>Upload File</span></td>
                            <td>
                                <input style={{width:'100%'}} name={"multipartFile"} onChange={this.handleFileChange} type={"file"} accept={"application/pdf"}/>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.addDocument}>Save</button>
                <button style={{width:'100px'}} onClick={this.props.is_legacy?PopDetailsTwo.dismiss:PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }



    addDocument(){

        if (this.state.multipartFile === undefined || this.state.multipartFile === null){
            alert('No Document')
            return
        }

        let datum = {
            transactionId:this.props.is_legacy?24011:TransactionPopup.id(),
            documentType:this.state.documentType,
            transactionName:this.state.transactionName,
            name: this.state.documentName,
            documentNo: this.state.documentNo,
            instrument:this.state.instrument,
            documentKind: this.state.documentKind,
            description:this.state.description
        }
        const data = new FormData()
        data.append('documentFile', this.state.multipartFile)
        data.append('extra', JSON.stringify(datum))

        try {
            Wait.show("Saving...")
            TxCreateService.addDocument(data).then(d=>{

                Wait.dismiss()
                if (this.props.is_legacy){
                    let docs = LegacyDocumentsPane.state().listOfDoc
                    docs.push(d.data)
                    LegacyDocumentsPane.setState('listOfDoc',docs)
                    PopDetailsTwo.dismiss()
                }else {
                    let docs = DocumentsPane.state().listOfDoc
                    docs.push(d.data)
                    DocumentsPane.setState('listOfDoc',docs)
                    PopDetail.dismiss()
                }
            })
        }catch (e){
            console.log(e)
        }
    }
}
