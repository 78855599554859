import * as React from "react";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import TxReadService from "../../../../services/transaction/transaction_read";
import TransactionPopup from "../transaction_popup";
import ReviewPane from "../screen/review";

export default class PaneAssign extends React.Component{
    constructor(props) {
        super(props);
        this.getUsers = this.getUsers.bind(this)

        this.state = {
            users:[],
            current_user:{}
        }
        this.assign = this.assign.bind(this)
        this.assignToMe = this.assignToMe.bind(this)
    }

    /*handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }
    handleCheckedChange= e =>{
        this.setState({[e.target.name]: e.target.checked});
    }*/

    assign(){
        if(this.state.current_user!=={}){
            ReviewPane.self.setState({
                assignee:this.state.current_user
            })

            PopDetail.dismiss()
        }

        console.log(this.state.current_user.id)
    }

    assignToMe(){
        if(this.state.current_user!=={}){
            TransactionPopup.setState('assignee', this.state.current_user)
            PopDetail.dismiss()
        }
        PopDetail.dismiss()
    }

    render() {
        return(<div style={{height:'100%',background:'#fff',overflow:'auto'}} className={'col col-12'}>
            <br/>
            <div className={'col col-sm-12'}>
                Select user from the list bellow to assign Transaction No. {TransactionPopup.txNumber} for processing <b>{this.state.nextTask}</b>
            </div>

            <hr/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <input placeholder={'Search User...'} style={{width:'78%',float:'left'}}/>
                    <button style={{float:'right',width:'20%'}} className={'refresh'}>Search</button>
                </div>
            </div>
            <hr/>

            <div className={'row'} style={{height:'45vh',overflow:'auto'}}>
                <div className={'col col-sm-12'}>
                    <table style={{width:'100%'}} className={'tb'}>
                        <thead>
                        <tr>
                            <th>USER</th>
                            <th>TASK</th>
                            <th>PENDING</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.users.map((d,i)=>{
                            return(<tr key={i} onClick={()=>this.setState({current_user:d})}>
                                <td>{d.user}</td>
                                <td>{d.task}</td>
                                <td>{d.tasks}</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <span style={{marginRight:'24px'}}>{this.state.current_user.user}</span>
                <button style={{width:'100px'}} onClick={this.assign}>Submit</button>
                <button  onClick={this.assignToMe} className={'default'} >Submit to Me</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Cancel</button>
            </div>
        </div>)
    }

    componentDidMount() {
        this.getUsers()
    }

    getUsers(){
        try {
            Wait.show("Loading Users...")
            TxReadService.getUsers(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log("users to assign",r)
                    this.setState({
                        users:r.data.users,
                        nextTask:r.data.nextTask
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
}
