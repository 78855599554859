import * as React from "react";

import * as LF from "leaflet";

import API from "../../services/API";
import Basemap from "./basemap";

export default class UgSpatial extends React.Component{

    static map = null
    static bound = null
    static layers = null

    static getLayerByName(layer_name,filter='1=1',style=''){

        return LF.tileLayer.wms(API.geo_ilmis + '/wms',style===''? {
            layers: layer_name,
            format: 'image/png',
            transparent: true,
            cql_filter: filter,
            maxZoom:10000
        }: {
            layers: layer_name,
            format: 'image/png',
            transparent: true,
            cql_filter: filter,
            maxZoom:10000,
            styles: style,

        })
    }


    static clear(map_id='ramani'){
        document.getElementById(map_id).innerHTML = "<div id='map' style='width: 100%; height: 100%;'/>";
        if (UgSpatial.map!==null){

            UgSpatial.map.eachLayer(function (layer) {
                UgSpatial.map.removeLayer(layer);
            });

            UgSpatial.map.invalidateSize()
            UgSpatial.map.off()
            UgSpatial.map.remove()
            UgSpatial.map = null
        }else{
            //console.log(UgSpatial.map)
        }

        if (UgSpatial.layers !==null){
            UgSpatial.layers.remove()
        }
    }

    static async getLayerBound(layer_name,filter='1=1',rough_coordinates=true,clear=true){

        return API.ax.get(`smd/read/bounds/${filter}/${layer_name}`).then(r=>{
            try {
                let poly = LF.polygon([r.data.polygon],{color: 'transparent',weight:15,zIndex:30, fillOpacity: 0})
                let ly_group = new LF.FeatureGroup();
                let sat = Basemap.googleSat(),
                    hybrid = Basemap.googleHybrid(),
                    black = Basemap.backWhite(),
                    blank = LF.tileLayer('')

                let map = UgSpatial.map==null?LF.map('map', {
                    layers: [blank, ly_group],
                    attributionControl: false,
                    fullscreenControl: true,
                }).setView([-6.085936, 35.711995], 6.4):UgSpatial.map


                poly.addTo(map)
                map.fitBounds(poly.getBounds())

                //Basemap.googleSat().addTo(map)

                UgSpatial.map = map
                UgSpatial.bound = poly


                let ly_vb = UgSpatial.getLayerByName('valuation_block','1=1').addTo(map)
                let ly_tp = UgSpatial.getLayerByName('tp_spatial_unit','1=1').addTo(map)

                let ly = UgSpatial.getLayerByName(layer_name,filter).addTo(UgSpatial.map)


                let overlays = {
                    //'Grids':grid,
                    'TP Spatial':ly_tp,
                    'Valuation Block':ly_vb
                }

                if (rough_coordinates){
                    let ly_sr = UgSpatial.getLayerByName('survey_instruction_geom','1=1').addTo(map)

                    let overlays = {
                        //'Grids':grid,
                        'TP Spatial':ly_tp,
                        'Valuation Block':ly_vb,
                        'Survey Instruction':ly_sr
                    }
                }


                let baseLayers = {
                    "<span style='color: gray'>Blank</span>": blank,
                    "Satellite": sat,
                    "Hybrid": hybrid,
                    "Black": black,
                }
                ly.setZIndex(1000)

                if(clear){
                    UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)
                }


                return {
                    'map':map,
                    'bound':poly
                }


            }catch (e) {
                console.log(e)
            }
        })
    }

    static async loadLayer(context,layer_name = 'tp_spatial_unit', filter = '1=1',
                           mouse_hovered=true,
                           rough_coordinates=true,clear=true) {
        try {
            if(clear){
                UgSpatial.clear()
            }

            //let mp = await UgSpatial.getLayerBound(layer_name,filter,rough_coordinates,clear)




            UgSpatial.getLayerByName(layer_name,'1=1').addTo(UgSpatial.map)


            let hovered = null
            let selected = null

            if (mouse_hovered){
                UgSpatial.bound.on('mousemove', (e) => {
                    API.geo.get('/wms' + LF.Util.getParamString(UgSpatial.getParamsByEvent(e, layer_name))).then(res => {
                        try {
                            if (hovered !== null) {
                                UgSpatial.map.removeLayer(hovered);
                            }
                            if (res.data.features.length > 0) {
                                hovered = LF.geoJson(res.data.features, {
                                    fillColor: '#ff0',
                                    weight: 2,
                                    opacity: 1,
                                    color: 'white',
                                    fillOpacity: 0.3
                                }).addTo(UgSpatial.map);

                                hovered.on('click', e => {
                                    if (selected !== null) {
                                        UgSpatial.map.removeLayer(selected);
                                    }
                                    selected = LF.geoJson(res.data.features, {
                                        fillColor: '#f00',
                                        weight: 2,
                                        opacity: 1,
                                        color: 'white',
                                        fillOpacity: 0.7
                                    }).addTo(UgSpatial.map);

                                    context.setState({
                                        'clicked':res.data
                                    })

                                })
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    })
                })
            }
        }catch (e) {
            console.log(e)
        }
    }



    static getParamsByEvent(e,layer_name) {
        const target = UgSpatial.map.latLngToContainerPoint(e.latlng, UgSpatial.map.getZoom());
        const size   = UgSpatial.map.getSize();

        const sw = UgSpatial.map.getBounds().getSouthWest();
        const ne = UgSpatial.map.getBounds().getNorthEast();

        return  {
            request: 'GetFeatureInfo',
            query_layers: layer_name,
            layers: layer_name,
            x: Math.round(target.x),
            y: Math.round(target.y),
            height: size.y,
            width: size.x,
            info_format: 'application/json',
            bbox: sw.lng + ',' + sw.lat + ',' + ne.lng + ',' + ne.lat,
        };

    }

}