import React, {Component} from 'react';
import Wait from "../../wait";
import TransactionPopup from "../transaction_popup";
import TxCreateService from "../../../../services/transaction/transaction_create";
import PopDetail from "../../popdetail";
import PaneSearchProperty from "./pane_search_property";
import PopDetailsTwo from "../../PopDetailsTwo";

class PaneSearchPropertyRent extends Component {
    static self = null
    constructor(props) {
        super(props);

        this.state={
            tenure:'',
            districtName:'',
            regionName:'',
            regPlanNumber:'',
            titleNumber:'',
            titleStatus:'Active',
            localityName:'',
            blockNumber:'',
            lotNumber:'',
            ldNumber:'',
            loNumber:'',
            rightHolder:'',
            registerFrom:'',
            registerTo:'',
            planNumber:'',
            allocationStatusName:'',
            landUseName:'',
            drawingNumber:'',
            legalArea:null,
            areaUnitName:'',
            spatialUnitId:null,
            baunitId:null,
        }
        PaneSearchPropertyRent.self = this
        this.handleChange = this.handleChange.bind(this)
       // this.pushProperty = this.pushProperty.bind(this)
        this.getProperties = this.getProperties.bind(this)
    }

/*    pushProperty(d) {
        //this.state.propertyPushedArray.push(d)
        /!*Properties.self.setState({
            landProperties: this.state.propertyPushedArray
        })*!/

        // PopDetail.show('Properties',"aaa")

    }*/
    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <div>

                <div style={{height:'40px',lineHeight:'40px'}}>
                    <b>Search Property</b>
                </div>
                <hr/>

                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td><span>Title Number</span></td>
                        <td><span>Tenure</span></td>
                        <td><span>Title Status</span></td>
                    </tr>
                    <tr>
                        <td><input style={{width: '100%'}} name={"titleNumber"}
                                   onChange={(e)=> this.handleChange(e)}/></td>
                        <td><input style={{width: '100%'}} name={"tenure"} onChange={(e) => this.handleChange(e)}/>
                        </td>
                        <td><input style={{width: '100%'}} name={"titleStatus"}
                                   onChange={(e) => this.handleChange(e)}/></td>
                    </tr>

                    <tr>
                        <td><span>Right Holder</span></td>
                        <td><span>LD Number</span></td>
                        <td><span>Estate Name</span></td>
                    </tr>
                    <tr>
                        <td><input style={{width: '100%'}} name={"rightHolder"}
                                   onChange={(e) => this.handleChange(e)}/></td>
                        <td><input style={{width: '100%'}} name={"ldNumber"}
                                   onChange={(e) => this.handleChange(e)}/></td>
                        <td><input style={{width: '100%'}} name={""} onChange={(e) => this.handleChange(e)}/></td>
                    </tr>
                    <tr>
                        <td><span>Register From</span></td>
                        <td colSpan={2}><span>Register To</span></td>
                    </tr>
                    <tr>
                        <td><input style={{width: '100%'}} name={"registerFrom"}
                                   onChange={(e) => this.handleChange(e)}/></td>
                        <td colSpan={2}><input style={{width: '100%'}} name={"registerTo"}
                                               onChange={(e) => this.handleChange(e)}/></td>

                    </tr>

                    <tr>
                        <td><span>Location</span></td>
                        <td><span>Block</span></td>
                        <td><span>Number</span></td>
                    </tr>
                    <tr>
                        <td><input style={{width: '100%'}} name={"localityName"}
                                   onChange={(e) => this.handleChange(e)}/></td>
                        <td><input style={{width: '100%'}} name={"blockNumber"} onChange={(e) => this.handleChange(e)}/>
                        </td>
                        <td><input style={{width: '100%'}} name={"lotNumber"} onChange={(e) => this.handleChange(e)}/>
                        </td>
                    </tr>
                    <tr style={{textAlign:'center'}}>
                        <td colSpan={3}><button onClick={() => this.getProperties()}>Search</button></td>
                    </tr>
                    </tbody>
                </table>





            </div>
        );
    }
    getProperties(){
        try {
            Wait.show("Loading Property...")
            let data={
                titleNumber:this.state.titleNumber,
                titleStatus:'Active',
                regionName:this.state.regionName,
                districtName:this.state.districtName,
                localityName:this.state.localityName,
                blockNumber:this.state.blockNumber,
                lotNumber:this.state.lotNumber,
                regPlanNumber:this.state.regPlanNumber,
                loNumber:this.state.loNumber,
                ldNumber:this.state.ldNumber,
                rightHolder:this.state.rightHolder,
                registerFrom:this.state.registerFrom,
                registerTo:this.state.registerTo,
                planNumber:this.state.planNumber,
                allocationStatusName:this.state.allocationStatusName,
                landUseName:this.state.landUseName,
                drawingNumber:this.state.drawingNumber,
                legalArea:this.state.legalArea,
                areaUnitName:this.state.areaUnitName,
                spatialUnitId:this.state.spatialUnitId,
                baunitId:this.state.baunitId,
                transactionId:TransactionPopup.id()

            }
            TxCreateService.createGetProperties(data).then(r => {
                Wait.dismiss()
                try {
                    console.log("PropertyLoaded",r)
                    /*this.setState({
                        dataToDisplay: r.data.properties
                    })*/

                    if(r.data.status===1) {

                        /*
                                            if (r.data.properties) {*/


                        PopDetailsTwo.show('Properties', <PaneSearchProperty dataToDisplay={r.data.result}/>)
                        PopDetail.dismiss()
                        /*}else
                         {
                             PopDetail.show('Message',<div>No Data</div>)
                         }*/
                    }else{
                        PopDetail.show(`${r.data.message}`)
                    }}catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
}

export default PaneSearchPropertyRent;
