import * as React from "react";
import MobileReadService from "../../../../services/mobile/mobile_read";
import CommonService from "../../../../services/common/common_read";
import TbDataCollection from "./tables/tb_data_collection";
import TransactionPopup from "../../../popups/transacrions/transaction_popup";
import Wait from "../../../popups/wait";
import DataTable from "datatables.net-dt";
export default class DataCollection extends React.Component {
    static self;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            villages: [],
            hamlets: [],
            current_tab: 'Loading...',
            tab: 0
        }
        DataCollection.self = this

        DataCollection.loadData = DataCollection.loadData.bind(this)
        this.loadVillageData = this.loadVillageData.bind(this)
        this.toggleCheck = this.toggleCheck.bind(this)
        this.loadHamlet = this.loadHamlet.bind(this)
        this.setTabAt = this.setTabAt.bind(this)
        this.submitFilter = this.submitFilter.bind(this)
    }

    componentDidMount() {
        DataCollection.loadData()
        this.loadVillageData()
        this.setTabAt(0)
        let table = new DataTable('#tbb', {})
    }

    toggleCheck(i) {

    }

    toggleFilters() {
        let div = document.getElementById('div-filter')
        div.style.display = div.style.display === 'none' ? 'inherit' : 'none'
    }

    submitFilter() {
        let source = document.getElementById('sp_source').value
        let action = document.getElementById('sp_action').value
        let hamlet = document.getElementById('sp_hamlet').value
        /*let dt_from = document.getElementById('dt_from').value
        let dt_to = document.getElementById('dt_to').value*/

        console.log(source + " - " + action + " - " + hamlet)

        switch (parseInt(action)) {
            case 1:
                switch (parseInt(source)) {
                    case 0:
                        MobileReadService.downloadParcelByStage({
                            project_id: parseInt(this.props.data.ref_id),
                            stage: parseInt(source),
                            hamlet_id: parseInt(hamlet)
                        }).then(r => {
                            try {
                                console.log(r.data)
                            } catch (e) {
                                console.log(e)
                            }
                        })
                        break
                    default:
                        break
                }
                break
            default:
                break
        }

    }

    setTabAt(tab) {
        this.setState({ tab: tab })
        switch (tab) {
            case 0:
                DataCollection.loadData(tab)
                break
            case 1:
                //this.setState({ current_tab:<MapView key={new Date().getMilliseconds()} project_id={this.props.data.ref_id} category={this.props.category}/> })
                break
            case 2:
                break
            default:
                break
        }

    }

    render() {
        return (<div style={{ overflow: 'hidden', height: '100%', marginRight: '16px' }}>

            <div className={'row'} style={{ boxShadow: '0 4px 4px #aaa', padding: '8px 16px', backgroundColor: '#efefef' }}>
                <div className={'col col-sm-8'}>
                    <span className={'fa fa fa-bars'} style={{ cursor: 'pointer', marginTop: '8px' }} onClick={() => this.toggleFilters()} />
                    <input style={{ width: '85%', marginLeft: '24px', backgroundColor: '#fff' }} placeholder={'Search here...'} />
                    <span className={'fa fa fa-plus-circle'} style={{ color: 'darkcyan', float: 'right', cursor: 'pointer', margin: '8px' }} onClick={() => this.toggleFilters()} />
                </div>
                <div className={'col col-sm-4 fa-btn'}>
                    <button style={{ float: 'right', width: '80px' }} onClick={() => TransactionPopup.dismiss()}>Close</button>
                    <button style={{ float: 'right', width: '80px' }} onClick={() => DataCollection.loadData(0)} className={'refresh'}>Refresh</button>
                    <span className={'fa fa-check'} title={'Approve'} style={{ color: 'darkcyan', marginRight: '24px' }} />
                    <span className={'fa fa-times'} title={'Reject'} style={{ color: '#DF4A36' }} />
                    <span className={'fa fa-pencil'} title={'Edit'} />
                    {/*<span className={'fa fa-trash'} title={'Delete'} style={{color:'#DF4A36'}}/>*/}
                </div>

            </div>


            <div className={'col col-sm-8'} style={{ border: '1px solid #ccc', display: 'none', boxShadow: '0 0 10px #888' }} id={'div-filter'}>

                <div className={'row'} style={{ padding: '16px' }}>
                    <div className={'col col-sm-3'}>
                        <select className={'select'} id={'sp_source'}>
                            <option value={-1}>Select Data Source</option>
                            <optgroup label={'Shapefile'}>
                                <option value={0}>Draft Data</option>
                                <option value={1}>GIS Approval</option>
                                <option value={2}>Rejected</option>
                                <option value={4}>LO Approval</option>
                                <option value={5}>Registered</option>
                            </optgroup>
                            <optgroup label={'Documents'}>
                                <option>Verification Form</option>
                                <option>Registry Book</option>
                                <option>CCRO</option>
                                <option>Transaction Sheet</option>
                                <option>Adjudication Form</option>
                                <option>Residential License</option>
                            </optgroup>
                        </select>
                    </div>
                    <div className={'col col-sm-3'}>
                        <select id={'sp_village'} className={'select'} onChange={() => this.loadHamlet()}>
                            <option>Select Village</option>
                            {this.state.villages.map((d, i) => {
                                return (<option value={d.village_id} key={i}>{d.name}</option>)
                            })}
                        </select>
                    </div>

                    <div className={'col col-sm-3'}>
                        <select id={'sp_hamlet'} className={'select'} onChange={() => this.loadData()}>
                            <option>Select Hamlet</option>
                            {this.state.hamlets.map((d, i) => {
                                return (<option value={d.id} key={i}>{d.name} - ({d.description})</option>)
                            })}
                        </select>
                    </div>

                    <div className={'col col-sm-3'}>
                        <select id={'sp_action'} className={'select'} onChange={() => this.loadHamlet()}>
                            <option value={0}>Select Action</option>
                            <option value={1}>Download</option>
                            <option value={2}>Upload</option>
                        </select>
                    </div>

                </div>
                {/*<div className={'row'} style={{padding:'0 16px 16px'}}>

                    <div className={'col col-sm-3'}>
                        <input id={'dt_from'} type={'date'} style={{width:'100%'}}/>
                    </div>
                    <div className={'col col-sm-3'}>
                        <input id={'dt_to'} type={'date'} style={{width:'100%'}}/>
                    </div>

                </div>*/}

                <div style={{ textAlign: 'center', padding: '8px 0 16px 0' }}>
                    <button className={'default'} style={{ width: '100px' }} onClick={() => this.submitFilter()}>Submit</button>
                    <button className={'default'} style={{ width: '100px' }} onClick={() => this.toggleFilters()}>Close</button>
                </div>

            </div>

            <div className={'row'} style={{ marginTop: '1vh' }}>
                <div className={'col col-sm-12'}>
                    <div className={this.state.tab === 0 ? 'tab tab-active' : 'tab'} onClick={() => this.setTabAt(0)}>
                        <span className={'fa fa-list'} /> TableView
                    </div>
                    <div className={this.state.tab === 1 ? 'tab tab-active' : 'tab'} onClick={() => this.setTabAt(1)}>
                        <span className={'fa fa-map'} /> MapView
                    </div>
                    {/*<div className={this.state.tab===2?'tab tab-active':'tab'} onClick={()=>this.setTabAt(2)}>
                        <span className={'fa fa-bar-chart'}/> Statistic
                    </div>*/}

                </div>
            </div>

            {this.state.current_tab}

        </div>)
    }


    static loadData(tab = 0) {
        try {
            Wait.show("Loading....")
            MobileReadService.readParcels(DataCollection.self.props.data.ref_id, tab).then(r => {
                Wait.dismiss()
                try {
                    //console.log(r.services)
                    DataCollection.self.setState({
                        current_tab: <TbDataCollection key={new Date().getMilliseconds()} data={r.data.results}
                            category={DataCollection.self.props.category}
                            project_id={DataCollection.self.props.data.ref_id} />
                    })
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    loadVillageData() {
        try {
            MobileReadService.readVillageConfig(this.props.data.ref_id).then(r => {
                try {
                    //console.log(r.services.results)
                    this.setState({
                        villages: r.data.results
                    })
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    loadHamlet() {
        let village_id = document.getElementById('sp_village').value
        try {
            CommonService.readDictionary('locality', 'hamlet', village_id).then(r => {
                try {
                    console.log(r.data.results)
                    this.setState({
                        hamlets: r.data.results
                    })
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
}