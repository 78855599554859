import * as React from "react";
import Wait from "../../wait";
import UgSpatial from "../../../maps/ug_spatial";
import * as LF from "leaflet";
import API from "../../../../services/API";

import Basemap from "../../../maps/basemap";
import L from "leaflet";
import TransactionPopup from "../transaction_popup";
import SMDCreateService from "../../../../services/smd/smd_create";

export default class PaneShowSurveyInstruction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locality:this.props.locality,
map:"",
            ly_group:""
        }
this.saveInstructionRef = this.saveInstructionRef.bind(this)
    }
    componentDidMount() {
        try {
            let ly_group = new LF.FeatureGroup();
            let sat = Basemap.googleSat(),
                hybrid = Basemap.googleHybrid(),
                blank = LF.tileLayer('')

            let map = LF.map('show-map', {
                layers: [blank, ly_group],
                attributionControl: false,
                fullscreenControl: true,
                maxZoom:10000
            }).setView([-6.085936, 35.711995], 4)

            let baseLayers = {
                "<span style='color: gray'>Blank</span>": blank,
                "Satellite": sat,
                "Hybrid": hybrid
            }

            this.setState({
                map:map,
                ly_group:ly_group,
            })

            let filter = 'transaction_id='+this.props.instruct.transactionId
            let layername = 'survey_instruction'
            let selected
            let ly = L.tileLayer.wms(API.geo_ilmis+'/wms', {
                layers: 'tp_spatial_unit',
                format: 'image/png',
                transparent: true,
                //cql_filter:'transaction_id in('+TransactionPopup.id()+')'
            }).addTo(ly_group)


            API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=> {
                Wait.dismiss()
                try {
                    r.data.features.forEach(p => {

                        let myLayer = LF.geoJSON(p, {
                            onEachFeature: (f, l) => {
                                LF.marker(l.getBounds().getCenter(), {
                                    icon: LF.divIcon({
                                        className: 'bindTooltipBig',
                                        html: "TP Drwaing No. ["+ f.properties.drawing_number+'] Surveyor Type: '+f.properties.surveyor_type ,
                                        iconSize: [100, 40]
                                    })
                                }).addTo(ly_group)
                                l.setStyle({
                                    color: f.properties.status === 'Approved' ? '#00ff00' : '#901'
                                })
                            },
                            coordsToLatLng: function (coords) {
                                return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();
                            },
                            weight: 3, zIndex: 2, fillOpacity: 0
                        }).on('dblclick', e => {

                            /* ValBlockSpatialUnitPane.self.openRatesAt({
                                 id:ValBlockSpatialUnitPane.self.state.id,
                                 blockId:ValBlockSpatialUnitPane.self.state.vblock_id,
                                 status:ValBlockSpatialUnitPane.self.state.status,
                             })*/

                        }).on('click', function (e) {

                            if (selected) {
                                e.target.resetStyle(selected)
                            }
                            selected = e.layer

                            /*ValBlockSpatialUnitPane.self.setState({
                                region:p.properties.region,
                                district:p.properties.district,
                                council:p.properties.council,
                                locality:p.properties.locality,
                                status:p.properties.status,
                                vblock_id:p.properties.vblock_id,
                                id:p.id.split('.')[1],
                                blockId:p.properties.blockId
                            })*/
                            selected.bringToFront()
                            selected.setStyle({color: '#000', weight: 4})
                            map.fitBounds(selected.getBounds())

                        })
                        myLayer.addTo(ly_group)
                    })

                    let myLayer = LF.geoJSON(r.data, {
                        coordsToLatLng: function (coords) {
                            return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();
                        }
                    })
                    try {
                        map.fitBounds(myLayer.getBounds())
                    } catch (e) {
                    }

                } catch (e) {
                    console.log(e)
                }

            })
           /* let ly_vb = UgSpatial.getLayerByName('valuation_block',filter).addTo(map)
            let ly_tp = UgSpatial.getLayerByName('tp_spatial_unit').addTo(map)
            let ly_sp = UgSpatial.getLayerByName('spatial_unit').addTo(map)

            console.log(ly_tp)

            let overlays = {
                'Valuation Blocks':ly_vb,
                'TP Drawing':ly_tp,
                'Spatial Unit':ly_sp,
                'Uploaded Layer':ly_group,
            }

            LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)
            /!*setTimeout(e=>{ this.loadSHP()},500)*!/*/

        }catch (e){
            console.log(e)
        }
    }

    render() {
        return(<div>


                <div id={'show-map'} style={{width:'inherit', height:'50vh'}}></div>
            <div style={{borderTop:'1px solid #ccc',width:'100%',height:'30vh'}}>

                <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                    <thead>
                    <tr>
                        <th>Survey Type</th>
                        <th>Instruction Number</th>
                        <th>TP Drawing Number</th>
                        <th>Number of Parcel</th>
                        <th>Lease</th>
                        <th>Approve Date</th>
                        <th>Council</th>
                        <th>Locality</th>
                    </tr>

                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.props.instruct.type}</td>
                        <td>{this.props.instruct.instructionNumber}</td>
                        <td>{this.props.instruct.drawingNumber}</td>
                        <td>{this.props.instruct.numberOfParcel}</td>
                        <td>{this.props.instruct.lease}</td>
                        <td>{this.props.instruct.approve_date}</td>
                        <td>{this.props.instruct.council}</td>
                        <td>{this.props.instruct.locality}</td>
                    </tr>
                    </tbody>
                </table>


                <div className={'row'}>
                    {console.log("propss",this.props)}
                    <button onClick={this.saveInstructionRef}>Save</button>
                </div>
            </div>


        </div>)
    }

    saveInstructionRef() {
        Wait.show("Saving .... .")
        try {
        
        let dataToSend = {
            transactionId: TransactionPopup.id(),
            surveyInstructionId:this.props.instruct.surveyInstructionId
        }
        console.log("instruction transaction to save===", dataToSend)
        SMDCreateService.addSurveyInstructionTransaction(dataToSend).then(d => {

            if (d.status == 1) {
                Wait.dismiss()
            } else {
                Wait.dismiss()
            }
        })

    }catch (e) {
            
        }

    }
}