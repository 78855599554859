import * as React from "react";
import PaneParcelMap from "./parcel/pane_parcel_map";
import PaneParcelDocument from "./parcel/pane_parcel_document";
import PaneParcelInfo from "./parcel/pane_parcel_info";
import PaneOwnersInfo from "./parcel/pane_owners_info";
import ParcelRemark from "../../../pane/common/remarks";
import PanePayment from "./parcel/pane_payment";
export default class PopParcel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabs: ['Parcel Info', 'Owners Info', 'Spatial Unit', 'Payments', 'Remarks', 'Documents'],
            pane: [<PaneParcelInfo parcel={this.props.parcel} />, <PaneOwnersInfo parcel={this.props.parcel} />,
            <PaneParcelMap parcel={this.props.parcel} />, <PanePayment parcel={this.props.parcel} handler={null} />,
            <ParcelRemark is_open={false} updateParcels={this.props.updateParcels} parcel={this.props.parcel} />, <PaneParcelDocument parcel={this.props.parcel} />],
            current_id: 0
        }

        this.loadDataAt = this.loadDataAt.bind(this)
    }

    componentDidMount() {
        this.setState({
            tabs: ['Parcel Info', 'Occupancy Info', 'Spatial Unit', 'Payments', 'Remarks', 'Documents'],
            pane: [<PaneParcelInfo parcel={this.props.parcel} />, <PaneOwnersInfo parcel={this.props.parcel} />,
            <PaneParcelMap parcel={this.props.parcel} />, <PanePayment parcel={this.props.parcel} handler={null} />,
            <ParcelRemark updateParcels={this.props.updateParcels} is_open={false} parcel={this.props.parcel} />, <PaneParcelDocument parcel={this.props.parcel} />],
        })
        this.loadDataAt(0)
    }

    loadDataAt(tab) {
        this.setState({ current_id: tab })

    }

    render() {
        return (<div className={'row'} style={{ height: '100%', overflowY: 'hidden' }}>
            <div className={'col col-sm-12'} style={{ position: 'sticky' }}>
                {this.state.tabs.map((d, i) => {
                    return (<div key={i} onClick={() => this.loadDataAt(i)} className={this.state.current_id === i ? 'tab tab-active' : 'tab'}>{d}</div>)
                })}
            </div>
            <div className={'col col-sm-12'} style={{ height: '100%', overflowX: 'hidden' }}>
                {this.state.pane[this.state.current_id]}
            </div>
        </div>)
    }
}