import * as React from "react";

export default class PrintDocPane extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:"data:application/pdf;base64,"

        }
    }


    render() {
        return(

            <embed type="application/pdf"
                   height="100%"
                   width="100%"
                   src={"data:application/pdf;base64, "+this.props.base64}/>

        )
    }
}