import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './index.css';
// import 'react-vertical-timeline-component/style.min.css';
import * as serviceWorker from './serviceWorker';
import App from "./App";
import PopDetail from "./apps/components/popups/popdetail";
import Wait from "./apps/components/popups/wait";


import 'axios-progress-bar/dist/nprogress.css'
import PopDetailsTwo from "./apps/components/popups/PopDetailsTwo";
import AddBillItem from "./apps/components/popups/transacrions/pops/pane_add_bill_item";
import RectificationParameter from "./apps/components/popups/transacrions/screen/objects/rectificationParameter";
import Users from "./apps/components/popups/uma/users";
import SpatialUpdating from "./apps/components/pane/SpatialUpdating";
import LastPayDateUpdates from "./apps/components/popups/transacrions/pops/lastPayDateUpdates";
import ValuationRatesUpdate from "./apps/components/popups/transacrions/pops/pane_update_valuation";
import TpSpatialUpdate from "./apps/components/popups/transacrions/pops/pane_update_tp_spatial";
import BaUnitUpdates from "./apps/components/popups/transacrions/pops/baunitUpdates";
import Uma from "./apps/components/popups/uma/uma";
import PopFinanceTransactions from "./apps/components/popups/transacrions/pops/reports/tracking/finance_transactions";
import InstructionToSurvey from "./apps/components/popups/transacrions/screen/instruction_to_survey";
import TestMap from './testmap';


ReactDOM.render(
    <>
        {/*  <Uma/>*/}
        {/* <Users/>*/}
        {/* <SpatialUpdating/>*/}
        {/*<ValuationRatesUpdate/>*/}
        {/*  <TpSpatialUpdate/>*/}
        {/* <InstructionToSurvey/>*/}
        {/*<LastPayDateUpdates/>*/}
        {/*<BaUnitUpdates/>*/}
        {/* <PopFinanceTransactions/>
        <Wait/>
        <PopDetail/>*/}
        {/*<Wait/>
        <PopDetail/>*/}
        {/* <SelectLegacyPlots/>
        <PopDetail/>*/}
        {/* <RectificationParameter/>*/}
        <App />
        {/* <TestMap /> */}
        {/*<AddTpInfoPane2/>*/}
        {/* <UploadSpatialPane/>*/}
        {sessionStorage.getItem('username') === null ? <><Wait /><PopDetail /></> : ''}
    </>,

    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
