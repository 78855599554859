import React, {Component} from 'react';
import Header from "./header";
import {styles} from "../record-request";
import TxReadService from "../../../../../services/transaction/transaction_read";
import TransactionPopup from "../../transaction_popup";

class BaUnitTitle extends Component {
    constructor(props) {
        super(props);
        this.state={
            baunit:{}
        }
    }

    componentDidMount() {

        TxReadService.getBaunitTransaction(TransactionPopup.id()).then(d=>{
            if(d.data.status===1) {
                console.log("BAUNIT ==>> >",d)
                this.setState({baunit: d.data.result})

            }
        })
    }

    render() {
        return (
            <>
                <Header display={false} label={"Properties/Title"} heading={this.props.heading}/>
                <div style={{...styles.content,height:'170px'}}>
                    <table className={'tb'} style={styles.table}>
                        <thead>
                        <tr>
                            <th>TITLE NUMBER</th>
                            <th>TYPE</th>
                            <th>TENURE</th>
                            <th>LD NUMBER</th>
                            <th>TOTAL AREA</th>
                            <th>TOTAL VALUE, TSH</th>
                            <th>REGISTRATION NO</th>
                            <th>VERSION</th>
                            <th>STATUS</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{this.state.baunit.titleNumber}</td>
                            <td>{this.state.baunit.typeName}</td>
                            <td>{this.state.baunit.tenureTypeName}</td>
                            <td>{this.state.baunit.ldNumber}</td>
                            <td>{this.state.baunit.legalArea}</td>
                            <td>{this.state.baunit.landRate}</td>
                            <td>{this.state.baunit.totalValue}</td>
                            <td>{this.state.baunit.registrationNo}</td>
                            <td>{this.state.baunit.version}</td>
                            <td>{this.state.baunit.baunitStatus}</td>
                            <td></td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default BaUnitTitle;
