import * as React from "react";
import MobileReadService from "../services/mobile/mobile_read";
import MobilePopup from "../components/popups/mobile/mobile_popup";

export default function MobileReport() {
    const [curReport, setCurReport] = React.useState(0);
    const [showReport, setShowReport] = React.useState(false);
    const reports = [
        "General Report",
        "User Performance M&E Report",
        "Parcel Ownership Report",
        "Land use Report",
    ];
    const [reportResponse, setReportResponse] = React.useState([]);
    const [, forceUpdate] = React.useState(null);
    const onReportTypeChange = (e) => {
        setCurReport(e.target.value);
        setShowReport(false)
        forceUpdate({});
    };
    return (
        <div style={{margin:10}}>
            <div className="row">
                <div className="col-md-12">
                    <select onChange={onReportTypeChange} className="form-control mb-3">
                        {reports.map((prop, key) => (
                            <option value={key} key={key}>
                                {prop}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-12">
                    {showReport ? (
                        <ChosenReport report={curReport} response={reportResponse}/>
                    ) : (
                        <ReportForm
                            report={curReport}
                            setResponse={(res) => {
                                setShowReport(true);
                                setReportResponse(res);
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
function ChosenReport({ report,response }) {
    switch (Number(report)) {
        case 1:
            return <UserReport data={response} heading={["USER","REMARK"]}/>;
        case 0:
            return <UserReport data={response} heading={["LAYER NAME","SINGLE TENANCY","JOINT TENANCY","JOINT TENANCY UNEQUAL","TENANCY EQUAL","ISTITUTION","CLAN","GUARDIAN","INHERITANCE","MALE","FEMALE","NATIONAL ID","OTHER ID","Total"]}/>;
        default:
            return <UserReport data={response} heading={["USER","REMARK"]}/>;
    }
}

function ReportForm({ report, setResponse }) {

    switch (Number(report)) {
        case 0:
            return <GeneralReportForm setResponse={setResponse} />;
        case 1:
            return <UserReportForm setResponse={setResponse} />;
        default:
            return null;
    }
}

function GeneralReportForm({setResponse}) {
    const [fromv, setFrom] = React.useState("");
    const [tov, setTo] = React.useState("");
    const [range, showRange] = React.useState(false);
    const [stage,setStage] = React.useState(0);
    const getReport = () => {
        MobileReadService.readGeneralReport(MobilePopup.id(),stage,fromv,tov).then((r)=>{
            setResponse(r.data.results);
        })
    }
    const getWeek = () => {
        const today = new Date();
        const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const startDate = new Date(today);
        const endDate = new Date(today);

        startDate.setDate(today.getDate() - currentDay + 1);
        endDate.setDate(today.getDate() - currentDay + 5);

        return { startDate, endDate };
    }
    const setDate = (num) => {
        if(num<0){
            const today = new Date();
            const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
            const maxD = new Date(today);
            let m = maxD.toISOString().slice(0, 10)
            document.getElementById("todate").setAttribute("max",m);
            document.getElementById("fromdate").setAttribute("max",m);
        }
        const {startDate, endDate} = getWeek();
    }

    return (<div className="row">
        <div className="col-md-6 offset-3">

        <div className="card">
            <div className="card-body">
                <IlmisForm onSubmit={
                    ()=>{getReport()}
                }>
                    <select onChange={(e)=>{
                        setStage(e.target.value)
                    }} className="form-control mb-3">
                        <option>Choose Stage</option>
                        <option value={"all"}>All</option>
                        <option value={4}>Registered</option>
                        <option value={0}>Mobile Data</option>
                        <option value={1}>Rejected</option>
                    </select>
                    <select className="form-control select2 mb-3" onChange={(e) => {
                                        if (e.target.value < 0) {
                                            showRange(true);

                                        }
                                        else {
                                            setDate(-1);
                                            showRange(false);
                                        }
                                    }}>
                                        <option value={''}>Choose Report Type</option>
                                        <option value={0}>Today</option>
                                        <option value={7}>Weekly</option>
                                        <option value={30}>Monthly</option>
                                        <option value={-1}>Range</option>
                                    </select>
                                    <div style={{ display: range ? "flex" : "none" }} className="row">
                                <div className="col-md-6">
                                    <label>From Date</label>
                                    <br />
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="From Date"
                                        // max={Date.now()}
                                        id="fromdate"
                                        onChange={(e) => {
                                            setFrom(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>End Date</label>
                                    <br />
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="todate"
                                        placeholder="End Date"
                                        onChange={(e) => {
                                            setTo(e.target.value);
                                        }}
                                    />
                                </div>

                            </div>
                            <input type="submit" value={"Query"} className="btn"/>
                </IlmisForm>
            </div>
        </div>
        </div>

    </div>)
}
function IlmisForm({ children, onSubmit }) {
    const onSubmitt = (e) => {
        e.preventDefault();
        console.log(e);
        onSubmit(e);
    };
    return <form onSubmit={onSubmitt}>{children}</form>;
}
function UserReportForm({ setResponse }) {
    const [fromv, setFrom] = React.useState("");
    const [tov, setTo] = React.useState("");
    const [range, showRange] = React.useState(false);
    const getReport = () => {
        MobileReadService.readUserReport(MobilePopup.id(),fromv,tov).then((r)=>{
            setResponse(r.data.results);
        })
    }
    const getWeek = () => {
        const today = new Date();
        const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const startDate = new Date(today);
        const endDate = new Date(today);

        startDate.setDate(today.getDate() - currentDay + 1);
        endDate.setDate(today.getDate() - currentDay + 5);

        return { startDate, endDate };
    }
    const setDate = (num) => {
        if(num<0){
            const today = new Date();
            const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
            const maxD = new Date(today);
            let m = maxD.toISOString().slice(0, 10)
            document.getElementById("todate").setAttribute("max",m);
            document.getElementById("fromdate").setAttribute("max",m);
        }
        const {startDate, endDate} = getWeek();
    }
    React.useEffect(()=>{
setDate(-1);
    },[]);
    return (
        <div className="row">
            <div className="col-md-6 offset-3">
                <div className="card">
                    <div className="card-body">

                        <IlmisForm
                            onSubmit={(e) => {
                                MobileReadService.readUserReport(MobilePopup.id(), fromv,tov)
                                    .then((r) => {
                                        // setRep(r.data.results);
                                        setResponse(r.data.results);
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                    });
                            }}
                        >
                            <div className="row">
                                <div className="col-md-12 mt-3 mb-3">
                                    <select className="form-control select2" onChange={(e) => {
                                        if (e.target.value < 0) {
                                            showRange(true);

                                        }
                                        else {
                                            setDate(-1);
                                            showRange(false);
                                        }
                                    }}>
                                        <option value={''}>Choose Report Type</option>
                                        <option value={0}>Today</option>
                                        <option value={7}>Weekly</option>
                                        <option value={30}>Monthly</option>
                                        <option value={-1}>Range</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: range ? "flex" : "none" }} className="row">
                                <div className="col-md-6">
                                    <label>From Date</label>
                                    <br />
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="From Date"
                                        // max={Date.now()}
                                        id="fromdate"
                                        onChange={(e) => {
                                            setFrom(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>End Date</label>
                                    <br />
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="todate"
                                        placeholder="End Date"
                                        onChange={(e) => {
                                            setTo(e.target.value);
                                        }}
                                    />
                                </div>

                            </div>

                        </IlmisForm>
                        <br />
                        <button className="btn btn-save" onClick={getReport}>Query Report</button>
                    </div>
                </div>
                <div className="row">
                </div>
            </div>
        </div>
    );
}
function UserReport({ data, heading }) {
    // console.log(data)
    const [rep, setRep] = React.useState([]);
    // alert(JSON.stringify(data[0])); 
    React.useEffect(()=>{
        setRep(data)
    },[]);
    return (
        <div>
            <div className="mb-3">
                <button style={{float:"right"}} onClick={()=>{exportTableToCSV("dowload.csv")}} className="btn" ><i className="fa fa-download"></i> Download CSV</button>
            </div>
            <br/>
            <br/>
            <table id="ilmistable" className="table table-bordered tbilmis table-striped table-hover">
                <thead>
                    <tr>
                        {heading.map((prop)=>(

                        <th>{prop}</th>
                        ))}
                    </tr>
                </thead>
                <tbody style={{ position: "sticky" }}>
                    {data.map((prop, key) => {
                    return(
                        <tr key={key}>
                            {
                                prop.map((pro,k)=>(
                                    <td key={k}>{pro}</td>
                                ))
                            }
                        </tr>
                    )})}
                </tbody>
            </table>
        </div>
    );
}
function exportTableToCSV(filename) {
    const table = document.getElementById("ilmistable");
    const rows = table.querySelectorAll("tr");
    const csvData = [];

    // Get column names from the first row (thead)
    const columnNames = Array.from(rows[0].querySelectorAll("th")).map(th => th.textContent);
    csvData.push(columnNames);

    // Iterate through rows and extract cell data
    for (let i = 1; i < rows.length; i++) {
        const rowData = Array.from(rows[i].querySelectorAll("td")).map(td => td.textContent);
        csvData.push(rowData);
    }

    // Convert the data to CSV format
    const csvContent = csvData.map(row => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
