export function today() {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth()+1;
    const yyyy = today.getFullYear();
    if(dd<10)
    {
        dd=`0${dd}`;
    }

    if(mm<10)
    {
        mm=`0${mm}`;
    }
    return `${dd}/${mm}/${yyyy}`
}
export function commaSeparate(x) {
    return x.toLocaleString();
}