import React, {Component} from 'react';
import transaction_popup from "../transaction_popup";
import TicCreateService from "../../../../services/tic/tic_create";

class PaneProcessForm1LadApproval extends Component {
    constructor(props) {
        super(props);
        this.state={
            status:'Accepted',
            nextTransaction:'GRO',
            remarks:"",
            applyBtn:<div></div>
        }
        this.handleChange =this.handleChange.bind(this)
        this.saveApproval =this.saveApproval.bind(this)
    }
    componentDidMount() {

    }
    handleChange=e=>{
        console.log("data to set",e.target.value)
        this.setState({[e.target.name]:e.target.value})
    }
    saveApproval(){
        let dataToSend={
            transactionId:transaction_popup.id(),
            status:this.state.status,
            nextTransaction:this.state.nextTransaction,
            remarks: this.state.remarks
        }
        console.log("data to send",dataToSend)
        TicCreateService.SaveTxApploval(dataToSend).then(d=>{
console.log("data returned==",d)
        })

    }
    render() {
        return (
            <div>
                <div className={'row'}>
                    <div className={'col-sm-4'}><h3>Application Approval</h3></div>
                    <div className={'col-sm-2'}>
                        <span> <span style={{color:'red'}}>*</span>Final Application Decision</span>
                        <select defaultValue={this.state.status} onChange={this.handleChange}>
                            <option value={'Accepted'}>Accepted</option>
                            <option value={'Rejected'}>Rejected</option>
                        </select>
                    </div>
                    <div className={'col-sm-2'}>

                        <span>Next Transaction</span>
                        <select defaultValue={this.state.nextTransaction} onChange={this.handleChange}>
                            <option value={''}></option>
                            <option value={'GRO'}>GRANT</option>
                            <option value={'TRRO'}>TRANSFER</option>
                        </select>
                    </div>
                    <div className={'col-sm-4'} style={{float:'left'}}>
                <span>
                <span style={{marginLeft:'16px'}} className={'fa fa-check-circle-o screen-action'} onClick={this.validateTask} />Validate
                </span>
                        <button onClick={this.saveApproval}>Apply</button>
                        {/*{this.state.applyBtn}*/}
                        {/*<span><span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'} onClick={()=>PopDetail.show('Apply Approve',<PaneApplyLadApproval decision={this.state.decision}/>,70)}/> Apply</span>*/}

                    </div>
                </div>
            </div>
        );
    }
}

export default PaneProcessForm1LadApproval;