import React,{useEffect} from 'react';
import {useForm} from "react-hook-form";
import API from "../../../../../services/API";
import PopDetail from "../../../popdetail";
import Message from "../gro/person/message";

function UnitsAdd(props) {

        const{register,handleSubmit} = useForm()
        const details = data => {
            let  dataToSend = {
                unitNumber: data.unitNumber,
                unitFactor: data.unitFactor,
                phase: data.phase,
                unitArea: data.unitArea,
                floorArea: data.floorArea,
                unitDetails: data.unitDetails,
                unitPlanId: props.unitPlanId
            }
            console.log("data to send", dataToSend )
            API.ax.post("/rt/create/unit-create", dataToSend).then(d =>{
                console.log("saved units " +  JSON.stringify(d.data))
                if (d.data.status === 0){
                    PopDetail.show("Error",<Message faIcon={"hazard"} message={d.data.message}/>,40)
                }

                if(props.refreshUnit === 0){
                    props.setRefreshUnit(1)
                }
                else {
                    props.setRefreshUnit(0)
                }


            }).catch(e => {
                console.log(e)
            })
            PopDetail.dismiss()
        }


    console.log("phases in unit" + JSON.stringify(props.phases))


        return (
        <div>
            <form onSubmit={handleSubmit(details)}>
                <label>*Unit Number/Name<br/>
                    <input type={"text"} name={"unitNumber"} style={{width: "400px"}} ref={register({required: true})} />
                </label>

                <br/>
                <label>*Unit Factor<br/>
                    <input type={"number"} name={"unitFactor"} step={0.01} style={{width: "400px"}} ref={register({required: true})}/>
                </label>

                <br/>
                <label>*Phase<br/>
                    <select className={"select"} style={{width: "100%", marginRight: "120px"}} name={"phase"} ref={register} >
                        <option disabled={true}>Select</option>
                        {props.phases.map((d,i)=>{
                            return(<option key={i} value={d.planPhaseId}>{d.phaseNameNumber}</option>)
                        })}
                    </select>
                </label>

                <br/>
                <label>*UNIT AREA (SQ.M)<br/>
                    <input type={"number"} name={"unitArea"} step={0.01} style={{width: "400px"}} ref={register({required: true})}/>
                </label>

                <br/>
                <label>*FLOOR AREA (SQ.M)<br/>
                    <input type={"number"} name={"floorArea"} step={0.01} style={{width: "400px"}} ref={register({required: true})}/>
                </label>

                <br/>
                <label> UNIT DETAILS<br/>
                    <input type={"text"} name={"unitDetails"} style={{width: "400px"}} ref={register}/>
                </label>
                <br/>
                <button type={'submit'} style={{width:'90px',paddingBottom:'.3rem'}}>Save</button>
                {/*<input  style={{width:'85px',paddingBottom:'.2.5rem'}}>Cancel</input>*/}
            </form>
        </div>
    );
}

export default UnitsAdd;