import React, {Component} from 'react';
import API from "../API";

export default class RtcheckListRead extends Component {
    static rtCheckList(transactionId) {
        return API.ax.get(`rt/rt-checklist/${transactionId}`).catch(e => {
            console.log(e);
        })
    }

    static rtCheckListQuestions(transactionId) {
        return API.ax.get(`rt/rt-questions/${transactionId}`).catch(e => {
            console.log(e);
        })
    }
}

