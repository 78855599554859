import React, {useEffect, useState} from 'react'
import Header from "../gro/header";
import PopDetail from "../../../popdetail";
import Select from "react-select";
import {colourStyles} from "../../../../../componentss/valuation-tabs/purpose";
import {useForm} from "react-hook-form";
import PopDetailsTwo from "../../../PopDetailsTwo";
import Message from "../gro/person/message";
import TransactionPopup from "../../transaction_popup";
import Wait from "../../../wait";
import API from "../../../../../services/API";
import DeedTittle from "./deedTittle";

const AddOfficial =({handleSubmitObj})=>{
    const[selectedOption] = useState()
    const[tenure,tenureSet] = useState({})
    const {register,handleSubmit} = useForm()
    const onChange = data =>{
        console.log("Tenure ",data)
        tenureSet(data)
    }
    const submit = (data) =>{
        if(tenure.hasOwnProperty("value")){
            handleSubmitObj({tenureTypeId:tenure.value,titleNumber:data.titleNumber})
        }else {
            PopDetailsTwo.show("Select Tenure",<Message action={2} message={"Please Select a tenure"} faIcon={"harzad"}/>,30)
        }
    }
    return(
        <form onSubmit={handleSubmit(submit)}>
        <div style={{display:"flex", flexDirection:"column"}}>
            <label><span>*</span>Type</label>
            <input name={"type"} readOnly={true} defaultValue={"Property/Title"} type={"text"}/>
            <label><span>*</span>Tenure</label>
            <Select
                value={selectedOption}
                onChange={onChange}
                options={options}
                placeholder={"Select Tenure"}
                styles ={colourStyles}
            />
            <label><span>*</span>Title Number</label>
            <input name={"titleNumber"} type={"text"} ref={register({required:true})}/>
            <div style={{display:"flex",marginTop:"1rem",flexDirection:"row-reverse"}}>
                <button className={"default"} style={{width:"150px"}} onClick={PopDetail.dismiss}>Cancel</button>
                <button style={{width:"150px"}} type={"submit"}>Save</button>
            </div>
        </div>
      </form>
    )
}



/* Adding object for Grant LeaseHold Transaction which is different
from the the normal objects it has {type(interest),
tenure(Right of Occupation), Tittle Number, interest Type(Lease), and Fd Number} */

const AddLeaseOfficial =({handleLeasSubmitObj})=>{
    const {register,handleSubmit} = useForm()
    const submit = (data) =>{
            handleLeasSubmitObj(data)
    }
    return(
        <form onSubmit={handleSubmit(submit)}>
            <div style={{display:"flex", flexDirection:"column"}}>
                <label><span>*</span>Type</label>
                <input name={"type"} readOnly={true} defaultValue={"Interest"}  type={"text"}/>
                <label><span>*</span>Tenure</label>
                <input name={"type"} readOnly={true} defaultValue={"Right of Occupancy"} type={"text"}/>
                <input name={"tenureTypeId"} readOnly={true} value={1} ref={register({required:true})} type={"hidden"}/>
                <label><span>*</span>Title Number</label>
                <input name={"titleNumber"} type={"text"} ref={register({required:true})}/>
                <label><span>*</span>Interest Type</label>
                <input name={"interestType"} readOnly={true} defaultValue={"Lease"} ref={register({required:true})} type={"text"}/>
                <label>Fd Number</label>
                <input name={"fdNumber"} type={"text"} ref={register({required:false})}/>
                <div style={{display:"flex",marginTop:"1rem",flexDirection:"row-reverse"}}>
                    <button className={"default"} style={{width:"150px"}} onClick={PopDetail.dismiss}>Cancel</button>
                    <button style={{width:"150px"}} type={"submit"}>Save</button>
                </div>
            </div>
        </form>
    )
}

/* Adding object for Transfer Transaction which is different
from the the normal objects it has {type(interest),
tenure(Unit Ownership), Tittle Number, interest Type(Unit), and Fd Number} */

const AddUnitOfficial =(props)=>{
    const {register,handleSubmit} = useForm()
    const submit = (data) =>{
        console.log("unit data =>>>", data)
        props.handleUnitSubmit(data)
    }
    return(
        <form onSubmit={handleSubmit(submit)}>
            <div style={{display:"flex", flexDirection:"column"}}>
                <label><span>*</span>Type</label>
                <input name={"type"} readOnly={true} defaultValue={"Interest"}  type={"text"}/>
                <label><span>*</span>Tenure</label>
                <input name={"type"} readOnly={true} defaultValue={"Unit Ownership"} type={"text"}/>
                <input name={"tenureTypeId"} readOnly={true} value={1} ref={register({required:true})} type={"hidden"}/>
                <label><span>*</span>Title Number</label>
                <input name={"titleNumber"} type={"text"} ref={register({required:true})}/>
                <label><span>*</span>Interest Type</label>
                <input name={"interestType"} readOnly={true} defaultValue={"Unit"} ref={register({required:true})} type={"text"}/>
                <label>Fd Number</label>
                <input name={"fdNumber"} type={"text"} ref={register({required:false})}/>
                <div style={{display:"flex",marginTop:"1rem",flexDirection:"row-reverse"}}>
                    <button className={"default"} style={{width:"150px"}} onClick={PopDetail.dismiss}>Cancel</button>
                    <button style={{width:"150px"}} type={"submit"}>Save</button>
                </div>
            </div>
        </form>
    )
}



export const AddOfficialDetails =({handleDetails}) =>{
    const{handleSubmit, register} = useForm()
    const handleDetail =data =>{
        handleDetails(data,false)
    }
    return(
        <>
            <form onSubmit={handleSubmit(handleDetail)}>
                <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                    <label>Lot Number</label>
                    <input type={"text"} name={"lotNumber"} ref={register({required:true})}/>
                </div>
                <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                    <label>Block</label>
                    <input type={"text"} name={"block"} ref={register({required:true})}/>
                </div>
                <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                    <label>Location</label>
                    <input type={"text"} name={"location"} ref={register({required:true})}/>
                </div>
                <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                    <label>Council</label>
                    <input type={"text"} name={"council"} ref={register({required:true})}/>
                </div>
                <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",width:"100%", marginTop:".2rem"}}>
                    <button style={{width:"150px"}} type={"submit"}>Save</button>
                    <button style={{width:"150px"}} className={"default"} onClick={PopDetail.dismiss}>Cancel</button>
                </div>
            </form>
        </>
    )
}

export default function Official({writable}) {
    const[object,objectSet] = useState([])
    const[objPresent,objPresentSet] = useState(true)
    const[isMortgage, isMortgageSet] = useState(false)
    let transactionId = TransactionPopup.id();
    useEffect(()=>{

        API.ax.get(`rt/services/search-object/${transactionId}`)
            .then(d=>{
                const{ status,result} = d.data;
                if(status === 1){
                    console.log("the objects", result)
                    objectSet(result)
                    if(TransactionPopup.state().transaction.name === "Mortgage Right of Occupancy"){
                        objPresentSet(false)
                        isMortgageSet(true)
                    }else{
                        objPresentSet(true)
                    }
                }else{
                    objPresentSet(false)
                }
            })
    },[])

    const handleSubmit = (data ,isObj = true) =>{
        let endPoint =isObj?"rt/services/search-object/":"rt/services/search-objDetails/";
        let toSend = {...data,transactionId};
        Wait.show("Saving ...")
        API.ax.post(endPoint,toSend)
            .then(d=>{
                let {status,message} = d.data
                if(status === 1){
                    API.ax.get(`rt/services/search-object/${transactionId}`)
                        .then(d=>{
                            Wait.dismiss()
                            console.log("the answer ",d.data)
                            const{ status,result} = d.data;
                            if(status === 1){
                                objectSet(result)
                                if(TransactionPopup.state().transaction.name === "Mortgage Right of Occupancy"){
                                    objPresentSet(false)
                                    isMortgageSet(true)
                                }else{
                                    objPresentSet(true)
                                }
                            }
                        })
                }else if( status === 2){

                    let lists="<ul>";
                    let conditions = message.join(" ,")
                    message.forEach((data)=>{
                        lists+= "<li>"+data+"</li>";
                    })
                    lists+="</ul>";
                    Wait.dismiss();
                    PopDetailsTwo.show("Error",<Message faIcon={"harzad"} message={conditions} action={1}/>,40)
                }else{
                    Wait.dismiss()
                    PopDetailsTwo.show("Error",<Message faIcon={"harzad"} message={message} action={1}/>,40)
                }
            }).catch(e=>{
                Wait.dismiss()
        })
        console.log("object data ",{...data,transactionId})
        PopDetail.dismiss()
    }



    const handleLeaseSubmit = (data) =>{
        let endPoint = "rt/services/search-lease-object/";
        let toSend = {...data,transactionId};
        Wait.show("Saving ...")
        API.ax.post(endPoint,toSend)
            .then(d=>{
                console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhh',d.data)
                let {status,message} = d.data
                if(status === 1){
                    API.ax.get(`rt/services/search-object/${transactionId}`)
                        .then(d=>{
                            Wait.dismiss()
                            console.log("the answer ",d.data)
                            const{ status,result} = d.data;
                            if(status === 1){
                                objectSet(result)
                                if(TransactionPopup.state().transaction.name === "Mortgage Right of Occupancy"){
                                    objPresentSet(false)
                                    isMortgageSet(true)
                                }else{
                                    objPresentSet(true)
                                }
                            }
                        })
                }else if( status === 2){

                    let lists="<ul>";
                    let conditions = message.join(" ,")
                    message.forEach((data)=>{
                        lists+= "<li>"+data+"</li>";
                    })
                    lists+="</ul>";
                    Wait.dismiss();
                    PopDetailsTwo.show("Error",<Message faIcon={"harzad"} message={conditions} action={1}/>,40)
                }else{
                    Wait.dismiss()
                    PopDetailsTwo.show("Error",<Message faIcon={"harzad"} message={message} action={1}/>,40)
                }
            }).catch(e=>{
            Wait.dismiss()
        })
        console.log("object data ",{...data,transactionId})
        PopDetail.dismiss()
    }

    const handleUnitSubmit = (data) =>{
        let endPoint = "rt/services/search-unit-object/";
        let toSend = {...data,transactionId};
        console.log("to send unit data ===>", toSend)
        Wait.show("Saving ...")
        API.ax.post(endPoint,toSend)
            .then(d=>{
                console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhh',d.data)
                let {status,message} = d.data
                if(status === 1){
                    API.ax.get(`rt/services/search-object/${transactionId}`)
                        .then(d=>{
                            Wait.dismiss()
                            console.log("the answer ",d.data)
                            const{ status,result} = d.data;
                            if(status === 1){
                                objectSet(result)
                                if(TransactionPopup.state().transaction.name === "Mortgage Right of Occupancy"){
                                    objPresentSet(false)
                                    isMortgageSet(true)
                                }else{
                                    objPresentSet(true)
                                }
                            }
                        })
                }else if( status === 2){

                    let lists="<ul>";
                    let conditions = message.join(" ,")
                    message.forEach((data)=>{
                        lists+= "<li>"+data+"</li>";
                    })
                    lists+="</ul>";
                    Wait.dismiss();
                    PopDetailsTwo.show("Error",<Message faIcon={"harzad"} message={conditions} action={1}/>,40)
                }else{
                    Wait.dismiss()
                    PopDetailsTwo.show("Error",<Message faIcon={"harzad"} message={message} action={1}/>,40)
                }
            }).catch(e=>{
            Wait.dismiss()
        })
        console.log("object data ",{...data,transactionId})
        PopDetail.dismiss()
    }


    return(
        <>
            <Header display={writable} label={"Transaction Objects"}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={objPresent?()=>{}:()=>{PopDetail.show("Transaction Object",
                            TransactionPopup.state().transaction.name === "TIC Grant Leasehold" ? <AddLeaseOfficial handleLeasSubmitObj={handleLeaseSubmit}/>
                            :TransactionPopup.state().transaction.name === "Grant Leasehold" ? <AddLeaseOfficial handleLeasSubmitObj={handleLeaseSubmit}/>
                            : TransactionPopup.state().transaction.name === "Register Document Cap 117" ? <AddOfficialDetails handleDetails={handleSubmit}/>
                            :TransactionPopup.state().transaction.name === "Transfer Unit Title"?<AddUnitOfficial handleUnitSubmit = {handleUnitSubmit}/>:<AddOfficial handleSubmitObj={handleSubmit}/>,65)}}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-globe'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={objPresent?()=>{}:()=>{PopDetail.show("Transaction Object",<AddOfficialDetails handleDetails={handleSubmit}/>,65)}}
                    /><strong> Details</strong>
                </div>
            </Header>
            <table style={{width:"100%"}} className={"tb"}>
                <thead>
                    <tr>
                        <th>TYPE</th>
                        <th>NUMBERS</th>
                        <th>SYSTEM ID</th>
                        <th>DETAILS</th>
                    </tr>
                </thead>
                <tbody>
                {
                    objPresent || isMortgage?object.map(({numbers, type,systemId,details})=>
                    <tr key={numbers}>
                        <td>{type}</td>
                        <td>{numbers}</td>
                        <td>{systemId}</td>
                        <td>{details}</td>
                    </tr>):<tr><td colSpan={4} style={{textAlign:"center"}}>No Title added</td></tr>
                }
                </tbody>
            </table>
        </>
    )
}

//tenure_type_id, tenure_type_name
const options=[
    {
    label:"Right of Occupancy",
    value:1
    },
    {
    label:"Leasehold",
    value:2
    },
    {
    label:"Sublease",
    value:3
    },
    {
    label:"Unit Ownership",
    value:4
    },
    {
    label:"Customary Occupancy",
    value:5
    },
    {
    label:"Village",
    value:6
    },
]