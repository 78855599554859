import React, {useState, useEffect} from 'react'
import PopDetail from "../../../../popdetail";
import PopDetailsTwo from "../../../../PopDetailsTwo";
const container ={
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'white',
    gap:'.5rem'
}
const icon ={
    width:'5rem',
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-start'
}
const body ={
    display:'flex',
    flexDirection: 'column',
    padding:'2rem'
}
const fa ={
    color:'tomato',
    fontSize:'5rem',
    marginLeft:'auto'
}

const button={
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-end'
}
const faIcons={
    harzad:'exclamation-triangle ',
    info:'info-circle',
    success:'check'
}
export default function Message ({faIcon, message,action=0}){
    const[currentIcon,currentIconSet] = useState('')
    useEffect(()=>{
        let icon = faIcon === 'harzad'?faIcons.harzad:
            faIcon === 'info'?faIcons.info:
                faIcon === 'success'?faIcons.success:''
        currentIconSet(icon)
    },[faIcon])
    return (
        <div style={container}>
            <div style={icon}>
                <i className={`fa fa-${currentIcon}`} style={fa}/>
            </div>
            <div style={body}>
                <p>{message}</p>
                <div style={button}>
                    <button onClick={()=>{action===0?PopDetail.dismiss():PopDetailsTwo.dismiss()}} style={{width:"150px"}}>Close</button>
                </div>
            </div>
        </div>
    )
}