import * as React from "react";
import Wait from "../../popups/wait";
import MobileCreateService from "../../../services/mobile/mobile_create";
import MobileReadService from "../../../services/mobile/mobile_read";
import PopDetail from "../../popups/popdetail";
import Message from "../../popups/transacrions/screen/gro/person/message";
import DataTable from 'datatables.net-dt';
export default class ParcelRemark extends React.Component {

    static self
    constructor(props) {
        super(props);

        this.state = {
            stage: 1,
            data: [],
            draft: '',
            stages: ['Mobile Data', 'Rejected', 'GIS Approval', 'ALO Approval', 'Paid', 'Printed', '', '', ''],
        }
        this.submitReject = this.submitReject.bind(this)
        this.loadData = this.loadData.bind(this)
        this.handleChange = this.handleChange.bind(this)

        ParcelRemark.self = this
    }

    handleChange = e => {

        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === 'stage' && parseInt(e.target.value) !== 1) {
            document.getElementById('txt_reject').value = ''
            document.getElementById('draftreason').style.display = "none";
        } else {
            document.getElementById('txt_reject').value = 'Approved'
            document.getElementById('draftreason').style.display = "none";
        }

        if (e.target.name === 'stage' && parseInt(e.target.value) === 6) {
            document.getElementById('txt_reject').style.display = 'none'
            document.getElementById('draftreason').style.display = 'block';
        } else {
            document.getElementById('txt_reject').style.display = 'block'
        }
        
    }
    handleDraftChange = e =>{
        if (e.target.name === "draft") {
            document.getElementById('txt_reject').value = e.target.value
            document.getElementById('txt_reject').style.display = 'none'
        }
        if (e.target.name === "draft" && e.target.value === "OTHERS") {
            document.getElementById('txt_reject').value = e.target.value
            document.getElementById('txt_reject').style.display = 'flex'
        }
    }

    componentDidMount() {
        if (this.props.is_open === true) {
            ParcelRemark.popRemark()
        }
        this.loadData()
    }

    loadData() {
        try {
            Wait.show("Loading....")
            MobileReadService.readRemark(this.props.parcel.id).then(r => {
                Wait.dismiss()
                try {
                    console.log(r.data.results)
                    this.setState({
                        data: r.data.results
                    })
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
    static popRemark() {
        document.getElementById('pop_remark').style.display = 'inherit'
    }
    static closeRemarkPop() {
        document.getElementById('pop_remark').style.display = 'none'
        document.getElementById('txt_reject').value = ''
    }

    noHandler() { PopDetail.dismiss() }
    handleMessage() { ParcelRemark.popRemark() }

    submitReject() {

        if (this.props.parcel.stage < 2) {
            PopDetail.show("Warning", <Message faIcon={'harzad'} message={'This parcel was not Approved by GIS Expert'} />, 30)
            return
        }

        let txt = document.getElementById('txt_reject').value.trim()

        if (txt.length > 3) {
            try {
                Wait.show('Remarking...')
                MobileCreateService.addRemark({
                    parcelId: this.props.parcel.id,
                    stage: this.state.stage,
                    message: txt
                }).then(r => {
                    try {
                        Wait.dismiss()
                        if (r.data.status === 0) {
                            alert(r.data.message)
                            return
                        }
                        console.log(r)
                        ParcelRemark.closeRemarkPop()
                        this.loadData()
                        this.props.updateParcels(this.props.parcel.stage);
                    } catch (e) {
                        console.log(e)
                    }
                })
            } catch (e) {
                console.log(e)
            }
        } else {
            PopDetail.show("Warning", <Message faIcon={'harzad'} message={'Too Short Message, Please Describe your Remark'} />, 30)
        }
    }



    render() {
        return (<div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <div className={'row'} style={{ boxShadow: '0 4px 4px #aaa', padding: '8px 16px', backgroundColor: '#efefef' }}>
                <div className={'col col-sm-8'}>
                    <span className={'fa fa fa-bars'} style={{ cursor: 'pointer', marginTop: '8px' }} onClick={() => ParcelRemark.popRemark()} />
                    <input style={{ width: '85%', marginLeft: '24px', backgroundColor: '#fff' }} placeholder={'Search here...'} />
                    <span className={'fa fa fa-plus-circle'} style={{ color: 'darkcyan', float: 'right', cursor: 'pointer', margin: '8px' }}
                        onClick={() => ParcelRemark.popRemark()} />
                </div>
                <div className={'col col-sm-4 fa-btn'}>
                    <button style={{ float: 'right', width: '80px' }} onClick={() => this.loadData(0)} className={'default'}>Refresh</button>
                </div>
            </div>

            <div>
                <div id={'pop_remark'}
                    style={{ position: 'absolute', display: 'none', height: '100%', top: 0, left: 0, right: 0, zIndex: '1004', backgroundColor: '#fff' }}>
                    <div style={{ padding: '24px' }}>
                        <p>Remark</p>
                        <div className="row">
                            <div className="col col-sm-6">
                                <label>
                                <input type={'radio'} name={'stage'} defaultValue={1} onChange={this.handleChange} defaultChecked={true} /> Approved
                                </label>
                                <br />
                                <label>

                                <input type={'radio'} name={'stage'} defaultValue={6} onChange={this.handleChange} /> Draft CCRO
                                </label>
                                <br />
                                <label>
                                <input type={'radio'} name={'stage'} defaultValue={7} onChange={this.handleChange} /> LO Rejected
                                </label>
                                <br />
                                <label>

                                <input type={'radio'} name={'stage'} defaultValue={0} onChange={this.handleChange} /> Rejected
                                </label>
                                <br />
                            </div>
                            <div className="col col-sm-6">
                                {<div id="draftreason">
                                    <label>
                                    <input type={'radio'} name={'draft'} defaultValue={"NO NIDA ID"} onChange={this.handleDraftChange} /> NO NIDA ID
                                    </label>
                                    <br />
                                    <label>
                                    <input type={'radio'} name={'draft'} defaultValue={"UNACCEPTED ID"} onChange={this.handleDraftChange} /> UNACCEPTED ID
                                    </label>
                                    <br />
                                    <label>
                                    <input type={'radio'} name={'draft'} defaultValue={"TAASISI"} onChange={this.handleDraftChange} /> TAASISI
                                    </label>
                                    <br />
                                    <label>

                                    <input type={'radio'} name={'draft'} defaultValue={"MIRATHI"} onChange={this.handleDraftChange} /> MIRATHI
                                    </label>
                                    <br/>
                                    <label>

                                    <input type={'radio'} name={'draft'} defaultValue={"NO PICTURES"} onChange={this.handleDraftChange} /> NO PICTURES
                                    </label>
                                    <br />
                                    <label>

                                    <input type={'radio'} name={'draft'} defaultValue={"OTHERS"} onChange={this.handleDraftChange} /> OTHERS
                                    </label>
                                    <br />
                                </div>}
                            </div>
                        </div>
                        {<div>
                            <textarea defaultValue={'Approved'} id={'txt_reject'} placeholder={'Type here....'} style={{ width: '100%' }} rows={5} /><br />
                        </div>}
                        <button style={{ width: '80px' }} className={'submit'} onClick={() => this.submitReject()}>Submit</button>
                        <button style={{ width: '80px' }} onClick={() => ParcelRemark.closeRemarkPop()}>Close</button>
                    </div>
                </div>
                <table className={'tb'} style={{ width: '100%', border: '0.5px solid #ccc' }}>
                    <thead>
                        <tr>
                            <th>#.</th>
                            <th>CREATED AT</th>
                            <th>STAGE</th>
                            <th>REMARK</th>
                            <th>CREATED BY</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map((d, i) => {
                            return (<tr key={i}>
                                <td>{(i + 1)}.</td>
                                <td>{d.created_time}</td>
                                <td>{this.state.stages[d.stage]}</td>
                                <td>{d.remark}</td>
                                <td>{d.created_by}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>)
    }
}

