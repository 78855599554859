import * as React from "react";
import PopDetail from "../../../popdetail";
import Wait from "../../../wait";
import ValCreateService from "../../../../../services/valuation/val_create";
import TransactionPopup from "../../transaction_popup";
import ValBlockSpatialUnitPane from "../spatial-unit/val_block_spatial_unit";
import SpatialPreviewPane from "../../../../../services/ugshp/SpatialPreviewPane";
import Message from "../gro/person/message";
import {parseZip} from 'shpjs'
import CommonCreateService from "../../../../../services/common/common_create";


export default class AddValuationBlockPane extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            parcel:null,
            building:null,
            point:null,
            contours:null,
            road:null,
            power:null,
            water:null,
            railways:null,
            pond:null,
            is_utm:'hidden'
        }
        this.uploadValuationBlock = this.uploadValuationBlock.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.previewBlockRates = this.previewBlockRates.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
    }
    handleChange= e =>{

        if (e.target.name==='dataset') {
            let is_utm = (e.target.value === 'EPSG:4326') ? 'hidden' : 'visible'
            this.setState({ is_utm: is_utm });
        }

        this.setState({[e.target.name]: e.target.value});
    }
    handleChangeFile=e=>{
        this.setState({ [e.target.name]:e.target.files[0] })
    }
    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>

                    <div className={'col col-sm-12'}>

                        <table style={{width:'100%'}}>
                            <tbody>

                            <tr>
                                <td style={{color:'#008b8b'}}>* Dataset</td>
                                <td style={{color:'#008b8b'}}>* File Format</td>
                            </tr>
                            <tr>
                                <td>
                                    <select name={'dataset'} style={{width:'100%'}} onChange={this.handleChange}>
                                        <option value={'EPSG:4326'}>- Select One -</option>
                                       {/* <option value={'EPSG:210'}>ARC 1960 - UTM (EPSG:210)</option>*/}
                                        <option value={'EPSG:327'}>TARIF 11 - UTM (EPSG:327)</option>
                                        {/*<option value={'EPSG:4326'}>WGS 1984 - LAT LONG (EPSG:4326)</option>*/}
                                        {/*<option>UTM</option>*/}
                                    </select>
                                </td>
                                <td>
                                    <select style={{width:'100%'}} name={'format'} onChange={this.handleChange}>
                                        <option value={'Shapefile'}>- Select One -</option>
                                        <option>Shapefile</option>
                                        <option>CSV</option>
                                    </select>
                                </td>
                            </tr>
                            <tr style={{visibility:this.state.is_utm}}>
                                <td style={{color:'#008b8b'}}>* Data Zone</td>
                                <td style={{color:'#008b8b'}}>* Data Band</td>
                            </tr>
                            <tr style={{visibility:this.state.is_utm}}>

                                <td>
                                    <select style={{width:'100%'}} name={'zone'} onChange={this.handleChange}>
                                        <option>- Choose One -</option>
                                        <option>35</option>
                                        <option>36</option>
                                        <option>37</option>
                                    </select>
                                </td>
                                <td>
                                    <select style={{width:'100%'}} name={'band'} onChange={this.handleChange}>
                                        <option>- Choose One -</option>
                                        <option>L</option>
                                        <option>M</option>
                                    </select>
                                </td>
                            </tr>

                            <tr style={{height:'4vh'}}/>

                            <tr>

                                <td style={{color:'#008b8b'}} colSpan={2}>* Valuation Block Shapefile </td>

                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <input type={'file'} style={{width:'100%'}} accept={".zip,.ZIP"}
                                           name={'shp'} onChange={this.handleChangeFile}/>
                                </td>
                            </tr>

                            </tbody>
                        </table>


                    </div>

                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.previewBlockRates}>Preview</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }

    previewBlockRates(){

        if (this.state.shp === undefined){
            return
        }

        const formData = new FormData()
        formData.append('coordinate',this.state.shp)
        formData.append('extra', JSON.stringify({
            layerName:'valuation_block'
        }))

        Wait.show('Validating.....')
        CommonCreateService.createPreviewSHP(formData).then(r => {
            Wait.dismiss()
            try {

                let reader = new FileReader()
                reader.onloadend = (e)=>{
                    PopDetail.show('Preview Valuation Blocks',
                        <SpatialPreviewPane json={parseZip(e.target.result)}
                                            label={'Vblock_ID'}
                                            validator={r.data}
                                            layer_name={'valuation_block'}
                                            upload={this.uploadValuationBlock}
                                            shp_columns={['Vblock_ID','region','district','council','locality']}
                        />)


                }
                reader.onError =e=>{

                }

                reader.readAsArrayBuffer(this.state.shp)
            }catch (e) {
                console.log(e)
            }
        })

    }


    uploadValuationBlock(){
        try {

            if (this.state.shp===undefined){
                alert('No Valuation Blocks Shapefile')
                return
            }
            const formData = new FormData()
            formData.append('file',this.state.shp)
            formData.append('dto', JSON.stringify({
                blockId:1,
                region:1,
                district:1,
                council:1,
                locality:1,
                transactionId:TransactionPopup.id()
            }))
            Wait.show("Saving...")
            ValCreateService.uploadValuationBrock(formData).then(d=>{
                Wait.dismiss()
                if (d.data.status===0){
                    PopDetail.show("Warning",<Message faIcon={'harzad'} message={d.data.message}/>,30)
                    return
                }
                console.log(d.data)
                ValBlockSpatialUnitPane.loadData()
                PopDetail.dismiss()
            })

        }catch (e){
            Wait.dismiss()
            console.log(e)
        }
    }

}