import * as React from "react";
import Wait from "../../wait";
import MobileReadService from "../../../../services/mobile/mobile_read";
import PopDetail from "../../popdetail";
import MobileSummaryPane from "../screens/mobile_summary";
import SummaryPane from "../../transacrions/screen/summary";
import Message from "../../transacrions/screen/gro/person/message";
import MobileUpdateService from "../../../../services/mobile/mobile_update";
import MobileTable from "../../../tables/tb_mobile";
export default class EditProjectPane extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            party:{},
            projectName:'',
            pap:0,
            acquiringAuthority:'',
            projectLocation:'',
            districts:[]
        }


        this.addParameters = this.addParameters.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange= e =>{this.setState({[e.target.name]:e.target.value})}

    componentDidMount() {
        this.loadDistricts()

        console.log(this.props.project)
        this.setState({
            projectName:this.props.project.projectName,
            acquiringAuthority:this.props.project.projectAuthority
        })
    }

    loadDistricts(){
        try {
            Wait.show("Loading Districts...")
            MobileReadService.listDistricts().then(r => {
                try {
                    this.setState({
                        districts:r.data.results
                    })
                    Wait.dismiss()
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <table style={{width:'100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width:'200px',color:'darkcyan'}}><span>Project Name</span></td>
                            <td>
                                <input style={{width:'100%'}} name={"projectName"} onChange={this.handleChange} defaultValue={this.state.projectName}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={{color:'darkcyan'}}><span>Authority</span></td>
                            <td>
                                <select style={{width:'100%'}} name={"acquiringAuthority"} onChange={this.handleChange} defaultValue={this.state.acquiringAuthority}>
                                    <option value={''}>- Select One -</option>
                                    <option value={'Halmashauri ya Jiji'}>City Council</option>
                                    <option value={'Halmashauri ya Manispaa'}>Municipal Council</option>
                                    <option value={'Halmashauri ya Wilaya'}>District Council</option>
                                    <option value={'Halmashauri ya Mji'}>Town Council</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><span style={{color:'darkcyan'}}>Project Location</span></td>
                            <td>
                                <select style={{width:'100%'}} name={"projectLocation"} onChange={this.handleChange}>
                                    <option value={''}>- Select District -</option>
                                    {this.state.districts.map((d,i)=>{
                                        return(<option key={i}>{d.name}</option>)
                                    })}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width:'200px',color:'darkcyan'}}><span>Project Target (P.A.P)</span></td>
                            <td>
                                <input style={{width:'100%'}} type={'number'} name={"pap"} onChange={this.handleChange}/>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width:'200px',color:'darkcyan'}}><span>Institution Type</span></td>
                            <td>
                                <select style={{width:'100%'}} type={'checkbox'} name={"isGovernmentInstitute"} onChange={this.handleChange}>
                                    <option value={0}>- Choose One -</option>
                                    <option value={true}>Government Institution</option>
                                    <option value={false}>Private Institution</option>
                                </select>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={()=>this.addParameters()}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }

    addParameters(){

        try {
            if (this.state.projectName === undefined || this.state.projectName === ''){
                PopDetail.show("Warning",<Message faIcon={'harzad'} message={'No Project Name'}/>,30)
                return
            }
            if (this.state.acquiringAuthority === undefined || this.state.acquiringAuthority === ''){
                PopDetail.show("Warning",<Message faIcon={'harzad'} message={'No Project Authority'}/>,30)
                return
            }
            if (this.state.projectLocation === undefined || this.state.projectLocation === ''){
                PopDetail.show("Warning",<Message faIcon={'harzad'} message={'No Project Location'}/>,30)
                return
            }

            if (this.state.isGovernmentInstitute === 0){
                PopDetail.show("Warning",<Message faIcon={'harzad'} message={'No Institution Type'}/>,30)
                return
            }

            Wait.show("Saving...")

            MobileUpdateService.updateProject({
                projectName:this.state.projectName,
                projectAuthority:this.state.acquiringAuthority,
                projectLocation:this.state.projectLocation,
                pap:this.state.pap,
                isGovernmentInstitute:this.state.isGovernmentInstitute,
                projectId:this.props.project.id
            }).then(d=>{
                Wait.dismiss()
                PopDetail.dismiss()
                console.log(d)
                if (this.props.source==='mobile'){
                    MobileTable.loadData()
                }
            })
        }catch (e){
            console.log(e)
        }
    }

}