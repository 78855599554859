import React from 'react';

import UgSpatial from "../../components/maps/ug_spatial";
import * as LF from "leaflet";
import Basemap from "../../components/maps/basemap";
import PopDetail from "../../components/popups/popdetail";
import PopDetailsTwo from "../../components/popups/PopDetailsTwo";
import Wait from "../../components/popups/wait";

export default class SpatialValidatorPane extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            title:'Spatial Preview',
            description_title:'Quality Checking',
            map:null,
            pass:false,
            errors:[]
        }
        this.loadSHP = this.loadSHP.bind(this)
    }

    componentDidMount() {
        try {
            UgSpatial.clear('ramani_preview')

            let ly_group = new LF.FeatureGroup();
            let sat = Basemap.googleSat(),
                hybrid = Basemap.googleHybrid(),
                blank = LF.tileLayer('')

            let map = LF.map('ramani_preview', {
                layers: [blank, ly_group],
                attributionControl: false,
                fullscreenControl: true,
                maxZoom:10000
            }).setView([-6.085936, 35.711995], 4)

            let baseLayers = {
                "<span style='color: gray'>Blank</span>": blank,
                "Satellite": sat,
                "Hybrid": hybrid
            }

            this.setState({
                map:map,
                ly_group:ly_group,
            })


            let ly_vb = UgSpatial.getLayerByName('valuation_block').addTo(map)
            let ly_tp = UgSpatial.getLayerByName('tp_spatial_unit').addTo(map)

            let overlays = {
                'Valuation Blocks':ly_vb,
                'TP Drawing':ly_tp,
                'Uploaded Layer':ly_group,
            }

            LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)
            setTimeout(_=>{ this.loadSHP()},500)

        }catch (e){
            console.log(e)
        }
    }

    render() {
        return(<div className={'col sol-sm-12'}>
            <div className={'row'} style={{marginBottom:'4px'}}>


                <div className={'col col-sm-12'} style={{height:'68vh',paddingLeft:'4px'}}>
                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>

                        <div className={'row'} style={{height:'80%'}}>
                            <div className={'col col-sm-12'} style={{background:'#efefef'}} id={'ramani_preview'}/>
                        </div>
                        <div>
                            <b style={{color:'#f00'}}>Errors</b><br/>
                            <table className={'tb'} width={'100%'}>
                                <tbody id={'tb'}>
                                </tbody>
                            </table>
                        </div>
                        <div className={'row'}>
                            <div className={'col col-sm-3'}/>
                            <div className={'col col-sm-9'} style={{textAlign:'right'}}>
                                <br/>
                                <button className={'submit'} style={{visibility:this.state.pass===true?'visible':this.props.label==='station'?'visible':'hidden'}} onClick={()=>this.props.upload()}>Submit</button>
                                <button onClick={()=>this.props.ispop2?PopDetailsTwo.dismiss():PopDetail.dismiss()}>Close</button>
                            </div>
                        </div>
                    </div>

                </div>


            </div>


        </div>)
    }

    loadSHP() {
        let self = this
        let out = ''
        Wait.show('Validating..')
        let ly = LF.geoJson(this.props.json,{
            onEachFeature:(f,l)=>{
                try {
                    if (f.properties[self.props.label]<1){
                        for (let key in f.properties) {
                            self.setState({
                                [key]:self.props.shp_columns.includes(key)?'check':'times',
                                pass:true
                            })
                            out+= '<tr><td>'+key + '</td><td>'+f.properties[key] + '</td></tr>'
                        }
                    }

                    LF.marker(l.getBounds().getCenter(),{
                        icon:LF.divIcon({
                            className:'bindTooltipBig2',
                            html:f.properties[self.props.label]?f.properties[self.props.label]:'',
                            iconSize:[100,40]
                        })
                    }).addTo(self.state.ly_group)

                    LF.marker(l.getBounds().getCenter(),{
                        icon:LF.divIcon({
                            className:'bindTooltipBig',
                            html:f.properties[self.props.label2]?f.properties[self.props.label2]:'',
                            iconSize:[100,40]
                        })
                    }).addTo(self.state.ly_group)



                    let color = self.props.validator.lst_poly.includes(f.properties[self.props.label])?'#901':f.properties[self.props.label]?'#00ff00':'#901'
                    //let fillColor = self.props.validator.lst_poly.includes(f.properties[self.props.label])?'transparent':'#901'
                    l.setStyle({
                        color:'#444',
                        weight:10,
                        fillColor:'#f00'
                    })
                }catch (e) {
                }
            },
            color:'#0c0',
            weight:2,
            fillColor:'transparent'
        }).addTo( self.state.ly_group)


        self.setState({
            errors:out,
            pass:out===''?true:false
        })

        document.getElementById('tb').innerHTML=out

        let ne = ly.getBounds().getNorthEast()
        let nw = ly.getBounds().getNorthWest()
        let sw = ly.getBounds().getSouthWest()
        let se = ly.getBounds().getSouthEast()

        self.props.shp_columns.forEach((it,i)=>{
            if (document.getElementById('sp_'+i).innerHTML!=='check'){
                self.setState({
                    pass:false
                })
            }
        })

       /*LF.polygon([
            [nw.lat,nw.lng],
            [ne.lat,ne.lng],
            [se.lat,se.lng],
            [sw.lat,sw.lng],

        ],{
            color:'#f00',
            weight:2,
            fillColor:'transparent',
            dashArray: '5, 10',
            lineCap: 'square',
        }).addTo(self.state.map);*/

        ly.bringToFront()

        try {
            self.state.map.fitBounds(ly.getBounds())
        }catch (e) {
            console.log(e)
        }


        Wait.dismiss()
    }
}