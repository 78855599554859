import React, {useEffect, useState} from 'react'
import API from "../../services/API";
import TransactionPopup from "../../components/popups/transacrions/transaction_popup";
import Header from "../../components/popups/transacrions/screen/gro/header";
import {useBuildingDispatch, useBuildingState} from "./store";

export default function ValuationParty() {
    const[parties, partiesSet] = useState([])
    const [messageString,messageStringSet] = useState('')
    const[status, statusSet] = useState(false)
    const[linked, linkedSet] = useState(false)
    const[indexe, indexeSet] = useState(-1)
    const[valuationNo,valuationNoSet] = useState("")
    const[readOnly, readOnlySet] = useState(false)
    const dispatch = useBuildingDispatch()
    const state = useBuildingState();
    useEffect(()=>{
        let txId = TransactionPopup.id()
        API.ax.get(`transaction/transaction-parties/${txId}`)
            .then(d=>{
                console.log("The People",d.data)
                const{result, status, message} = d.data
                let peoples = result;
                const{valuationPartyVu:party}=state
                console.log("party",party)
                if(status === 1){
                    party.forEach(({partyId,valuationNo},i)=>{
                       const results = peoples.filter(obj => {
                            return obj.partyId === partyId
                        })
                        if(results){
                            peoples[i]={...peoples[i],valuationNo}
                            linkedSet(true)
                        }
                    })
                    statusSet(true)
                    partiesSet(peoples)
                }
                else {
                    messageStringSet(message)
                }
            })
            .catch(e=>console.error("error",e))
    },[])

    const link =()=>{
        console.log("chekout",state)
        const{partyId,valuationNo } = parties[indexe]
        dispatch({type:"party", payload:{partyId,valuationNo}})
        console.log("state",state)
    }
    const highlight ={
        fontWeight:'bold',
        cursor:'pointer'
    }
    const unHighlight = {
        cursor:'pointer'
    }
    const handleKeyPress=(event)=>{
        if(event.key === 'Enter'){
            statusSet(false)
            let newParty = parties
            newParty[indexe] = {...newParty[indexe], valuationNo}
            partiesSet(newParty)
            readOnlySet(true)
            statusSet(true)
        }
    }
    return(
        <>
            <Header label={"Person"} display={true}>
                <div>
                    <i className={'fa fa-plus-circle'}
                       style={{color:'tomato', fontSize:'25px', cursor:'pointer'}}
                       onClick={indexe>-1?link:()=>{}}/> <b>Link</b>
                </div>
            </Header>
            <table className={'tb'} style={{width:'100%'}}>
                <thead>
                    <tr>
                        <th>VALUATION NO</th>
                        <th>NAME</th>
                        <th>NIDA/ID DOC NO</th>
                        <th>ADDRESS</th>
                        <th>PHONE</th>
                        <th>LINKED</th>
                    </tr>
                </thead>
                <tbody>
                {
                    status? parties.map(({ valuationNo,firstName, lastName, middleName, nationalId, partyId, phoneNumber },i)=>
                            (
                                <tr onClick={()=>{indexeSet(i); }} key={i} style={indexe === i?highlight:unHighlight}>
                                    <td>{valuationNo? valuationNo
                                        :<input onKeyPress={handleKeyPress} readOnly={readOnly} type={"text"} placeholder={"Please Enter a Valuation No."} onChange={(e)=>{valuationNoSet(e.target.value)}}/>}
                                    </td>
                                    <td>{firstName?firstName:''} {middleName?middleName:''} {lastName?lastName:''}</td>
                                    <td>{nationalId}</td>
                                    <td>{}</td>
                                    <td>{phoneNumber?phoneNumber:"No phone number"}</td>
                                    <td>{linked?"lINKED":"NOT LINKED"}</td>
                                </tr>
                            ))
                        :<tr><td colSpan={6} style={{textAlign:'center'}}>{messageString}</td></tr>
                }
                </tbody>
            </table>
        </>
    )
}