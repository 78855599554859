import * as React from "react";
import CROStages from "./stages"
import PopDetail from "../../../popups/popdetail";
import Wait from "../../../popups/wait";
import SMDReadService from "../../../../services/smd/smd_read";
import MobilePopup from "../../../popups/mobile/mobile_popup";
import KTab from "../../common/tab";
import SMDCreateService from "../../../../services/smd/smd_create";
import TxReadService from "../../../../services/transaction/transaction_read";
import { Documents, TPRemarks } from "./tp";
import { SpatialMapDisplay } from "./mappane.js";
import StageHandler from "../../../../componentss/stagehandler";
import { Button } from "react-bootstrap";
import TxCreateService from "../../../../services/transaction/transaction_create";
import PrintingLayout from "./printingLayout";
import { centroid, combine, difference, dissolve, distance, featureCollection, intersect, lineSegment, lineString, multiLineString, multiPolygon, point, polygon, polygonToLine, union } from "@turf/turf";
function SurveyTab() {
    const [activeStage, setActiveStage] = React.useState(0);
    const stages = ["Draft Survey", "Approved Survey", "Rejected Survey"]
    const [surveys, setTp] = React.useState([]);
    React.useEffect(() => {

        TxReadService.readTransactionsByProjectAndType(MobilePopup.id(), 70).then((r) => {
            setTp(r.data);
        })
        // TPReadService.readReportData(MobilePopup.id(), activeStage).then((r) => {
        //     console.log(r.data)
        //     setTp(r.data.result)
        // }).catch((e) => { console.log(e) });
    }, [])
    return (
        <div style={{}}>
            <div>
                <div>
                    <button onClick={() => {
                        PopDetail.show("Add Request for Survey Approval", <SurveyPane transactionId={0} nw={true} transactionCode={70} />)
                    }}>Add Request for Survey Approval</button>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", }}>
                <div style={{ width: "20%" }}>
                    <CROStages type={"survey"} changeStage={(prop) => { setActiveStage(prop) }} />
                </div>
                <div style={{ width: "80%", maxHeight: window.outerHeight * 0.4, overflow: "scroll", fontSize: 10 }}>
                    <table className='table table-striped table-bordered table-hover'>
                        <thead>
                            <tr style={{ position: "sticky", top: 0, backgroundColor: "#8f8" }}>
                                <th>Transaction Number</th>
                                <th>Title</th>
                                <th>Plan Number</th>
                                {/* <th>Created Date</th> */}
                                {/* <th>Council</th> */}
                                <th>Current Stage</th>
                                {/* <th>Mtaa</th> */}
                                <th>Date</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        {
                            surveys.map((prop, key) => (
                                <tr>
                                    <td onClick={() => {
                                        PopDetail.show("Transaction " + prop.transactionId, <SurveyPane nw={false} stage={prop.stage} transactionId={prop.transactionId} />);
                                    }} style={{ fontWeight: "bold" }}>{prop.transactionId}</td>
                                    <td>{prop.title}</td>
                                    <td>{prop.sp}</td>
                                    <td >{stages[activeStage]}</td>
                                    <td>{prop.date}</td>
                                    <td>{prop.userstr}</td>
                                </tr>
                            ))
                        }
                        <tr></tr>
                    </table>
                </div>
            </div>
        </div>

    );
}
export function polyToLine(cord) {
    let p = []
    for (let k = 0; k < cord.length; k++) {
        let cx = cord[k].geometry.coordinates
        for (let i = 0; i < cx.length; i++) {
            for (let j = 1; j < cx[i].length; j++) {
                // console.log(cx[i])
                let x = j - 1;
                let ol = [cx[i][x], cx[i][j]]
                
                ol=ol[0][0]>ol[1][0]
                ?[ol[1],ol[0]]:[ol[0],ol[1]]
                
                let ca = calculateAngle(ol).toFixed(2)
                let xc = distance(ol[0], ol[1],{units:"meters"})
                let line = lineString(ol, {
                    angle: ca,
                    distance: xc.toFixed(2),
                    centroid: centroid(lineString(ol)).geometry.coordinates
                })
                if(!p.includes(line)){
                    p.push(line)
                }
            }
        }
    }
    // console.log(p)
    return p;
}
export function Computations({ regionId, sp, sp2, sp3, distanc }) {
    const [survey, setSurvey] = React.useState(sp);
    const [controlPoints, setControlPoints] = React.useState([]);
    const [computationData, setComputations] = React.useState([]);
    const [selected, setSelected] = React.useState(0);
    const [selected2, setSelected2] = React.useState(0);
    const [selected3, setSelected3] = React.useState(0);
    const [measured,setMeasured] = React.useState(sp3)
    const [, forceUpdate] = React.useState();
    const cpm = [
        "Control Points",
        "Parallelism",
        "Road width",
    ]
    const calulateControls = (sp, control) => {
        let x = sp.x - control.x
        let y = sp.y - control.y
        //console.log([sp.x, control.x])
        //console.log([sp.y, control.y])
        return {
            distance: <p>&Delta; X: {x} &Delta; Y:{y}</p>,
            status: x < 0.0015 ? 1 : 0
        }
    }
    const calcDiff = (s, s2) => {
        // distanc
        let dif = distance([s.x, s.y], [s2.x, s2.y])
        return dif;
    }
    const getControls = (region) => {
        SMDReadService.getControlPoints(region).then(r => {
            setControlPoints(r.data)
        })
    }
    React.useEffect(() => {
        getControls(regionId)
        forceUpdate({})
    }, [])
    const computeData = () => {
        let i = computationData;
        let c = calulateControls(sp, controlPoints[selected]);
        let d = calulateControls(sp2, controlPoints[selected2]);
        let e = calulateControls(sp3, controlPoints[selected3]);
        let f = calcDiff(controlPoints[selected2], controlPoints[selected]);
        i.push({ name: "Control point 1", status: c.status, results: c.distance });
        i.push({ name: "Control point 2", status: d.status, results: d.distance });
        i.push({ name: "Check point verification", status: '', results: e.distance });
        if (Number(distanc) > 0) {
            i.push({ name: "Measured Distance", status: '', results: distanc })
            i.push({ name: "Computed Distance", status: '', results: f })
            i.push({name: "Difference in Distances",status:'',results:Math.abs(distanc-f).toFixed(2)})
        }
        setComputations(i)
        // cpm.forEach(t => {
        //     switch (t) {
        //         case "Control Points":
        //             break;
        //         default:
        //             computationData.push({ name: t, status: 0, results: "Not Checked" });
        //             break;

        //     }
        // })
        forceUpdate({})
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <h6><b>Control Points Used</b></h6>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-4">
                            <b><p>Control Point (Given) #1</p></b>
                            <p>Id: {sp.n}</p>
                            <p>X: {sp.x} <br /> Y: {sp.y}</p>
                        </div>
                        <div className="col-4">
                            <b><p>Control Point (Given) #2</p></b>
                            <p>Id: {sp2.n}</p>

                            <p>X: {sp2.x}<br /> Y: {sp2.y}</p>
                        </div>
                        <div className="col-4">
                            <p><b>Check point (Measured)</b></p>
                            <p>Id: {sp3.n}</p>
                            <p>X: <input onChange={(e)=>{let o=measured; o.x=e.target.value; setMeasured(o)}} value={measured.x} className="mb-3"/> <br /> Y: <input onChange={(e)=>{let o=measured; o.y=e.target.value; setMeasured(o)}} value={measured.y}/></p>
                        </div>
                        <div className="col-12">
                            <p>Distance Measured</p>
                            <p>{distanc}</p>
                        </div>
                    </div>
                </div>

            </div>
            <select className="form-control mb-2" onChange={(e) => { setSelected(e.target.value) }}>
                <option value={""}>Choose Control Point 1</option>
                {
                    controlPoints.map((prop, key) => (
                        <option value={key}>{prop.label} x {prop.x} y {prop.y}</option>
                    ))
                }
            </select>
            <select className="form-control mb-2" onChange={(e) => { setSelected2(e.target.value) }}>
                <option value={""}>Choose Control Point 2</option>
                {
                    controlPoints.map((prop, key) => (
                        <option value={key}>{prop.label} x {prop.x} y {prop.y}</option>
                    ))
                }
            </select>
            <select className="form-control mb-2" onChange={(e) => { setSelected3(e.target.value) }}>
                <option value={""}>Choose Control Point 2</option>
                {
                    controlPoints.map((prop, key) => (
                        <option value={key}>{prop.label} x {prop.x} y {prop.y}</option>
                    ))
                }
            </select>
            <button onClick={computeData}>Run Computations</button>
            <table className="table table-bordered table-hover">
                <tr style={{ fontWeight: "bold" }}>
                    <td>Computation name</td>
                    <td>Results</td>
                </tr>
                {
                    computationData.map((prop) => (
                        <tr style={{}}>
                            <td>{prop.name}</td>
                            <td>{prop.results}</td>
                        </tr>
                    ))
                }
            </table>
        </div>
    )
}
function calculateAngle(line) {
    const coordinates = line;
    const [x1, y1] = coordinates[0];
    const [x2, y2] = coordinates[1];
    const dx = x2 - x1;
    const dy = y2 - y1;
    let angle = Math.atan(dy / dx);
    angle = angle * (180 / Math.PI);
    if (dx < 0) {
        angle += 180;
    }
    else if (dy < 0) {
        angle += 360;
    }
    return angle;
}
function coordDistance(needle, hay) {
    // console.log([needle,hay])
    for (var o = 0; o < hay.length; o++) {
        if (distance(needle, hay[0]) <= 0) {
            return true;
        }
    }
    return false;
}
export function joinBlocks(block) {
    let geom = block
    let featureSize = geom.length
    let unchecked = geom
    let blocks = [];
    let unc = unchecked.length;
    // console.log(geom)
    while (unchecked.length > 0) {
        blocks.push(unchecked[0]);
        unchecked.splice(0, 1)
        unc = unchecked.length;
        // console.log(unc)

        let curblock = blocks.length - 1;
        let foun = false
        for (let i = 0; i < blocks.length - 1; i++) {
            let theblock = blocks[curblock]
            if (polygonNeighbor(blocks[i], blocks[curblock])) {
                let newblock = union(blocks[i], blocks[curblock]);
                blocks.splice(i, 1)
                i--;
                blocks.splice(blocks.indexOf(theblock), 1)
                foun = true
                // console.log([newblock])

                blocks.push(newblock)
            }
        }
        if (foun === false) {
            for (let i = 0; i < unchecked.length; i++) {
                // console.log([blocks[curblock],unchecked[i],blocks])
                if (polygonNeighbor(blocks[curblock], unchecked[i])) {
                    let newblock = union(blocks[curblock], unchecked[i])
                    // i--;
                    // console.log(polygonNeighbor(blocks[curblock],unchecked[i]))
                    unchecked.splice(i, 1);
                    blocks.splice(curblock, 1);
                    blocks.push(newblock)
                    // console.log([newblock])

                }
            }
        }
        // break;

    }
    let last_check = 1
    let new_check = 0
    while (last_check !== new_check) {
        last_check = blocks.length;
        //console.log("in here " + last_check + " -" + new_check)
        for (let i = 0; i < blocks.length; i++) {
            let f = false;
            for (let j = 0; j < blocks.length; j++) {
                if (blocks[i] !== undefined && blocks[j] !== undefined) {

                    if (i !== j) {
                        if (polygonNeighbor(blocks[i], blocks[j])) {
                            let newpoly = union(blocks[i], blocks[j]);
                            let thepoly = blocks[j]
                            blocks.splice(i, 1);
                            // console.log([newpoly])

                            i = 0;
                            j = 0;
                            blocks.splice(blocks.indexOf(thepoly), 1)
                            blocks.push(newpoly);
                            f = true
                            // continue;
                        }
                    }
                    else {
                    }
                }
                else {
                    i = 0
                    i = 0
                }
            }
            if (f) {
                // continue;
            }
        }
        new_check = blocks.length;
    }
    //console.log(blocks)
    return blocks
}
function polygonNeighbor(p1, p2) {
    try {
        let d = []
        let g = p1.geometry.coordinates;
        let g2 = p2.geometry.coordinates
        let p = []
        if (p1.geometry.type.toLowerCase() === "multipolygon") {
            g[0].forEach(h => { p.push(h) })
            g = p;

        }
        else if (typeof g[0] === "number") {
            g = [g]
        }
        if (p2.geometry.type.toLowerCase() === "multipolygon") {
            g[0].forEach(h => { d.push(h) })
            g = d;
        }
        else if (typeof g2[0] === "number") {
            g2 = [g2]
        }
        // console.log([p1,p2,g,g2]);
        for (let i = 0; i < g.length; i++) {
            for (let j = 0; j < g2.length; j++) {
                for (let k = 0; k < g[i].length; k++) {
                    // console.log([g[i][k],g2[j]])
                    if (coordDistance(g[i][k], g2[j])) {
                        return true;
                    }
                    // for(let l=0;l<g2[j].length;l++){
                }
            }
        }
    }
    catch (e) {

    }
    return false;
}
export function polgonsToLineSegment(features = []) {
    let lines = []
    features.forEach(e => {
        let line = lineSegment(e)
        line.features.forEach(f => {
            let na = calculateAngle(f.geometry.coordinates)
            let lb = distance(f.geometry.coordinates[0], f.geometry.coordinates[1], { units: "meters" }) / 111111
            f.properties["angle"] = na;
            f.properties["distance"] = lb.toFixed(2)
            f.properties["centroid"] = centroid(f).geometry.coordinates
            lines.push(f)
        })
        // let lineSegment
    })
    //console.log(lines)
    return lines;
}
export function parralelismCheck(geom) {
    // if (geom.features) {
    // let featureSize = geom.features.length
    let blocks = geom;
    //console.log(geom)
    let block_lines = []
    //console.log(blocks)
    blocks.forEach(block => {
        let polyline = polygonToLine(block)
        //    polyline.
        block_lines.push(polyline)
    })
    let just_lines = []
    for (let i = 0; i < block_lines.length; i++) {
        let pl = block_lines[i]
        if (pl.type === "FeatureCollection") {

            for (let j = 0; j < pl.features.length; j++) {
                let angle = calculateAngle(pl.features[j].geometry.coordinates);
                just_lines.push({ line: lineString(pl.features[j].geometry.coordinates), index: i, });
            }
        }
        else {
            let angle = calculateAngle(pl.geometry.coordinates);
            just_lines.push({ line: pl, index: i, angle: angle });
        }
    }
    //Check Closest neigbor Lines
    let parlLines = [];
    let redundant = [];
    let polyline = [];
    let linedist = [];
    let tr = [];
    for (let i = 0; i < just_lines.length; i++) {
        let l = just_lines[i].line.geometry.coordinates;
        let linescol = lineSegment(just_lines[i].line)
        linescol.features.forEach(e => {

            let ldist = distance(e.geometry.coordinates[0], e.geometry.coordinates[1], { units: 'meters' }) / 111111
            e.properties = { angle: calculateAngle(e.geometry.coordinates), index: i, len: ldist }
            linedist.push(e)
        });
    }
    for (let i = 0; i < linedist.length; i++) {
        for (let j = 0; j < linedist.length; j++) {
            let nextLine = linedist[j]
            let curLine = linedist[i]
            // console.log(curLine.geometry.)
            // centroid()
            let curCenter = centroid(curLine)
            let nextCenter = centroid(nextLine)
            if (nextLine.properties.index !== curLine.properties.index && i !== j) {
                // console.log([angle_diff,dist])
                let angle_diff = Math.abs(nextLine.properties.angle - curLine.properties.angle);
                let dist = distance(nextCenter.geometry.coordinates, curCenter.geometry.coordinates, { units: 'meters' }) / 111111
                if (angle_diff < 0.15) {
                    // console.log([curLine,nextLine,dist,angle_diff])
                    if (!parlLines.includes(curLine)) {
                        parlLines.push(curLine)
                    }
                    if (!parlLines.includes(nextLine)) {
                        parlLines.push(nextLine)
                    }
                    // linedist.splice(j,1)
                    // linedist.splice(i,1)
                }
                // else{
                //     if(dist<=4){
                //         console.log(angle_diff)
                //     }
                // }
                if (true) {
                    if (!parlLines.includes(curLine) && !parlLines.includes(nextLine)) {

                    }
                    else {
                        if (!parlLines.includes(nextLine)) {
                            redundant.push(curLine)
                        }
                        else {
                            redundant.push(nextLine)
                        }

                    }
                    tr.push([i, j])
                    tr.push([j, i])
                }
                else {
                }
            }
        }
    }
    // parlLines.()
    // let parCoord = []
    // parlLines.forEach((p)=>{
    //     p.geometry.coordinates.forEach(c=>{
    //         parCoord.push(C)
    //     })
    // })
    //console.log(parlLines)
    parlLines = featureCollection(parlLines)
    return parlLines;
    // }
    // else {
    //     return null;
    // }
}
function SurveyPane({ transactionId, stage, nw, transactionCode }) {
    const [survey, setSurvey] = React.useState({});
    const [transaction, setTransaction] = React.useState(0);
    const [geom, setGeom] = React.useState(0);
    const [curstage, setStage] = React.useState(0);
    const [isNew, setNew] = React.useState(nw);
    const [, forceUpdate] = React.useState();
    const [tp, setTp] = React.useState(null);
    React.useEffect(() => {
        if (isNew) {
            TxCreateService.createTransaction({
                txDefinition: 70,
                isNew: true,
                projectId: MobilePopup.id()
            })
                .then((r) => {
                    setTransaction(r.data.txId)
                    setStage(r.data.stage);
                    setNew(false)
                });
        }
        else {
            setStage(stage)
            setTransaction(transactionId)
        }
        forceUpdate({})
    }, []);
    const updateSMD = (prop) => {
        setSurvey(prop)
    }
    return (
        <div>
            {transaction !== 0 && curstage !== 0 ?
                <KTab heads={["Process", "Spatial Unit", "Printing Layout", "Attachments", "Remarks"]}
                    body={[
                        <StageHandler
                            // newRequest={newRequest}
                            // transactionCode={69}
                            data={survey}
                            updateData={updateSMD}
                            curStage={curstage}
                            transactionId={transaction}
                        />,
                        <SpatialMapDisplay
                            computeData={true}
                            txId={transaction}
                            sptype={"sp"}
                            type="sp"
                            updateGeom={setGeom}
                        />,
                        <PrintingLayout data={tp} txId={transaction} sptype={"sp"}
                            type="sp" />,
                        <Documents txId={transaction} />,
                        <TPRemarks txId={transaction} />,
                    ]}
                /> : null}
        </div>
    )
}
export default SurveyTab;
