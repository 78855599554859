import React from "react";
import MobileCreateService from "../../../../../services/mobile/mobile_create";
import Wait from "../../../wait";
import PopDetail from "../../../popdetail";
import ProjectConfig from "./project_config";
import MobileUpdateService from "../../../../../services/mobile/mobile_update";

export default class LocationMapperPane extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            location:0
        }
        this.handleChange = this.handleChange.bind(this)
        this.saveHamlet = this.saveHamlet.bind(this)
    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }

    updateHamletAt(i,d){
        /*let code = document.getElementById('hcode'+i).value.trim().toUpperCase()
        let name = document.getElementById('hname'+i).value.trim().toUpperCase()

        Wait.show('Saving...')
        MobileUpdateService.updateHamlet({
            id: d.id,
            name: name,
            code: code
        }).then(r  =>{
            Wait.dismiss()
            ProjectConfig.loadVillages()
        })*/

    }

    static popAddHamlet(){
        document.getElementById('pop_add_hamlet').style.display='inherit'
    }

    static hideAddHamlet(){
        document.getElementById('pop_add_hamlet').style.display='none'
    }

    saveHamlet(){
        console.log(this.state.location)
    }

    render() {
        return(<div style={{width:'100%',height:'100%',overflow:'auto'}}>

            <div className={'row'} style={{boxShadow:'0 4px 4px #aaa',padding:'8px 16px',backgroundColor:'#efefef'}}>
                <div className={'col col-sm-8'}>
                    <span className={'fa fa fa-bars'} style={{cursor:'pointer', marginTop:'8px'}} />
                    <input style={{width:'85%',marginLeft:'24px',backgroundColor:'#fff'}} placeholder={'Search here...'}/>
                    <span className={'fa fa fa-plus-circle'} style={{color:'#008b8b', float:'right',cursor:'pointer', margin:'8px'}}
                    />
                </div>
                <div className={'col col-sm-4 fa-btn'}>
                    <button style={{float:'right',width:'80px'}} onClick={()=>PopDetail.dismiss()} className={'default'}>Refresh</button>
                </div>
            </div>

            <div className={'col col-sm-12'} style={{height:'55vh',border:'1px solid #ccc',padding:'0',overflow:'auto'}}>
                <select>
                    {this.props.data.mapped_option.map((d,i)=>{
                        return(<option key={i} value={d.id}>{d.name}
                        </option>)
                    })}

                </select>
            </div>
            <br/>
            <button onClick={this.saveHamlet}>Submit</button>
        </div>)
    }
}