import * as React from "react";
import PopDetail from "../../popdetail";
import PaneAddDocuments from "../pops/pane_add_documents";
import TransactionPopup from "../transaction_popup";
import TxReadService from "../../../../services/transaction/transaction_read";
import API from "../../../../services/API";
import Wait from "../../wait";
import Header from "./gro/header";
export default class DocumentsPane extends React.Component {

    static self = null
    constructor(props) {
        super(props);
        this.state = {
            listOfDoc: [],
            listOfRequiredDoc: [],
            listOfOutgoingDoc: [],
            current_doc: {},
            selected: -1,
            docId: 0,
            doc_frame: <div />
        }
        DocumentsPane.self = this
        this.showDocumentAt = this.showDocumentAt.bind(this)
        this.loadAttachedDocs = this.loadAttachedDocs.bind(this)
        this.handleRemoveDoc = this.handleRemoveDoc.bind(this)
    }

    componentDidMount() {
        try {
            this.loadAttachedDocs()
            let doc = TransactionPopup.self.state.documents
            this.setState({
                listOfRequiredDoc: doc.listOfRequiredDoc,
                listOfOutgoingDoc: doc.listOfOutgoingDoc,
            })
        } catch (e) {
            console.log(e)
        }
    }
    static setState(state_name, state_value) { DocumentsPane.self.setState({ [state_name]: state_value }) }
    static state() { return DocumentsPane.self.state }

    render() {
        return (<div >

            <div className={'row'} style={{ marginBottom: '4px' }}>

                <div className={'col col-sm-3'} style={{ height: '93vh', paddingRight: '4px' }}>
                    <div style={{ background: '#fff', padding: '24px', height: '46.5vh', border: '0.5px solid #efefef' }}>
                        <div style={{ height: '40px', lineHeight: '40px' }}>
                            <b>Attached Documents</b>
                        </div>
                        <div style={{ width: '100%', marginTop: '8px', height: '43vh', overflow: 'auto', zIndex: 1 }}>
                            {this.state.listOfDoc.map((d, i) => {
                                return (<div key={i} style={{ width: '100%', cursor: 'pointer' }} onClick={() => this.showDocumentAt(d, i)}>
                                    <div><span className={'fa fa-file'} style={{ marginRight: '8px' }} />{d.documentName}</div>
                                    <div>{d.tag}</div>
                                </div>)
                            })}
                        </div>
                    </div>
                    <div style={{ background: '#fff', padding: '24px', height: '46.5vh', marginTop: '4px', border: '0.5px solid #efefef' }}>
                        <div style={{ height: '40px', lineHeight: '40px' }}>
                            <b>Required Document</b>
                        </div>
                        <div style={{ width: '100%', marginTop: '8px', height: '43vh', overflow: 'auto', zIndex: 10 }}>
                            {this.state.listOfRequiredDoc !== null ? this.state.listOfRequiredDoc.map((d, i) => {
                                return (<div key={i} style={{ width: '100%', cursor: 'pointer' }}>
                                    <div><span className={'fa fa-file'} style={{ marginRight: '8px' }} />{d.documentName}</div>
                                    <div>{d.tag}</div>
                                </div>)
                            }) : <div />}
                        </div>
                    </div>

                </div>

                <div className={'col col-sm-6'} style={{ height: '93vh', paddingRight: '4px', paddingLeft: 0 }}>

                    <div style={{ background: '#fff', height: '100%', padding: '24px', border: '0.5px solid #efefef' }}>

                        <Header display={true} label={"Documents"} heading={false}>
                            <div>
                                <i className={'fa fa-plus-circle'}
                                    style={{ color: 'tomato', fontSize: '25px', cusor: 'pointer' }}
                                    onClick={() => PopDetail.show('Add Document', <PaneAddDocuments />, 40)} /> <b>Add</b>
                            </div>
                            <div>
                                <i className={'fa fa-trash'}
                                    style={{ color: 'tomato', fontSize: '25px', cusor: 'pointer' }}
                                    onClick={this.state.selected === -1 ? () => { } : () => { this.handleRemoveDoc() }} /> <b>Remove</b>
                            </div>
                        </Header>
                        {this.state.doc_frame}


                    </div>

                </div>

                <div className={'col col-sm-3'} style={{ height: '94vh', paddingLeft: '4px' }}>
                    <div style={{ background: '#fff', padding: '24px', height: '100%', border: '0.5px solid #efefef' }}>
                        <div style={{ height: '40px', lineHeight: '40px' }}>
                            <b>Properties</b>
                        </div>
                        <div style={{ width: '100%', marginTop: '8px' }}>
                            <div>Document ID</div>
                            <div><input style={{ width: '100%' }} defaultValue={this.state.current_doc.docId} readOnly={true} /></div>
                            <div>Document No</div>
                            <div><input style={{ width: '100%' }} defaultValue={this.state.current_doc.docNo} readOnly={true} /></div>
                            <div>Document</div>
                            <div><input style={{ width: '100%' }} defaultValue={this.state.current_doc.documentName} readOnly={true} /></div>
                            <div>Issued Date</div>
                            <div><input style={{ width: '100%' }} defaultValue={this.state.current_doc.issuedDate} readOnly={true} /></div>
                            <div>
                                <div style={{ width: '50%', float: 'left' }}>Instrument</div>
                                <div style={{ width: '50%', float: 'left' }}>FD Number</div>
                            </div>
                            <div>
                                <div style={{ width: '50%', float: 'left' }}><input type={'checkbox'} checked={this.state.current_doc.instrument} readOnly={true} /></div>
                                <div style={{ width: '50%', float: 'left' }}><input style={{ width: '100%' }} defaultValue={this.state.current_doc.fdNo} readOnly={true} /></div>
                            </div>
                            <div>
                                <div style={{ width: '50%', float: 'left' }}>Estimated Pages</div>
                                <div style={{ width: '50%', float: 'left' }}>Scanned Pages</div>
                            </div>
                            <div>
                                <div style={{ width: '50%', float: 'left' }}><input style={{ width: '98%' }} defaultValue={this.state.current_doc.estimated} readOnly={true} /></div>
                                <div style={{ width: '50%', float: 'left' }}><input style={{ width: '100%' }} defaultValue={this.state.current_doc.scannedPages} readOnly={true} /></div>
                            </div>
                            <div>Description</div>
                            <div><textarea style={{ width: '100%' }} rows={4} defaultValue={this.state.current_doc.description} readOnly={true} /></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>)
    }

    showDocumentAt(d, i) {
        console.log(d)
        this.setState({ selected: i, docId: d.docId })
        setTimeout(() => {
            this.setState({
                current_doc: d,
                doc_frame: <embed style={{ height: '100%', width: '100%' }} src={"data:application/pdf;base64, " + d.base64} />
            })
        }, 1000)
        this.setState({
            current_doc: '',
            doc_frame: <div></div>
        })
    }

    loadAttachedDocs() {
        TxReadService.getAttachedDocts(TransactionPopup.id()).then(d => {
            console.log("MWakijule", d.data.result)
            this.setState({ listOfDoc: d.data.result })
        })
    }
    handleRemoveDoc() {
        const { selected, docId } = this.state
        if (this.state.selected > -1) {
            Wait.show("Removing Document ....")
            const txId = TransactionPopup.id()
            const data = { documentId: docId, transactionId: txId }
            // transaction/remove-document
            API.ax.post("transaction/remove-document", data)
                .then(d => {
                    if (d.data.status === 1) {
                        const { listOfDoc } = this.state;
                        const value = listOfDoc[selected];
                        const newDocList = listOfDoc.filter(item => item !== value)
                        this.setState({ listOfDoc: newDocList, selected: -1 })

                    }
                })
                .catch(e => console.error(e))
            Wait.dismiss()
        }
    }
}
