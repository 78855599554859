import React, {useEffect, useState} from 'react'
import {ItemHistory, ItemRequest, styles} from "../popups/transacrions/screen/record-request";
import Header from "../popups/transacrions/screen/gro/header";
import PopDetail from "../popups/popdetail";
import {useForm} from "react-hook-form";
import GeneralSelect from "../../utils/GeneralSelect";
import API from "../../services/API";

const Item =({isReadOnly, data,submit, isNew = false, index})=>{
    const{register, handleSubmit} = useForm()
    const[id,idSet] = useState('')
    const[values,valuesSet] = useState('')
    const onChangeId = event =>{valuesSet(event.value)}
    const submitData = datas =>{
        let someData ={}
        console.log("Data ",data)
        if(index === -1){
            someData ={...datas,permanentBoxId:values};
        }else {
            someData ={...data, ...datas,permanentBoxId:values};
        }

        console.log("data ",{...datas,permanentBoxId:values})
        API.ax.post("/rts/new-item",someData)
            .then(d=>{
                console.log("items ", d.data)
                const{result, status} = d.data
                if(status === 1){
                    submit(result, index)
                }
            })
    }
    const departments =
        {
            'endpoint':'/rts/new-location',
            'value':'id',
            'label':'boxNo',
            'placeholder':'Select Permanent Location'
        }
        useEffect(()=>{
            if(index === -1){
                API.ax.get("/rts/item-id")
                    .then(d=>{
                        const{result}=d.data
                        idSet(result)
                    })
            }
        },[index])
    return(
        <form onSubmit={handleSubmit(submitData)}>
            <table style={styles.table}>
                <tbody>
                <tr>
                    <td>Item ID</td>
                </tr>
                <tr>
                    <td><input type={"text"} style={styles.table} name={"itemId"} defaultValue={data?data.itemId:id} ref={register({required:true})} readOnly={true}/></td>
                </tr>
                <tr>
                    <td>Condition</td>
                </tr>
                <tr>
                    {
                        isReadOnly?<td><input type={"text"} style={styles.table} name={"condition"} defaultValue={data?data.condition:''} readOnly={isReadOnly} /></td>
                                  : <td>
                                <select name={"condition"} ref={register({required:true})} style={styles.table} defaultValue={data?data.condition:''}>
                                    <option value={""}/>
                                    <option value={"Damaged"}>Damaged</option>
                                    <option value={"Good"}>Good</option>
                                    <option value={"Unknown"}>Unknown</option>
                                </select>
                                  </td>
                    }
                </tr>
                <tr>
                    <td>Item Type</td>
                </tr>
                <tr>
                    {
                        isReadOnly?<td><input type={"text"} style={styles.table} name={"itemType"} defaultValue={data?data.itemType:''} readOnly={isReadOnly} /></td>
                            : <td>
                                <select name={"itemType"} ref={register({required:true})} defaultValue={data?data.itemType:''} style={styles.table}>
                                    <option value={""}/>
                                    <option value={"LAD Folder"}>LAD Folder</option>
                                    <option value={"SMD Folder"}>SMD Folder</option>
                                    <option value={"Title Folder"}>Title Folder</option>
                                </select>
                            </td>
                    }
                </tr>
                <tr>
                    <td>Permanent Location</td>
                </tr>
                <tr>
                    {
                        isReadOnly? <td><input type={"text"} style={styles.table} name={"permanentLocation"} defaultValue={data?data.permanentLocation:''} readOnly={isReadOnly}/></td>
                                  :<td><GeneralSelect parameters={departments} onChanges={onChangeId} defaultValue={data?data.permanentLocation:null}/></td>
                    }
                </tr>
                <tr>
                    <td>Item Number</td>
                </tr>
                <tr>
                    <td><input type={"text"} style={styles.table} name={"itemNo"} ref={register({required:true})} defaultValue={data?data.itemNo:''}/></td>
                </tr>
                {!isReadOnly?<tr><td><button type={"submit"} style={{float:"right", marginTop:".5rem", width:'100px'}}>Save</button></td></tr>:<tr/>}
                </tbody>
            </table>
        </form>
    )
}

const Remove = () =>{
    return(
        <></>
    )
}

export default function Items() {
    const[currentMenu, currentMnuSet] = useState(0)
    const[items, itemsSet] = useState([])
    const[index, indexSet] = useState(-1)
    const[item, itemSet] = useState({})
    useEffect(()=>{
        if(index > -1){
            itemSet(items[index])
        }
    },[index])
    const submitted=(dataz,idBack)=>{
        let toData =[...items]
        if(idBack === -1){
            toData.push(dataz)
            itemsSet(toData)
        }else{
            toData[idBack] = dataz
            itemsSet(toData)
        }
    }
    useEffect(()=>{
        API.ax.get("/rts/new-items")
            .then(d=>{
                const{status,result} = d.data
                if(status === 1){
                    console.log("items ",result)
                    itemsSet(result)
                }
            })
    },[])
    return(
        <div style={{...styles.main, height:'100%'}}>
            <Header label={"Items"} display={true}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("New Item",<Item isNew={true} index={index} submit={submitted}/>,55)}}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={index >-1?()=>{PopDetail.show("Edit Item",<Item index={index} data={item}  submit={submitted}/>,55)}:()=>{}}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={index>-1?()=>{PopDetail.show("Remove Item",<Remove/>,50)}:()=>{}}
                    /><strong> Remove</strong>
                </div>
            </Header>
            <div style={{...styles.content}}>
                <table className={"tb"} style={styles.table}>
                    <thead>
                    <tr>
                        <th>ITEM ID</th>
                        <th>ITEM TYPE</th>
                        <th>ITEM NUMBER</th>
                        <th>CURRENT LOCATION</th>
                        <th>PERMANENT LOCATION</th>
                        <th>CONDITION</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        items.map(({itemId, condition, itemType, permanentLocation,itemNo, currentLocation},i)=>
                        <tr key={i} onClick={()=>indexSet(i)} style={i === index?{background:"#FCEBE9"}:{}}>
                            <td>{itemId}</td>
                            <td>{itemType}</td>
                            <td>{itemNo}</td>
                            <td>{currentLocation}</td>
                            <td>{permanentLocation}</td>
                            <td>{condition}</td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>
            <div className={"row"} style={styles.detailsHeader}>
                <label style={currentMenu === 0?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMnuSet(0)}}>General</label>
                <label style={currentMenu === 1?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMnuSet(1)}}>Item History</label>
                <label style={currentMenu === 2?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMnuSet(2)}}>Item Requests</label>
            </div>
            <div style={currentMenu === 0?styles.show:styles.hide}>
                <Item isReadOnly={true} data={item}/>
            </div>
            <div style={currentMenu === 1?styles.show:styles.hide}>
                <ItemHistory/>
            </div>
            <div style={currentMenu === 2?styles.show:styles.hide}>
                <ItemRequest/>
            </div>
        </div>
    )

}