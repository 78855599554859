import React from "react";
import PopDetail from "../../../popdetail";

export default class PopNewIssues extends React.Component{
    render() {
        return(<div>
            <div>* Reason</div>
            <div>
                <select style={{width:'100%'}}>
                    <option></option>
                </select>
            </div>
            <div>* Details</div>
            <div>
                <textarea style={{width:'100%'}} rows={8}/>
            </div>
            <div>* Status</div>
            <div>
                <select style={{width:'100%'}}>
                    <option>Pending</option>
                </select>
            </div>
            <hr/>
            <div style={{textAlign:'right'}}>
                <button style={{width:'80px'}}>Save</button>
                <button  onClick={PopDetail.dismiss} style={{width:'80px'}} className={'default'}>Cancel</button>
            </div>
        </div>)
    }
}