import * as React from "react";
import PopDetail from "../../../../popdetail";
import Wait from "../../../../wait";
import ReportCreateService from "../../../../../../services/common/reports/report_create";

export default class PopFinanceTransactions extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            to: null,
            from:null
            /*dt0:'',
            dt1: '',
            sort: '',
            transaction: '',
            parcel: '',
            officeId: 0*/
        }

        this.handleChange = this.handleChange.bind(this)
        this.generate = this.generate.bind(this)

    }

    componentDidMount() {

    }

    generate(){
        try {
            Wait.show('Generating...')
console.log("date from get reports==",this.state.from)
            console.log("date to get reports==",this.state.to)
            ReportCreateService.fianceTransactionDetails(this.state.from,this.state.to
            /*    dt0: this.state.dt0,
                dt1: this.state.dt1,
                sort: this.state.sort,
                transaction: this.state.transaction,
                parcel: this.state.parcel,
                officeId: this.state.officeId*/
            ).then(r =>{
               console.log("data iliorudi==",r)
                Wait.dismiss()
                PopDetail.dismiss()

                const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "ilmis.csv"); //or any other extension
                document.body.appendChild(link);
                link.click();

            })
        }catch (e) {
            console.log(e)
        }
    }

    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}

    render() {
        return(<div className={'col col-sm-12'}>

            <table style={{width:'100%'}}>
                <tbody>
                <tr style={{color:'darkcyan'}}>
                    <td>* Submitted Date From</td>
                    <td>* Submitted Date To</td>
                </tr>
                <tr>
                    <td>
                        <input name={'from'} type={'date'} style={{width:'100%'}} onChange={this.handleChange} />
                    </td>
                    <td>
                        <input name={'to'} type={'date'} style={{width:'100%'}} onChange={this.handleChange}/>
                    </td>
                </tr>
              {/*  <tr style={{color:'darkcyan'}}>
                    <td>* Sort Order</td>
                    <td>Office</td>
                </tr>
                <tr>
                    <td>
                        <select  style={{width:'100%'}} name={'sort'} onChange={this.handleChange}>
                            <option></option>
                            <option value={'txNo'}>By Transaction Number</option>
                            <option value={'txTypeTask'}>By Transaction Type, By Task</option>
                        </select>
                    </td>
                    <td>
                        <select  style={{width:'100%'}} name={'officeId'} onChange={this.handleChange}>
                            <option value={0}></option>
                            <option value={0}>DODOMA CITY COUNCIL</option>
                        </select>
                    </td>
                </tr>
                <tr style={{color:'darkcyan'}}>
                    <td>Transactions</td>
                    <td>Include Parcel Details</td>
                </tr>
                <tr>
                    <td>
                        <select  style={{width:'100%'}} name={'transaction'} onChange={this.handleChange}>
                            <option></option>
                            <option>All Transactions</option>
                        </select>
                    </td>
                    <td>
                        <select  style={{width:'100%'}} name={'parcel'} onChange={this.handleChange}>
                            <option></option>
                            <option>Yes</option>
                            <option>No</option>
                        </select>
                    </td>
                </tr>
*/}

                </tbody>
            </table>

            <hr/>
            <div className={{textAlign:'right'}}>
                <button onClick={this.generate}>Generate</button>
                <button className={'default'} onClick={PopDetail.dismiss}>Cancel</button>
            </div>
        </div>)
    }
}