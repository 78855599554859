import * as React from 'react'
import CommonService from '../services/common/common_read'
import DynamicForm from './formhandler';
import TxCreateService from '../services/transaction/transaction_create';
import PreviewHandler from './previewhandler';
import MobilePopup from '../components/popups/mobile/mobile_popup';
import { Card } from 'react-bootstrap';

export default function StageHandler({ newRequest, transactionCode, transactionId, curStage, updateData }) {
    const [stages, setStages] = React.useState([])
    const [fu, uf] = React.useState({});
    const [nr, setNr] = React.useState(newRequest);
    const [config, setConfig] = React.useState({});
    React.useEffect(() => {
        setTimeout(() => {
            if (transactionId !== 0) {
                CommonService.readStage(curStage, transactionId).then(r => {
                    setStages(r.data.results)
                    setConfig(r.data.config)
                    // setTransaction(transactionId);
                });
            }
            else {

            }
        }, 2000);
    }, [fu])
    return (
        <div>
            <div>
                {config.last_error==="Your request was not approved because of Reason: no_error"?null:<div className='alert alert-danger'>{config.last_error}</div>}
            </div>
            <div>

                {
                    stages.map((prop) => (
                        <div className="vertical-timeline-element--work"
                            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            date="2011 - present"
                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        // icon={<WorkIcon />}
                        >

                            {prop.type === "form" ?
                                <div className='card mb-3'>
                                    <div className='alert alert-primary mb-3'>
                                        Current Stage {prop.description}
                                    </div>
                                    <div className='card-header'><b>{prop.title}</b></div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-8'>
                                                {prop.type === "form" ? <div className='alert alert-info'>{prop.description}</div> : null}
                                                <DynamicForm updateData={updateData} config={config} update={uf} code={prop.formCode} txId={transactionId} inputs={prop.inputs} />
                                            </div>
                                            <div className='col-4' style={{ backgroundColor: "#ddd", margin: 0 }}>
                                                <h6 className='mb-3 mt-3'><b>Checklists to be reviewed</b></h6>
                                                {
                                                    prop.checklist.map((prop) => (
                                                        <div className={'alert ' + checkchecklist(prop.status)}>{prop.name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : prop.display && null/*<PreviewHandler code={prop.formCode} tx={transactionId} form={prop.inputs} />*/}
                        </div>

                    ))
                }
            </div>

        </div>
    )
}
function checkchecklist(status) {
    switch (status) {
        case 0:
            return 'alert-warning';
        case 1:
            return 'alert-success';
        case 2:
            return 'alert-danger';
    }
}