import React, {useEffect, useState} from 'react'
import {styles} from "../record-request";
import Header from "./header";
import PopDetail from "../../../popdetail";
import GeneralSelect from "../../../../../utils/GeneralSelect";
import TransactionPopup from "../../transaction_popup";
import {useForm} from "react-hook-form";
import API from "../../../../../services/API";
import PropertyGRO from "./property_gro";
/* a lot of things are not okay here... fix them later*/
const Rrr = ({interestId,tenancyType,transactionId, dataResp}) =>{
    const[partyId, partyIdSet] =useState()
    let endPoint =`transaction/transaction-parties/${transactionId}`
    const partiesOptions =
        {
            'endpoint':endPoint,
            'value':'partyId',
            'label':'fullName',
            'placeholder':'Select Party'
        }
     const setPartyId = data =>{
        partyIdSet(data?data.map(a=>a.value):[])
     }
     const submitRrr = data =>{
         if(partyId !== undefined){
             console.log("the partyId ",partyId)
             let datas ={...data,transactionId,tenancyType,interestId,partyId}
             console.log("Party Id  tenancy",datas)
             API.ax.post("/land/rrr",datas)
                 .then(d=>{
                     //const[result, status] = d.data
                     if(d.data.status === 1){
                         console.log("the result ",d.data.result)
                         dataResp(d.data.result)

                     }
                 })

             PopDetail.dismiss()
         }
     }

     const{register,handleSubmit}=useForm()
    return(
        <form onSubmit={handleSubmit(submitRrr)}>
        <div style={{...styles.table,...localStyle.container}}>
            <span><span style={{color:'red'}}> * </span> Party Role</span>
            {TransactionPopup.transaction().name==="Mortgage Right of Occupancy"?<select defaultValue={"Mortgagor"} name={"partyRoleType"} ref={register({required:"true"})}>
                <option value={""}/>
                <option value={"Mortgagor"}>Mortgagor</option>
                <option value={"Mortgagee"}>Mortgagee</option>
            </select>:TransactionPopup.transaction().name==="Transfer Right of Occupancy" ||
            TransactionPopup.transaction().name==="Transfer Unit Title"?<select defaultValue={"Transferee"} name={"partyRoleType"} ref={register({required:"true"})}>
                <option value={""}/>
                <option value={"Transferee"}>Transferee</option>
            </select>:<select defaultValue={"Occupier"} name={"partyRoleType"} ref={register({required:"true"})}>
                <option value={""}/>
                <option value={"Legal Representative"}>Legal Representative</option>
                <option value={"Occupier"}>Occupier</option>
            </select>}
            <span><span style={{color:'red'}}> * </span> Share</span>
            <div style={{...localStyle.row}}>
                <input type={"number"} style={{width:'100%'}} defaultValue={1} name={"shareNumerator"}  ref={register({required:"true"})}/>
                <span style={{fontSize:"25px"}}>/</span>
                <input type={"number"} style={{width:'100%'}} defaultValue={1} name={"shareDenominator"}  ref={register({required:"true"})}/>
            </div>
            <span><span style={{color:'red'}}> * </span> Person</span>
            <GeneralSelect parameters={partiesOptions} onChanges={setPartyId} isMulti={true}/>
            <div style={{...localStyle.row, marginTop:".5rem"}}>
                <button type={"submit"}  style={{width:"150px"}}>Save</button>
            </div>
        </div>
        </form>
    )
}
const Table =({ compName,transactionId, refreshh }) =>{
    const [refresh,refreshSet]=useState(refreshh)
    const[dataToLoop, dataToLoopSet] =useState([])
    const[mortgagors, mortgagorsSet] =useState([])
    const[mortgagees, mortgageesSet] =useState([])
    const[transferees,transfereesSet] = useState([])
    const[transferors,transferorsSet] =useState([])
    const[unitOwner,setUnitOwner] =useState([])
    //const [dataToRemove,dataToRemoveSet] = useState(null)
    useEffect(()=>{
        API.ax.get(`/land/rrr/${TransactionPopup.id()}`)
            .then( d=>{
                console.log("the  occupiers ",d.data)
                const{result,status} = d.data
                if(status === 1){
                    console.log("the result of occupiers ",result)
                    mortgagorsSet(result.filter(d=>d.partyRole==='Mortgagor'))
                    mortgageesSet(result.filter(d=>d.partyRole==='Mortgagee'))
                    transferorsSet(result.filter(d=>d.partyRole==='Transferor'))
                    transfereesSet(result.filter(d=>d.partyRole==='Transferee'))
                    setUnitOwner(result.filter(d=>d.partyRole==='Unit Owner'))
                    dataToLoopSet(result)
                }
            })
    },[refreshh])
    useEffect(()=>{
        if(refresh !== undefined && refresh.length>0){
            dataToLoopSet(refresh)
        }
    },[refresh])
    return(
        <div style={{...styles.content, height:'150px'}}>
            <table className={'tb'} style={styles.table}>
                <thead>
                <tr>
                    <td>REMOVE</td>
                    <th>SHARE</th>
                    <th>PARTY ROLE</th>
                    <th>NAME</th>
                    <th>ADDRESS</th>
                </tr>
                </thead>
                <tbody>
                {
                    compName==='Mortgagor'?
                    mortgagors.map(({id,share, partyRole, name,address,transactionId},i)=>
                    <tr key={i} >
                        <td ><span  className={'fa fa-trash'}
                                      style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                            .then(d=>{
                                console.log("the occupiers ",d.data)
                                const{result,status} = d.data
                                if(status === 1){
                                    console.log("the result of occupiers ",result)
                                    dataToLoopSet(result)
                                    refreshSet(result)
                                }

                            })}}></span></td>
                        <td>{share}</td>
                        <td>{partyRole}</td>
                        <td>{name}</td>
                        <td>{address}</td>

                    </tr>
                    ):compName==='Mortgagee'?
                        mortgagees.map(({id,share, partyRole, name,address,transactionId},i)=>
                            <tr key={i} >
                                <td ><span  className={'fa fa-trash'}
                                            style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                    .then(d=>{
                                        console.log("the occupiers ",d.data)
                                        const{result,status} = d.data
                                        if(status === 1){
                                            console.log("the result of occupiers ",result)
                                            dataToLoopSet(result)
                                            refreshSet(result)
                                        }

                                    })}}></span></td>
                                <td>{share}</td>
                                <td>{partyRole}</td>
                                <td>{name}</td>
                                <td>{address}</td>

                            </tr>
                        ):compName==='Transferee'?
                            transferees.map(({id,share, partyRole, name,address,transactionId},i)=>
                                <tr key={i} >
                                    <td ><span  className={'fa fa-trash'}
                                                style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                        .then(d=>{
                                            console.log("the occupiers ",d.data)
                                            const{result,status} = d.data
                                            if(status === 1){
                                                console.log("the result of occupiers ",result)
                                                transfereesSet(result)
                                                refreshSet(result)
                                            }

                                        })}}></span></td>
                                    <td>{share}</td>
                                    <td>{partyRole}</td>
                                    <td>{name}</td>
                                    <td>{address}</td>

                                </tr>
                            ):compName==='Transferor'?
                                transferors.map(({id,share, partyRole, name,address,transactionId},i)=>
                                    <tr key={i} >
                                        <td ><span  className={'fa fa-trash'}
                                                    style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                            .then(d=>{
                                                console.log("the occupiers ",d.data)
                                                const{result,status} = d.data
                                                if(status === 1){
                                                    console.log("the result of occupiers ",result)
                                                    dataToLoopSet(result)
                                                    refreshSet(result)
                                                }

                                            })}}></span></td>
                                        <td>{share}</td>
                                        <td>{partyRole}</td>
                                        <td>{name}</td>
                                        <td>{address}</td>

                                    </tr>
                                ):TransactionPopup.transaction().name==='Grant Right of Occupancy'?dataToLoop.map(({id,share, partyRole, name,address,transactionId},i)=>
                            <tr key={i} >
                                <td ><span  className={'fa fa-trash'}
                                            style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                    .then(d=>{
                                        console.log("the occupiers ",d.data)
                                        const{result,status} = d.data
                                        if(status === 1){
                                            console.log("the result of occupiers ",result)
                                            dataToLoopSet(result)
                                            refreshSet(result)
                                        }

                                    })}}></span></td>
                                <td>{share}</td>
                                <td>{partyRole}</td>
                                <td>{name}</td>
                                <td>{address}</td>

                            </tr>
                        ):TransactionPopup.transaction().name==='Surrender Right of Occupancy'?dataToLoop.map(({id,share, partyRole, name,address,transactionId},i)=>
                                        <tr key={i} >
                                            <td ><span  className={'fa fa-trash'}
                                                        style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                                .then(d=>{
                                                    console.log("the occupiers ",d.data)
                                                    const{result,status} = d.data
                                                    if(status === 1){
                                                        console.log("the result of occupiers ",result)
                                                        dataToLoopSet(result)
                                                        refreshSet(result)
                                                    }

                                                })}}></span></td>
                                            <td>{share}</td>
                                            <td>{partyRole}</td>
                                            <td>{name}</td>
                                            <td>{address}</td>

                                        </tr>
                                    )
                                    :TransactionPopup.transaction().name==='Renew Right of Occupancy'?dataToLoop.map(({id,share, partyRole, name,address,transactionId},i)=>
                                        <tr key={i} >
                                            <td ><span  className={'fa fa-trash'}
                                                        style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                                .then(d=>{
                                                    console.log("the occupiers ",d.data)
                                                    const{result,status} = d.data
                                                    if(status === 1){
                                                        console.log("the result of occupiers ",result)
                                                        dataToLoopSet(result)
                                                        refreshSet(result)
                                                    }

                                                })}}></span></td>
                                            <td>{share}</td>
                                            <td>{partyRole}</td>
                                            <td>{name}</td>
                                            <td>{address}</td>

                                        </tr>
                                    ):TransactionPopup.transaction().name==='Rectification by LAD'?dataToLoop.map(({id,share, partyRole, name,address,transactionId},i)=>
                                        <tr key={i} >
                                            <td ><span  className={'fa fa-trash'}
                                                        style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                                .then(d=>{
                                                    console.log("the occupiers ",d.data)
                                                    const{result,status} = d.data
                                                    if(status === 1){
                                                        console.log("the result of occupiers ",result)
                                                        dataToLoopSet(result)
                                                        refreshSet(result)
                                                    }

                                                })}}></span></td>
                                            <td>{share}</td>
                                            <td>{partyRole}</td>
                                            <td>{name}</td>
                                            <td>{address}</td>

                                        </tr>
                                    ):TransactionPopup.transaction().name==='Replace Title Certificate'?dataToLoop.map(({id,share, partyRole, name,address,transactionId},i)=>
                                            <tr key={i} >
                                                <td ><span  className={'fa fa-trash'}
                                                            style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                                    .then(d=>{
                                                        console.log("the occupiers ",d.data)
                                                        const{result,status} = d.data
                                                        if(status === 1){
                                                            console.log("the result of occupiers ",result)
                                                            dataToLoopSet(result)
                                                            refreshSet(result)
                                                        }

                                                    })}}></span></td>
                                                <td>{share}</td>
                                                <td>{partyRole}</td>
                                                <td>{name}</td>
                                                <td>{address}</td>

                                            </tr>)
                                        :TransactionPopup.transaction().name==='TIC Grant Right of Occupancy'?dataToLoop.map(({id,share, partyRole, name,address,transactionId},i)=>
                                        <tr key={i} >
                                            <td ><span  className={'fa fa-trash'}
                                                        style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                                .then(d=>{
                                                    console.log("the occupiers ",d.data)
                                                    const{result,status} = d.data
                                                    if(status === 1){
                                                        console.log("the result of occupiers ",result)
                                                        dataToLoopSet(result)
                                                        refreshSet(result)
                                                    }

                                                })}}></span></td>
                                            <td>{share}</td>
                                            <td>{partyRole}</td>
                                            <td>{name}</td>
                                            <td>{address}</td>

                                        </tr>
                                    ):TransactionPopup.transaction().name==='Rectification by Registrar'?dataToLoop.map(({id,share, partyRole, name,address,transactionId},i)=>
                                                <tr key={i} >
                                                    <td ><span  className={'fa fa-trash'}
                                                                style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                                        .then(d=>{
                                                            console.log("the occupiers ",d.data)
                                                            const{result,status} = d.data
                                                            if(status === 1){
                                                                console.log("the result of occupiers111111 ",result)
                                                                dataToLoopSet(result)
                                                                refreshSet(result)
                                                            }

                                                        })}}></span></td>
                                                    <td>{share}</td>
                                                    <td>{partyRole}</td>
                                                    <td>{name}</td>
                                                    <td>{address}</td>

                                                </tr>
                                            )
                                            :TransactionPopup.transaction().name==='Register Unit Title'?dataToLoop.map(({id,share, partyRole, name,address,transactionId},i)=>
                                                    <tr key={i} >
                                                        <td ><span  className={'fa fa-trash'}
                                                                    style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.post("land/remove-rrr",{id,transactionId})
                                                            .then(d=>{
                                                                console.log("the unit owner ",d.data)
                                                                const{result,status} = d.data
                                                                if(status === 1){
                                                                    console.log("the result of occupiers111111 ",result)
                                                                    dataToLoopSet(result)
                                                                    refreshSet(result)
                                                                }

                                                            })}}></span></td>
                                                        <td>{share}</td>
                                                        <td>{partyRole}</td>
                                                        <td>{name}</td>
                                                        <td>{address}</td>

                                                    </tr>
                                                )
                                        :''
                }
                </tbody>
            </table>
        </div>
    )
}
const Headers = ({heading, display,interestId,tenancyType,transactionId,dataResp,shares}) =>{
//new function,
    return(
        <Header display={display} label={heading}>
            <div>
                <i
                    className={'fa fa-plus-circle'}
                    style={{color:'tomato', fontSize:'25px'}}
                    onClick={interestId?()=>{PopDetail.show("New",<Rrr interestId={interestId} tenancyType={tenancyType} transactionId={transactionId} dataResp={dataResp}/>)}:()=>{}}
                /><strong> New</strong>
            </div>
            <div>
                <i
                    className={'fa fa-edit'}
                    style={{color:'tomato', fontSize:'25px'}}
                    onClick={()=>{}}
                /><strong> Edit</strong>
            </div>
        </Header>
    )

}

export const Occupier =({ interestId, tenancyType})=>{
    let transactionId = TransactionPopup.id()
    const[refresh,refreshSet]=useState([])
    const dataResp = data =>{
        refreshSet(data)
    }

    return(
        <div  style={{...styles.content, height:'100%'}}>
            <Headers heading={"Occupier"} display={true}  interestId={interestId} tenancyType={tenancyType} transactionId={transactionId} dataResp={dataResp}/>
            <Table transactionId={transactionId} refreshh={refresh}/>
        </div>
    )
}
export const Grantor =()=>{
    const selected = (data) =>{
        console.log("Data",data)
    }
    return(
        <div  style={{...styles.content, height:'100%'}}>
            <Headers heading={"Grantor"} display={false}/>
            <div style={{...styles.content, height:'150px'}}>
                <table className={'tb'} style={styles.table}>
                    <thead>
                    <tr>
                        <th>SHARE</th>
                        <th>PARTY ROLE</th>
                        <th>NAME</th>
                        <th>ADDRESS</th>
                    </tr>
                    </thead>
                    <tbody/>
                </table>
            </div>
        </div>
    )
}

export const Transferor =({ interestId, tenancyType})=>{
    let transactionId = TransactionPopup.id()
    let compName='Transferor'
    const[refresh,refreshSet]=useState([])
    const dataResp = data =>{
        refreshSet(data)
    }

    return(
        <div  style={{...styles.content, height:'100%'}}>
            <Headers heading={"Transferor"} display={true}  interestId={interestId} tenancyType={tenancyType} transactionId={transactionId} dataResp={dataResp}/>
            <Table compName={compName} transactionId={transactionId} refreshh={refresh}/>
        </div>
    )


}

export const Transferee =({ interestId, tenancyType, partyRoles})=>{
    let transactionId = TransactionPopup.id()
    let compName='Transferee'
    const[refresh,refreshSet]=useState([])
    const dataResp = data =>{
        refreshSet(data)
    }
    console.log("the transferee result of part role ", partyRoles)

    return(
        <div  style={{...styles.content, height:'100%'}}>
            <Headers heading={"Transferee"} display={true}  interestId={interestId} tenancyType={tenancyType} transactionId={transactionId} dataResp={dataResp}/>
            <Table compName={compName} transactionId={transactionId} refreshh={refresh}/>
        </div>
    )
}


export const Mortgagor=({ interestId, tenancyType})=>{
    let transactionId = TransactionPopup.id()
    let compName='Mortgagor'
    const[refresh,refreshSet]=useState([])
    const dataResp = data =>{
        refreshSet(data)
    }
    return(
        <div  style={{...styles.content, height:'100%'}}>
            <Headers heading={"Mortgagor"} display={true}  interestId={interestId} tenancyType={tenancyType} transactionId={transactionId} dataResp={dataResp}/>
            <Table compName={compName} transactionId={transactionId} refreshh={refresh}/>
        </div>
    )
}

export const Mortgagee=({ interestId, tenancyType})=>{
    let transactionId = TransactionPopup.id()
    let compName='Mortgagee'
    const[refresh,refreshSet]=useState([])
    const dataResp = data =>{
        refreshSet(data)
    }

    return(
        <div  style={{...styles.content, height:'100%'}}>
            <Headers heading={"Mortgagee"} display={true}  interestId={interestId} tenancyType={tenancyType} transactionId={transactionId} dataResp={dataResp}/>
            <Table compName={compName} transactionId={transactionId} refreshh={refresh}/>
        </div>
    )
}

export const LegalRepresentative=({ interestId, tenancyType})=>{
    let transactionId = TransactionPopup.id()
    let compName='Mortgagee'
    const[refresh,refreshSet]=useState([])
    const dataResp = data =>{
        refreshSet(data)
    }

    return(
        <div  style={{...styles.content, height:'100%'}}>
            <Headers heading={"Legal Representative"} display={true}  interestId={interestId} tenancyType={tenancyType} transactionId={transactionId} dataResp={dataResp}/>
            <Table compName={compName} transactionId={transactionId} refreshh={refresh}/>
        </div>
    )
}

export const UnitOwner =({ interestId, tenancyType})=>{
    let transactionId = TransactionPopup.id()
    let compName='Unit Owner'
    const[refresh,refreshSet]=useState([])
    const dataResp = data =>{
        refreshSet(data)
    }

    return(
        <div  style={{...styles.content, height:'100%'}}>
            <Headers heading={"Unit Owner"} display={true}  interestId={interestId} tenancyType={tenancyType} transactionId={transactionId} dataResp={dataResp}/>
            <Table compName={compName} transactionId={transactionId} refreshh={refresh}/>
        </div>
    )
}

const localStyle ={
    container:{
        display:"flex",
        flexDirection:"column",
        padding:".2rem"
    },
    row:{
        display: "flex",
        flexDirection: "row"
    }
}
