import React, {useEffect, useState} from 'react'
import Header from "../popups/transacrions/screen/gro/header";
import PopDetail from "../popups/popdetail";
import {styles} from "../popups/transacrions/screen/record-request";
import API from "../../services/API";
import {useForm} from "react-hook-form";
import Message from "../popups/transacrions/screen/gro/person/message";
import Wait from "../popups/wait";

const Locate = ({isNew = true, data=null, dataBack, index =-1})=>{
    const{register, handleSubmit}=useForm()
    const[id,idSet] = useState('')
    const submit = datas =>{
        Wait.show(index ===-1?"Saving...":"Update...")
            API.ax.post("/rts/new-location",datas)
                .then(d=>{
                    const{result,status, message} = d.data
                    if(status === 1){
                        dataBack(index === -1?result:{...data,...result},index)
                        PopDetail.dismiss()
                    }else{
                        PopDetail.dismiss()
                        PopDetail.show("Error",<Message faIcon={"hazard"} message={message}/>,40)
                    }
                })
                .catch(e=>console.error(e))
        Wait.dismiss()
    }
    useEffect(()=>{
        if(isNew){
            API.ax.get("/rts/box-id")
                .then(d=>{
                    const{result} = d.data
                    idSet(result)
                })
        }
    },[isNew])
    return(
        <form onSubmit={handleSubmit(submit)}>
            <table style={styles.table}>
                <tbody>
                <tr>
                    <td>ID</td>
                </tr>
                <tr>
                    <td><input type={"text"} name={"boxId"} style={styles.table} readOnly value={data?data.boxId:id} onChange={e=>{console.log(e.target.value)}} ref={register({required:true})}/></td>
                </tr>
                <tr>
                    <td>Name</td>
                </tr>
                <tr>
                    <td><input type={"text"} name={"boxNo"} defaultValue={data?data.boxNo:''} style={styles.table} ref={register({required:true})}/></td>
                </tr>
                <tr>
                    <td>Description</td>
                </tr>
                <tr>
                    <td><textarea  rows={5} style={{...styles.table,...styles.txArea}} name={"description"} defaultValue={data?data.description:''} ref={register({required:true})} /></td>
                </tr>
                <tr><td><button>Save</button></td></tr>
                </tbody>
            </table>
        </form>
    )
}
export default function Location() {
    const[locations,locationsSet] = useState([])
    const[index, indexSet] = useState(-1)
    const[location, locationSet] = useState({})
    useEffect(()=>{
        if(index >-1){
        locationSet(locations[index])
        }
    },[index])
    const dataBack =(data, indexe=-1)=>{
        if(indexe === -1){
            let toData =[...locations]
            toData.push(data)
            locationsSet(toData)
        }else{
            let toData = [...locations]
            toData[indexe] = data
            locationsSet(toData)
        }
    }
    useEffect(()=>{
        API.ax.get("/rts/new-location")
            .then(d=>{
                const{result, status} = d.data
                if(status === 1){
                    locationsSet(result)
                }
            })
    },[])
    return(<div style={{...styles.main, height:'100%'}}>
            <Header label={"Locations"} display={true}>
            <div>
                <i
                    className={'fa fa-plus-circle'}
                    style={{color:'tomato', fontSize:'25px'}}
                    onClick={()=>{PopDetail.show("New Location",<Locate dataBack={dataBack} />,50)}}
                /><strong> New</strong>
            </div>
            <div>
                <i
                    className={'fa fa-edit'}
                    style={{color:'tomato', fontSize:'25px'}}
                    onClick={index>-1?()=>{PopDetail.show("Edit Location",<Locate data={location} isNew={false} dataBack={dataBack} index={index}/>,50)}:()=>{}}
                /><strong> Edit</strong>
            </div>
        </Header>
            <div style={{...styles.content,height:'70vh'}}>
                <table className={"tb"} style={styles.table}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>DESCRIPTION</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      locations.map(({boxId, boxNo, description},i)=>
                        <tr key={i} onClick={()=>indexSet(i)} style={i === index?{background:"#FCEBE9"}:{}}>
                            <td>{boxId}</td>
                            <td>{boxNo}</td>
                            <td>{description}</td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}