import * as L from "leaflet";

export default class Basemap{
    static grayScale(){
        let mbAttr = '', mbUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';
        return L.tileLayer(mbUrl, {id: 'mapbox/light-v9', tileSize: 512, zoomOffset: -1, attribution: mbAttr});
    }
    static googleStreets(){
        return  L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
            maxZoom: 1000,
            subdomains:['mt0','mt1','mt2','mt3']
        });
    }
    static googleHybrid(){
        return  L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                maxZoom: 1000,
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        });
    }
    static googleSat(){
        return  L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
            maxZoom: 1000,
            subdomains:['mt0','mt1','mt2','mt3']
        });
    }
    static googleTerrain(){
        return  L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',{
            maxZoom: 1000,
            subdomains:['mt0','mt1','mt2','mt3']
        });
    }

    static backWhite(){
        return L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}', {
            attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            subdomains: 'abcd',
            maxZoom: 1000,
            ext: 'png'
        });
    }

}
