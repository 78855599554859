import React, {Component} from 'react';
import API from "../../../../services/API";
import TransactionPopup from "../transaction_popup";
import Wait from "../../wait";

class Status extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plotFarm: "",
            size: "",
            history: "",
            tpReference: "",
            gn_no: "",
            landUse: "",
            investment: "",
            shareholderCitizenship: "",
            workPlan: "",
            investmentValue: "",
            benefitsFromInvestment: "",
            committeeViews: "",
            refNumber: "",
            status: ""

        }
        this.reportUpdate = this.reportUpdate.bind(this)
        this.handleChange =this.handleChange.bind(this)

    }

    handleChange= e =>{
        console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value});
    }


    reportUpdate = () =>  {
        let dataToSend = {
            size: this.state.size,
            history: this.state.history,
            tpReference: this.state.tpReference,
            landUse: this.state.landUse,
            investmentValue: this.state.investmentValue,
            committeeViews: this.state.committeeViews,
            status: this.state.status,
            refNumber: this.state.refNumber,
            plotFarm: this.state.plotFarm
        }
        Wait.show()
        API.ax.post("/tic/update-matrix-report/",dataToSend).then(d => {
            console.log("update matrix report data" , d.data)
            Wait.dismiss()
        }).catch(e => {
            console.log("update matrix report error" , e)
            Wait.dismiss()
        })
    }
    componentDidMount() {
        API.ax.get("/tic/get-matrix-report/"+TransactionPopup.id()).then(
            d => {
                console.log("matrix Report result" , d)
                this.setState({
                    plotFarm: d.data.result.plotFarm,
                    size: d.data.result.size,
                    history: d.data.result.history,
                    tpReference: d.data.result.tpReference,
                    gn_no: d.data.result.gnNo,
                    landUse: d.data.result.landUse,
                    investment: d.data.result.investment,
                    shareholderCitizenship: d.data.result.shareholderCitizenship,
                    workPlan: d.data.result.workPlan,
                    investmentValue: d.data.result.investmentValue,
                    benefitsFromInvestment: d.data.result.benefitsFromInvestment,
                    committeeViews: d.data.result.committeeViews,
                    refNumber: d.data.result.refNumber,
                    status: d.data.result.status
                })

            }
        ).catch(e => {
            console.log("matrix report error" , e)
        })
    }

    render() {
        return (
            <div>
                <div>
                    <div style={{height:'40px',lineHeight:'40px', padding: '4px'}}>
                        <h3><b>MEETING DETAILS</b></h3>
                    </div>
                    <section style={{background:'#fff',height:'100%',paddingRight:'10px',paddingLeft:'20px',paddingTop:'5px',border:'0.5px solid #efefef'}}>
                        <div className={"row"}>
                            <div className={"col-sm-6"}>
                                <div>
                                    <label>Farm Plot
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.plotFarm} name={"plotFarm"} onChange={(e) => this.handleChange(e)} readOnly={false}/>
                                    </label>
                                </div>
                               <div>
                                   <label>Size
                                       <input type={'text'} style={{width:'100%'}} defaultValue={this.state.size} name={"size"} onChange={(e) => this.handleChange(e)} readOnly={false}/>
                                   </label>
                               </div>
                                <div>
                                    <label>History
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.history} name={"history"} onChange={(e) => this.handleChange(e)} readOnly={false}/>
                                    </label>
                                </div>
                               <div>
                                   <label>Town Planning Reference
                                       <input type={'text'} style={{width:'100%'}} defaultValue={this.state.tpReference} name={"tpReference"} onChange={(e) => this.handleChange(e)} readOnly={false}/>
                                   </label>
                               </div>
                                <div>
                                    <label>GN Number
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.gn_no} readOnly={true}/>
                                    </label>
                                </div>
                                <div>
                                    <label>Land Use
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.landUse} name={"landUse"} onChange={(e) => this.handleChange(e)} readOnly={false}/>
                                    </label>
                                </div>
                                <div>
                                    <label>Investment
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.investment} readOnly={true}/>
                                    </label>
                                </div>
                            </div>
                            <div className={"col-sm-6"}>
                               <div>
                                   <label>Share Holder CitizenShip
                                       <input type={'text'} style={{width:'100%'}} defaultValue={this.state.shareholderCitizenship} readOnly={true}/>
                                   </label>
                               </div>
                                <div>
                                    <label>Investment Value
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.investmentValue} name={"investmentValue"} onChange={(e) => this.handleChange(e)} readOnly={false}/>
                                    </label>
                                </div>
                                <div>
                                    <label>Benefits From Investment
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.benefitsFromInvestment} readOnly={true}/>
                                    </label>
                                </div>
                                <div>
                                    <label>Committee Views
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.committeeViews} name={"committeeViews"} onChange={(e) => this.handleChange(e)} readOnly={false}/>
                                    </label>
                                </div>
                                <div>
                                    <label>Reference Number
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.refNumber} readOnly={true}/>
                                    </label>
                                </div>
                                <div>
                                    <label>Status
                                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.status} name={"committeeViews"} onChange={(e) => this.handleChange(e)} readOnly={false}/>
                                    </label>
                                </div>
                              </div>
                            <div style={{marginTop: "8px"}}>
                                <span style={{marginLeft: "12px",float: "right", padding:'8px',color:'#fff',fontWeight:'bold',cursor:'pointer',marginRight:'4px',background:'#DF4A36'}} onClick={() => this.reportUpdate()} >Update</span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Status;