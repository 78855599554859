import React, {Component} from 'react';
import Wait from "../../../wait";
import ReportCreateService from "../../../../../services/common/reports/report_create";
import PopDetail from "../../../popdetail";

class ReportParameters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            department: '',
            fromDate: null,
            toDate: null,
            transactionName: '',
            reportName: '',
            doc_frame: <div/>
        }

        this.handleChange = this.handleChange.bind(this)
        this.generate = this.generate.bind(this)
    }
    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}
    generate() {
        try {
            Wait.show('Generating...')
            let reportDto={
                fromDate: this.state.fromDate,
                toDate: this.state.toDate,
                sort: this.state.sort,
                transactionName: this.props.transactionName,
                department: this.props.department,
                reportName:this.props.reportName
            }
            console.log("Report DTO", reportDto)
            ReportCreateService.getGenericReport(reportDto).then(r => {

                Wait.dismiss()
                //PopDetail.dismiss()
if(r.data.status==1) {
    this.setState({
        doc_frame: <div style={{height: '40vh'}}>
            <embed style={{height: '100%', width: '100%'}} src={"data:application/pdf;base64, " + r.data.result}/>
        </div>
    })
}
            })
        } catch (e) {

        }
    }

    render() {
        return (
            <div className={'col col-sm-12'}>

                <table style={{width:'100%'}}>
                    <tbody>
                    <tr style={{color:'darkcyan'}}>
                        <td>* Submitted Date From</td>
                        <td>* Submitted Date To</td>
                    </tr>
                    <tr>
                        <td>
                            <input type={'date'} style={{width:'100%'}} name={'fromDate'} onChange={this.handleChange} />
                        </td>
                        <td>
                            <input type={'date'} style={{width:'100%'}} name={'toDate'} onChange={this.handleChange}/>
                        </td>
                    </tr>
                    <tr style={{color:'darkcyan'}}>
                        <td>Office</td>
                        <td>Transactions</td>
                    </tr>
                    <tr>
                        <td>
                            <select  style={{width:'100%'}} name={'officeId'} onChange={this.handleChange}>
                                <option value={0}></option>
                                <option value={0}>DODOMA CITY COUNCIL</option>
                            </select>
                        </td>
                        <td>
                            <select  style={{width:'100%'}} name={'transaction'} onChange={this.handleChange}>
                                <option></option>
                                <option>All Transactions</option>
                            </select>
                        </td>
                    </tr>


                    </tbody>
                </table>
                <hr/>
                <div className={{textAlign:'right',marginBottom:'18px'}}>
                    <button onClick={this.generate}>Generate</button>
                    <button className={'default'} onClick={PopDetail.dismiss}>Cancel</button>
                </div>

                {this.state.doc_frame}
            </div>
        );
    }
}

export default ReportParameters;
