import * as React from "react";
import UgSpatial from "../../../maps/ug_spatial";
import SMDReadService from "../../../../services/smd/smd_read";
import TransactionPopup from "../transaction_popup";
import Wait from "../../wait";
import check from "../../../../../resource/img/checkbox/checkbox_checked_16.png";
import PopDetail from "../../popdetail";
import SMDCreateService from "../../../../services/smd/smd_create";
import PaneShowSurveyInstruction from "../pops/pane_show_survey_instruction";
import * as LF from "leaflet";
import Basemap from "../../../maps/basemap";
import L from "leaflet";
import API from "../../../../services/API";

export  default class InstructionToSurvey extends UgSpatial {
    static self = null

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            instructionNo:'',
            instructions: {},
            map_layers: [
                {
                    name: 'Regions Boundaries',
                    layer: null
                },
                {
                    name: 'Districts Boundaries',
                    layer: null
                },
                {
                    name: 'Rough Coordinates',
                    layer: null
                },
                {
                    name: 'TP',
                    layer: null
                }
            ]
        }
        InstructionToSurvey.self = this

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {


        InstructionToSurvey.loadData()

    }


  static loadData() {
        try {
            // Wait.show('Loading rough coordinate.....')
            Wait.show('Loading Instruction...')
            SMDReadService.getRoughCoordinateByTransactionId(TransactionPopup.id()).then(d => {
                try {
                    Wait.dismiss()

                    console.log(d)


                    InstructionToSurvey.self.setState({
                        instructions: d.data.result
                    })
                } catch (e) {
                    console.log(e)
                }
                let ly_group = new LF.FeatureGroup();
                let sat = Basemap.googleSat(),
                    hybrid = Basemap.googleHybrid(),
                    blank = LF.tileLayer('')

                let map = LF.map('showmap', {
                    layers: [blank, ly_group],
                    attributionControl: false,
                    fullscreenControl: true,
                    maxZoom:10000
                }).setView([-6.085936, 35.711995], 4)

                let baseLayers = {
                    "<span style='color: gray'>Blank</span>": blank,
                    "Satellite": sat,
                    "Hybrid": hybrid
                }

               /* this.setState({
                    map:map,
                    ly_group:ly_group,
                })*/

                let filter = 'transaction_id='+d.data.result.transactionId
                let layername = 'survey_instruction'
                let selected
                let ly = L.tileLayer.wms(API.geo_ilmis+'/wms', {
                    layers: 'tp_spatial_unit',
                    format: 'image/png',
                    transparent: true,
                    //cql_filter:'transaction_id in('+TransactionPopup.id()+')'
                }).addTo(ly_group)


                API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=> {
                    Wait.dismiss()
                    try {
                        r.data.features.forEach(p => {

                            let myLayer = LF.geoJSON(p, {
                                onEachFeature: (f, l) => {
                                    LF.marker(l.getBounds().getCenter(), {
                                        icon: LF.divIcon({
                                            className: 'bindTooltipBig',
                                            html: "TP Drwaing No. ["+ f.properties.drawing_number+'] Surveyor Type: '+f.properties.surveyor_type ,
                                            iconSize: [100, 40]
                                        })
                                    }).addTo(ly_group)
                                    l.setStyle({
                                        color: f.properties.status === 'Approved' ? '#00ff00' : '#901'
                                    })
                                },
                                coordsToLatLng: function (coords) {
                                    return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();
                                },
                                weight: 3, zIndex: 2, fillOpacity: 0
                            }).on('dblclick', e => {

                                /* ValBlockSpatialUnitPane.self.openRatesAt({
                                     id:ValBlockSpatialUnitPane.self.state.id,
                                     blockId:ValBlockSpatialUnitPane.self.state.vblock_id,
                                     status:ValBlockSpatialUnitPane.self.state.status,
                                 })*/

                            }).on('click', function (e) {

                                if (selected) {
                                    e.target.resetStyle(selected)
                                }
                                selected = e.layer

                                /*ValBlockSpatialUnitPane.self.setState({
                                    region:p.properties.region,
                                    district:p.properties.district,
                                    council:p.properties.council,
                                    locality:p.properties.locality,
                                    status:p.properties.status,
                                    vblock_id:p.properties.vblock_id,
                                    id:p.id.split('.')[1],
                                    blockId:p.properties.blockId
                                })*/
                                selected.bringToFront()
                                selected.setStyle({color: '#000', weight: 4})
                                map.fitBounds(selected.getBounds())

                            })
                            myLayer.addTo(ly_group)
                        })

                        let myLayer = LF.geoJSON(r.data, {
                            coordsToLatLng: function (coords) {
                                return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();
                            }
                        })
                        try {
                            map.fitBounds(myLayer.getBounds())
                        } catch (e) {
                        }

                    } catch (e) {
                        console.log(e)
                    }

                })

            })

        } catch (e) {
            console.log(e)
        }
    }

    handleChange = (e) => {
        e.preventDefault();
       this.setState({instructionNo:e.target.value})
    };



    noHandler(){ PopDetail.dismiss() }

    render() {
        return(<div>

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'16px',overflow:'auto',height:'100%',border:'0.5px solid #efefef'}}>

                        <b>Registered Survey Instruction</b>

                        <input style={{width:'100%',margin:'8px'}} placeholder={'Instruction No...'} onChange={this.handleChange} /><button onClick={this.getInstruction} >Save</button>
                        <table className={'tb'} style={{width:'100%'}}>
                            <thead>
                            <tr>
                                <th>Instruction Number</th>
                                <th>Survey Type</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{color:'#DF4A36'}}>
                                        {this.state.instructions.instructionNumber}
                                    </td>
                                    <td>{this.state.instructions.type}</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>

                    <div className={'row'} style={{background:'#fff',height:'100%',padding:'4px',border:'0.5px solid #efefef'}}>
                        <div className={'col col-sm-12'} style={{height:'40px',lineHeight:'40px'}}>
                            <b>Map</b>

                            <div style={{float:'right'}}>
                                <span  style={{marginLeft:'16px'}} onClick={()=>InstructionToSurvey.loadData()}>
                                    <span className={'fa fa-refresh screen-action'}/>Refresh
                                </span>

                            </div>
                        </div>

                        <div className={'col col-sm-12'} style={{height:'92%',border:'1px solid #ccc',padding:0}}>

                            <div className={'col col-sm-10'} style={{background:'#efefef',padding:0,float:'left',height:'100%'}} id={'showmap'}>

                            </div>

                            <div className={'col col-sm-2'} style={{borderLeft:'1px solid #ccc',float:'left',height:'100%',padding:0}}>
                                <div style={{padding:'8px',fontSize:'12px',borderBottom:'1px solid #ccc',background:'linear-gradient(#ccc,#fff)'}}>
                                    <b><span className={'fa fa-map'}/> Map Layers</b>
                                </div>
                                <div style={{fontSize:'12px',padding:'8px'}}>
                                    {this.state.map_layers.map((d,i)=>{
                                        return(<div key={i}>
                                            <img alt={i} src={check} style={{marginRight:'4px'}}/> <b>{d.name}</b>
                                        </div>)
                                    })}
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>

            <div className={'row'} >

                <div className={'col col-sm-12'} style={{paddingRight:'4px',height:'27vh'}}>
                    <div style={{background:'#fff',padding:'4px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <table style={{width:'100%'}} className={'tb'}>
                            <tbody>
                            <tr>
                                <td>Search</td>
                                <td><input style={{width:'100%'}}/></td>
                                {/*<td>Applicant Role</td>
                                <td><input style={{width:'100%'}}/></td>*/}
                            </tr>
                            </tbody>
                        </table>

                        <div style={{borderTop:'1px solid #ccc',float:'left',width:'100%'}}>

                            <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                                <thead>
                                <tr>
                                    <th>Survey Type</th>
                                    <th>Instruction Number</th>
                                    <th>Lease</th>
                                    <th>Approve Date</th>
                                    <th>Council</th>
                                    <th>Locality</th>
                                </tr>

                                </thead>
                                <tbody>

                                    <tr >
                                        <td>{this.state.instructions.type}</td>
                                        <td>{this.state.instructions.instructionNumber}</td>
                                        <td>{this.state.instructions.lease}</td>
                                        <td>{/*{d.approve_date}*/}</td>
                                        <td>{this.state.instructions.council}</td>
                                        <td>{this.state.instructions.locality}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div>

        </div>)
    }

    getInstruction() {

        Wait.show("Instruction loading......")
        SMDReadService.getInstructionByNumber(this.state.instructionNo).then(d=>{

            if(d.data.status==1){
                Wait.dismiss()
                console.log("instruction Data==",d.data.result)
                PopDetail.show("SURVEY INSTRUCTION",<PaneShowSurveyInstruction instruct={d.data.result}/>,90)

            }else {


            }

        })

    }
}