import API from "../API";

export default class ValReadService{
    static listBlocks(txId){
        return API.ax.get(`valuation/list/blocks/${txId}`).catch(e=>{console.log(e);})
    }

    static getValuationRates(txId) {
        return API.ax.get(`valuation/get-valuation-blocks-rates/${txId}`)
    }
    static getPVUApproval(txId){
        return API.ax.get("valuation/pvu-approval/"+txId).catch(e=>{console.log(e);})
    }
}
