import * as React from "react";
import TransactionPopup from "../../transaction_popup";
import PaneUploadSpatialUnitSurverRequest from "../add/add_rough_coordinate";
import Wait from "../../../wait";
import TxReadService from "../../../../../services/transaction/transaction_read";
import UgSpatial from "../../../../maps/ug_spatial";
import * as LF from "leaflet";
import check from "../../../../../../resource/img/checkbox/checkbox_checked_16.png";
import SMDReadService from "../../../../../services/smd/smd_read";
import TxCreateService from "../../../../../services/transaction/transaction_create";
import Properties from "../properties";
import PaneSearchProperty from "../../pops/pane_search_property";
import PopDetail from "../../../popdetail";
import ComputedRent from "../../pops/computeRent";
import PaneSearchPropertyRent from "../../pops/pane_search_property_rent";

export default class PropertyPane extends React.Component{
    static self = null
    constructor(props) {
        super(props);

        this.state= {
            property: {},
            rent: [],
            showMenu: false,
            gepgMenu: false,
            base64: '',
            tp_drawings: [],
            dataToDisplay:[],
            propertyPushedArray:[],
            /*tenure:'',
            districtName:'',
            regionName:'',
            regPlanNumber:'',
            titleNumber:'',
            titleStatus:'Active',
            localityName:'',
            blockNumber:'',
            lotNumber:'',
            ldNumber:'',
            loNumber:'',
            rightHolder:'',
            registerFrom:'',
            registerTo:'',
            planNumber:'',
            allocationStatusName:'',
            landUseName:'',
            drawingNumber:'',
            legalArea:null,
            areaUnitName:'',
            spatialUnitId:null,
            baunitId:null,*/
            map_layers:[
                {
                    name:'Regions Boundaries',
                    layer:null
                },
                {
                    name:'Districts Boundaries',
                    layer:null
                },
                {
                    name:'Valuation Blocks',
                    layer:null
                },
                {
                    name:'TP',
                    layer:null
                },
                {
                    name:'Parcel',
                    layer:null
                }
            ]
        }
        PropertyPane.self = this

       // this.getProperties = this.getProperties.bind(this)
       // this.pushProperty = this.pushProperty.bind(this)

    }
    componentDidMount() {
        TxReadService.readComputedRent(TransactionPopup.id()).then(r=>{
            console.log("Rent Returned:",r)
            if(r.data.status==1){
                this.setState({rent: r.data.result})
            }else {

            }

        })
TxReadService.readPropertyTitle(TransactionPopup.id()).then(d=>{
    console.log("Rent Property Title:",d)
    if(d.data.status==1){
        this.setState({
            property: d.data.result
        })
    }else{

    }

})
      //  PropertyPane.loadData()
    }

   /* handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }*/


   /* pushProperty(d) {
        //this.state.propertyPushedArray.push(d)
        /!*Properties.self.setState({
            landProperties: this.state.propertyPushedArray
        })*!/

       // PopDetail.show('Properties',"aaa")

    }*/

/*    static loadData(){

        UgSpatial.clear()
        Wait.show("Loading Data...")

        try {
            SMDReadService.read(0).then(r=>{
                Wait.dismiss()
                try {
                    PropertyPane.self.setState({data:r.data.result})

                    Wait.show("Loading Map...")

                    UgSpatial.loadLayer(PropertyPane.self,'spatial_unit','geo_server_tx_id in ('+0+')',false,false).then(_ => {
                        Wait.dismiss()
                    })
                }catch (e){

                }
            })
        }catch (e){
            console.log(e)
        }


    }*/



    popAddSpatial(tag){
        let trans_name = TransactionPopup.self.state.title

        switch (tag) {
            case 'add':
                break
            case 'upload':
                switch (trans_name) {
                    case 'Survey Request':
                        PopDetail.show('Upload Rough Coordinates',<PaneUploadSpatialUnitSurverRequest/>)
                        break
                    default:
                        break
                }
                break
            default:
                break
        }


        //
        //PopDetail.show('New Spatial Unit',pan)
    }

    render() {
        return(<div >

            <div className={'row'} >

                <div className={'col col-sm-12'} style={{height:'25vh'}}>
                    <div style={{background:'#fff',padding:'10px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <div><span>Property/Titles</span>
                            <span>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-trash screen-action'}
                                          onClick={()=>PopDetail.show('Remove Property',<></>)} />Remove
                                </span>
                            <span style={{marginLeft:'16px',cursor:'pointer'}}  onClick={()=>PopDetail.show('Search Properties',<PaneSearchPropertyRent/>)}>
                                    <span className={'fa fa-search screen-action'}
                                    />Search
                                </span>
                        </div>
                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>

                            <tr>
                                <th>TITLE NUMBER</th>
                                <th>TYPE</th>
                                <th>TENURE</th>
                                <th>LD NUMBER</th>
                                <th>TOTAL AREA</th>
                                <th>DISTRICT</th>
                                <th>LOCATION</th>
                                <th>BLOCK</th>
                                <th>LOT NUMBER</th>
                                <th>USE</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>{/*{console.log(this.props.children.annualRent1)}*/}
                                <td>{this.state.property.titleNumber}</td>
                                <td>{this.state.property.baunittType}</td>
                                <td>{this.state.property.tenureTypeName}</td>
                                <td>{this.state.property.ldNumber}</td>
                                <td>{this.state.property.legalArea}</td>
                                <td>{this.state.property.districtName}</td>
                                <td>{this.state.property.localityName}</td>
                                <td>{this.state.property.blockNumber}</td>
                                <td>{this.state.property.lotNumber}</td>
                                <td>{this.state.property.landUseName}</td>
                                <td>
                                    <span>
                                        <span style={{marginLeft:'16px'}} className={'fa fa-check screen-action'}
                                              onClick={()=>this.calculateRent()} />Compute`
                                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className={'col col-sm-12'} style={{height:'50vh'}}>
                    <div style={{background:'#fff',padding:'10px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <div><span>Rent to pay</span>

                        </div>
                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>
                            <tr>
                                <th>FINANCIAL YEAR</th>
                                <th>LAND RENT RATE</th>
                                <th>LAND COMPUTATION TYPE</th>
                                <th>TOTAL AREA</th>
                                <th>LAND RENT</th>
                                <th>ARREARS</th>
                                <th>AMOUNT DUE</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.rent.map((d, i) => {
                                return(
                                    <tr key={i}>
                                        <td>{d.rentFinancialYear}</td>
                                        <td>{d.rentRate}</td>
                                        <td>{d.landrentcomputationtype}</td>
                                        <td>{d.legalArea}</td>
                                        <td>{d.landRent}</td>
                                        <td>{d.rentArrears}</td>
                                        <td>{d.amountDue}</td>
                                    </tr>)})
                            }

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>)
    }



   /* getProperties(){
        try {
            Wait.show("Loading Property...")
            let data={
                titleNumber:this.state.titleNumber,
                titleStatus:'Active',
                regionName:this.state.regionName,
                districtName:this.state.districtName,
                localityName:this.state.localityName,
                blockNumber:this.state.blockNumber,
                lotNumber:this.state.lotNumber,
                regPlanNumber:this.state.regPlanNumber,
                loNumber:this.state.loNumber,
                ldNumber:this.state.ldNumber,
                rightHolder:this.state.rightHolder,
                registerFrom:this.state.registerFrom,
                registerTo:this.state.registerTo,
                planNumber:this.state.planNumber,
                allocationStatusName:this.state.allocationStatusName,
                landUseName:this.state.landUseName,
                drawingNumber:this.state.drawingNumber,
                legalArea:this.state.legalArea,
                areaUnitName:this.state.areaUnitName,
                spatialUnitId:this.state.spatialUnitId,
                baunitId:this.state.baunitId,
                transactionId:TransactionPopup.id()

            }
            TxCreateService.createGetProperties(data).then(r => {
                Wait.dismiss()
                try {
                    console.log("PropertyLoaded",r)
                    /!*this.setState({
                        dataToDisplay: r.data.properties
                    })*!/

                    if(r.data.status===1) {

                        /!*
                                            if (r.data.properties) {*!/


                        PopDetail.show('Properties', <PaneSearchProperty dataToDisplay={r.data.result}/>)
                        /!*}else
                         {
                             PopDetail.show('Message',<div>No Data</div>)
                         }*!/
                    }else{
                       PopDetail.show(`${r.data.message}`)
                    }}catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
*/
    calculateRent() {
        try {
            Wait.show("Calculating Rent...")
            console.log(this.state.property.baUnitId+"BAUNIT ID"+TransactionPopup.id())
            TxReadService.calculateRent(this.state.property.baUnitId,TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    if (r.data.status === 1){
                        this.setState({
                            rent:r.data.landRent,
                            showMenu: true
                        })
                    console.log(r.data.landRent)
                   // PopDetail.show("Computed Rent", <ComputedRent rent={r.data.landRent}/>)
                }else {
                        PopDetail.show("Computed Rent Error:", <div><p>{r.data.message}</p></div>)
                    }

                }catch (e) {
                    console.log(e)}
            })
        }catch (e) {
            console.log(e)
        }
    }

    sendToGepg() {
        try {
            Wait.show("Submitting to GEPG ...")
            TxReadService.sendBillToGepg(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        rent:r.data.bill,
                       gepgMenu:true

                    })

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    createPayment() {
        try {
            Wait.show("Generate Bill ...")
            TxReadService.createBillPayment(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        rent:r.data.bill,
                        gepgMenu:true

                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    generateInvoice() {
        try {
            Wait.show("Generate Invoice ...")
            TxReadService.generateRentInvoice(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        base64:r.data.base64,

                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
}
