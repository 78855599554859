import React, {useState, useEffect} from 'react'
import Header from "./header";
import PopDetail from "../../../popdetail";
import {styles} from "../record-request";
import {
    Grantor,
    LegalRepresentative,
    Mortgagee,
    Mortgagor,
    Occupier,
    Transferee,
    Transferor,
    UnitOwner
} from "./source-target";
import {useForm} from "react-hook-form";
import TransactionPopup from "../../transaction_popup";
import API from "../../../../../services/API";
import TheRelatedInterest from "./theRelatedInterest";
import OccupancyEditInterest from "./occupancy-edit-interest";
import BaUnitTitle from "./baUnitTitle";
import DocumentsPane from "../documents";

const TheInterest =({txType, data, submit})=>{
    const{register,handleSubmit}=useForm()
    const[terms,termsSet] = useState(null);
    const[endsDate,endsDateSet] = useState("");
    const[commencementDate,commencementDateSet] = useState(null);
    const[currentMenu,currentMenuSet] = useState(0)
    const [today,todaySet]= useState("")

    const handleTerm=event=>termsSet(event.target.value)
    const handleCommencement=event=>commencementDateSet(event.target.value)

    useEffect(()=>{
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const todays = yyyy+'-'+mm + '-' + dd;
        todaySet(todays)
    },[])

    useEffect(()=>{
        if(terms !== null && terms !== "custom" && commencementDate !==null){
            let date = new Date(commencementDate);
            let year = date.getFullYear();
            //let month = date.getMonth();
            // let month = date.getMonth();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = date.getDate();
            let sum = year + parseInt(terms);
            let endDate = sum+"-"+month+"-"+day;
            endsDateSet(endDate)
        }
    },[terms,commencementDate])
     return(
        <div style={{padding:".2rem"}}>
            <form onSubmit={handleSubmit(submit)}>
            <table style={styles.table}>
                <tbody>
                 <tr>
                     <td>Type</td>
                     <td>Tenancy Type</td>
                     <td>FD Number</td>
                 </tr>
                 <tr>
                     <td><input type={"text"} style={styles.table} readOnly={true} defaultValue={txType} name={"rrrType"} ref={register({required:true})}/></td>
                     <td><select style={styles.table} name={"tenancyTypeName"} defaultValue={data?data.tenancyTypeName:''}  ref={register({required:true})} >
                         <option value={""}/>
                         <option value={"Single Occupancy"}>Single Occupancy</option>
                         <option value={"Joint Occupancy"}>Joint Occupancy</option>
                         <option value={"Occupancy in Common in Equal Share"}>Occupancy in Common in Equal Share</option>
                         <option value={"Occupancy in Common in Unequal Share"}>Occupancy in Common in Unequal Share</option>
                     </select>
                     </td>
                     <td><input type={"text"} style={styles.table} name={"fdNumber"}  ref={register}/></td>
                 </tr>
                 <tr>
                     <td>Term</td>
                     <td>Commencement Date</td>
                     <td>End Date</td>
                 </tr>
                <tr>
                    <td><select name={"tenureDurationName"} defaultValue={data?data.tenureDurationName:""} onChange={handleTerm} style={styles.table}  ref={register({required:true})}>
                        <option value={""}/>
                        <option value={"custom"}>Custom</option>
                        <option value={"5"}>Five(5) Years</option>
                        <option value={"99"}>Ninety Nine (99) Years</option>
                        <option value={"66"}>Sixty Six (66) Years</option>
                        <option value={"33"}>Thirty Three (33) Years</option>
                    </select></td>
                    <td>
                        <input type={"date"}   name={"startDate"} defaultValue={data?data.startDate:""} onChange={handleCommencement} style={styles.table}  ref={register({required:true})}/>
                    </td>
                    <td>
                        {
                            terms === null || terms !== "custom" ?<input type={"text"} value={endsDate} readOnly={true} name={"endDate"} style={styles.table}  ref={register({required:true})}/>
                                                                 : <input type={"date"} name={"endDate"} style={styles.table}  ref={register({required:true})}/>
                        }
                    </td>
                </tr>
                 <tr>
                     <td>Particulars</td>
                 </tr>
                <tr>
                    <td colSpan={3}><textarea cols={3} rows={3} style={{...styles.table,...styles.txArea}} name={"particulars"}  ref={register}/></td>
                </tr>
                <tr>
                    <td>Registration Date/Time</td>
                    <td>Deregistration Date</td>
                    <td>Consideration</td>
                </tr>
                <tr>
                    <td><input type={"text"} name={"registrationDateTime"} readOnly={true} defaultValue={data?data.registrationDateTime:''} style={styles.table}  ref={register}/></td>
                    <td><input type={"text"} name={"deregistrationDateTime"} readOnly={true} defaultValue={data?data.deregistrationDateTime:''} style={styles.table}  ref={register}/></td>
                    <td><input type={"number"} min={0} defaultValue={data?data.consideration:''} name={"consideration"} style={styles.table}  ref={register}/></td>
                </tr>
                </tbody>
            </table>
            <div className={"row"} style={styles.detailsHeader}>
                <div  style={currentMenu===  0?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(0)}}><label style={styles.headerLabels}>Properties/Title</label></div>
                <div  style={currentMenu === 1?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(1)}}><label style={styles.headerLabels}>Documents</label></div>
            </div>
            <div style={currentMenu === 0?styles.show:styles.hide}>
                <BaUnitTitle heading={false}/>
            </div>
            <div style={currentMenu === 1?styles.show:styles.hide}>
                <DocumentsPane/>
            </div>
             <button type={"submit"}>Save</button>
            </form>
        </div>
    )
}

export const  TheMortgageInterest =({txType, data, submit})=>{
    const{register,handleSubmit}=useForm()
    const[terms,termsSet] = useState(null);
    const[endsDate,endsDateSet] = useState("");
    const[commencementDate,commencementDateSet] = useState(null);
    const[unspecifiedAmount,unspecifiedAmountSet]= useState(false)
    const[amountSecond,amountSecondSet] = useState(0)
    const[amountSecondUSD,amountSecondUSDSet] = useState(0)
    const[readStatus,readStatusSet]=useState(false)
    const [shareNumerator,shareNumeratorSet] =useState(0)
    const [shareDenominator,shareDenominatorSet] =useState(0)
    const [] =useState(0)
    const[currentMenu,currentMenuSet] = useState(0)
    const [today,todaySet]= useState("")

    useEffect(()=>{
        if(unspecifiedAmount){
            amountSecondSet(0)
            amountSecondUSDSet(0)
            readStatusSet(true)

        }
    },[unspecifiedAmount])

    useEffect(()=>{
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const todays = yyyy+'-'+mm + '-' + dd;
        todaySet(todays)
    },[])

    useEffect(()=>{
        if(terms !== null && terms !== "custom" && commencementDate !==null){
            let date = new Date(commencementDate);
            let year = date.getFullYear();
            //let month = date.getMonth();
            // let month = date.getMonth();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = date.getDate();
            let sum = year + parseInt(terms);
            let endDate = sum+"-"+month+"-"+day;
            endsDateSet(endDate)
        }
    },[terms,commencementDate])
    return(
        <div style={{padding:".2rem"}}>
            <form onSubmit={handleSubmit(submit)}>
                <table style={styles.table}>
                    <tbody>
                    <tr>
                        <td>Type</td>
                        <td>FD Number</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><input type={"text"} style={styles.table} readOnly={true} defaultValue={txType} name={"rrrType"} ref={register({required:true})}/></td>
                        <td><input readOnly={true} type={"text"} style={styles.table} name={"fdNumber"}  ref={register}/></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Unspecified Amount</td>
                        <td>Secured Amount TSh</td>
                        <td>Secured Amount USD</td>
                    </tr>
                    <tr>
                        <td><input type={'checkbox'} name={'unspecifiedAmount'}  ref={register}/></td>
                        <td><input type={'number'} name={'amountSecond'} readOnly={readStatus}  ref={register}/></td>
                        <td><input type={'number'}name={'amountSecondUSD'} readOnly={readStatus}  ref={register}/></td>
                    </tr>

                    <tr>
                        <td>
                            <input hidden={true} type={"date"}   name={"startDate"}  style={styles.table} defaultValue={null} ref={register}/>
                        </td>
                        <td>
                           <input hidden={true} type={"date"}  name={"endDate"} style={styles.table} defaultValue={null} ref={register}/>
                        </td>
                        <td></td>
                    </tr>


                    <tr>
                        <td><input hidden={true} type={"date"} name={"registrationDateTime"} style={styles.table}  ref={register}/></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                <div className={"row"} style={styles.detailsHeader}>
                    <div  style={currentMenu === 0?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(0)}}><label style={styles.headerLabels}>Properties/Title</label></div>
                    <div  style={currentMenu === 1?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(1)}}><label style={styles.headerLabels}>Documents</label></div>
                </div>
                <div style={currentMenu === 0?styles.show:styles.hide}>
                    <BaUnitTitle heading={false}/>
                </div>
                <div style={currentMenu === 1?styles.show:styles.hide}>
                    <DocumentsPane/>
                </div>
                <button type={"submit"}>Save</button>
            </form>
        </div>
    )
}






const EditInterest =({txType,data,submit})=>{
    const [interest,interestSet]=useState(data)
    const{register,handleSubmit}=useForm()
    const[terms,termsSet] = useState(null);
    const[endsDate,endsDateSet] = useState("");
    const[commencementDate,commencementDateSet] = useState(null);
    const[currentMenu,currentMenuSet] = useState(0)
    const [today,todaySet]= useState("")

    const handleTerm=event=>termsSet(event.target.value)
    const handleCommencement=event=>commencementDateSet(event.target.value)

    useEffect(()=>{
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const todays = yyyy+'-'+mm + '-' + dd;
        todaySet(todays)
    },[])

    useEffect(()=>{
        if(terms !== null && terms !== "custom" && commencementDate !==null){
            let date = new Date(commencementDate);
            let year = date.getFullYear();
           // let month = date.getMonth();
            let month = String(today.getMonth() + 1).padStart(2, '0');
            console.log("END DATE TWO===>",month)
            let day = date.getDate();

            let sum = year + parseInt(terms);
            let endDate = sum+"-"+month+"-"+day;
            console.log("END ==>",endDate)
            endsDateSet(endDate)
        }
    },[terms,commencementDate])

    const handleEditedInterest=data=>{
        console.log("the form data",data)
        const dataSend ={...data,transactionId:TransactionPopup.id()}
        console.log(dataSend)
        API.ax.post("/land/interest",dataSend)
            .then(d=>{
                const{status, result} = d.data
                if(status === 1){
                    interestSet(result)
                    //addInterestSet(false)
                }
            })
            .catch(e=>{
                console.log(e)
            })
        PopDetail.dismiss()
    }
    return(
        <div style={{padding:".2rem"}}>
            <form onSubmit={handleSubmit(submit)}>
                <table style={styles.table}>
                    <tbody>
                    <tr>
                        <td>Type</td>
                        <td>Tenancy Type</td>
                        <td>FD Number</td>
                    </tr>
                    <tr>
                        <td><input type={"text"} style={styles.table} readOnly={true} defaultValue={txType} name={"rrrType"} ref={register({required:true})}/></td>
                        <td><select style={styles.table} name={"tenancyTypeName"} defaultValue={data?data.tenancyTypeName:''}  ref={register({required:true})} >
                            <option value={""}/>
                            <option value={"Single Occupancy"}>Single Occupancy</option>
                            <option value={"Joint Occupancy"}>Joint Occupancy</option>
                            <option value={"Occupancy in Common in Equal Share"}>Tenancy in Common in Equal Share"</option>
                            <option value={"Occupancy in Common in Unequal Share"}>Tenancy in Common in Unequal Share</option>
                        </select>
                        </td>
                        <td><input type={"text"} style={styles.table} name={"fdNumber"}  ref={register}/></td>
                    </tr>
                    <tr>
                        <td>Term</td>
                        <td>Commencement Date</td>
                        <td>End Date</td>
                    </tr>
                    <tr>
                        <td><select name={"tenureDurationName"} defaultValue={data?data.tenureDurationName:""} onChange={handleTerm} style={styles.table}  ref={register({required:true})}>
                            <option value={""}/>
                            <option value={"custom"}>Custom</option>
                            <option value={"5"}>Five(5) Years</option>
                            <option value={"99"}>Ninety Nine (99) Years</option>
                            <option value={"66"}>Sixty Six (66) Years</option>
                            <option value={"33"}>Thirty Three (33) Years</option>
                        </select></td>
                        <td>
                            <input type={"date"} min={today}  name={"startDate"} defaultValue={data?data.startDate:""} onChange={handleCommencement} style={styles.table}  ref={register({required:true})}/>
                        </td>
                        <td>
                            {
                                terms === null || terms !== "custom" ?<input type={"text"} value={endsDate} readOnly={true} name={"endDate"} style={styles.table}  ref={register({required:true})}/>
                                    : <input type={"date"} name={"endDate"} style={styles.table}  ref={register({required:true})}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Particulars</td>
                    </tr>
                    <tr>
                        <td colSpan={3}><textarea cols={3} rows={3} style={{...styles.table,...styles.txArea}} name={"particulars"}  ref={register}/></td>
                    </tr>
                    <tr>
                        <td>Registration Date/Time</td>
                        <td>Deregistration Date</td>
                        <td>Consideration</td>
                    </tr>
                    <tr>
                        <td><input type={"text"} name={"registrationDateTime"} readOnly={true} defaultValue={data?data.registrationDateTime:''} style={styles.table}  ref={register}/></td>
                        <td><input type={"text"} name={"deregistrationDateTime"} readOnly={true} defaultValue={data?data.deregistrationDateTime:''} style={styles.table}  ref={register}/></td>
                        <td><input type={"number"} min={0} defaultValue={data?data.consideration:''} name={"consideration"} style={styles.table}  ref={register}/></td>
                    </tr>
                    </tbody>
                </table>
                <div className={"row"} style={styles.detailsHeader}>
                    <div  style={currentMenu === 0?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(0)}}><label style={styles.headerLabels}>Properties/Title</label></div>
                    <div  style={currentMenu === 1?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(1)}}><label style={styles.headerLabels}>Documents</label></div>
                </div>
                <div style={currentMenu === 0?styles.show:styles.hide}>
                    <BaUnitTitle heading={false}/>
                </div>
                <div style={currentMenu === 1?styles.show:styles.hide}>
                    <DocumentsPane/>
                </div>
                <button type={"submit"}>Save</button>
            </form>
        </div>
    )
}

export default function Interest(){
    let txName=TransactionPopup.transaction().name
    const[ interest, interestSet ]=useState({})
    const[addInterest, addInterestSet] = useState(true)
    const handleNewInterest=data=>{
        console.log("the form data",data)
        const dataSend ={...data,transactionId:TransactionPopup.id()}
        console.log(dataSend)
        API.ax.post("/land/interest",dataSend)
            .then(d=>{
                const{status, result} = d.data
                if(status === 1){
                    interestSet(result)
                    //addInterestSet(false)
                }
            })
            .catch(e=>{
                console.log(e)
            })
        PopDetail.dismiss()
    }
    const handleSecondNewInterest=data=>{
        console.log("the form data",data)
        const dataSend ={...data,transactionId:TransactionPopup.id()}
        console.log(dataSend)
        API.ax.post("/land/second_interest",dataSend)
            .then(d=>{
                const{status, result} = d.data
                if(status === 1){
                    console.log("RESPONSE===>",d.data)
                    interestSet(result)
                    window.location.reload();
                    //addInterestSet(false)
                }
            })
            .catch(e=>{
                console.log(e)
            })
        PopDetail.dismiss()
    }


   const transactionInterestType=()=>{
        let txName=TransactionPopup.transaction().name
       console.log("TRANSACTION NAME:",txName)
       switch (txName) {
           case"Grant Right of Occupancy":
               return(<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={addInterest?()=>{PopDetail.show("New Interest",<TheInterest txType={"Right of Occupancy"} submit={handleNewInterest}/>,82)}:()=>{}}
               /><strong> New</strong></>)
               break
           case"Variation of Right of Occupancy":

               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break
           case"Discharge Mortgage":

               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break
           case"Mortgage Right of Occupancy":
               return(<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={addInterest?()=>{PopDetail.show("New Interest",<TheMortgageInterest txType={"Mortgage"} submit={handleSecondNewInterest}/>,82)}:()=>{}}
               /><strong> New</strong></>)
               break
           case"Transfer Right of Occupancy":

               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break
           case"Renew Right of Occupancy":

               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break
           case"Surrender Right of Occupancy":

               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break
           case"Rectification by LAD":
console.log("In rectification case")
               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break

           case"Rectification by Registrar":
               console.log("In rectification case")
               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break


           case"Replace Title Certificate":
               return(<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={addInterest?()=>{PopDetail.show("New Interest",<TheInterest txType={"Right of Occupancy"} submit={handleNewInterest}/>,82)}:()=>{}}
               /><strong> New</strong></>)
               break

           case"Grant Leasehold":
               console.log("In rectification case")
               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break
           case"Transfer Unit Title":
               console.log("In unit transfer case")
               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break
           case"TIC Grant Leasehold":
               console.log("In rectification case")
               return (<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={()=>{PopDetail.show("Related Interest",<TheRelatedInterest />,82)}}
               /><strong> Add Related</strong></>)
               break
           case"TIC Grant Right of Occupancy":
               return(<><i
                   className={'fa fa-plus-circle'}
                   style={{color:'tomato', fontSize:'25px'}}
                   onClick={addInterest?()=>{PopDetail.show("New Interest",<TheInterest txType={"Right of Occupancy"} submit={handleNewInterest}/>,82)}:()=>{}}
               /><strong> New</strong></>)
               break
           default:
               break
       }
   }
    useEffect(()=>{
        const transactionId = TransactionPopup.id()
        console.log("the tx id", transactionId)
        API.ax.get(`/land/interest/${transactionId}`)
            .then(d=>{
                const{status, result} = d.data
                console.log("the result ", result)
                console.log("the result of part role ", result.partRole)
                if(status === 1){
                    interestSet(result)
                    console.log("the result after set state of part role ", interest.partRole)
                }
            })
            .catch(e=>{
                console.log("there ",e)
            })
    },[])
    useEffect(()=>{
        addInterestSet(!interest.hasOwnProperty("tenancyType"))
    },[ interest ])
    return(
        <div style={styles.main}>
            <Header display={true} label={"Interests"}>
                <div>{
                    transactionInterestType()
                }

                </div>
                {TransactionPopup.transaction().name === "Register Unit Title" ? "" :
                    <div>
                        <i
                            className={'fa fa-edit'}
                            style={{color:'tomato', fontSize:'25px'}}
                            onClick={()=>{
                                let submit;
                                PopDetail.show("Edit Interest",<OccupancyEditInterest/>,80)}}
                        /><strong> Edit</strong>
                    </div>
                }
                {TransactionPopup.transaction().name === "Register Unit Title" ? "" :
                    <div>
                        <i
                            className={'fa fa-trash'}
                            style={{color:'tomato', fontSize:'25px'}}
                            onClick={()=>{}}
                        /><strong> Remove</strong>
                    </div>
                }
            </Header>
            <div style={styles.content}>
                <table className={'tb'} style={styles.table}>
                    <thead>
                    <tr>
                        <th>REMOVE</th>
                        <th>INTEREST</th>
                        <th>TENANCY TYPE</th>
                        <th>TERM</th>
                        <th>COMMENCEMENT DATE</th>
                        <th>END DATE</th>
                        <th>DOCUMENT</th>
                        <th>TITLES</th>
                        <th>STATUS</th>
                        <th>FD NUMBER</th>
                        <th>IID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !addInterest?<tr>
                        <td><span  className={'fa fa-trash'}
                                   style={{color:'tomato', fontSize:'25px'}} onClick={()=>{API.ax.get(`land/interest/remove/${interest.interestId}`)
                            .then(d=>{
                                console.log("the removed Interest ",d.data)
                                if(d.data.status === 1){
                                    console.log("the result  ")
                                    interestSet({})

                                }

                            })}}></span>
                        </td>
                        <td>{interest.interest}</td>
                        <td>{interest.tenancyType}</td>
                        <td>{interest.term}</td>
                        <td>{interest.startDate}</td>
                        <td>{interest.endDate}</td>
                        <td>{interest.document}</td>
                        <td>{interest.titles}</td>
                        <td>{interest.status}</td>
                        <td>{interest.fdNumber}</td>
                        <td>{interest.iid}</td>
                        </tr>:<tr><td colSpan={10} style={{textAlign:"center"}}>Please Add Interest</td></tr>
                    }
                    </tbody>
                </table>
            </div>
            <div>
                {txName==="Grant Right of Occupancy"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                </div>:txName==="Variation of Right of Occupancy"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }}>

                </div>:txName==="Transfer Right of Occupancy"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }}>
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Transferor/></div>
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Transferee tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                </div>:txName==="Mortgage Right of Occupancy"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }}>
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Mortgagor tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Mortgagee tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                </div>:txName==="Surrender Right of Occupancy"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                </div>:txName==="Renew Right of Occupancy"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                </div>

                    :txName==="Rectification by LAD"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                    <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                    </div>

                    :txName==="Rectification by Registrar"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                            <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                            <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                        </div>

                    :txName==="Replace Title Certificate"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                        <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                        <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                    </div>
                    :txName==="Grant Leasehold"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                        <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                        <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                    </div> :txName==="TIC Grant Leasehold"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                                            <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                                            <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                                        </div>
                    :txName==="Register Unit Title"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                            <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                            <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><UnitOwner tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                        </div>
                    :txName==="Transfer Unit Title"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }}>
                                            <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Transferor/></div>
                                            <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Transferee tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                                        </div>
                                            :txName==="TIC Grant Right of Occupancy"?<div  className={"row"} style={{margin:0,marginTop:'.5rem', border:'1px solid lightgray' }} >
                                                <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Grantor/></div>
                                                <div className={"col col-sm-6"} style={{margin:0,height:'200px',padding:0}}><Occupier tenancyType={interest.tenancyType} interestId={interest.interestId}/></div>
                                            </div>: ""}

            </div>
            <div className={"row"} style={{margin:0}}>
                <BaUnitTitle/>
            </div>
        </div>
    )
}
