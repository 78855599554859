import * as React from "react";
import PropertyPane from "../screen/spatial-unit/propertyPane";
import Wait from "../../wait";
import TxCreateService from "../../../../services/transaction/transaction_create";
import PopDetail from "../../popdetail";
import SelectPlot from "../screen/selectPlot";
import TransactionPopup from "../transaction_popup";

export default class PaneSearchPropertyBatchDemandNotice extends React.Component {
    static self = null

    constructor(props) {
        super(props);

        /*  this.state = {
              dataToDisplay:[],
              tenure:'',
              districtName:'',
              regionName:'',
              regPlanNumber:'',
              titleNumber:'',
              titleStatus:'Active',
              localityName:'',
              blockNumber:'',
              lotNumber:'',
              ldNumber:'',
              loNumber:'',
              registerFrom:'',
              registerTo:''
          }*/

        this.state = {
            selectedProperties: [],
            landProperties: [],
            rent: [],
            showMenu: false,
            gepgMenu: false,
            base64: '',
            dataToDisplay: [],
            propertyPushedArray: [],
            tenure: '',
            titleNumber: '',
            titleStatus: 'Active',
            districtName:'',
            regionName:'',
            regPlanNumber:'',
            localityName: '',
            blockNumber: '',
            lotNumber: '',
            ldNumber: '',
            loNumber: '',
            rightHolder: '',
            registerFrom: '',
            registerTo: '',
            planNumber:'',
            allocationStatusName:'',
            landUseName:'',
            drawingNumber:'',
        }
        this.getProperties = this.getProperties.bind(this)
        this.onclickPushBaunitIds=this.onclickPushBaunitIds.bind(this)
        this.pustSelected = this.pustSelected.bind(this)
        PaneSearchPropertyBatchDemandNotice.self = this
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    pushProperty(d) {

        PropertyPane.self.setState({
            property: d,
        })

    }


    render() {
        return (<div style={{height: '100%', overflow: 'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>

                <div className={'col col-sm-12'}>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td><span>Title Number</span></td>
                            <td><span>Tenure</span></td>
                            <td><span>Title Status</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width: '100%'}} name={"titleNumber"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={"tenure"} onChange={(e) => this.handleChange(e)}/>
                            </td>
                            <td><input style={{width: '100%'}} name={"titleStatus"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                        </tr>

                        <tr>
                            <td><span>Right Holder</span></td>
                            <td><span>LD Number</span></td>
                            <td><span>Estate Name</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width: '100%'}} name={"rightHolder"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={"ldNumber"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={""} onChange={(e) => this.handleChange(e)}/></td>
                        </tr>
                        <tr>
                            <td><span>Register From</span></td>
                            <td><span>Register To</span></td>
                            <td><span>-</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width: '100%'}} name={"registerFrom"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={"registerTo"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td><span>Location</span></td>
                            <td><span>Block</span></td>
                            <td><span>Number</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width: '100%'}} name={"localityName"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={"blockNumber"}
                                       onChange={(e) => this.handleChange(e)}/>
                            </td>
                            <td><input style={{width: '100%'}} name={"lotNumber"}
                                       onChange={(e) => this.handleChange(e)}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <button style={{float: 'right'}} onClick={() => this.getProperties()}>Search</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <br/>
                <div className={'row'}>
                    <hr/>
                    <div className={'col col-sm-12'}>

                        <table style={{width: '100%'}} className={'tb'}>
                            <thead>
                            <tr>
                                <th><span>SELECT</span></th>
                                <th><span>BAUNITID</span></th>
                                <th><span>TITLE No.</span></th>
                                <th><span>RIGHT HOLDER</span></th>
                                <th><span>DISTRICT</span></th>
                                <th><span>LOCATION</span></th>
                                <th><span>BLOCK</span></th>
                                <th><span>NUMBER</span></th>
                                <th><span>USE</span></th>

                            </tr>
                            </thead>
                            <tbody>
                            {this.state.dataToDisplay.map((d, i) => {

                                return (<tr key={i}>
                                        <td><input type={'checkbox'} onClick={()=>this.onclickPushBaunitIds(d)}/></td>
                                        <td>{d.baunitId}</td>
                                        <td>{d.titleNumber}</td>
                                        <td>{d.party}</td>
                                        <td>{d.districtName}</td>
                                        <td>{d.localityName}</td>
                                        <td>{d.blockNumber}</td>
                                        <td>{d.lotNumber}</td>
                                        <td>{d.landUseName}</td>


                                    </tr>
                                )
                            })
                            }
                            <tr>
                                <td colSpan={3}>
                                    <button style={{float: 'right'}} onClick={() => this.pustSelected()}>Save</button>
                                </td>
                            </tr>
                            </tbody>

                        </table>


                        {/* <br/>
                <div className={'col col-sm-12'}>

                    <table style={{width: '100%',marginTop:'32px'}} className={'tb'}>
                        <thead>
                        <tr>
                            <th><span>REGISTRATION NO.</span></th>
                            <th><span>TITLE NUMBER</span></th>
                            <th><span>RIGHT HOLDERS</span></th>
                            <th><span>TENURE</span></th>
                            <th><span>LD NUMBER</span></th>
                            <th><span>ESTATE NAME</span></th>
                            <th><span>STATUS</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.dataToDisplay.map((d, i) => {

                            return (<tr key={i} onClick={this.pushProperty(d)}>
                                <td>{d.recordingNumber}</td>
                                <td>{d.titleNumber}</td>
                                <td>{d.party}</td>
                                <td>{d.tenureTypeName}</td>
                                <td>{d.ldNumber}</td>
                                <td>{d.number}</td>
                                <td>{d.statuscode}</td>

                            </tr>)
                        })
                        }
                        </tbody>

                    </table>
                    <hr/>
                    <div style={{textAlign:'right',width:'100%'}}>
                        <button style={{width:'100px'}} onClick={this.savePerson}>Save</button>
                        <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
                    </div>
                </div>*/}
                    </div>
                </div>

            </div>
        </div>)
    }


    getProperties() {
        try {
            Wait.show("Loading Property...")

            let data = {
                titleNumber: this.state.titleNumber,
                titleStatus: 'Active',
                regionName: this.state.regionName,
                districtName: this.state.districtName,
                localityName: this.state.localityName,
                blockNumber: this.state.blockNumber,
                lotNumber: this.state.lotNumber,
                regPlanNumber: this.state.regPlanNumber,
                loNumber: this.state.loNumber,
                ldNumber: this.state.ldNumber,
                rightHolder: this.state.rightHolder,
                registerFrom: this.state.registerFrom,
                registerTo: this.state.registerTo,
                allocationStatusName:this.state.allocationStatusName,
                drawingNumber:this.state.drawingNumber,
                landUseName:this.state.landUseName,
                planNumber:this.state.planNumber,
                transactionId:TransactionPopup.id()
            }
            TxCreateService.selectedPlotsForDemandNotice(data).then(r => {
                Wait.dismiss()
                try {
                    console.log("PropertyLoaded", r)
                    this.setState({
                        dataToDisplay: r.data.result
                    })

                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }


    onclickPushBaunitIds(p){
        console.log(p)
        this.state.selectedProperties.push(p.baunitId)
        this.state.landProperties.push(p)

        console.log(this.state.landProperties)
    }

    pustSelected(){



        SelectPlot.self.setState({
            selectedProperties:this.state.selectedProperties,
            landProperties:this.state.landProperties
        })
        PopDetail.dismiss()

    }
}

