import React from 'react';
import {useForm} from "react-hook-form";
import API from "../../../../../services/API";
import TransactionPopup from "../../transaction_popup";
import PopDetail from "../../../popdetail";
import Wait from "../../../wait";
import Message from "../gro/person/message";

const NewPhase = ({handleChange, unitPlanId}) => {
    const{register,handleSubmit} = useForm()

    const details = data => {

        let dataToSend = {
            ...data,
            transactionId: TransactionPopup.id(),
        }
        Wait.show("Saving...")
        // alert(JSON.stringify(data) )
        API.ax.post("/rt/create/plan-phase", dataToSend,).then(d =>{
            console.log("plan phase added"+JSON.stringify(d.data))
            if(d.data.status === 0){
                PopDetail.show("Fail to Create New Phase",<Message message={"Total Phase can not be greater the amount of phase specified in Unit Plan"} faIcon={"harzad"}/>,60)
            }else {
                getPhases()
                PopDetail.dismiss()
            }

        }).catch(e => {
            console.log(e)
        })
        Wait.dismiss()
    }

    const getPhases = () => {
        API.ax.get(`/rt/get-phases/${unitPlanId}`).then(da => {
                console.log("list of phases " + JSON.stringify(da.data))
                if (da.data.status === 1){
                    handleChange(da.data.result)
                }
            }
        ).catch( e => {
            console.log(e)
        })
    }


    return (
        <div>
            <form onSubmit={handleSubmit(details)}>
                    <label>*Phase Number/Name<br/>
                        <input type={"text"} name={"phaseNumberName"} style={{width: "400px"}} ref={register({required: true})} />
                    </label>

                    <br/>
                    <label>Total Number of Unit<br/>
                        <input type={"number"} name={"totalNumberOfUnit"} style={{width: "400px"}} ref={register({required: true})}/>
                    </label>

                    <br/>
                    <label>Phase Value, TSh<br/>
                        <input type={"currency"} name={"phaseValue"} style={{width: "400px"}} ref={register({required: true})}/>
                    </label>
                <br/>
                <button type={'submit'} style={{width:'90px',paddingBottom:'.3rem'}}>Save</button>
                {/*<input  style={{width:'85px',paddingBottom:'.2.5rem'}}>Cancel</input>*/}
            </form>
        </div>
    );
}

export default NewPhase;