import * as React from "react";
import Wait from "../wait";
import PopDetail from "../popdetail";
import BpmnViewer from "../../bpmn/BpmnViewer";
import PaymentPane from "../transacrions/screen/payment";
import MobileSummaryPane from "./screens/mobile_summary";
import MobileProjectLayerPane from "./screens/project_layers";
import MobileDocumentsPane from "./screens/mobile_documents";
import ProjectUsersPane from "./screens/project_users";
import MobileTable from "../../tables/tb_mobile";
import DataCollectionPane from "./screens/data_collections";
import ProcessingReportPane from "./screens/processing_report";
import QuestionnairesConfig from "../../pane/mobile/project_pane/questionnares";
import MobileLocalityPane from "./screens/locality";
import DataProcessingPane from "./screens/data_processing";
import MobileCreateService from "../../../services/mobile/mobile_create";
import Message from "../transacrions/screen/gro/person/message";
import LADLegacy from "./screens/lad_legacy";
import ProjectAdmin from "./screens/project_admin";
import Superuser from "./screens/superuser";
import UtambuziData from "./screens/utambuzi_data";
import CRODataCollection from "./screens/cro_data";
import MobileReport from "../../../componentss/mobile_report";

export default class MobilePopup extends React.Component {

    static self = null;
    constructor(props) {
        super(props);

        this.state = {
            title: 'Loading...',
            project_id: 0,
            currentTabId: 0,
            screens: [
                { screenName: 'Summary' }
            ],
            project: {},
        }

        MobilePopup.self = this;
        this.loadScreenAt = this.loadScreenAt.bind(this)
        this.saveProject = this.saveProject.bind(this)
        this.popCamundda = this.popCamundda.bind(this)
    }


    static state() { return MobilePopup.self.state }
    static project() { return MobilePopup.state().project }
    static setState(data) {
        MobilePopup.self.setState(data)
    }

    static id() { return MobilePopup.state().project.projectId }
    static outputId() { return MobilePopup.state().project.outputId }

    static show(project) {
        MobilePopup.self.state = null

        try {
            MobilePopup.setState({
                project: project,
                current_screen: <MobileSummaryPane location={project.location} output={project.outputName} />
            })
        } catch (e) {
            console.log(e)
        }

        try {
            MobilePopup.self.setState({
                screens: []
            })

            if (project.outputName === 'LAD Legacy') {
                MobilePopup.self.setState({
                    screens: project.is_new === false ? [
                        { screenName: 'Summary' },
                        { screenName: 'Legacy Data' },
                    ] : [{ screenName: 'Summary' }],
                })
            }
            else if (project.outputName === 'Utambuzi') {
                MobilePopup.self.setState({
                    screens: project.is_new === false ? [
                        { screenName: 'Summary' },
                        { screenName: 'Project Users' },
                        { screenName: 'Utambuzi Data' },
                    ] : [{ screenName: 'Summary' }],
                })
            }
            else {
                if (!project.is_new) {
                    try {
                        setTimeout(() => {
                            try {
                                let arr_screes = [];

                                project.screens.replace('[', '').replace(']', '').split(',').forEach(sc => {
                                    let name = sc.trim()
                                    console.log(name)
                                    arr_screes.push({ screenName: name })
                                })

                                MobilePopup.self.setState({
                                    screens: arr_screes
                                })
                            } catch (e) {
                                //console.log(e)
                            }
                        }, 50)
                    } catch (e) {
                        console.log(e)
                    }

                } else {
                    MobilePopup.self.setState({
                        screens: [
                            { screenName: 'Summary' },
                        ],
                    })
                }
            }

        } catch (e) {
            console.log(e)
        }

        document.getElementById('div-popup').style.display = 'inherit';
    }

    static dismiss() {
        document.getElementById('div-popup').style.display = 'none';
        MobilePopup.self.props = null
    }

    popCamundda() {
        PopDetail.show(this.state.title, <BpmnViewer bpmnfile={(this.state.title).replaceAll(' ', '')} currentTask={MobilePopup.taskKey} />)
    }

    render() {

        return (<div className={'popup'} id={'div-popup'}>

            <div style={{ height: '6vh', background: '#fff', borderBottom: '0.5px solid #efefef' }}>
                <div className={'col col-sm-8'} style={{ float: 'left' }}>
                    <div style={{ height: '6vh', lineHeight: '6vh', cursor: 'pointer' }} className={'col col-10'} onClick={() => this.popCamundda()}>
                        <b style={{ color: '#008b8b' }}>{this.state.project.projectName}</b> / {this.state.project.outputName}
                    </div>
                </div>
                <div className={'col col-sm-4'} style={{ textAlign: 'right', float: 'right', height: '6vh', lineHeight: '6vh' }}>
                    <span style={{ padding: '8px', color: '#fff', fontWeight: 'bold', cursor: 'pointer', marginRight: '4px', background: '#008b8b' }} onClick={() => this.saveProject(false)} >Save</span>
                    <span style={{ padding: '8px', fontWeight: 'bold', cursor: 'pointer', marginRight: '4px' }} onClick={() => this.saveProject()} className={'default'}>Save & Close</span>
                    <span style={{ padding: '8px', fontWeight: 'bold', cursor: 'pointer' }} onClick={MobilePopup.dismiss} className={'default'}>Cancel</span>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col col-sm-2'} style={{ paddingRight: 0 }}>
                    {
                        this.state.screens.map((d, i) => {
                            return (<div key={i} className={'btn-left-menu'} id={'menu_' + i} onClick={() => this.loadScreenAt(d, i)} style={this.state.currentTabId === i ? selected : {}}>
                                {d.screenName}
                            </div>)
                        })
                    }
                </div>

                <div className={'col col-sm-10'} style={{ height: '94vh', paddingLeft: 0 }}>
                    {this.state.current_screen}
                </div>

            </div>

        </div>)
    }

    saveProject(close = true) {
        let project = MobilePopup.state().project
        let project_id = parseInt(document.getElementById(project.outputName === '' ? 'sp_projects_legacy' : 'sp_projects').value)

        if (project.is_new) {
            if (project_id === 0) {
                PopDetail.show("Warning", <Message faIcon={'harzad'} message={'No Project'} />, 30)
                return
            }

            try {

                Wait.show("Saving...")
                let initPayment = 0.0

                let data = {
                    project_id: project_id,
                    projectName: project.projectName,
                    outputId: project.outputId,
                    initPayment: initPayment
                }

                MobileCreateService.createProject(data).then(r => {
                    try {
                        Wait.dismiss()
                        console.log(r)
                        MobileTable.loadData()
                        if (close) {
                            MobilePopup.dismiss()
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }
    }
    loadScreenAt(d, i) {
        let current_screen = d.screenName === "Summary" ? <MobileSummaryPane output={this.state.project.outputName} location={this.state.project.location} key={new Date().getMilliseconds() + '_' + i} /> :
            d.screenName === "Print Documents" ? <MobileDocumentsPane transaction={this.state.transaction} key={new Date().getMilliseconds()} /> :
                d.screenName === "Data Processing" ? <DataProcessingPane tab={0} hide_map={true} key={new Date().getMilliseconds()} /> :
                    d.screenName === 'Locality' ? <MobileLocalityPane key={new Date().getMilliseconds()} /> :
                        d.screenName === 'Legacy Data' ? <LADLegacy key={new Date().getMilliseconds()} /> :
                            d.screenName === 'Project Layers' ? <MobileProjectLayerPane key={new Date().getMilliseconds()} /> :
                                d.screenName === 'Questionnaires' ? <QuestionnairesConfig key={new Date().getMilliseconds()} /> :
                                    d.screenName === 'Mobile Data' ? <DataCollectionPane tab={0} hide_map={false} key={new Date().getMilliseconds()} /> :
                                        d.screenName === 'Payments' ? <PaymentPane key={new Date().getMilliseconds()} /> :
                                            d.screenName === 'Project Users' ? <ProjectUsersPane key={new Date().getMilliseconds()} /> :
                                                d.screenName === 'Project Admin' ? <ProjectAdmin key={new Date().getMilliseconds()} /> :
                                                    d.screenName === 'Super User' ? <Superuser key={new Date().getMilliseconds()} /> :
                                                        d.screenName === 'Utambuzi Data' ? <UtambuziData key={new Date().getMilliseconds()} /> :
                                                            d.screenName === "Generated plots" ? <CRODataCollection tabIndex={0} key={new Date().getMilliseconds()} /> :
                                                                d.screenName === "Surveys" ? <CRODataCollection tabIndex={1} key={new Date().getMilliseconds()} /> :
                                                                    d.screenName === "TP Drawings" ? <CRODataCollection tabIndex={2} key={new Date().getMilliseconds()} /> :
                                                                        d.screenName === "Processing Report" ? <MobileReport hide_map={true} key={new Date().getMilliseconds()} /> : ''
        this.setState({
            current_screen: current_screen,
            currentTabId: i
        })
    }
}

const selected = { cursor: 'pointer', borderLeft: '5px solid #DF4A36', background: '#ffffff', fontWeight: 'bold' }