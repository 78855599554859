import React , { useState } from 'react'
import { listener,startTablet } from "../../../../../../utils/sign-pad";
import API from "../../../../../../services/API";
import Wait from "../../../../wait";
import PopDetail from "../../../../popdetail";
import Message from "./message";

const groups = {
}
export default function DemoSign({partyId, nationalId, refresh}) {

    const [sigCaptured, sigCapturedSet] = useState(null);

    listener()

    const handleSig = () => {
        let signature = sigCaptured.sig;
        let dataToSend ={partyId,nationalId,signature}
        console.log("Required Data ",dataToSend)
        Wait.show("Updating Customer Signature ....")
        API.ax.post("/party/update-signature",dataToSend)
            .then(d=>{
                refresh(true);
                Wait.dismiss()
                let {status, message} = d.data
                if(status === 1){
                    PopDetail.show("Successful",<Message message={message} action={0} faIcon={"success"}/>,40)
                }else{
                    let sms = "The new Signature was not Set, Please Try Again"
                    PopDetail.show("Error",<Message message={sms} action={0} faIcon={"success"}/>,40)

                }
            }
            )
            .catch(e=>{
                    PopDetail.show("Error",<Message message={e} action={0} faIcon={"harzad"}/>,40)

            })
    }

    const handleSigCapture = (sig) => {
        let image = new Image();
        image.src = 'data:image/png;base64,' + sig;
        sigCapturedSet({image, sig})
    }
return(
    <>
        <canvas id="cnv" width="500" height="100" />
    <div>
    <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end", width:"100%"}}>
        {!sigCaptured?
            <button style={{width:"150px",outline:"none"}} onClick={() => {startTablet(handleSigCapture);}}>Sign</button>
            :<button style={{width:"250px",outline:"none"}} onClick={() => {handleSig();}}>Submit Signature</button>
        }
        <button className={"default"} style={{width:"150px",outline:"none"}} onClick={()=>PopDetail.dismiss()}>Cancel</button>
    </div>
    </div>
   </>
)
}