import * as React from "react";
import PopDetail from "../../../popdetail";

import * as LF from "leaflet";
import "leaflet.utm"

import uncheck from "../../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../../resource/img/checkbox/checkbox_checked_16.png";
import AddSurveyInstructionPane from "../add/add_surver_instruction";
import Wait from "../../../wait";
import UgSpatial from "../../../../maps/ug_spatial";
import UploadSpatialPane from "../add/upload_spatial";
import TransactionPopup from "../../transaction_popup";
import SMDReadService from "../../../../../services/smd/smd_read";
import Basemap from "../../../../maps/basemap";
import API from "../../../../../services/API";
import PaneApplySmdApproval from "../../pops/pane_apply_smd_approval";

export default class SurveyInstructionPane extends React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            instructions:[],
        }

        SurveyInstructionPane.self = this

        this.uploadInstruction = this.uploadInstruction.bind(this)
        this.uploadRough = this.uploadRough.bind(this)
        this.toggleCheck = this.toggleCheck.bind(this)

    }



    static loadData(){


        try {
            Wait.show('Loading instructions....')
            SMDReadService.listSR(TransactionPopup.id()).then(r=>{
                Wait.dismiss()
                console.log(r.data)
                try {

                    SurveyInstructionPane.self.setState({
                        instructions:r.data.result
                    })


                    UgSpatial.clear()
                    let ly_group = new LF.FeatureGroup();
                    let sat = Basemap.googleSat(),
                        hybrid = Basemap.googleHybrid(),
                        blank = LF.tileLayer('')

                    let map = LF.map('map', {
                        layers: [blank, ly_group],
                        attributionControl: false,
                        fullscreenControl: true,
                        maxZoom:10000
                    }).setView([-6.085936, 35.711995], 4)

                    let ly_vb = UgSpatial.getLayerByName('valuation_block','transaction_id !='+TransactionPopup.id()).addTo(map)


                    let baseLayers = {
                        "<span style='color: gray'>Blank</span>": blank,
                        "Satellite": sat,
                        "Hybrid": hybrid
                    }

                    let filter = 'transaction_id='+TransactionPopup.id()
                    let layername = 'survey_instruction'
                    let selected



                    API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                        Wait.dismiss()
                        try {
                            r.data.features.forEach(p=>{

                                let myLayer = LF.geoJSON(p,{
                                    onEachFeature:(f,l)=>{
                                        LF.marker(l.getBounds().getCenter(),{
                                            icon:LF.divIcon({
                                                className:'bindTooltipBig',
                                                html:f.properties.survey_instruction_id,
                                                iconSize:[100,40]
                                            })
                                        }).addTo(ly_group)
                                        l.setStyle({
                                            color:f.properties.status==='Approved'?'#00ff00':'#901'
                                        })
                                    },
                                    coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                                    weight:3,zIndex:2, fillOpacity: 0
                                }).on('dblclick',e=>{

                                   /* ValBlockSpatialUnitPane.self.openRatesAt({
                                        id:ValBlockSpatialUnitPane.self.state.id,
                                        blockId:ValBlockSpatialUnitPane.self.state.vblock_id,
                                        status:ValBlockSpatialUnitPane.self.state.status,
                                    })*/

                                }).on('click', function (e) {

                                    if (selected) {e.target.resetStyle(selected)}
                                    selected = e.layer

                                    /*ValBlockSpatialUnitPane.self.setState({
                                        region:p.properties.region,
                                        district:p.properties.district,
                                        council:p.properties.council,
                                        locality:p.properties.locality,
                                        status:p.properties.status,
                                        vblock_id:p.properties.vblock_id,
                                        id:p.id.split('.')[1],
                                        blockId:p.properties.blockId
                                    })*/
                                    selected.bringToFront()
                                    selected.setStyle({color: '#000', weight:4})
                                    map.fitBounds(selected.getBounds())

                                })
                                myLayer.addTo(ly_group)
                            })

                            let myLayer = LF.geoJSON(r.data,{coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();}})
                            try{ map.fitBounds(myLayer.getBounds())}catch (e) { }

                        }catch (e){
                            console.log(e)
                        }

                    })

                    let overlays = {
                        'Others Valuation Blocks':ly_vb,
                        'Current Layer':ly_group,
                    }

                    UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)


                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }


    toggleCheck(i){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            return;
        }
        doc.src=uncheck;
    }

    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>



                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>

                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Map</b>

                            <div style={{float:'right'}}>
                                <span style={{cursor:'pointer'}} onClick={()=>SurveyInstructionPane.loadData()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-refresh screen-action'}/>Refresh
                                </span>

                                <span style={{cursor:'pointer',}} onClick={()=>this.uploadInstruction()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'}/>Add Instruction Info
                                </span>

                                <span style={{cursor:'pointer'}} onClick={()=>this.uploadRough()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-upload screen-action'}/>Upload Rough Coordinates
                                </span>

                            </div>
                        </div>

                        <div className={'row'} style={{height:'97%'}}>

                            <div className={'col col-sm-12'} style={{background:'#efefef'}} id={'ramani'}/>

                        </div>

                    </div>
                </div>



            </div>
            <div className={'row'} >

                <div className={'col col-sm-12'} style={{height:'34vh'}}>
                    <div style={{background:'#fff',padding:'24px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{textAlign:'right'}}>

                        </div>

                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>
                            <tr>
                                <th>
                                    <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                                </th>
                                <th>INSTRUCTION NO</th>
                                <th>TP NO</th>
                                <th>INSTRUCTION TO</th>
                                <th>SURVEY TYPE</th>
                                <th>REGION</th>
                                <th>DISTRICT</th>
                                <th>COUNCIL</th>
                                <th>LOCATION</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.instructions.map((d,i)=>{
                                return(<tr key={i}>
                                    <td>
                                        <img id={'chk_'+(i+1)} alt={'chk_'+(i+1)} src={uncheck} onClick={()=>this.toggleCheck(i+1)}/>
                                    </td>
                                    <td>{d.instructionNumber}</td>
                                    <td>{d.drawingNumber}</td>
                                    <td>{d.instructionTo}</td>
                                    <td>{d.surveyType}</td>
                                    <td>{d.region}</td>
                                    <td>{d.district}</td>
                                    <td>{d.council}</td>
                                    <td>{d.locality}</td>
                                </tr>)
                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>)
    }

    uploadInstruction(){
        if (this.state.instructions.length===0){
            PopDetail.show('Add Instruction Info', <AddSurveyInstructionPane/>,60)
        }else{
            PopDetail.confirm('Survey Instruction Exists','Survey Instruction Exists, Do you want to Edit Existing Instruction ?',this.noHandler,0,this.noHandler)
        }

    }

    noHandler(){ PopDetail.dismiss() }

    uploadRough() {
        if (this.state.instructions.length<1){
            PopDetail.confirm('No Instruction Info','This Instruction has no any information, Please Add Instruction Info',this.uploadInstruction,0,this.noHandler)
        }else{
            PopDetail.show('Upload Rough Coordinates',
                <UploadSpatialPane type={'rough_coordinates'} surveyId={0} instructions={this.state.instructions[0].id}/>,44)
        }
    }

}