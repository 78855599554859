import React, {Component} from 'react';
import TransactionPopup from "../../transaction_popup";
import TicReadService from "../../../../../services/tic/tic_read";

class LandInfo extends Component {
    constructor(props) {
        super(props);
        this.state={
            plotnumber:"",
            blocknumber:"",
            size:"",
            unit:"",
            locality:"",
            district:"",
            region:"",
            landuse:"",
        }

    }

    componentDidMount() {

        TicReadService.getLandInfo(TransactionPopup.id()).then(d=>{
            console.log("Land info",d.data)
            if(d.data.status===1){
                this.setState({plotnumber:d.data.result.plotnumber,blocknumber:d.data.result.blocknumber,size:d.data.result.size,unit:d.data.result.unit,locality:d.data.result.locality,district:d.data.result.district,region:d.data.result.region,landuse:d.data.result.landuse})
            }else {

            }
        })
    }
    render() {
        return (
            <div>
                <table style={{width:'100%'}}>
                    <tbody>
                    <tr style={{color:'#888'}}>
                        <td>Plot No</td>
                        <td>Block No</td>
                        <td>Locality</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.plotnumber} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.blocknumber} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.locality} readOnly={true}/></td>
                    </tr>
                    <tr style={{color:'#888'}}>
                        <td>Area</td>
                        <td>Unit</td>
                        <td>Use</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.size} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.unit} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.landuse} readOnly={true}/></td>
                    </tr>

                    <tr style={{color:'#888'}}>
                        <td>Location</td>
                        <td>District</td>
                        <td>Region</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.locality} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.district} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.region} readOnly={true}/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default LandInfo;