import React, {Component} from 'react';
import TxReadService from "../../../../services/transaction/transaction_read";
import TransactionPopup from "../transaction_popup";
import PopDetail from "../../popdetail";
import SelectPlots from "./selectPlots";

class SelectLegacyPlots extends Component {
    static self = null
    constructor(props) {
        super(props);
        this.state={
            selectedSpatial:[],
        }
        SelectLegacyPlots.self = this
    }
    componentDidMount() {

        try{
        let txid = TransactionPopup.id()
        TxReadService.getSpatialUnitTransactionStatus(txid).then(d => {
            try {
                if (d.data.status === 1) {
                    this.setState({selectedSpatial: d.data.result})
                }
            }catch (e) {

            }
        })
    }catch (e) {

        }
    }

    render() {
        return (
            <div>
                <div className={'row'} style={{height: '10%'}}>
                    <div style={{height: "inherit", overflow: 'auto'}} className={'col col-11'}>
                        Selected Legacy Plots
                    </div>
                    <div style={{height: "inherit", overflow: 'auto',float:"right"}} className={'col col-1'}>
                        <button onClick={()=>{PopDetail.show("SEARCH LEGACY PLOTS",<SelectPlots/>,80)}}>Search Plots</button>
                    </div>

                </div>
                <div style={{height: '100%', overflow: 'auto',width:'100%'}} className={'row'}>
                    <div style={{height: '100%', overflow: 'auto'}} className={'col col-12'}>
                    <table className={'tb'}  style={{width: '100%'}}>
                        <thead>
                        <tr>
                            <th>Council</th>
                            <th>Location</th>
                            <th>Block</th>
                            <th>LOT Number</th>
                            <th>Reg.Plan Number</th>
                            <th>Plan Number</th>
                            <th>Legal Area</th>
                            <th>Unit</th>
                            <th>Use</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.selectedSpatial.map((d,i)=>{

                                return(<tr key={i}>
                                    <td>{d.districtName}</td>
                                    <td>{d.localityName}</td>
                                    <td>{d.blockNumber}</td>
                                    <td>{d.lotNumber}</td>
                                    <td>{d.regPlanNumber}</td>
                                    <td>{d.planNumber}</td>
                                    <td>{d.legalArea}</td>
                                    <td>{d.areaUnitName}</td>
                                    <td>{d.uses}</td>
                                </tr>)
                            }
                        )}

                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectLegacyPlots;
