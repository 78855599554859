import * as React from "react";
import PopDetail from "../../../popdetail";
import Wait from "../../../wait";
import TransactionPopup from "../../transaction_popup";
import TPCreateService from "../../../../../services/tp/tp_create";
import SMDCreateService from "../../../../../services/smd/smd_create";
import SpatialUnitScreen from "../spatial-unit/spatial_unit";
import TpSpatialUnitPane from "../spatial-unit/tp_spatial_unit";
import SurveyInstructionPane from "../spatial-unit/surver_instruction";
import {parseZip} from "shpjs";
import CommonCreateService from "../../../../../services/common/common_create";
import SpatialValidatorPane from "../../../../../services/ugshp/SpatialValidatorPane";


export default class UploadShapefile extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            parcel:null,
            building:null,
            point:null,
            contours:null,
            road:null,
            power:null,
            water:null,
            railways:null,
            pond:null,
            is_utm:'hidden',
            layer_type:''
        }
        this.saveCooridates = this.saveCooridates.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.previewSpatia = this.previewSpatia.bind(this)
    }
    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }

    handleChangeFile=e=>{
        this.setState({ [e.target.name]:e.target.files[0] })
    }


    putInQueue(){

        let txNo = TransactionPopup.id()

        if (this.state.coordinate===undefined){
            alert('No Coordinates')
            return;
        }


        const formData = new FormData()
        formData.append('coordinate',this.state.coordinate)
        formData.append('extra', JSON.stringify({
            txNo:txNo,
            layerType:this.state.layer_type
        }))

        Wait.show('Queueing.....')

        console.log("Creating ......")
        CommonCreateService.createQueue(formData).then(r => {
            Wait.dismiss()
            PopDetail.dismiss()
            SpatialUnitScreen.loadData()
        })



    }


    previewSpatia(){

        if (this.state.coordinate===undefined){
            alert('No Coordinates')
            return;
        }

        const formData = new FormData()
        formData.append('coordinate',this.state.coordinate)
        formData.append('extra', JSON.stringify({
            purpose:this.props.purpose
        }))

        Wait.show('Validating.....')
        CommonCreateService.createPreviewSHP(formData).then(r => {
            Wait.dismiss()
            try {
                let reader = new FileReader()
                reader.onloadend = (e)=>{

                    let label = 'tp_number'
                    let layername = this.props.type==='rough_coordinates'?'survey_instruction': this.props.type==='smd_spatial'?'smd_spatial':'tp_spatial_unit'

                    let cols =this.props.type==='rough_coordinates'?[]:
                        this.props.type==='smd_spatial'?['reg_pn','block_num','plot_num','cal_area','use','locality','district',
                                'region','la_zone','digit_user','tp_no','lot_type','unit','id_sp']:
                            ['basemap','mtaa','tp_firm','tp_obtaine','tp_number','locality','block','revision','use','lot_type']

                    if (this.props.type==='smd_spatial'){
                        label = 'plot_num'
                        if (this.state.layer_type==='Point Features'){
                            cols = ['station','x','y']
                            label = 'station'
                        }
                    }
                    if(TransactionPopup.transaction().name==='First Parcel Registration'){cols = []}

                    PopDetail.show('Preview Shapefile',
                        <SpatialValidatorPane json={parseZip(e.target.result)}
                                            label={this.props.label}
                                            label2={this.props.label2}
                                            validator={r.data}
                                            layer_name={layername}
                                            upload={this.saveCooridates}
                                            shp_columns={cols}
                        />)
                }
                reader.onError =e=>{}
                reader.readAsArrayBuffer(this.state.coordinate)


            }catch (e) {
                console.log(e)
            }
        })

    }

    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <div style={{color:'#008b8b'}}>Choose File ( .zip )</div>
                    <input type={'file'} style={{width:'100%'}} accept={".zip,.ZIP"} name={'coordinate'} onChange={this.handleChangeFile}/>
                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.previewSpatia}>Preview</button>
                {/*<button style={{width:'100px'}} onClick={this.putInQueue}>Queue</button>*/}
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }


    saveCooridates(){
        try {

            if(this.state.coordinate ===undefined){
                alert('No Coordinates')
                return
            }

            const formData = new FormData()
            formData.append('coordinate',this.state.coordinate)

            formData.append('extra',JSON.stringify({
                transactionId:TransactionPopup.id(),
                layerType:'Parcels',
                purpose:'compilation'
            }))

            console.log(this.props.type)

            Wait.show("Saving...")

            if (this.props.type==='smd_spatial'){

                SMDCreateService.uploadSpatial(formData).then(d=>{
                    Wait.dismiss()
                    try {
                        let data = d.data
                        if (data.status===0){
                            alert(data.message)
                            return
                        }
                    }catch (e){console.log(e)}
                    PopDetail.dismiss()
                    SpatialUnitScreen.loadData()
                })
            }
            else if(this.props.type==='rough_coordinates'){
                try {

                    Wait.show("Saving...")

                    const formData = new FormData()
                    formData.append('coordinate',this.state.coordinate)
                    formData.append('extra',JSON.stringify({
                        txId:TransactionPopup.id(),
                        surveyId:this.props.instructions
                    }))
                    SMDCreateService.uploadRoughCoordinates(formData).then(d=>{
                        Wait.dismiss()
                        if (d.data.status===1){
                            PopDetail.dismiss()
                        }
                        PopDetail.dismiss()
                        SurveyInstructionPane.loadData()
                    })
                }catch (e){
                    console.log(e)
                }

            }
            else{
                TPCreateService.uploadTpLayer(formData).then(d=>{
                    Wait.dismiss()
                    let data = d.data
                    if (data.status===0){
                        alert(data.message)
                        return
                    }
                    PopDetail.dismiss()
                    TpSpatialUnitPane.loadData()

                })
            }


        }catch (e){
            Wait.dismiss()
            console.log(e)
        }
    }

}
