import * as React from "react"


import AdminUploadSpatial from "../popups/admin/admin_upload_spatial";

export default class AdminLayout extends React.Component{

    static project_menu = [{
        'title':'Upload Spatial',
        'pane':<AdminUploadSpatial/>
    }]
    constructor(props) {
        super(props);
        this.state = {
            project_menu:AdminLayout.project_menu
        }

        AdminLayout.project_menu = [



        ]
    }

    componentDidMount() {
        this.loadProjectDetail()
        this.openPaneAt(0)
    }

    loadProjectDetail(){
        try {

            //console.log(this.props.services)
            //MobileReadService.readProject(this.props.services.ref_id)

        }catch (e) {
            console.log(e)
        }
    }

    openPaneAt(paneId){
        try {
            this.setState({
                current_pane:this.state.project_menu[paneId].pane
            })
        }catch (e) {
            console.log(e)
        }
    }

    hidePane(){ document.getElementById('admin_layout').style.display = 'none' }

    static show(){ document.getElementById('admin_layout').style.display = 'inherit' }

    render() {
        return(<div style={{position:'absolute',display:'none',left:0,right:0,top:0,bottom:0,backgroundColor:'#f7f7f7'}}  id={'admin_layout'}>
            <div className={'row'}>
                <div className={'col col-sm-12'} style={{height:'7vh',boxShadow:'0 0 4px #ccc',
                    display:'table-cell',lineHeight:'5vh', paddingTop:'1vh',
                        borderRadius:'2px',marginTop:'1vh',background:'#fff',verticalAlign:'middle'}}>

                    <b style={{color:'darkcyan'}}>{this.props.title} </b>


                    <span className={'btn'} style={{width:'100px',float:'right'}}
                        onClick={()=>this.hidePane()}>Close</span>
                    <span className={'btn refresh'} style={{width:'100px',float:'right'}}>Refresh</span>
                   {/* <span className={'btn submit'} style={{width:'100px',float:'right'}}>Submit</span>*/}

                </div>


            </div>
            <div className={'row'} style={{height:'84vh',marginTop:'1vh'}}>

                <div className={'col col-sm-2'} style={{float:'left'}}>
                    {this.state.project_menu.map((d,i)=>{

                        return(<div key={i}>
                            <div className={'left-menu'} style={{borderBottom:'1px solid #ccc'}} onClick={()=>this.openPaneAt(i)}>
                                <span className={'fa fa-angle-double-right'}/>
                                <span>{d.title}</span>
                            </div>

                        </div>)
                    })}
                </div>

                <div className={'col col-sm-10'}
                     style={{float:'left',boxShadow:'0 0 50px #ccc',padding:0,border:'1px solid #ccc',background:'#fff'}}>
                    {this.state.current_pane}
                </div>

            </div>

        </div>)
    }
}