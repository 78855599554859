import * as React from "react";
import LegacyDocumentsPane from "./legacy/legacy_documents";
import LegacyMap from "./legacy/legacy_map";
import LegacyOccupancyInfo from "./legacy/legacy_occupancy_info";

export default class PopLegacy extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            tabs:['Occupancy Info','Spatial Unit','Documents'],
            pane:[<LegacyOccupancyInfo parcel={this.props.parcel}/>,
                <LegacyMap parcel={this.props.parcel}/>,<LegacyDocumentsPane parcel={this.props.parcel}/>],
            current_id:0
        }

        this.loadDataAt = this.loadDataAt.bind(this)
    }

    componentDidMount() {
        this.loadDataAt(0)
    }

    loadDataAt(tab){
        this.setState({current_id:tab})

    }

    render() {
        return(<div className={'row'} style={{width:'100%',height:'95%'}}>
            <div className={'col col-sm-12'} style={{position:'sticky'}}>
                {this.state.tabs.map((d,i)=>{
                    return(<div key={i} onClick={()=>this.loadDataAt(i)} className={this.state.current_id===i?'tab tab-active':'tab'}>{d}</div>)
                })}
            </div>
            <div className={'col col-sm-12'} style={{height:'100%'}}>
                {this.state.pane[this.state.current_id]}
            </div>
        </div>)
    }
}