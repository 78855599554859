import * as React from "react";
import PopDetail from "../../popdetail";
import TransactionPopup from "../transaction_popup";
import ReviewPane from "./review";
import Wait from "../../wait";
import TxCreateService from "../../../../services/transaction/transaction_create";
import TransactionTable from "../../../tables/tb_transaction";
import ProcessingComments from "./objects/processingComments";
import SummaryPane from "./summary";
import PaneApplyRtuApproval from "../pops/pane_apply_rtu_approval";
import WarningPane from "./warningPane";

export default class CompletePane extends React.Component{

    static self = null
    constructor(props) {
        super(props);
        this.state = {
            nextTask:{
                question:''
            },
            selectedTask:{},
            message:[],
            warning:[]
        }
        this.completeTask = this.completeTask.bind(this)
        this.validateTask = this.validateTask.bind(this)
        this.completingTransaction = this.completingTransaction.bind(this)
        CompletePane.self = this
    }

    completeTask(){
        this.setState({message:[]})
        try {
            //let assignee = TransactionPopup.assignee()
            this.validateTask(true)

        }catch (e){
            console.log(e)
        }
    }

    static completeWithWarning(){
        return this.completingTransaction();
    }

    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-4'} style={{height:'47vh',paddingRight:'4px'}}>

                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Next Task</b>

                            <div style={{float:'right'}}>
                                <span>
                                <span style={{marginLeft:'16px'}} className={'fa fa-check-circle-o screen-action'}
                                      onClick={this.validateTask} />Validate
                            </span>
                                <span style={{cursor:'pointer'}} onClick={this.completeTask}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-check screen-action'}
                                    />Complete
                                </span>

                            </div>
                        </div>


                        <div style={{width:'100%'}}>
                            {TransactionPopup.self.state.nextTask!==null?TransactionPopup.self.state.nextTask.question:'No Next Task'}

                            {TransactionPopup.self.state.nextTask!==null?TransactionPopup.self.state.nextTask.options.map((d,i)=>{
                                return(<div key={i} style={{width:'100%'}}>
                                    <input type={'radio'} name={'label'} onClick={()=>this.setState({
                                        selectedTask:d
                                    })} defaultChecked={TransactionPopup.self.state.nextTask.options.length===1}/> {d.label}
                                </div>)
                            }):<div>No Transaction Number</div>}
                        </div>





                    </div>
                </div>

                <div className={'col col-sm-8'} style={{height:'47vh',paddingLeft:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Task Validation Results</b>

                        </div>

                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>
                            <tr>
                                <th style={{width:'200px'}}>TYPE</th>
                                <th>MESSAGE</th>
                            </tr>
                            </thead>

                            <tbody>
                            {this.state.message.map((d,i)=>{
                                return(<tr key={i}>
                                    <td style={{color:'red'}}>Critical</td>
                                    <td>{d}</td>
                                </tr>)
                            }
                            )}

                            </tbody>
                        </table>

                    </div>
                </div>

            </div>

            <div className={'row'} style={{marginTop:'4px'}}>

                <div className={'col col-sm-12'}>
                    <ProcessingComments coment={SummaryPane.processingComments()}/>
                </div>



            </div>


        </div>)
    }

    validateTask(complete=false) {
        try {
            if (TransactionPopup.self.state.nextTask.options.length === 1) {
                console.log(TransactionPopup.self.state.nextTask.options[0])
                this.setState({
                    selectedTask: TransactionPopup.self.state.nextTask.options[0]
                })
            }
            setTimeout(()=> {
                let txDefName = this.state.selectedTask.taskName
                let dat = {
                    transactionId: TransactionPopup.id(),
                    taskDefinitionId: TransactionPopup.transaction().txDefId,
                    nextTask:txDefName
                }

                console.log("validation", dat)
                Wait.show("Validating Task ....")
                TxCreateService.validatingTask(dat).then(r => {
                    console.log("data Validation messages", r)
                    Wait.dismiss()

                   try {
                       if(r.data.result.length>0) {
                           this.setState({message: r.data.result})
                           PopDetail.dismiss()
                       }
                       else {
                           if(complete){
                               console.log("MESSAGE",this.state.message)

                               if(this.state.message.length>0){
                                   PopDetail.show("Warning",<div>Clear Errors shown</div>)
                               }else
                               {
                                       this.completingTransaction()

                               }

                           }
                       }
                   }catch (e) {
                       console.log(e)
                   }
                    /* TransactionTable.loadData()
                     TransactionPopup.dismiss()*/
                })
            },1000)
        }catch (e){
            console.log(e)
        }
    }

    completingTransaction(){
        let uid = ReviewPane.self != null ? ReviewPane.self.state.assignee.id : 0
        /*
                    if(uid===0){
                        PopDetail.show('Assign Officer',<PaneAssign/>)
                        return
                    }*/

        if (TransactionPopup.self.state.nextTask.options.length === 1) {
            console.log(TransactionPopup.self.state.nextTask.options[0])
            this.setState({
                selectedTask: TransactionPopup.self.state.nextTask.options[0]
            })
        }

        setTimeout(() => {
            let txDefId = this.state.selectedTask.taskDefinitionId
            let txDefName = this.state.selectedTask.taskName
            console.log(txDefId, "txname", txDefName)
            if (txDefName) {
                //check comments from instruction to officer screen
                let comments=ReviewPane.self.state.comments;
                const  commentLngth=comments.length;
                if(commentLngth>1){
                    console.log("comment to save",comments)
                    try {
                        let tempArray = [ ...comments];
                        Wait.show("Saving...Instruction")
                        let commentToSave={
                            transactionFk:TransactionPopup.id(),
                            taskId:TransactionPopup.taskId(),
                            taskName:TransactionPopup.task(),
                            comment:comments,
                            userName:sessionStorage.getItem('username')
                        }
                        TxCreateService.addComment(commentToSave).then(d=>{

                        })
                    }catch (e){
                        console.log(e)
                    }
                }
                Wait.show("Completing Task....")
                TxCreateService.completeTask({
                    userId: uid,
                    taskDefinitionId: txDefId,
                    taskName: txDefName,
                    txId: TransactionPopup.id()
                }).then(r => {
                    TransactionPopup.setState("currentTabId", 0)
                    Wait.dismiss()
                    PopDetail.dismiss()
                    TransactionTable.loadData()
                    TransactionPopup.dismiss()
                })
            } else {
                alert('Select Next Task')
            }

        }, 100)
    }
}
