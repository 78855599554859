import React, {useState, useEffect} from "react";
import Header from "./header";
import EditCondition from "./editCondition";
import AddCondition from "./AddCondition";
import LoadCondition from "./loadCondition";
import PopDetail from "../../../popdetail";
import TxReadService from "../../../../../services/transaction/transaction_read";
import TransactionPopup from "../../transaction_popup";



export default class  Conditions extends React.Component{
    static self = null
    constructor(props) {
        super(props);

        this.state={
            conditionsToshow:[]
        }
        Conditions.self = this
    }
    componentDidMount() {

        this.setState({conditionsToshow:[
                {number:'(i)',
                category:'Main',
                type:'Permanent',
                endDate:'2110-01-21',
                    condition:'Hutakiwi  kuuza ndani ya miaka 25'}]})
        TxReadService.getConditions(TransactionPopup.id()).then(r=>{
            if(r){
            this.setState({conditionsToshow:r.data.result})
        }})
    }

    render() {


        return (
            <div className={"row"}>
                <Header display={true} label={'Conditions'}>
                    <div>
                        <i
                            className={'fa fa-plus-circle'}
                            style={{color: 'tomato', fontSize: '25px'}}
                            onClick={() => {
                                PopDetail.show("New Condition", <AddCondition/>)
                            }}/> <b>New</b>
                    </div>
                    <div>
                        <i
                            className={'fa fa-edit'}
                            style={{color: 'tomato', fontSize: '25px'}}
                            onClick={() => {
                                PopDetail.show("Edit Condition", <EditCondition/>)
                            }}/> <b>Edit</b>
                    </div>
                    <div>
                        <i
                            className={'fa fa-trash'}
                            style={{color: 'tomato', fontSize: '25px'}}/> <b>Remove</b>
                    </div>
                    <div>
                        <i
                            className={'fa fa-book'}
                            style={{color: 'tomato', fontSize: '25px'}}
                            onClick={() => {
                                PopDetail.show("Standard Condition", <LoadCondition/>)
                            }}/><b> Load</b>
                    </div>
                    <div>
                        <i
                            className={'fa fa-paperclip'}
                            style={{color: 'tomato', fontSize: '25px'}}/><b>Attach</b>
                    </div>
                    <div>
                        <i
                            className={'fa fa-paperclip'}
                            style={{color: 'tomato', fontSize: '25px'}}/><b>Detach</b>
                    </div>
                </Header>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>NO.</th>
                        <th>TYPE</th>
                        <th>CONDITION</th>
                        <th>END DATE</th>
                        <th>CATEGORY</th>
                        <th>DEPARTMENT</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.conditionsToshow.map((d,i)=>{
                            return(<tr  key={i}>
                                <td>{d.number}</td>
                                <td>{d.type}</td>
                                <td>{d.condition}</td>
                                <td>{d.endDate}</td>
                                <td>{d.category}</td>
                                <td>{d.number}</td>
                            </tr>)
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }

}








