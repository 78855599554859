import API from "../API";

export default class CommonService {

    static readDictionary(category, sub_category, parent_id = 0) {
        if (parent_id === 0) {
            return API.ax.get(`mobile/read/project-outputs/${category}/${sub_category}`)
                .catch((e) => { console.log(e); })
        }
        return API.ax.get(`mobile/read/project-outputs/${category}/${sub_category}/${parent_id}`)
            .catch((e) => { console.log(e); })
    }

    static readDictionaryBy(category, sub_category, column, value) {
        return API.ax.get(`mobile/read/translation/${category}/${sub_category}/${column}/${value}`)
            .catch((e) => { console.log(e); })
    }
    static readStage(stage, transactionId) {
        return API.ax.get(`common/form/read/stages/${transactionId}/${stage}`);
    }





    static updateStage(param) { return API.ax.put(`mobile/parcel/update/stage`, param).catch((e) => { console.log(e); }) }
}
