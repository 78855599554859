import * as React from "react";
import PopDetail from "../../popdetail";
import MobilePopup from "../mobile_popup";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import Wait from "../../wait";
import MobileProjectLayerPane from "../screens/project_layers";
import MobileReadService from "../../../../services/mobile/mobile_read";
import Message from "../../transacrions/screen/gro/person/message";
import PopDetailsTwo from "../../PopDetailsTwo";
import CommonCreateService from "../../../../services/common/common_create";
import SpatialPreviewPane from "../../../../services/ugshp/SpatialPreviewPane";
import {parseZip} from "shpjs";

export default class PanAddProjectLayer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            column:'',
            bound:false,
            is_ccro:false,//MobilePopup.project().outputName!=='Residential License',
            districts:[],
            wards:[]
        }



        this.uploadFile = this.uploadFile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.loadLocality = this.loadLocality.bind(this)
        this.loadWards = this.loadWards.bind(this)
        this.previewSpatial = this.previewSpatial.bind(this)

    }
    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}
    handleChangeFile=e=>{this.setState({ coordinate:e.target.files[0] })}

    componentDidMount() {
        this.loadLocality()

    }

    loadWards(){
        let dist = document.getElementById('sp_dist').value
        try {
            Wait.show("Loading Wards...")
            MobileReadService.listWards(dist,MobilePopup.id()).then(r => {
                Wait.dismiss()
                try {
                    this.setState({
                        wards:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>

                    <div className={'col col-sm-12'}>
                        <table style={{width:'100%'}}>
                            <tbody>

                            <tr style={{color:'#008b8b'}}>
                                <td>* District Council</td>
                                <td>* Ward / Village</td>
                            </tr>
                            <tr>
                                <td>
                                    <select style={{width:'100%'}} onChange={this.loadWards} id={'sp_dist'}>
                                        <option value={0}>- Choose District -</option>
                                        {this.state.districts.map((d,i)=>{
                                            return(<option key={'dist_'+i} value={d.id}>{d.name}</option>)
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select style={{width:'100%'}}  onChange={this.handleChange} name={'ward_id'}>
                                        <option value={0}>- Choose Ward / Village -</option>
                                        {this.state.wards.map((d,i)=>{
                                            return(<option key={'ward_'+i} value={d.id}>{d.name}</option>)
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr style={{color:'#008b8b'}}>
                                <td>* Layer Type</td>
                                <td>* Layer Name</td>
                            </tr>
                            <tr>
                                <td>
                                    <select name={'bound'} onChange={this.handleChange} style={{width:'100%'}}>
                                        <option value={false}>- Select Layer Type -</option>
                                        <optgroup label={'Project Boundaries'}>
                                            <option value={true}>- Street / Village Boundary</option>
                                        </optgroup>
                                        <optgroup label={'Project Feature'}>
                                            <option value={false}>- Land Use</option>
                                            <option value={false}>- Upimaji</option>
                                            <option value={false}>- Others</option>
                                        </optgroup>
                                    </select>
                                </td>
                                <td>
                                    <input style={{width:'100%'}} name={'name'} onChange={this.handleChange}/>
                                </td>
                            </tr>

                            <tr style={{color:'#008b8b'}}>
                                <td>* Layer Permission</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <select name={'allowed'} onChange={this.handleChange} style={{width:'100%'}}>
                                        <option value={false}>- Select Permission -</option>
                                        <option value={true}>Allowed</option>
                                        <option value={false}>Restricted</option>
                                    </select>
                                </td>
                            </tr>

                            <tr style={{color:'#008b8b'}}>
                                <td>*Shapefile</td>
                                <td>* Shapefile <b style={{color:'#f00'}}>Attribute Name</b> As Reference</td>
                            </tr>
                            <tr>
                                <td>
                                    <input type={'file'} name={'coordinate'}
                                           onChange={this.handleChangeFile}
                                           accept={".zip,.ZIP"}
                                           style={{width:'100%'}}/>
                                </td>
                                <td>
                                    <input style={{width:'100%'}} name={'column'} onChange={this.handleChange}
                                    placeholder={'Shapefile column name to read...'} required={true}/>
                                </td>
                            </tr>

                            <tr style={{display:this.state.is_ccro?'table-row':'none',color:'#008b8b'}}>
                                <td colSpan={2}>Hamlets</td>
                            </tr>
                            <tr style={{display:this.state.is_ccro?'table-row':'none'}}>
                                <td colSpan={2}>
                                    <textarea style={{width:'100%'}} placeholder={'Eg : Kitongoji A, Kitongoji B, Kitongoji C'} name={'hamlets'}  onChange={this.handleChange}/>
                                </td>
                            </tr>

                            </tbody>
                        </table>


                    </div>

                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={()=>this.uploadFile()}>Upload</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }


    previewSpatial(){

        if (this.state.column===null||this.state.column===undefined){
            alert('No Shapefile Column Name')
            return;
        }
        if (this.state.coordinate === undefined){
            alert('No Shapefile')
            return
        }



        const formData = new FormData()
        formData.append('coordinate',this.state.coordinate)
        formData.append('extra', JSON.stringify({
            layerName:'mob_layers'
        }))

        Wait.show('Validating.....')
        CommonCreateService.createPreviewSHP(formData).then(r => {
            Wait.dismiss()
            try {
                let reader = new FileReader()
                reader.onloadend = (e)=>{
                   try {
                       PopDetailsTwo.show('Preview Layer',
                           <SpatialPreviewPane  json={parseZip(e.target.result)}
                                               label={'Name'}
                                               validator={r.data}
                                               layer_name={'mob_layers'}
                                               upload={this.uploadFile}
                                               shp_columns={[this.state.column]}
                                                ispop2={true}
                           />)
                   }catch (e) {
                       console.log(e)
                   }
                }
                reader.onError =e=>{

                }

                reader.readAsArrayBuffer(this.state.coordinate)
            }catch (e) {
                console.log(e)
            }
        })

    }



    uploadFile(){
        try {
            console.log(MobilePopup.state())
            let prj_id = MobilePopup.id()
            let name = this.state.name
            let column = this.state.column
            let bound = this.state.bound
            let allowed = this.state.allowed
            let ward_id = this.state.ward_id
            let hamlets = this.state.hamlets
            if (name.length<1){
                PopDetailsTwo.show("Warning",<Message faIcon={'harzad'} message={'No Layer Name'}/>,30)
                return
            }
            if (bound===0){
                PopDetailsTwo.show("Warning",<Message faIcon={'harzad'} message={'No Layer Type'} action={1}/>,30)
                return
            }
            if (column.length<1){
                PopDetailsTwo.show("Warning",<Message faIcon={'harzad'} message={'No Shapefile Column as Reference'} action={1}/>,30)
                return
            }
            if (this.state.coordinate===undefined){
                PopDetailsTwo.show("Warning",<Message faIcon={'harzad'} message={'No Shapefile'} action={1}/>,30)
                return;
            }
            if (ward_id<1){
                PopDetailsTwo.show("Warning",<Message faIcon={'harzad'} message={'No Ward'} action={1}/>,30)
                return;
            }
            Wait.show("Saving...")
            const formData = new FormData()
            formData.append('coordinate',this.state.coordinate)
            formData.append('extra',JSON.stringify({
                project_id:prj_id,
                layerName:name,
                refColumn:column,
                bound:bound,
                ward_id:ward_id,
                hamlets:hamlets,
                allowed:allowed
            }))
            MobileCreateService.createLayer(formData).then(d=>{
                Wait.dismiss()
                console.log(d.data)

                if (d.data.status===0){
                    alert(d.data.message)
                    return
                }
                MobileProjectLayerPane.loadData()
                PopDetailsTwo.dismiss()

                PopDetail.dismiss()
            })
        }catch (e){
            console.log(e)
        }
    }

    loadLocality() {
        try {
            Wait.show("Loading Localities...")
            MobileReadService.listLocality(MobilePopup.id()).then(r => {
                Wait.dismiss()
                try {
                    this.setState({
                        districts:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
}