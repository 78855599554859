import * as React from "react";
import PopDetail from "../../../popdetail";
import Wait from "../../../wait";
import ValCreateService from "../../../../../services/valuation/val_create";
import TransactionPopup from "../../transaction_popup";


export default class AddValuationRateCSVPane extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            parcel:null,
            building:null,
            point:null,
            contours:null,
            road:null,
            power:null,
            water:null,
            railways:null,
            pond:null,
            rates:[],
            csv:null
        }
        this.saveValuationRate = this.saveValuationRate.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
    }
    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }
    handleChangeFile=e=>{
        this.setState({ [e.target.name]:e.target.files[0] })
    }
    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>

                    <div className={'col col-sm-12'}>

                        <table style={{width:'100%'}}>
                            <tbody>


                           {/* <tr>
                                <td colSpan={3}>* Valuation Brock </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <select style={{width:'100%'}} name={'block'} onChange={this.handleChange}>
                                        <option value={''}>- Select Valuation Block -</option>
                                        {this.props.blocks.map((d,i)=>{
                                            return(<option>{d.blockId}</option>)
                                        })}
                                    </select>
                                </td>
                            </tr>*/}
                            <tr>
                                <td colSpan={3}>* CSV Rates </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <input type={'file'} style={{width:'100%'}} accept={".csv,.CSV,.excel,.EXCEL"}
                                           name={'csv'} onChange={this.handleChangeFile}/>
                                </td>
                            </tr>

                            </tbody>
                        </table>


                    </div>

                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.saveValuationRate}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }


    saveValuationRate(){
        try {
            if(this.state.csv===undefined){
                alert('No CSV File')
                return
            }

            Wait.show("Saving...")
            const formData = new FormData()
            formData.append('file',this.state.csv)
            formData.append('block',this.state.block)
            formData.append('transactionId',TransactionPopup.id())

            ValCreateService.uploadValuationCSVRates(formData).then(d=>{
                Wait.dismiss()
                try {
                    if (d.data.status===0){
                        alert(d.data.message)
                        return
                    }
                    PopDetail.dismiss()
                }catch (e) {
                    console.log(e)
                }

            })

        }catch (e){
            Wait.dismiss()
            console.log(e)
        }
    }

}
