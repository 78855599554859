import * as React from "react";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import TransactionPopup from "../../../popups/transacrions/transaction_popup";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";

export default class ProjectUsersConfig extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
            users_config:[],
            village_config:[]
        }

        this.saveUser = this.saveUser.bind(this)
        this.loadData = this.loadData.bind(this)
        this.loadVillageConfig = this.loadVillageConfig.bind(this)
        this.setTabAt = this.setTabAt.bind(this)
    }

    componentDidMount() {
        this.loadData()
        this.loadVillageConfig()
        this.setTabAt(0)
    }

    saveUser(){
        let fullname = document.getElementById("txt_name").value
        let phone = document.getElementById("txt_phone").value
        let username = document.getElementById("txt_username").value
        let draw_poly = document.getElementById("chk_draw_poly").checked
        let upload = document.getElementById("chk_upload").checked
        let expire = document.getElementById("txt_expire").value
        let village_id = document.getElementById('sp_village').value

        try {
            MobileCreateService.createUser({
                mobile_project:this.props.data.ref_id,
                village_config:village_id,
                fullname:fullname,
                phone:phone,
                username:username,
                draw_poly:draw_poly,
                upload:upload,
                expire:expire
            }).then(r=>{
                try {
                    document.getElementById("txt_name").value = ""
                    document.getElementById("txt_phone").value = ""
                    document.getElementById("txt_username").value = ""
                    this.toggleFilters()
                    this.loadData()
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    toggleFilters(){
        let div = document.getElementById('div-filter')
        div.style.display = div.style.display === 'none'?'inherit':'none'
    }

    toggleCheck(i){

    }

    setTabAt(tab) {
        this.setState({tab: tab})
    }


    render() {
        return(<div style={{overflow:'hidden',height:'100%',marginRight:'16px'}}>

            <div className={'row'} style={{boxShadow:'0 4px 4px #aaa',padding:'8px 16px',backgroundColor:'#efefef'}}>
                <div className={'col col-sm-8'}>
                    <span className={'fa fa fa-bars'} style={{cursor:'pointer', marginTop:'8px'}} onClick={()=>this.toggleFilters()}/>
                    <input style={{width:'85%',marginLeft:'24px',backgroundColor:'#fff'}} placeholder={'Search here...'}/>
                    <span className={'fa fa fa-plus-circle'} style={{color:'darkcyan', float:'right',cursor:'pointer', margin:'8px'}} onClick={()=>this.toggleFilters()}/>
                </div>
                <div className={'col col-sm-4 fa-btn'}>
                    <button style={{float:'right',width:'80px'}} onClick={()=>TransactionPopup.dismiss()}>Close</button>
                    <button style={{float:'right',width:'80px'}} onClick={()=>this.loadData(0)} className={'refresh'}>Refresh</button>
                    <span className={'fa fa-check'} title={'Approve'} style={{color:'darkcyan', marginRight:'24px'}}/>
                    <span className={'fa fa-times'} title={'Reject'} style={{color:'#DF4A36'}}/>
                    <span className={'fa fa-pencil'} title={'Edit'}/>
                    {/*<span className={'fa fa-trash'} title={'Delete'} style={{color:'#DF4A36'}}/>*/}
                </div>

            </div>

            <div className={'col col-sm-9'} style={{border:'0.5px solid #ccc',display:'none'}} id={'div-filter'}>
                <br/>

                <table style={{width:'100%'}}>
                    <tbody>
                     <tr>
                        <td><label>Fullname : </label></td>
                        <td><input id={'txt_name'} style={{width:'100%'}} type={'text'}/></td>
                     </tr>
                     <tr>
                        <td><label>Phone Number : </label></td>
                        <td><input id={'txt_phone'} style={{width:'100%'}} type={'text'}/></td>
                     </tr>
                     <tr>
                        <td><label>Username : </label></td>
                        <td><input id={'txt_username'} style={{width:'100%'}} type={'text'}/></td>
                     </tr>
                     <tr style={{height:'2vh'}}/>
                     <tr>
                        <td style={{verticalAlign:'top'}}><label>User Permission : </label></td>
                        <td>
                             <input id={'chk_draw_poly'} type={'checkbox'} style={{marginRight:'8px'}}/><label>Can Draw Polygon</label><br/>
                             <input id={'chk_upload'} type={'checkbox'} style={{marginRight:'8px'}}/><label>Can Upload Data</label><br/>
                        </td>
                     </tr>
                     <tr style={{height:'2vh'}}/>
                     <tr>
                        <td><label>Current Village : </label></td>
                        <td>
                             <select id={'sp_village'} className={'select'}>
                                <option>Select Village</option>
                                 {this.state.village_config.map((d,i)=>{
                                     return(<option key={i} value={d.id}>{d.name}</option>)
                                 })}
                            </select>
                        </td>
                     </tr>

                    <tr>
                        <td><label>Expire Date : </label></td>
                        <td>
                            <input id={'txt_expire'} type={'date'}/>
                        </td>
                     </tr>

                    </tbody>
                </table>
                <hr/>
                <button onClick={()=>this.saveQuest()} className={'default'} style={{float:'left' , width:'48%',marginRight:'1%'}}>Save</button>
                <button onClick={()=>this.toggleFilters()}
                    className={'default'} style={{float:'left', width:'48%'}}>Close</button>

                <br/>

                <hr/>


                <div style={{textAlign:'center', padding:'8px 0 16px 0'}}>
                    <button className={'default'} style={{width:'100px'}} onClick={()=>this.saveQuest()}>Submit</button>
                    <button className={'default'} style={{width:'100px'}} onClick={()=>this.toggleFilters()}>Close</button>
                </div>

            </div>

            <div className={'row'} style={{marginTop:'1vh'}}>
                <div className={'col col-sm-12'}>
                    <div className={this.state.tab===0?'tab tab-active':'tab'} onClick={()=>this.setTabAt(0)}>
                        <span className={'fa fa-list'}/> Users
                    </div>
                    <div className={this.state.tab===1?'tab tab-active':'tab'} onClick={()=>this.setTabAt(1)}>
                        <span className={'fa fa-map'}/> Teams
                    </div>
                    {/*<div className={this.state.tab===2?'tab tab-active':'tab'} onClick={()=>this.setTabAt(2)}>
                        <span className={'fa fa-bar-chart'}/> Statistic
                    </div>*/}

                </div>
            </div>

            <div style={{width:'100%',height:'100%', overflow:'auto'}}>
                <table className={'tb'} style={{border:'1px solid #ccc',width:'98%',margin:'1%'}}>
                    <thead>
                        <tr>
                            <th style={{textAlign:'left',paddingLeft:'8px'}}>
                                <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                            </th>
                            <th>USER</th>
                            <th>FULL NAME</th>
                            <th>CAN DRAW</th>
                            <th>CAN UPLOAD</th>
                            <th>CURRENT VILLAGE</th>
                            <th>TOTAL PARCEL</th>
                            <th style={{textAlign:'right'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.users_config.map((d,i)=>{
                        return(<tr key={i}>
                            <td>
                                <img id={'chk_'+i} alt={'chk_'+i}  style={{float:'left'}}
                                 src={uncheck} onClick={()=>this.toggleCheck(i)}/>
                                <span style={{float:'right'}}>
                                    {i+1}.
                                </span>

                            </td>
                            <td>{d.username}</td>
                            <td>{d.name}</td>
                            <td style={{textAlign:'center'}}>
                                <input type={'checkbox'} checked={d.can_draw} readOnly={true}/>
                            </td>
                            <td style={{textAlign:'center'}}>
                                <input type={'checkbox'} checked={d.can_upload} readOnly={true}/>
                            </td>
                            <td>{d.village}</td>
                            <td>{d.total_parcel}</td>
                            <td style={{textAlign:'right'}}>
                                <button style={{width:'50px'}} className={'default'}>Edit</button>
                                <button style={{width:'50px'}} className={'default'}>Delete</button>
                            </td>
                        </tr>)
                    })}


                    </tbody>

                </table>
            </div>

        </div>)
    }

     loadData(){
        try {
            MobileReadService.readUsers(this.props.data.ref_id).then(result=>{
                try {
                    console.log(result.data.results)
                    this.setState({
                        users_config:result.data.result
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    loadVillageConfig(){
        try {
            MobileReadService.readVillageConfig(this.props.data.ref_id).then(r=>{
                try {
                    //console.log(r.services.results)
                    this.setState({
                        village_config:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

}