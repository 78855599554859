import React, { useEffect, useState } from "react";
import Select from "react-select";
import API from "../services/API";
import MobileReadService from "../services/mobile/mobile_read";
import Wait from "../components/popups/wait";
import MobilePopup from "../components/popups/mobile/mobile_popup";
const colourStyles = {
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#DF4A3622" : null,
            color: 'black'
        };
    },
    control: (base) => ({
        ...base,
        "&:hover": {
            borderColor: "tomato"
        },
        borderColor: "grey",
        boxShadow: 'none'

    })
};

const GeneralSelect = ({ parameters, onChanges, defaultValue, isMulti = false }) => {
    const [selectedOption] = useState()
    const [placeholder, setPlaceholder] = useState('')
    const [name, setName] = useState('')
    const [value, valueSet] = useState([])
    const [options, setOptions] = useState([
        {
            value: '',
            label: ''
        }
    ])

    useEffect(() => {

        let ops = []
        setPlaceholder(parameters.placeholder)
        setName(parameters.value)
        if (parameters.hasOwnProperty("endpoint")) {
            API.ax.get(parameters.endpoint)
                .then(response => {
                    console.log("data", response.data)
                    if (response.data.status === 1) {
                        response.data.result.forEach(data => {
                            ops.push({ value: data[parameters.value], label: data[parameters.label] })
                        })
                        // setOptions(ops)
                        // for editing
                        //let value = options.filter(opt => opt.value === prop);
                        //defaultValue={value}
                        //valueSet(defaultValue?options.filter(opt => opt.label === defaultValue):value)
                    } else {
                        console.log("status is expected")
                    }

                })
                .catch(error => {
                    console.error(error)
                })
            if (parameters.endpoint.includes("user")) {
                // console.log(options)
                // MobileReadService.readUsers(MobilePopup.id(), "all").then(r => {
                //     let u = []
                //     u = options;
                //     r.data.result.map((prop, key) => {
                //         let ix = { value: prop.id, label: prop.username }
                //         ops.push(ix);
                //     })
                //     console.log(u)
                //     try {

                //     } catch (e) {
                //         console.log(e)
                //     }
                // })
                setOptions(ops)
            }
            else {
                setOptions(ops)
            }
        }
    }, [parameters, parameters.endpoint])
    useEffect(() => {
        let defaultsValue = defaultValue ? options.findIndex(opt => opt.value === defaultValue) : [];
        valueSet([options[defaultsValue]])
    }, [options, defaultValue])
    return (
        <Select readOnly={true}
            id={name}
            defalutValue={value}
            name={name}
            isMulti={isMulti}
            value={selectedOption}
            onChange={onChanges}
            options={options}
            placeholder={placeholder}
            styles={colourStyles}
        />
    )
}
export default GeneralSelect
