import * as React from "react";
import PopDetail from "../../../popdetail";
import Wait from "../../../wait";
import TransactionPopup from "../../transaction_popup";
import TPCreateService from "../../../../../services/tp/tp_create";
import SMDCreateService from "../../../../../services/smd/smd_create";
import SpatialUnitScreen from "../spatial-unit/spatial_unit";
import TpSpatialUnitPane from "../spatial-unit/tp_spatial_unit";
import SurveyInstructionPane from "../spatial-unit/surver_instruction";
import SpatialPreviewPane from "../../../../../services/ugshp/SpatialPreviewPane";
import {parseZip} from "shpjs";
import CommonCreateService from "../../../../../services/common/common_create";


import "leaflet.utm";
import API from "../../../../../services/API";

export default class UploadSpatialAmendPane extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            parcel:null,
            building:null,
            point:null,
            contours:null,
            road:null,
            power:null,
            water:null,
            railways:null,
            pond:null,
            is_utm:'hidden',
            layer_type:''
        }
        this.saveCooridates = this.saveCooridates.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.previewSpatia = this.previewSpatia.bind(this)
        this.putInQueue = this.putInQueue.bind(this)
    }

    handleChange= e =>{

        if (e.target.name==='dataset') {
            let is_utm = (e.target.value === 'EPSG:4326') ? 'hidden' : 'visible'

            this.setState({ is_utm: is_utm });
        }

        this.setState({[e.target.name]: e.target.value});
    }

    handleChangeFile=e=>{
        this.setState({ [e.target.name]:e.target.files[0] })
    }

    previewSpatia(){


        if (this.state.coordinate===undefined){
            alert('No Coordinates')
            return
        }

        const formData = new FormData()
        formData.append('coordinate',this.state.coordinate)
        formData.append('extra', JSON.stringify({
            layerName:this.props.reference,
            purpose:'amend'
        }))

        Wait.show('Validating.....')
        CommonCreateService.createPreviewSHP(formData).then(r => {
            Wait.dismiss()
            try {
                let reader = new FileReader()
                reader.onloadend = (e)=>{

                    let label = 'tp_number'

                    let cols =this.props.reference==='rough_coordinates'?[]:
                        this.props.reference==='smd_spatial'?['reg_pn','block_num','plot_num','cal_area','locality','district',
                                'region','la_zone','digit_user','tp_no','lot_type','unit','id_sp']:
                            ['basemap','mtaa','tp_firm','tp_obtaine','tp_number','locality','block','revision',
                                'use','lot_type','use_group','use_class','council']


                    if (this.props.reference==='smd_spatial'){
                        label = 'plot_num'
                        if (this.state.layer_type==='Point Features'){
                            cols = ['station','x','y']
                            label = 'station'
                        }
                    }
                    if(TransactionPopup.transaction().name==='First Parcel Registration'){cols = []}

                    PopDetail.show('Preview Shapefile',
                        <SpatialPreviewPane json={parseZip(e.target.result)}
                                            label={label}
                                            validator={r.data}
                                            layer_name={this.props.reference}
                                            upload={()=>this.saveCooridates(r.data.lst_affected)}
                                            shp_columns={cols}
                        />)
                }
                reader.onError =e=>{}
                reader.readAsArrayBuffer(this.state.coordinate)



            }catch (e) {
                console.log(e)
            }
        })

    }


    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>

                    <div className={'col col-sm-12'}>

                        <table style={{width:'100%'}}>
                            <tbody>

                            {/*<tr>
                                <td style={{color:'#008b8b'}} colSpan={2}>* Amend Spatial Reference</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <select name={'reference'} style={{width:'100%'}} onChange={this.handleChange}>
                                        <option value={''}>- Select One -</option>
                                        <option value={'smd_spatial'}>Survey</option>
                                        <option value={'tp_spatial_unit'}>TP</option>
                                    </select>
                                </td>
                            </tr>*/}
                            <tr>
                                <td style={{color:'#008b8b'}}>* Dataset</td>
                                <td style={{color:'#008b8b'}}>* File Format</td>
                            </tr>
                            <tr>
                                <td>
                                    <select name={'dataset'} style={{width:'100%'}} onChange={this.handleChange}>
                                        <option value={'EPSG:4326'}>- Select One -</option>
                                        {/*<option value={'EPSG:210'}>ARC 1960 - UTM (EPSG:210)</option>*/}
                                        <option value={'EPSG:327'}>TARIF 11 - UTM (EPSG:327)</option>
                                       {/* <option value={'EPSG:4326'}>WGS 1984 - LAT LONG (EPSG:4326)</option>*/}
                                        {/*<option>UTM</option>*/}
                                    </select>
                                </td>
                                <td>
                                    <select style={{width:'100%'}} name={'format'} onChange={this.handleChange}>
                                        <option value={'Shapefile'}>- Select One -</option>
                                        <option>Shapefile</option>
                                        <option>CSV</option>
                                    </select>
                                </td>
                            </tr>
                            <tr style={{visibility:this.state.is_utm}}>
                                <td style={{color:'#008b8b'}}>* Data Zone</td>
                                <td style={{color:'#008b8b'}}>* Data Band</td>
                            </tr>
                            <tr style={{visibility:this.state.is_utm}}>

                                <td>
                                    <select style={{width:'100%'}} name={'zone'} onChange={this.handleChange}>
                                        <option>- Choose One -</option>
                                        <option>35</option>
                                        <option>36</option>
                                        <option>37</option>
                                    </select>
                                </td>
                                <td>
                                    <select style={{width:'100%'}} name={'band'} onChange={this.handleChange}>
                                        <option>- Choose One -</option>
                                        <option>L</option>
                                        <option>M</option>
                                    </select>
                                </td>
                            </tr>

                            <tr style={{height:'4vh'}}/>

                            <tr>
                                <td style={{color:'#008b8b',display:this.props.reference==='rough_coordinates'?'none':'inherit'}}>* Layer Type</td>
                                <td style={{color:'#008b8b'}}>* Shapefile</td>
                            </tr>
                            <tr>
                                <td style={{display:this.props.reference==='rough_coordinates'?'none':'inherit'}}>
                                    <select name={'layer_type'} style={{width:'100%'}} onChange={this.handleChange}>
                                        <option value={''}>-Choose One-</option>
                                        <option>Parcels</option>
                                        <option>Buildings</option>
                                        <option>Contours</option>
                                        <option>Roads</option>
                                        <option>Railways</option>
                                        <option>Power Line</option>
                                        <option>Water Line</option>
                                        <option>Point Features</option>
                                        <option>Ponds</option>
                                    </select>
                                </td>
                                <td>
                                    <input type={'file'} style={{width:'100%'}} accept={".zip,.ZIP"} name={'coordinate'} onChange={this.handleChangeFile}/>
                                </td>
                            </tr>



                            </tbody>
                        </table>

                    </div>


                    {/*<div id={'map2'} style={{width:'100%',height:'400px'}}>

                    </div>*/}
                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.previewSpatia}>Preview</button>
                {/*<button style={{width:'100px'}} onClick={this.putInQueue}>Queue</button>*/}
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }


    putInQueue(){

        let txNo = TransactionPopup.id()





        const formData = new FormData()
        formData.append('file',this.state.coordinate)
        formData.append('layer_type',this.state.layer_type)
        formData.append('srid','37236')
        formData.append('transaction_id',txNo)

        /*Wait.show('Queueing.....')
        CommonCreateService.createQueue(formData).then(r => {
            Wait.dismiss()
            PopDetail.dismiss()
        })*/

        try {
            Wait.show('Queueing.....')
            API.ramani.post('queue',formData).then(r => {
                try {
                    console.log(r.data)
                    Wait.dismiss()
                    PopDetail.dismiss()
                    SpatialUnitScreen.loadData()
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }


    }
    saveCooridates(lst_affected){
        try {

            if (this.props.reference!=='rough_coordinates'){
                if (this.state.layer_type===''){
                    alert('No Layer Type')
                    return
                }
            }


            if(this.state.coordinate ===undefined){
                alert('No Coordinates')
                return
            }

            const formData = new FormData()
            formData.append('coordinate',this.state.coordinate)

            formData.append('extra',JSON.stringify({
                transactionId:TransactionPopup.id(),
                layerType:this.state.layer_type,
                lstAffected:lst_affected,
                purpose:'amend'
            }))

            Wait.show("Saving...")

            if (this.props.reference==='smd_spatial'){

                SMDCreateService.uploadSpatial(formData).then(d=>{
                    Wait.dismiss()
                    try {
                        let data = d.data
                        if (data.status===0){
                            alert(data.message)
                            return
                        }
                    }catch (e){console.log(e)}
                    PopDetail.dismiss()
                    SpatialUnitScreen.loadData()
                })
            }
            else{
                TPCreateService.uploadTpLayer(formData).then(d=>{
                    Wait.dismiss()
                    let data = d.data
                    if (data.status===0){
                        alert(data.message)
                        return
                    }
                    PopDetail.dismiss()
                    TpSpatialUnitPane.loadData()

                })
            }
        }catch (e){
            Wait.dismiss()
            console.log(e)
        }
    }

}
