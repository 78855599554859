import PopDetail from "../../../popdetail";
import React from "react";

export default class EditCondition /*= ({handleClose}) =>*/ extends React.Component{
constructor() {
    super();

    this.handleSave =this.handleSave.bind(this)
}
     handleSave = () =>{
        //save, but kinda think to move this to the parent component
        PopDetail.dismiss()
    }
    render() {
        return (
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col col-sm-6 form-group'}>
                        <label htmlFor={'number'}>* Number</label>
                        <input name={'number'} id={'number'} className={'form-control'}/>
                    </div>
                    <div className={'col col-sm-6 form-group'}>
                        <label htmlFor={'category'}>* Category</label>
                        <select
                            name={'category'}
                            id={'category'}
                            className={'form-control'}
                        >
                            <option value={''}> --Select Type--</option>
                        </select>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col col-sm-6 form-group'}>
                        <label htmlFor={'type'}>* Type</label>
                        <select
                            name={'type'}
                            id={'type'}
                            className={'form-control'}
                        >
                            <option value={''}> --Select Type--</option>
                        </select>
                    </div>
                    <div className={'col col-sm-6 form-group'}>
                        <label htmlFor={'date'}> End Date</label>
                        <input
                            name={'endDate'}
                            id={'endDate'}
                            type={'date'}
                            className={'form-control'}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col col-sm-12 form-group'}>
                        <label htmlFor={'condition'}>* Condition</label>
                        <textarea
                            name={'condition'}
                            id={'condition'}
                            className={'form-control'}
                            rows={7}
                            style={{resize: 'none'}}
                        />
                    </div>
                </div>
                <div className={'form-inline'}>
                    <button onClick={this.handleSave}>Save</button>
                    <button onClick={this.props.handleClose}>Cancel</button>
                </div>
            </div>
        )
    }

}
