import React from "react";
import MobileCreateService from "../../../../../services/mobile/mobile_create";
import Wait from "../../../wait";
import MobilePopup from "../../mobile_popup";
import GeneralSelect from "../../../../../utils/GeneralSelect";
import MobileReadService from "../../../../../services/mobile/mobile_read";

const partiesOptions = {
    'endpoint': 'user/users',
    'value': 'userId',
    'label': 'userName',
    'placeholder': 'Select User/s'
}

export default class ProjectUserMGR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            lst_users: [],
            screens: ['Summary', 'Project Layers', 'Project Users', 'Mobile Data','Generated plots', 'Surveys','TP Drawings', 'Data Processing',
                'Processing Report', 'Project Admin']
        }
        this.handleChange = this.handleChange.bind(this)
        this.generalSelect = this.generalSelect.bind(this)
        this.updateRoles = this.updateRoles.bind(this)
        this.loadUsers = this.loadUsers.bind(this)
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        this.loadUsers()
    }

    loadUsers() {
        Wait.show('Loading Roles...')
        MobileReadService.loadUsers(MobilePopup.id()).then(r => {
            try {
                Wait.dismiss()
                this.setState({
                    users: r.data.results
                })
            } catch (e) {
                console.log(e)
            }
        })

    }

    updateRoles() {
        let arr = []
        let users = []
        this.state.screens.forEach(d => {
            if (document.getElementById('chk_' + d).checked) {
                arr.push(d)
            }
        })

        this.state.lst_users.forEach(d => {
            users.push(d.value)
        })
        Wait.show('Saving...')
        MobileCreateService.createRoles({
            project_id: MobilePopup.id(),
            roles: arr,
            users: users
        }).then(r => {
            Wait.dismiss();
            document.getElementById('div_add_user').style.display = 'none'
            this.loadUsers()
        })
    }

    generalSelect(arr) {
        this.setState({
            lst_users: arr
        })
    }

    deleteRole(d) {

    }

    render() {
        return (<div style={{ padding: '24px', width: '100%' }}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr style={{ border: '1px solid #cccccc', background: '#efefef' }}>
                        <td style={{ paddingLeft: '24px' }}><b>Project Users :</b></td>
                        <td style={{ height: '48px' }}>

                        </td>

                        <td style={{ paddingLeft: '24px' }}><b>District Code :</b></td>
                        <td>

                        </td>
                        <td style={{ paddingLeft: '24px' }}><b>District Total Parcels :</b></td>
                        <td>
                            <button style={{ float: 'right', marginRight: '16px' }} onClick={() => document.getElementById('div_add_user').style.display = 'table-cell'}>Add/Update Role</button>
                        </td>
                    </tr>
                    <tr id={'div_add_user'} style={{ display: 'none' }}>
                        <td colSpan={6}>
                            <div style={{ width: '93%', padding: '24px', zIndex: 4, backgroundColor: '#fcfcfc', position: 'absolute', left: '40px', border: '1px solid #ccc' }}>
                                <label>Select User/s</label><br />
                                <GeneralSelect onChanges={this.generalSelect} parameters={partiesOptions} isMulti={true} />
                                <br /><br />
                                <label>Select Screen</label><br />
                                {this.state.screens.map((d, i) => {
                                    return (<span key={i}>
                                        <input type={'checkbox'} name={d} id={'chk_' + d} onChange={this.handleChange} /> {d}<br />
                                    </span>)
                                })}
                                <br />
                                <button onClick={() => this.updateRoles()} className={'submit'}>Update</button>
                                <br /><br />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <table className={'tb2'}>
                                <thead>
                                    <tr>
                                        <th>#.</th>
                                        <th>User</th>
                                        <th>Roles / Screen Permissions</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users.map((d, i) => {
                                        return (<tr key={i}>
                                            <td>{(i + 1)}.</td>
                                            <td>{d.name}</td>
                                            <td>
                                                {/*{d.screens}*/}
                                                {
                                                    (d.screens).replace(']', '').replace('[', '').split(',').map((s, j) => {
                                                        return (<span className={'ug-badge-ok'} key={i + '_' + j}>{s}</span>)
                                                    })
                                                }
                                            </td>
                                            <td>
                                                <button className={'default'}>Edit</button>
                                                <button className={'refresh'} onClick={() => this.deleteRole(d)}>Delete</button>
                                            </td>
                                        </tr>)
                                    })}



                                </tbody>
                            </table>
                        </td>
                    </tr>


                </tbody>
            </table>
        </div>)
    }
}