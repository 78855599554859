import React, { useEffect, useState } from "react";
import Header from "./gro/header";
import { useForm } from 'react-hook-form';
import L from "leaflet";
import API from "../../../../services/API";
import Wait from "../../wait";
import Basemap from "../../../maps/basemap";
import PopDetail from "../../popdetail";
import Message from "./gro/person/message";
import TransactionPopup from "../transaction_popup";
import { parseZip } from "shpjs";

const searchResult = [
    {
        "spUnitId": 0,
        "council": "",
        "location": "",
        "block": "",
        "lotNumber": "",
        "planNumber": "",
        "regPlanNumber": ""
    }
]
const clearMap = () =>{
    return document.getElementById("mapContainer").innerHTML = "<div id='map' style='width: 100%; height: 100%; border-radius: 2px;'></div>";
}
export const Upload =() =>{
    const [arrayB, arrayBSet] = useState();
    const [jsonDecode, jsonDecodeSet] = useState();
    const [isUpload, processSet] = useState(false);
    const [hasError, hasErrorSet] = useState(false);
    const [errors, errorSet] = useState();
    const [errorPolygon, errorPolygonSet] = useState()
    const [tpAmnd, tpSet] = useState();
    const [spOrgn,spSet] = useState();
    let transactionId = TransactionPopup.id();

    const submitData =()=>{
        console.log("Data data ",jsonDecode)
        let {features} = jsonDecode
        let dataToSend = new Array()
        features.forEach( feature =>{
            let{geometry:{ type, coordinates}, properties} = feature
            let geometry =JSON.stringify({type,coordinates})
            let feat = {properties,geometry}
            dataToSend.push(feat)
        })
        console.log("Data to send", dataToSend)
        Wait.show("Saving ...")
        API.ax.post(`/smd/create/add-subdivision/${transactionId}`,dataToSend)
        .then(d=>{
            Wait.dismiss()
            let json;
            console.log("data ",d.data)
            let{status } = d.data
            if(status == 2){
                processSet(false);
                let{message, result:{type, properties:{name}, geometry} } = d.data
                 json =`{
                    "type": "FeatureCollection",
                    "features": [
                        {
                            "type":"${type}",
                            "properties":{"error":${JSON.stringify(name)}},
                            "geometry":${geometry}
                    }
                    ]}`;
                errorPolygonSet(json)
                hasErrorSet(true)
            }
            if( status == 3 || status == 4 || status == 5){
                processSet(false);
                let{ result:{ tpAmend, originSpatial, rss } } = d.data

                tpSet(tpAmend)
                spSet(originSpatial)
                let featuresArray = new Array()
                rss.forEach( rs =>{
                    let{type, geometry, properties:prop} = rs
                    featuresArray.push({type,geometry:JSON.parse(geometry),properties:{"error":prop.name}})
                })
                json =`{
                    "type": "FeatureCollection",
                    "features": ${JSON.stringify(featuresArray)}
                }`;
                console.log("json ",json)
                errorSet(json)
                hasErrorSet(true)
            }
        })
        .catch(e=>{
            Wait.dismiss()
            console.error(e)
        })
    }
    const handleChange = (data) => {
        arrayBSet(undefined);
        let file = data.target.files[0];
        let reader = new FileReader();

        reader.onloadend = (event) => {
            arrayBSet(event.target.result);
        };

        reader.onError = () => {
            console.error(reader.error);
        };
        if (file !== undefined) {
            reader.readAsArrayBuffer(file);
        }
    };
    useEffect(() => {
        if (arrayB !== undefined) {
            parseZip(arrayB).then(data2 => {
                hasErrorSet(false)
                jsonDecodeSet(data2);
                processSet(true)
            }).catch(err => console.error("error ", err));
        }
    }, [arrayB])
    useEffect(()=>{
        if(jsonDecode !== undefined){
            document.getElementById("mapContainer2").innerHTML = "<div id='mapSubdivision' style='width: 100%; height: 100%; border-radius: 2px;'></div>";

            let subdivisionFeature = new L.FeatureGroup();
            let errorFeature = new L.FeatureGroup();
            let tpFeature = new L.FeatureGroup();
            let spFeature = new L.FeatureGroup();

            let sat = Basemap.googleSat(),
                hybrid = Basemap.googleHybrid(),
                blank = L.tileLayer('')

            let m = L.map("mapSubdivision", {
                layers: [subdivisionFeature],
                attributionControl: false,
                zoomControl: true,
                fullscreenControl: true,
                attributionControl: false,
                minZoom: 4,
                maxZoom:23,
                maxBoundsViscosity: 1.0
            }).setView([-6.085936, 35.711995], 6.4);

            let baseLayers = {
                "<span style='color: gray'>Blank</span>": blank,
                "Satelite": sat,
                "Hybrid": hybrid
            }
            let myStyle = {
                weight: 5,
                color: 'green',
                fillColor: '#ff7800',
                fillOpacity: 0
            };
            let myStyleR = {
                weight: 5,
                color: 'red',
                fillColor: '#ff7800',
                fillOpacity: 0
            };
            let myStyleRed = {
                weight: 5,
                color: 'red',
                fillColor: '#ff7800',
                fillOpacity: 0
            };

            let subdivision = new L.GeoJSON(jsonDecode,{
    
                style: function(feature) {
                    return myStyle;
                },
                onEachFeature: function(feature, layer) {
                    L.marker(layer.getBounds().getCenter(), {
                        icon: L.divIcon({
                            className: 'bindTooltipBig',
                            html: `${feature.properties.plot_num}`,
                            iconSize: [100, 50]
                        }),
                        permanent: true,
                        interactive: false
                    }).addTo(subdivisionFeature);
                    layer.on({
                        click: function(e) {
                            e.target.setStyle(myStyle);
                            e.target.bringToFront();
                        }
                    });
                }
            });
            subdivision.addTo(subdivisionFeature);
            m.fitBounds(subdivisionFeature.getBounds())

            if(!hasError){
                errorSet(undefined)
                errorPolygonSet(undefined)
            }
            if(hasError && errors !== undefined){
                let errorLayer = new L.GeoJSON(JSON.parse(errors),{
                    style: function(feature) {
                        return myStyle;
                    },
                    onEachFeature: function(feature, layer) {
                        layer.bindPopup(feature.properties.error)
                    }
                }).addTo(errorFeature)
                m.fitBounds(errorFeature.getBounds())
            }
            if(hasError && errorPolygon !== undefined){
                let errorLayer = new L.GeoJSON(JSON.parse(errorPolygon),{
                    style: function(feature) {
                        return myStyle;
                    },
                    onEachFeature: function(feature, layer) {
                        layer.bindPopup(feature.properties.error)
                    }
                }).addTo(errorFeature)
                m.fitBounds(errorFeature.getBounds())
            }
            if(hasError && tpAmnd !== undefined){
                let tpAnd = new L.GeoJSON(JSON.parse(tpAmnd),{
                    style:function(feature){
                        return myStyleRed;
                    },
                    onEachFeature:function(feature, layer) {
                        L.marker(layer.getBounds().getCenter(), {
                            icon: L.divIcon({
                                className: 'bindTooltipBig',
                                html: `${feature.properties.landUse}`,
                                iconSize: [100, 50]
                            }),
                            permanent: true,
                            interactive: false
                        }).addTo(tpFeature);
                        layer.on({
                            click: function(e) {
                                e.target.setStyle(myStyle);
                                e.target.bringToFront();
                            }
                        });
                    }
                }).addTo(tpFeature)
                tpFeature.addTo(m)
                m.fitBounds(tpFeature.getBounds())
            }
            if(hasError && spOrgn !== undefined){

               let originSpatials =new L.GeoJSON(JSON.parse(spOrgn),{
                    
                    style: function(feature) {
                        return myStyleR;
                    },
                    onEachFeature: function(feature, layer) {
                        console.log("feature",feature)
                        L.marker(layer.getBounds().getCenter(), {
                            icon: L.divIcon({
                                className: 'bindTooltipBig',
                                html: `Plot:${feature.properties.lotNumber}Block:${feature.properties.blockNumber} Location:${feature.properties.localityName}`,
                                iconSize: [100, 50]
                            }),
                            permanent: true,
                            interactive: false
                        }).addTo(spFeature);
    
                        layer.on({
                            click: function(e) {
                                e.target.setStyle(myStyle);
                                e.target.bringToFront();
                            }
                        });
                    }
                });
                originSpatials.addTo(spFeature);
                spFeature.addTo(m)
                m.fitBounds(spFeature.getBounds())
            }
            console.log("error ",errors, "errorpolygon ",errorPolygon, "sporgin ",spOrgn, "tpamnd ",tpAmnd)
            let overlays = subdivisionFeature != undefined && (errors != undefined || errorPolygon !== undefined)?{
                'Errors':errorFeature,
                'Subivision':subdivisionFeature,
                'Origin Spatial': spFeature,
                'TP Amendment': tpFeature
            }:{
                'Subivision':subdivisionFeature,
            }

            console.log("the overalys ",overlays)

            L.control.layers(baseLayers,overlays, {position: 'bottomleft',collapsed: false}).addTo(m)
        }
    },[jsonDecode, hasError])

    return(
        <div style={{display:"flex", flexDirection:"column", width:'56vw',alignItems:"center"}}>
            <h3>Choose Respective Shapefile</h3>
            <div style={{display:"flex", flexDirection:"row"}}>
                <input name={"shapefile"} type={"file"} accept={".zip,.ZIP"} onChange={handleChange}/>
            </div>
            <hr style={{width:"100%", color:"tomato"}}/>
            <div id={"mapContainer2"} style={{ height: '50vh',width:'100%', backgroundColor: 'lightgray', cursor: 'pointer', borderRadius: '2px' }} />
            {isUpload&&<div style={{marginTop:"1rem", float:"right"}}>
            <button className={'default'} style={{ width: '150px', float: 'right' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>
            <button onClick={submitData} style={{ width: '150px', float: 'right' }}><i className="fa fa-spinner"/>Process</button></div>}
        </div>
    )

}
const Search = ({ refresh }) => {
    const { handleSubmit, register } = useForm();
    const [search, searchSet] = useState(searchResult);
    const [isPresent, isPresentSet] = useState(false)
    const [selected, selectedSet] = useState()
    const [spatialUnitId, spatialUnitIdSet] = useState()
    let transactionId = TransactionPopup.id()

    const highlight = {
        fontWeight: 'bold',
        cursor: 'pointer',
        background: '#DF4A3622'
    }
    const unHighlight = {
        cursor: 'pointer'
    }
    const addSpUnit = () => {
        console.log("spId is ", spatialUnitId)
        let data = { spatialUnitId, transactionId }
        Wait.show("saving ...")
        API.ax.post("smd/create/add-origin-spatial", data)
            .then(datas => {
                Wait.dismiss()
                console.log(datas.data)
                const { status, message } = datas.data
                if (status === 1) {
                    //back to refresh
                    refresh("refresh...");
                } else {
                    PopDetail.show("Error", <Message faIcon="harzad" message={message} />, 40)
                }
            })
            .catch(e => {
                Wait.dismiss()
                PopDetail.show("Error", <Message faIcon="harzad" message={e.message} />, 40)

            })
    }
    const submit = data => {
        const isNotEmpty = Object.values(data).reduce((container, currentValue) => container || currentValue !== "");
        if (isNotEmpty) {
            console.log("send the data", data)
            Wait.show("Searching ...")
            API.ax.post('smd/read/search-spunit', data)
                .then(datas => {
                    Wait.dismiss();
                    const { result, status, message } = datas.data
                    console.log("the data is ", datas);
                    if (status === 1) {
                        searchSet(result)
                        isPresentSet(true)
                    } else {
                        PopDetail.show("Error", <Message faIcon="harzad" message={message} />, 40)
                    }

                })
                .catch(e => {
                    Wait.dismiss();
                    PopDetail.show("Error", <Message faIcon="harzad" message={e.message} />, 40)
                })

        } else {
            PopDetail.show("Error", <Message faIcon="harzad" message={"Please fill the details before proceeding"} />, 40)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: "20px" }}>
            <form onSubmit={handleSubmit(submit)}><table style={{ width: "100%" }}><tbody>
                <tr>
                    <td><input type="text" name="council" placeholder="Council" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><input type="text" name="location" placeholder="Location" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><input type="text" name="block" placeholder="Block" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                </tr>
                <tr>
                    <td><input type="text" name="plotNumber" placeholder="Plot Number" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><input type="text" name="planNumber" placeholder="Plan Number" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><input type="number" name="regPlanNumber" placeholder="Reg Plan Number" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><button type={"submit"} style={{ width: '150px', height: '38px' }}><i style={{ color: "white" }} className={"fa fa-search"} />Search</button></td>
                </tr>
            </tbody></table><hr /></form>
            {isPresent && <table style={{ width: '100%' }} className={"tb"}>
                <thead><tr>
                    <th>Council</th>
                    <th>Location</th>
                    <th>Block</th>
                    <th>Plot Number</th>
                    <th>Plan Number</th>
                    <th>Reg. Plan Number</th>
                </tr></thead>
                <tbody>
                    {
                        search.map(({ spUnitId, council, location, block, lotNumber, planNumber, regPlanNumber }, index) =>
                            <tr style={selected === index ? highlight : unHighlight} key={index} onClick={() => { spatialUnitIdSet(spUnitId); selectedSet(index); }}>
                                <td>{council}</td>
                                <td>{location}</td>
                                <td>{block}</td>
                                <td>{lotNumber}</td>
                                <td>{planNumber}</td>
                                <td>{regPlanNumber}</td>
                            </tr>)
                    }
                </tbody>
            </table>}
            {selected > -1 && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <button style={{ width: '150px' }} className={"default"} onClick={() => PopDetail.dismiss()}><i style={{ color: "tomato" }} className={"fa fa-times"} />Cancel</button>
                <button style={{ width: '150px' }} onClick={addSpUnit}><i style={{ color: "white" }} className={"fa fa-save"} />Save</button>
            </div>}
        </div>
    )
}
export default function LegacySubdivision({ writable }){

    const [dataPresent, dataPresentSet] = useState();
    const [dataTwoPresent,dataTwoPresentSet] = useState();
    
const remove = () => {
        Wait.show("Processing ...")
        let transactionId = TransactionPopup.id()
        API.ax.delete(`/smd/read/remove-subdivision/${transactionId}`)
            .then((response) => {
                Wait.dismiss();
                console.log("the response is ", response.data)
                const { status, message } = response.data
                if (status === 1) {
                    refresh(true)
                } else {
                    PopDetail.show("Error", <Message faIcon="harzad" message={message} />, 40)
                }
            })
            .catch((error) => {
                Wait.dismiss();
                PopDetail.show("Error", <Message faIcon="harzad" message={error.message} />, 40)
            })
    }
const refresh = states =>{
    clearMap()
    PopDetail.dismiss()
    Wait.show(states)
    let transactionId = TransactionPopup.id()
    API.ax.get(`/smd/read/get-subdivisions/${transactionId}`)
    .then( d => {
        Wait.dismiss()
        const{status, message, result} = d.data
        if( status === 0 ){
            dataPresentSet(false)
            let m = L.map("map", {
                drawControl: true,
                zoomControl: true,
                fullscreenControl: true,
                attributionControl: false,
                minZoom: 4,
                maxBoundsViscosity: 1.0
            }).setView([-6.085936, 35.711995], 6.4);
            L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                maxZoom: 1000,
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
            }).addTo(m);
        }else{
            clearMap()
            dataPresentSet(true)
            let { subdivision, originSpatial, tpAmend } = result

            let groupFeature = new L.FeatureGroup();
            let tpFeature = new L.FeatureGroup();
            let originFeature = new L.FeatureGroup();

            let sat = Basemap.googleSat(),
                hybrid = Basemap.googleHybrid(),
                blank = L.tileLayer('')

            let m = L.map("map", {
                layers: [blank],
                attributionControl: false,
                zoomControl: true,
                fullscreenControl: true,
                attributionControl: false,
                minZoom: 4,
                maxZoom:50,
                maxBoundsViscosity: 1.0
            }).setView([-6.085936, 35.711995], 6.4);

            let baseLayers = {
                "<span style='color: gray'>Blank</span>": blank,
                "Satelite": sat,
                "Hybrid": hybrid
            }
            let myStyle = {
                weight: 5,
                color: '#ff7800',
                fillColor: '#ff7800',
                fillOpacity: 0
            };
            let tpStyle = {
                weight: 5,
                color: 'brown',
                fillColor: '#ff7800',
                fillOpacity: 0
            };
            let subStyle = {
                weight: 5,
                color: 'green',
                fillColor: '#ff7800',
                fillOpacity: 0
            };
            let tpAmends
            if(tpAmend !== undefined){

                tpAmends = new L.GeoJSON(JSON.parse(tpAmend),{
    
                    style: function(feature) {
                        return tpStyle;
                    },
                    onEachFeature: function(feature, layer) {
                        L.marker(layer.getBounds().getCenter(), {
                            icon: L.divIcon({
                                className: 'bindTooltipBig',
                                html: `${feature.properties.landUse}`,
                                iconSize: [100, 50]
                            }),
                            permanent: true,
                            interactive: false
                        }).addTo(tpFeature);
                        layer.on({
                            click: function(e) {
                                e.target.setStyle(myStyle);
                                e.target.bringToFront();
                            }
                        });
                    }
                });
                tpAmends.addTo(tpFeature);
                tpFeature.addTo(m)
                m.fitBounds(tpFeature.getBounds())

            }

            let originSpatials;
            if(originSpatial !== undefined){
                originSpatials =new L.GeoJSON(JSON.parse(originSpatial),{
                    
                    style: function(feature) {
                        return myStyle;
                    },
                    onEachFeature: function(feature, layer) {
                        console.log("feature",feature)
                        L.marker(layer.getBounds().getCenter(), {
                            icon: L.divIcon({
                                className: 'bindTooltipBig',
                                html: `Plot:${feature.properties.lotNumber}Block:${feature.properties.blockNumber} Location:${feature.properties.localityName}`,
                                iconSize: [100, 50]
                            }),
                            permanent: true,
                            interactive: false
                        }).addTo(originFeature);
    
                        layer.on({
                            click: function(e) {
                                e.target.setStyle(myStyle);
                                e.target.bringToFront();
                            }
                        });
                    }
                });
                originSpatials.addTo(originFeature);
                originFeature.addTo(m)
                m.fitBounds(originFeature.getBounds())

            }

            if( subdivision !== undefined ){
                dataTwoPresentSet(true)
                let groups = new L.GeoJSON(JSON.parse(subdivision),{

                    style: function(feature) {
                        return subStyle;
                    },
                    onEachFeature: function(feature, layer) {

                        L.marker(layer.getBounds().getCenter(), {
                            icon: L.divIcon({
                                className: 'bindTooltipBig',
                                html: `Plot:${feature.properties.lotNumber}Block:${feature.properties.blockNumber} Location:${feature.properties.localityName}`,
                                iconSize: [100, 50]
                            }),
                            permanent: true,
                            interactive: false
                        }).addTo(groupFeature);
                        layer.on({
                            click: function(e) {
                                e.target.setStyle(myStyle);
                                e.target.bringToFront();
                            }
                        });
                    }

                });
                groups.addTo(groupFeature)
                groupFeature.addTo(m)
                m.fitBounds(groupFeature.getBounds())
            }

            let overlays = tpAmend !== undefined && originSpatial !== undefined && subdivision !== undefined?{
                'Subdivided Spatial Units':groupFeature,
                'TP Amendments':tpFeature,
                'Original Spatial Unit':originFeature,             
            }:tpAmend !== undefined && originSpatial !== undefined?{
                'TP Amendments':tpFeature,
                'Original Spatial Unit':originFeature,
            }:tpAmend != undefined?{
                'TP Amendments':tpFeature,
                'Original Spatial Unit':originFeature,
            }:{
                'Original Spatial Unit':originFeature,                
            }

            L.control.layers(baseLayers,overlays, {position: 'bottomleft',collapsed: false}).addTo(m)
        }

    })
    .catch( e => {
        console.error(e)
        Wait.dismiss()
        clearMap()
        let m = L.map("map", {
            zoomControl: true,
            fullscreenControl: true,
            attributionControl: false,
            minZoom: 4,
            maxBoundsViscosity: 1.0
        }).setView([-6.085936, 35.711995], 6.4);
        L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            maxZoom: 1000,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(m);

    })
    
}
useEffect(()=>{
    refresh("Loading...")
},[])
    return(
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Header display={writable} label={'Spatial Unit'}>
        <div>
            <i
                className={'fa fa-plus-circle'}
                style={{ color: 'tomato', fontSize: '25px' }}
                onClick={dataPresent?() => { }:()=>{PopDetail.show("Search Spatial Unit to Subdivide", <Search refresh={refresh} />, 80)}}
            /><strong> New</strong>
        </div>        
        <div>
            <i
                className={'fa fa-upload'}
                style={{ color: 'tomato', fontSize: '25px' }}
                onClick={dataTwoPresent?() => { }:()=>{PopDetail.show("Choose Shapefile of Subdivided Parcels", <Upload refresh={refresh} />, 80)}}
            /><strong> Upload Spatial</strong>
        </div>        
        <div>
            <i
                className={'fa fa-trash'}
                style={{ color: 'tomato', fontSize: '25px' }}
                onClick={dataPresent? remove :()=>{}}
            /><strong> Remove</strong>
        </div>
        </Header>
        <div id={"mapContainer"} style={{ height: '80vh', backgroundColor: 'lightgray', cursor: 'pointer', borderRadius: '2px' }} />
    </div>
    )
}

const style = {
    inputHeight: {
        height: "25px",
        width: '100%'
    },
    label: {
        color: "#888",
        fontSize: "15px",
        fontWeight: "normal !important",
        marginBottom: "1px"
    }
}