import React, {useEffect, useState} from 'react'
import Header from "./gro/header";
import PopDetail from "../../popdetail";
import {useForm} from "react-hook-form";
import PopDetailsTwo from "../../PopDetailsTwo";
import Wait from "../../wait";
import TxReadService from "../../../../services/transaction/transaction_read";
import TxCreateService from "../../../../services/transaction/transaction_create";
import API from "../../../../services/API";
import wait from "../../wait";

const Search =({submit})=>{
    const{register,handleSubmit}=useForm()
    //add reason and then add the control numbers in a popuptwo

    return(
        <form onSubmit={handleSubmit(submit)}>
            <table style={{width:'100%'}}>
                <tbody>
                <tr>
                    <td><span>Cancellation Reason</span></td></tr>
                <tr>
                    <td>
                        <textarea name={"reason"} ref={register({required:true,minLength:"10",maxLength:"500"})} style={{resize:"none",width:"100%",height:"100%",rows:10}}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span><strong>Control Numbers List</strong>  <i className={'fa fa-plus-circle'} style={{color:'tomato', fontSize:'25px',cursor:"pointer"}} onClick={()=>{PopDetailsTwo.show("Search Control Number",<SearchControlNumber submit={submit}/>,70)}}
                /></span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea name={"controlNumbers"} ref={register({required:true,minLength:"10",maxLength:"500"})} style={{resize:"none",width:"100%",height:"100%",rows:10}} readOnly={true}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button className={"default"} onClick={PopDetail.dismiss} style={{width:"150px",float:"right"}}>Cancel </button>
                        <button type={"submit"} style={{width:"150px",float:"right"}} >Add </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    )
}
const SearchControlNumber =({submit})=>{
    const{register,handleSubmit}= useForm()
    const[show,showSet]=useState(false)
    const[display, displaySet] = useState('none')
    const[ctrNum,ctrNumSet] = useState(0)
    const[controlNumberRst,controlNumberRstSet] = useState({});
    const submitHandler = data=>{
        setTimeout(()=>{
            Wait.show("Searching ...")
            console.log("control number",data)
            //get control no details
            API.ax.get(`/finance/control-number-search/${data.controlNumber}`)
                .then(d=>{
                    const{status, result} = d.data
                    console.log("the result ", result)
                    if(status === 1){
                        wait.dismiss()
                        console.log("submit",submit)
                        controlNumberRstSet(result)
                        showSet(true)
                    }else {
                        wait.dismiss()
                        console.log("the result ", d.data)
                    }
                })
                .catch(e=>{
                    console.log("there ",e)
                })



        },7000)
        Wait.dismiss()
    }
    useEffect(()=>{
        if(ctrNum >0){
            displaySet("")
        }
    },[ctrNum])
    return(
        <>
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <div style={{display:"flex", padding:"5px"}}>
                            <input name={"controlNumber"} ref={register({required:true})} type={"number"} style={{width:"100%"}} placeholder={"Enter a Valid Control Number"}/>
                            <button style={{width:"150px"}} type={'submit'}>Search</button>
                        </div>
                    </form>
                     {show&&
                      <>
                         <table className={'tb'} style={{width:'100%'}}>
                            <thead>
                            <tr>
                                <th>TRANSACTION NUMBER</th>
                                <th>TRANSACTION NAME</th>
                                <th>CONTROL NUMBER</th>
                                <th>AMOUNT</th>
                                <th>PARTIES</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                    <tr onClick={()=>ctrNumSet(controlNumberRst.controlNumber)}>
                                        <td>{controlNumberRst.transactionNumber}</td>
                                        <td>{controlNumberRst.transactionName}</td>

                                        <td>{controlNumberRst.controlNumber}</td>
                                        <td>{controlNumberRst.amount}</td>
                                        <td>{controlNumberRst.parties}</td>
                                    </tr>
                            }
                            </tbody>
                         </table>
                          <button  style={{width:"150px",float:"right",display:display}}>Add</button>
                     </>

                     }
        </>
    )
}
export default function CancelControlNumber({writable}){
    const[controlNumbers,controlNumbersSet] = useState([]);
    const[display, displaySet] = useState(false);
    const [bill,setBill] =useState([])
    useEffect(()=>{
        controlNumbersSet(initialState)
        displaySet(false)
    },[])
    const submit = data =>{
        console.log("Our data ",data)
    }
    const approvals = (checked,billId)=>{
        console.log("checked ", checked," billId ",billId)
                if(checked){
                    setBill(billId)
                }
    }
    const sendForCancellation=()=>{
        TxCreateService.sendControlNumbers({
            "bills": bill,
            "reason": "Testing"
        }).then(r =>{})

    }

    return(
        <>
            <Header label={"Cancel Control Number"} display={writable}>
                <div>
                    <i
                        className={'fa fa-search'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("New Control Number Cancel Request",<Search submit={submit}/>,40)}}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("Remove Control Number",<Search submit={submit}/>,40)}}
                    /><strong> Remove</strong>
                </div>
                {display &&
                <div>
                    <i
                        className={'fa fa-check'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("Remove Control Number",<Search submit={submit}/>,40)}}
                    /><strong>Approve/Reject</strong>
                </div>
                }
            </Header>
            <table className={'tb'} style={{width:'100%'}}>
                <thead>
                    <tr>
                        <th style={!display?{display:"none"}:{}}>APPROVE/REJECT</th>
                        <th>TRANSACTION NUMBER</th>
                        <th>TRANSACTION NAME</th>
                        <th>CONTROL NUMBER</th>
                        <th>AMOUNT</th>
                        <th>PARTIES</th>
                        <th>REASON</th>
                        <th>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                {
                    controlNumbers.map(({transactionName,transactionNumber,controlNumber,amount,parties,reason,status},i)=>
                    <tr key={i}>
                        <td style={!display?{display:"none"}:{}}><input type={"checkbox"} onChange={e=>approvals(e.target.checked,23)} style={{width:"25px",height:"25px"}}/></td>
                        <td>{transactionName}</td>
                        <td>{transactionNumber}</td>
                        <td>{controlNumber}</td>
                        <td>{amount}</td>
                        <td>{parties}</td>
                        <td>{reason}</td>
                        <td>{status}</td>
                    </tr>
                    )
                }
                </tbody>
            </table>
            <div><button onClick={()=>sendForCancellation}>Send</button></div>
        </>
    )
}

const initialState=[{
    transactionNumber:"",
    transactionName:"",
    controlNumber:"",
    parties:"",
    reason:"",
    status:""
}]
