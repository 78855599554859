import * as React from "react";
import PopDetail from "../../popdetail";
import PaneAddPerson from "../pops/pane_add_person";
import PaneAddSpatialUnit from "../pops/pane_add_spatial";
import TransactionPopup from "../transaction_popup";
import PaneUploadSpatialUnitSurverRequest from "./add/add_rough_coordinate";
import Wait from "../../wait";
import PaneSearchPropertyBatch from "../pops/pane_search_property_batch";
import TxReadService from "../../../../services/transaction/transaction_create";
import PaneSearchPropertyBatchDemandNotice from "../pops/pane_search_property_demand_plots";
export default class SelectPlot extends React.Component{
    static self = null
    constructor(props) {
        super(props);

        this.state={
            selectedProperties:[],
            landProperties:[],
            rent:[],
            showMenu:false,
            gepgMenu:false,
            base64:''
        }

        SelectPlot.self = this

        this.computeRent=this.computeRent.bind(this)



    }

    static rentData(){return SelectPlot.self.state.rent.annualLandRentBatch}

    popAddSpatial(tag){
        let pan = <PaneAddSpatialUnit/>
        let trans_name = TransactionPopup.self.state.title

        switch (tag) {
            case 'add':
                break
            case 'upload':
                switch (trans_name) {
                    case 'Survey Request':
                        PopDetail.show('Upload Rough Coordinates',<PaneUploadSpatialUnitSurverRequest/>)
                        break
                    default:
                        break
                }
                break
            default:
                break
        }


        //
        //PopDetail.show('New Spatial Unit',pan)
    }

    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-12'} style={{height:'47vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Properties/Titles</b>

                            <div style={{float:'right'}}>
                                <span>
                                <span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'}
                                      onClick={()=>this.popAddSpatial('add')} />New
                            </span>
                                <span>
                                {/*<span style={{marginLeft:'16px'}} className={'fa fa-edit screen-action'}
                                      onClick={()=>PopDetail.show('Add Person',<PaneAddPerson/>)} />Edit*/}
                            </span>
                                <span>
                                <span style={{marginLeft:'16px'}} className={'fa fa-trash screen-action'}
                                      onClick={()=>PopDetail.show('Remove Property',<PaneAddPerson/>)} />Remove
                            </span>
                                <span>
                                <span style={{marginLeft:'16px'}} className={'fa fa-search screen-action'}
                                      onClick={()=>PopDetail.show('Search Properties',<PaneSearchPropertyBatchDemandNotice/>)} />Property Search
                            </span>
                                <span>
                                <span style={{marginLeft:'16px'}} className={'fa fa-check screen-action'}
                                      onClick={()=>this.computeRent()} />Check
                            </span>
                            </div>
                        </div>
                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>

                            <tr>
                                <th>BAUNIT ID</th>
                                <th>TITLE NUMBER</th>
                                <th>RIGHT HOLDER</th>
                                <th>DISTRICT</th>
                                <th>LOCATION</th>
                                <th>BLOCK</th>
                                <th>LOT NUMBER</th>
                                <th>USE</th>

                            </tr>
                            </thead>
                            <tbody>

                            {this.state.landProperties.map((d,i) => {
                                return(
                                    <tr key={i}>
                                        <td>{d.baunitId}</td>
                                        <td>{d.titleNumber}</td>
                                        <td>{d.party}</td>
                                        <td>{d.districtName}</td>
                                        <td>{d.localityName}</td>
                                        <td>{d.blockNumber}</td>
                                        <td>{d.lotNumber}</td>
                                        <td>{d.landUseName}</td>
                                    </tr>)})}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </div>)
    }

    computeRent(){
        try {

            /* this.state.landProperties.forEach(p=>{

                 this.state.selectedProperties.push(p.baninitId)
                 }
             )*/
            let data={
                baunitIds:this.state.selectedProperties,
                txId:TransactionPopup.id()}

            Wait.show("Calculating Batch Rent...")
            TxReadService.calculateBatchRent(data).then(r => {
                Wait.dismiss()
                try {
                    this.setState({rent: r.data})
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

}
