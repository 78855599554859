import React, { useEffect, useState } from "react";
import Header from "../gro/header";
import PopDetail from "../../../popdetail"
import { useForm } from 'react-hook-form';
import L from "leaflet";
import API from "../../../../../services/API";
import Wait from "../../../wait";
import Message from "../gro/person/message";
import TransactionPopup from "../../transaction_popup";

const initialData = {
    spatialUnitId: 0,
    json: '',
    regionName: '',
    districtName: '',
    council: '',
    localityName: '',
    blockNumber: '',
    lotNumber: '',
    gisArea: '',
    legalArea: '',
    areaUnitName: '',
    lotType: '',
    drawingNumber: '',
    regPlanNumber: '',
    planNumber: '',
    instructionNumber: '',
    surveyTypeName: '',
    approvedDate: '',
    surveyDate: '',
    transactionId: 0
};

const searchResult = [
    {
        "spUnitId": 0,
        "council": "",
        "location": "",
        "block": "",
        "lotNumber": "",
        "planNumber": "",
        "regPlanNumber": ""
    }
]
const Map = ({ json }) => {
    useEffect(() => {
        document.getElementById("mapContainer").innerHTML = "<div id='map' style='width: 100%; height: 100%; border-radius: 2px;'></div>";
        let m = L.map("map", {
            drawControl: true,
            zoomControl: true,
            fullscreenControl: true,
            attributionControl: false,
            minZoom: 4,
            maxBoundsViscosity: 1.0
        }).setView([-6.085936, 35.711995], 6.4);
        L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            maxZoom: 1000,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(m);
        if (json !== undefined) {
            let { coordinates } = JSON.parse(json);

            let polygon = L.polygon(coordinates[0]);
            polygon.setStyle({ fillColor: 'lightblue', opacity: .7, color: "#f00", weight: 4, fillOpacity: 0.2 });
            polygon.addTo(m);
            /*L.marker(polygon.getBounds().getCenter(), {
                icon: L.divIcon({
                    className: 'bindTooltipBig',
                    html: `Plot:${lotNumber}`,
                    iconSize: [100, 50]
                }),
                permanent: true,
                interactive: false
            }).addTo(m);*/
            m.fitBounds(polygon.getBounds())

        }
    }, [json])
    return (
        <>
            {
                <div id={"mapContainer"} style={{ height: '60vh', backgroundColor: 'lightgray', cursor: 'pointer', borderRadius: '2px' }} />
            }
        </>

    )
}

const Search = ({ refresh }) => {
    const { handleSubmit, register } = useForm();
    const [search, searchSet] = useState(searchResult);
    const [isPresent, isPresentSet] = useState(false)
    const [selected, selectedSet] = useState()
    const [spatialUnitId, spatialUnitIdSet] = useState()
    let transactionId = TransactionPopup.id()

    const highlight = {
        fontWeight: 'bold',
        cursor: 'pointer',
        background: '#DF4A3622'
    }
    const unHighlight = {
        cursor: 'pointer'
    }
    const addSpUnit = () => {
        console.log("spId is ", spatialUnitId)
        let data = { spatialUnitId, transactionId }
        Wait.show("saving ...")
        API.ax.post("smd/create/update-spatial-transaction", data)
            .then(datas => {
                Wait.dismiss()
                console.log(datas.data)
                const { status, message } = datas.data
                if (status === 1) {
                    //back to refresh
                    console.log("refresh ...")
                    refresh(true);
                } else {
                    PopDetail.show("Error", <Message faIcon="harzad" message={message} />, 40)
                }
            })
            .catch(e => {
                Wait.dismiss()
                PopDetail.show("Error", <Message faIcon="harzad" message={e.message} />, 40)

            })
    }
    const submit = data => {
        const isNotEmpty = Object.values(data).reduce((container, currentValue) => container || currentValue !== "");
        if (isNotEmpty) {
            console.log("send the data", data)
            Wait.show("Searching ...")
            API.ax.post('smd/read/search-spunit', data)
                .then(datas => {
                    Wait.dismiss();
                    const { result, status, message } = datas.data
                    console.log("the data is ", datas);
                    if (status === 1) {
                        searchSet(result)
                        isPresentSet(true)
                    } else {
                        PopDetail.show("Error", <Message faIcon="harzad" message={message} />, 40)
                    }

                })
                .catch(e => {
                    Wait.dismiss();
                    PopDetail.show("Error", <Message faIcon="harzad" message={e.message} />, 40)
                })

        } else {
            PopDetail.show("Error", <Message faIcon="harzad" message={"Please fill the details before proceeding"} />, 40)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: "20px" }}>
            <form onSubmit={handleSubmit(submit)}><table style={{ width: "100%" }}><tbody>
                <tr>
                    <td><input type="text" name="council" placeholder="Council" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><input type="text" name="location" placeholder="Location" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><input type="text" name="block" placeholder="Block" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                </tr>
                <tr>
                    <td><input type="text" name="plotNumber" placeholder="Plot Number" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><input type="text" name="planNumber" placeholder="Plan Number" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><input type="number" name="regPlanNumber" placeholder="Reg Plan Number" ref={register} style={{ ...style.inputHeight, height: '38px' }} /></td>
                    <td><button type={"submit"} style={{ width: '150px', height: '38px' }}><i style={{ color: "white" }} className={"fa fa-search"} />Search</button></td>
                </tr>
            </tbody></table><hr /></form>
            {isPresent && <table style={{ width: '100%' }} className={"tb"}>
                <thead><tr>
                    <th>Council</th>
                    <th>Location</th>
                    <th>Block</th>
                    <th>Plot Number</th>
                    <th>Plan Number</th>
                    <th>Reg. Plan Number</th>
                </tr></thead>
                <tbody>
                    {
                        search.map(({ spUnitId, council, location, block, lotNumber, planNumber, regPlanNumber }, index) =>
                            <tr style={selected === index ? highlight : unHighlight} key={index} onClick={() => { spatialUnitIdSet(spUnitId); selectedSet(index); }}>
                                <td>{council}</td>
                                <td>{location}</td>
                                <td>{block}</td>
                                <td>{lotNumber}</td>
                                <td>{planNumber}</td>
                                <td>{regPlanNumber}</td>
                            </tr>)
                    }
                </tbody>
            </table>}
            {selected > -1 && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <button style={{ width: '150px' }} className={"default"} onClick={() => PopDetail.dismiss()}><i style={{ color: "tomato" }} className={"fa fa-times"} />Cancel</button>
                <button style={{ width: '150px' }} onClick={addSpUnit}><i style={{ color: "white" }} className={"fa fa-save"} />Save</button>
            </div>}
        </div>
    )
}

const Details = ({ data, readOnly, isPresent = false, edit, refresh }) => {
    const { register, handleSubmit } = useForm()
    const submit = datum => {
        let spatialUnitId = data.spatialUnitId
        let transactionId = TransactionPopup.id()
        console.log("data ", datum);
        let dataToSend = { spatialUnitId, ...datum };
        console.log("data to send ", dataToSend)

        Wait.show("Updating ...")
        API.ax.put(`/smd/update/update-spatial-replica/${transactionId}`, dataToSend)
            .then(d => {
                Wait.dismiss()
                refresh(true)
            })
            .catch(e => {
                Wait.dismiss();
                PopDetail.show("Error", <Message faIcon="harzad" message={e.message} />, 40)
            })
    }
    return (
        <form style={{ height: '30vh' }} onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td><span style={style.label}>Region Name</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.regionName : ''} name={"regionName"} ref={register({ required: true })} /></td>
                        <td><span style={style.label}>District Name</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.districtName : ''} name={"districtName"} ref={register({ required: true })} /></td>
                        <td><span style={style.label}>Council</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.council : ''} name={"council"} ref={register({ required: true })} /></td>
                    </tr>
                    <tr>
                        <td><span style={style.label}>Locality Name</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.localityName : ''} name={"localityName"} ref={register({ required: true })} /></td>
                        <td><span style={style.label}>Block Number</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.blockNumber : ''} name={"blockNumber"} ref={register({ required: true })} /></td>
                        <td><span style={style.label}>Lot Number</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.lotNumber : ''} name={"lotNumber"} ref={register({ required: true })} /></td>
                    </tr>
                    <tr>
                        <td><span style={style.label}>Survey Date</span></td>
                        <td> {
                            readOnly ? <input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.surveyDate : ''} /> :
                                <input type={"date"} style={{ ...style.inputHeight, height: '38px' }} defaultValue={isPresent ? data.surveyDate : ''} name={"surveyDate"} ref={register({ required: true })} />
                        } </td>
                        <td><span style={style.label}>Legal Area</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.legalArea : ''} name={"legalArea"} ref={register({ required: true })} /></td>
                        <td><span style={style.label}>Area Unit Name</span></td>
                        <td>{
                            readOnly ? <input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.areaUnitName : ''} /> :
                                <select style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} defaultValue={isPresent ? data.areaUnitName : ''} name={"areaUnitName"} ref={register({ required: true })}>
                                    <option value={''}></option>
                                    <option value={'ACRES'}>Acres</option>
                                    <option value={'ARES'}>Ares</option>
                                    <option value={'HA'}>Hectares</option>
                                    <option value={'SQF'}>Square Feet</option>
                                    <option value={'SQM'}>Square Meters</option>
                                </select>
                        }</td>
                    </tr>
                    <tr>
                        <td><span style={style.label}>Lot Type</span></td>
                        <td>{
                            readOnly ? <input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.lotType : ''}></input> :
                                <select style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} defaultValue={isPresent ? data.lotType : ''} name={"lotType"} ref={register({ required: true })}>
                                    <option value={''}></option>
                                    <option value={'Plot'}>Plot</option>
                                    <option value={'Farm'}>Farm</option>
                                </select>

                        }
                        </td>
                        <td><span style={style.label}>Drawing Number</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.drawingNumber : ''} name={"drawingNumber"} ref={register({ required: true })} /></td>
                        <td><span style={style.label}>Registred Plan Number</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.regPlanNumber : ''} name={"regPlanNumber"} ref={register({ required: true })} /></td>
                    </tr>
                    <tr>
                        <td><span style={style.label}>Plan Number</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.planNumber : ''} name={"planNumber"} ref={register({ required: true })} /></td>
                        <td><span style={style.label}>Instruction Number</span></td>
                        <td><input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.instructionNumber : ''} name={"instructionNumber"} ref={register({ required: false })} /></td>
                        <td><span style={style.label}>Survey Type Name</span></td>
                        <td>{
                            readOnly ? <input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.surveyTypeName : ''} name={"surveyTypeName"} /> :
                                <select style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} defaultValue={isPresent ? data.surveyTypeName : ''} name={"surveyTypeName"} ref={register({ required: true })}>
                                    <option value={''}></option>
                                    <option value={'Government'}>Government</option>
                                    <option value={'Private'}>Private</option>
                                </select>
                        }</td>
                    </tr>
                    <tr>
                        <td><span style={style.label}>Approved Date</span></td>
                        <td>{
                            readOnly ? <input type="text" style={readOnly ? { ...style.inputHeight } : { ...style.inputHeight, height: '38px' }} readOnly={readOnly} defaultValue={isPresent ? data.approvedDate : ''} /> :
                                <input type={"date"} style={{ ...style.inputHeight, height: '38px' }} defaultValue={isPresent ? data.approvedDate : ''} name={"approvedDate"} ref={register({ required: true })} />

                        }</td>

                    </tr>
                    {edit && <tr>
                        <td colSpan={6}>
                            <br /><button onClick={PopDetail.dismiss} style={{ float: 'right', width: '150px' }} className={"default"}>Cancel</button>
                            <button type={'submit'} style={{ float: 'right', width: '150px' }}>Update</button>
                        </td>
                    </tr>}
                </tbody>
            </table>
        </form>
    )
}

export default function SpatialUnitMaintanance({ writable }) {
    const [dataPresent, dataPresentSet] = useState();
    const [data, dataSet] = useState(null);
    const [json, jsonSet] = useState(undefined);

    const refresh = status => {
        if (status) {
            PopDetail.dismiss()
            let transactionId = TransactionPopup.id()
            Wait.show("Loading ...")
            API.ax.get(`/smd/read/spatial-unit-update/${transactionId}`)
                .then(response => {
                    Wait.dismiss()
                    const { result, status } = response.data
                    if (status === 1) {
                        let { json } = result
                        jsonSet(json)
                        dataSet(result)
                        dataPresentSet(true)
                    } else {
                        dataSet(initialData)
                        jsonSet(undefined)
                        dataPresentSet(false)
                    }
                })
                .catch(e => {
                    Wait.dismiss();
                    PopDetail.show("Error", <Message faIcon="harzad" message={e.message} />, 40)
                })
        }
    }

    const whatChanged = () => {
        Wait.show("Processing ...")
        let transactionId = TransactionPopup.id()
        API.ax.get(`/smd/read/what-change/${transactionId}`)
            .then((response) => {
                Wait.dismiss();
                console.log("the response is ", response.data)
            })
            .catch((error) => {
                Wait.dismiss();
                PopDetail.show("Error", <Message faIcon="harzad" message={error.message} />, 40)
            })
    }
    const remove = () => {
        Wait.show("Processing ...")
        let transactionId = TransactionPopup.id()
        API.ax.delete(`/smd/read/remove/${transactionId}`)
            .then((response) => {
                Wait.dismiss();
                console.log("the response is ", response.data)
                const { status, message } = response.data
                if (status === 1) {
                    refresh(true)
                } else {
                    PopDetail.show("Error", <Message faIcon="harzad" message={message} />, 40)
                }
            })
            .catch((error) => {
                Wait.dismiss();
                PopDetail.show("Error", <Message faIcon="harzad" message={error.message} />, 40)
            })
    }

    useEffect(() => {
        let transactionId = TransactionPopup.id()
        Wait.show("Loading ...")
        API.ax.get(`/smd/read/spatial-unit-update/${transactionId}`)
            .then(data => {
                Wait.dismiss()
                const { result, status } = data.data
                if (status === 1) {
                    let { json } = result
                    jsonSet(json)
                    dataSet(result)
                    dataPresentSet(true)
                }
            })
            .catch(e => {
                Wait.dismiss();
                PopDetail.show("Error", <Message faIcon="harzad" message={e.message} />, 40)
            })
    }, [])
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Header display={writable} label={'Spatial Unit'}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={!dataPresent ? () => { PopDetail.show("Search Spatial Unit", <Search refresh={refresh} />, 80) } : () => { }}
                    /><strong> Search</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={dataPresent ? () => { PopDetail.show("Edit Spatial Unit Details", <Details isPresent={dataPresent} readOnly={false} data={data} edit={true} refresh={refresh} />, 55) } : () => { }}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={dataPresent ? remove : () => { }}
                    /><strong> Remove</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-eye'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={dataPresent ? () => { } : () => { }}
                    /><strong> View</strong>
                </div>
            </Header>
            <Map json={json} />
            <Details isPresent={dataPresent} readOnly={true} data={data} edit={false} />
        </div>
    )
}

const style = {
    inputHeight: {
        height: "25px",
        width: '100%'
    },
    label: {
        color: "#888",
        fontSize: "15px",
        fontWeight: "normal !important",
        marginBottom: "1px"
    }
}