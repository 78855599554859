import API from "../API";

export default class RTReadService{
    static getVacantSpatial(){
        return API.ax.get(`rt/vacant/spatial`).catch(e=>{console.log(e);})
    }

    static getBaunit(baunitId) {
        return API.ax.get("land/baunit/"+baunitId).catch(e=>{console.log(e);})
    }
    static getRtApproval(transactionId) {
        return API.ax.get("rt/rt-approval/"+transactionId).catch(e=>{console.log(e);})
    }
}
