import React, {useState} from 'react'
import OutBuilding from "./OutBuilding";
import BuildingForOuter from "./BuildingForOuter";
import {OutBuildingProvider} from "./outer-buildings";

export default function BuildingHelper() {
    const [currentTab, currentTabSet] = useState(1)
return(
<OutBuildingProvider>
    <div style={{height:'100vh'}}>
        <div className={'row'} style={{height:'6vh'}}>
            <div className={'col col-sm-12'} style={{background:'#fff', padding:'.2rem'}}>
                <div className={currentTab === 1?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{currentTabSet(1)}}>
                    Outer Building
                </div>
                <div className={currentTab === 2?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{currentTabSet(2)}}>
                    OB Details
                </div>
            </div>
        </div>
        <div className={'row'} style={{height:'94vh', margin:0,overflow:'auto'}}>
         <div className={'col col-sm-12'} style={{height:'100%',background:'#fff',padding:'1.5rem', overflow:'auto',border:'0.5px solid #efefef'}}>
                        <div style={currentTab===1?{}:{display:'none'}}>
                            <OutBuilding/>
                        </div>
                        <div style={currentTab===2?{}:{display:'none'}}>
                            <BuildingForOuter/>
                        </div>
                    </div>
                </div>

    </div>
</OutBuildingProvider>
)
}