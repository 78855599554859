import API from "../API";

export default class MobileUpdateService {

    static updateVillageAt(param) {
        return API.ax.put(`mobile/update/layer/village`,param).catch((e)=>{console.log(e);})
    }

    static updatePayments(param) {
        return API.ax.put(`mobile/update/parcel/payments`,param).catch((e)=>{console.log(e);})
    }

    static updatePictures(formData) {
        return API.ax.put('mobile/update/parcel/pictures',formData,{headers:{'Content-Type':'multipart/form-data'}}).catch(e=>{console.log(e);})
    }

    static updateParcelOwner(param) {
        return API.ax.put(`mobile/update/parcel/owner`,param).catch((e)=>{console.log(e);})
    }

    static updateParcelInfo(param) {
        return API.ax.put(`mobile/update/parcel/info`,param).catch((e)=>{console.log(e);})
    }

    static updateCouncil(param) {
        return API.ax.put(`mobile/update/council/project`,param).catch((e)=>{console.log(e);})
    }

    static updateHamlet(param) {
        return API.ax.put(`mobile/update/hamlet`,param).catch((e)=>{console.log(e);})
    }

    static updateLayer(formData) {return API.ax.put(`mobile/update/layers`,formData,{headers: {'Authorization':sessionStorage.getItem("token"), 'Content-Type': 'multipart/form-services'}}).catch((e)=>{console.log(e);})}
    static updateMapper(formData) {return API.ax.put(`mobile/update/layer`,formData).catch((e)=>{console.log(e);})}

    static updateInstitution(formData){
        return API.ax.put('mobile/update/parcel/institution',formData,{headers:{'Content-Type':'multipart/form-data'}}).catch(e=>{console.log(e);})
    }
    static changeProjectLayer(param) {
        return API.ax.put(`mobile/update/users/layer`,param)
    }
    static updateProject(param) {
        return API.ax.put(`mobile/update/project`,param).catch((e)=>{console.log(e);})
    }
    static updateProjectInfo(param) {
        return API.ax.put(`mobile/update/project/info`,param).catch((e)=>{console.log(e);})
    }

    static resetUser(sid){return API.ax.put(`mobile/update/reset/${sid}`,).catch((e)=>{console.log(e);})}
}
