import API from "../API";


export default class LadCheckListRead {
    static ladCheckList(transactionId) {
        return API.ax.get(`land/lad-checklist/${transactionId}`).catch(e => {
            console.log(e);
        })
    }

    static ladCheckListQuestions(transactionName) {
        return API.ax.get(`land/lad-questions/${transactionName}`).catch(e => {
            console.log(e);
        })
    }
}
