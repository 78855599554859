import * as React from "react";
import Wait from "./wait";

export default class PopDetail extends React.Component{

    static self = null
    constructor(props) {
        super(props)

        PopDetail.self = this

        this.state = {
            title:'Loading...',
            current_pane:<div>Loading...</div>,
        }

    }

    static height = 80
    static show(title_,pane,height=80){
        PopDetail.height = height
        PopDetail.self.setState({
            title:title_,
            current_pane: pane
        })

        document.getElementById('pop_detail').style.display='inherit'
        document.getElementById('div-body').style.height=PopDetail.height+'vh'
    }

    static confirm(title_,description,handler,id,noHandler,height=0){
        if(height===0){
            PopDetail.height = 23
        }else{
            PopDetail.height = height
        }


        let pane = <div style={{textAlign:'center',padding:'16px'}}>
            <h4>{description}</h4>
            <hr/>
            <button onClick={()=>handler(id)} className={'submit'}>Yes</button>
            <button onClick={()=>noHandler()}>No</button>
        </div>
        PopDetail.self.setState({
            title:title_,
            current_pane: pane
        })


        document.getElementById('pop_detail').style.display='inherit'
        document.getElementById('div-body').style.height=PopDetail.height+'vh'
    }

    static dismiss(){
        PopDetail.self.setState({
            title:'loading.....',
            current_pane:<div>Loading...</div>
        })
        PopDetail.self.state = null
        document.getElementById('pop_detail').style.display='none'
    }

    static download(path){
        Wait.show('Downloading....')
        const link = document.createElement('a');
        link.href = path;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Wait.dismiss()


    }

    render() {
        return(<div id={'pop_detail'} style={{verticalAlign:'middle',background:'#fff',zIndex:1001,display:'none',position:'absolute',
            backgroundColor:'#fffa',top:0,left:0,right:0,textAlign:'center'}}>
            <div style={{height:'100vh',textAlign:'center',width:'100vw',verticalAlign:'middle',display: 'table-cell'}}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col col-sm-12'}
                             style={{backgroundColor:'#fff',padding:0,border:'2px solid #fff',boxShadow:'0 0 100px #888'}}>
                            <div id={'div-body'} style={{height:PopDetail.height+'vh',width:'50px',backgroundColor:'#484848'}}>
                                <span onClick={()=>PopDetail.dismiss()} className={'fa fa-times-circle'}
                                      style={{color:'#fff',cursor:'pointer',fontSize:'20px',marginTop:'8px'}}/>
                            </div>

                            <div style={{position:'absolute',textAlign:'left',top:0,right:0,bottom:0,left:'50px',float:'left'}}>
                                <div className={'col col-sm-12'} style={{paddingRight:0,width:'100%',lineHeight:'40px',height:'40px',backgroundColor:'#ccc'}}>
                                    <span style={{marginTop:'16px',fontWeight:'bold'}}>
                                        {this.state.title}
                                    </span>

                                </div>
                                <div style={{height:'94%',
                                    overflowY:'auto', padding:'24px',
                                    overflowX:'auto'}}>
                                    {this.state.current_pane}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>)
    }
}