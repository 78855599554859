import * as React from "react";
import PopDetail from "../../../popdetail";

import * as LF from "leaflet";
import "leaflet.utm";

import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'


import uncheck from "../../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import Wait from "../../../wait";
import check from "../../../../../../resource/img/checkbox/checkbox_checked_16.png";
import UgSpatial from "../../../../maps/ug_spatial";
import TransactionPopup from "../../transaction_popup";
import TPReadService from "../../../../../services/tp/tp_read";
import UploadSpatialPane from "../add/upload_spatial";
import API from "../../../../../services/API";
import Basemap from "../../../../maps/basemap";
import PreviewPrintDocument from "../../pops/preview_print_document";
import QueuePane from "../queue_pane";
export default class NewTpSpatialUnitPane extends React.Component{
    static self = null

    constructor(props) {
        super(props);
        this.state = {
            tp_drawings:[],
            screenName:'',
            can_upload:'inherit',
            in_queue:false,
            remainingTime:''
        }

        NewTpSpatialUnitPane.self = this

        this.toggleCheck = this.toggleCheck.bind(this)
        this.downloadSpatial = this.downloadSpatial.bind(this)
    }

    static ids(){ return NewTpSpatialUnitPane.self.state.ids }

    static loadData(screenName=''){

        Wait.show("Loading TP...")
        TPReadService.tpList(TransactionPopup.id()).then(r => {
            Wait.dismiss()
            try {

                console.log(r.data)

                if (r.data.in_queue===true){
                    NewTpSpatialUnitPane.self.setState({
                        in_queue:true
                    })
                    return
                }

                UgSpatial.clear()

                let ids = ''
                r.data.result.forEach(dt=>{ids+=','+dt.id})

                NewTpSpatialUnitPane.self.setState({
                    tp_drawings:r.data.result,
                    ids:ids.toString().substring(1),
                    screenName:screenName
                    //can_upload:TransactionPopup.task().startsWith('Receive')?'inherit':'none'
                })



                UgSpatial.clear()

                let ly_group = new LF.FeatureGroup();
                let sat = Basemap.googleSat(),
                    hybrid = Basemap.googleHybrid(),
                    blank = LF.tileLayer('')

                let map = LF.map('map', {
                    layers: [blank, ly_group],
                    attributionControl: false,
                    fullscreenControl: true,
                    maxZoom:10000
                }).setView([-6.085936, 35.711995], 4)

                let baseLayers = {
                    "<span style='color: gray'>Blank</span>": blank,
                    "Satellite": sat,
                    "Hybrid": hybrid
                }

                let filter =  'DWITHIN(geom,'+r.data.centroid+',1000,meters)'//'transaction_id='+TransactionPopup.id()
                let layername = 'tp_spatial_unit_history'
                let selected

                try{

                    let url = API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson'
                    console.log(url)
                    API.ax.get(url).then(r=>{
                        Wait.dismiss()
                        try {
                            r.data.features.forEach(p=>{

                                let myLayer = LF.geoJSON(p,{
                                    onEachFeature:(f,l)=>{
                                        LF.marker(l.getBounds().getCenter(),{
                                            icon:LF.divIcon({
                                                className:'bindTooltipBig',
                                                html:'',//f.properties.vblock_id,
                                                iconSize:[100,40]
                                            })
                                        }).addTo(ly_group)


                                        l.setStyle({
                                            color:f.properties.status==='Approved'?'#00ff00':'#901'
                                        })
                                    },
                                    coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                                    weight:3,zIndex:2, fillOpacity: 0
                                }).on('dblclick',e=>{

                                    /*ValBlockSpatialUnitPane.self.openRatesAt({
                                        id:ValBlockSpatialUnitPane.self.state.id,
                                        blockId:ValBlockSpatialUnitPane.self.state.vblock_id,
                                        status:ValBlockSpatialUnitPane.self.state.status,
                                    })*/

                                }).on('click', function (e) {

                                    if (selected) {e.target.resetStyle(selected)}
                                    selected = e.layer

                                    /*ValBlockSpatialUnitPane.self.setState({
                                        region:p.properties.region,
                                        district:p.properties.district,
                                        council:p.properties.council,
                                        locality:p.properties.locality,
                                        status:p.properties.status,
                                        vblock_id:p.properties.vblock_id,
                                        id:p.id.split('.')[1],
                                        blockId:p.properties.blockId
                                    })*/
                                    selected.bringToFront()
                                    selected.setStyle({color: '#000', weight:4})
                                    map.fitBounds(selected.getBounds())

                                })
                                myLayer.addTo(ly_group)
                            })
                            let myLayer = LF.geoJSON(r.data,{coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();}})
                            try{ map.fitBounds(myLayer.getBounds())}catch (e) { }
                        }catch (e){
                            console.log(e)
                        }

                    })

                }catch (e){console.log(e)}

                let ly_vb = UgSpatial.getLayerByName('valuation_block',filter).addTo(map)
                let ly_tp = UgSpatial.getLayerByName('tp_spatial_unit_history',filter+' transaction_id not in ('+TransactionPopup.id()+')').addTo(map)
                ly_vb.bringToFront()

                let overlays = {
                    'Valuation Blocks':ly_vb,
                    'TP Drawings':ly_tp,
                    'Current Layer':ly_group,
                }

                UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)


            }catch (e) {
                console.log(e)
            }
        })
    }


    toggleCheck(i){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            return;
        }
        doc.src=uncheck;
    }

    previewTP(){
        PopDetail.show('Preview TP',<PreviewPrintDocument type={'tp'}/>)
    }

    render() {
        return(this.state.in_queue===true?<QueuePane/>:<div>

            <div className={'row'} style={{marginBottom:'4px'}}>


                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'20px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{display:TransactionPopup.task()==='Print Registered TP'?'inherit':'none'}}>
                            <div>
                                <b>Select Layers</b>
                            </div>
                            <br/>
                            <div>
                                <span><input type={'checkbox'}/> Parcels</span><br/>
                                <span><input type={'checkbox'}/> Buildings</span><br/>
                                <span><input type={'checkbox'}/> Contours</span><br/>
                                <span><input type={'checkbox'}/> Point</span><br/>
                                <span><input type={'checkbox'}/> Ponds</span><br/>
                                <span><input type={'checkbox'}/> Power Lines</span><br/>
                                <span><input type={'checkbox'}/> Railways</span><br/>
                                <span><input type={'checkbox'}/> Rivers</span><br/>
                                <span><input type={'checkbox'}/> Roads</span><br/>
                            </div>
                            <br/>
                            <div style={{textAlign:'center'}}>
                                <button onClick={this.previewTP}>Preview</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>

                    <div className={'row'} style={{background:'#fff',height:'100%',padding:'4px',border:'0.5px solid #efefef'}}>
                        <div className={'col col-sm-12'} style={{height:'40px',lineHeight:'40px'}}>
                            <b>Map</b>

                            <div style={{float:'right'}}>
                                <span style={{cursor:'pointer'}} onClick={()=>NewTpSpatialUnitPane.loadData()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-refresh screen-action'}/>Refresh
                                </span>
                                <span style={{cursor:'pointer'}} onClick={()=>PopDetail.show('Upload Spatial',
                                    <UploadSpatialPane type={'tp_spatial'} tp_drawings={this.state.tp_drawings}/>,44)}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-upload screen-action'}/>Upload Spatial
                                </span>

                                <span style={{cursor:'pointer',marginRight:'16px'}} onClick={()=>this.downloadSpatial()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-download screen-action'}/>Download Spatial
                                </span>


                            </div>
                        </div>

                        <div className={'col col-sm-12'} style={{height:'92%',border:'1px solid #ccc',padding:0}}>

                            <div className={'col col-sm-12'} style={{background:'#efefef',padding:0,float:'left',height:'100%'}} id={'ramani'}/>

                        </div>

                    </div>
                </div>

            </div>


            <div className={'row'} >

                <div className={'col col-sm-12'} style={{paddingRight:'4px',height:'27vh'}}>
                    <div style={{background:'#fff',padding:'4px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{marginTop:'8px',width:'100%',float:'left'}}>
                            <div className={'tab tab-active no-bg'}><b>Parcels</b></div>
                            <div className={'tab no-bg'}><b>Buildings</b></div>
                            <div className={'tab no-bg'}><b>Contours</b></div>
                            <div className={'tab no-bg'}><b>Point</b></div>
                            <div className={'tab no-bg'}><b>Ponds</b></div>
                            <div className={'tab no-bg'}><b>Power</b></div>
                            <div className={'tab no-bg'}><b>Railways</b></div>
                            <div className={'tab no-bg'}><b>Rivers</b></div>
                            <div className={'tab no-bg'}><b>Roads</b></div>
                        </div>
                        <div style={{borderTop:'1px solid #ccc',float:'left',width:'100%'}}>

                            {this.state.tp_drawings.length > 0?
                                <table className={'tb'} style={{width:'100%'}}>
                                    <thead>
                                    <tr>
                                        <th>PARCEL ID</th>
                                        <th>LOT TYPE</th>
                                        <th>USE</th>
                                        <th>AREA</th>
                                        <th>BLOCK ID</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.tp_drawings[0].parcels.map((d,i)=>{
                                        return(<tr key={i}>
                                            <td>{d.parcelId}</td>
                                            <td>{d.lotType}</td>
                                            <td>{d.use}</td>
                                            <td>{d.area}</td>
                                            <td>{d.blockId}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                                :
                                <div>No data</div>
                            }

                        </div>



                    </div>
                </div>


            </div>







        </div>)
    }

    downloadSpatial() {
        alert('Downloading...')
    }
}
