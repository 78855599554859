import * as React from "react";
import PopDetail from "../../../../popdetail";
import Wait from "../../../../wait";
import ReportCreateService from "../../../../../../services/common/reports/report_create";

export default class CompletedTasksByUser extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            to: '',
            dt0:'',
            dt1: '',
            transaction: '',
            officeId: 0,
            doc_frame:<div/>
        }

        this.handleChange = this.handleChange.bind(this)
        this.generate = this.generate.bind(this)

    }

    componentDidMount() {

    }

    generate(){
        try {
            Wait.show('Generating...')
            ReportCreateService.completedTaskByUser({
                dt0: this.state.dt0,
                dt1: this.state.dt1,
                sort: this.state.sort,
                transaction: this.state.transaction,
                officeId: this.state.officeId
            }).then(r =>{

                Wait.dismiss()
                //PopDetail.dismiss()

                this.setState({
                    doc_frame:<div style={{height:'40vh'}}><embed style={{height:'100%', width:'100%'}} src={"data:application/pdf;base64, "+r.data}/></div>
                })

                /*const url = window.URL.createObjectURL(new Blob([r.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Pending Transaction Summary.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();*/
            })
        }catch (e) {
            console.log(e)
        }
    }

    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}

    render() {
        return(<div className={'col col-sm-12'}>

            <table style={{width:'100%'}}>
                <tbody>
                <tr style={{color:'darkcyan'}}>
                    <td>* Submitted Date From</td>
                    <td>* Submitted Date To</td>
                </tr>
                <tr>
                    <td>
                        <input type={'date'} style={{width:'100%'}} name={'dt0'} onChange={this.handleChange} />
                    </td>
                    <td>
                        <input type={'date'} style={{width:'100%'}} name={'dt1'} onChange={this.handleChange}/>
                    </td>
                </tr>
                <tr style={{color:'darkcyan'}}>
                    <td>Office</td>
                    <td>Transactions</td>
                </tr>
                <tr>
                    <td>
                        <select  style={{width:'100%'}} name={'officeId'} onChange={this.handleChange}>
                            <option value={0}></option>
                            <option value={0}>DODOMA CITY COUNCIL</option>
                        </select>
                    </td>
                    <td>
                        <select  style={{width:'100%'}} name={'transaction'} onChange={this.handleChange}>
                            <option></option>
                            <option>All Transactions</option>
                        </select>
                    </td>
                </tr>


                </tbody>
            </table>
            <hr/>
            <div className={{textAlign:'right',marginBottom:'18px'}}>
                <button onClick={this.generate}>Generate</button>
                <button className={'default'} onClick={PopDetail.dismiss}>Cancel</button>
            </div>

            {this.state.doc_frame}
        </div>)
    }
}