import * as React from "react";


import * as LF from "leaflet";
import UgSpatial from "../../../../maps/ug_spatial";
import Basemap from "../../../../maps/basemap";
import API from "../../../../../services/API";

import "leaflet.utm"
import Wait from "../../../wait";
import TxReadService from "../../../../../services/transaction/transaction_read";
import TransactionPopup from "../../transaction_popup";

export default class GROMap extends  React.Component{
    static self = null
    static map = null

    constructor(props) {
        super(props);
        this.state = {
            tp_drawings: [],
            layers: [],
            data:[],
            page_status:'Loading...'
        }

        GROMap.self = this
    }

    static loadSpatial() {
        Wait.show('Loading Spatial..')
        try {
            TxReadService.getSpatialUnitTransaction(TransactionPopup.id()).then(d=>{
console.log("data with centroid",d)
                try {
                    let arr = []
                    GROMap.self.setState({data:d.data.result})
                    d.data.result.forEach(r=>arr.push(r.spatialUnitId))

                    UgSpatial.clear("map3")

                    let ly_group = new LF.FeatureGroup();
                    let sat = Basemap.googleSat(),
                        hybrid = Basemap.googleHybrid(),
                        blank = LF.tileLayer('')

                    let map = LF.map('map', {
                        layers: [blank, ly_group],
                        attributionControl: false,
                        fullscreenControl: true,
                        maxZoom:10000
                    }).setView([-6.085936, 35.711995], 4)


                    let arr_str = arr.toString()

                    let id = arr_str.replace('[','').replace(']','')

                    let layername = 'spatial_unit'

                    Wait.show('Loading Spatial...')


                    let filter = 'DWITHIN(geom,'+d.data.centroid+',1000,meters)'
                    let ly_vb = UgSpatial.getLayerByName('valuation_block',filter).addTo(map)
                    let ly_tp = UgSpatial.getLayerByName('tp_spatial_unit',filter).addTo(map)
                    let ly_p = UgSpatial.getLayerByName('point',filter).addTo(map)
                    ly_vb.bringToFront()

                    let overlays = {
                        'Valuation Blocks':ly_vb,
                        'TP Drawings':ly_tp,
                        'Points':ly_p,
                    }

                    let baseLayers = {
                        "<span style='color: gray'>Blank</span>": blank,
                        "Satellite": sat,
                        "Hybrid": hybrid
                    }

                    UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)
                    console.log("Filter",filter)
let url=API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson';
console.log("URL==>",url);


API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                        Wait.dismiss()
                        try {
                         //   console.log("R==>",r)
                            r.data.features.forEach(p=>{
                                LF.geoJSON(p,{
                                    onEachFeature:(ft,ly)=>{
                                        console.log("ft Data==>",ft)
                                        let label = LF.marker(ly.getBounds().getCenter(),{
                                            icon:LF.divIcon({
                                                className:'bindTooltipBig',
                                                html:ft.properties.label,
                                                iconSize:[100,40]
                                            })
                                        }).addTo(map)
                                    },
                                    coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                                    color: '#888',weight:p.properties.boundary?4:3,zIndex:1, fillOpacity: 0
                                }).addTo(map);

                                if (id.split(',').includes(p.id.replace(layername+'.',''))){
                                    let myLayer = LF.geoJSON(p,{
                                        onEachFeature:(ft,ly)=>{
                                            let label = LF.marker(ly.getBounds().getCenter(),{
                                                icon:LF.divIcon({
                                                    className:'bindTooltipBig',
                                                    html:ft.properties.lot_number,
                                                    iconSize:[100,40]
                                                })
                                            }).addTo(map)
                                        },
                                        coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                                        color: '#ff0000',weight:p.properties.boundary?4:3,zIndex:1, fillOpacity: 0
                                    }).addTo(map);
                                    try{ map.fitBounds(myLayer.getBounds())}catch (e) { }
                                }
                            })
                            Wait.dismiss()
                        }catch (e) {
                            console.log(e)
                        }
                    })

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }


    }







    render() {
        return(<div>

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        {this.props.screen==='print'?
                            <div>
                                <div>
                                    <b>Select Layers</b>
                                </div>
                                <br/>
                                <div>
                                    <span><input type={'checkbox'}/> Parcels</span><br/>
                                    <span><input type={'checkbox'}/> Buildings</span><br/>
                                    <span><input type={'checkbox'}/> Contours</span><br/>
                                    <span><input type={'checkbox'}/> Point</span><br/>
                                    <span><input type={'checkbox'}/> Ponds</span><br/>
                                    <span><input type={'checkbox'}/> Power Lines</span><br/>
                                    <span><input type={'checkbox'}/> Railways</span><br/>
                                    <span><input type={'checkbox'}/> Rivers</span><br/>
                                    <span><input type={'checkbox'}/> Roads</span><br/>
                                </div>
                                <br/>
                                <div style={{textAlign:'center'}}>
                                    <button onClick={this.previewSMD}>Preview</button>
                                </div>

                            </div>:
                            <div>*</div>}
                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>

                    <div className={'row'} style={{background:'#fff',height:'100%',padding:'4px',border:'0.5px solid #efefef'}}>
                        <div className={'col col-sm-12'} style={{height:'40px',lineHeight:'40px'}}>
                            <b>Map</b>

                            {/*<div style={{float:'right'}}>
                                <span style={{cursor:'pointer'}} onClick={()=>SpatialUnitScreen.loadData()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-refresh screen-action'}/>Refresh
                                </span>

                                <span style={{cursor:'pointer'}} id={'btn_upload'} onClick={()=>PopDetail.show('Upload Spatial',
                                    <SpatialUploadPane tp_drawings={this.state.tp_drawings} type={this.props.type}/>,44)}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-upload screen-action'}/>Upload Spatial
                                </span>

                            </div>*/}
                        </div>

                        <div className={'col col-sm-12'} style={{height:'92%',border:'1px solid #ccc',padding:0}}>

                            <div className={'col col-sm-12'}
                                 style={{background:'#efefef',padding:0,float:'left',height:'100%'}}
                                 id={'map3'}/>



                        </div>

                    </div>
                </div>

            </div>




            <div className={'row'} >

                <div className={'col col-sm-12'} style={{paddingRight:'4px',height:'27vh'}}>
                    <div style={{background:'#fff',padding:'4px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <table style={{width:'100%'}} className={'tb'}>
                            <tbody>
                            <tr>
                                <td>Search</td>
                                <td><input style={{width:'100%'}}/></td>
                                {/*<td>Applicant Role</td>
                                <td><input style={{width:'100%'}}/></td>*/}
                            </tr>
                            </tbody>
                        </table>

                        <div style={{borderTop:'1px solid #ccc',float:'left',width:'100%'}}>

                            <table style={{width:'100%'}} className={'tb'}>
                                <thead>
                                <tr>

                                    <th>UPID</th>
                                    <th>REG. PLAN</th>
                                    <th>COUNCIL</th>
                                    <th>LOCATION</th>
                                    <th>BLOCK</th>
                                    <th>LOT TYPE</th>
                                    <th>TP NUMBER</th>
                                    <th>PLOT NO</th>
                                    <th>STATUS</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.map((d,i)=>{
                                    return(<tr key={i}>
                                        <td>{d.id}</td>
                                        <td>{d.regPlanNumber}</td>
                                        <td>{d.districtName}</td>
                                        <td>{d.localityName}</td>
                                        <td>{d.blockNumber}</td>
                                        <td>{d.lotType}</td>
                                        <td>{d.drawingNumber}</td>
                                        <td>{d.lotNumber}</td>
                                        <td>{i}</td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>

                        </div>



                    </div>
                </div>


            </div>







        </div>)
    }

}
