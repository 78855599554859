import API from "../API";

export default class DocumentService {

    static readTxDocuments(txId) {
        return API.ax.get(`document/read-metadata/${txId}`)
    }
    static readDoc(docId) {
        return API.ax.get(`document/read-doc/${docId}`)
    }
}