import * as React from "react";
import PopDetail from "../../popdetail";

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import Wait from "../../wait";
import UgSpatial from "../../../maps/ug_spatial";
import * as LF from "leaflet";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../resource/img/checkbox/checkbox_checked_16.png";
import PanAddProjectLayer from "../pops/pane_add_project_layer";
import MobilePopup from "../mobile_popup";
import MobileReadService from "../../../../services/mobile/mobile_read";
import API from "../../../../services/API";
import Basemap from "../../../maps/basemap";
import PaneUpdateLayer from "../pops/pane_update_layers";
import MobileDeleteService from "../../../../services/mobile/mobile_delete";

export default class MobileProjectLayerPane extends React.Component {
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            tp_drawings: [],
            layers: [],
            description_title: 'Properties',
        }

        MobileProjectLayerPane.self = this
        this.toggleCheck = this.toggleCheck.bind(this)
        this.searchLayer = this.searchLayer.bind(this)
        MobileProjectLayerPane.handleDelete = MobileProjectLayerPane.handleDelete.bind(this)
    }

    componentDidMount() {
        MobileProjectLayerPane.loadData()
    }

    static loadData(layer_name = 'all') {
        Wait.show("Loading Data...")
        try {
            let prj_id = MobilePopup.id()
            Wait.show("Please Wait...")
            MobileReadService.mobileLayers(prj_id, true, layer_name).then(d => {
                Wait.dismiss()
                try {
                    console.log(d.data.results)

                    MobileProjectLayerPane.self.setState({ layers: d.data.results })
                    UgSpatial.clear()

                    let ly_group = new LF.FeatureGroup();
                    let ly_features = new LF.FeatureGroup();
                    let sat = Basemap.googleSat(),
                        hybrid = Basemap.googleHybrid(),
                        blank = LF.tileLayer('')

                    let map = UgSpatial.map == null ? LF.map('map', {
                        layers: [blank, ly_group, ly_features],
                        attributionControl: false,
                        fullscreenControl: true,
                        maxZoom: 10000
                    }).setView([-6.085936, 35.711995], 4) : UgSpatial.map


                    let baseLayers = {
                        "<span style='color: gray'>Blank</span>": blank,
                        "Satellite": sat,
                        "Hybrid": hybrid
                    }

                    let overlays = {
                        'Project Boundaries': ly_group,
                        'Project Features': ly_features,

                    }

                    LF.control.layers(baseLayers, overlays, { position: 'bottomleft' }).addTo(map)

                    MobileProjectLayerPane.initDrawEvents(map)

                    /*map.pm.addControls({
                        position: 'topleft',
                        drawCircle: false,
                    });*/

                    let filter = 'project_id in (' + MobilePopup.id() + ') AND deleted=false'
                    let layername = 'ilmis:mob_layers'


                    API.ax.get(API.geo_ilmis + '/ows?cql_filter=' + filter + '&service=WFS&version=1.0.0&request=GetFeature&typeName=' + layername + '&outputFormat=application%2Fjson').then(r => {
                        console.log(r.data)

                        try {
                            let selected

                            r.data.features.forEach(p => {


                                let myLayer = LF.geoJSON(p, {
                                    onEachFeature: (ft, ly) => {
                                        console.log(ft)
                                        let label = LF.marker(ly.getBounds().getCenter(), {
                                            icon: LF.divIcon({
                                                className: 'bindTooltipBig',
                                                html: ft.properties.label + ' - ' + ft.id.split('.')[1],
                                                iconSize: [100, 40]
                                            })
                                        }).addTo(p.properties.boundary ? ly_group : ly_features)
                                        //label.options.pmIgnore = false;
                                    },
                                    color: p.properties.boundary ? '#000' :
                                        p.properties.allowed ? '#080' : '#f00', weight: p.properties.boundary ? 4 : 3, zIndex: 1, fillOpacity: p.properties.allowed ? 0 : 0.2,
                                    //fillColor:p.properties.allowed?'#000':'#f00'
                                }).on('dblclick', e => {
                                    //this.popDataAt(p.properties)
                                }).on('click', function (e) {
                                    if (selected) { e.target.resetStyle(selected) }
                                    selected = e.layer
                                    selected.bringToFront()
                                    MobileProjectLayerPane.self.setState({ description_title: p.id.replace('mob_layers.', '') + ' - ' + p.properties.label })
                                    selected.setStyle({ color: '#ff0', weight: 4 })
                                    //map.fitBounds(selected.getBounds())
                                    map.setView(selected.getBounds().getCenter());
                                }).addTo(p.properties.boundary ? ly_group : ly_features);

                                /*myLayer.pm.enable({
                                    allowSelfIntersection: false,
                                });*/


                            })

                            let myLayer = LF.geoJSON(r.data.features)

                            try { map.fitBounds(myLayer.getBounds()) } catch (e) { }
                        } catch (e) {
                            console.log(e)
                        }

                    })

                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }

    }



    toggleCheck(i) {
        let doc = document.getElementById('chk_' + i);
        if (doc.src === uncheck) {
            doc.src = check;
            return;
        }
        doc.src = uncheck;
    }

    popFullTable(tb_id = 'bottom_table') {
        let btn = document.getElementById('btn_enl')

        if (btn.innerHTML !== 'Show Map') {
            document.getElementById(tb_id).style.position = 'absolute'
            document.getElementById(tb_id).style.zIndex = 10000000
            document.getElementById(tb_id).style.left = '-1vw'
            document.getElementById(tb_id).style.right = 0
            document.getElementById(tb_id).style.bottom = 0
            document.getElementById(tb_id).style.top = 0
            document.getElementById(tb_id).style.height = '80vh'
            document.getElementById(tb_id).style.overflow = 'auto'
            btn.innerHTML = 'Show Map'
        } else {
            document.getElementById(tb_id).style.position = 'absolute'
            document.getElementById(tb_id).style.zIndex = 10000000
            document.getElementById(tb_id).style.left = '-1vw'
            document.getElementById(tb_id).style.right = 0
            document.getElementById(tb_id).style.bottom = 0
            document.getElementById(tb_id).style.top = '60vh'
            document.getElementById(tb_id).style.height = '34vh'
            document.getElementById(tb_id).style.overflow = 'auto'
            btn.innerHTML = 'Hide Map'
        }

    }

    static handleDelete(id) {
        try {
            Wait.show('Removing Layer...')
            MobileDeleteService.deleteLayer(id).then(r => {
                try {
                    Wait.dismiss()
                    PopDetail.dismiss()
                    MobileProjectLayerPane.self.setState({
                        description_title: 'Properties',
                    })
                    MobileProjectLayerPane.loadData()
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
    noHandler() {
        PopDetail.dismiss()
    }
    delete(d) {
        PopDetail.confirm('Delete ' + d.label, d.label + ' will be removed.', MobileProjectLayerPane.handleDelete, d.id, this.noHandler, 30)
    }

    edit(layer) {
        PopDetail.show('Edit ' + layer.name, <PaneUpdateLayer layers={this.state.layers} layer={layer} />, 30)
    }

    loadDataInLayer = e => {
        this.setState({ [e.target.name]: e.target.value });

        let layer = e.target.value


        this.state.layers.forEach(it => {
            if (it.id === layer) {
                try {
                    this.state.map.fitBounds(it.layer.getBounds())
                    //this.state.map.setView(it.layer.getBounds().getCenter(),100);
                } catch (e) {
                    //console.log(e)
                }
            }
        })
        /* */
    }

    searchLayer = (e) => {
        let val = e.target.value
        if (val === '') {
            val = 'all'
        }
        MobileProjectLayerPane.loadData(val)
    }

    render() {
        return (<div >

            <div className={'row'} style={{ marginBottom: '4px' }}>

                <div className={'col col-sm-3'} style={{ height: '60vh', paddingRight: '4px', paddingLeft: 0 }}>

                    <div style={{ background: '#fff', padding: '24px', height: '100%', border: '0.5px solid #efefef' }}>
                        <div style={{ height: '40px', lineHeight: '40px' }}>
                            <b>{this.state.description_title}</b>
                            <span onClick={() => this.delete({
                                id: this.state.description_title.split('-')[0],
                                label: this.state.description_title.split('-')[1]
                            })} style={{ color: '#f00', cursor: 'pointer', display: this.state.description_title === 'Properties' ? 'none' : 'inherit' }}>
                                <span className={'fa fa-trash'} style={{ marginRight: '8px' }} />Delete
                            </span>
                        </div>

                        <div style={{ width: '100%', marginTop: '8px' }}>



                        </div>

                    </div>

                </div>

                <div className={'col col-sm-9'} style={{ height: '60vh', paddingLeft: '4px' }}>

                    <div style={{ background: '#fff', height: '100%', padding: '24px', border: '0.5px solid #efefef' }}>
                        <div className={'row'} style={{ height: '40px', lineHeight: '40px' }}>

                            <div className={'col col-sm-3'}>
                                <select name={'layer_id'} style={{ width: '100%' }} onChange={(e) => this.loadDataInLayer(e)} id={'sp_layer'} >
                                    <option value={0}>- Choose Project Layer -</option>
                                    <option value={0}>- All Project Layer -</option>
                                    {this.state.layers.map((d, i) => {
                                        return (<option key={i} value={d.id}>{d.label} - {d.name}</option>)
                                    })}
                                </select>
                            </div>
                            <div className={'col col-sm-3'}>
                                <select name={'layer_id'} style={{ width: '100%' }} onChange={(e) => this.loadDataInLayer(e)} id={'sp_layer'} >
                                    <option value={0}>- Layer Permission -</option>
                                    <option>All</option>
                                    <option>Allowed</option>
                                    <option>Restricted</option>
                                </select>
                            </div>

                            <div style={{ textAlign: 'right' }} className={'col col-sm-6'}>
                                <span style={{ cursor: 'pointer', marginRight: '24px' }}
                                    onClick={() => MobileProjectLayerPane.loadData()}>
                                    <span style={{ marginLeft: '16px' }} className={'fa fa-refresh screen-action'} />
                                    Refresh
                                </span>

                                <span style={{ cursor: 'pointer', marginRight: '24px' }}
                                    onClick={() => PopDetail.show('Upload Layer', <PanAddProjectLayer />, 60)}>
                                    <span style={{ marginLeft: '16px' }} className={'fa fa-upload screen-action'} />
                                    Add New Layer
                                </span>

                            </div>
                        </div>

                        <div className={'row'} style={{ height: '96%' }}>
                            <div style={{ width: '100%', height: '100%', background: '#fff' }} id={'ramani'}>

                            </div>
                        </div>

                    </div>


                </div>

            </div>

            <div className={'row'} style={{ overflow: 'auto' }}>

                <div className={'col col-sm-12'} style={{ background: '#fff', padding: 0, height: '34vh' }} id={'bottom_table'}>
                    <div className={'row'} style={{ paddingLeft: '16px', paddingTop: '16px' }}>
                        <div className={'col col-sm-3'}>
                            <input placeholder={'Search Here'} style={{ width: '100%' }} onChange={(e) => this.searchLayer(e)} />
                        </div>
                        <div className={'col col-sm-3'}>
                            <select style={{ width: '100%' }}>
                                <option>- Select Street/Hamlet -</option>
                            </select>
                        </div>
                        <div className={'col col-sm-3'}>
                            <select style={{ width: '100%' }}>
                                <option>- Select Stage -</option>
                            </select>
                        </div>
                        <div className={'col col-sm-3'}>
                            <button className={'default'}>Search</button>
                            <button id={'btn_enl'} style={{ float: 'right', marginRight: '24px' }} onClick={() => this.popFullTable()}>Hide Map</button>
                        </div>
                    </div>

                    <div className={'col col-sm-12'} >
                        <table className={'tb'} style={{ width: '100%', marginTop: '8px', border: '1px solid #ddd' }}>
                            <thead>
                                <tr>
                                    <th />
                                    <th>LAYER ID</th>
                                    <th>CREATED DATE</th>
                                    <th>LAYER NAME</th>
                                    <th>LAYER LABEL</th>
                                    <th>LAYER TYPE</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.layers.map((d, i) => {
                                    return (<tr key={i}>
                                        <td style={{ textAlign: 'center' }}>
                                            <img id={'chk_' + (i + 1)} alt={'chk_' + (i + 1)} src={uncheck} onClick={() => this.toggleCheck(i + 1)} />
                                        </td>
                                        <td><b>{d.id}</b></td>
                                        <td>{d.created_time}</td>
                                        <td><b>{d.name}</b></td>
                                        <td>{d.label}</td>
                                        <td>{d.boundary ? <span style={{ color: '#008b8b' }}>Project Boundary</span> : 'Project Layer'}</td>
                                        <td style={{ textAlign: 'right' }}>
                                            <button style={{ width: '50px' }} className={'default'} onClick={() => this.edit(d)}>Edit</button>
                                            <button style={{ width: '50px' }} className={'default'} onClick={() => this.delete(d)}>Delete</button>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>

                </div>


            </div>


        </div>)
    }

    static initDrawEvents(map) {
        /* map.on('pm:drawstart', e => {
             console.log(e);
         });*/
    }
}