import * as React from "react";
import PropertyGRO from "../screen/gro/property_gro";
import TransactionPopup from "../transaction_popup";
import PopDetail from "../../popdetail";
import CLCreateService from "../../../../services/cl/cl_create";

export default class PaneApplyLadApproval extends React.Component{
    constructor(props) {
        super(props);
        this.acceptLadDecision=this.acceptLadDecision.bind(this)
    }

    render() {
        return(<div>
            <div className={'row'}>
                <h3>By Clicking OK button you accept</h3>
                <button onClick={this.acceptLadDecision}>OK</button>
            </div>
        </div>)
    }
    acceptLadDecision=()=>{
        console.log("Baunit property",PropertyGRO.self.state.baunitId)
        let datam={
            transactionId:TransactionPopup.id(),
            username:sessionStorage.getItem('username'),
            baunitId:PropertyGRO.self.state.baunit.baunitId,
            decision:this.props.decision,
            taskName:TransactionPopup.task(),
            taskId:TransactionPopup.taskId()

        }

        console.log("lad approval",datam)
        CLCreateService.saveLadAcceptance(datam).then(d=>{
        })
        PopDetail.dismiss()
    }
}
