import API from "../API";

export default class SMDReadService {

    static read(txId) {
        return API.ax.get("smd/read/" + txId).catch(e => { console.log(e); })
    }
    static listParcels(project,page,noOfRecords){
        return API.ax.get(`smd/read/spatialunits/list/approved/${project}/${page}/${noOfRecords}`).catch(e=>{console.log(e)});
    }
    static listIPC(transaction){
        return API.ax.get(`smd/read/ipc-geom/${transaction}`).catch(e=>{
            console.log(e)
        })
    }
    static downloadSHP(txId, create = true) {
        if (create) {
            return API.ax.get(`smd/read/download?txId=${txId}`)
                .catch((e) => { console.log(e); })
        }
        return API.ax.get(`smd/read/zip?txId=${txId}`)
            .catch((e) => { console.log(e); })
    }

    static readParcelWithStatus(status = 'Legacy') {
        return API.ax.get("smd/read/status/" + status).catch(e => { console.log(e); })
    }

    static listSR(txId) {
        console.log(txId)
        return API.ax.get("smd/read/" + txId + "/sr").catch(e => { console.log(e); })
    }
    static listSRAttached(txId) {
        return API.ax.get("smd/read/" + txId + "/sr/attached").catch(e => { console.log(e); })
    }

    static listSurveyor() {
        return API.ax.get("smd/read/surveyors").catch(e => { console.log(e); })
    }
    static listProfessionals(type) {
        return API.ax.get(`professional/professional-by-type/${type}`).catch(e => { console.log(e); })
    }

    static getPolyByPoint(x, y) {
        return API.ax.get(`smd/read/poly-by-point/${x}/${y}`).catch(e => { console.log(e); })
    }
    static summ
    static getPolyByLotNumbers(lots) {
        return API.ax.get(`smd/read/poly-by-lots/${lots}`).catch(e => { console.log(e); })
    }

    static getSmdApproval(transactionId) {

        return API.ax.get("smd/read/smd-approval/" + transactionId).catch(e => {
            console.log(e);
        })
    }

    static getInstructionByNumber(instructionNo) {

        return API.ax.get("smd/read/smd-instruct/" + instructionNo).catch(e => {
            console.log(e);
        })
    }

    static getControlPoints(regionId){
        return API.ax.get("smd/read/controlpoints/"+regionId).catch(e=>{
            console.log(regionId)
        })
    }

    static getRoughCoordinateByTransactionId(txId) {
        console.log(txId)
        return API.ax.get("smd/read/rough-coordinate/" + txId).catch(e => { console.log(e); })
    }

    static readReportSummary(project, stage) {
        return API.ax.get(`smd/read/report/${project}/${stage}/summary`).catch(e => { console.log(e); })
    }

    static readReportData(project, stage) {
        return API.ax.get(`smd/read/report/${project}/${stage}/data`).catch(e => { console.log(e); })
    }
    static readSpatialGeom(transaction){
        return API.ax.get(`smd/read/geom/${transaction}`).catch(e=>{console.log(e)})
    }
    static readSpatialUnits(txId){
        return API.ax.get(`smd/read/spatial-list/${txId}`).catch(e=>{console.log(e)})
    }

}