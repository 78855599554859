export const purpose = [
    {
    "label": "Transfer",
    "value": 3
    },
    {
        "label": "Bail",
        "value": 4
    },
    {
        "label": "Sale",
        "value": 5
    },
    {
        "label": "Probate",
        "value": 6
    },
    {
        "label": "Matrimonial",
        "value": 7
    },
    {
        "label": "Mortgage",
        "value": 2
    },
    {
        "label": "Accounting",
        "value": 8
    },
    {
        "label": "Renewal",
        "value": 10
    },
    {
        "label": "Investment",
        "value": 9
    },
    {
        "label": "Other Purpose",
        "value": 1
    }
]

export const compenstion =[
    {
        "label": "Compensation",
        "value": 11
    }
]