import API from "../API";

export default class TPCreateService{
    static uploadTpLayer(formData) {
        return API.ax.post('tp/create/layer',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }
    static uploadNewTpLayer(formData) {
        /*return API.ax.post('tp/create/temp_layer',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})*/
    }
    static createTP(formData) {
        return API.ax.post('tp/create/tp',formData,
        {
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static createNewTP(formData) {
        return API.ax.post('tp/create/tp',formData,
            {
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).catch(e=>{console.log(e);})
    }

    static createDocument(formData){
        return API.ax.post('tp/create/document',formData).catch(e=>{console.log(e);})
    }
    static updateTpSpatial(formData) {
        return API.ax.post('tp/update/spatial-update',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }
    static freeze(formData) {
        return API.ax.post('tp/update/freeze',formData).catch(e=>{console.log(e);})
    }

    static createTP(formData) {
        return API.ax.post('tp/create/temp_layer',formData,
            {
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).catch(e=>{console.log(e);})
    }
    static createRemark(formData){
        return API.ax.post('tp/create/remark',formData)
    }
    static uploadUnapprovedTpLayer(formData) {
        return API.ax.post('tp/create/temp_layer',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }
}
