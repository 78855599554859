import React, {Component} from 'react';
import TicReadService from "../../../../../services/tic/tic_read";
import TransactionPopup from "../../transaction_popup";
import TicCreateService from "../../../../../services/tic/tic_create";
import Wait from "../../../wait";
import wait from "../../../wait";


class GnInfo extends Component {
    constructor(props) {
        super(props);
        this.state={
            gnNo:"",
            gnDescription:""
        }
        this.saveGnInf=this.saveGnInfo.bind(this)
        this.updateGnInfo =this.updateGnInfo.bind(this)
    }
    componentDidMount() {
        TicReadService.getGnInfo(TransactionPopup.id()).then(d=>{
            console.log("Land info",d.data)
            if(d.data.status===1){



            }
    })
    }

    saveGnInfo=()=>{

        let dataToSend={
            transactionId:TransactionPopup.id(),
            gnNo:this.state.gnNo,
            gnDescription: this.state.gnDescription
        }
        console.log("gn into==>",dataToSend)

        if(this.state.gnNo!==''){
            Wait.show("GN saving","...........saving ... . .")
            TicCreateService.saveGnInfo(dataToSend).then(d=>{
                if(d.data.status===1){
                    this.setState({gnNo:d.data.result.gnNo,gnDescription:d.data.result.gnDescription})
                    wait.dismiss()
                }else {
                    wait.dismiss()
                }


            })
        }

    }

    updateGnInfo(e){
        console.log("gn value",e.target.value)
        this.setState({[e.target.name]:e.target.value})
    }

    render() {
        return (
            <div>
                <b>GN Info</b>
                <table style={{width:'100%'}}>
                    <tbody>
                    <tr style={{color:'#888'}}>
                        <td>Enter GN No.</td>
                        <td>GN Description</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><input name={'gnNo'} type={'text'} style={{width:'100%'}} defaultValue={this.state.gnNo}  onChange={this.updateGnInfo}/></td>
                        <td><input name={'gnDescription'} type={'text'} style={{width:'100%'}} defaultValue={this.state.gnDescription}  onChange={this.updateGnInfo}/></td>
                        <td><button onClick={this.saveGnInfo}>Save</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default GnInfo;