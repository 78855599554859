import * as React from "react";
import PopDetail from "../../../popdetail";

import * as LF from "leaflet";
import "leaflet.utm"


import uncheck from "../../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../../resource/img/checkbox/checkbox_checked_16.png";
import Wait from "../../../wait";
import SpatialUploadPane from "../add/upload_spatial";
import SMDReadService from "../../../../../services/smd/smd_read";
import TransactionPopup from "../../transaction_popup";
import UgSpatial from "../../../../maps/ug_spatial";
import Basemap from "../../../../maps/basemap";
import API from "../../../../../services/API";
import PreviewPrintDocument from "../../pops/preview_print_document";
import UploadSpatialPane from "../add/upload_spatial";
import UploadShapefile from "../add/upload_shapefile";
import UploadSpatialAmendPane from "../add/upload_spatial_amend";

export default class AmendSpatialScreen extends React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            tp_drawings:[],
            data:[],
            show_upload:'inherit',
        }

        AmendSpatialScreen.self = this
        this.toggleCheck = this.toggleCheck.bind(this)
        this.uploadSHP = this.uploadSHP.bind(this)
    }


    static loadData(txId=TransactionPopup.id()){
        try {
            if (AmendSpatialScreen.self.props.transaction.name==='Grant Right of Occupancy'){
                txId = 0
            }
            if (AmendSpatialScreen.self.props.transaction.name==='Compensation Valuation'){
                txId = 0
            }
            if (AmendSpatialScreen.self.props.transaction.name==='Conduct General Valuation'){
                txId = 0
            }
        }catch (e){}



        Wait.show("Loading Data...")

        try {
            SMDReadService.read(txId).then(r=>{
                Wait.dismiss()
                try {
                    AmendSpatialScreen.self.setState({
                        data:r.data.result
                    })

                    Wait.show("Loading Map...")

                    UgSpatial.clear()

                    let ly_group = new LF.FeatureGroup();
                    let sat = Basemap.googleSat(),
                        hybrid = Basemap.googleHybrid(),
                        blank = LF.tileLayer('')

                    let map = LF.map('map', {
                        layers: [blank, ly_group],
                        attributionControl: false,
                        fullscreenControl: true,
                        maxZoom:10000
                    }).setView([-6.085936, 35.711995], 4)



                    let baseLayers = {
                        "<span style='color: gray'>Blank</span>": blank,
                        "Satellite": sat,
                        "Hybrid": hybrid
                    }

                    let filter =  'DWITHIN(geom,'+r.data.centroid+',1000,meters)'//'transaction_id='+TransactionPopup.id()
                    let layername = 'spatial_unit'
                    let selected

                    try{
                        API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                            Wait.dismiss()
                            try {
                                r.data.features.forEach(p=>{

                                    let myLayer = LF.geoJSON(p,{
                                        coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                                        weight:3,zIndex:2, fillOpacity: 0
                                    }).on('dblclick',e=>{


                                    }).on('click', function (e) {

                                        if (selected) {e.target.resetStyle(selected)}
                                        selected = e.layer

                                        selected.bringToFront()
                                        selected.setStyle({color: '#000', weight:4})
                                        map.fitBounds(selected.getBounds())

                                    })
                                    myLayer.addTo(ly_group)
                                })


                                let myLayer = LF.geoJSON(r.data,{coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();}})
                                try{ map.fitBounds(myLayer.getBounds())}catch (e) { }

                            }catch (e){
                                console.log(e)
                            }

                        })

                    }catch (e){console.log(e)}

                    let ly_vb = UgSpatial.getLayerByName('tp_spatial_unit',filter).addTo(map)
                    let ly_tp = UgSpatial.getLayerByName('valuation_block',filter).addTo(map)
                    let ly_point = UgSpatial.getLayerByName('point',filter).addTo(map)

                    ly_vb.bringToFront()

                    let overlays = {
                        'Valuation Blocks':ly_vb,
                        'TP Drawings':ly_tp,
                        'Points':ly_point,
                        'Current Layer':ly_group,
                    }

                    UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)

                }catch (e){

                }
            })
        }catch (e){
            console.log(e)
        }

    }



    toggleCheck(i){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            return;
        }
        doc.src=uncheck;
    }

    previewSMD(){
        PopDetail.show('Preview SMD',<PreviewPrintDocument type={'smd'}/>)
    }

    render() {
        return(<div>

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        {this.props.screen==='print'?
                            <div>
                                <div>
                                    <b>Select Layers</b>
                                </div>
                                <br/>
                                <div>
                                    <span><input type={'checkbox'}/> Parcels</span><br/>
                                    <span><input type={'checkbox'}/> Buildings</span><br/>
                                    <span><input type={'checkbox'}/> Contours</span><br/>
                                    <span><input type={'checkbox'}/> Point</span><br/>
                                    <span><input type={'checkbox'}/> Ponds</span><br/>
                                    <span><input type={'checkbox'}/> Power Lines</span><br/>
                                    <span><input type={'checkbox'}/> Railways</span><br/>
                                    <span><input type={'checkbox'}/> Rivers</span><br/>
                                    <span><input type={'checkbox'}/> Roads</span><br/>
                                </div>
                                <br/>
                                <div style={{textAlign:'center'}}>
                                    <button onClick={this.previewSMD}>Preview</button>
                                </div>

                            </div>:
                            <div>
                                <span>Amendment Options</span>
                                <hr/>


                            </div>}
                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>

                    <div className={'row'} style={{background:'#fff',height:'100%',padding:'4px',border:'0.5px solid #efefef'}}>
                        <div className={'col col-sm-12'} style={{height:'40px',lineHeight:'40px'}}>
                            <b>Amendment</b>

                            <div style={{float:'right'}}>
                                <span style={{cursor:'pointer'}} onClick={()=>AmendSpatialScreen.loadData()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-refresh screen-action'}/>Refresh
                                </span>

                                <span style={{cursor:'pointer'}} id={'btn_upload'} onClick={()=>this.uploadSHP()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-upload screen-action'}/>Upload Spatial
                                </span>
                                <span style={{cursor:'pointer'}} id={'btn_upload'} onClick={()=>this.downloadSHP()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-download screen-action'}/>Download Spatial
                                </span>

                            </div>
                        </div>

                        <div className={'col col-sm-12'} style={{height:'92%',border:'1px solid #ccc',padding:0}}>

                            <div className={'col col-sm-12'} style={{background:'#efefef',padding:0,float:'left',height:'100%'}} id={'ramani'}>

                            </div>



                        </div>

                    </div>
                </div>

            </div>

            <div className={'row'} >

                <div className={'col col-sm-12'} style={{paddingRight:'4px',height:'27vh'}}>
                    <div style={{background:'#fff',padding:'4px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <table style={{width:'100%'}} className={'tb'}>
                            <tbody>
                            <tr>
                                <td>Search</td>
                                <td><input style={{width:'100%'}}/></td>
                                {/*<td>Applicant Role</td>
                                <td><input style={{width:'100%'}}/></td>*/}
                            </tr>
                            </tbody>
                        </table>

                        <div style={{borderTop:'1px solid #ccc',float:'left',width:'100%'}}>

                            <table style={{width:'100%'}} className={'tb'}>
                                <thead>
                                <tr>

                                    <th>UPID</th>
                                    <th>REG. PLAN</th>
                                    <th>COUNCIL</th>
                                    <th>LOCATION</th>
                                    <th>BLOCK</th>
                                    <th>LOT TYPE</th>
                                    <th>TP NUMBER</th>
                                    <th>VERSION</th>
                                    <th>STATUS</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.map((d,i)=>{
                                    return(<tr key={i}>
                                        <td>{d.id}</td>
                                        <td>{d.planNo}</td>
                                        <td>{d.district}</td>
                                        <td>{d.locality}</td>
                                        <td>{d.blockName}</td>
                                        <td>{d.lotType}</td>
                                        <td>{d.drawingNo}</td>
                                        <td>{i}</td>
                                        <td>{i}</td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>

                        </div>



                    </div>
                </div>


            </div>

        </div>)
    }

    downloadSHP(){
        try {
            Wait.show('Creating Shapefile...')
            SMDReadService.downloadSHP(TransactionPopup.id()).then(r  =>{
                Wait.dismiss()
                try {
                    if (r.data.status===0){
                        alert(r.data.message)
                        return
                    }
                    let path = API.base_url+`smd/read/zip?txId=${TransactionPopup.id()}`
                    PopDetail.download(path)

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    uploadSHP() {
        PopDetail.show('Upload Spatial', <UploadSpatialAmendPane/>,60)

    }
}
