import React from 'react'

export default class PropertyTitle extends React.Component {
    static self = null
 constructor() {
     super();
     this.state= {
         rentComputed: []
     }

     PropertyTitle.self = this
 }

 render() {


     return(
         <div className={"row"}>
             <h6>Properties/Titiles</h6>
             <table className={"table"}>
                 <thead>
                 <tr>
                     <th>TITLE NUMBER</th>
                     <th>landRent</th>
                     <th>rentArrears</th>
                     <th>amountDue</th>
                     <th>TOTAL AREA</th>
                     <th>TOTAL VALUE ( TSH )</th>
                     <th>REGISTRATION NO.</th>
                     <th>VERSION</th>
                     <th>STATUS</th>
                 </tr>
                 </thead>
                 <tbody>
                 {this.state.rentComputed.map(d=>{
                     return(<tr>

                     </tr>)
                 })}

                 </tbody>
             </table>
         </div>
     )
 }
}
