import React, {useEffect, useState} from "react";
import PopDetail from "../../../popdetail";
import TxCreateService from "../../../../../services/transaction/transaction_create";
import Conditions from "./conditions";

export default class AddCondition extends React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state={
            number:'',
            category:'',
            type:'',
            endDate:'',
            condition:''
        }
        this.handleSaveCondition=this.handleSaveCondition.bind(this)
        this.handleCondition =this.handleCondition.bind(this)
        this.handleNumber = this.handleNumber.bind(this)
        this.handleCategory = this.handleCategory.bind(this)
        this.handleType = this.handleType.bind(this)
        this.handleEndDate=this.handleEndDate.bind(this)
        AddCondition.self = this
    }
   /* const [maxDate,maxDateSet] = useState('')

    useEffect(() => {
        let today = new Date().toISOString().slice(0, 10)
        maxDateSet(today);
    }, []);
*/

    handleCondition(e){
    this.setState({condition:e.target.value})

    }

    handleNumber(e){
        this.setState({number:e.target.value})
    }

    handleCategory(e){
        this.setState({category:e.target.value})
    }
    handleType(e){
        this.setState({type:e.target.value})
    }
    handleEndDate(e){
        this.setState({endDate:e.target.value})
    }
render() {

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col col-sm-6 form-group'}>
                    <label htmlFor={'number'}>* Number</label>
                    <input onChange={this.handleNumber} name={'number'} id={'number'} className={'form-control'}/>
                </div>
                <div className={'col col-sm-6 form-group'}>
                    <label htmlFor={'category'}>* Category</label>
                    <select onChange={this.handleCategory}
                        name={'category'}
                        id={'category'}
                        className={'form-control'}
                    >
                        <option value={''}> --Select Type--</option>
                        <option>Main</option>
                        <option>Minor</option>
                    </select>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col col-sm-6 form-group'}>
                    <label htmlFor={'type'}>* Type</label>
                    <select onChange={this.handleType}
                        name={'type'}
                        id={'type'}
                        className={'form-control'}
                    >
                        <option value={''}> --Select Type--</option>
                        <option>Permanent</option>
                        <option>Temporary</option>
                    </select>
                </div>
                <div className={'col col-sm-6 form-group'}>
                    <label htmlFor={'endDate'}> End Date</label>
                    <input onChange={this.handleEndDate}
                        name={'endDate'}
                        id={'endDate'}
                        type={'date'}
                      //  min={maxDate}
                        className={'form-control'}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col col-sm-12 form-group'}>
                    <label htmlFor={'condition'}>* Condition</label>
                    <textarea onChange={this.handleCondition}
                        name={'condition'}
                        id={'condition'}
                        className={'form-control'}
                        rows={7}
                        style={{resize: 'none'}}
                    />
                </div>
            </div>
            <div className={'form-inline'}>
                <button onClick={this.handleSaveCondition}>Save</button>
                {/*<button onClick={PopDetail.dismiss()}>Cancel</button>*/}
            </div>
        </div>
    )
}
    handleSaveCondition(e){
        let data={
            number:this.state.number,
            category:this.state.category,
            type:this.state.type,
            endDate:this.state.endDate,
            condition:this.state.condition
        }
        TxCreateService.saveCondition(data).then(d=>{

            Conditions.self.state.conditionsToshow.push(d)
        })
}
}
