import * as React from "react";
import MobileReadService from "../../../../../services/mobile/mobile_read";
import ParcelRemark from "../../../common/remarks";
import CommonService from "../../../../../services/common/common_read";
import Wait from "../../../../popups/wait";

export default class ParcelInfoPane extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            question_categ:[],
            questions:[],
            tab:this.props.tab,
            tab_pane:<div>Loading...</div>,
            load_data:false
        }
        this.loadQuestCategory = this.loadQuestCategory.bind(this)
        this.loadQuestionsAnswersAt = this.loadQuestionsAnswersAt.bind(this)
        this.setTabAt = this.setTabAt.bind(this)

    }
    componentDidMount() {
        this.setTabAt(this.state.tab,0)
    }

    setTabAt(tab,tab_id=0){
        try {
            this.setState({
                tab:tab
                //questions:this.state.load_data?this.state.question_categ[tab].questions:''
            })
            if (this.state.load_data){
                let tab_size = parseInt(this.state.question_categ.length)
                if (tab >= tab_size - 2){
                    let tab_pane = tab===tab_size-2?<ParcelRemark is_open={false} key={new Date().getMilliseconds()} parcel={this.props.data}/>:
                        <div key={new Date().getMilliseconds()} parcel={this.props.data}
                                         project_id={this.props.project_id}/>

                    this.setState({
                        tab_pane:tab_pane
                    })
                }
            }

            this.loadQuestCategory(tab_id)
        }catch (e) {
            //console.log(e)
        }
    }

    render() {
        return(<div style={{width:'100%',height:'100%'}}>

            <div style={{width:'100%',float:'left'}}>
                {this.state.question_categ.map((d,i)=>{
                    return(<div onClick={()=>this.setTabAt(i,d.id)} key={i} title={d.description}
                                className={this.state.tab===i?'tab tab-active':'tab'}
                                style={{width:'100px'}}>{d.name}</div>)
                })}
            </div>


            <div style={{width:'100%',height:'93%',float:'left'}}>

                {this.state.tab < this.state.question_categ.length - 2 ?
                    <table style={{margin:'24px'}}>
                        <tbody>
                        {this.state.questions.map((d, i) => {
                            function showEditAt(i) {
                                document.getElementById('answ_'+i).style.display='none'
                                document.getElementById('edit_'+i).style.display='none'

                                document.getElementById('input_'+i).style.display='inherit'
                                document.getElementById('save_'+i).style.display='inherit'
                            }

                            return (<tr key={i} style={{paddingTop: '8px'}}>
                                <td style={{textAlign: 'right', paddingRight: '16px'}}>
                                    <b>{d.question} :</b>
                                </td>
                                <td>
                                    <span id={'answ_'+i}>{d.answer}</span>
                                    <input  style={{marginLeft:'16px', display:'none'}}  type={'text'} placeholder={d.answer} required={d.compulsory} id={'input_'+i}/>
                                    <span style={{marginLeft:'16px',cursor:'pointer'}} onClick={()=>showEditAt(i)} className={'fa fa-pencil'} id={'edit_'+i}/>
                                    <span style={{marginLeft:'16px',cursor:'pointer',float:'right',display:'none'}} className={'fa fa-save'} id={'save_'+i}/>
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>:this.state.tab_pane
                }

            </div>

        </div>)
    }

    loadQuestCategory(tab_id) {

         try {
            CommonService.readDictionary("mobile","questionnaire").then(result=>{
                try {
                    let res = result.data.results
                    res.push({name:'Remarks'})
                    res.push({name:'Spatial'})

                    let tab = tab_id >0?tab_id:res[0].id

                    this.setState({
                        question_categ:res,
                        load_data:true
                    })


                    if(this.state.tab<res.length-2){
                        this.loadQuestionsAnswersAt(tab)
                    }


                    //console.log(res)

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }


    }

    loadQuestionsAnswersAt(tab_id) {
        try {
            Wait.show('Loading...')
            MobileReadService.readQuestinnares(this.props.category,tab_id).then(r=>{
                Wait.dismiss()
                try {
                    this.setState({
                        questions:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
}