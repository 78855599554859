import * as React from "react";
import PopDetail from "../../popdetail";
import Wait from "../../wait";
import ValCreateService from "../../../../services/valuation/val_create";
import TransactionPopup from "../transaction_popup";
import CompensationRatesUpload from "../screen/compensation_rates_upload";

export default class PaneUploadCompRates extends React.Component{
static self=null
    constructor(props) {
        super(props);
        this.state={
            csv:null
        }

        this.saveCompValuationRate = this.saveCompValuationRate.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        PaneUploadCompRates.self=this
    }
    handleChangeFile=e=>{
        this.setState({ [e.target.name]:e.target.files[0] })
    }

    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>

                    <div className={'col col-sm-12'}>

                        <table style={{width:'100%'}}>
                            <tbody>
                            <tr>
                                <td colSpan={3}>* CSV Rates </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <input type={'file'} style={{width:'100%'}} accept={".csv,.CSV,.excel,.EXCEL"}
                                           name={'csv'} onChange={this.handleChangeFile}/>
                                </td>
                            </tr>

                            </tbody>
                        </table>


                    </div>

                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.saveCompValuationRate}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }

    saveCompValuationRate() {
        try {
            Wait.show("Saving...")
            const formData = new FormData()
            formData.append('file', this.state.csv)
            formData.append('txId', TransactionPopup.id())

            ValCreateService.uploadCompValuationCSVRates(formData).then(d => {
                Wait.dismiss()

                try {
                    console.log(d)
                    CompensationRatesUpload.self.setState({
                        rates:d.data.rates
                    })
                    //ValuationRatePane.self.setState({rates:d.data.rates})
                } catch (e) {
                    console.log(e)
                }
               /* PopDetail.dismiss()*/
            })

        } catch (e) {
            Wait.dismiss()
            console.log(e)
        }
    }

}