import * as React from "react";
import logo from '../../../resource/img/nembo.png';
import Wait from "../popups/wait";
import TransactionPopup from "../popups/transacrions/transaction_popup";
import Dashboard from "./dashboard";
import PopDetail from "../popups/popdetail";
import MobileTable from "../tables/tb_mobile";
import MobilePopup from "../popups/mobile/mobile_popup";
import ProjectPane from "../pane/mobile/project_pane";
import MobileReadService from "../../services/mobile/mobile_read";
import {PasswordResetRequest} from "../popups/uma/password-reset";
import ProfessionTable from "../tables/tb_profession";
import PopDetails from "../../componentss/popdetails";
import PaneAddPerson from "../popups/transacrions/pops/pane_add_person";

export default class ProfessionLayout extends React.Component{

    static current_menu = 0
    constructor(props) {
        super(props);

        this.state = {
            menu_project_output:[],//"CCRO","Residental License","CRO","Rent Blocks","Demand Note"
            menu:[{title:'',icon:''}],
            is_admin: this.props.is_super_user?'inherit':'none',

            left_menu:[
                {title:'Common', access:true, submenu:[
                    {title:'Active Projects',id:1},
                    {title:'Not Active Projects',id:0},
                    ]},
                {title:'By Task', access:true, submenu:[{title:'Pending Request',pane:'Loading...'}]},
                {title:'By Projects', access:true, submenu:[{title:'First Parcel Registration',pane:'Loading...'}]},
            ],
            i:0,
            j:0,
        }

        this.showAddSubmenuAt = this.showAddSubmenuAt.bind(this)
        this.showLeftMenuAt = this.showLeftMenuAt.bind(this)
        this.setMenuAt = this.setMenuAt.bind(this)



        this.loadProfessionMenu = this.loadProfessionMenu.bind(this)

    }

    componentDidMount() {
        try {
            this.loadProfessionMenu()

        }catch (e){
            console.log(e)
        }
    }

    showAddSubmenuAt(i,transactions){
        this.setState({menu:transactions})
        document.getElementById('pop-new').style.display ='inherit'
        ProfessionLayout.current_menu = i
    }

    setMenuAt(trans){



    }

    showLeftMenuAt(i,j,submenu){
        this.setState({
            i:i,
            j:j,
        })

        this.state.menu.forEach((d,ii)=>{ if(ii!==i){document.getElementById('sub-menu-'+ii).style.display = 'none';}});

        let div_submenu = document.getElementById('sub-menu-'+i);

        if(!submenu){
            div_submenu.style.display = div_submenu.style.display==='inherit'?'none':'inherit';
        }


    }

    showSystemAdmin(){

    }

    render() {
        return(<div className={'base-container'} id={'base-container'}>

            <div className={'col col-sm-12 base-toolbar'}>

                <div className={'col col-sm-2'} style={{float:'left'}}>
                    <span className={'fa fa-navicon'} style={{marginRight:'16px',fontSize:'20px'}}/>
                    <img src={logo} alt={'logo'} style={{height:'4vh',marginRight:'16px',marginTop:'-1.5vh'}}/>
                    <span style={{fontFamily:'oxygen-bold',fontSize:'20px'}}>ILMIS</span>

                </div>

                <div id={'btn-new'} style={{float:'left',padding:'0px 8px'}} onClick={()=>this.showAddSubmenuAt(0,[])}>
                    <span className={'fa fa-plus'} style={{fontSize:'18px',marginRight:'8px'}}/>
                    <b>New Profession</b>
                </div>



                <div style={{float:'right'}}>
                     <span style={{cursor:"pointer"}}
                           onClick={sessionStorage.getItem('username') !== null?()=>PopDetail.show("Password Reset Request",<PasswordResetRequest user={sessionStorage.getItem('username')}/>,50):()=>{}}>{sessionStorage.getItem('username')}</span>
                    <span onClick={()=>Dashboard.logout()} className={'fa fa-power-off'}
                          style={{fontSize:'18px',cursor:'pointer',marginLeft:'16px'}}/>
                    {/*<span>{sessionStorage.getItem('username')}</span>
                    <span onClick={()=>Dashboard.logout()} className={'fa fa-power-off'}
                          style={{fontSize:'18px',cursor:'pointer',marginLeft:'16px'}}/>*/}
                </div>

                <div id={'btn-admin'} style={{float:'right',display:this.state.is_admin,padding:'0px 24px'}} onClick={()=>this.showSystemAdmin()}>
                    <span className={'fa fa-cog'} style={{fontSize:'18px',marginRight:'8px'}}/>
                    <b>System Admin</b>
                </div>

            </div>
            {/*------------------------------------------------------*/}

            <div className={'col col-sm-2 base-body-left'}>
                {
                    this.state.left_menu.map((d,i)=>{

                        return(<div key={'left-menu-'+i} style={{display:d.access?'inherit':'none'}}>

                                <div key={'menu-'+i} className={'btn-left-menu'} onClick={()=>this.showLeftMenuAt(i,0,false)}>

                                    <b>{d.title}</b>
                                </div>

                                <div id={'sub-menu-' + i} style={{display: i===0?'inherit':'none',padding:'8px',margin:'2px 0',backgroundColor:'#fff'}}>
                                    {d.submenu.map((s, j) => {
                                        return (<div key={'sub-menu-' + j} className={'sub-menu'} onClick={()=>this.showLeftMenuAt(i,j,true)}>
                                            <span className={'fa fa-angle-double-right'} style={{marginRight:'8px'}}/>
                                            <span>{s.title} </span>
                                            {/**/}
                                        </div>)
                                    })}
                                </div>
                        </div>)
                    })
                }
            </div>

            {/*------------------------------------------------------*/}

            <div className={'col col-sm-10 base-body-right'} >
                <ProfessionTable/>
            </div>


            {/*------------------------------------------------------*/}


            <div onMouseLeave={()=>document.getElementById('pop-new').style.display='none'}
                 className={'col col-sm-4'} style={{position:'absolute',display:'none',minWidth:'400px',textAlign:'left',left:'17vw',top:'6vh',padding:0}} id={'pop-new'}>

                <div className={'col col-sm-6'} style={{background:'#f7f7f7',boxShadow:'0 0 10px #888',float:'left',padding:0}}>
                    {
                        this.state.menu_project_output.map((d,i)=>{
                            return(<div key={i} className={'btn-left-menu'} id={'tp-mn-'+i} onClick={()=>this.createNewProffesionAt(i,d)}>
                                <b>{d.name}</b>
                            </div>)
                        })
                    }
                </div>


            </div>



            {/***** Waiting Pane*****/}

            <PopDetails/>




        </div>)
    }

    static show(){ document.getElementById('base-container').style.display='inherit' }








    /*------------------ New Work -----------------*/
    loadProfessionMenu() {
        try {
            /*Wait.show("Loading Professions...")
            MobileReadService.readTranslateByCategorySubCategory("profession","profession").then(d=>{
                Wait.dismiss()
                try {
                    this.setState({menu_project_output:d.data.results})
                }catch (e){
                    console.log(e)
                }
            })*/

            this.setState({menu_project_output:[
                    {id:1,name:'Valuer'},
                    {id:2,name:'Town Planner'},
                    {id:3,name:'Land Officer'}
                ]})
        }catch (e) {
            console.log(e)
        }
    }

    createNewProffesionAt(psn,output) {
       /* MobilePopup.show({
            outputId:output.id,
            outputName:output.name,
            projectName:'New '+output.name,
            is_new:true,
        },<ProjectPane
            title={output.name}
            key={new Date().getMilliseconds()}/>)
*/
        PopDetails.show('Add Profession',<PaneAddPerson/>)
    }


}

