import * as React from "react";
import PopDetail from "../../popdetail";
import PaneAddSpatialUnit from "../pops/pane_add_spatial";
import TransactionPopup from "../transaction_popup";
import PaneUploadSpatialUnitSurverRequest from "./add/add_rough_coordinate";
import Wait from "../../wait";
import TxReadService from "../../../../services/transaction/transaction_create";
import PaneSearchPropertyBatch from "../pops/pane_search_property_batch";
import UgSpatial from "../../../maps/ug_spatial";
import * as LF from "leaflet";
import Basemap from "../../../maps/basemap";
import API from "../../../../services/API";
export default class Properties extends React.Component{
    static self = null
    constructor(props) {
        super(props);

        this.state={
            selectedProperties:[],
            landProperties:[],
            rent:[],
            showMenu:false,
            gepgMenu:false,
            base64:''
        }

        Properties.self = this

        this.computeRent=this.computeRent.bind(this)



    }
    componentDidMount() {

        this.setState({
            is_prop_search:TransactionPopup.self.state.tx.name==='Property Search'?'none':'inherit',
            is_prop_search_tr:TransactionPopup.self.state.tx.name==='Property Search'?'none':'table-cell',
        })

        if (TransactionPopup.self.state.tx.name==='Property Search'){
            PopDetail.show("Search Property",<PaneSearchPropertyBatch/>)
        }
    }

    static loadData(txId=TransactionPopup.id()){

        try {


            Wait.show("Loading Map...")

            UgSpatial.clear()

            let ly_group = new LF.FeatureGroup();
            let sat = Basemap.googleSat(),
                hybrid = Basemap.googleHybrid(),
                blank = LF.tileLayer('')

            let map = LF.map('map', {
                layers: [blank, ly_group],
                attributionControl: false,
                fullscreenControl: true,
                maxZoom:10000
            }).setView([-6.085936, 35.711995], 4)

            let ly_point = UgSpatial.getLayerByName('point').addTo(map)

            let baseLayers = {
                "<span style='color: gray'>Blank</span>": blank,
                "Satellite": sat,
                "Hybrid": hybrid
            }

            let uiid_no=''
            let filter = 'uiid_no in ('+uiid_no+')'
            let layername = 'spatial_unit'
            let selected

            try{
                API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                    Wait.dismiss()
                    try {
                        r.data.features.forEach(p=>{

                            let myLayer = LF.geoJSON(p,{
                                /* onEachFeature:(f,l)=>{
                                     LF.marker(l.getBounds().getCenter(),{
                                         icon:LF.divIcon({
                                             className:'bindTooltipBig',
                                             html:'',//f.properties.vblock_id,
                                             iconSize:[100,40]
                                         })
                                     }).addTo(ly_group)


                                     l.setStyle({
                                         color:f.properties.status==='Approved'?'#00ff00':'#901'
                                     })
                                 },*/
                                coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                                weight:3,zIndex:2, fillOpacity: 0
                            }).on('dblclick',e=>{

                                /*ValBlockSpatialUnitPane.self.openRatesAt({
                                    id:ValBlockSpatialUnitPane.self.state.id,
                                    blockId:ValBlockSpatialUnitPane.self.state.vblock_id,
                                    status:ValBlockSpatialUnitPane.self.state.status,
                                })*/

                            }).on('click', function (e) {

                                if (selected) {e.target.resetStyle(selected)}
                                selected = e.layer

                                /*ValBlockSpatialUnitPane.self.setState({
                                    region:p.properties.region,
                                    district:p.properties.district,
                                    council:p.properties.council,
                                    locality:p.properties.locality,
                                    status:p.properties.status,
                                    vblock_id:p.properties.vblock_id,
                                    id:p.id.split('.')[1],
                                    blockId:p.properties.blockId
                                })*/
                                selected.bringToFront()
                                selected.setStyle({color: '#000', weight:4})
                                map.fitBounds(selected.getBounds())

                            })
                            myLayer.addTo(ly_group)
                        })


                        let myLayer = LF.geoJSON(r.data,{coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();}})
                        try{ map.fitBounds(myLayer.getBounds())}catch (e) { }

                    }catch (e){
                        console.log(e)
                    }

                })

            }catch (e){console.log(e)}

            let ly_vb = UgSpatial.getLayerByName('tp_spatial_unit').addTo(map)
            let ly_tp = UgSpatial.getLayerByName('valuation_block').addTo(map)
            ly_vb.bringToFront()

            let overlays = {
                'Valuation Blocks':ly_vb,
                'TP Drawings':ly_tp,
                'Points':ly_point,
                'Current Layer':ly_group,
            }

            UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)

        }catch (e){

        }

    }


    popAddSpatial(tag){
        let pan = <PaneAddSpatialUnit/>
        let trans_name = TransactionPopup.self.state.title

        switch (tag) {
            case 'add':
                break
            case 'upload':
                switch (trans_name) {
                    case 'Survey Request':
                        PopDetail.show('Upload Rough Coordinates',<PaneUploadSpatialUnitSurverRequest/>)
                        break
                    default:
                        break
                }
                break
            default:
                break
        }


        //
        //PopDetail.show('New Spatial Unit',pan)
    }


    render() {
        return(<div>

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        {this.props.screen==='print'?
                            <div>
                                <div>
                                    <b>Select Layers</b>
                                </div>
                                <br/>
                                <div>
                                    <span><input type={'checkbox'}/> Parcels</span><br/>
                                    <span><input type={'checkbox'}/> Buildings</span><br/>
                                    <span><input type={'checkbox'}/> Contours</span><br/>
                                    <span><input type={'checkbox'}/> Point</span><br/>
                                    <span><input type={'checkbox'}/> Ponds</span><br/>
                                    <span><input type={'checkbox'}/> Power Lines</span><br/>
                                    <span><input type={'checkbox'}/> Railways</span><br/>
                                    <span><input type={'checkbox'}/> Rivers</span><br/>
                                    <span><input type={'checkbox'}/> Roads</span><br/>
                                </div>
                                <br/>
                                <div style={{textAlign:'center'}}>
                                    <button onClick={this.previewSMD}>Preview</button>
                                </div>

                            </div>:
                            <div>f</div>}
                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>

                    <div className={'row'} style={{background:'#fff',height:'100%',padding:'4px',border:'0.5px solid #efefef'}}>
                        <div className={'col col-sm-12'} style={{height:'40px',lineHeight:'40px'}}>
                            <b>Map</b>


                            <div style={{float:'right'}}>
                                <span style={{display:this.state.is_prop_search}} onClick={()=>this.popAddSpatial('add')}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'}
                                    />New
                                </span>
                                <span style={{display:this.state.is_prop_search}}>
                                <span style={{marginLeft:'16px'}} className={'fa fa-trash screen-action'}
                                      onClick={()=>{}} />Remove
                            </span>
                                <span onClick={()=>{PopDetail.show("Search Property",<PaneSearchPropertyBatch/>)}}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-search screen-action'}/>Property Search
                                </span>
                                <span style={{display:this.state.is_prop_search}} onClick={()=>this.computeRent()}>
                                <span style={{marginLeft:'16px'}} className={'fa fa-check screen-action'}
                                />Check
                            </span>
                                <span style={{cursor:'pointer'}}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-refresh screen-action'} onClick={()=>Properties.loadData()}/>Refresh
                                </span>
                            </div>

                        </div>

                        <div className={'col col-sm-12'} style={{height:'92%',border:'1px solid #ccc',padding:0}}>

                            <div className={'col col-sm-12'} style={{background:'#efefef',padding:0,float:'left',height:'100%'}} id={'ramani'}>

                            </div>



                        </div>

                    </div>
                </div>

            </div>

            <div className={'row'} >



                <div className={'col col-sm-12'} style={{paddingRight:'4px',height:'27vh'}}>
                    <div style={{background:'#fff',padding:'4px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <table style={{width:'100%'}} className={'tb'}>
                            <tbody>
                            <tr>
                                <td>Search</td>
                                <td><input style={{width:'100%'}}/></td>
                                {/*<td>Applicant Role</td>
                                <td><input style={{width:'100%'}}/></td>*/}
                            </tr>
                            </tbody>
                        </table>

                        <div style={{borderTop:'1px solid #ccc',float:'left',width:'100%'}}>

                            <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                                <thead>

                                <tr>
                                    <th style={{display:this.state.is_prop_search_tr}}><span>BAUNITID</span></th>
                                    <th style={{display:this.state.is_prop_search_tr}}><span>TITLE No.</span></th>
                                    <th style={{display:this.state.is_prop_search_tr}}><span>RIGHT HOLDER</span></th>
                                    <th>DISTRICT</th>
                                    <th>LOCATION</th>
                                    <th>BLOCK</th>
                                    <th>LOT NUMBER</th>
                                    <th>USE</th>
                                    <th>ALLOCATION STATUS</th>

                                </tr>
                                </thead>
                                <tbody>

                                {this.state.landProperties.map((d,i) => {
                                    return(
                                        <tr key={i}>
                                            <td style={{display:this.state.is_prop_search_tr}}>{d.baunitId}</td>
                                            <td style={{display:this.state.is_prop_search_tr}}>{d.titleNumber}</td>
                                            <td style={{display:this.state.is_prop_search_tr}}>{d.party}</td>
                                            <td>{d.districtName}</td>
                                            <td>{d.localityName}</td>
                                            <td>{d.blockNumber}</td>
                                            <td>{d.lotNumber}</td>
                                            <td>{d.landUseName}</td>
                                            <td>{d.allocationStatusName}</td>
                                        </tr>)})}
                                </tbody>
                            </table>

                        </div>



                    </div>
                </div>


            </div>

        </div>)
    }


    computeRent(){
        try {
            let data={
                baunitIds:this.state.selectedProperties,
                txId:TransactionPopup.id()}

                Wait.show("Calculating Batch Rent...")
                TxReadService.calculateBatchRent(data).then(r => {
                    Wait.dismiss()
                    try {
                        console.log("BATCH ==>",r.data)
                        if(r.data.status===1) {
                            this.setState({rent: r.data})
                        }else{
                            PopDetail.show("CALCULATION ERRORS",<div><h3>{r.data.message}</h3></div>)
                        }
                    }catch (e) {
                        console.log(e)
                    }
                })
            }catch (e) {
                console.log(e)
            }
    }

}
