import React, {Component} from 'react';
import TxCreateService from "../../../../services/transaction/transaction_create";
import TransactionPopup from "../transaction_popup";
import PopDetails from "../../../../componentss/popdetails";
import PopDetailsTwo from "../../PopDetailsTwo";
import Wait from "../../wait";
import TransactionTable from "../../../tables/tb_transaction";

class PaneAddAction extends Component {
    static self = null
    constructor(props) {
        super(props);
        this.state={
            username:''

        }
        PaneAddAction.self = this
        this.reassign = this.reassign.bind(this)
        this.handleChange= this.handleChange.bind(this)
    }
    static state(){return PaneAddAction.self.state}
    static setState(state_name,state_value) {
        //try {
            console.log("KILICHOFIKA",state_name,state_value)
           // self.setState({[state_name]: state_value})
      /*  } catch (e) {
            console.log(e)
        }*/

    }
   handleChange(e){
        this.setState({[e.target.name]:e.target.value})
   }

    reassign(){
        let dataTosend={
            transactionIds:TransactionPopup.state().transactionIds,
            username:this.state.username
        }
console.log("ACTIONS DTO"+dataTosend)
        Wait.show("Re assigning....")
        TxCreateService.reassign(dataTosend).then(d=>{
            if(d.data.status===1){
                Wait.dismiss()
              PopDetailsTwo.show("STATUS",<div><table className={'tb'} style={{width:'inherit'}}><tbody>{d.data.message.map((d,i)=>{
                  return(<tr key={i} style={{width:'inherit'}}>
                      <td  style={{width:'inherit'}}>{d}</td>
                  </tr>)
              })}</tbody></table></div>,60)


            }
        })
    }

    render() {
        return (
            <div>
                <h4>Re Assign Transaction</h4>
                <table>
                    <tr><td>Enter Assignee</td></tr>
                    <tr><td><input type={'text'} name={'username'}onChange={this.handleChange}/></td></tr>
                    <tr><td><button onClick={this.reassign}>Assign</button><button onClick={ ()=>{
                        TransactionTable.loadData()
                        PopDetails.dismiss()
                    }
                     }>Exit</button></td></tr>
                </table>

            </div>
        );
    }
}

export default PaneAddAction;
