import React from "react";
export default function Header({label,children, display, heading=true}) {
 return(
     <div className={'form-group'} style={{gap:'1rem',margin:0,marginBottom:'.3rem',textAlign: 'center'}}>
         <span
             style={{marginLeft:'.2rem',fontSize:'1rem', display:heading?'inherit':'none'}}
         ><b>{label}</b></span>
         <div style={{display:'flex', gap: '1rem', cursor:'pointer', flexDirection:"row"}}>
             {children}
         </div>
     </div>
 )
}
