    import * as React from "react";
import TransactionPopup from "../transaction_popup";
import Wait from "../../wait";
import TxReadService from "../../../../services/transaction/transaction_read";
import Properties from "./properties";
    import computeRent from "../pops/computeRent";
export default class TitleRent extends React.Component{
    static self = null
    constructor(props){
        super(props);
        this.state = {
            landProperties: {},
            computedRent:this.props.rent,
            rent: [],
            showMenu: true,
            gepgMenu: true,
            base64: ''
        }

        this.sendToGepg=this.sendToGepg.bind(this);
        this.createPayment=this.createPayment.bind(this);
        this.generateBatchInvoice=this.generateBatchInvoice.bind(this);
        TitleRent.self=this

    }


    componentDidMount() {
        TxReadService.readComputedBatchRent(TransactionPopup.id()).then(d=>{
            if(d.data.status===1){
                this.setState({computeRent:d.data.result})
            }

        })

    }

    render() {
        return(<div>
            {this.state.showMenu?
                <div>
                    <span onClick={()=>this.dismiss()} className={'fa fa-times-circle'} style={{marginRight:'24px',fontSize:'22px',color:'#f43',cursor:'pointer',float:'right'}}/>
                    <button className={'fa fa-calculator'} onClick={()=>this.createPayment()}>Calculate Rent</button>
                    <button className={'fa fa-external-link'} onClick={()=>this.sendToGepg()}>Submit to GePG</button>
                    <button>Check GePG Status</button>


                </div>:''}

                <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>TITLE NUMBER</th>
                        <th>LAND RENT</th>
                        <th>ARREARS</th>
                        <th>AMOUNT DUE</th>
                        <th>BILL NUMBER</th>
                        <th>CONTROL NUMBER</th>
                        <th>PAYER PHONE</th>
                        <th>PAYMENTERROR</th>
                        <th>GEPGERROR</th>
                        <th>SUBMITTED TO GEPG</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.computedRent===undefined?<tr/>:this.state.computedRent.map((d, i) => {
                        return(
                            <tr key={i}>
                                <td>{d.baseUnitId}</td>
                                <td>{d.titleNumber}</td>
                                <td>{d.landRent}</td>
                                <td>{d.rentArrears}</td>
                                <td>{d.amountDue}</td>
                                <td>{d.billNumber}</td>
                                <td>{d.controlNumber}</td>
                                <td>{d.phone}</td>
                                <td>{d.paymentError}</td>
                                <td>{d.gepgError}</td>
                                <td>{d.gepgStatus}</td>
                            </tr>)})}
                    </tbody>
                </table>
        </div>)
    }

    sendToGepg() {
        try {
            Wait.show("Submitting to GEPG ...")
            TxReadService.sendBillToGepg(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        rent:r.data.bill,
                        gepgMenu:true

                    })

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    createPayment() {
        try {
            Wait.show("Generate Bill ...")
            TxReadService.createBillPayment(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        rent:r.data.bill,
                        gepgMenu:true

                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    generateBatchInvoice() {
        try {
            Wait.show("Generate Invoice ...")
            TxReadService.generateRentInvoice(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        base64:r.data.base64,

                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
    }
