import React from "react"
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../../../popups/mobile/mobile_popup";
import TPReadService from "../../../../services/tp/tp_read";
import SMDReadService from "../../../../services/smd/smd_read";
import Wait from "../../../popups/wait";

export default function CROStages({ type, changeStage }) {
    const pstages = ["Mobile Data", "RTK Data", "Draft TP", "Approved TP", "Draft Surveys", "Approved Surveys", "Rejected Surveys", "Rejected TP", "Registered Plots"]
    const tpStages = ["Draft TPs", "Approved TPs", "Rejected TPs"]
    const surveyStages = ["Approved TPs", "Draft Survey Plan", "Approved Survey Plan", "Rejected Survey Plan"]
    const [activeTab, setActive] = React.useState(0);
    const [stages, setStages] = React.useState([]);
    const [activeStage, setActiveStage] = React.useState(0);
    const [summary, setSummary] = React.useState({})
    React.useEffect(() => {
        changeStage(activeStage)
    }, [activeStage]);
    React.useEffect(() => {
        // Wait.show("Please Wait");
        switch (type) {
            case 'parcels':
                Wait.show("Plaese wait")
                setStages(pstages)
                MobileReadService.readReportSummary(MobilePopup.id(), 0, 0, 0).then((r) => {
                    setSummary(r.data.results);
                    Wait.dismiss()
                })
                break;
            case 'tp':
                Wait.show("Plaese wait")
                setStages(tpStages)
                TPReadService.readReportSummary(MobilePopup.id(), 0).then((r) => {
                    setSummary(r.data)
                    Wait.dismiss()
                })
                break;
            case 'survey':
                Wait.show("Please wait");
                setStages(surveyStages)
                SMDReadService.readReportSummary(MobilePopup.id(), 0).then(r => {
                    setSummary(r.data)
                    Wait.dismiss()
                })
                break;
            default:
                break;
        }
    }, []);
    const getStageNum = (stage) => {
        switch (stage) {
            //Mobile Data Stages
            case 'Mobile Data':
                return 0;
            case 'RTK Data':
                return 33;
            case 'Draft Surveys':
                return 34;
            case 'Approved Surveys':
                return 35;
            case 'Rejected Surveys':
                return 36;
            case 'Rejected TP':
                return 37;
            case 'Approved TP':
                return 32;
            case 'Draft TP':
                return 31;
            case 'Registered Plots':
                return 38;
            //TP Stages
            case 'Draft TPs':
                return 0;
            case 'Approved TPs':
                return 1;
            case 'Rejected TPs':
                return 2;
            //Survey Stages
            case 'Draft Surveys':
                return 0;
            case 'Approved Survey Plans':
                return 1;
            case 'Rejected Survey Plans':
                return 2;
            default:
                return 0;

        }
    }
    return (
        <div style={{ flexDirection: "column", display: "flex" }}>
            {
                stages.map((prop, key) => (
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => { setActive(key); setActiveStage(getStageNum(prop)); }} key={key} className={activeTab === key ? "tab kltabactive" : "tab kltab"}>
                        <div>{prop}</div>
                        <div style={{ marginRight: 10, padding: 5, backgroundColor: "#DF4A36", paddingLeft: 10, paddingRight: 10, borderRadius: 10, color: "#fff", fontWeight: "bold" }}>{summary['stage_' + getStageNum(prop)]}</div>
                    </div>
                ))
            }
        </div>
    )
}