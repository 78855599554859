let tmr;
let eventTmr;

let resetIsSupported = false;

let lcdSize, lcdX, lcdY, scrn;

export function startTablet(setSigCaptured)
{
    try
    {
        SetTabletState(1);
        let retmod = parseInt(TabletModelNumber());
        SetTabletState(0);
        if(retmod === 11 || retmod === 12 || retmod === 15)
        {
            ctx = document.getElementById('cnv').getContext('2d');
            eventTmr = setInterval( SigWebEvent, 20 );
            console.log(eventTmr)
            tmr = SetTabletState(1, ctx, 50) || tmr;
            SetLCDCaptureMode(2);
            LcdRefresh(0, 0, 0, 240, 64);
            SetJustifyMode(0);
            KeyPadClearHotSpotList();
            ClearSigWindow(1);
            SetDisplayXSize(500);
            SetDisplayYSize(100);
            SetImageXSize(500);
            SetImageYSize(100);
            SetLCDCaptureMode(2);


            LCDSendGraphicUrl(1, 2, 0, 20, "http://www.sigplusweb.com/SigWeb/Sign.bmp");
            LCDSendGraphicUrl(1, 2, 207, 4, "http://www.sigplusweb.com/SigWeb/OK.bmp");
            LCDSendGraphicUrl(1, 2, 15, 4, "http://www.sigplusweb.com/SigWeb/CLEAR.bmp");

            lcdSize = LCDGetLCDSize();
            lcdX = lcdSize & 0xffff;
            lcdY = (lcdSize >> 16) & 0xffff;

            const data = "Karibu ILMIS. Tafadhali bofya Endelea.";

            parse(data);

            LCDWriteString(0, 2, 15, 45, "9pt Arial", 15, "Endelea");

            KeyPadAddHotSpot(0, 1, 12, 40, 40, 15);   //Continue

            ClearTablet();

            LCDSetWindow(0, 0, 1, 1);
            SetSigWindow(1, 0, 0, 1, 1);
            SetLCDCaptureMode(2);

            scrn = 1;

            onSigPenUp = () =>
            {
                processPenUp(setSigCaptured);
            };

            SetLCDCaptureMode(2);
        }
        else
        {
            alert("You do not have the appropriate signature pad plugged in to use this System.");
        }
    }
    catch (e)
    {
        console.error(e,"exception")
        alert("Unable to communicate with SigWeb. Please confirm that SigWeb is installed and running on this PC!");
    }

}
function processPenUp(setSigCaptured)
{
    if(KeyPadQueryHotSpot(0) > 0)
    {
        ClearSigWindow(1);
        LcdRefresh(1, 16, 45, 50, 15);

        if(scrn === 1)
        {
            ClearTablet();
            LcdRefresh(0, 0, 0, 240, 64);

            const data2 = "Saini hii itatumika katika muamala huu. Tafadhali Bofya Endelea.";

            parse(data2);

            LCDWriteString(0, 2, 15, 45, "9pt Arial", 15, "Endelea");
            LCDWriteString(0, 2, 200, 45, "9pt Arial", 15, "Ghairi");

            KeyPadAddHotSpot(1, 1, 195, 40, 20, 15);  //Back

            scrn = 2;
        }
        else if(scrn === 2)
        {
            LcdRefresh(2, 0, 0, 240, 64);
            ClearTablet();
            KeyPadClearHotSpotList();
            KeyPadAddHotSpot(2, 1, 10, 5, 53, 17);   //CLEAR
            KeyPadAddHotSpot(3, 1, 197, 5, 19, 17);  //OK
            LCDSetWindow(2, 22, 236, 40);
            SetSigWindow(1, 0, 22, 240, 40);
        }

        SetLCDCaptureMode(2);
    }

    if(KeyPadQueryHotSpot(1) > 0)
    {
        ClearSigWindow(1);
        LcdRefresh(1, 200, 45, 25, 15);

        if(scrn === 2)
        {
            KeyPadClearHotSpotList();
            LcdRefresh(1, 200, 45, 25, 15);
            ClearTablet();
            LcdRefresh(0, 0, 0, 240, 64);

            const data = "Karibu ILMIS. Tafadhali bofya Endelea.";

            parse(data);

            LCDWriteString(0, 2, 15, 45, "9pt Arial", 15, "Endelea");

            KeyPadAddHotSpot(0, 1, 12, 40, 40, 15);   //Continue

            scrn = 1;
        }

        SetLCDCaptureMode(2);
    }

    if(KeyPadQueryHotSpot(2) > 0)
    {
        ClearSigWindow(1);
        LcdRefresh(1, 10, 0, 53, 17);

        LcdRefresh(2, 0, 0, 240, 64);
        ClearTablet();
    }

    if(KeyPadQueryHotSpot(3) > 0)
    {
        ClearSigWindow(1);
        LcdRefresh(1, 210, 3, 14, 14);

        if(NumberOfTabletPoints() > 0)
        {
            LcdRefresh(0, 0, 0, 240, 64);
            LCDWriteString(0, 2, 35, 25, "9pt Arial", 15, "Saini imepokelewa");

            let CryptoData = "";
            CryptoData = "This represents sample data the signer reads and is agreeing to when signing.";
            CryptoData = CryptoData + "Concatenate all this data into a single letiable.";
            AutoKeyAddANSIData(CryptoData);
            SetEncryptionMode(2);
            SetSigCompressionMode(1);

            clearInterval(eventTmr);
            SetImageXSize(500);
            SetImageYSize(100);
            SetImagePenWidth(5);
            GetSigImageB64(setSigCaptured);
        }
        else
        {
            LcdRefresh(0, 0, 0, 240, 64);
            LCDSendGraphicUrl(0, 2, 4, 20, "http://www.sigplusweb.com/SigWeb/please.bmp");
            ClearTablet();
            LcdRefresh(2, 0, 0, 240, 64);
            SetLCDCaptureMode(2);
        }
    }

    ClearSigWindow(1);
}

function parse(textData)
{
    let words = textData.split(" ");
    let writeData = "";
    let tempData = "";
    let xSize = 0;
    let ySize = 0;
    let i = 0;
    let yPos = 0;

    for(i=0; i < words.length; i++)
    {
        tempData += words[i];

        xSize = LCDStringWidth("9pt Arial", tempData);

        if (xSize < lcdX)
        {
            writeData = tempData;
            tempData += " ";

            xSize = LCDStringWidth("9pt Arial", tempData);

            if (xSize < lcdX)
            {
                writeData = tempData;
            }
        }
        else
        {
            ySize = LCDStringHeight("9pt Arial", tempData);

            LCDWriteString(0, 2, 0, yPos, "9pt Arial", 15, writeData);

            tempData = "";
            writeData = "";
            yPos += ySize;
            i--;
        }
    }

    if(writeData !== "")
    {
        LCDWriteString(0, 2, 0, yPos, "9pt Arial", 15, writeData);
    }
}

function endDemo()
{
    LcdRefresh(0, 0, 0, 240, 64);
    LCDSetWindow(0, 0, 240, 64);
    SetSigWindow(1, 0, 0, 240, 64);
    KeyPadClearHotSpotList();
    SetLCDCaptureMode(1);
    SetTabletState(0, tmr);
    ClearTablet();
}

function close(){
    if(resetIsSupported){
        Reset();
    } else{
        endDemo();
    }
}

export const listener = () => {

    console.log('listen')
    window.onload = () => {

        if(IsSigWebInstalled()){
            console.log('installed')
            resetIsSupported = GetResetSupported();
            if(!resetIsSupported){
                let sigweb_link = document.createElement("a");
                sigweb_link.href = "https://www.topazsystems.com/software/sigweb.exe";
                sigweb_link.innerHTML = "https://www.topazsystems.com/software/sigweb.exe";

                let note = document.getElementById("sigWebVrsnNote");
                note.innerHTML = "There is a newer version of SigWeb available here: ";
                note.appendChild(sigweb_link);
            }
        }
        else{
            alert("Unable to communicate with SigWeb. Please confirm that SigWeb is installed and running on this PC...");
        }
    };

    window.onbeforeunload = function(evt){
        close();
        clearInterval(tmr);
        evt.preventDefault(); //For Firefox, needed for browser closure
    };
}


function GetResetSupported(){
    let minSigWebVersionResetSupport = "1.6.4.0";

    if(isOlderSigWebVersionInstalled(minSigWebVersionResetSupport)){
        console.log("Old SigWeb version installed.");
        return false;
    }
    return true;
}

function isOlderSigWebVersionInstalled(cmprVer){
    let sigWebVer = GetSigWebVersion();
    if(sigWebVer !== ""){
        return isOlderVersion(cmprVer, sigWebVer);
    } else{
        return false;
    }
}

function isOlderVersion (oldVer, newVer) {
    const oldParts = oldVer.split('.')
    const newParts = newVer.split('.')
    for (let i = 0; i < newParts.length; i++) {
        const a = parseInt(newParts[i]) || 0
        const b = parseInt(oldParts[i]) || 0
        if (a < b) return true
        if (a > b) return false
    }
    return false;
}




let getBlobURL = (window.URL && URL.createObjectURL.bind(URL)) || (window.webkitURL && window.webkitURL.createObjectURL.bind(window.webkitURL)) || window.createObjectURL;
let revokeBlobURL = (window.URL && URL.revokeObjectURL.bind(URL)) || (window.webkitURL && window.webkitURL.revokeObjectURL.bind(window.webkitURL)) || window.revokeObjectURL;

let baseUri = makeUri();
let	ctx;

function IsSigWebInstalled(){
    let xhr = new XMLHttpRequest();
    try{
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status === 0) {
                console.log("Unknown Error Occured. SigWeb Service response not received.");
                return false;
            }
        };
        xhr.open("GET", baseUri+"TabletState"+"?noCache="+generateUUID(), false);
        xhr.send();
    }catch(e){
        console.log('catch', e);
    }

    return (xhr.status !== 404 && xhr.status !== 0);
}

function isIE() {

    return ((navigator.appName === 'Microsoft Internet Explorer') || ((navigator.appName === 'Netscape') && (new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").exec
    (navigator.userAgent) != null)));
}

function isChrome() {
    let ua = navigator.userAgent;
    let chrome = false;

    return ua.lastIndexOf('Chrome/') > 0;
}

function makeUri() {
    let prot = window.location.protocol;
    if(prot === "file:")
    {
        prot = 'http:';
    }

    if (isIE()) {
        if (prot === 'https:') {
            return (prot + "//tablet.sigwebtablet.com:47290/SigWeb/");
        }
        else {
            return (prot + "//tablet.sigwebtablet.com:47289/SigWeb/");
        }
    }

    if (isChrome()) {
        if (prot === 'https:') {
            return (prot + "//tablet.sigwebtablet.com:47290/SigWeb/");
        }
        else {
            return (prot + "//tablet.sigwebtablet.com:47289/SigWeb/");
        }
    }

    else {
        //FIREFOX
        if (prot === 'https:') {
            return (prot + "//tablet.sigwebtablet.com:47290/SigWeb/");
        }
        else {
            return (prot + "//tablet.sigwebtablet.com:47289/SigWeb/");
        }
    }
}

function SigWebcreateXHR() {
    try { return new XMLHttpRequest(); } catch (e) { }
    try { return new window.ActiveXObject("Msxml2.XMLHTTP.6.0"); } catch (e) { }
    try { return new window.ActiveXObject("Msxml2.XMLHTTP.3.0"); } catch (e) { }
    try { return new window.ActiveXObject("Msxml2.XMLHTTP"); } catch (e) { }
    try { return new window.ActiveXObject("Microsoft.XMLHTTP"); } catch (e) { }

    alert("XMLHttpRequest not supported");
    return null;
}

let Count = false;



function SigWebSetProperty(prop) {
    let xhr = SigWebcreateXHR();

    if (xhr) {
        xhr.open("POST", baseUri + prop, true);
        xhr.send(null);
        if (xhr.readyState === 4 && xhr.status === 200) {
            return xhr.responseText;
        }
    }
    return "";
}

function SigWebSetPropertySync(prop) {
    let xhr = SigWebcreateXHR();

    if (xhr) {
        xhr.open("POST", baseUri + prop, false);
        xhr.send();
        if (xhr.readyState === 4 && xhr.status === 200) {
            return xhr.responseText;
        }
    }
    return "";
}

function SigWebSyncSetStreamProperty(prop, strm) {
    let xhr = SigWebcreateXHR();

    if (xhr) {
        xhr.open("POST", baseUri + prop , false);
        xhr.setRequestHeader("Content-Type", "text/plain");
        xhr.send(strm);
        if (xhr.readyState === 4 && xhr.status === 200) {
            return xhr.responseText;
        }
    }
    return "";
}

function SigWebSetImageStreamProperty(prop, strm) {
    let xhr = SigWebcreateXHR();

    if (xhr) {
        xhr.open("POST", baseUri + prop, false);
        xhr.setRequestHeader("Content-Type", "image/png");
        xhr.send(strm);
        if (xhr.readyState === 4 && xhr.status === 200) {
            return xhr.responseText;
        }
    }
    return "";
}

function SigWebGetProperty(prop) {
    let xhr = SigWebcreateXHR();

    if (xhr) {
        xhr.open("GET", baseUri + prop + "?noCache=" + generateUUID(), false );
        xhr.send(null);
        if (xhr.readyState === 4 && xhr.status === 200) {
            return xhr.responseText;
        }
    }
    return "";
}

function GetSigImageB64(callback )
{
    let cvs = document.createElement('canvas');
    cvs.width = GetImageXSize();
    cvs.height = GetImageYSize();

    let xhr2 = new XMLHttpRequest();
    xhr2.open("GET", baseUri + "SigImage/1"  + "?noCache=" + generateUUID(), true);
    xhr2.responseType = "blob";
    xhr2.send(null);
    xhr2.onload = function ()
    {
        let cntx = cvs.getContext('2d');
        let img = new Image();
        //			img.src = window.URL.createObjectURL(xhr2.response);
        img.src = getBlobURL(xhr2.response);
        img.onload = function ()
        {
            cntx.drawImage(img, 0, 0);
            let b64String = cvs.toDataURL("image/png");
            let loc = b64String.search("base64,");
            let retstring = b64String.slice(loc + 7, b64String.length);
            if (callback)
            {
                callback(retstring);
            }
        }
    }
}

let EvStatus;
let onSigPenDown;
let onSigPenUp;

function SigWebSetDisplayTarget(obj) {
    ctx = obj;
}




let		NumPointsLastTime = 0;

function SigWebRefresh() {
    let NumPoints = NumberOfTabletPoints();
    if ( NumPoints === NumPointsLastTime )
    {
        return;
    }
    NumPointsLastTime = NumPoints;

    let xhr2 = new XMLHttpRequest();
    xhr2.open("GET", baseUri + "SigImage/0" + "?noCache=" + generateUUID(), true);
    xhr2.responseType = "blob"
    xhr2.onload = function () {
        let img = new Image();
        img.src = getBlobURL(xhr2.response);
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            revokeBlobURL(this.src);
            img = null;
        }
    }
    xhr2.send(null);
}


function SigWebEvent() {
    let OldEvStatus = EvStatus;

    let xhr = SigWebcreateXHR();

    if (xhr) {
        xhr.open("GET", baseUri + "EventStatus" + "?noCache=" + generateUUID(), true);
        xhr.onload = function () {
            EvStatus = xhr.responseText
            if ((OldEvStatus & 0x01) && (EvStatus && 0x02)) {
                if (onSigPenDown) {
                    onSigPenDown();
                }
            }

            if ((OldEvStatus & 0x02) && (EvStatus && 0x01)) {
                if (onSigPenUp) {
                    onSigPenUp();
                }
            }
        }
        xhr.send(null);
    }
}

function generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}


let SigWebFontThreshold = 155;

function createLcdBitmapFromCanvas(ourCanvas, xp, yp, width, height) {
    let canvasCtx = ourCanvas.getContext('2d');
    let imgData = canvasCtx.getImageData(0, 0, width, height);
    let j = 0;
    let sVal = 0;
    let outData = "";
    let outIdx = 0;
    let data = imgData.data;

    for (let y = 0; y < height; y++)
        for (let x = 0; x < width; x++) {
            let tmp1 = data[j];
            let tmp2 = data[j + 1];
            let tmp3 = data[j + 2];
            let tmp4 = data[j + 3];
            j = j + 4;
            if (tmp1 < SigWebFontThreshold) {
                outData += "B";
            }
            else {
                outData += "W";
            }
        }

    return outData;
}

function LcdWriteImage(Dst, Mode, Xp, Yp, Url) {
    let Prop = "LcdWriteImage/";
    let NewUrl = Url.replace(/\//g, "_");

    Prop = Prop + Dst + "," + Mode + "," + Xp + "," + Yp + "," + NewUrl;
    SigWebSetPropertySync(Prop);
}

function LcdWriteImageStream(Dst, Mode, Xp, Yp, Xs, Ys, Url) {
    let Prop1 = "LcdWriteImageStreamParams/" + Dst + "," + Mode + "," + Xp + "," + Yp + "," + Xs + "," + Ys;
    let Prop2 = "LcdWriteImageStream/";

    SigWebSetPropertySync(Prop1);
    SigWebSetImageStreamProperty(Prop2, Url);
}

function GetSigWebVersion(){
    let prop = "SigWebVersion";

    let xhr = SigWebcreateXHR();

    if (xhr) {
        xhr.open("GET", baseUri + prop + "?noCache=" + generateUUID(), false );
        xhr.send(null);
        if (xhr.readyState === 4 && xhr.status === 200) {
            return xhr.responseText.slice(1, xhr.responseText.length - 1);
        } else{
            return "1.5";
        }
    }
    return "";
}

function ClearTablet() {
    const Prop = "ClearSignature";

    return SigWebGetProperty(Prop);
}

function NumberOfTabletPoints() {
    const Prop = "TotalPoints";

    return SigWebGetProperty(Prop);
}

function SetSigCompressionMode(v) {
    let Prop = "CompressionMode/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}

function SetEncryptionMode(v) {
    let Prop = "EncryptionMode/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}

function AutoKeyAddANSIData(keyData) {
    const Prop = "AutoKeyAddANSIData";

    return SigWebSyncSetStreamProperty(Prop, keyData);
}

function SetDisplayXSize(v) {
    let Prop = "DisplayXSize/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}


function SetDisplayYSize(v) {
    let Prop = "DisplayYSize/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}

function SetImageXSize(v) {
    let Prop = "ImageXSize/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}

function GetImageXSize() {
    let Prop = "ImageXSize";


    return SigWebGetProperty(Prop);
}

function SetImageYSize(v) {
    let Prop = "ImageYSize/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}

function GetImageYSize() {
    let Prop = "ImageYSize";


    return SigWebGetProperty(Prop);
}

function SetImagePenWidth(v) {
    let Prop = "ImagePenWidth/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}

function SetJustifyMode(v) {
    let Prop = "JustifyMode/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}


function KeyPadAddHotSpot(key, coord, xp, yp, xs, ys) {
    let Prop = "KeyPadAddHotSpot/";
    Prop = Prop + key + "," + coord + "," + xp + "," + yp + "," + xs + "," + ys;
    SigWebSetPropertySync(Prop);
}

function KeyPadQueryHotSpot(key) {
    let Prop = "KeyPadQueryHotSpot/";
    Prop = Prop + key;
    return SigWebGetProperty(Prop);
}

function KeyPadClearHotSpotList() {
    let Prop = "KeyPadClearHotSpotList";
    SigWebSetPropertySync(Prop);
}

function SetSigWindow(coords, xp, yp, xs, ys) {
    let Prop = "SigWindow/";

    Prop = Prop + coords + "," + xp + "," + yp + "," + xs + "," + ys;
    SigWebSetPropertySync(Prop);
}

function ClearSigWindow(inside) {
    let Prop = "ClearSigWindow/";
    Prop = Prop + inside;
    SigWebSetPropertySync(Prop);
}
function SetLCDCaptureMode(v) {
    let Prop = "CaptureMode/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}


function LCDSetWindow(xP, yP, xS, yS) {
    let Prop = "LCDSetWindow/";
    Prop = Prop + xP + "," + yP + "," + xS + "," + yS;
    SigWebSetPropertySync(Prop);
}


function LCDWriteString(dest, mode, x, y, fnt, size, str) {
    let c = document.createElement('canvas');
    let cntx = c.getContext('2d');
    cntx.font = fnt;
    let txt = str;
    let xs = Math.round(cntx.measureText(txt).width);
    let ys = size;
    c.width = xs;
    c.height = ys;

    if ( xs === 0 )
    {
        return;
    }

    cntx.font = fnt;
    cntx.fillStyle = '#FFFFFF'
    cntx.rect(0, 0, xs, ys);
    cntx.fill();


    cntx.fillStyle = '#000000'
    cntx.textBaseline = "top";
    cntx.fillText(txt, 0, 0);

    cntx.drawImage(cntx.canvas, 0, 0, xs, ys);

    let Gstr = createLcdBitmapFromCanvas(c, x, y, xs, ys);

    LcdWriteImageStream(dest, mode, x, y, xs, ys, Gstr);
}


function LCDStringWidth(fnt, str) {
    let c = document.createElement('canvas');
    let cntx = c.getContext('2d');
    cntx.font = fnt;
    return Math.round(cntx.measureText(str).width);
}


function LCDStringHeight(fnt, str) {
    return 16;
}

function LcdRefresh(Mode, Xp, Yp, Xs, Ys) {
    let Prop = "LcdRefresh/";

    Prop = Prop + Mode + "," + Xp + "," + Yp + "," + Xs + "," + Ys;
    SigWebSetPropertySync(Prop);
}

function LCDSendGraphicUrl(dest, mode, x, y, url) {
    LcdWriteImage(dest, mode, x, y, url)
}

function LCDGetLCDSize() {
    let Prop = "LcdGetLcdSize";


    return SigWebGetProperty(Prop);
}

function SetRealTabletState(v) {
    let Prop = "TabletState/";

    Prop = Prop + v;
    SigWebSetPropertySync(Prop);
}

function GetTabletState() {
    let Prop = "TabletState";


    return SigWebGetProperty(Prop);
}

function TabletModelNumber() {
    let Prop = "TabletModelNumber";


    return SigWebGetProperty(Prop);
}
function Reset() {
    let Prop = "Reset";
    SigWebSetProperty(Prop);
}

function SetTabletState(v, ctx, tv)
{
    let delay;


    if (tv)
    {
        delay = tv;
    }
    else
    {
        delay = 100;
    }

    if (GetTabletState() !== v)
    {
        if (v === 1)
        {
            if (ctx)
            {
                let can = ctx.canvas;
                SetDisplayXSize( can.width );
                SetDisplayYSize( can.height );
                SigWebSetDisplayTarget(ctx);
            }
            SetRealTabletState(v);
            if ( ctx && (GetTabletState() !== 0) )
            {
                let tmr = setInterval(SigWebRefresh, delay);
            }
            else
            {
                let tmr = null;
            }

            return tmr;
        }
        else
        {
            if (ctx)
            {
                clearInterval(ctx);
            }
            SigWebSetDisplayTarget(null);
            SetRealTabletState(v);
        }
    }
    return null;
}