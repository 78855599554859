import React, {Component} from 'react';
import GeneralSelect from "../../../../utils/GeneralSelect";
import TransactionPopup from "../transaction_popup";
import TxCreateService from "../../../../services/transaction/transaction_create";
const options ={
    'endpoint':'finance/fee-types',
    'value':'paymentDefinitionId',
    'label':'paymentName',
    'placeholder':'Select Payment'
}
class AddBillItem extends Component {
    constructor(props) {
        super(props);
        this.state={
            amount:0,
            total:0,
            items:[],
            billPaymentId:null,
            feeType:'',
            feeId:null,
            feeTypes:[],
            selectedOption: '',
            clearable: true,
            searchable:''

        }
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.calculate = this.calculate.bind(this)
        this.saveBillItem = this.saveBillItem.bind(this)
    }

    handleSelectChange = event =>{
        this.setState({paymentName:event.label})
        this.setState({feeId:event.value})
    }
    componentDidMount() {

   /*     TxReadService.getFeeTypes().then(d=>{
            if(d.data.status==1){
                this.setState({feeTypes:d.data.result});
            }
        })*/

    }

    render() {
       /* let options = this.state.feeTypes.map(function (fee) {
            return fee.paymentName;
        })*/
        return (
            <div>
                <table>
                    <thead><th>Add Bill Item</th></thead>
                    <tbody>
                    <tr>
                        <td>Fee Type:</td>
                    </tr>
                    <tr>
                        <td>
                            <GeneralSelect onChanges={this.handleSelectChange} parameters={options}/>
                        </td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                    </tr>
                    <tr>
                        <td><input/></td>
                    </tr>
                    <tr>
                        <td><button onClick={()=>this.calculate}>Calculate</button></td>
                    </tr>
                    <tr>
                        <td><button onClick={()=>this.saveBillItem}>Save</button></td>
                    </tr>
                    </tbody>
                </table>

            </div>
        );
    }

    calculate=()=>{
        console.log(" data",this.state.paymentName)
        let data={
            feeId:this.state.feeId,
            transactionId:TransactionPopup.id()
        }
        console.log(" calculate",data)
        TxCreateService.getAdditionalItemAmount(data).then(d=>{

            if(d.data.status==1){
                this.setState({amount:d.data.result})
            }else {

            }
        })

}

saveBillItem=()=>{
     let totalBillAmount= parseFloat(this.state.total)+  parseFloat(this.state.amount)

    let data={
        billPaymentId:this.state.feeId,
        total:totalBillAmount,
        feeId:this.state.feeId,
        itemAmount:this.state.amount
    }
    TxCreateService.saveAdditionalBillItemAmount(data).then(d=>{
        if(d.data.status==1){

        }else{

        }
    })
}
}

export default AddBillItem;
