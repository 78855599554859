import React, {useState} from 'react'
import { styles} from "../popups/transacrions/screen/record-request";
import Items from "./items";
import Location from "./location";
import logo from "../../../resource/img/nembo.png";
import Dashboard from "../containers/dashboard";

export default function RecordManagement(){
    const[currentMenu,currentMenuSet] = useState(0)
    return(
        <div style={styles.main} className={'base-container'} id={'base-container'}>

            <div className={'col col-sm-12 base-toolbar'}>

                <div className={'col col-sm-2'} style={{float:'left'}}>
                    <span className={'fa fa-navicon'} style={{marginRight:'16px',fontSize:'20px'}}/>
                    <img src={logo} alt={'logo'} style={{height:'4vh',marginRight:'16px',marginTop:'-1.5vh'}}/>
                    <span style={{fontFamily:'oxygen-bold',fontSize:'20px'}}>ILMIS</span>

                </div>

                {/*<div id={'btn-new'} style={{float:'left',padding:'0px 8px'}} onClick={()=>this.showAddSubmenuAt(0,[])}>
                    <span className={'fa fa-plus'} style={{fontSize:'18px',marginRight:'8px'}}/>
                    <b>New Project</b>
                </div>*/}

                <div style={{float:'right'}}>
                    <span>{sessionStorage.getItem('username')}</span>
                    <span onClick={()=>Dashboard.logout()} className={'fa fa-power-off'}
                          style={{fontSize:'18px',cursor:'pointer',marginLeft:'16px'}}/>
                </div>

            </div>

            <div className={"col col-sm-12"} style={styles.detailsHeader}>
                <label style={currentMenu === 0?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(0)}}>Items</label>
                <label style={currentMenu === 1?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(1)}}>Location</label>

                <button style={{float:'right',width:'80px'}} onClick={()=>document.getElementById('base-container').style.display='none'}>Close</button>
            </div>
            <div className={'col col-sm-12'} style={{float:'left'}}>
                <div style={currentMenu === 0?styles.show:styles.hide}>
                    <Items/>
                </div>
                <div style={currentMenu === 1?styles.show:styles.hide}>
                    <Location/>
                </div>

            </div>

        </div>
    )
}