import * as React from "react";
import SMDReadService from "../../../../services/smd/smd_read";
import TPReadService from "../../../../services/tp/tp_read";
import { centroid, featureCollection, lineString, multiPolygon, polygon } from "@turf/turf";
import { GeoJSON, ImageOverlay, LayersControl, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import 'proj4leaflet'
import L from 'leaflet'
import 'leaflet-graticule'
import 'leaflet-ruler'
import 'leaflet-auto-graticule'
import { LayerGroup, MapContainer } from "react-leaflet";
import MobilePopup from "../../../popups/mobile/mobile_popup";
import { DivIcon } from "leaflet";
import LatLngGraticule from "react-leaflet-lat-lng-graticule";
import { Computations, joinBlocks, parralelismCheck, polgonsToLineSegment, polyToLine } from "./surveytab";
import AutoGraticule from "leaflet-auto-graticule";
import UgSpatial from "../../../maps/ug_spatial";
import proj4 from "proj4";
import '../../../../utils/ruler'
import LeafletRuler from "../../../../utils/LeafletRuler";
import Basemap from "../../../maps/basemap";
import Wait from "../../../popups/wait";
function SpatialMapDisplay({ updateD=()=>{},computation=true,txId, type, sptype, computeData = false, style = { height: 450, width: "100%", boxShadow: "0px 0px 5px 0px #000", backgroundColor: "#fff" }, updateGeom = () => { } }) {
    const utmProjection = "+proj=utm +zone=36 +south +datum=WGS84 +units=m +no_defs";
    const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';
    const [dat, setData] = React.useState({ regionId: 0 });
    const crs32736 = new L.Proj.CRS(
        'ESPG:32736',
        utmProjection,
        {
            //     bounds:[[-238375.0,9046750.0],[805125.0,10000000.0]],
            resolutions: [
                8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25
            ],

            //     origin: [-238375.0,9046750.0],

        }

    )
    const updateData = (data) => {
        setData(data)
        updateD(data)
    }
    // Define the CRS (Coordinate Reference System) for EPSG:32736
    const utmCRS = crs32736
    return (
        <div>

            <MapContainer center={[0, 0]} zoom={12} style={style}>
                <Spatial updateData={updateData} updateGeom={updateGeom} txId={txId} type={type} sptype={sptype} computeData={false} style={style} />
                <LeafletRuler />
            </MapContainer>
            {sptype === "sp" && dat.regionId !== 0 && computation ? <Computations sp={{ x: dat.controlX, y: dat.controlY, n: dat.controlName }} distanc={dat.distance} sp3={{ x: dat.controlX2, y: dat.controlY2, n: dat.controlName2 }} sp2={{ x: dat.controlX1, y: dat.controlY1, n: dat.controlName1 }} regionId={dat.regionId} /> : null}
        </div>
    )
}
function Spatial({ txId, type, sptype, computeData = false, style = { height: 650, width: "100%", boxShadow: "0px 0px 5px 0px #000" }, updateData = () => { }, updateGeom = () => { } }) {
    const [planData, setPlanData] = React.useState({ regionId: 0, crs: 'ESPG:4326', centroid: [50, 0] });
    const [, forceUpdate] = React.useState()
    const mapRef = React.useRef(null)
    const [myMap, setMap] = React.useState(null)
    const [layers, setLayers] = React.useState({ Grid: null });
    const map = useMap()
    const utmProjection = "+proj=utm +zone=36 +south +datum=WGS84 +units=m +no_defs";
    const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';
    const textMarker = (position, text, style = "") => {
        // console.log(position)
        let x = proj4(utmProjection, wgs84Projection, position);
        // let p = 
        let m = L.marker([position[0], position[1]], { icon: L.divIcon({ className: 'tp-text', html: `<div style="font-size:10px;color:#000;font-weight:bold;${style}" class='mapicondiv'>${text}</div>` }) })
        return m
    }
    const createGridLines = () => {
        map.eachLayer(function (layer) {
            if (layer.options.attribution === "grid") {
                map.removeLayer(layer);
            }
        });
        // console
        // Get the current map bounds
        var bounds = map.getBounds();
        var south = bounds.getSouth();
        var west = bounds.getWest();
        var north = bounds.getNorth();
        var east = bounds.getEast();
        let lineFactor = Math.abs(east - west) / 10
        var latSpacing = lineFactor / 1; // Latitude grid spacing (e.g., 10 degrees)
        var lngSpacing = latSpacing / 1; // Longitude grid spacing (e.g., 15 degrees)
        let ls = []
        for (var lat = Math.ceil(south / latSpacing) * latSpacing; lat <= north; lat += latSpacing) {
            let d1 = proj4(wgs84Projection, utmProjection, [west, lat]);
            // d1[lat,west]
            let p1 = L.polyline([[lat, west], [lat, east]], { color: '#000', dashArray: 3, weight: 0.9 })
            let p2 = L.marker([lat, west], { icon: L.divIcon({ className: 'grid-label', html: `<p style="margin-left:5px">${d1[0].toFixed(2).toString()}</p>` }) })
            let p3 = L.marker([lat, east], { icon: L.divIcon({ className: 'grid-label', html: `<p style="margin-left:5px">${d1[0].toFixed(2).toString()}</p>` }) })
            ls.push(p3)
            ls.push(p2)
            ls.push(p1)
        }
        for (var lng = Math.ceil(west / lngSpacing) * lngSpacing; lng <= east; lng += lngSpacing) {
            let d2 = proj4(wgs84Projection, utmProjection, [lng, south]);
            let p6 = L.polyline([[north, lng], [south, lng]], { color: '#000', dashArray: 3, weight: 0.5 })
            let p4 = L.marker([north, lng], { icon: L.divIcon({ className: 'grid-label', html: `<p style="margin-top:5px">${d2[1].toFixed(2).toString()}</p>`, }) })
            let p5 = L.marker([south, lng], { icon: L.divIcon({ className: 'grid-label', html: `<p style="margin-top:5px">${d2[1].toFixed(2).toString()}</p>` }) })
            ls.push(p5)
            ls.push(p4)
            ls.push(p6)
        }
        let p = layers;
        p.Grid = L.layerGroup(ls, { attribution: "grid" }).addTo(map)

        setLayers(p)
    }

    const getTp = (tx, type) => {
        return TPReadService.getSpatial(tx, type).then((r) => {
            let last = null
            let index = 1
            let dat = []
            let texts = []
            r.data.results.forEach((prop) => {
                let g = polygon(JSON.parse(prop.geom).coordinates[0])
                dat.push(g)
                let center = centroid(g)
                index++;
                let c = textMarker(center.geometry.coordinates, prop.landUse !== null ? getLU(prop.landUse) : null)
                texts.push(c)
            });
            let lb = L.layerGroup(texts)
            let ls = layers
            lb.addTo(map)
            ls["Plot numbers, Distance,Area Labels, Land use"] = lb
            setLayers(ls)
            let coll = featureCollection(dat);
            addLayer("TP Layer", geojsonfy(crs4326, coll.features), mapStyle("tp"))
            forceUpdate({})

        })
    }
    const crs4326 = "urn:ogc:def:crs:OGC:1.3:CRS84"
    const getSp = (lname, tx,adjoining=false) => {
        return SMDReadService.readSpatialGeom(tx).then((f) => {
            let texts = []
            const plotData = f.data
            f.data.features.forEach(t => {
                let c = textMarker(centroid(t).geometry.coordinates, `<div><center><span style="color:#f00">${(t.properties.plotNum)}</span><br/>${Number(t.properties.area).toFixed(2)}m<sup>2</sup></center></div>`)
                texts.push(c)
            })
            planData.centroid = [0, 0]
            if(!adjoining){
                Wait.show("Loading Survey Plan")
                let geoJSon = geojsonfy(crs4326, f.data.features)
                addLayer("Survey plan", geoJSon, mapStyle("survey"))
                Wait.show("Joining Polygons")
                let lines = polyToLine(plotData.features);
                let theb = joinBlocks(plotData.features)
                let paral = parralelismCheck(theb)
                Wait.show("Calculating Parallelism")
                addLayer("Parallelism Computations", geojsonfy(crs4326, paral), mapStyle("computation"))
                addLayer("Blocks", geojsonfy(crs4326, theb), mapStyle("blocks"))
                Wait.show("Calculating Line distances")
                lines.forEach(t => {
                    let c = textMarker(t.properties.centroid, t.properties.distance, "transform:rotate(" + (Number(t.properties.angle)+90) + "deg);font-size:10px;").addTo(map)
                    texts.push(c)
                })
            }
            else{
                let geoJSon = geojsonfy(crs4326, f.data.features)
                addLayer("Adjoining Survey plan", geoJSon, mapStyle("adjoining_survey"))
            }
            Wait.dismiss()
            let lb = L.layerGroup(texts)
            let ls = layers
            lb.addTo(map)
            ls["Plot numbers, Distance,Area Labels, Land use"] = lb
            setLayers(ls)
            forceUpdate({})

        });
    }
    const getIPC = (tx) => {
        let ipc1 = (b)=>( '<div class="tx11"><div class="tx1"></div>'+b+'</div>');
        let ipc2 = '<div class="tx2"></div>';
        let bp = []
        return SMDReadService.listIPC(tx).then((r) => {
            r.data.map((prop) => {
                bp.push(textMarker([prop.x,prop.y], ipc1(prop.beaconNum), ''));
            });
            let lb = L.layerGroup(bp)
            let lv = layers
            lv["IPC"] = lb
            lb.addTo(map)
        });
    }
    const addLayer = (layername, layer, style) => {
        let lay = L.geoJSON(layer, {
            coordsToLatLng: (coords) => {
                return coords;
            },
            style: style
        })
        map.fitBounds(lay.getBounds())
        let gr = new L.layerGroup([lay],).addTo(map);
        let d = layers
        d[layername] = gr
        setLayers(d)
        forceUpdate({})
    }

    const getPlanData = () => {
        if (sptype === "tp") {
            return TPReadService.getTpByTx(txId).then(r => {
                let p = r.data
                if (p.result.length > 0) {
                    let l = p.result[0]
                    let cent = p.centroid;
                    cent = cent.replace(")", "").replace("POINT(", "").split(" ");
                    l["centroid"] = cent;
                    console.log(cent)
                    setPlanData(l)
                    updateData(l)
                }
                else {

                }
                return r;
            });
        }
        else {
            return SMDReadService.read(txId).then(r => {
                let b = r.data
                b["centroid"] = [0, 0]
                setPlanData(b)
                updateData(b)
                forceUpdate({})
                return r;
            })
        }
    }

    React.useEffect(() => {
        try {
            createGridLines();
            map.on('moveend', createGridLines);
            L.control.scale({ imperial: false }).addTo(map);
            // map.addControl(new AutoGraticule({ redraw: 'moveend' }))
            getPlanData().then((r) => {
                if (sptype === "tp") {
                    getTp(txId, type).then(() => {
                        L.control.layers({ "Google Satellite Image": Basemap.googleHybrid() }, layers, { position: "bottomright" }).addTo(map);
                    })
                    // getAdjTp();
                } else {
                    getTp(r.data.tpTx, 'tp').then(() => {
                        getSp("Survey Plan", txId).then(() => {
                            getIPC(txId).then(() => {
                                L.control.layers({ "Google Satellite Image": Basemap.googleHybrid() }, layers, { position: "bottomright" }).addTo(map);
                            })
                        })
                    })

                    // setTimeout(()=>{

                    // },1000)
                }

                forceUpdate({})
                // setTimeout(() => {
                // }, 1000);
            })
        }
        catch (e) {

        }
    }, [])
    return (
        <div>

            {/* <div id="map" style={{height:400,width:"100%"}} ref={mapRef}></div> */}
        </div>
    )
}

function mapStyle(map) {
    const p = {
        tp: {
            color: "#000",
            fillColor: "rgba(0,0,0,0)"
        },
        survey: {
            color: "#ccf",
            fillColor: "rgba(0,0,0,0)"
        },
        mobile_data: {
            color: "#fcf",
            fillColor: "rgba(0,0,0,0)"
        },
        labels: {
            color: "#000"
        },
        computation: {
            color: "#f00",
            fillColor: "rgba(0,255,0,0.3)"
        },
        blocks: {
            color: "#fcc",
            fillColor: "",
        },
        adjoining_survey:{
            dashArray: [3],
            color:"#000",
        }
    }
    return p[map]
}

function geojsonfy(espg, data, props = {}) {
    if (typeof data === "string") {

        let geoJSon = {
            type: "FeatureCollection",
            crs: {
                type: "name",
                properties: {
                    name: espg,
                },
            },
            features: data,
        };
        // console.log(geoJSon)
        return geoJSon;
    }
    else {
        return data;
    }

}
function getLU(landUse) {
    if (landUse !== null) {

        let b = {
            Residential: 'R',
            Commercial_Residential: 'CR',
            Housing_Estate: 'HE'
        }
        if (b[landUse.replace(' ', '_')] !== undefined) {
            return b[landUse.replace(' ', '_')];
        }
        else {
            return 'OT'
        }
    } else {
        return 'RL';
    }

}
export { getLU, SpatialMapDisplay }