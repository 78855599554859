import React, {useEffect, useState} from 'react'
import Select from "react-select";
import { styles } from "./record-request";
import Header from "./gro/header";
import PopDetail from "../../popdetail";
import API from "../../../../services/API";
import TransactionPopup from "../transaction_popup";
import { colourStyles } from "../../../../componentss/valuation-tabs/purpose";
import Moment from 'moment';
import { useForm } from "react-hook-form";
import Wait from "../../wait";
import GeneralSelect from "../../../../utils/GeneralSelect";

export const Rrr = ({interestId,tenancyType, partyRoles, shares=false,refresh}) => {
    const [partyId, partyIdSet] = useState()
    let txId = TransactionPopup.id()
    let endPoint = `transaction/transaction-parties/${txId}`
    const partiesOptions =
    {
        'endpoint': endPoint,
        'value': 'partyId',
        'label': 'fullName',
        'placeholder': 'Select Party'
    }
    const setPartyId = data => {
        partyIdSet(data ? data.map(a => a.value) : [])
    }
    const submitRrr = data => {
        if (partyId !== undefined) {
            Wait.show("Saving RRR")
            let datas = { ...data, transactionId: txId, tenancyType, interestId, partyId }
            console.log("Party Id  tenancy", datas)
            API.ax.post("/land/rrr", datas)
                .then(d => {
                    const { status } = d.data
                    if (status === 1) {
                        PopDetail.dismiss()
                        Wait.dismiss()
                        refresh(true)
                    }
                })
        }
    }

    const { register, handleSubmit } = useForm()
    return (
        <form onSubmit={handleSubmit(submitRrr)}>
            <div style={style.columns}>
                <div style={style.columns}>
                    <label><span style={{ color: 'red' }}>* </span>Party Role</label>
                    <select style={{ width: '100%' }} name={'partyRoleType'} ref={register({ required: true })}>
                        {partyRoles.map((d, i) =>
                            <option value={d} key={d}>{d}</option>
                        )}
                    </select>
                </div>
                {shares && <div style={style.columns}>
                    <label><span style={{ color: 'red' }}>* </span>Share</label>
                    <div style={{ ...style.columns, flexDirection: 'row' }}>
                        <input name={'shareNumerator'} type={'number'} defaultValue={1} style={{ width: '50%' }} ref={register({ required: shares })} />
                        <span style={{ fontWeight: 'bold' }}>/</span>
                        <input name={'shareDenominator'} type={'number'} defaultValue={1} style={{ width: '50%' }} ref={register({ required: shares })} />
                    </div>
                </div>}
                <div>
                    <label><span style={{ color: 'red' }}>* </span>Person</label>
                    <GeneralSelect parameters={partiesOptions} onChanges={setPartyId} isMulti={true} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <button style={{ width: '150px', }} type={'submit'}>Save</button>
                    <button style={{ width: '150px', }} className={'default'} onClick={() => PopDetail.dismiss()}>Cancel</button>
                </div>
            </div>
        </form>
    )
}
const Single = ({ heading, display, data, interestId, tenancyType, transactionId, partyRoles, dShares = false, writable, refresh }) => {
    const [dataResp, dataRespSet] = useState([])
    const [selected, selectedSet] = useState({})
    return (
        <>
            <Header display={writable && display ? display : false} label={heading}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={interestId ? () => { PopDetail.show("New", <Rrr interestId={interestId} tenancyType={tenancyType} partyRoles={partyRoles} refresh={refresh} transactionId={transactionId} shares={dShares} />) } : () => { }}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={
                            () => {
                                API.ax.post("land/remove-rrr", { ...selected })
                                    .then(d => {
                                        console.log("the occupiers ", d.data)
                                        const { result, status } = d.data
                                        if (status === 1) {
                                            console.log("the result of occupiers ", result)

                                        }

                                    })
                            }
                        }
                    /><strong> Delete</strong>
                </div>
            </Header>
            <table className={'tb'} style={styles.table}>
                <thead>
                    <tr>
                        {dShares && <th>SHARE</th>}
                        <th>PARTY ROLE</th>
                        <th>NAME</th>
                        <th>ADDRESS</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map(({ id, share, partyRole, name, address, transactionId }, i) =>
                        <tr key={i} onClick={() => selectedSet({ id: id, transactionId: transactionId })}>
                            {dShares && <td>{share}</td>}
                            <td>{partyRole}</td>
                            <td>{name}</td>
                            <td>{address}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </>
    )
}
const SourceTarget = ({ sHeading, tHeading, sData, tData, sRead, tRead, writable, refresh, sShares, tShares, interestId, sPartyRole, tPartyRole }) => {
    //right and left
    return (
        <div className={'row'} style={{ ...styles.content, margin: 0 }}>
            <div className={"col col-sm-6"} style={{ margin: 0, padding: 0, borderRight: '1px solid lightgray', overflow: 'auto' }}>
                <Single display={sRead} heading={sHeading} data={sData} writable={writable} dShares={sShares} interestId={interestId} partyRoles={sPartyRole} refresh={refresh} />
            </div>
            <div className={"col col-sm-6"} style={{ margin: 0, padding: 0, overflow: 'auto' }}>
                <Single display={tRead} heading={tHeading} data={tData} writable={writable} dShares={tShares} interestId={interestId} partyRoles={tPartyRole} refresh={refresh} />
            </div>
        </div>
    )
}
const Any = ({ refresh }) => {
    const [selectedOption] = useState()
    const [rrrType, rrrTypeSet] = useState('Caveat')
    const onChange = data => {
        rrrTypeSet(data.value)
    }

    return (
        <div style={{ width: '100%' }}>
            <Select
                value={selectedOption}
                onChange={onChange}
                options={options}
                styles={colourStyles}
                defaultValue={options[0]} />
            <button onClick={() => { PopDetail.dismiss() }} className={'default'} style={{ width: "150px", float: "right", marginTop: "1rem" }}> Cancel</button>
            <button style={{ width: "150px", float: "right", marginTop: "1rem" }} onClick={() => PopDetail.show("New Interest", <Encumbrance rrrType={rrrType} refresh={refresh} />)}>Add</button>
        </div>
    )
}
const Encumbrance = ({ rrrType, data = null, refresh }) => {
    const [current, currentSet] = useState();
    const submit = datum => {
        console.log("the data ", data)
        let transactionId = TransactionPopup.id()
        let dataToSend
        if (data !== null) {
            Wait.show("Updating ...")
            dataToSend = { ...datum, interestId: data.interestId, transactionId }
        } else {
            Wait.show("Saving ...")
            dataToSend = { ...datum, transactionId }

        }
        API.ax.post("/rt/save-interest", dataToSend)
            .then(d => {
                Wait.dismiss()
                let { status } = d.data
                if (status === 1) {
                    PopDetail.dismiss()
                    refresh(true)
                }
                else {
                    PopDetail.dismiss()
                }
            })
            .catch(e => console.log(e))
        console.log("dataTo Send", dataToSend)
    }
    useEffect(() => {
        if (rrrType === 'Caveat' || rrrType === 'Power of Attorney') { currentSet(<Caveat submit={submit} name={rrrType} data={data} />) }
        else if (rrrType === 'Charge' || rrrType === 'Injunction') { currentSet(<Charge submit={submit} name={rrrType} data={data} />) }
        else if (rrrType === 'Deeds') { currentSet(<Deeds submit={submit} name={rrrType} data={data} />) }
        else if (rrrType === 'Deposit' || rrrType === 'Mortgage') { currentSet(<Deposit submit={submit} name={rrrType} data={data} />) }
        else if (rrrType === 'Lease' || rrrType === 'Sublease') { currentSet(<Lease submit={submit} name={rrrType} data={data} />) }
        else if (rrrType === 'Right of Occupancy' || rrrType === 'Sublease') { currentSet(<Right submit={submit} data={data} name={rrrType} />) }
    }, [rrrType])
    return (
        <>{current}</>
    )

}
const Caveat = ({ submit, name, data }) => {
    const { handleSubmit, register } = useForm()
    return (
        <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td><span style={{ color: 'red' }}>*</span>FD Number</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} name={'rrrType'} readOnly={true} style={{ width: '100%', height: '25px' }} value={name} ref={register} /></td>
                        <td><input type={'text'} name={'fdNumber'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.fdNumber} /></td>
                    </tr>
                    <tr>
                        <td>Commencement Date</td>
                        <td>Expiration Date</td>
                    </tr>
                    <tr>
                        <td><input type={'date'} name={'startDate'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.startDate} /></td>
                        <td><input type={'date'} name={'endDate'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.endDate} /></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            Particular
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <textarea rows={4} name={'description'} style={{ resize: "none", width: '100%' }} ref={register} defaultValue={data?.description} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Registration Date/Time
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type={'date'} name={'registrationDatetime'} style={{ width: '100%' }} ref={register} defaultValue={data?.registrationDatetime} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className={'default'} style={{ width: '150px', float: 'right' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>
                            <button style={{ width: '150px', float: 'right' }} type={'submit'}>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    )
}
const Charge = ({ submit, name, data }) => {
    const { handleSubmit, register } = useForm()
    return (
        <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td><span style={{ color: 'red' }}>*</span>FD Number</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} name={'rrrType'} readOnly={true} style={{ width: '100%' }} value={name} ref={register} /></td>
                        <td><input type={'text'} name={'fdNumber'} style={{ width: '100%' }} ref={register} defaultValue={data?.fdNumber} /></td>
                    </tr>
                    <tr>
                        <td>Commencement Date</td>
                        <td>Expiration Date</td>
                    </tr>
                    <tr>
                        <td><input type={'date'} name={'startDate'} style={{ width: '100%' }} ref={register} defaultValue={data?.startDate} /></td>
                        <td><input type={'date'} name={'endDate'} style={{ width: '100%' }} ref={register} defaultValue={data?.endDate} /></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            Particular
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <textarea rows={4} name={'description'} style={{ resize: "none", width: '100%' }} ref={register} defaultValue={data?.description} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Registration Date/Time
                        </td>
                        <td>
                            <span style={{ color: 'red' }}>*</span>Placed By
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type={'date'} name={'registrationDatetime'} style={{ width: '100%' }} ref={register} defaultValue={data?.registrationDatetime} />
                        </td>
                        <td>
                            <select style={{ width: '100%' }} name={'authorityTypeName'} ref={register} defaultValue={data?.authorityTypeName}>
                                <option value={'Assistant Commissioner for Lands'}>Assistant Commissioner for Lands</option>
                                <option value={'Commissioner of Income Tax'}>Commissioner of Income Tax</option>
                                <option value={'Commissioner for Lands'}>Commissioner for Lands</option>
                                <option value={'Court'}>Court</option>
                                <option value={'Prevention and Combating of Corruption Bureau'}>Prevention and Combating of Corruption Bureau</option>
                                <option value={'Registrar of Titles'}>Registrar of Titles</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className={'default'} style={{ width: '150px', float: 'right', marginTop: '1rem' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>
                            <button style={{ width: '150px', float: 'right', marginTop: '1rem' }} type={'submit'}>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    )
}
const Deeds = ({ submit, name, data }) => {
    const { handleSubmit, register } = useForm()
    return (
        <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>Deed Type</td>
                        <td><span style={{ color: 'red' }}>*</span>FD Number</td>
                    </tr>
                    <tr>
                        <td><select style={{ width: '100%' }} name={'deedType'} ref={register({ required: true })} defaultValue={data?.deedType} >
                            <option value={'Caveat'}>Caveat</option>
                            <option value={'Injunction'}>Injunction</option>
                            <option value={'Power of Attorney'}>Power of Attorney</option>
                            <option value={'RDA Caveat'}>RDA Caveat</option>
                            <option value={'RDA Injunction'}>RDA Injunction</option>
                            <option value={'RDA Power of Attorney'}>RDA Power of Attorney</option>
                            <option value={'RDA Will'}>RDA Will</option>
                            <option value={'Will'}>Will</option>
                        </select></td>
                        <td><input type={'text'} name={'fdNumber'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.fdNumber} /></td>
                    </tr>
                    <tr>
                        <td>Commencement Date</td>
                        <td>Expiration Date</td>
                    </tr>
                    <tr>
                        <td><input type={'date'} name={'startDate'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.startDate} /></td>
                        <td><input type={'date'} name={'endDate'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.endDate} /></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            Particular
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <textarea rows={4} name={'description'} style={{ resize: "none", width: '100%' }} ref={register} defaultValue={data?.description} />
                        </td>
                    </tr>
                    <tr>
                        <td>Type</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} name={'rrrType'} readOnly={true} style={{ width: '100%' }} value={name} ref={register} /></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className={'default'} style={{ width: '150px', float: 'right' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>
                            <button style={{ width: '150px', float: 'right' }} type={'submit'}>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    )

}
const Deposit = ({ submit, name, data }) => {
    const { handleSubmit, register } = useForm()
    return (
        <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td><span style={{ color: 'red' }}>*</span>FD Number</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} name={'rrrType'} readOnly={true} style={{ width: '100%', height: '25px' }} value={name} ref={register} /></td>
                        <td><input type={'text'} name={'fdNumber'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.fdNumber} /></td>
                    </tr>
                    <tr>
                        <td>Source Interest</td>
                        <td>Unspecified Amount</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} name={'sourceInterest'} value={'In Whole'} readOnly={true} style={{ width: '100%' }} ref={register} defaultValue={data?.sourceInterest} /></td>
                        <td><input type={'checkbox'} name={'unspecifiedAmount'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.unspecifiedAmount} /></td>
                    </tr>
                    <tr>
                        <td>Secured Amount TSH</td>
                        <td>Secured Amount USD</td>
                    </tr>
                    <tr>
                        <td><input type={'number'} name={'amount'} style={{ width: '100%' }} ref={register} defaultValue={data?.amount} /></td>
                        <td><input type={'number'} name={'amountSecond'} style={{ width: '100%' }} ref={register} defaultValue={data?.amountSecond} /></td>
                    </tr>
                    <tr>
                        <td>Commencement Date</td>
                        <td>End Date</td>
                    </tr>
                    <tr>
                        <td><input type={'date'} name={'startDate'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.startDate} /></td>
                        <td><input type={'date'} name={'endDate'} style={{ width: '100%', height: '25px' }} ref={register} defaultValue={data?.endDate} /></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            Particular
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <textarea rows={4} name={'description'} style={{ resize: "none", width: '100%' }} ref={register} defaultValue={data?.description} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Registration Date/Time
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input type={'date'} name={'registrationDatetime'} style={{ width: '100%' }} ref={register} defaultValue={data?.registrationDatetime} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button className={'default'} style={{ width: '150px', float: 'right' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>
                            <button style={{ width: '150px', float: 'right' }} type={'submit'}>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    )

}
const Lease = ({ submit, name, data }) => {
    const { handleSubmit, register } = useForm()
    const [term, termSet] = useState('')
    const [startDate, startDateSet] = useState('')
    const [isCustom, isCustomSet] = useState(false)
    const [endDate, endDateSet] = useState('')
    const [purpose, purposeSet] = useState('In Whole')
    const [isPartial, isPartialSet] = useState(false)
    useEffect(() => {
        if (purpose === 'Partial') {
            isPartialSet(true)
        } else {
            isPartialSet(false)
        }
    }, [purpose])
    useEffect(() => {
        if (term !== '' && term !== 'custom' && startDate.trim() !== null) {
            isCustomSet(true)
            endDateSet(Moment(new Date(startDate)).add(term, 'years').subtract(1, "days").format('YYYY-MM-DD'))
        } else {
            isCustomSet(false)
        }
    }, [term, startDate])
    useEffect(() => {

        endDateSet(data?.endDate)
        console.log("The end date is ", data?.endDate)

    }, [])
    return (
        <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td><span style={{ color: 'red' }}>*</span> Tenancy Type</td>
                        <td><span style={{ color: 'red' }}>*</span>FD Number</td>
                    </tr>
                    <tr>
                        <td><input style={{ width: '100%' }} name={'rrrType'} type={'text'} readOnly={true} value={name} ref={register} /></td>
                        <td>
                            <select name={'tenancyTypeName'} style={{ width: '100%' }} defaultValue={data?.tenancyTypeName} ref={register}>
                                <option value={''} />
                                <option value={'Joint Occupancy'}>Joint Occupancy</option>
                                <option value={'Single Occupancy'}>Single Occupancy</option>
                                <option value={'Tenancy in Common in Equal Shares'}>Tenancy in Common in Equal Shares</option>
                                <option value={'Tenants in Common in Unequal Shares'}>Tenants in Common in Unequal Shares</option>
                            </select>
                        </td>
                        <td><input style={{ width: '100%' }} name={'fdNumber'} type={'text'} ref={register} defaultValue={data?.fdNumber} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ color: 'red' }}>*</span> Term</td>
                        <td><span style={{ color: 'red' }}>*</span> Commencement Date</td>
                        <td><span style={{ color: 'red' }}>*</span> End Date</td>
                    </tr>
                    <tr>
                        <td>
                            <select style={{ width: '100%' }} name={'tenureDurationName'} onChange={(e) => termSet(e.target.value)} ref={register} defaultValue={data?.tenureDurationName}>
                                <option value={"custom"}>Custom</option>
                                <option value={"5"}>Five(5) Years</option>
                                <option value={"99"}>Nintey Nine (99) Years</option>
                                <option value={"66"}>Sixty Six (66) Years</option>
                                <option value={"33"}>Thirty Three (33) Years</option>
                            </select>
                        </td>
                        <td>
                            <input type={'date'} name={'startDate'} style={{ width: '100%' }} onChange={(e) => startDateSet(e.target.value)} defaultValue={data?.startDate} ref={register({ required: true })} />
                        </td>
                        <td>
                            <input type={'date'} name={'endDate'} style={{ width: '100%' }} readOnly={isCustom} onChange={e => { endDateSet(e.target.value) }} value={endDate} ref={register({ required: true })} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>Particulars</td>
                    </tr>
                    <tr>
                        <td colSpan={3}><textarea rows={3} name={'description'} style={{ width: '100%', resize: 'none' }} ref={register} defaultValue={data?.description} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ color: 'red' }}>*</span>Registration Date</td>
                        <td> Deregistration Date</td>
                        <td>Annual Rent</td>
                    </tr>
                    <tr>
                        <td><input name={'registrationDatetime'} style={{ width: '100%' }} type={'date'} defaultValue={data?.registrationDatetime} ref={register} /></td>
                        <td><input name={'deRegistrationDate'} style={{ width: '100%' }} type={'date'} defaultValue={data?.deRegistrationDate} readOnly={true} /></td>
                        <td><input style={{ width: '100%' }} name={'rent'} type={'number'} step={'.01'} ref={register} defaultValue={data?.rent} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ color: 'red' }}>*</span>Investment Purpose</td>
                        <td><span style={{ color: 'red' }}>*</span>Source Interest</td>
                        <td><span style={{ color: 'red' }}>*</span>Share</td>
                    </tr>
                    <tr>
                        <td>
                            <select style={{ width: '100%' }} name={'investmentPurpose'} defaultValue={data?.investmentPurpose} ref={register}>
                                <option>Select Purpose</option>
                                <option value={'Unkown'}>Unkown</option>
                                <option value={'Yes'}>Yes</option>
                                <option value={'No'}>No</option>
                            </select>
                        </td>
                        <td>
                            <select style={{ width: '100%' }} name={'sourceInterest'} defaultValue={data?.sourceInterest} onChange={e => purposeSet(e.target.value)} value={purpose} ref={register}>
                                <option value={'In Whole'}>In Whole</option>
                                <option value={'Partial'}>Partial</option>
                            </select>
                        </td>
                        <td>
                            <input style={{ width: '40%' }} name={'shareNumerator'} type={'number'} defaultValue={data?.shareNumerator} readOnly={!isPartial} ref={register({ required: isPartial })} /><span>/</span><input name={'shareDenominator'} defaultValue={data?.shareDenominator} style={{ width: '40%' }} type={'number'} readOnly={!isPartial} ref={register({ required: isPartial })} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <button className={'default'} style={{ width: '150px', float: 'right', marginTop: '1rem' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>
                            <button style={{ width: '150px', float: 'right', marginTop: '1rem' }} type={'submit'}>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    )
}
const Right = ({ submit, name, data = null }) => {
    const { handleSubmit, register } = useForm()
    const [term, termSet] = useState('')
    const [startDate, startDateSet] = useState('')
    const [isCustom, isCustomSet] = useState(false)
    const [endDate, endDateSet] = useState('')

    useEffect(() => {
        if (term !== '' && term !== 'custom' && startDate.trim() !== null) {
            isCustomSet(true)
            endDateSet(Moment(new Date(startDate)).add(term, 'years').subtract(1, "days").format('YYYY-MM-DD'))
        } else {
            isCustomSet(false)
        }
    }, [term, startDate])
    useEffect(() => {

        endDateSet(data?.endDate)
        console.log("The end date is ", data?.endDate)
        if (data !== null && data.tenureDurationName !== 'custom') {
            console.log("Data ", data)
            isCustomSet(false)
        }

    }, [])
    return (
        <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td><span style={{ color: 'red' }}>*</span> Tenancy Type</td>
                        <td><span style={{ color: 'red' }}>*</span>FD Number</td>
                    </tr>
                    <tr>
                        <td><input style={{ width: '100%' }} name={'rrrType'} type={'text'} readOnly={true} value={name} ref={register({ required: 'true' })} /></td>
                        <td>
                            <select name={'tenancyTypeName'} style={{ width: '100%' }} ref={register({ required: 'true' })} defaultValue={data?.tenancyTypeName.trim()}>
                                <option value={""} />
                                <option value={"Joint Occupancy"}>Joint Occupancy</option>
                                <option value={"Single Occupancy"}>Single Occupancy</option>
                                <option value={"Tenancy in Common in Equal Shares"}>Tenancy in Common in Equal Shares</option>
                                <option value={"Tenants in Common in Unequal Shares"}>Tenants in Common in Unequal Shares</option>
                            </select>
                        </td>
                        <td><input style={{ width: '100%' }} name={'fdNumber'} type={'text'} defaultValue={data?.fdNumber} ref={register} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ color: 'red' }}>*</span> Term</td>
                        <td><span style={{ color: 'red' }}>*</span> Commencement Date</td>
                        <td><span style={{ color: 'red' }}>*</span> End Date</td>
                    </tr>
                    <tr>
                        <td>
                            <select style={{ width: '100%' }} name={'tenureDurationName'} onChange={(e) => termSet(e.target.value)} ref={register({ required: 'true' })} defaultValue={data?.tenureDurationName}>
                                <option value={""}>Custom</option>
                                <option value={"custom"}>Custom</option>
                                <option value={"5"}>Five(5) Years</option>
                                <option value={"99"}>Nintey Nine (99) Years</option>
                                <option value={"66"}>Sixty Six (66) Years</option>
                                <option value={"33"}>Thirty Three (33) Years</option>
                            </select>
                        </td>
                        <td>
                            <input type={'date'} name={'startDate'} style={{ width: '100%' }} onChange={(e) => startDateSet(e.target.value)} defaultValue={data?.startDate} ref={register({ required: true })} />
                        </td>
                        <td>
                            <input type={'date'} name={'endDate'} style={{ width: '100%' }} readOnly={isCustom} onChange={e => { endDateSet(e.target.value) }} value={endDate} ref={register({ required: true })} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>Particulars</td>
                    </tr>
                    <tr>
                        <td colSpan={3}><textarea rows={3} name={'description'} style={{ width: '100%', resize: 'none' }} defaultValue={data?.description} ref={register} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ color: 'red' }}>*</span>Registration Date</td>
                        <td> Deregistration Date</td>
                        <td>Consideration</td>
                    </tr>
                    <tr>
                        <td><input name={'registrationDatetime'} style={{ width: '100%' }} type={'date'} defaultValue={data?.registrationDatetime} ref={register} /></td>
                        <td><input name={'deRegistrationDate'} style={{ width: '100%' }} type={'date'} defaultValue={data?.deRegistrationDate} readOnly={true} /></td>
                        <td><input style={{ width: '100%' }} name={'consideration'} type={'number'} step={'.01'} defaultValue={data?.consideration} ref={register} /></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <button className={'default'} style={{ width: '150px', float: 'right', marginTop: '1rem' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>
                            <button style={{ width: '150px', float: 'right', marginTop: '1rem' }} type={'submit'}>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    )
}
const Related = ({ refresh }) => {
    const [related, relatedSet] = useState([])
    const [selected, selectSet] = useState(-1)
    const [selectedIndex, selectedIndexSet] = useState({})
    const [interestId, interestIdSet] = useState(0)

    const [sData, sDataSet] = useState([])
    const [tData, tDataSet] = useState([])
    const [sName, sNameSet] = useState('')
    const [tName, tNameSet] = useState('')
    useEffect(() => {
        let txId = TransactionPopup.id()
        API.ax.get(`rt/related/${txId}`)
            .then(d => {
                let { result } = d.data.result
                console.log("Related ", result)
                relatedSet(d.data.result)
                //selectedIndexSet(d.data.result[0])
                //selectSet(0)
            })
    }, [])
    useEffect(() => {
        if (selected > -1) {
            console.log("selected ", selected)
            //sourceName, sourceData, sourceRead, targetName, targetData, targetRead,rrrId
            selectedIndexSet(related[selected])
        }
    }, [selected])
    useEffect(() => {
        if (selected > -1) {
            let { sourceData, sourceName, targetData, targetName, interest } = selectedIndex
            console.log("The selected Interest ", interest)
            interestIdSet(interest.interestId)
            sDataSet(sourceData)
            tDataSet(targetData)
            sNameSet(sourceName)
            tNameSet(targetName)
        }

    }, [selectedIndex])
    const onSubmit = () => {
        let txId = TransactionPopup.id()
        let dataToSend = { interestId, transactionId: txId }
        Wait.show("Adding Related Interest ...")
        API.ax.post("rt/related", dataToSend)
            .then(d => {
                Wait.dismiss()
                refresh(true)
                PopDetail.dismiss()
                console.log("add related ", d.data)
            })
            .catch(e => console.log(e))
    }
    return (
        <>
            <div style={{ ...styles.content, height: '240px' }}>
                <table className={'tb'} style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>FD NUMBER</th>
                            <th>INTEREST</th>
                            <th>TENANCY TYPE</th>
                            <th>TITLES</th>
                            <th>DOCUMENT</th>
                            <th>STATUS</th>
                            <th>IID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {related?.map((data, id) => {
                            return (
                                <tr key={id} style={selected === id ? { fontWeight: 'bold' } : {}} onClick={() => selectSet(id)}>
                                    <td>{data.interest.fdNumber}</td>
                                    <td>{data.interest.interest}</td>
                                    <td>{data.interest.tenancyType}</td>
                                    <td>{data.interest.titles}</td>
                                    <td>{data.interest.document}</td>
                                    <td>{data.interest.status}</td>
                                    <td>{data.interest.iid}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {selected > -1 && <SourceTarget sData={sData} sHeading={sName} sRead={false} tData={tData} tHeading={tName} tRead={false} />}
            {selected > -1 && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button style={{ width: '150px', float: 'right', marginTop: '1rem' }} onClick={onSubmit}>Save</button>
                <button className={'default'} style={{ width: '150px', float: 'right', marginTop: '1rem' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>

            </div>}
        </>
    )
}
export default function ScreenInterest({ writable }) {

    const [addInterest, addInterestSet] = useState(false)
    const [titleDatum, titleDataSet] = useState({})
    const [selectedInt, selectedIntSet] = useState(-1)

    const [interestZ, interestsSet] = useState([])
    const [interestSele, interestSeleSet] = useState({})

    const [interestNme, interestNmeSet] = useState()
    const [sData, sDataSet] = useState([])
    const [tData, tDataSet] = useState([])
    const [sName, sNameSet] = useState('')
    const [sRead, sReadSet] = useState('')
    const [tName, tNameSet] = useState('')
    const [tRead, tReadSet] = useState('')
    const [sShares, sSharesSet] = useState('')
    const [tShares, tSharesSet] = useState('')
    const [sPartyRole, sPartyRoleSet] = useState([])
    const [tPartyRole, tPartyRoleSet] = useState([])
    const [interestId, interestIdSet] = useState()

    const refresh = data => {
        if (data === true) {
            let txId = TransactionPopup.id();
            Wait.show("Load Interests ...")
            API.ax.get(`rt/interest/${txId}`)
                .then(d => {
                    Wait.dismiss()
                    selectedIntSet(-1)
                    console.log("the new interests", d.data)
                    const { interestName, interests, multiInterests, titleData } = d.data.result
                    interestNmeSet(interestName)
                    addInterestSet(multiInterests)
                    titleDataSet(titleData)
                    interestsSet(interests);
                })
                .catch(e => console.log(e))
        }
    }
    const editRequest = () => {
        Wait.show("Getting the Interest ...")
        let { interest } = interestSele
        console.log("the interest ", interestSele)
        API.ax.get(`rt/update-interest/${TransactionPopup.id()}/${interest.interestId}`)
            .then(d => {
                let { result, status } = d.data
                if (status === 1) {
                    console.log("The data is ", result)
                    Wait.dismiss()
                    PopDetail.show("Edit Interest", <Encumbrance rrrType={result.rrrType} data={result} refresh={refresh} />)
                }
            })
            .catch(e => {
                console.log(e)
                Wait.dismiss()
            })
    }
    useEffect(() => {
        let txId = TransactionPopup.id();
        API.ax.get(`rt/interest/${txId}`)
            .then(d => {
                console.log("the new interests", d.data)
                const { interestName, interests, multiInterests, titleData } = d.data.result
                interestNmeSet(interestName)
                addInterestSet(multiInterests)
                titleDataSet(titleData)
                interestsSet(interests);
            })
            .catch(e => console.log(e))

    }, [])
    useEffect(() => {
        if (selectedInt > -1) {
            //const{source,sourceData,target,targetData} =
            interestSeleSet(interestZ[selectedInt])

        }
    }, [selectedInt])
    useEffect(() => {
        if (selectedInt > -1) {
            const { source, sourceData, target, targetData, interest } = interestSele
            console.log("the selected data is", interestSele)
            interestIdSet(interest.interestId)

            sDataSet(sourceData)
            sReadSet(source.isRead)
            sNameSet(source.name)
            sSharesSet(source.shares)
            sPartyRoleSet(source.partyRole)

            tDataSet(targetData)
            tReadSet(target.isRead)
            tNameSet(target.name)
            tSharesSet(target.shares)
            tPartyRoleSet(target.partyRole)
        }
    }, [interestSele])

    const deleteInterest = () => {
        Wait.show("Removing Interest ...")
        let { interest } = interestZ[selectedInt]
        let dataToSend = { interestId: interest.interestId, transactionId: TransactionPopup.id() }
        API.ax.post("rt/delete-interest", dataToSend)
            .then(d => {
                Wait.dismiss()
                refresh(true)

            })
    }
    return (
        <div style={{ ...styles.main, display: 'flex', flexDirection: 'column' }}>
            <Header display={writable} label={'Interests'}>
                {
                    TransactionPopup.transaction().name === "Legal Personal Representative of Right of Occupancy" ||
                        TransactionPopup.transaction().name === "Assent to Bequest of Right of Occupancy" ||
                        TransactionPopup.transaction().name === "Withdraw of Deposit" ||
                        TransactionPopup.transaction().name === "Grant Leasehold" ?
                        ""
                        : <div>
                            <i
                                className={'fa fa-plus-circle'}
                                style={{ color: 'tomato', fontSize: '25px' }}
                                onClick={(!interestZ.length > 0 || addInterest) ?
                                    () => { interestNme === "any" ? PopDetail.show("New Interest", <Any refresh={refresh} />, 60) : PopDetail.show("New Interest", <Encumbrance rrrType={interestNme} refresh={refresh} />) }
                                    : () => { }}
                            /><strong> New</strong>
                        </div>
                }
                {
                    TransactionPopup.transaction().name === "Notice of Deposit" ||
                        TransactionPopup.transaction().name === "Caveat"
                        ? ""
                        :
                        <div>
                            <i
                                className={'fa fa-plus-circle'}
                                style={{ color: 'tomato', fontSize: '25px' }}
                                onClick={() => { PopDetail.show("Related Interests", <Related refresh={refresh} />, 80) }}
                            /><strong> Add Related</strong>
                        </div>
                }
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={selectedInt > -1 ? () => { editRequest() } : () => { }}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={selectedInt > -1 ? () => { deleteInterest() } : () => { }}
                    /><strong> Remove</strong>
                </div>
            </Header>
            <div style={styles.content}>
                <table className={'tb'} style={styles.table}>
                    <thead>
                        <tr>
                            <th>INTEREST</th>
                            <th>TENANCY TYPE</th>
                            <th>TERM</th>
                            <th>COMMENCEMENT DATE</th>
                            <th>END DATE</th>
                            <th>DOCUMENT</th>
                            <th>TITLES</th>
                            <th>STATUS</th>
                            <th>FD NUMBER</th>
                            <th>IID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            interestZ?.map((data, id) => <tr key={id} onClick={() => selectedIntSet(id)} style={selectedInt === id ? { fontWeight: 'bold' } : {}}>
                                <td>{data.interest.interest}</td>
                                <td>{data.interest.tenancyType}</td>
                                <td>{data.interest.term}</td>
                                <td>{data.interest.startDate}</td>
                                <td>{data.interest.endDate}</td>
                                <td>{data.interest.document}</td>
                                <td>{data.interest.titles}</td>
                                <td>{data.interest.status}</td>
                                <td>{data.interest.fdNumber}</td>
                                <td>{data.interest.iid}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
            {selectedInt > -1 && <SourceTarget interestId={interestId} tRead={tRead} tHeading={tName} tData={tData} sRead={sRead} sHeading={sName} refresh={refresh}
                sData={sData} writable={writable} sShares={sShares} tShares={tShares} sPartyRole={sPartyRole} tPartyRole={tPartyRole} />}
            <div style={styles.content}>
                <Header label={"Property/Title"} />
                <table style={{ width: "100%" }} className={'tb'}>
                    <thead>
                        <tr>
                            <th>TITLE NUMBER</th>
                            <th>TYPE</th>
                            <th>TENURE</th>
                            <th>LD NUMBER</th>
                            <th>TOTAL AREA</th>
                            <th>LAND RENT</th>
                            <th>TOTAL VALUE</th>
                            <th>STATUS</th>
                            <th>REGISTRATION NUMBER</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{titleDatum.titleNumber}</td>
                            <td>{titleDatum.type}</td>
                            <td>{titleDatum.tenure}</td>
                            <td>{titleDatum.ldNumber}</td>
                            <td>{titleDatum.totalArea}</td>
                            <td>{titleDatum.landRent}</td>
                            <td>{titleDatum.totalValue}</td>
                            <td>{titleDatum.status}</td>
                            <td>{titleDatum.registrationNumber}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )

}

const options = [
    {
        value: "Caveat",
        label: 'Caveat'
    },
    {
        value: "Charge",
        label: 'Charge'
    },
    {
        value: "Deeds",
        label: 'Deeds'
    },
    {
        value: "Deposit",
        label: 'Deposit'
    },
    {
        value: "Injunction",
        label: 'Injunction'
    },
    {
        value: "Lease",
        label: 'Lease'
    },
    {
        value: "Mortgage",
        label: 'Mortgage'
    },
    {
        value: "Power of Attorney",
        label: 'Power of Attorney'
    },
    {
        value: "Sublease",
        label: 'Sublease'
    },
]
const style = {
    columns: { display: "flex", flexDirection: "column", width: "100%" }
}