import API from "../API";

export default class ValCreateService{
    static uploadValuationBrock(formData) {
        return API.ax.post('valuation/add-valuation-blocks',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static uploadValuationCSVRates(formData) {
        return API.ax.post('valuation/upload-csv-valuation-block-rates',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static uploadCompValuationCSVRates(formData) {
        return API.ax.post('valuation/compensation-rates',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static approveBlock(data) {
        return API.ax.post('valuation/approve-rates',data).catch(e=>{console.log(e);})
    }

    static savePVUAcceptance(data) {
        return API.ax.post("valuation/pvu-approval",data).catch(e=>{console.log(e);})
    }


    static updateVblockRates(data) {
        return API.ax.post('valuation/correct-land-values',data,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }
    static updateTpValuationMapper(data) {
        return API.ax.post('valuation/add-record',data,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

}
