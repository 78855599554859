import React, {Component} from 'react';
import TxCreateService from "../../../../../services/transaction/transaction_create";
import TxReadService from "../../../../../services/transaction/transaction_read";
import TransactionPopup from "../../transaction_popup";
import Wait from "../../../wait";
import Message from "../gro/person/message";
import PopDetail from "../../../popdetail";
import wait from "../../../wait";

class RectificationParameter extends Component {
    constructor(props) {
        super(props);
        this.state={
            type:'Rectification',
            name:'',
            details:'',

        }
        this.saveParameter =this.saveParameter.bind(this)
    }
componentDidMount() {

        TxReadService.getRectificationParameter(TransactionPopup.id()).then(d=>{
            console.log("################################")
            console.log({d})
            if(d.data.status===1){
                this.setState({
                    type:d.data.result.parameterCode,name:d.data.result.parameterValue,details:d.data.result.details
                })

            }
        })
}

    handleChange=(event)=> {
        this.setState({ [event.target.name]: event.target.value });
        console.log("***********************************************")
        console.log(event.target.name,event.target.value )
    }

    saveParameter=()=>{
        let datam={
            parameterCode:this.state.type,
            parameterValue:this.state.name,
            details:this.state.details,
            transactionId: TransactionPopup.id()
        }
        if(this.state.name===''){
           console.log("NOOOOOOOOOOOOOO parameter")

        }else
        {

            Wait.show("", "Saving....")
            console.log("Rectification Parameters", datam)
            TxCreateService.saveRectificationParameter(datam).then(d => {

                if (d.data.status === 1) {
                    this.setState({
                        type: d.data.result.parameterCode,
                        name: d.data.result.parameterValue,
                        details: d.data.result.details
                    })

                    Wait.dismiss()
                }
            })
        }
    }
    render() {
        return (
            <div>
                <div>Rectification Type:</div>
                    <div><select value={this.state.name} name={'name'}  onChange={this.handleChange}>
                        <option>--</option>
                        <option value="Archive Title">Archive Whole Title</option>
                        <option value="Update Title">Update Title</option>
                    </select></div>
                    <div>Transaction Details:</div>
                    <div><textarea name={'details'}
                        value={this.state.details}
                        onChange={this.handleChange}
                    /></div>
                <div><button onClick={this.saveParameter}>Save</button></div>
            </div>
        );
    }
}

export default RectificationParameter;
