import * as React from "react";
export default class PaneInfo extends React.Component{
    render() {
        return(<div className={'col col-sm-12'}>
            <table className={'tb'} style={{width:'100%'}}>
                <tbody>
                {this.props.data.map((d,i)=>{
                    return(<tr key={i}>
                        <td><b>{d.split(':')[0]} :</b></td>
                        <td>{d.split(':')[1]}</td>
                    </tr>)
                })}
                </tbody>
            </table>
        </div>)
    }
}