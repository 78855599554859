import * as React from "react";
import logo from '../../../resource/img/aa.gif';

export default class Wait extends React.Component {

    static self = null;
    constructor(props) {
        super(props);

        this.state = {
            title: 'Loading...',
            content: 'Loading...'
        }
        Wait.self = this;

    }

    static show(title, content = '') {
        Wait.self.setState({
            title: title,
            content: content
        });
        document.getElementById('div-wait').style.display = 'inherit';
    }

    static dismiss() { document.getElementById('div-wait').style.display = 'none'; }

    render() {
        return (<div style={{ position: 'absolute', zIndex: 2000, top: 0, bottom: 0, left: 0, right: 0, display: 'none', backdropFilter: "blur(5px)" }} id={'div-wait'}>
            <div style={{ height: '34vh' }} />
            <div style={{ textAlign: 'center', padding: '24px 58px', borderRadius: '4px', display: 'table', margin: 'auto', }}>
                <img src={logo} style={{ width: '54px' }} alt={'loading'} />
                <p>{this.state.title}</p>
            </div>
        </div>)
    }
}
