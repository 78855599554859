import React from "react";

const initialState ={
    roof:'',
    accommodationRoomQty:'',
    ceiling:'',
    commercialRoomQty:'',
    walls:'',
    condition:'',
    floors:'',
    doors:'',
    windows:'',
    photoUrl:'',
    other:'',

    number:0,
    area:0,
    unit:'sqm',
    constructionRate:0,
    reducedValue:0,
    reducingFactor:0,
    depreciationPercentage:0,
    reducedArea:0,
    deprecatedValue:0,
    incompletenessPercentage:0,
    comments:''
}
const OutBuildingStateContext = React.createContext()
const OutBuildingDispatchContext = React.createContext()
function outBuildingReducer(state, action) {
    switch (action.type) {
        case 'fields': {
            return {
                ...state,
                [action.field] : action.payload
            }
        }default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function OutBuildingProvider({children}) {
    const [state, dispatch] = React.useReducer(outBuildingReducer, initialState)
    return (
        <OutBuildingStateContext.Provider value={state}>
            <OutBuildingDispatchContext.Provider value={dispatch}>
                {children}
            </OutBuildingDispatchContext.Provider>
        </OutBuildingStateContext.Provider>
    )
}
function useOutBuildingState() {
    const context = React.useContext(OutBuildingStateContext)
    if (context === undefined) {
        throw new Error('useBuildingState must be used within a BuildingProvider')
    }
    return context
}
function useOutBuildingDispatch() {
    const context = React.useContext(OutBuildingDispatchContext)
    if (context === undefined) {
        throw new Error('useBuildingDispatch must be used within a BuildingProvider')
    }
    return context
}
export {OutBuildingProvider, useOutBuildingState, useOutBuildingDispatch}