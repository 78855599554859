import React, { useState, useEffect } from 'react'
import Header from "./gro/header";
import PopDetail from "../../popdetail";
import { useForm } from "react-hook-form";
import API from "../../../../services/API";
import { styles } from "./record-request";
import TransactionPopup from "../transaction_popup";
import Wait from "../../wait";
import { parseZip } from "shpjs";
import L from "leaflet";
import "../../../../utils/BoundaryCanvas";
import data from "../../../../utils/data.json";
import Message from "./gro/person/message";

const expectedObject = {
    Cal_area: "",
    District: "",
    council: "",
    Region: "",
    id: "",
    locality: "",
    Val_no: "",
    Unit: "",
    coordinates: ""
};
const compareKeys = (a, b) => {
    let aKeys = Object.keys(a).sort();
    let bKeys = Object.keys(b).sort();
    return JSON.stringify(aKeys) === JSON.stringify(bKeys);
};

const Unsurveyed = () => {
    const [arrayB, arrayBSet] = useState();
    const [propertiesArray, propertiesArraySet] = useState([]);
    const [currentData, currentDataSet] = useState({});
    const [featureSelected, featureSelectedSet] = useState(false);
    const [submit, submitSet] = useState(false);
    const [jsonDecode, jsonDecodeSet] = useState();

    const addProperties = (data) => {
        let { features } = data;
        features.forEach((feature) => {
            if (feature.geometry.type === "Polygon") {
                let { properties, geometry } = feature;

                properties = { ...properties, coordinates: JSON.stringify(geometry) };
                if (compareKeys(expectedObject, properties)) {
                    let { Cal_area: calArea, District: district, Region: region, locality, Unit: unit, Val_no: valNo, coordinates } = properties
                    let newData = propertiesArray;
                    newData.push({ calArea, district, region, locality, unit, valNo, transactionId: TransactionPopup.id(), coordinates });
                    //console.log("the new array", newData)
                    propertiesArraySet(newData);
                }
            }
        });
        if (features.length === propertiesArray.length) {
            submitSet(true);
        }
    };

    const handleSubmit = () => {
        console.log("the values in an array ", propertiesArray);
        Wait.show("Saving...")
        API.ax.post("spatial-unit-vu/unsurveyed-spatials", propertiesArray)
            .then(d => {
                console.log("response", d)
                Wait.dismiss()
                PopDetail.dismiss()
            })
            .catch(e => {
                Wait.dismiss()
                PopDetail.dismiss()
            })
    };
    const handleChange = (data) => {
        arrayBSet(undefined);
        let file = data.target.files[0];
        let reader = new FileReader();

        reader.onloadend = (event) => {
            arrayBSet(event.target.result);
        };

        reader.onError = () => {
            console.error(reader.error);
        };
        if (file !== undefined) {
            reader.readAsArrayBuffer(file);
        }
    };
    useEffect(() => {
        if (Object.keys(currentData).length !== 0) {
            featureSelectedSet(true);
        }
    }, [currentData]);

    useEffect(() => {
        if (arrayB != undefined) {
            parseZip(arrayB).then(data2 => {
                console.log("the data ", data2)
                jsonDecodeSet(data2);
            }).catch(err => console.error("error ", err));
        }
    }, [arrayB])

    useEffect(() => {
        if (jsonDecode !== undefined) {
            document.getElementById("mapContainer").innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
            propertiesArraySet([])

            console.log("json ", jsonDecode)
            async function fetchData(data) {
                await addProperties(data)
            }
            fetchData(jsonDecode).then(r => console.log("promise log ", r))
            let m = L.map("map", {
                drawControl: true,
                zoomControl: true,
                fullscreenControl: true,
                attributionControl: false,
                minZoom: 6,
                maxBounds: [
                    29.592134682000076,
                    -11.761760639999975,
                    40.44473482300003,
                    -0.9831430169999429
                ],
                maxBoundsViscosity: 1.0
            });
            //"https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.{ext}",subdomains: "abcd"
            const osm = new L.TileLayer.BoundaryCanvas(
                "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
                {
                    boundary: data,
                    attribution:
                        'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    ext: "jpg"
                }
            ).addTo(m);
            let currentLayer;
            let shpfile = new L.GeoJSON(jsonDecode, {
                onEachFeature: function (feature, layer) {

                    layer.on("click", (e) => {
                        if (currentLayer) {
                            shpfile.resetStyle(currentLayer);
                        }
                        currentLayer = layer;
                        let { properties, geometry } = feature;
                        properties = { ...properties, coordinates: geometry.coordinates };
                        if (feature.geometry.type === "Polygon") {
                            if (compareKeys(expectedObject, properties)) {
                                currentLayer.setStyle({ color: "green", weight: 4 });
                                properties = { ...properties, status: "Correct" };
                            } else {
                                console.log("properties did not", properties);
                                currentLayer.setStyle({ color: "yellow", weight: 4 });
                                properties = { ...properties, status: "Attributes Not Match" };
                            }
                        } else {
                            currentLayer.setStyle({ color: "red", weight: 4 });
                            properties = {
                                ...properties,
                                status: "It is a " + feature.geometry.type
                            };
                        }
                        currentDataSet(properties);
                        currentLayer.bringToFront();
                    });
                }
            }).addTo(m);
            m.setView(shpfile.getBounds().getCenter(), 12);
            console.log("the bbx is", shpfile.getBounds())

            if (jsonDecode.features.length === propertiesArray.length) {
                submitSet(true);
            }
        }
    }, [jsonDecode]);

    return (
        <>
            <input type={"file"} name={"file"} onChange={handleChange} />
            <div id={"mapContainer"} style={{ height: "60vh", width: "70vw" }} />
            {featureSelected && (
                <table className={"tb"} style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Valuation No.</th>
                            <th>Area</th>
                            <th>Unit</th>
                            <th>District</th>
                            <th>Region</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{currentData.Val_no}</td>
                            <td>{currentData.Cal_area}</td>
                            <td>{currentData.Unit}</td>
                            <td>{currentData.District}</td>
                            <td>{currentData.Region}</td>
                            <td>{currentData.status}</td>
                        </tr>
                    </tbody>
                </table>
            )}
            {submit && <button style={{ display: "block", float: "right", marginRight: "10px", marginTop: "5px" }} onClick={handleSubmit}>Save</button>}
        </>
    );
}
const SearchResult = ({ results, selected }) => {
    const [select, selectedSet] = useState(0)
    return (
        <table className={"tb"} style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th>UPID</th>
                    <th>TYPE</th>
                    <th>ALLOCATION STATUS</th>
                    <th>DISTRICT</th>
                    <th>LOCATION</th>
                    <th>BLOCK</th>
                    <th>NUMBER</th>
                    <th>REG PLAN NO.</th>
                    <th>LAND USE</th>
                    <th>AREA</th>
                </tr>
            </thead>
            <tbody>
                {
                    results.map(({ blockNumber, allocationStatusName, districtName, landUseName, localityName, lotNumber, regPlanNumber, spatialUnitId, legalArea, lotType }) =>
                        <tr key={spatialUnitId} onClick={() => { selectedSet(spatialUnitId); selected(spatialUnitId) }} style={select === spatialUnitId ? styles.highlight : styles.unHighlight}>
                            <td>{spatialUnitId}</td>
                            <td>{lotType}</td>
                            <td>{allocationStatusName}</td>
                            <td>{districtName}</td>
                            <td>{localityName}</td>
                            <td>{blockNumber}</td>
                            <td>{lotNumber}</td>
                            <td>{regPlanNumber}</td>
                            <td>{landUseName}</td>
                            <td>{legalArea}</td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
}
const Search = ({ newSpatials }) => {
    const { register, handleSubmit } = useForm()
    const [results, resultsSet] = useState([])
    const [show, showSet] = useState(false)
    const [showAdd, showAddSet] = useState(false)
    const [forSpatialDetail, forSpatialDetailSet] = useState({})
    const [currentComp, currentCompSet] = useState("")
    const selectedSpatial = data => {
        //add the sp to the tx
        console.log("the selected Id is ", data)
        const dataToSend = {
            spatialUnitId: data,
            transactionId: TransactionPopup.id()
        }
        forSpatialDetailSet(dataToSend)
    }
    const handleAdd = () => {
        Wait.show("Add Spatial ...")
        API.ax.post("/transaction/spatial-details", forSpatialDetail)
            .then(d => {
                if (d.data.status === 1) {
                    newSpatials(d.data.result)
                }
                Wait.dismiss()
                PopDetail.dismiss()
            })
            .catch(e => { console.error(e) })
    }
    useEffect(() => {
        if (forSpatialDetail.hasOwnProperty("transactionId")) {
            showAddSet(true)
        }
    }, [forSpatialDetail])
    useEffect(() => {
        if (results.length > 0) {
            currentCompSet(<SearchResult results={results} selected={selectedSpatial} />)
            showSet(true)
        }
    }, [results])
    const submit = data => {
        //send data
        showAddSet(false)
        console.log("data", data)
        Wait.show("Search Spatial ...")
        API.ax.post("land/get-spatial", data)
            .then(d => {
                console.log(d.data)
                if (d.data.status === 1) {
                    resultsSet(d.data.result)
                }
                Wait.dismiss()
            })
            .catch(e => { console.log("the error", e) })
    }
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <table style={{ width: "97%", margin: ".2rem" }}>
                    <tbody>
                        <tr>
                            <td ><span style={{ ...style.label }}>Reg. Plan No.</span></td>
                            <td><span style={{ ...style.label }}>Allocation Status</span></td>
                            <td><span style={{ ...style.label }}>Region</span></td>
                            <td><span style={{ ...style.label }}>District</span></td>
                        </tr>
                        <tr>
                            <td><input type={"text"} name={"regPlanNumber"} style={{ ...style.inputHeight }} ref={register} /></td>
                            <td><input type={"text"} name={"allocationStatusName"} style={{ ...style.inputHeight }} ref={register} /></td>
                            <td><input type={"text"} name={"regionName"} style={{ ...style.inputHeight }} ref={register} /></td>
                            <td><input type={"text"} name={"districtName"} style={{ ...style.inputHeight }} ref={register} /></td>
                        </tr>
                        <tr>
                            <td><span style={{ ...style.label }}>Location</span></td>
                            <td><span style={{ ...style.label }}>Block</span></td>
                            <td><span style={{ ...style.label }}>Number</span></td>
                            <td><span style={{ ...style.label }}>Drawing Number</span></td>
                        </tr>
                        <tr>
                            <td><input type={"text"} name={"localityName"} style={{ ...style.inputHeight }} ref={register} /></td>
                            <td><input type={"text"} name={"blockNumber"} style={{ ...style.inputHeight }} ref={register} /></td>
                            <td><input type={"text"} name={"lotNumber"} style={{ ...style.inputHeight }} ref={register} /></td>
                            <td><input type={"text"} name={"drawingNumber"} style={{ ...style.inputHeight }} ref={register} /></td>
                        </tr>
                        <tr>
                            <td colSpan={2}><span style={{ ...style.label }}>Plan Number</span></td>
                            <td colSpan={2}><span style={{ ...style.label }}>Use Type</span></td>
                        </tr>
                        <tr>
                            <td colSpan={2}><input type={"text"} name={"planNumber"} style={{ ...style.inputHeight }} ref={register} /></td>
                            <td colSpan={2}><input type={"text"} name={"landUseName"} style={{ ...style.inputHeight }} ref={register} /></td>
                        </tr>
                        <input name={'transactionId'} type={"text"} value={TransactionPopup.id()} hidden={true} ref={register} />
                        <tr><td colSpan={4}><button type={"submit"} style={{ float: 'right', width: '100px', marginTop: '1rem', marginBottom: '.2rem' }}>Search</button></td></tr>
                    </tbody>
                </table>

            </form>
            {
                show ? <>
                    <div style={{ width: '1051px', height: '300px', padding: '.2rem', margin: '.2rem', overflow: 'auto', border: '1px solid #888' }}>
                        {currentComp}
                    </div>
                    <div style={{ width: '1052px' }}>
                        <button className={'default'} style={{ float: 'right', width: '100px' }} onClick={() => { PopDetail.dismiss() }}>Cancel</button>
                        {showAdd ? <button style={{ float: 'right', width: '100px' }} onClick={handleAdd}>Add</button> : ''}
                    </div></> : ''
            }
        </>
    )
}
const SpatialDetails = ({ spatial }) => {
    const [selected, selectedSet] = useState(1)
    return (
        <>
            <div className={"row"} style={{ marginLeft: '.2rem', marginTop: '.2rem', marginRight: '.2rem' }}>
                <div className={"col col-sm-2"} style={style.label}>Type</div>
                <div className={"col col-sm-10"}><input type={"text"} style={style.inputHeight} readOnly={true} defaultValue={spatial ? spatial.lotType : ''} /></div>
            </div>
            <div className={"row"} style={{ borderBottom: '1px solid #888', gap: '1rem', marginLeft: '.3rem', marginTop: '.2rem', marginRight: '.2rem' }}>
                <div style={selected === 1 ? { ...style.selected, ...style.tab, marginLeft: '.2rem', fontWeight: 'bold' } : { ...style.tab, marginLeft: '.2rem' }} onClick={() => selectedSet(1)}>General</div>
                <div style={selected === 2 ? { ...style.selected, ...style.tab, marginLeft: '.2rem', fontWeight: 'bold' } : { ...style.tab, marginLeft: '.2rem' }} onClick={() => selectedSet(2)}>Legacy</div>
                <div style={selected === 10 ? { ...style.selected, ...style.tab, marginLeft: '.2rem', fontWeight: 'bold' } : { ...style.tab, marginLeft: '.2rem' }}>Parcel Neighbors</div>
                <div style={selected === 4 ? { ...style.selected, ...style.tab, marginLeft: '.2rem', fontWeight: 'bold' } : { ...style.tab, marginLeft: '.2rem' }} onClick={() => selectedSet(4)}>Previous Valuations</div>
            </div>
            <div className={"row"} style={selected === 1 ? { ...style.tabBody, marginRight: '.2rem' } : { display: 'none' }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td><span style={style.label}>Region</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.regionName : ''} /></td>
                            <td><span style={style.label}>District</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.districtName : ''} /></td>
                            <td><span style={style.label}>Location</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.localityName : ''} /></td>
                        </tr>
                        <tr>
                            <td><span style={style.label}>Block</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.blockNumber : ''} /></td>
                            <td><span style={style.label}>Number</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.lotNumber : ''} /></td>
                            <td><span style={style.label}>UPID</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.spatialUnitId : ''} /></td>
                        </tr>
                        <tr>
                            <td><span style={style.label}>Ward</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} /></td>
                            <td><span style={style.label}>Sub-ward</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} /></td>
                            <td><span style={style.label}>Reg. Plan No</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.spatialUnitId : ''} /></td>
                        </tr>
                        <tr>
                            <td><span style={{ color: 'red' }}>*</span><span>Survey Area</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.legalArea + ' ' + spatial.areaUnitName : ''} /></td>
                            <td><span style={style.label}>GIS Area</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} /></td>
                        </tr>
                        <tr>
                            <td><span style={style.label}>Address</span></td>
                            <td colSpan={3}><input type={"text"} style={{ width: '100%', ...style.inputHeight }} readOnly={true} /></td>
                            <td><span style={style.label}>Plan Name/Number</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.planNumber : ''} /></td>
                        </tr>
                        <tr>
                            <td><span style={style.label}>Use Type</span></td>
                            <td colSpan={3}><input type={"text"} style={{ width: '100%', ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.landUseName : ''} /></td>
                            <td><span style={style.label}>TP Drawing No</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.tpDrawingNumber : ''} /></td>
                        </tr>
                        <tr>
                            <td><span style={style.label}>Group/Class</span></td>
                            <td colSpan={3}><input type={"text"} style={{ width: '100%', ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.groupClass : ''} /></td>
                        </tr>
                        <tr>
                            <td><span style={style.label}>Notes</span></td>
                            <td colSpan={3}><textarea rows={4} style={{ resize: 'none', width: '100%' }} readOnly={true} defaultValue={spatial ? spatial.notes : ''} /></td>
                        </tr>
                        <tr>
                            <td><span style={style.label}>Allocation Status</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.allocationStatusName : ''} /></td>
                            <td><span style={style.label}>Status</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} defaultValue={spatial ? spatial.status : ''} /></td>
                            <td><span style={style.label}>Version</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={"row"} style={selected === 2 ? { ...style.tabBody, marginRight: '.2rem' } : { display: 'none' }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td><span style={style.label}>Legacy Title Location</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} /></td>
                            <td><span style={style.label}>Legacy Survey Location</span></td>
                            <td><input type={"text"} style={{ ...style.inputHeight }} readOnly={true} /></td>
                            <td><span style={style.label}>Legacy Landuse Name</span></td>
                            <td><input type={"type"} style={{ ...style.inputHeight }} readOnly={true} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={""} style={selected === 4 ? { ...style.tabBody, marginRight: '.2rem' } : { display: 'none' }}>
                <label style={{ marginLeft: '.3rem', fontSize: '25px' }}>Valuations</label>
                <table className={"tb"}>
                    <thead>
                        <tr>
                            <th>VID</th>
                            <th>PUROSE OF VALUATION</th>
                            <th>PROPERTY CATEGORY</th>
                            <th>LAND VALUE</th>
                            <th>TOTAL LAND AREA</th>
                            <th>TOTAL CURRENT MARKET VALUE</th>
                            <th>PARCELS</th>
                            <th>RIGHT HOLDERS</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </>
    )
}
const EditSpatial = ({ refresh, spatialUnit }) => {
    const { register, handleSubmit } = useForm()
    const [done, doneSet] = useState(false)
    const [spUnit, spUnitSet] = useState({
        spatialUnitId: 0,
        planNumber: '',
        drawingNumber: '',
        regPlanNumber: '',
        legalArea: '',
        areaUnitName: '',
        lotNumber: '',
        lotType: '',
        blockNumber: '',
        localityName: '',
        districtName: '',
        regionName: '',
        txId: 0
    })

    useEffect(() => {
        Wait.show("Load Spatial ...")
        API.ax.get(`transaction/update-legacy-spUnit/${spatialUnit.spatialUnitId}`)
            .then(d => {
                console.log("Data ", d.data)
                const { result, status } = d.data
                if (status === 1) {
                    spUnitSet(result);
                    doneSet(true)
                }
                Wait.dismiss()
            })
            .catch(e => {
                console.log(e)
                Wait.dismiss()
            })
    }, [])
    let submit = data => {
        let txId = TransactionPopup.id()
        let dataToSend = { spatialUnitId: spatialUnit.spatialUnitId, ...data, txId }
        console.log("submitted ", dataToSend)
        API.ax.post('transaction/update-legacy-spUnit', dataToSend)
            .then(d => {
                const { status, message } = d.data
                if (status === 1) {
                    refresh(true)
                    PopDetail.dismiss()
                }
                else {
                    PopDetail.show("Error", <Message message={message} faIcon={'harzad'} />)
                }

            })
            .catch(e => {
                PopDetail.show("Error", <Message message={"There was an Error while processing the request"} faIcon={'harzad'} />, 40)
            })
    }
    return (
        done && <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td><span style={{ fontWeight: 'bold' }}>Plan Number</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Drawing Number</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Registration Number</span></td>
                    </tr>
                    <tr>
                        <td><input defaultValue={spUnit.planNumber} name={'planNumber'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><input defaultValue={spUnit.drawingNumber} name={'drawingNumber'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><input defaultValue={spUnit.regPlanNumber} name={'regPlanNumber'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ fontWeight: 'bold' }}>Lot Number</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Lot Type</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Block Number</span></td>
                    </tr>
                    <tr>
                        <td><input defaultValue={spUnit.lotNumber} name={'lotNumber'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><select defaultValue={spUnit.lotType} name={'lotType'} style={style.td} ref={register({ required: true })}>
                            <option value={'Plot'}>Plot</option>
                            <option value={'Farm'}>Farm</option>
                        </select></td>
                        <td><input defaultValue={spUnit.blockNumber} name={'blockNumber'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ fontWeight: 'bold' }}>Locality Name</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>District Name</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Region Name</span></td>
                    </tr>
                    <tr>
                        <td><input defaultValue={spUnit.localityName} name={'localityName'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><input defaultValue={spUnit.districtName} name={'districtName'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><input defaultValue={spUnit.regionName} name={'regionName'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ fontWeight: 'bold' }}>Legal Area</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Area Unit Name</span></td>
                    </tr>
                    <tr>
                        <td><input defaultValue={spUnit.legalArea} name={'legalArea'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td>
                            <select defaultValue={spUnit.areaUnitName} name={'areaUnitName'} style={style.td} ref={register({ required: true })}>
                                <option value={'ACRES'}>Acres</option>
                                <option value={'ARES'}>Ares</option>
                                <option value={'HA'}>Hectares</option>
                                <option value={'SQF'}>Square Feet</option>
                                <option value={'SQM'}>Square Meters</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <br /><button onClick={PopDetail.dismiss} style={{ float: 'right', width: '150px' }} className={"default"}>Cancel</button>
                            <button type={'submit'} style={{ float: 'right', width: '150px' }}>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    )
}
const EditTPSpatial = ({ refresh = null, spatialUnit }) => {
    const { register, handleSubmit } = useForm()
    const [done, doneSet] = useState(false)
    const [tpUnit, tpUnitSet] = useState({
        tpSpunitId: 0,
        landUseType: '',
        useClass: '',
        useGroup: '',
        tpDrawingNumber: '',
        districtName: '',
        regionName: '',
        txId: 0,
        spUnitId: 0
    })
    //update-legacy-tpUnit
    useEffect(() => {
        Wait.show("Load Tp Data ...")
        API.ax.get(`transaction/update-legacy-tpUnit/${spatialUnit.spatialUnitId}`)
            .then(d => {
                console.log("Data ", d.data)
                const { result, status } = d.data
                if (status === 1) {
                    tpUnitSet(result);
                    doneSet(true)
                }
                Wait.dismiss()
            })
            .catch(e => {
                console.log(e)
                Wait.dismiss()
            })
    }, [])
    const submit = data => {
        let txId = TransactionPopup.id();
        let tpSpunitId = tpUnit.tpSpunitId;
        let spUnitId = tpUnit.spUnitId;
        let dataToSend = { ...data, txId, tpSpunitId, spUnitId }
        console.log("data", dataToSend)
        Wait.show("Updating ...")
        API.ax.post("transaction/update-legacy-tpUnit", dataToSend)
            .then(d => {
                const { status, message } = d.data
                if (status === 1) {
                    refresh(true);
                    Wait.dismiss()
                    PopDetail.dismiss()
                } else {
                    Wait.dismiss()
                    PopDetail.show("Error", <Message faIcon={'harzad'} message={message} />)
                }
            })
    }
    return (
        done && <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td><span style={{ fontWeight: 'bold' }}>Land Use Type</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Use Class</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Use Group</span></td>
                    </tr>
                    <tr>
                        <td><input defaultValue={tpUnit.landUseType} name={'landUseType'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><input defaultValue={tpUnit.useClass} name={'useClass'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><input defaultValue={tpUnit.useGroup} name={'useGroup'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                    </tr>
                    <tr>
                        <td><span style={{ fontWeight: 'bold' }}>TP Drawing Number</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>District Name</span></td>
                        <td><span style={{ fontWeight: 'bold' }}>Region Name</span></td>
                    </tr>
                    <tr>
                        <td><input defaultValue={tpUnit.tpDrawingNumber} name={'tpDrawingNumber'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><input defaultValue={tpUnit.districtName} name={'districtName'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                        <td><input defaultValue={tpUnit.regionName} name={'regionName'} style={style.td} type={'text'} ref={register({ required: true })} /></td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <br /><button onClick={PopDetail.dismiss} style={{ float: 'right', width: '150px' }} className={"default"}>Cancel</button>
                            <button type={'submit'} style={{ float: 'right', width: '150px' }}>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    )
}
export default function SpatialUnit({ writable, heading = true, datum = null, spEdit = false, tpEdit = false }) {
    const [spatials, spatialsSet] = useState([])
    const [select, selectedSet] = useState(-1)
    const newSpatials = data => {
        console.log("data to display", data)
        let news = [...spatials, data];
        spatialsSet(news)
    }
    /*    useEffect(()=>{
            if(datum !== null){
                console.log("data to display ****",datum)
                spatialsSet(datum)
            }
        },[])*/
    useEffect(() => {
        API.ax.get(`/transaction/spatial-detail/${TransactionPopup.id()}`)
            .then(d => {
                console.log("data get", d.data)
                spatialsSet(d.data.result)
            })
            .catch(e => console.error(e))
    }, [])
    const refresh = data => {
        if (data === true) {
            Wait.show("Refresh ...")
            API.ax.get(`/transaction/spatial-detail/${TransactionPopup.id()}`)
                .then(d => {
                    Wait.dismiss()
                    console.log("data get", d.data)
                    spatialsSet(d.data.result)
                })
                .catch(e => {
                    Wait.dismiss()
                    console.error(e)
                })
        }
    }
    return (
        <div style={{ border: '0.5px solid #efefef', backgroundColor: '#fff' }}>
            <Header display={writable} label={'Spatial Units'} heading={heading}>
                <div>
                    <i className={'fa fa-plus-circle'}
                        style={{ color: 'tomato', fontSize: '25px', cursor: 'pointer' }}
                        onClick={() => PopDetail.show("New Unsurveyed Spatial Units", <Unsurveyed />, 90)} /> <b>New Unsurveyed Spatial Units</b>
                </div>
                <div>
                    <i className={'fa fa-search'}
                        style={{ color: 'tomato', fontSize: '25px', cursor: 'pointer' }}
                        onClick={() => PopDetail.show("Search Spatial Units", <Search newSpatials={newSpatials} />, 90)} /> <b>Search</b>
                </div>
                <div>
                    <i className={'fa fa-trash'}
                        style={{ color: 'tomato', fontSize: '25px', cursor: 'pointer' }}
                        onClick={() => { }} /> <b>Remove</b>
                </div>
            </Header>
            {spEdit && <Header display={spEdit}>
                <div>
                    <i className={'fa fa-edit'}
                        style={{ color: 'tomato', fontSize: '25px', cursor: 'pointer' }}
                        onClick={select > -1 ? () => PopDetail.show("Edit Legacy Spatial Details", <EditSpatial refresh={refresh} spatialUnit={spatials[select]} />, 70) : () => { }} /> <b>Edit Spatial Unit</b>
                </div>
            </Header>}

            {tpEdit && <Header display={tpEdit}>
                <div>
                    <i className={'fa fa-edit'}
                        style={{ color: 'tomato', fontSize: '25px', cursor: 'pointer' }}
                        onClick={select > -1 ? () => PopDetail.show("Edit Legacy TP Details", <EditTPSpatial spatialUnit={spatials[select]} refresh={refresh} />, 60) : () => { }} /> <b>Edit TP Spatial Unit</b>
                </div>
            </Header>}
            <div style={{ height: '200px', overflow: 'auto', width: '100%' }}>
                <table style={{ width: '100%' }} className={'tb'}>
                    <thead>
                        <tr>
                            <th>UPID</th>
                            <th>TYPE</th>
                            <th>TITLE</th>
                            <th>DISTRICT</th>
                            <th>LOCATION</th>
                            <th>BLOCK</th>
                            <th>NUMBER</th>
                            <th>VALUATION NO.</th>
                            <th>USE TYPE</th>
                            <th>GROUP/CLASS</th>
                            <th>SURVEY AREA</th>
                            <th>ALLOCATION STATUS</th>
                            <th>GEOMETRY</th>
                            <th>STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {spatials.length > 0 && spatials?.map(({ spatialUnitId, lotType, title, districtName, localityName, blockNumber, lotNumber, valuationNo, landUseName, groupClass, legalArea, areaUnitName, allocationStatusName, geomerty, status }, i) =>
                            <tr key={i} onClick={() => { selectedSet(i) }} style={select === i ? styles.highlight : styles.unHighlight}>
                                <td>{spatialUnitId}</td>
                                <td>{lotType}</td>
                                <td>{title}</td>
                                <td>{districtName}</td>
                                <td>{localityName}</td>
                                <td>{blockNumber}</td>
                                <td>{lotNumber}</td>
                                <td>{valuationNo}</td>
                                <td>{landUseName}</td>
                                <td>{groupClass}</td>
                                <td>{legalArea} {areaUnitName}</td>
                                <td>{allocationStatusName}</td>
                                <td>{geomerty}</td>
                                <td>{status}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <SpatialDetails spatial={spatials[select]} />
        </div>
    )
}
export const style = {
    inputHeight: {
        height: "25px",
        width: '100%'
    },
    label: {
        color: "#888",
        fontSize: "15px",
        fontWeight: "normal !important",
        marginBottom: "1px"
    },
    selected: {
        borderTop: ".2rem solid #DF4A36",
        borderLeft: "1px solid #efefef",
        borderRight: "1px solid #efefef",
    },
    tab: {
        cursor: 'pointer',
        fontSize: '20px',
        padding: '.2rem'
    },
    tabBody: {
        marginLeft: '.3rem',
        marginTop: '.2rem',
        paddingBottom: '.3rem'
    },
    td: { width: '100%', height: '38px' }
}
