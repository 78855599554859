import React, { useState, useEffect } from 'react'
import PopDetail from "../popdetail";
import Header from "../transacrions/screen/gro/header";
import TxCreateService from "../../../services/transaction/transaction_create";
import Wait from "../wait";
import GeneralSelect from "../../../utils/GeneralSelect";
import { useForm } from "react-hook-form";
import API from "../../../services/API";
import PopDetailsTwo from "../PopDetailsTwo";
import Message from "../transacrions/screen/gro/person/message";
import { listener, startTablet } from "../../../utils/sign-pad";
import CompensationAllowance from '../../../componentss/valuation-tabs/compensation-allowance';
/*
* user list table
* action buttons to edit, add roles disable, deactivate
*/
const regions =
{
    'endpoint': 'land/regions',
    'value': 'regionId',
    'label': 'regionName',
    'placeholder': 'Select Region'
}
const positions =
{
    'endpoint': 'user/positions',
    'value': 'positionId',
    'label': 'position',
    'placeholder': 'Select Position'
}
const departments =
{
    'endpoint': 'user/departments',
    'value': 'departmentId',
    'label': 'department',
    'placeholder': 'Select Department'
}
const highlight = {
    fontWeight: 'bold',
    cursor: 'pointer'
}
const unHighlight = {
    cursor: 'pointer'
}
const active = {
    cursor: 'pointer',
    fontSize: '20px',
    borderTop: '4px solid tomato'
}
const tab = {
    cursor: 'pointer',
    fontSize: '20px'
}
const show = {
    display: 'inherit'
}
const hide = {
    display: 'none'
}
const AddRoles = ({ user }) => {
    const [transactions, transactionsSet] = useState([])
    const [pointer, pointerSet] = useState('')
    const [inner, innerSet] = useState({})
    const [secondary, secondarySet] = useState([])
    const [taskId, taskIdSet] = useState(0)
    const [workstationPermissions, workstationPermissionsSet] = useState({ data: [] })
    const [selected, selectedSet] = useState({ data: [] })
    const [txName, txNameSet] = useState('')
    useEffect(() => {
        API.ax.get("transaction/list-definition")
            .then(d => {
                console.log("the list ", d.data.transactions)
                transactionsSet(d.data.transactions)
            })
    }, [])
    useEffect(() => {
        if (user) {
            API.ax.get(`transaction/user-permission/${user.userId}`)
                .then(d => {
                    console.log("the user permission ", d.data)
                    const { status, result } = d.data
                    if (status === 1) {
                        selectedSet(prevState => ({ ...prevState, data: result }))
                    }
                })
        }
    }, [])
    useEffect(() => {
        if (taskId > 0) {
            secondary.map(d => {
                if (d.id === parseInt(taskId)) {
                    txNameSet(d.name)
                    console.log("tx id ", d.id)
                }
            })
            API.ax.get(`transaction/tasks-by-transaction/${taskId}`)
                .then(d => {
                    console.log("from back ", d.data)
                    workstationPermissionsSet(prevState => ({ ...prevState, data: d.data.tasksByTransaction }))
                })
                .catch(e => { console.log(e) })
        }
    }, [taskId])
    useEffect(() => {
        innerSet(transactions[parseInt(pointer)]);
    }, [pointer])
    useEffect(() => {
        if (inner !== undefined) {
            const { transactions: tx } = inner
            secondarySet(tx)
        }
    }, [inner])

    const handleSubmit = () => {
        let permissionArray = [];
        selected.data.map(d => {
            permissionArray.push(parseInt(d.permissionId))
        })
        const toSubmit = {
            userId: user.userId,
            "selectedRoles": permissionArray,
            "module": 1
        }
        try {
            Wait.show("Saving Roles")
            API.ax.post("user/add-user-permission", toSubmit)
                .then(d => {
                    Wait.dismiss()
                    console.log("Response", d)
                })
                .catch(e => {
                    Wait.dismiss()
                    console.log(e)
                })
        } catch (e) {
            console.error(e)
        }
        PopDetail.dismiss()
    }
    return (
        <>
            <h3>Please add Roles for {user.userName}</h3>
            <div>
                <select onChange={(e) => { pointerSet(e.target.value); }}>
                    <option value={''}> Select a Transaction Category</option>
                    {transactions.map((d, i) => {
                        return (
                            <option key={i} value={i}>{d.transactionType}</option>
                        )
                    })}
                </select>
                {secondary ?
                    <select onChange={(e) => { taskIdSet(e.target.value); }}>
                        <option value={''}> Select a Transaction</option>
                        {secondary.map((d, i) => {
                            return (
                                <option key={i} value={d.id} onClick={() => { console.log(i) }}>{d.name}</option>
                            )
                        })}
                    </select>
                    : ''}
            </div>
            {
                workstationPermissions.data.length > 0 || workstationPermissions.data.length === undefined ?
                    [...new Set(workstationPermissions.data)].map((d, i) => {
                        return (
                            <li
                                key={i}
                                style={{ cursor: 'pointer', listStyleType: 'none', marginLeft: '10px' }}
                                onClick={() => {
                                    workstationPermissions.data.splice(i, 1)
                                    selectedSet(prevState => ({ ...prevState, data: [...prevState.data, d] }))
                                }}
                            >
                                <input key={i} type="radio" checked={false} style={{ margin: '.5rem' }} onChange={() => { }} /><b>{d.txName}</b> - {d.taskName}</li>
                        )
                    }) : <b>You have selected all Roles</b>
            }
            {selected.data.length > 0 ? [...new Set(selected.data)].map((s, i) => {
                return (
                    <li key={i} style={{ cursor: 'pointer', listStyleType: 'none', marginLeft: '10px' }} onClick={() => {
                        selected.data.splice(i, 1)
                        workstationPermissionsSet(prevState => ({ ...prevState, data: [...prevState.data, s] }))
                    }}><input key={i} type="radio" checked="checked" style={{ margin: '.5rem' }} onChange={() => { }} /> <b>{s.txName}</b> - {s.taskName}</li>
                )
            }) : <b>You have not selected any Roles</b>}
            <button onClick={handleSubmit}> save</button>
        </>
    )
}
const AddUsers = ({ handleAddUser }) => {
    const [nidaOk, nidaOkSet] = useState(false)
    const [nidaNumber, nidaNumberSet] = useState('')
    const [nidaData, nidaDataSet] = useState({})
    const [isReadOnly, isReadOnlySet] = useState(false)
    const [current, currentSet] = useState(0)
    const [departmentId, departmentIdSet] = useState('');
    const [positionId, positionIdSet] = useState('');
    const [officeId, officeIdSet] = useState('');
    const [regionId, regionIdSet] = useState(0);
    const [councilId, councilIdSet] = useState(0);
    const [isSubmit, isSubmitSet] = useState(false);
    const { register, handleSubmit } = useForm();

    const handleChange = e => {
        nidaNumberSet(e.target.value)
    }
    const onChangeRegion = event => {
        regionIdSet(event.value);
    }
    const onChangeCouncil = event => {
        councilIdSet(event.value);
    }
    const onChangeDepartment = event => {
        departmentIdSet(event.value);
    }
    const onChangePosition = event => {
        positionIdSet(event.value);
    }
    const onChangeOffice = event => {
        officeIdSet(event.value);
    }

    const [councils, councilsSet] = useState({}
    )

    const [offices, officesSet] = useState(
        {
            'value': 'officeId',
            'label': 'name',
            'placeholder': 'Select Office'
        })
    useEffect(() => {
        councilsSet(
            {
                'value': 'id',
                'label': 'name',
                'placeholder': 'Select Council'
            });

    }, [])
    useEffect(() => {
        if (regionId > 0 && !councils.hasOwnProperty("endpoint") && !isSubmit) {
            councilsSet({ ...councils, endpoint: `land/councils-by-region/${regionId}` })
        }
    }, [regionId])
    useEffect(() => {
        if (councilId > 0 && !offices.hasOwnProperty("endpoint") && !isSubmit) {
            officesSet({ ...offices, endpoint: `land/offices-by_council/${councilId}` })
        }
    }, [councilId])
    const handleSearch = () => {
        console.log(nidaNumber, "nidas")
        try {
            if (nidaNumber.trim().length === 20 && !isNaN(nidaNumber)) {
                Wait.show("Searching...")
                TxCreateService.verifyNida(nidaNumber)
                    .then(d => {
                        Wait.dismiss()
                        console.log("The response", d.data)
                        nidaDataSet(d.data.party)
                        isReadOnlySet(d.data.status !== 0)
                        nidaOkSet(true)
                    })
                    .catch(e => {
                        Wait.dismiss()
                        console.error("exception", e)
                    })
            }
        } catch (e) {
            console.error("catch", e)
        }

    }
    const submit = data => {
        const submitData = { ...data, officeId, departmentId, positionId }
        isSubmitSet(true);
        handleAddUser(submitData)
    }

    return (
        <div style={{ marginLeft: '1.5rem', marginRight: '1rem' }}>
            <div className={'row'}
                style={{ paddingTop: '.5rem', borderBottom: '1px solid #ccc', width: '100%', textAlign: 'center' }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td><label htmlFor={'nida'}>* Person Nida Id Number</label></td>
                            <td><input style={{ width: '100%' }} id={'nida'} readOnly={isReadOnly} onChange={handleChange} /></td>
                            <td>
                                <button style={{ width: '100%' }} onClick={handleSearch}>Search</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={'row'} style={{ gap: '40px', borderBottom: '1px solid #ccc', width: '100%', paddingTop: '.5rem', marginTop: '.5rem' }} >
                <label
                    style={current === 0 ? active : tab}
                    onClick={() => { currentSet(0) }}>General</label>
                <label
                    style={current === 1 ? active : tab}
                    onClick={() => { currentSet(1) }}>Work Details</label>
            </div>
            <table style={current === 0 ? show : hide} className={'tb'}>
                <tbody>
                    <tr>
                        <td><label>First Name: {nidaData ? nidaData.firstName : ''}</label></td>
                        <td rowSpan={3}><img alt={'photo1'} style={{ height: '192px', width: '192px', marginRight: '8px', background: '#f7f7f7' }} src={nidaData ? 'data:image/png;base64,' + nidaData.photo : ''} /></td>
                    </tr>
                    <tr>
                        <td><label>Middle Name: {nidaData ? nidaData.middleName : ''}</label></td>
                    </tr>
                    <tr>
                        <td><label>Last Name: {nidaData ? nidaData.surName : ''}</label></td>
                    </tr>
                </tbody>
            </table>
            <form onSubmit={handleSubmit(submit)}>
                <table style={current === 1 ? show : hide} className={''}>
                    <tbody>
                        <tr>
                            <td><label>Nida Number</label></td>
                            <td><input type={'text'} name={'nationalId'} readOnly={true} defaultValue={nidaData ? nidaData.nin : ''} ref={register({ required: true })} /></td>
                            <td><label>Region Name</label></td>
                            <td style={{ width: '50%' }}><GeneralSelect parameters={regions} onChanges={onChangeRegion} /></td>
                        </tr>
                        <tr>
                            <td><label>Check Number</label></td>
                            <td><input type={'text'} name={'checkNumber'} ref={register({ required: true, minLength: 4 })} /></td>
                            <td><label>Council Name</label></td>
                            <td><GeneralSelect parameters={councils} onChanges={onChangeCouncil} /></td>
                        </tr>
                        <tr>
                            <td><label>Phone Number</label></td>
                            <td><input type={'text'} name={'phoneNumber'} ref={register({ required: true, pattern: { value: /^255[67]{1}[0-9]{2}[0-9]{6}$/, message: "Enter a Valid Phone number" } })} /></td>
                            <td><label>Office Name</label></td>
                            <td><GeneralSelect parameters={offices} onChanges={onChangeOffice} /></td>
                        </tr>
                        <tr>
                            <td><label>Job Title</label></td>
                            <td><input type={'text'} name={'jobTitle'} ref={register({ required: true, minLength: 4 })} /></td>
                            <td><label>Department Name</label></td>
                            <td><GeneralSelect parameters={departments} onChanges={onChangeDepartment} /></td>
                        </tr>
                        <tr>
                            <td><label>Office Mail</label></td>
                            <td><input type={'email'} name={'officeMail'} ref={register({ required: true })} /></td>
                            <td><label>Position</label></td>
                            <td><GeneralSelect parameters={positions} onChanges={onChangePosition} /></td>
                        </tr>
                        <tr>
                            <td><label>IS HR?</label></td>
                            <td><input type={'checkbox'} name={'isHr'} ref={register} defaultChecked={false} style={{ width: "25px", height: "25px" }} /></td>
                            <td><label>IS HOD?</label></td>
                            <td><input type={'checkbox'} name={'isHod'} ref={register} defaultChecked={false} style={{ width: "25px", height: "25px" }} /></td>
                        </tr>
                        <tr>
                            <td><label>Jurisdiction</label></td>
                            <td><select name={'jurisdiction'} ref={register({ required: true })} defaultValue={'center'} style={{ width: "100%" }}>
                                <option value={'1'}>Center</option>
                                <option value={'2'}>Region</option>
                                <option value={'3'}>Country</option>
                            </select></td>
                        </tr>
                    </tbody>
                </table>
                <button type={'submit'}> save</button>
            </form>
        </div>
    )
}

const UserSign = ({ userId }) => {

    const [sigCaptured, sigCapturedSet] = useState(null);

    listener()

    const handleSig = () => {
        let signature = sigCaptured.sig;
        let dataToSend = { userId: userId.userId, signature }
        console.log("Required Data ", dataToSend)
        Wait.show("Updating User Signature ....")
        API.ax.post("/user/update-signature", dataToSend)
            .then(d => {
                Wait.dismiss()
                let { status, message } = d.data
                if (status === 1) {
                    PopDetail.show("Successful", <Message message={message} action={0} faIcon={"success"} />, 40)
                } else {
                    let sms = "The new Signature was not Set, Please Try Again"
                    PopDetail.show("Error", <Message message={sms} action={0} faIcon={"harzad"} />, 40)

                }
            }
            )
            .catch(e => {
                PopDetail.show("Error", <Message message={e} action={0} faIcon={"harzad"} />, 40)

            })
    }

    const handleSigCapture = (sig) => {
        let image = new Image();
        image.src = 'data:image/png;base64,' + sig;
        sigCapturedSet({ image, sig })
    }
    return (
        <>
            <canvas id="cnv" width="500" height="100" />
            <div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%" }}>
                    {!sigCaptured ?
                        <button style={{ width: "150px", outline: "none" }} onClick={() => { startTablet(handleSigCapture); }}>Sign</button>
                        : <button style={{ width: "250px", outline: "none" }} onClick={() => { handleSig(); }}>Submit Signature</button>
                    }
                    <button className={"default"} style={{ width: "150px", outline: "none" }} onClick={() => PopDetail.dismiss()}>Cancel</button>
                </div>
            </div>
        </>
    )
}

const EnableUser = ({ userId, active, userName, refresh }) => {
    const { register, handleSubmit } = useForm()
    const submit = (data) => {
        let dataToSend = { userId, ...data }
        Wait.show(`${active ? 'Deactivating' : 'Activating'} ${userName}....`)
        API.ax.post(`${active ? '/auth/disable-user' : '/auth/enable-user'}`, dataToSend)
            .then(d => {
                Wait.dismiss()
                //PopDetail.show("success", <Message faIcon={"success"} message={`You have Successful ${active ? 'Deactivated' : 'Activated'} ${userName}`} />, 50)
                refresh();
            })
            .catch(e => {
                Wait.dismiss()
                PopDetail.show("Error", <Message faIcon={"harzad"} message={e} />, 50)
                console.error(e)
            })
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td><label>Reason to {active ? 'Deactivate' : 'Activate'} {userName}</label></td>
                    </tr>
                    <tr>
                        <td><textarea name={"reason"} style={{ width: '100%', resize: 'none' }} rows={5} ref={register({ required: true })}></textarea></td>
                    </tr>

                </tbody>
            </table>
            <button style={{ float: 'right', width: '150px' }} onClick={() => PopDetail.dismiss()} className={'default'}>Cancel</button>
            <button style={{ float: 'right', width: '150px' }} type={'submit'}>{active ? 'Deactivate' : 'Activate'}</button>
        </form>
    )
}
export default function Users() {
    const [users, usersSet] = useState([])
    const handleAddUser = data => {
        console.log(data)
        Wait.show("Saving User...")
        PopDetail.dismiss()
        try {
            API.ax.post('user/register', data)
                .then(d => {
                    Wait.dismiss()
                    console.log(d)
                })
                .catch(e => {
                    Wait.dismiss()
                    console.error(e)
                })
        }
        catch (e) {
            console.error(e)
            Wait.dismiss()
        }
    }
    const [selectedUserId, selectedUserIdSet] = useState(0)

    useEffect(() => {
        Wait.show("Loading Users ...")
        API.ax.get('user/users')
            .then(d => {
                Wait.dismiss()
                PopDetail.dismiss()
                console.log("the users", d.data.result)
                usersSet(d.data.result)
            })
            .catch(e => {
                Wait.dismiss()
                console.log(e)
            })
    }, [])

    const refresh = () => {
        Wait.show("Loading Users ...")
        API.ax.get('user/users')
            .then(d => {
                Wait.dismiss()
                PopDetail.dismiss()
                console.log("the users", d.data.result)
                usersSet(d.data.result)
            })
            .catch(e => {
                Wait.dismiss()
                console.log(e)
            })
    }

    const handlePin = () => {
        const { firstName, phoneNumber, userId, userName } = users[selectedUserId]
        let data = { firstName, phoneNumber, userId, userName }
        Wait.show("Pin Send Request ...")
        API.ax.post("user/pin-request", data)
            .then(d => {
                Wait.dismiss()
                PopDetail.dismiss()
                console.log(d)
                if (d.data.status === 1) {
                    PopDetailsTwo.show("Successful", <Message faIcon={"success"} message={`A pin has Been Successfully Sent to ${firstName}`} action={1} />, 40)
                } else {
                    PopDetailsTwo.show("Error", <Message faIcon={"harzad"} message={`A Failure `} action={1} />, 40)
                }
            })
        console.log(data)
        //user/pin-request
    }

    return (
        <div style={{ height: '200px' }}>
            <Header display={true} label={'Users'}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={() => { PopDetail.show("Add User", <AddUsers handleAddUser={handleAddUser} />) }}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={() => { }}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-tasks'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={selectedUserId > -1 ? () => { PopDetail.show("Add Roles to User", <AddRoles user={users[selectedUserId]} />) } : () => { }}
                    /><strong> Assign Roles</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-bell'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={selectedUserId > -1 ? handlePin : () => { }}
                    /><strong> Resend PIN</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-toggle-on'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={selectedUserId > -1 ? () => { PopDetail.show("Activate/Deactivate User", <EnableUser userId={users[selectedUserId].userId} active={users[selectedUserId].active} userName={users[selectedUserId].userName} refresh={refresh} />, 50) } : () => { }}
                    /><strong> Activate/Deactivate</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-pencil'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={selectedUserId > -1 ? () => { PopDetail.show("Update Signature", <UserSign userId={users[selectedUserId]} />, 50) } : () => { }}
                    /><strong> Change Signature</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-refresh'}
                        style={{ color: 'tomato', fontSize: '25px' }}
                        onClick={refresh}
                    /><strong> Refresh</strong>
                </div>
                <div>
                    <button style={{ float: 'right', width: '80px' }} onClick={() => document.getElementById('base-container').style.display = 'none'}>Close</button>
                </div>
            </Header>
            <div className={''} style={{ height: '500px', overflow: 'auto', padding: '15px', width: '100%' }}>
                <table className={'tb'}>
                    <thead>
                        <tr>
                            <th>USER NAME</th>
                            <th>FIRST NAME</th>
                            <th>MIDDLE NAME</th>
                            <th>LAST NAME</th>
                            <th>OFFICE</th>
                            <th>DEPARTMENT</th>
                            <th>POSITION</th>
                            <th>STATUS</th>
                            <th>PHONE</th>
                            <th>EMAIL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users ?
                                users.map((d, i) => {
                                    return (
                                        <tr key={i} style={i === selectedUserId ? highlight : unHighlight} onClick={() => { selectedUserIdSet(i) }}>
                                            <td>{d.userName}</td>
                                            <td>{d.firstName}</td>
                                            <td>{d.secondName}</td>
                                            <td>{d.lastName}</td>
                                            <td>{d.officeId}</td>
                                            <td>{d.departmentId}</td>
                                            <td>{d.jobTitle}</td>
                                            <td>{d.active === true ? 'Active' : 'Disabled'}</td>
                                            <td>{d.phoneNumber}</td>
                                            <td>{d.email}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan={12} style={{ textAlign: 'center' }}>No users</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
