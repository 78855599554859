import logo from "../../../../resource/img/nembo.png";
import Dashboard from "../../containers/dashboard";
import React from "react";
import Users from "./users";

export default function Uma (){

    return (
<div  className={'base-container'} id={'base-container'}>
        <div className={'col col-sm-12 base-toolbar'}>

            <div className={'col col-sm-2'} style={{float:'left'}}>
                <span className={'fa fa-navicon'} style={{marginRight:'16px',fontSize:'20px'}}/>
                <img src={logo} alt={'logo'} style={{height:'4vh',marginRight:'16px',marginTop:'-1.5vh'}}/>
                <span style={{fontFamily:'oxygen-bold',fontSize:'20px'}}>ILMIS</span>

            </div>

            {/*<div id={'btn-new'} style={{float:'left',padding:'0px 8px'}} onClick={()=>this.showAddSubmenuAt(0,[])}>
                    <span className={'fa fa-plus'} style={{fontSize:'18px',marginRight:'8px'}}/>
                    <b>New Project</b>
                </div>*/}

            <div style={{float:'right'}}>
                <span>{sessionStorage.getItem('username')}</span>
                <span onClick={()=>Dashboard.logout()} className={'fa fa-power-off'}
                      style={{fontSize:'18px',cursor:'pointer',marginLeft:'16px'}}/>
            </div>

        </div>
    <div className={'col col-sm-12'} style={{marginLeft:'.2rem',paddingTop:'.2rem'}}><Users/></div>
    </div>
    )
}
