import React from "react";
import {useBuildingDispatch, useBuildingState} from "./store";

export default function  OtherBuilding() {
    const state = useBuildingState()
    const dispatch = useBuildingDispatch()

    const handleChange=(e)=>{
        const { name, value } = e.target;
        dispatch({ type: "building",field:name, payload:value });
    }

    const{
        bathroomType,
        bedroomType,
        buildingAgeType,
        buildingName,
        buildingQuantityType,
        numberOfFloors,
        toiletType,
        wallFinishedType
    }=state.valuationBuilding
    return(
        <div className={""}>
            <div className={"row"}>
                <div className={"col-sm-6"}>
                    <div className={"form-group"}>
                        <label htmlFor="#bathroomType" className={"control-label" }>Bathroom Type</label>
                        <input
                            id="#bathroomType"
                            placeholder="Bathroom Type"
                            name ="bathroomType"
                            defaultValue={bathroomType}
                            min="1"
                            max="50"
                            className={"form-control"}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className={"col-sm-6"}>
                    <div className={"form-group"}>
                        <label htmlFor="#bedroomType" className={"control-label" }>Bedroom Type</label>
                        <input
                            id="#bedroomType"
                            placeholder="Bedroom Type"
                            defaultValue={bedroomType}
                            name ="bedroomType"
                            className={"form-control"}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-6"}>
                    <div className={"form-group"}>
                        <label htmlFor="#buildingAgeType" className={"control-label" }>Building Age Type</label>
                        <input
                            id="#buildingAgeType"
                            placeholder="Building Age Type"
                            name ="buildingAgeType"
                            defaultValue={buildingAgeType}
                            className={"form-control"}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className={"col-sm-6"}>
                    <div className={"form-group"}>
                        <label htmlFor="#buildingName" className={"control-label" }>Building Name</label>
                        <input
                            id="#buildingName"
                            placeholder="Building Name"
                            name ="buildingName"
                            defaultValue={buildingName}
                            className={"form-control"}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-6"}>
                    <div className={"form-group"}>
                        <label htmlFor="#buildingQuantityType" className={"control-label" }>Building Quantity Type</label>
                        <input
                            id="#buildingQuantityType"
                            placeholder="Building Quantity Type"
                            name ="buildingQuantityType"
                            defaultValue={buildingQuantityType}
                            className={"form-control"}
                            min={0}
                            max={100}
                            onChange={handleChange}
                        />

                    </div>
                </div>
                <div className={"col-sm-6"}>
                    <div className={"form-group"}>
                        <label htmlFor="#numberOfFloors" className={"control-label" }>Number Of Floors</label>
                        <input
                            id="#numberOfFloors"
                            placeholder="Number Of Floors"
                            name ="numberOfFloors"
                            defaultValue={numberOfFloors}
                            className={"form-control"}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-sm-6"}>
                    <div className={"form-group"}>
                        <label htmlFor="#toiletType" className={"control-label" }>Toilet Type</label>
                        <input
                            id="#toiletType"
                            placeholder="Toilet Type"
                            name ="toiletType"
                            defaultValue={toiletType}
                            className={"form-control"}
                            onChange={handleChange}
                        />

                    </div>
                </div>
                <div className={"col-sm-6"}>
                    <div className={"form-group"}>
                        <label htmlFor="#wallFinishedType" className={"control-label" }>Wall Finished Type</label>
                        <input
                            id="#wallFinishedType"
                            placeholder="Wall Finished Type"
                            name ="wallFinishedType"
                            defaultValue={wallFinishedType}
                            className={"form-control"}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )


}