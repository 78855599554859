import * as React from "react";
import Wait from "../../wait";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../mobile_popup";
import * as LF from "leaflet";
import Basemap from "../../../maps/basemap";
import UgSpatial from "../../../maps/ug_spatial";
import API from "../../../../services/API";
import PopDetail from "../../../../componentss/popdetail";
import PopParcel from "../pops/pane_pop_parcel";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../resource/img/checkbox/checkbox_checked_16.png";
export default class MobileCompletionPane extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
            parcels:[],
            layers:[],
            page:0,
            data:[],
            total:{},
            description_title:'Processing Report'
        }

        this.loadParcels = this.loadParcels.bind(this)
        this.loadLocality = this.loadLocality.bind(this)
        this.toggleCheck = this.toggleCheck.bind(this)
        this.downloadData = this.downloadData.bind(this)
        this.searchByClaimNo = this.searchByClaimNo.bind(this)
        this.loadDataInLayer = this.loadDataInLayer.bind(this)
        this.handleChangeDataType = this.handleChangeDataType.bind(this)
        this.download = this.download.bind(this)
        this.loadDataAt = this.loadDataAt.bind(this)
    }

    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}
    handleChangeFile=e=>{this.setState({ coordinate:e.target.files[0] })}
    handleChangeDataType=e=>{this.loadParcels(parseInt(e.target.value))}

    download(d){

    }

    loadDataInLayer=e=> {
        this.setState({[e.target.name]: e.target.value});

        let layer = e.target.value

        this.state.list_layers.forEach(it=>{


            if(parseInt(it.id.split('.')[1])===parseInt(layer)){

                console.log(it.layer.getBounds())

                try {
                    this.state.map.fitBounds(it.layer.getBounds())
                    //this.state.map.setView(it.layer.getBounds().getCenter(),100);
                }catch (e) {
                    console.log(e)
                }
            }
        })

    }

    searchByClaimNo(){
        let claim = this.state.search_claim
        try {
            Wait.show('Searching...')
            MobileReadService.searchByClaimNo(MobilePopup.id(),claim).then(r => {
                Wait.dismiss()
                try {
                    console.log(r.data)
                    this.setState({
                        parcels:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    loadLocality() {
        Wait.show("Loading Localities...")
        MobileReadService.mobileLayers(MobilePopup.id()).then(d => {
            Wait.dismiss()
            try {
                this.setState({
                    layers:d.data.results
                })


                UgSpatial.clear()

                let ly_group = new LF.FeatureGroup();
                let sat = Basemap.googleSat(),
                    hybrid = Basemap.googleHybrid(),
                    blank = LF.tileLayer('')

                let map = LF.map('map', {
                    layers: [blank, ly_group],
                    attributionControl: false,
                    fullscreenControl: true,
                    maxZoom:10000
                }).setView([-6.085936, 35.711995], 4)


                let baseLayers = {
                    "<span style='color: gray'>Blank</span>": blank,
                    "Satellite": sat,
                    "Hybrid": hybrid
                }

                let overlays = {
                    //'Grids':grid,
                    'Mobile layers':ly_group,

                }

                UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)

                let filter = 'project_id in ('+MobilePopup.id()+')'
                let layername = 'mob_layers'
                let self = this


                API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                    try {
                        //console.log(r.data.features)
                        r.data.features.forEach(p=>{
                            let myLayer = LF.geoJSON(p,{
                                onEachFeature:(ft,ly)=>{
                                    let label = LF.marker(ly.getBounds().getCenter(),{
                                        icon:LF.divIcon({
                                            className:'bindTooltipBig',
                                            html:ft.properties.label,
                                            iconSize:[100,40]
                                        })
                                    }).addTo(ly_group)
                                },
                                color: p.properties.boundary?'#000':'#f00',weight:p.properties.boundary?4:3,zIndex:1, fillOpacity: 0
                            }).addTo(ly_group);
                        })

                        let myLayer = LF.geoJSON(r.data.features)

                        try{ map.fitBounds(myLayer.getBounds())}catch (e) {}

                    }catch (e) {
                        console.log(e)
                    }
                })


                layername = 'mob_parcel'
                let selected

                API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                    try {
                        //console.log(r.data.features)
                        r.data.features.forEach(p=>{
                            let myLayer = LF.geoJSON(p,{
                                color: 'transparent',weight:3,zIndex:2, fillOpacity: 0
                            }).on('dblclick',e=>{
                                this.popDataAt(p.properties)
                            }).on('click', function (e) {
                                if (selected) {e.target.resetStyle(selected)}
                                selected = e.layer
                                selected.bringToFront()
                                self.setState({description_title:p.properties.claim_no})
                                selected.setStyle({color: '#000', weight:4})
                                map.fitBounds(selected.getBounds())
                                //map.setView(selected.getBounds().getCenter());
                            }).addTo(map)


                        })
                    }catch (e) {
                        console.log(e)
                    }
                })

                let ly_vb = UgSpatial.getLayerByName('mob_parcel','project_id in ('+MobilePopup.id()+') and stage in ('+this.state.current_tab+')').addTo(map)
                ly_vb.bringToFront()

            }catch (e) {
                console.log(e)
            }
        })


    }

    downloadData() {
        let layer_id = this.state.layer_id.replace('mob_layers.', '')
        let stage = this.state.stage
        let doc_id = this.state.doc_id

        if (layer_id < 1 || layer_id === undefined) {
            alert('No Project Layer')
            return
        }

        if (doc_id < 1 || doc_id === undefined) {
            alert('Select Document Type')
            return
        }
    }

    componentDidMount() {
        this.loadLocality()
        this.loadParcels()
        UgSpatial.clear()

        if (this.props.hide_map===true){this.popFullTable()}

        let ly_group = new LF.FeatureGroup();
        let sat = Basemap.googleSat(),
            hybrid = Basemap.googleHybrid(),
            blank = LF.tileLayer('')

        let map = UgSpatial.map==null?LF.map('map', {
            layers: [blank, ly_group],
            attributionControl: false,
            fullscreenControl: true,
            maxZoom:10000
        }).setView([-6.085936, 35.711995], 4):UgSpatial.map



        let baseLayers = {
            "<span style='color: gray'>Blank</span>": blank,
            "Satellite": sat,
            "Hybrid": hybrid
        }



        let filter = 'project_id in ('+MobilePopup.id()+')'
        let layername = 'mob_layers'
        let self = this

        API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
            try {
                //console.log(r.data.features)
                let list_layers = []
                r.data.features.forEach(p=>{
                    let myLayer = LF.geoJSON(p,{
                        onEachFeature:(ft,ly)=>{
                            let label = LF.marker(ly.getBounds().getCenter(),{
                                icon:LF.divIcon({
                                    className:'bindTooltipBig',
                                    html:ft.properties.label,
                                    iconSize:[100,40]
                                })
                            }).addTo(ly_group)
                        },
                        color: p.properties.boundary?'#000':'#f00',weight:p.properties.boundary?4:3,zIndex:1, fillOpacity: 0
                    }).addTo(ly_group);
                    if (p.properties.boundary===true){
                        list_layers.push({
                            id:p.id,
                            label:p.properties.label,
                            name:p.properties.name,
                            layer:myLayer
                        })
                    }

                })

                this.setState({
                    layers:list_layers,
                    list_layers:list_layers,
                    map:map
                })



                let myLayer = LF.geoJSON(r.data.features)
                try{ map.fitBounds(myLayer.getBounds())}catch (e) {}



                //filter = 'project_id in ('+MobilePopup.id()+');stage in ('+this.state.current_tab+')'
                layername = 'mob_parcel'
                let selected

                API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                   try {
                       r.data.features.forEach(p=>{

                           /*if (p.properties.stage === this.state.current_tab){
                               let myLayer = LF.geoJSON(p,{
                                   color: 'transparent',weight:3,zIndex:2, fillOpacity: 0
                               }).on('dblclick',e=>{
                                   this.popDataAt(p.properties)
                               }).on('click', function (e) {
                                   if (selected) {e.target.resetStyle(selected)}
                                   selected = e.layer
                                   selected.bringToFront()
                                   self.setState({description_title:p.properties.claim_no})
                                   selected.setStyle({color: '#000', weight:4})
                                   //map.fitBounds(selected.getBounds())
                                   map.setView(selected.getBounds().getCenter());
                               }).addTo(map)
                           }*/

                       })
                   }catch (e) {
                       console.log(e)
                   }

                })

                let ly_vb = UgSpatial.getLayerByName('mob_parcel','project_id in ('+MobilePopup.id()+')').addTo(map)
                ly_vb.bringToFront()

                let overlays = {
                    'Project Layers':ly_group,
                    'Parcels':ly_vb

                }


                UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)
            }catch (e) {
                console.log(e)
            }



        })




    }

    popDataAt(d){
        PopDetail.show(d.claim_no,<PopParcel parcel={d}/>)
    }

    popFullTable(tb_id='bottom_table'){
        document.getElementById(tb_id).style.position = 'absolute'
        document.getElementById(tb_id).style.zIndex = 10000000
        document.getElementById(tb_id).style.left = '-1vw'
        document.getElementById(tb_id).style.right = 0
        document.getElementById(tb_id).style.bottom = 0
        document.getElementById(tb_id).style.top = 0
        document.getElementById(tb_id).style.height = '100vh'
    }

    toggleCheck(i){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            return;
        }
        doc.src=uncheck;
    }

    loadDataAt(psn){
        this.setState({
            page:this.state.page+psn
        })
        this.loadParcels()
    }

    loadParcels(tab=this.state.current_tab){
        try {
            Wait.show("Loading Report...")
            MobileReadService.readReportProcessing(MobilePopup.id()).then(r => {
                try {
                    this.setState({
                        data:r.data.results,
                        total:r.data.total
                    })
                }catch (e) {
                    console.log(e)
                }
            })


        }catch (e) {
            console.log(e)
        }
    }


    render() {
        return(<div>
            <div className={'row'} style={{marginBottom:'4px'}}>
                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px',paddingLeft:0}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>{this.state.description_title}</b>
                        </div>

                        <div style={{width:'100%', marginTop:'8px'}}>

                        </div>

                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>
                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>

                        <div className={'row'} style={{height:'40px'}}>
                            <b>Data at Stage {this.state.current_tab}</b>
                        </div>

                        <div className={'row'} style={{height:'97%'}}>
                            <div className={'col col-sm-12'} style={{background:'#efefef'}} id={'ramani'}/>
                        </div>

                    </div>

                </div>

            </div>
            <div className={'row'}>

                <div className={'col col-sm-12'} style={{background:'#fff',padding:0,height:'34vh',overflow:'auto'}} id={'bottom_table'}>

                    <div className={'col col-sm-12'}>

                        <div style={{height:'80vh',overflow:'auto'}}>
                            <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #ddd'}}>
                                <thead>
                                <tr>
                                    <th/>
                                    <th>LAYER NAME</th>
                                    <th>MOBILE DATA</th>
                                    <th>REJECTED</th>
                                    <th>GIS APPROVAL</th>
                                    <th>ALO APPROVAL</th>
                                    <th>REGISTERED</th>
                                    <th>PRINTED</th>
                                    <th>INIT</th>
                                    <th>LAND RENT</th>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.data.map((d,i)=>{
                                    return(<tr key={i}>
                                        <td>{(i+1)}.</td>
                                        <td>{d.label}</td>
                                        <td>{d.mobile}</td>
                                        <td>{d.rejected}</td>
                                        <td>{d.gis}</td>
                                        <td>{d.alo}</td>
                                        <td>{d.paid}</td>
                                        <td>{d.printed}</td>
                                        <td>{d.tot_init}</td>
                                        <td>{d.tot_rent}</td>
                                    </tr>)
                                })}

                                <tr style={{fontSize:'18px',fontWeight:'bold'}}>
                                    <td/>
                                    <td>TOTAL</td>
                                    <td>{this.state.total.mobile}</td>
                                    <td>{this.state.total.rejected}</td>
                                    <td>{this.state.total.gis}</td>
                                    <td>{this.state.total.alo}</td>
                                    <td>{this.state.total.paid}</td>
                                    <td>{this.state.total.printed}</td>
                                    <td>{this.state.total.tot_init}</td>
                                    <td>{this.state.total.tot_rent}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>


            </div>



        </div>)
    }

}
