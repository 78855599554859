import API from "../API";

export default class RTCreateService{
    static saveCondition(data) {
        return API.ax.post("land/condition",data).catch(e=>{console.log(e);})
    }

    static saveRtuAcceptance(data) {
        return API.ax.post("rt/create/rtApproval",data).catch(e=>{console.log(e);})
    }
    updaterero
    static saveRtCheckList(dataCheckList) {
        return API.ax.post("rt/rt-checklist",dataCheckList).catch(e=>{console.log(e);})
    }
    static saveRtuAcceptanceInterest(data) {
        return API.ax.get("rt/create/updaterero").catch(e=>{console.log(e);})
    }
}
