import * as React from "react";
import BaseLayout from "./base_layout";
import logo from "../../../resource/img/nembo.png"
import AdminLayout from "./admin_layout";
import MobileLayout from "./mobile_layout";
import PopDetail from "../popups/popdetail";
import RecordManagement from "../records_management/record-management";
import { PasswordResetRequest } from "../popups/uma/password-reset";
import TxCreateService from "../../services/transaction/transaction_create";
import jwt from 'jwt-decode'
import MobileReadService from "../../services/mobile/mobile_read";
import Uma from "../popups/uma/uma";
import SpatialUpades from "../popups/transacrions/pops/spatialUpades";
import BaUnitUpdates from "../popups/transacrions/pops/baunitUpdates";
import TpSpatialUpdate from "../popups/transacrions/pops/pane_update_tp_spatial";
import BaUnitSpatialUpdates from "../popups/transacrions/pops/baunitSpatialUpdates";
import SpatialUpdating from "../pane/SpatialUpdating";
import LastPayDateUpdates from "../popups/transacrions/pops/lastPayDateUpdates";
import ProfessionLayout from "./professions_layout";
export default class Dashboard extends React.Component {

    static user

    constructor(props) {
        super(props);
        this.state = {
            dashboard: [
                [
                    /*{title:'WorkStation',fa:'desktop',pane:'',display:'none'},
                    {title:'Projects',fa:'connectdevelop',display:'none'},
                    {title:'M&E',fa:'bar-chart',display:'none'},
                    {title:'Dashboard',fa:'dashboard',display:'none'},
                    {title:'Record Management',fa:'folder',display:'none'},
                    {title:'Admin',fa:'cogs',pane:'',display:'none'},*/
                ]
            ],
            is_super_user: false,
            base_layout: <div>Loading...</div>,
            titles: []
        }

        this.showSubmenuAt = this.showSubmenuAt.bind(this);
        this.hideSubmenuAt = this.hideSubmenuAt.bind(this);
        this.showDivisionAt = this.showDivisionAt.bind(this);
        this.decodeToken = this.decodeToken.bind(this);
    }

    showSubmenuAt(i, j) { document.getElementById('submenu_' + i + '_' + j).style.display = 'inherit'; }
    hideSubmenuAt(i, j) { document.getElementById('submenu_' + i + '_' + j).style.display = 'none'; }

    componentDidMount() {
        let roles = jwt(sessionStorage.getItem("token"))
        //console.log("ROLESS ===>",roles.authorities)
        let objs = []

        let has_mobile = false



        roles.authorities.map(d => {
            if (d.authority === "Workstation") {
                //this.state.dashboard.push([...this.state.dashboard,{title:'WorkStation',fa:'desktop',pane:'',display:'none'}])
                objs.push({ title: 'WorkStation', fa: 'desktop', pane: '', display: 'none' })
            }
            if (d.authority === "Project") {
                //this.state.dashboard.push([{title:'Projects',fa:'connectdevelop',display:'none'}])
                objs.push({ title: 'Projects', fa: 'connectdevelop', display: 'none' })
                has_mobile = true
            }
            if (d.authority === "M&E") {
                objs.push({ title: 'M&E', fa: 'users', display: 'none' })
            }
            if (d.authority === "UMA") {
                objs.push({ title: 'UMA', fa: 'users', display: 'none' })
            }
            if (d.authority === "Professions") {
                objs.push({ title: 'Professions', fa: 'users', pane: '', display: 'none' })
            }
            if (d.authority === "Dashboard") {
                this.state.dashboard.push([{ title: 'Dashboard', fa: 'dashboard', display: 'none' }])
            }
            if (d.authority === "Record Management") {
                this.state.dashboard.push([{ title: 'Record Management', fa: 'folder', display: 'none' }])
            }
            if (d.authority === "Admin") {
                this.state.dashboard.push([{ title: 'Admin', fa: 'cogs', pane: '', display: 'none' }])
            }
            if (d.authority === "Actions") {
                sessionStorage.setItem("Action", "1")
            }
        })


        if (has_mobile === false) {
            try {
                MobileReadService.isMobileUser().then(r => {
                    try {
                        this.setState({
                            is_super_user: r.data.is_super_user
                        })
                        let roles = r.data.results;
                        if (roles.length > 0) {
                            objs.push({ title: 'Projects', fa: 'connectdevelop', display: 'none' })
                        }
                        sessionStorage.setItem("mob_roles", JSON.stringify(roles))
                    } catch (e) {
                        console.log(e)
                    }

                    this.setState({ dashboard: [objs] })

                    if (objs.length === 1) {
                        this.showDivisionAt(objs[0])
                    }

                })
            } catch (e) {
                console.log(e)
            }
        } else {

            this.setState({ dashboard: [objs] })

            if (objs.length === 1) {
                this.showDivisionAt(objs[0])
            }
        }


    }


    decodeToken() {






    }




    showDivisionAt(s) {
        switch (s.title) {
            case 'WorkStation':
                this.setState({
                    base_layout: <BaseLayout key={new Date().getMilliseconds()} />
                })
                //BaseLayout.show()
                break
            case 'Projects':
                this.setState({
                    base_layout: <MobileLayout key={new Date().getMilliseconds()} is_super_user={this.state.is_super_user} />
                })
                //BaseLayout.show()
                break
            case 'Dashboard':
                break
            case 'Professions':
                this.setState({
                    base_layout: <ProfessionLayout key={new Date().getMilliseconds()} is_super_user={this.state.is_super_user} />
                })
                break
            case 'M&E':
                //console.log(sessionStorage.getItem('token'))
                this.setState({
                    token: sessionStorage.getItem("token").split(" ")[1]
                })
                document.getElementById("mne").style.display = "inherit";
                //window.open('http://192.168.0.101:3000/?token=','new')
                break
            case 'Record Management':

                this.setState({
                    base_layout: <RecordManagement key={new Date().getMilliseconds()} />
                });
                break
            case 'UMA':
                this.setState({
                    base_layout: <Uma key={new Date().getMilliseconds()} />
                });
                break
            case 'Admin':
                AdminLayout.show();
                break;

            default:
                break
        }
    }

    static logout() {
        let tkn = sessionStorage.getItem('token');
        console.log("TOKEN", tkn);
        TxCreateService.invalidateToken(tkn).then(d => {
            console.log("token invalidated", tkn)
        });
        sessionStorage.clear();
        sessionStorage.setItem('noOfAttempt', 0);
        window.location.reload();
    }
    render() {
        return (
            <div>


                <div className={'container dashboard'}>
                    <div style={{ marginTop: '18vh', marginBottom: '1vh' }} className={'row'}>
                        <div className={'col col-sm-3'} />
                        <div style={{
                            boxShadow: ' 0 0 8px #cccccc', borderRadius: '4px', padding: '30px',
                            marginBottom: '2vh', background: '#fff'
                        }} className={'col col-sm-6'}>
                            <span>
                                <img alt={'logo_admn'} src={logo} style={{ height: '6vh', marginTop: '-16px', marginRight: '8px' }} />
                            </span>

                            <span style={{ fontSize: '28px' }}>
                                <b> Tanzania ILMIS</b>
                            </span>

                            <div style={{ fontSize: '18px' }}>
                                <span>Dodoma Region</span>
                                {/*{sessionStorage.getItem('username')==='isaya.mwakijule'?<div>

                                <button onClick={PopDetail.show("UPDATE TP",<TpSpatialUpdate/>)}>Upload</button>
                            </div>:''}*/}

                                {/*{sessionStorage.getItem('username')==='isaya.mwakijule'?<div>

                                <button onClick={PopDetail.show("UPDATE SPATIAL",<SpatialUpdating/>)}>Upload</button>
                            </div>:''}*/}

                                {/*{sessionStorage.getItem('username')==='isaya.mwakijule'?<div>

                                <button onClick={PopDetail.show("UPDATE BAUNIT LAST PAY DATE",)}>Upload</button>
                            </div>:''}*/}
                                {/*{sessionStorage.getItem('username')==='isaya.mwakijule'?<div>

                                <button onClick={PopDetail.show("UPDATE BAUNIT",<BaUnitUpdates/>)}>Upload</button>
                            </div>:''}*/}

                                {/*  {sessionStorage.getItem('username')==='isaya.mwakijule'?<div>

                                <button onClick={PopDetail.show("UPDATE BAUNIT SPATIAL",<BaUnitSpatialUpdates/>)}>Upload</button>
                            </div>:''}*/}
                                <br />
                                <span style={{ color: 'darkcyan', fontWeight: 'bold', cursor: 'pointer' }} onClick={sessionStorage.getItem('username') !== null ? () => PopDetail.show("Password Reset Request", <PasswordResetRequest user={sessionStorage.getItem('username')} />, 50) : () => { }}>{sessionStorage.getItem('username')}</span>
                                <span style={{ marginLeft: '16px', color: '#DF4A36', cursor: 'pointer', fontWeight: '900' }} onClick={Dashboard.logout}>[ Logout ]</span>
                            </div>
                        </div>
                    </div>
                    {this.state.dashboard.map((d, i) => {
                        return (<div className={'row d-flex justify-content-center'} style={{ marginBottom: '24px' }} key={'menu_row_' + i}>
                            {d.map((s, j) => {
                                return (<div className={'col col-sm-2'} key={'sub_menu_row_' + i + '_' + j}>
                                    <div className={'dashboard-menu '} onClick={() => this.showDivisionAt(s)}>
                                        <span className={'fa fa-' + s.fa} />
                                        <p>{s.title}</p>
                                    </div>
                                </div>)
                            })}
                        </div>)
                    })}
                </div>


                <div style={{ position: 'absolute', color: '#DF4A36', bottom: 6, width: '100%', textAlign: 'center', fontSize: '11px' }}>
                    Developed By MLHHSD - DiCT
                </div>
                {this.state.base_layout}
                {/*<AdminLayout title={'Admin Pane' http://154.118.227.28:7071/home}/>*/}
                <div id="mne" style={{ backgroundColor: "#fff", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 10, display: "none" }}>
                    {/*                <iframe title={'me'} src={"http://154.118.227.28:7071/home"} style={{
    width: "100vw",
    height: "100vh"
}}/>*/}
                </div>
            </div>)
    }
}
/*<iframe title={'me'} src={"http://154.118.227.28:7071/home" + sessionStorage.getItem("token").split(" ")[1]} style={{
    width: "100vw",
    height: "100vh"
}}/>*/
