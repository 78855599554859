import * as React from "react";
import Wait from "../../wait";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../mobile_popup";
import PopParcel from "./pane_pop_parcel";
import PopDetailsTwo from "../../PopDetailsTwo";
import MobileCreateService from "../../../../services/mobile/mobile_create";
export default class UserMobileData extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            parcels:[],
            current_tab:0,
            tabs:[
                {id:0,name:'Loading..',total:0}
            ]
        }

        this.loadParcels = this.loadParcels.bind(this)
        this.popUploadZip = this.popUploadZip.bind(this)
        this.onCoordChange = this.onCoordChange.bind(this)
        this.submitZip = this.submitZip.bind(this)
        this.loadDataAt = this.loadDataAt.bind(this)
    }

    onCoordChange = (e) => {e.preventDefault();this.setState({multidata:e.target.files[0]})}

    componentDidMount() {
        this.loadParcels()
    }

    loadParcels(locality_id=0){
        try {
            Wait.show("Loading User Data...")
            MobileReadService.readUserData(MobilePopup.id(),this.props.user.id).then(d=>{
                try {
                    this.setState({ tabs:d.data.results})

                    if(locality_id===0){
                        if (d.data.results.length>0){
                            locality_id = d.data.results[0].id
                        }
                    }

                    Wait.show("Loading Data...")
                    MobileReadService.readDataByProjectId(MobilePopup.id(),0,this.props.user.id,locality_id).then(r => {
                        Wait.dismiss()
                        try {

                            this.setState({
                                parcels:r.data.results
                            })

                        }catch (e) {
                            console.log(e)
                        }
                    })

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    popDataAt(d){
        PopDetailsTwo.show(d.claim_no,<PopParcel parcel={d}/>)
    }

    popUploadZip(){
        document.getElementById('row_upload').style.display='inherit'
    }

    submitZip(){
        if (this.state.multidata===undefined){
            alert('No Data')
            return
        }

        try {
            Wait.show('Uploading...')

            const formData = new FormData()
            formData.append('zipped',this.state.multidata)
            formData.append('sender',this.props.user.id)
            formData.append('project',MobilePopup.id())
            formData.append('extra',JSON.stringify({
                sender:this.props.user.id,
                project:MobilePopup.id()
            }))

            MobileCreateService.uploadZip(formData).then(r => {
                try {
                    Wait.dismiss()
                    /*if (r.data.status===0){
                        alert(r.data.message)
                        return
                    }*/
                    document.getElementById('row_upload').style.display='none'
                    this.loadParcels()
                }catch (e) {
                    console.log(e)
                }
            })

        }catch (e) {
            console.log(e)
        }
    }

    loadDataAt(tab,id){
        this.setState({current_tab:tab})
        this.loadParcels(id)
    }

    render() {
        return(<div  style={{width:'98%',height:'98%',padding:'2%'}}>

            <div className={'row'}>
                <div className={'col col-sm-10'} style={{paddingBottom:'8px',position:'stick'}}>
                    {this.state.tabs.map((d,i)=>{
                        return(<div key={i} className={this.state.current_tab===i?'tab tab-active':'tab'} onClick={()=>this.loadDataAt(i,d.id)}>
                            <b>{d.name}</b>
                            <span style={{float:'right'}}>{d.total}</span>
                        </div>)
                    })}
                </div>
                <div className={'col col-sm-2'}>
                    <button className={'submit'} style={{float:'right'}} onClick={()=>this.popUploadZip()}>Upload ZIP</button>
                </div>
            </div>

            <div className={'row'} id={'row_upload'} style={{display:'none'}}>
                <div className={'col col-sm-12'} style={{textAlign:'center', backgroundColor:'#ccc'}}>
                    <hr/>
                    <input type={'file'} onChange={this.onCoordChange} accept={".zip, .ZIP"}/>
                    <br/>
                    <br/>
                    <button className={'default'} onClick={()=>this.submitZip()}>Submit</button>
                    <button className={'default'} onClick={()=>document.getElementById('row_upload').style.display='none'}>Close</button>
                    <hr/>
                </div>
            </div>



            <div className={'row'} style={{height:'50vh',overflow:'auto'}}>
                <table style={{width:'100%',marginTop:'4px'}} className={'tb'}>
                    <thead>
                    <tr>
                        <th>CLAIM NO</th>
                        <th>OWNERS</th>
                        <th>OWNERSHIP</th>
                        <th>USE</th>
                        <th>NORTH</th>
                        <th>SOUTH</th>
                        <th>EAST</th>
                        <th>WEST</th>
                        <th>CLAIM DATE</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.parcels.map((d,i)=>{
                        return(<tr key={i}>
                            <td style={{color:'#008b8b'}} onClick={()=>this.popDataAt(d)}><b>{d.claim_no}</b></td>
                            <td><b>{d.owners}</b></td>
                            <td><b>{d.ownership}</b></td>
                            <td><b>{d.use}</b></td>
                            <td><b>{d.north}</b></td>
                            <td>{d.south}</td>
                            <td>{d.east}</td>
                            <td>{d.west}</td>
                            <td>{d.created_at}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>


        </div>)
    }
}