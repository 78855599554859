import * as React from "react";
import PopDetail from "../../popdetail";
import PanAddProjectUser from "../pops/pane_add_user";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../mobile_popup";
import Wait from "../../wait";
import PanAddProjectLayer from "../pops/pane_add_project_layer";
import UserMobileData from "../pops/pane_user_mobile_data";
import API from "../../../../services/API";
import MobileDeleteService from "../../../../services/mobile/mobile_delete";
import MobileUpdateService from "../../../../services/mobile/mobile_update";
import ReportCreateService from "../../../../services/common/reports/report_create";
export default class ProjectUsersPane extends React.Component{
    static self = null
    constructor(props) {
        super(props);

        this.state ={
            users:[],
            user:{
                id:0
            }
        }

        ProjectUsersPane.self = this
        this.searchUser = this.searchUser.bind(this)
    }

    componentDidMount() {
        ProjectUsersPane.loadData()
    }

    loadUserReports(){
        //let path = API.base_url+`mobile/create/users/report?project_id=${MobilePopup.id()}`
        //PopDetail.download(path)

        Wait.show('Downloading...')
        try {
            ReportCreateService.getExcel(`mobile/create/users/report?project_id=${MobilePopup.id()}`).then(r => {
                try{
                    Wait.dismiss()
                    const url = window.URL.createObjectURL(new Blob([r.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'User Report.xls'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    noHandler(){ PopDetail.dismiss() }

    uploadLayer(
        id=0){
        PopDetail.show('Upload Layer',<PanAddProjectLayer/>,60)
    }

    popAddUser(){

        if(MobilePopup.project().outputName==='Utambuzi'){
            PopDetail.show('Add Project User',<PanAddProjectUser layers={this.state.layers} user={this.state.user} edit={false}/>)
            return
        }


        // else if (project.outputName==='Utambuzi'){
        if (this.state.layers.length<1){
            PopDetail.confirm('No Project Layers','This Project Has no Layers, Do you want to Upload Now ?',this.uploadLayer,0,this.noHandler)
            return
        }
        PopDetail.show('Add Project User',<PanAddProjectUser layers={this.state.layers} user={this.state.user} edit={false}/>)
    }

    deleteUser(id){
        try {
            Wait.show('Deleting...')
            MobileDeleteService.deleteUser(id).then(r => {
                try {
                    Wait.dismiss()
                    PopDetail.dismiss()
                    ProjectUsersPane.loadData()
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
    resetUser(id){
        try {
            Wait.show('Resetting...')
            MobileUpdateService.resetUser(id).then(r => {
                try {
                    Wait.dismiss()
                    PopDetail.dismiss()
                    ProjectUsersPane.loadData()
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
    delete(d){
        PopDetail.confirm('Delete '+d.username,'Confirm delete account - '+d.name,this.deleteUser,d.id,this.noHandler)
    }

    reset(d){
        PopDetail.confirm('Reset '+d.username,'Confirm Reset account - '+d.name,this.resetUser,d.id,this.noHandler)
    }

    edit(d) {
        PopDetail.show('Edit '+d.name,<PanAddProjectUser layers={this.state.layers} user={d} edit={true}/>)
    }

    searchUser=(e)=>{
        let val = e.target.value
        if (val===''){
            val='all'
        }
        ProjectUsersPane.loadData(val)
    }
    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px',height:'96vh',background:'#fff',padding:'24px',border:'0.5px solid #efefef'}}>

                <div className={'col col-sm-12'} style={{height:'80vh',paddingRight:'4px',overflow:'auto'}}>
                    <div style={{height:'100%'}}>
                        <div style={{height:'40px'}} className={'row'}>
                            <div className={'col col-sm-2'} style={{lineHeight:'40px'}}>
                               <b>Project Users</b>
                            </div>
                            <div className={'col col-sm-7'}>
                                <input placeholder={'Search Here'}  style={{width:'100%'}} onChange={(e)=>this.searchUser(e)}/>
                            </div>

                            <div className={'col col-sm-3'} style={{lineHeight:'40px', textAlign:'right'}}>
                                <span onClick={()=>this.popAddUser()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'}/>New
                                </span>



                                <span onClick={()=>this.loadUserReports()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-file screen-action'}/>Report
                                </span>


                            </div>
                        </div>


                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #ddd'}}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>ID</th>
                                <th>FULL NAME</th>
                                <th>USERNAME</th>
                                <th>PHONE NUMBER</th>
                                <th>DESIGNATION</th>
                                <th>PROJECT LAYERS</th>
                                <th>TOTAL PARCELS</th>
                                <th>LAST UPLOAD</th>
                                <th>IMEI</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.users.map((d,i)=>{
                                return(<tr key={i}>
                                    <td>{(i+1)}.</td>
                                    <td onClick={()=>this.edit(d)} style={{color:'#008b8b'}}>
                                        {d.id}</td>
                                    <td onClick={()=>this.edit(d)} style={{color:'#008b8b'}}>
                                        {d.name}
                                    </td>
                                    <td>{d.username}</td>
                                    <td>{d.phone}</td>
                                    <td>{d.design?'Supervisor':'Mobile Data Entry'}</td>
                                    <td>{d.layer}</td>
                                    <td style={{textAlign:'center',color:'#008b8b'}} onClick={()=>PopDetail.show(d.name,<UserMobileData user={d}/>)}>
                                        <b>{d.total}</b>
                                    </td>
                                    <td>{d.last_date}</td>
                                    <td style={{color:'#DF4A36'}}>{d.imei}</td>
                                    <td style={{textAlign:'right'}}>
                                        <button style={{width:'50px'}} className={'default'} onClick={()=>this.edit(d)}>Edit</button>
                                        <button style={{width:'50px'}} className={'default'} onClick={()=>this.delete(d)}>Delete</button>
                                        <button style={{width:'50px'}}  onClick={()=>this.reset(d)}>Reset</button>
                                    </td>
                                </tr>)


                            })}
                            </tbody>
                        </table>


                    </div>
                </div>


            </div>


        </div>)
    }

    static setData(data){
        ProjectUsersPane.self.setState(data)
    }
    static loadData(search='all') {
        try {
            Wait.show("Loading Project Users...")
            MobileReadService.readUsers(MobilePopup.id(),search).then(r => {
                Wait.dismiss()
                try {
                    ProjectUsersPane.setData({
                        users:r.data.result
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }

        Wait.show("Loading Data...")
        try {
            let prj_id = MobilePopup.id()
            Wait.show("Please Wait...")
            MobileReadService.mobileLayers(prj_id).then(d => {
                Wait.dismiss()
                try {
                    console.log(d.data)
                    ProjectUsersPane.self.setState({
                        layers:d.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }


}