import * as React from "react";

import logo from '../../../../../resource/img/aa.gif';
export default class QueuePane extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            remainingTime:''
        }
    }

    componentDidMount() {
        setInterval(()=>{
            let now = new Date(),
                then = new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate()+1,
                    0,0,0),
                diff = then.getTime() - now.getTime(); // difference in milliseconds


            this.setState({
                remainingTime:this.msToTime(diff)
            })

        },1000)
    }

    msToTime(millisec) {
        /*let seconds = (ms / 1000).toFixed(1);
        let minutes = (ms / (1000 * 60)).toFixed(1);
        let hours = (ms / (1000 * 60 * 60)).toFixed(1);
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
        if (seconds < 60) return seconds + " Sec";
        else if (minutes < 60) return minutes + " Min";
        else if (hours < 24) return hours + " Hrs";
        else return days + " Days"*/

        var seconds = (millisec / 1000).toFixed(0);
        var minutes = Math.floor(seconds / 60);
        var hours = "";
        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            hours = (hours >= 10) ? hours : "0" + hours;
            minutes = minutes - (hours * 60);
            minutes = (minutes >= 10) ? minutes : "0" + minutes;
        }

        seconds = Math.floor(seconds % 60);
        seconds = (seconds >= 10) ? seconds : "0" + seconds;
        if (hours != "") {
            return hours + ":" + minutes + ":" + seconds;
        }
        return minutes + ":" + seconds;

    }

    render() {
        return( <div className={'row'} style={{marginBottom:'4px'}}>


            <div className={'col col-sm-12'} style={{height:'87vh',paddingRight:'4px'}}>
                <div style={{background:'#fff',padding:'20px',height:'100%',border:'0.5px solid #efefef'}}>
                    <span>Transaction On Queue....... <img src={logo} style={{height:'4vh'}}/></span>
                    <span style={{background:'#f00',padding:'4px 8px',border:'1px solid #ff0',marginLeft:'24px',fontWeight:900,color:'#fff'}}>
                        {this.state.remainingTime} </span>

                    <table className={'tb'} style={{width:'100%'}}>
                        <thead>
                        <tr>
                            <th>Parcel Number</th>
                            <th>Error Message</th>
                        </tr>
                        </thead>
                    </table>
                </div>

            </div>
        </div>)
    }
}