import * as React from "react";
import PrintDocumentPane from "../../transacrions/screen/print_doc";
import MobilePopup from "../mobile_popup";
import API from "../../../../services/API";
import PopDetail from "../../popdetail";
import Wait from "../../wait";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import ReportCreateService from "../../../../services/common/reports/report_create";


export default class MobileDocumentsPane extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            data: {
                listOfDoc: [
                    {docName:'Public Display',id:1},
                    //{docName:'Village Register Book'},
                    {docName:'Residential Licence',id:2},
                    //{docName:'Transaction Sheet'},
                    //{docName:'Adjudication Form'}
                ]
            },
            current_doc: {},
            districts:[],
            wards:[],
            streets:[],
            doc_frame: <PrintDocumentPane/>
        }

        this.showDocumentAt = this.showDocumentAt.bind(this)
        this.printReport = this.printReport.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.loadLocality = this.loadLocality.bind(this)
        this.loadWards = this.loadWards.bind(this)
        this.loadStreets = this.loadStreets.bind(this)
    }

    componentDidMount() {
        this.loadLocality()

        switch(MobilePopup.project().outputName){
            case 'Residential License':
                this.setState({
                    data: {
                        listOfDoc: [
                            {docName:'Public Display',id:1},
                            //{docName:'Village Register Book'},
                            {docName:'Residential Licence',id:2},
                            //{docName:'Transaction Sheet'},
                            //{docName:'Adjudication Form'}
                        ]
                    },
                })
                break
            case 'CCRO':
                this.setState({
                    data: {
                        listOfDoc: [
                            {docName:'Public Display',id:1},
                            //{docName:'Village Register Book'},
                            {docName:'CCRO',id:2},
                            {docName:'Transaction Sheet',id:3},
                            //{docName:'Adjudication Form',id:2},
                        ]
                    },
                })
                break
        }

    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }

    loadLocality() {
        try {
            Wait.show("Loading Localities...")
            MobileReadService.listLocality(MobilePopup.id()).then(r => {
                Wait.dismiss()
                try {
                    this.setState({
                        districts:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    loadWards(){
        let dist = document.getElementById('sp_dist').value
        try {
            Wait.show("Loading Wards...")
            MobileReadService.listWards(dist).then(r => {
                Wait.dismiss()
                try {
                    this.setState({
                        wards:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    loadStreets(){
        let ward = document.getElementById('sp_ward').value
        try {
            Wait.show("Loading Streets...")
            MobileReadService.listStreets(ward).then(r => {
                Wait.dismiss()
                try {

                    console.log(r.data.results)

                    this.setState({
                        streets:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }


    render() {
        return(<div className={'col col-sm-12'} style={{height:'94vh',paddingRight:'4px',paddingLeft:0}}>

            <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>

                <div className={'row'} style={{height:'97%'}}>
                    <div className={'col col-sm-3'} style={{padding:0}}>
                        <div style={{padding:'24px'}}>
                            <span  style={{marginBottom:'16px'}}>Select Document To Print</span>
                            {this.state.data.listOfDoc.map((d,i)=>{
                                return(<div key={i} style={{width:'100%',fontSize:'13px',cursor:'pointer'}} onClick={()=>this.showDocumentAt(d)}>

                                    <div>
                                        <input type={'radio'} name={'doc'} value={d.id} style={{marginRight:'16px'}} onChange={(e)=>this.handleChange(e)}/>
                                        <span className={'fa fa-file'} style={{marginRight:'8px'}}/>{d.docName}
                                    </div>
                                    <div>{d.tag}</div>
                                </div>)
                            })}

                            <hr/>

                            <div style={{marginTop:'16px'}}>Select District</div>
                            <div>
                                <select style={{width:'100%'}} onChange={this.loadWards} id={'sp_dist'} >
                                    <option value={0}>- Choose District -</option>
                                    {this.state.districts.map((d,i)=>{
                                        return(<option key={i} value={d.id}>{d.name}</option>)
                                    })}
                                </select>
                            </div>
                            <div style={{marginTop:'16px'}}>Select Village/Ward</div>
                            <div>
                                <select style={{width:'100%'}}  onChange={this.loadStreets} name={'ward_id'} id={'sp_ward'}>
                                    <option value={0}>- Choose Ward / Village -</option>
                                    {this.state.wards.map((d,i)=>{
                                        return(<option key={i} value={d.id}>{d.name}</option>)
                                    })}
                                </select>
                            </div>

                            <div style={{marginTop:'16px'}}>Select Hamlet/Street</div>
                            <div>
                                <select style={{width:'100%'}} name={'locality_id'} onChange={this.handleChange}>
                                    <option value={0}>- Choose Street / Hamlet -</option>
                                    {this.state.streets.map((d,i)=>{
                                        return(<option key={i} value={d.id}>{d.name}</option>)
                                    })}
                                </select>
                            </div>
                            <hr/>
                            <div style={{textAlign:"center"}}>
                                <button onClick={()=>this.printReport()}>Print</button>
                            </div>

                        </div>

                    </div>
                    <div className={'col col-sm-9'} style={{background:'#efefef'}}>
                        {this.state.doc_frame}
                    </div>
                </div>

            </div>
        </div>)
    }

    showDocumentAt(d) {
        console.log(d)
        this.setState({
            current_doc:d
        })
    }

    printReport() {
        let doc = this.state.doc

        if (doc===undefined){
            alert('Select Document')
            return
        }
        let param = {
            project_id: MobilePopup.id(),
            document_id: doc,
            hamlet_id: 1
        }

        if (doc<2){
            //let path = API.base_url+`mobile/create/report?project_id=${param.project_id}&document_id=${param.document_id}&hamlet_id=${param.hamlet_id}`
            //PopDetail.download(path)

            Wait.show('Downloading...')
            try {
                ReportCreateService.getExcel(`mobile/create/report?project_id=${param.project_id}&document_id=${param.document_id}&hamlet_id=${param.hamlet_id}`).then(r => {
                    try{
                        Wait.dismiss()
                        const url = window.URL.createObjectURL(new Blob([r.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Public Display.xls'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }catch (e) {
                        console.log(e)
                    }
                })
            }catch (e) {
                console.log(e)
            }
            return
        }

        Wait.show('Loading Output...')
        MobileCreateService.createOutput(param.project_id, param.document_id, param.hamlet_id).then(r  =>{
            try {
                Wait.dismiss()
                this.setState({
                    doc_frame: <PrintDocumentPane base64={r.data}/>
                })
            }catch (e) {
                console.log(e)
            }
        })

    }
}