import React from "react";
import PropertyTitle from "./propertyTitle";
import Conditions from "./conditions";
//import {BaUnitTitle} from "./source-target";
import PopDetail from "../../../popdetail";
import BaUnitTitle from "./baUnitTitle";

export default function ConditionsPage() {
    return(
        <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
            <BaUnitTitle/>
            <br/>
          <Conditions/>
        </div>
    )
}
