import * as React from "react";
import PropertyPane from "../screen/spatial-unit/propertyPane";
import Properties from "../screen/properties";
import Wait from "../../wait";
import TxCreateService from "../../../../services/transaction/transaction_create";
import PopDetail from "../../popdetail";
import TransactionPopup from "../transaction_popup";

export default class PaneSearchPropertyBatch extends React.Component {
    static self = null

    constructor(props) {
        super(props);


        this.state = {
            selectedProperties: [],
            landProperties: [],
            rent: [],
            showMenu: false,
            gepgMenu: false,
            base64: '',
            dataToDisplay: [],
            propertyPushedArray: [],
            tenure: '',
            titleNumber: '',
            titleStatus: 'Active',
            districtName:'',
            regionName:'',
            regPlanNumber:'',
            localityName: '',
            blockNumber: '',
            lotNumber: '',
            ldNumber: '',
            loNumber: '',
            rightHolder: '',
            registerFrom: '',
            registerTo: '',
            planNumber:'',
            allocationStatusName:'',
            landUseName:'',
            drawingNumber:'',
        }
        this.getProperties = this.getProperties.bind(this)
        this.onclickPushBaunitIds=this.onclickPushBaunitIds.bind(this)
        this.pustSelected = this.pustSelected.bind(this)
        PaneSearchPropertyBatch.self = this
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    componentDidMount() {
        this.setState({
            tx:TransactionPopup.self.state.tx,
            is_prop_search:TransactionPopup.self.state.tx.name==='Property Search'?'none':'inherit',
            is_prop_search_tr:TransactionPopup.self.state.tx.name==='Property Search'?'none':'table-cell',
        })
    }

    pushProperty(d) {

        PropertyPane.self.setState({
            property: d,
        })

    }
 render() {
        return (<div style={{height: '100%', overflow: 'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>

                <div className={'col col-sm-12'}>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td><span>Title Number</span></td>
                            <td><span>Tenure</span></td>
                            <td><span>Title Status</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width: '100%'}} name={"titleNumber"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={"tenure"} onChange={(e) => this.handleChange(e)}/>
                            </td>
                            <td><input style={{width: '100%'}} name={"titleStatus"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                        </tr>

                        <tr>
                            <td><span>Right Holder</span></td>
                            <td><span>LD Number</span></td>
                            <td><span>Estate Name</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width: '100%'}} name={"rightHolder"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={"ldNumber"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={""} onChange={(e) => this.handleChange(e)}/></td>
                        </tr>
                        <tr>
                            <td><span>Register From</span></td>
                            <td><span>Register To</span></td>
                            <td><span></span></td>
                        </tr>
                        <tr>
                            <td><input style={{width: '100%'}} name={"registerFrom"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={"registerTo"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td><span>Location</span></td>
                            <td><span>Block</span></td>
                            <td><span>Number</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width: '100%'}} name={"localityName"}
                                       onChange={(e) => this.handleChange(e)}/></td>
                            <td><input style={{width: '100%'}} name={"blockNumber"}
                                       onChange={(e) => this.handleChange(e)}/>
                            </td>
                            <td><input style={{width: '100%'}} name={"lotNumber"}
                                       onChange={(e) => this.handleChange(e)}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <br/>
                                <button style={{float: 'right'}} onClick={() => this.getProperties()}>
                                    <i className={'fa fa-search'} style={{marginRight:'8px'}}/>Search
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <br/>

                <div className={'col col-sm-12'} style={{marginTop:'24px', display:'none'}} id={'row_data'}>

                    <div  className={'col col-sm-12'}>
                        <table  className={'tb'} style={{width: '100%'}}>
                            <thead>
                            <tr><th><span>SELECT</span></th>
                                <th style={{display:this.state.is_prop_search_tr}}><span>BAUNITID</span></th>
                                <th style={{display:this.state.is_prop_search_tr}}><span>TITLE No.</span></th>
                                <th style={{display:this.state.is_prop_search_tr}}><span>RIGHT HOLDER</span></th>
                                <th><span>DISTRICT</span></th>
                                <th><span>LOCATION</span></th>
                                <th><span>BLOCK</span></th>
                                <th><span>NUMBER</span></th>
                                <th><span>USE</span></th>
                                <th><span>ALLOCATION STATUS</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.dataToDisplay.map((d, i) => {

                                return (<tr key={i}>
                                    <td><input id={'chk_batch_'+d.baunitId} type={'checkbox'} onClick={()=>this.onclickPushBaunitIds(d)}/></td>
                                    <td style={{display:this.state.is_prop_search_tr}}>{d.baunitId}</td>
                                    <td style={{display:this.state.is_prop_search_tr}}>{d.titleNumber}</td>
                                    <td style={{display:this.state.is_prop_search_tr}}>{d.party}</td>
                                    <td>{d.districtName}</td>
                                    <td>{d.localityName}</td>
                                    <td>{d.blockNumber}</td>
                                    <td>{d.lotNumber}</td>
                                    <td>{d.landUseName}</td>
                                    <td>{d.allocationStatusName}</td>

                                </tr>)

                            })
                            }

                            <tr>
                                <td colSpan={10}>
                                    <button style={{float: 'right',display:this.state.is_prop_search}} onClick={() => this.pustSelected(0)}>Save</button>
                                    <button className={'refresh'} style={{float: 'right',display:this.props.is_shotcut===true?'none':'inherit'}} onClick={() => this.pustSelected(1)}>View Spatial</button>
                                </td>

                            </tr>

                            </tbody>

                        </table>
                    </div>




                </div>


            </div>
        </div>)
    }


    getProperties() {
        try {
            Wait.show("Loading Property...")

            let data = {
                titleNumber: this.state.titleNumber,
                titleStatus: 'Active',
                regionName: this.state.regionName,
                districtName: this.state.districtName,
                localityName: this.state.localityName,
                blockNumber: this.state.blockNumber,
                lotNumber: this.state.lotNumber,
                regPlanNumber: this.state.regPlanNumber,
                loNumber: this.state.loNumber,
                ldNumber: this.state.ldNumber,
                rightHolder: this.state.rightHolder,
                registerFrom: this.state.registerFrom,
                registerTo: this.state.registerTo,
                allocationStatusName:this.state.allocationStatusName,
                drawingNumber:this.state.drawingNumber,
                landUseName:this.state.landUseName,
                planNumber:this.state.planNumber,
                transactionId:TransactionPopup.id()
            }
            TxCreateService.createGetProperties(data).then(r => {
                Wait.dismiss()
                try {
                    if (r.data.status===0){
                        document.getElementById('row_data').style.display='none'
                        return
                    }
                    console.log("PropertyLoaded", r)
                    this.setState({
                        dataToDisplay: r.data.result
                    })
                    document.getElementById('row_data').style.display='inherit'
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }


    onclickPushBaunitIds(p){
        let chk_batch = document.getElementById('chk_batch_'+p.baunitId).checked
        if (chk_batch){
            this.state.selectedProperties.push(p.baunitId)
        }else{
            let arr = []
            this.state.selectedProperties.map(d=>{
                if (d!==p.baunitId){
                    arr.push(d)
                }
            })
            this.setState({
                selectedProperties:arr
            })
        }
        console.log(this.state.selectedProperties)
        this.state.landProperties.push(p)
    }

    pustSelected(action){
        let selected = this.state.selectedProperties
        let land_prop = this.state.landProperties

        if (action===1 && selected.length===0){
            try {
                let arr = []
                this.state.dataToDisplay.map(d=>{
                    arr.push(d.baunitId)
                })
                selected = arr
                land_prop = this.state.dataToDisplay
            }catch (e) {

            }

        }



        Properties.self.setState({
            selectedProperties:selected,
            landProperties:land_prop
        })

        console.log(land_prop)

        Properties.loadData()
        PopDetail.dismiss()

    }
}

