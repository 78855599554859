import React, {Component} from 'react';
import Wait from "../../wait";
import SMDUpdateService from "../../../../services/smd/smd_update";
import wait from "../../wait";

class BaUnitUpdates extends Component {
    constructor(props) {
        super(props);
        this.state={
            csv:null,
            code:''
        }
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.upload = this.upload.bind(this)
    }

    handleChangeFile=e=>{
        this.setState({ [e.target.name]:e.target.files[0] })
    }

    handleChange=e=>{
        this.setState({ [e.target.name]:e.target.value})
    }

    upload(){
        Wait.show("Uploading...")
        const formData = new FormData()
        formData.append('spatial', this.state.csv)
        formData.append('code', this.state.code)
        console.log("CODE: ",formData.get('code'))
        SMDUpdateService.updateBasicDate(formData).then(d=>{
            if(d.data.status===1){
                wait.dismiss()
            }else {
                wait.dismiss()
            }

        })
    }

    render() {
        return (
            <div>
                <div><select value={this.state.code} name={'code'}  onChange={this.handleChange}>
                    <option value="LPDU">Last Pay Date Update</option>
                    <option value="LAUU">Legal Area Unit Update</option>
                    {/*<option value="ATA">UPDATE Legal Area</option>
                    <option value="BTB">UPDATE Block</option>
                    <option value="BL">UPDATE Block, Location</option>
                    <option value="UNIT">UPDATE Area Unit</option>
                    <option value="BLPN">UPDATE Block, Location ,Plan Number</option>
                    <option value="AUPN">UPDATE Area, Unit, Plan Number</option>
                    <option value="PN">UPDATE Plan Number</option>
                    <option value="Update Title">UPDATE Plan Number Or Reg Plan Number</option>*/}
                </select></div>
                <input type={'file'} name={'csv'}  accept={".csv,.CSV,.excel,.EXCEL"} onChange={this.handleChangeFile}/>
                <button onClick={this.upload}> UPLOAD</button>
            </div>
        );
    }
}

export default BaUnitUpdates;
