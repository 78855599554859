import * as React from 'react'
import CommonService from '../services/common/common_read';
import CommonCreateService from '../services/common/common_create';
import TxReadService from '../services/transaction/transaction_read';
import Select from 'react-select'
import MobilePopup from '../components/popups/mobile/mobile_popup';
import SMDReadService from '../services/smd/smd_read';
import TPReadService from '../services/tp/tp_read';
export default function DynamicForm({ inputs, code, txId, type, update, config, updateData }) {
    const onSubmit = (e) => {
        e.preventDefault();
        let form = document.forms.newilform;
        let formdata = new FormData(form)
        formdata.append("code", code);
        formdata.append("txId", txId);
        formdata.append("projectId", MobilePopup.id());
        CommonCreateService.saveForm(formdata);
        update({ s: 's' })
    }
    const [data, setData] = React.useState({});
    const onChange = (e) => {
    }
    const [elems, setElems] = React.useState(inputs);
    return (
        <form id="newilform" onSubmit={onSubmit}>
            <div className='row'>
                {
                    elems.map((prop, key) => (
                        <div className='col-12' key={key}>
                            {
                                <GetInput txId={txId} config={config} element={prop} onChanger={onChange} parent={prop.parentId} parentValue={prop.parentValue} />
                            }
                        </div>
                    ))
                }
            </div>
            <input className='btn mt-3' type='submit' value={'save'} />
        </form>
    )
}
function GetInput({ element, onChanger, parent, parentValue, config, txId }) {
    const [show, setShow] = React.useState(false);
    const [, forceUpdate] = React.useState();
    const [region, setRegion] = React.useState(0);
    const parentValueListener = (parent, parentValue) => {
        if (parent !== null && parent !== 0) {
            // alert(parent)    
            setInterval(() => {
                // alert(parentValue + " "+getFormValue(parent))
                setShow(getFormValue(parent) === parentValue)
                // forceUpdate({})
            }, 1000);
        }
        else {
            setShow(true);
        }
    }
    React.useEffect(() => {

        parentValueListener(parent, parentValue)
    }, []);
    const [n, g] = React.useState(0);
    const onChange = () => {
        // g(1)
        // onChanger()
    }
    const Elem = ({ elem, onChanged }) => {
        switch (elem.type) {
            case 'text':
                return <TextInput e={elem} onChange={onChanged} />
            case 'number':
                return <TextInput e={elem} onChange={onChanged} />
            case 'email':
                return <TextInput e={elem} onChange={onChanged} />
            case 'locality':
                return <LocalityInput setReg={setRegion} e={elem} onChange={onChanged} />
            case 'dropdown':
                return <SelectInput e={elem} onChange={onChanged} />
            case 'csv_file':
                return <FileInput accept={'.csv'} e={elem} onChange={onChanged} />
            case 'pdffile':
                return <FileInput e={elem} onChange={onChanged} accept={'.pdf'} />
            case 'shapefile':
                return <FileInput e={elem} onChange={onChanged} accept={'.zip'} />
            case 'imagefile':
                return <FileInput e={elem} onChange={onChanged} accept={'image'} />
            case 'administrative_office':
                return <AdminOffices e={elem} onChange={onChanged} config={config} />
            case 'textarea':
                return <textarea className='form-control' name={"g" + elem.id}></textarea>
            case 'choose_surveyor':
                return <SurveyorSelect e={elem} onChange={onChanged} type={"TPO"} config={config} />
            case 'choose_company':
                return <SurveyorSelect e={elem} onChange={onChanged} type={"CO"} config={config} />
            case 'tp_drawing_local':
                return <TPSelect e={elem} onChange={onChanged} />
            case 'control_point':
                return <ControlPoint />
            case 'checkbox':
                return <Checkboxx e={elem} onChange={onChanged} />
            case 'SurveyId':
                return <SpatialUnits txId={txId} e={elem} onChange={onChanged}/>
            default:
                return <div>{elem.type}</div>
        }

    }
    console.log(document.getElementById("g" + parent))
    return (<div>
        { }
        {show ? <div> <InputLabel name={element.name} required={element.required} /><Elem elem={element} onChanged={onChange} /></div> : null}
        {/* 
        {parent!==0 && getFormValue('g'+parent,g)===parentValue? <Elem elem={element} onChanged={onChange}/>:null}         */}
    </div>)
}

function FileInput({ accept, e, onChange }) {
    return <div>
        {/* <label><b>{e.name} </b></label> */}
        <input type='file' accept={accept} onChange={onChange} required={e.required} name={'g' + e.id} className='form-control mb-3' id={'g' + e.id} />
    </div>
}
function SpatialUnits({e,onChange, txId}){
    const [sp,setSp] = React.useState([]);
    React.useEffect(()=>{
        SMDReadService.readSpatialUnits(txId).then((r)=>{
            setSp(r.data);
        });
    },[]);
    return(
        <div className='row'>
        <div className='col-12'>
            <Select options={sp} isMulti name={"g"+e.id} style={{width:"100%"}}/>
        </div>
        </div>
    )
}
function AdminOffices({ e, config }) {
    console.log(config)
    const [offices, setOffices] = React.useState([]);
    React.useEffect(() => {
        TxReadService.getCouncilsByDistrictId(config.district).then(r => {
            if (r.data.status === 1) {
                let p = []
                r.data.result.map((prop) => {
                    p.push({ label: prop.councilName, value: prop.councilId });
                });
                setOffices(p);
            }
        }).catch(e => {
            console.log(e);
        })
    }, []);
    return (
        <Select name={"g" + e.id} options={offices} />
    )
}

function InputLabel({ name, required }) {
    return <div>
        <label><b>{name} {required ? <font style={{ color: "#f00" }}>*</font> : null}</b></label>
    </div>
}
function GetPreview({ type, data }) {
    switch (type) {
        case 'shapefile':
            // <FileInput accept={ } />
            break;
        case 'text':
            return <div>
                <label><b>{data.name}</b></label>
                <label>{data.value}</label>
            </div>
    }
}
function TextInput({ e, onChange }) {
    return (
        <input required={e.required} onChange={onChange} name={'g' + e.id} type={e.type} id={'g' + e.id} placeholder={e.name} className='form-control mb-3' />
    )
}

function SelectInput({ e, onChange, }) {
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        let b = []
        e.options.map((prop) => {
            b.push({ label: prop.name, value: prop.value })
        });
        setOptions(b);
    }, [])
    return <Select onChange={onChange} className='mb-3' name={'g' + e.id} options={options} />
}
function Checkboxx({e,onChange}){
    return(
        <div>
            <input type='checkbox' placeholder={e.name} name={'g'+e.id}/>
        </div>
    )

}
function ControlPoint({ e, onChange }) {
    const [m,setM] = React.useState(false);
    const [,forceUpdate] = React.useState();
    return (
        <div>

            <label>Control Point #1</label>
            <div className='row'>
                <div className='col-12'>
                    <input type='text' placeholder='Name' className='form-control mb-2' name='cname' required />
                </div>
                <div className='col-6'>
                    <input type='text' placeholder='X Coordinate' className='form-control mb-2' name='x' required />
                </div>
                <div className='col-6'>
                    <input type='text' placeholder='Y Coordinate' className='form-control mb-2' name='y' required />
                </div>
            </div>
            <label>Control Point #2</label>
            <div className='row'>
                <div className='col-12'>
                    <input type='text' placeholder='Name' className='form-control mb-2' name='cname1' required />
                </div>
                <div className='col-6'>
                    <input type='text' placeholder='X Coordinate' className='form-control mb-2' name='x1' required />
                </div>
                <div className='col-6'>
                    <input type='text' placeholder='Y Coordinate' className='form-control mb-2' name='y1' required />
                </div>
            </div>
            <label>Meaasured Point #2</label>
            <div className='row'>
                <div className='col-12'>
                    <input type='text' placeholder='Name' className='form-control mb-2' name='cname2' required />
                </div>
                <div className='col-6'>
                    <input type='text' placeholder='X Coordinate' className='form-control mb-2' name='x2' required />
                </div>
                <div className='col-6'>
                    <input type='text' placeholder='Y Coordinate' className='form-control mb-2' name='y2' required />
                </div>
                <div className='col-6'>
                    <select onChange={(e)=>{setM(e.target.value!=="gnss"); forceUpdate({})}} className='form-control' name="dev_type" required>
                        <option value={""}>Choose Measurement type</option>
                        <option value={"gnss"}>GNSS Observation</option>
                        <option value="Terrestrial">Terrestrial Measurements</option>
                    </select>
                </div>
                <div className='col-12'>
                    {m?<input type='text' placeholder='Distance' className='form-control mb-2' name='distance' required/>:null}
                </div>
            </div>
        </div>
    )
}
function TPSelect({ e, onChange, config }) {
    const [options, setOptions] = React.useState([]);
    const [regions, setRegions] = React.useState([])
    const [rid, setRegion] = React.useState(0)
    const getTp = () => {

    }
    React.useEffect(() => {
        TxReadService.getRegions().then(r => {
            let b = []
            r.data.result.map((prop) => {
                b.push({ label: prop.regionName, value: prop.regionId })
            });
            setRegions(b)

        })
    }, []);
    React.useEffect(() => {
        if (rid !== 0) {
            TPReadService.getTpByRegion(rid).then((r) => {
                let b = []
                r.data.map((prop) => {
                    b.push({ label: prop.tpDrawingNumber, value: prop.tpDrawingId })
                });
                setOptions(b);
            });
        }
        // TxReadService.getRegions().then(r => {
        //     setRegions(r.data.result)
        // })

        // SMDReadService.listProfessionals(type).then((r)=>{
        //     let b = []
        //     r.data.result.map((prop) => {
        //         b.push({ label: prop.name, value: prop.id })
        //     });
        //     setOptions(b);
        //     // console.log(r.data)
        // });
    }, [rid])
    return <div className='row'>
        <div className='col-md-4'>
            {/* <button type='button' onClick={getTp} className='btn btn-block btn-save'>Load TP Drawings</button> */}
            <Select onChange={e => { setRegion(e.value) }} options={regions} />
        </div>
        <div className='col-md-8'>
            <Select onChange={onChange} className='mb-3' name={'g' + e.id} options={options} />
        </div>
    </div>
}
function SurveyorSelect({ e, onChange, type }) {
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        SMDReadService.listProfessionals(type).then((r) => {
            let b = []
            r.data.result.map((prop) => {
                b.push({ label: prop.names, value: prop.id })
            });
            setOptions(b);
            // console.log(r.data)
        });
    }, [])
    return <Select onChange={onChange} className='mb-3' name={'g' + e.id} options={options} />
}

function LocalityInput({ e, onChange, setReg }) {
    const [regions, setRegions] = React.useState([])
    const [districts, setDistricts] = React.useState([])
    const [councils, setCouncils] = React.useState([])
    const [wards, setWards] = React.useState([])
    const [streets, setStreets] = React.useState([])
    const [hamlets, setHamlets] = React.useState([])
    const [locality, setLocality] = React.useState([])

    React.useEffect(() => {
        TxReadService.getRegions().then(r => {
            setRegions(r.data.result)
        })
    }, []);
    return (
        <div className='row'>
            <div className="col-md-4">
                <label><b>Region</b><br /></label>
                <select required id='regionId' name="regionId" onChange={(e) => {
                    TxReadService.getDistrictsByRegId(e.target.value).then((r) => {
                        if (r.data.status === 1) {
                            setDistricts(r.data.result)
                        }
                    })
                }} className="form-control mb-3" placeholder="Region">
                    <option value={""}>Choose region</option>
                    {regions.map((prop, key) => (
                        <option value={prop.regionId}>{prop.regionName}</option>
                    ))}
                </select>
            </div>
            <div className="col-md-4">
                <label><b>District</b><br /></label>
                <select required onChange={(e) => { TxReadService.getCouncilsByDistrictId(e.target.value).then((r) => { if (r.data.status === 1) { setCouncils(r.data.result) } }) }} name="districtId" className="form-control mb-3" placeholder="District">
                    <option value={""}>Choose district</option>
                    {districts.map((prop) => (
                        <option value={prop.id}>{prop.names}</option>
                    ))}
                </select>
            </div>
            <div className="col-md-4">
                <label><b>Council</b><br /></label>
                <select required onChange={(e) => {
                    TxReadService.getWardsByCouncilId(e.target.value).then(r => { if (r.data.status === 1) { setWards(r.data.result) } });
                    TxReadService.getLocalitiesByCouncilId(e.target.value).then(r => { if (r.data.status === 1) { setLocality(r.data.result) } });
                }} name="councilId" className="form-control mb-3" placeholder="District">
                    <option value={""}>Choose Council</option>
                    {councils.map((prop) => (
                        <option value={prop.councilId}>{prop.councilName}</option>
                    ))}
                </select>
            </div>

            <div className="col-md-4">
                <label><b>Ward</b><br /></label>
                <select required name="wardId" onChange={(e) => { TxReadService.getSubwardsByWardId(e.target.value).then((r) => { if (r.data.status === 1) { setStreets(r.data.result) } }) }} className="form-control mb-3" placeholder="Ward">
                    <option value={""}>Choose ward</option>
                    {wards.map((prop) => (
                        <option value={prop.wardId}>{prop.wardName}</option>
                    ))}
                </select>
            </div>
            <div className="col-md-4">
                <label><b>Village/Mtaa</b><br /></label>

                <select required name="subWardId" className="form-control mb-3" placeholder="Street" onChange={(e) => {
                    TxReadService.getHamletsByWardId(e.target.value).then((r) => {
                        if (r.data.status === 1) { setHamlets(r.data.result) }
                    })
                }}>
                    <option value={""}>Choose Village/Mtaa/</option>
                    {streets.map((prop) => (
                        <option value={prop.subWardId}>{prop.name}</option>
                    ))}
                </select>
            </div>
            <div className="col-md-4">
                <label><b>Hamlet</b><br /></label>

                <select name="hamletId" className="form-control mb-3" placeholder="Hamlet">
                    <option value={""}>Choose Hamlet/</option>
                    {hamlets.map((prop) => (
                        <option value={prop.hamletId}>{prop.hamletName}</option>
                    ))}
                </select>
            </div>
            <div className="col-md-4">
                <label><b>Locality</b><br /></label>

                <select required name="localityId" className="form-control mb-3" placeholder="Locality">
                    <option value={""}>Choose Locality/</option>
                    {locality.map((prop) => (
                        <option value={prop.localityId}>{prop.name}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}
function getFormValue(name) {
    var doo = document.forms.newilform
    var d = new FormData(doo)
    var object = {};
    d.forEach(function (value, key) {
        object[key] = value;
    });
    return object['g' + name];
}

function hide(id) {
    document.getElementById('g' + id).style.display = "none";
}