import React, {Component} from 'react';
import TxReadService from "../../../../services/transaction/transaction_read";
import PopDetails from "../../../../componentss/popdetails";

class TaskHistory extends Component {
    constructor(props) {
        super(props);

        this.state={
            tasks:[],
            transaction:this.props.transaction
        }
    }
    componentDidMount() {

        TxReadService.getTaskHistory(this.state.transaction.transactionId).then(d=>{
            this.setState({tasks:d.data.result})
        })
    }

    render() {
        return (
            <div className={'row'}>
                <div className={'col col-sm-12'} >
                <table width={"100%"} className={'tb'}>
                    <thead>
                    <th>Task</th>
                    <th>Owner</th>
                    <th>Date</th>
                    </thead>
                    <tbody>

                    {this.state.tasks.map((task,i)=>{

                        return(<tr key={i}>
                            <td>{task.taskName}</td>
                            <td>{task.taskOwner}</td>
                            <td>{task.lodgingDatetime}</td>
                        </tr>)
                    })}
                    </tbody>

                </table>
                    <button onClick={()=>PopDetails.dismiss()}>Close</button>
            </div>
            </div>
        );
    }
}

export default TaskHistory;
