import React from "react";
import TransactionPopup from "../transaction_popup";
import PopDetail from "../../popdetail";
import PaneApplyRtuApproval from "../pops/pane_apply_rtu_approval";
import CLReadService from "../../../../services/cl/cl_read";
import PaneApplyLadApproval from "../pops/pane_apply_lad_approval";

class LadCLApproval  extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            decision:'Accepted',
            clSigningOfficer:'',
            applyBtn:<div></div>
        }
        this.handleChange =this.handleChange.bind(this)
    }
    componentDidMount() {

        CLReadService.getCLApproval(TransactionPopup.id()).then(d=>{
            if(d.data.status===1){
                this.setState({decision:d.data.result.rejectionType,clSigningOfficer:d.data.result.username})
            }
        })
        TransactionPopup.task()==='CL Approve Certificate of Occupancy'?
                this.setState({applyBtn:<span><span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'} onClick={()=>PopDetail.show('Apply Approve ',<PaneApplyLadApproval decision={this.state.decision}/>,40)}/> Apply</span>}):
            TransactionPopup.task()==='CL Approve Rectification'?
                this.setState({applyBtn:<span><span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'} onClick={()=>PopDetail.show('Apply Approve ',<PaneApplyLadApproval decision={this.state.decision}/>,40)}/> Apply</span>}):
            TransactionPopup.task()==='CL Approve Certificate'?
                this.setState({applyBtn:<span><span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'} onClick={()=>PopDetail.show('Apply Approve ',<PaneApplyLadApproval decision={this.state.decision}/>,40)}/> Apply</span>}):<></>

    }

    handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }
    render() {
        return(<div>
            <div className={'row'}>
                <div className={'col-sm-4'}><h3>LAD Registration Approval</h3></div>
                <div className={'col-sm-2'}>
                    <span> <span style={{color:'red'}}>*</span>Final LAD Decision</span>
                    <select defaultValue={this.state.decision} onClick={this.handleChange}>
                        <option>Accepted</option>
                        <option>Rejected</option>
                    </select>
                </div>
                <div className={'col-sm-2'}>

                    <span>LAD Signing Officer</span>
                    <input name={'clSigningOfficer'} value={sessionStorage.getItem('username')}/>
                </div>
                <div className={'col-sm-4'} style={{float:'left'}}>
                <span>
                <span style={{marginLeft:'16px'}} className={'fa fa-check-circle-o screen-action'} onClick={this.validateTask} />Validate
                </span>
                    {this.state.applyBtn}
                    <span><span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'} onClick={()=>PopDetail.show('Apply Approve',<PaneApplyLadApproval decision={this.state.decision}/>,70)}/> Apply</span>

                </div>

            </div>
        </div>)
    }
}
export default LadCLApproval
