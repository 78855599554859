import * as React from "react";
import PopDetail from "../../../popdetail";

import * as LF from "leaflet";
import "leaflet.utm";


import AddValuationBlockPane from "../add/add_valuation_block";
import Wait from "../../../wait";
import ValReadService from "../../../../../services/valuation/val_read";
import uncheck from "../../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../../resource/img/checkbox/checkbox_checked_16.png";
import AddValuationRateCSVPane from "../add/add_valuation_rate_csv";
import UgSpatial from "../../../../maps/ug_spatial";
import TransactionPopup from "../../transaction_popup";
import Basemap from "../../../../maps/basemap";
import API from "../../../../../services/API";
import ValCreateService from "../../../../../services/valuation/val_create";

export default class ValBlockSpatialUnitPane extends React.Component{
    static self = null
    static vMap = null
    constructor(props) {
        super(props);
        this.state = {
            blocks:[],
            rates:[],
            region:'',
            district:'',
            council:'',
            locality:'',
            status:'',
            vblock_id:'',
            id:0,
            blockId:'',
            block_status:'all',
            is_receive:true
        }

        ValBlockSpatialUnitPane.self = this
        this.toggleCheck = this.toggleCheck.bind(this)
        this.uploadRates = this.uploadRates.bind(this);
        this.filterByStatus = this.filterByStatus.bind(this)
    }

    componentDidMount() {
        try {
            this.setState({
                is_receive:TransactionPopup.task().startsWith('Receive')
            })
        }catch (e) {
            console.log(e)
        }
        ValBlockSpatialUnitPane.loadData()
    }

    static loadData(){


        ValBlockSpatialUnitPane.self.setState({
            region:'',
            district:'',
            council:'',
            locality:'',
            status:'',
            vblock_id:'',
            id:0,
            blockId:'',
        })

        Wait.show("Loading Valuation Blocks...")
        ValReadService.listBlocks(TransactionPopup.id()).then(r=>{
            try {
                Wait.dismiss()
                ValBlockSpatialUnitPane.self.setState({blocks:r.data})

                UgSpatial.clear('ramani')
                let ly_group = new LF.FeatureGroup();
                let sat = Basemap.googleSat(),
                    hybrid = Basemap.googleHybrid(),
                    blank = LF.tileLayer('')

                let map = LF.map('map', {
                    layers: [blank, ly_group],
                    attributionControl: false,
                    fullscreenControl: true,
                    maxZoom:10000
                }).setView([-6.085936, 35.711995], 4)

                let ly_vb = UgSpatial.getLayerByName('valuation_block','transaction_id !='+TransactionPopup.id()).addTo(map)


                let baseLayers = {
                    "<span style='color: gray'>Blank</span>": blank,
                    "Satellite": sat,
                    "Hybrid": hybrid
                }

                let filter = 'transaction_id='+TransactionPopup.id()
                let layername = 'valuation_block'
                let selected

                if (ValBlockSpatialUnitPane.self.state.block_status!=='all'){
                    filter+=' and status=\''+ValBlockSpatialUnitPane.self.state.block_status+'\''
                }

                Wait.show("Loading Valuation Blocks Spatial...")
                API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                    Wait.dismiss()
                    try {
                        r.data.features.forEach(p=>{

                            let myLayer = LF.geoJSON(p,{
                                onEachFeature:(f,l)=>{
                                    LF.marker(l.getBounds().getCenter(),{
                                        icon:LF.divIcon({
                                            className:'bindTooltipBig',
                                            html:f.properties.vblock_id,
                                            iconSize:[100,40]
                                        })
                                    }).addTo(ly_group)
                                    l.setStyle({
                                        color:f.properties.status==='Approved'?'#00ff00':'#901'
                                    })
                                },
                                coordsToLatLng: function (coords) {
                                    return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();
                                },
                                weight:3,zIndex:2, fillOpacity: 0
                            }).on('dblclick',e=>{

                                ValBlockSpatialUnitPane.self.openRatesAt({
                                    id:ValBlockSpatialUnitPane.self.state.id,
                                    blockId:ValBlockSpatialUnitPane.self.state.vblock_id,
                                    status:ValBlockSpatialUnitPane.self.state.status,
                                })

                            }).on('click', function (e) {

                                if (selected) {e.target.resetStyle(selected)}
                                selected = e.layer

                                ValBlockSpatialUnitPane.self.setState({
                                    region:p.properties.region,
                                    district:p.properties.district,
                                    council:p.properties.council,
                                    locality:p.properties.locality,
                                    status:p.properties.status,
                                    vblock_id:p.properties.vblock_id,
                                    id:p.id.split('.')[1],
                                    blockId:p.properties.blockId
                                })
                                selected.bringToFront()
                                selected.setStyle({color: '#000', weight:4})
                                map.fitBounds(selected.getBounds())

                            })
                            myLayer.addTo(ly_group)
                        })

                        let myLayer = LF.geoJSON(r.data,{coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();}})
                        try{ map.fitBounds(myLayer.getBounds())}catch (e) { }

                    }catch (e){
                        console.log(e)
                    }

                })
                let overlays = {
                    'Others Valuation Blocks':ly_vb,
                    'Current Layer':ly_group,
                }

                UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)

            }catch (e){
                console.log(e)
            }
        })
    }



    static open = false

    toggleCheck(i) {
        let doc = document.getElementById('chk_' + i);
        if (doc.src === uncheck) {
            doc.src = check;
            return;
        }
        doc.src = uncheck;

    }

    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        <p  style={{display:this.state.id===0?'inherit':'none'}}>Click on block to view Details</p>
                        <table className={'tb'}  style={{visibility:this.state.id!==0?'visible':'hidden',width:'100%'}}>
                            <thead>
                            <tr>
                                <th style={{textAlign:'center'}} colSpan={2}>{this.state.vblock_id}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr><td>Block Code</td><td>{this.state.vblock_id}</td></tr>
                            <tr><td>Region</td><td>{this.state.region}</td></tr>
                            <tr><td>District</td><td>{this.state.district}</td></tr>
                            <tr><td>Council</td><td>{this.state.council}</td></tr>
                            <tr><td>Locality</td><td>{this.state.locality}</td></tr>
                            <tr><td>Status</td><td>{this.state.status}</td></tr>
                            <tr>
                                <td/>
                                <td>
                                    <button style={{display:this.state.id!==0?'inherit':'none'}} onClick={()=>this.openRatesAt({
                                        id:this.state.id,
                                        blockId:this.state.vblock_id,
                                        status:this.state.status
                                    })}>View Rates</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className={'col col-sm-9'} style={{height:'60vh',background:'#fff',paddingLeft:'4px'}}>

                    <div className={'row'} style={{padding:'4px'}}>

                        <div className={'col col-sm-2'}>
                            <input placeholder={'Search By VBlock ID.......'} style={{width:'100%'}}/>
                        </div>

                        <div  className={'col col-sm-2'}>
                            <select style={{width:'100%'}} name='opt_status' onChange={(e)=>this.filterByStatus(e)}>
                                <option value={'all'}>- All -</option>
                                <option>Approved</option>
                                <option>Not Approved</option>
                            </select>
                        </div>
                        <div  className={'col col-sm-2'}>
                            <button>Search</button>
                        </div>

                        <div className={'col col-sm-6'} style={{textAlign:'right'}}>
                                <span style={{cursor:'pointer'}} onClick={()=>ValBlockSpatialUnitPane.loadData()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-refresh screen-action'}/>Refresh
                                </span>
                            <span style={{cursor:'pointer',visibility:this.state.is_receive?'visible':'hidden'}} onClick={()=>this.uploadSpatial()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-upload screen-action'}/>Upload Spatial
                                </span>

                            <span style={{cursor:'pointer',visibility:this.state.is_receive?'visible':'hidden'}} onClick={()=>this.uploadRates()}>
                                    <span style={{marginLeft:'16px'}} className={'fa fa-upload screen-action'}/>Upload Rates
                                </span>
                        </div>

                    </div>

                    <div className={'col col-sm-12'} style={{height:'100%',padding:'4px'}}>
                        <div style={{height:'92%',border:'1px solid #ccc',padding:0}} id={'ramani'}>

                        </div>

                    </div>
                </div>

            </div>
            <div className={'row'} >
                <div className={'col col-sm-12'} style={{paddingRight:'4px',height:'27vh'}}>
                    <div style={{background:'#fff',padding:'4px',overflow:"auto",height:'100%',border:'0.5px solid #efefef'}}>
                        <table style={{width:'100%'}} className={'tb'}>
                            <thead>
                            <tr>
                                <th style={{textAlign:'left'}}>
                                    <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                                </th>
                                <th>BLOCK CODE</th>
                                <th>REGION</th>
                                <th>COUNCIL</th>
                                <th>LOCALITY</th>
                                <th>STATUS</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.blocks.map((d,i)=>{
                                return(<tr key={i}>
                                    <td style={{textAlign:"left"}}>
                                        <img id={'chk_'+(i+1)} alt={'chk_'+(i+1)} src={uncheck} onClick={()=>this.toggleCheck(i+1)}/>
                                    </td>
                                    <td style={{color:'#DF4A36'}} onClick={()=>this.openRatesAt(d)}>{d.blockId}</td>
                                    <td>{d.district}</td>
                                    <td>{d.council}</td>
                                    <td>{d.locality}</td>
                                    <td>{d.status}</td>

                                </tr>)
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>



        </div>)
    }


    noHandler(){
        PopDetail.dismiss()
        ValBlockSpatialUnitPane.open = false
    }

    uploadSpatial(){
        PopDetail.show('Upload Spatial', <AddValuationBlockPane/>,48)
    }

    approveBlock(bid,approval){
        try {
            Wait.show('Approving...')
            ValCreateService.approveBlock({
                vid: bid,
                approval: approval
            }).then(r  =>{
                Wait.dismiss()
                try {
                    console.log(r)
                    PopDetail.dismiss()
                    ValBlockSpatialUnitPane.loadData()
                }catch (e){
                    console.log(e)
                }
            })
        }catch (e){
            console.log(e)
        }
    }

    openRatesAt(block) {
        try {
            Wait.show("Loading "+block.blockId+" Valuation Rates...")
            ValReadService.getValuationRates(block.id).then(d=>{
                Wait.dismiss()
                try {
                    console.log(d.data.rates)
                    ValBlockSpatialUnitPane.self.setState({
                        rates:d.data.rates
                    })

                    if(d.data.rates.length<1){
                        Wait.dismiss()
                        PopDetail.confirm('No Valuation Rates',block.blockId+' has no valuation rates, Do you want to Upload ?',this.uploadRates,0,this.noHandler)
                        return
                    }

                    let pane = <div className={'col col-sm-12'} style={{background:'#fff',paddingLeft:'4px',height:'100%'}}>
                        <div style={{padding:'4px',overflow:"auto",height:'60vh',border:'1px solid #888'}}>

                            <table className={'tb'} style={{width:'100%'}}>
                                <thead>
                                <tr style={{fontSize:'18px'}}>
                                    <th>USE</th>
                                    <th>YEAR</th>
                                    <th>LAND VALUE</th>
                                    <th>RATE</th>
                                    <th>UNIT</th>
                                    <th>STATUS</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.rates.map((d,i)=>{
                                    return(<tr key={i}>
                                        <td>{d.use}</td>
                                        <td>{d.year}</td>
                                        <td>{d.landValue}</td>
                                        <td>{d.rate}</td>
                                        <td>{d.unitOfMeasure}</td>
                                        <td>{d.status}</td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                        <button style={{float:'right',marginTop:'8px'}} onClick={()=>this.approveBlock(block.id,0)}>Reject</button>
                        <button style={{float:'right',marginTop:'8px',visibility:block.status==='Approved'?'hidden':'visible'}} className={'submit'} onClick={()=>this.approveBlock(block.id,1)}>Approve</button>
                    </div>
                    PopDetail.show('Valuation Rates - '+block.blockId,pane)
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    uploadRates(id=0) {
        ValBlockSpatialUnitPane.open = false

        if (this.state.blocks.length<1){
            PopDetail.confirm('No Valuation Blocks','This Transaction has no Valuation Blocks, Do you want to upload ?',this.uploadSpatial,0,this.noHandler)
            return
        }

        PopDetail.show('Upload Rates', <AddValuationRateCSVPane blocks={this.state.blocks}/>,26)
    }

    filterByStatus(e) {
        this.setState({
            block_status:e.target.value
        })

        ValBlockSpatialUnitPane.loadData()
    }
}