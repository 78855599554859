import * as React from "react";
import PopDetail from "../../../popdetail";
import PaneSearchPropertyGro from "../../pops/pane_search_property_gro";
import {EditPropertyTitle} from "./editPropertyTitle";
import TxReadService from "../../../../../services/transaction/transaction_read";
import RTReadService from "../../../../../services/rt/rt_read";
import TransactionPopup from "../../transaction_popup";
import TxCreateService from "../../../../../services/transaction/transaction_create";
export default class PropertyGRO extends React.Component{
    static self = null
    constructor(props) {
        super(props);

        this.state={
            property:{},
            rent:[],
            spatial:[],
            baunit:{}
        }
       // this.loadData = this.loadData.bind(this)
        PropertyGRO.self = this
        this.removeSpatial =this.removeSpatial.bind(this)
        this.removeBaunitFromTx =this.removeBaunitFromTx.bind(this)
    }

    componentDidMount() {
        TxReadService.getSpatialUnitTransaction(TransactionPopup.id()).then(d=>{
            console.log("DATABI==>"+d.data)
            if(d.data.status===1) {
                this.setState({spatial: d.data.result})
            }
        })

        TxReadService.getBaunitTransaction(TransactionPopup.id()).then(d=>{
            if(d.data.status===1) {
                console.log("BAUNIT ==>> >",d)
                this.setState({baunit: d.data.result})

            }
        })
    }

    render() {
        function removeContent() {
            return undefined;
        }

        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-12'} style={{height:'94vh',paddingRight:'4px'}}>
                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b style={{float: 'left'}}>Properties/Titles</b>
                            <div style={{float: 'left'}}>
                               {/* <span onClick={()=>PopDetail.show('New Property/Title', <NewPropertyTitle baunitId={this.state.baunitId}/>)}>
                                    <span style={{marginLeft: '16px'}} className={'fa fa-plus-circle screen-action'}
                                    />New
                                </span>*/
                               }
                                      {
                                    TransactionPopup.transaction().name === 'Replace Title Certificate' ?
                                        <span>
                                        <span style={{marginLeft: '16px'}} className={'fa fa-plus screen-action'}
                                              onClick={() => PopDetail.show('Add Title Number',  <EditPropertyTitle/>,40)}/>Add Title Number
                                        </span>
                                        : ''
                                      }

                                {/* <span>
                                <span style={{marginLeft: '16px'}} className={'fa fa-trash screen-action'}
                                      onClick={() => removeContent()}/>Remove
                                </span>*/}
                            </div>
                            <div style={{float:'right'}}>
                                <span style={{marginLeft:'16px',cursor:'pointer'}} onClick={()=>PopDetail.show('Search Properties',<PaneSearchPropertyGro/>)}>
                                    <span className={'fa fa-search screen-action'}/>Property Search
                                </span>
                            </div>
                        </div>
                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>
                            <tr><th>ACTION</th>
                                <th>TITLE NUMBER</th>
                                <th>TYPE</th>
                                <th>TENURE</th>
                                <th>LD NUMBER</th>
                                <th>TOTAL AREA</th>
                                <th>LAND RENT</th>
                                <th>TOTAL VALUE</th>
                                <th>VERSION</th>
                                <th>STATUS</th>
                                <th>REGISTRATION NO.</th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr><td><span  onClick={()=>this.removeBaunitFromTx(this.state.baunit.baUnitId)}>
                                        <i
                                            className={'fa fa-trash'}
                                            style={{color:'tomato', fontSize:'25px'}}
                                        />
                                    </span></td>
                                <td>{this.state.baunit.titleNumber}</td>
                                <td>{this.state.baunit.typeName}</td>
                                <td>{this.state.baunit.tenureTypeName}</td>
                                <td>{this.state.baunit.ldNumber}</td>
                                <td>{this.state.baunit.legalArea}</td>
                                <td>{this.state.baunit.landRate}</td>
                                <td>{this.state.baunit.totalValue}</td>
                                <td>{this.state.baunit.version}</td>
                                <td>{this.state.baunit.baunitStatus}</td>
                                <td>{this.state.baunit.registrationNo}</td>
                                <td></td>
                                </tr>


                            </tbody>
                        </table>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Spatial Units</b>


                        </div>
                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>
                            <tr>
                                <th>ACTION</th>
                                <th>DISTRICT</th>
                                <th>LOCATION</th>
                                <th>BLOCK</th>
                                <th>LOT NUMBER</th>
                                <th>PLAN NUMBER</th>
                                <th>USE</th>
                                <th>AREA</th>
                                <th>UNIT</th>
                                <th>DRAWING NUMBER</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/*{console.log(this.state.spatial)}*/}
                            {this.state.spatial.map((d,i)=>{
                                return(<tr key={i}>
                                    <td><span  onClick={()=>this.removeSpatial(d.spatialUnitId)}>
                                        <i
                                            className={'fa fa-trash'}
                                            style={{color:'tomato', fontSize:'25px'}}
                                        />
                                    </span></td>
                                    <td>{d.districtName}</td>
                                    <td>{d.localityName}</td>
                                    <td>{d.blockNumber}</td>
                                    <td>{d.lotNumber}</td>
                                    <td>{d.planNumber}</td>
                                    <td>{d.landUseName}</td>
                                    <td>{d.legalArea}</td>
                                    <td>{d.areaUnitName}</td>
                                    <td>{d.drawingNumber}</td>
                                </tr>)
                            })}

                            </tbody>
                        </table>

                    </div>
                </div>

            </div>

        </div>)
    }


loadData(id){
        try {
           // Wait.show('Loading...')
           // RTReadService.getVacantSpatial().then(r => {
            RTReadService.getBaunit(id).then(r => {
               // Wait.dismiss()
                try {
                    console.log("BAUNIT=====>",r.data.result)
                    this.setState({
                        baunit:r.data.result
                    })
                    console.log(r.data.result)
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
    removeSpatial(id){
        let spatialToRemove={
            spatialUnitId:id,
            transactionId:TransactionPopup.id()
        }
        console.log("SPATIAL==>REMOVE",spatialToRemove)
        TxCreateService.removeSpatialUnit(spatialToRemove).then(r=>{
            if (r.data.status===1){
                this.setState({spatial:r.data.result})

            }
        })


        let dataToRemove={
            baunitId:this.state.baunit.baunitId,
            transactionId:TransactionPopup.id()
        }
        console.log("BAUNIT===>",dataToRemove)
        TxCreateService.removeBaunit(dataToRemove).then(r=>{
            if(r.data.status===1){
                this.setState({baunit:{}})
            }
        })



    }

    removeBaunitFromTx(id){
        console.log("BAUNIT OBJ===>",this.state.baunit)
        let dataToRemove={
            baunitId:id,
            transactionId:TransactionPopup.id()
        }
        console.log("BAUNIT===>",dataToRemove)
        TxCreateService.removeBaunit(dataToRemove).then(r=>{
            if(r.data.status===1){
                this.setState({baunit:{}})
            }
        })
    }
}
