import React, {useState, useEffect} from 'react'
import Header from "./gro/header";
import PopDetail from "../../popdetail";
import GeneralSelect from "../../../../utils/GeneralSelect";
import {useForm} from "react-hook-form";
import {today} from "../../../../utils/today";
import API from "../../../../services/API";
import TransactionPopup from "../transaction_popup";

const Item =() =>{
    return(
        <></>
    )
}
export const ItemHistory =({data = null}) =>{
    return(
        <table className={"tb"} style={styles.table}>
            <thead>
             <tr>
                 <th>ITEM ID</th>
                 <th>ITEM NUMBER</th>
                 <th>CURRENT LOCATION</th>
                 <th>FROM</th>
                 <th>TO</th>
             </tr>
            </thead>
            <tbody>
            {
                data ? data.map(({itemId, itemNo, currentLocation, from, to },i)=>
                <tr>
                    <td>{itemId}</td>
                    <td>{itemNo}</td>
                    <td>{currentLocation}</td>
                    <td>{from}</td>
                    <td>{to}</td>
                </tr>
                ):<tr><td colSpan={5} style={{textAlign:"center"}}>No History Yet</td></tr>
            }
            </tbody>
        </table>
    )
}
export const ItemRequest =({requests = null}) =>{
    return(
        <table className={"tb"} style={styles.table}>
            <thead>
            <tr>
                <th>REQUEST ID</th>
                <th>ITEM TYPE</th>
                <th>ITEM NUMBER</th>
                <th>DATE REQUESTED</th>
                <th>REQUESTED BY</th>
                <th>ITEM ID</th>
                <th>CURRENT LOCATION</th>
                <th>STATUS</th>
                <th>TRANSACTION</th>
            </tr>
            </thead>
            <tbody>
            {
                requests ? requests.map(({requestId, itemType, itemNumber,dateRequested,
                                  requestedBy,itemId,currentLocation,status,transactionNo },i)=>
                    <tr key={i}>
                        <td>{requestId}</td>
                        <td>{itemType}</td>
                        <td>{itemNumber}</td>
                        <td>{dateRequested}</td>
                        <td>{requestedBy}</td>
                        <td>{itemId}</td>
                        <td>{currentLocation}</td>
                        <td>{status}</td>
                        <td>{transactionNo}</td>
                    </tr>
                ):<tr><td colSpan={9} style={{textAlign:"center"}}>No data Yet</td></tr>
            }
            </tbody>
        </table>
    )
}

const RemoveNotes =()=>{
    return(
        <>remove...</>
    )
}
const Note =({ data=null, register = null})=>{
   /* const preSubmit =datas =>{
        if(data){
            const{commentId} = data;
            submit({...datas, commentId})
        }
        else{
            submit(datas)
        }
    }*/
    return(
        <div style={{padding:'.5rem'}}>
        <table style={styles.table}>
            <tbody>
            <tr>
                <td>Comment Text</td>
            </tr>
            <tr>
                <td>
                    <textarea
                        rows={5}
                        style={{resize:'none',...styles.table}}
                        name={"comment"}
                        defaultValue={data?data.comment:''}
                        ref={register?register({required:true}):register}/>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    )
}
const NewNote =()=>{
    const{register, handleSubmit} = useForm()

    const submit = data =>{
        console.log("data ",data)
    }
    return(
        <form onSubmit={handleSubmit(submit)}>
            <Note register={register}/>
            <button type={"submit"}>Save</button>
        </form>
    )

}

const EditNote = data =>{
    const{register, handleSubmit} = useForm()

    const submit = data =>{
        console.log("data ",data)
    }
    return(
        <form onSubmit={handleSubmit(submit)}>
            <Note register={register} data={data}/>
            <button type={"submit"}>Update</button>
        </form>
    )
}
const Notes =({data =null})=>{
    const[notes, notesSet]=useState([])
    const[index, indexSet] = useState(-1);
    useEffect(()=>{
        if(data !== null && data.status === 1){
            notesSet( prev => ([...prev,...data.result]))
        }
    },[data])
    const submit = data =>{
        console.log(data)
    }
    return(
        <>
            <Header display={true} label={"Notes"}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("New Notes",<NewNote submit={submit}/>,40)}}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={index>-1?()=>{PopDetail.show("Edit Notes",<EditNote submit={submit}/>,40)}:()=>{}}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={index>-1?()=>{PopDetail.show("Cancel Request",<RemoveNotes/>)}:()=>{}}
                    /><strong> Remove</strong>
                </div>
            </Header>
            <div style={{...styles.content,height:'300px'}}>
                <table className={'tb'} style={styles.table}>
                    <thead>
                    <tr>
                        <th>DATE ADDED</th>
                        <th>COMMENT</th>
                        <th>USER</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        notes.map(({dateAdded, comment, user},i)=>
                        <tr key={i} onClick={()=>indexSet(i)}>
                            <td>{dateAdded}</td>
                            <td>{comment}</td>
                            <td>{user}</td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>

        </>
    )
}

const Request = ({isNew, data, register=null, required, requestedFromFunc = null}) =>{
    const options ={
        'endpoint':'user/departments',
        'value':'departmentId',
        'label':'department',
        'placeholder':'Select Department'
    };
    const[user, userSet] = useState("");
    const[department, departmentSet] = useState("");
    const[requestedFrom, requestedFromSet] = useState("");
    const[itemType, itemTypeSet] = useState("");
    const[itemNo, itemNumberSet] = useState("");
    const[itemId, itemIdSet] = useState("");
    const generalChange = e =>{requestedFromSet(e.label); requestedFromFunc(e.label)}
    useEffect(() => {
        if(isNew){
            userSet(sessionStorage.getItem("username"))
            departmentSet(sessionStorage.getItem("department"))
        }
    }, [isNew]);
    useEffect(()=>{
        if(itemNo.trim().length > 0 && requestedFrom.trim().length > 0 && itemType.trim().length > 0 ){

            const data ={itemNo,requestedFrom,itemType}
            API.ax.post("/rts/item-id",data)
                .then(d=>{
                    const{status,result} = d.data
                    if(status === 1){
                        itemIdSet(result)
                    }
                })
        }
    },[itemType,requestedFrom,itemNo])

    return(
        <table style={styles.table}>
            <tbody>
            <tr>
                <td><span>Item Type</span></td>
                <td><span>Request Id</span></td>
            </tr>
            <tr>
                {
                    isNew?
                     <td>
                         {
                           isNew ?  <select  name={"itemType"}ref={register?register({required:required}):register} style={styles.table} onChange={e => itemTypeSet(e.target.value)}>
                             <option/>
                             <option value={"LAD Folder"}>LAD Folder</option>
                             <option value={"SMD Folder"}>SMD Folder</option>
                             <option value={"Title Folder"}>Title Folder</option>
                             </select>
                               :<input type={"text"} defaultValue={data?data.itemType:''} readOnly={true}/>
                         }
                     </td>
                    :<td><input type={"text"} defaultValue={ data?data.itemType:''} style={styles.table} readOnly={true} /></td>}
                <td><input type={"text"} defaultValue={!isNew && data?data.requestId:''} style={styles.table} name={"requestId"} readOnly={true} ref={register}/></td>
            </tr>
            <tr>
                <td><span>Item Number</span></td>
                <td><span>Status</span></td>
            </tr>
            <tr>
                <td><input type={"text"} defaultValue={!isNew && data?data.itemNo:itemNo} style={styles.table} name={"itemNo"} readOnly={!isNew} ref={register?register({required:required}):register} onChange={e=>itemNumberSet(e.target.value)}/></td>
                <td><input type={"text"} defaultValue={!isNew && data?data.status:'New'} style={styles.table} name={"status"} readOnly={true} ref={register?register({required:required}):register}/></td>
            </tr>
            <tr>
                <td><span>Item ID</span></td>
                <td><span>Date Requested</span></td>
            </tr>
            <tr>
                <td><input type={"text"} value={!isNew && data?data.itemId:itemId} style={styles.table} name={"itemId"}  readOnly={true}ref={register?register({required:required}):register} onChange={e=>itemIdSet(e.target.value)}/></td>

                    <td><input type={"text"} defaultValue={!isNew && data?data.dateRequested:today()} style={styles.table}  readOnly={true} name={"dateRequested"} ref={register?register({required:required}):register}/></td>

            </tr>
            <tr>
                <td><span>Requested From</span></td>
                <td><span>External Request</span></td>
            </tr>
            <tr>
                {
                    !isNew? <td><input type={"text"} defaultValue={data?data.requestedFrom:''} style={styles.table} name={"requestedFrom"} readOnly={true}/></td>
                          :<td><GeneralSelect parameters={options} onChanges={generalChange}/></td>
                }
                <td><input type={"checkbox"} checked={!isNew && data?data.external:false} name={"external"} onChange={()=>{}}  readOnly={true} ref={register}/></td>
            </tr>
            <tr>
                <td><span>Requested To</span></td>
                <td><span>Notes</span></td>
            </tr>
            <tr>
                <td><input type={"text"} defaultValue={!isNew && data?data.requestedTo:department} style={styles.table}  name={"requestedTo"}  readOnly={true} ref={register?register({required:required}):register}/></td>
                <td rowSpan={4}><textarea defaultValue={!isNew && data?data.requestId:''} style={{...styles.table,resize:'none'}} rows={4} name={"note"}  ref={register} readOnly={!isNew}/></td>
            </tr>
            <tr>
                <td><span>Requested By</span></td>
            </tr>
            <tr>
                <td><input type={"text"} defaultValue={!isNew && data?data.requestId:user} style={styles.table} name={"requestedBy"}  readOnly={true} ref={register}/></td>
            </tr>
            </tbody>
        </table>
    )
}
const ReceiveItem = () =>{
    return(
        <>
            <table style={styles.table}>
                <tbody>
                    <tr>
                        <td>Item ID</td>
                    </tr>
                    <tr>
                        <td><input type={"text"} style={styles.table}/></td>
                    </tr>
                    <tr>
                        <td>Item Number</td>
                    </tr>
                    <tr>
                        <td><input type={"text"} style={styles.table}/></td>
                    </tr>
                    <tr>
                        <td>Received By Department</td>
                    </tr>
                    <tr>
                        <td><input type={"text"} style={styles.table}/></td>
                    </tr>
                </tbody>
            </table>
        </>
    )}
const CancelRequest = () =>{
    return(
        <></>
    )}
const EditRequest = ({data}) =>{
    return(
        <></>
    )}
const NewRequest = ({isNew = false}) =>{
    const { register, handleSubmit } = useForm();
    const[requestedFrom,requestedFromSet]= useState("")
    const submit = data =>{
        console.log("data to send here ",{...data,requestedFrom,transactionId:TransactionPopup.id()})
        //PopDetail.dismiss()
    }
    const requestedFromFunc = data =>requestedFromSet(data)
    return(
        <form onSubmit={handleSubmit(submit)}>
        <Request isNew={isNew} register={register} required={true} requestedFromFunc={requestedFromFunc}/>
        <button type={"submit"}>Save</button>
        </form>
    )
}
export default function RecordRequest({writable = true}){
    const[currentMenu, currentMnuSet] = useState(0)
    const[requests,requestsSet]=useState([])
    const[index,indexSet] = useState(-1)
    const[request,requestSet] = useState({})
    const[notes,notesSet] = useState([])
    const[itemHistory,itemHistorySet] = useState([])
    const submit = data =>{}
    useEffect(()=>{
        try{
            const transactionId =TransactionPopup.id();
            API.ax.get(`/rts/request/${transactionId}`)
                .then(d=>{
                    const{result,status} = d.data;
                    if(status === 1){
                        requestsSet(result)
                    }
                })
        }
        catch (e) {
            console.error(e)
        }
    },[])
    useEffect(()=>{
        if(index > -1){
            let notes = requests[index].notes
            const{requestId,itemType,itemNo,status,itemId,dateRequested,requestedFrom,external,requestedTo,note,requestedBy} = requests[index]
            const general ={requestId,itemType,itemNo,status,itemId,dateRequested,requestedFrom,external,requestedTo,note,requestedBy}
            const history = requests[index].itemHistory
            notesSet(notes)
            requestSet(general)
            itemHistorySet(history)
        }
    },[index])
    return(
        <div style={styles.main}>
            <Header display={writable} label={"Requests"}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("New Request",<NewRequest submit={submit} isNew={true}/>)}}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("Edit Request",<EditRequest/>)}}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("Cancel Request",<CancelRequest/>)}}
                    /><strong> Cancel</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-file'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("Receive Request",<ReceiveItem/>, 50)}}
                    /><strong> Receive Item</strong>
                </div>
            </Header>
            <div style={styles.content}>
                <table className={'tb'} style={styles.table}>
                    <thead>
                        <tr>
                            <th>REQUEST ID</th>
                            <th>ITEM TYPE</th>
                            <th>ITEM NUMBER</th>
                            <th>DATE REQUESTED</th>
                            <th>REQUESTED BY</th>
                            <th>ITEM ID</th>
                            <th>CURRENT LOCATION</th>
                            <th>STATUS</th>
                            <th>TRANSACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                         requests.map(({requestId, itemType, itemNo,dateRequested,
                                           requestedBy,itemId,currentLocation,status,transactionNo },i)=>
                            <tr key={i} onClick={()=>indexSet(i)}>
                                <td>{requestId}</td>
                                <td>{itemType}</td>
                                <td>{itemNo}</td>
                                <td>{dateRequested}</td>
                                <td>{requestedBy}</td>
                                <td>{itemId}</td>
                                <td>{currentLocation}</td>
                                <td>{status}</td>
                                <td>{transactionNo}</td>
                            </tr>
                            )
                        }
                    </tbody>
                    <tbody/>
                </table>
            </div>
            <div className={"row"} style={styles.detailsHeader}>
                <label style={currentMenu === 0?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMnuSet(0)}}>General</label>
                <label style={currentMenu === 1?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMnuSet(1)}}>Notes</label>
                <label style={currentMenu === 2?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMnuSet(2)}}>Item</label>
                <label style={currentMenu === 3?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMnuSet(3)}}>Item History</label>
                <label style={currentMenu === 4?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMnuSet(4)}}>Item Requests</label>
            </div>
            <div style={currentMenu === 0?styles.show:styles.hide}>
                <Request isNew={false} data={request}/>
            </div>
            <div style={currentMenu === 1?styles.show:styles.hide}>
                <Notes data={notes}/>
            </div>
            <div style={currentMenu === 2?styles.show:styles.hide}>
                <Item/>
            </div>
            <div style={currentMenu === 3?styles.show:styles.hide}>
                <ItemHistory data={itemHistory}/>
            </div>
            <div style={currentMenu === 4?styles.show:styles.hide}>
                <ItemRequest data={requests}/>
            </div>
        </div>
    )
}

export const styles ={
    container:{
        border:'0.5px solid #efefef',
        backgroundColor:'#fff'
    },
    main:{
        width:'100%',
        background:'#fff',
        height:'94vh',
    },
    table:{
        width:'100%',
    },
    content:{
        width:'100%',
        height:'200px',
        overflow:'auto',
        border:'1px solid lightgray',
    },
    detailsHeader:{
        float:'left',
        gap:'5rem',
        margin:0,
        marginTop:'.5rem'
    },
    headerLabels:{
        cursor:'pointer',
    },
    headerLabelsHighlight:{
        cursor:'pointer',
        borderTop:'4px solid tomato'
    },
    hide:{
        display:'none',
    },
    show:{
        display:'inherit',
    },
    txArea:{
        resize:'none'
    },
    selectorStyles : {
        option: (styles, { isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#DF4A3622" : null,
                color:'black'
            };
        },
        control: ( base ) => ({
            ...base,
            "&:hover": {
                borderColor: "tomato"
            },
            borderColor: "grey",
            boxShadow: 'none'

        })
    },
    highlight:{
        fontWeight:'bold',
        cursor:'pointer'
    },
    unHighlight:{
        cursor:'pointer'
    },
    phases:{
        width:'100%',
        background:'#fff',
        height:'45vh',
    }
}