import React from 'react'
import PopDetail from "../../../../../componentss/popdetail";
import TxCreateService from "../../../../../services/transaction/transaction_create";
import TransactionPopup from "../../transaction_popup";
import LadcheckListRead from "../../../../../services/checklist/ladcheckListRead";
import Wait from "../../../wait";



 class LADChecklist extends React.Component{
     constructor(props) {
         super(props);
         this.state={
             questionAnswer:[],
             showQuestion:true,
             questions:[],
             questionOne:'',
             answerOne:'',
             commentOne:'',
             questionTwo:'',
             answerTwo:'',
             commentTwo:'',
             questionThree:'',
             answerThree:'',
             commentThree:'',
             questionFour:'',
             answerFour:'',
             commentFour:'',
             questionFive:'',
             answerFive:'',
             commentFive:'',
             questionSix:'',
             answerSix:'',
             commentSix:'',
             questionSeven:'',
             answerSeven:'',
             commentSeven:'',
             questionEight:'',
             answerEight:'',
             commentEight:''


         }

         this.handleChange =this.handleChange.bind(this)
         this.saveCheckList =this.saveCheckList.bind(this)
     }

     componentDidMount() {
         let ladCheck={
             transactionId:TransactionPopup.id(),
             transactionName:TransactionPopup.transaction().name
         }
         LadcheckListRead.ladCheckListQuestions(TransactionPopup.transaction().name).then(d=>{
             if(d.data.status===1){
                 console.log("QUESTIONS==>",d.data)
                 this.setState({questions:d.data.result})
             }else {

             }
         })

         LadcheckListRead.ladCheckList(TransactionPopup.id()).then(d=>{
             if(d.data.status===1){
            console.log("CheckLIST from Db ==>",d.data.result)
                 this.setState({questionAnswer:d.data.result,showQuestion:false})
             }
         })

     }

     handleChange (e){
         let name=e.target.name;
         console.log("name",e.target.name)
        console.log("change",e.target.value)
         this.setState({[e.target.name]:e.target.value})
    }
    render() {

         console.log("QUESTIONS===>",this.state.questions)
     return(
        <div className={"container"} style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
        <div style={{ border:"1px solid"}}>
          <table className={"table"}>
                    <thead>
                    <tr>
                        <th style={{width:'100px'}} className={"col"}>ANSWER</th>
                        <th  className={"col"}>QUESTION</th>
                        <th  className={"col"}>COMMENTS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.showQuestion?this.state.questions.map((d,i)=>{
                        let questionNo="question"+d.order

                        return(<tr key={i}>
                                <td>
                                    <select name={"answer"+d.order} onChange={this.handleChange}>
                                        <option value={"na"}>N/A</option>
                                        <option value={"no"}>No</option>
                                        <option value={"yes"}>Yes</option>
                                    </select>
                                </td>
                                <td>{d.value}</td>
                                <td>
                                    <input name={"comment"+d.order} onChange={this.handleChange}/>
                                </td></tr>)
                    }) :this.state.questionAnswer.map((d,i)=>{

                            return(<tr key={i}>
                            <td >
                            <select name={"checklistAnswer"} value={d.checklistAnswer}>
                            <option value={"na"}>N/A</option>
                            <option value={"no"}>No</option>
                            <option value={"yes"}>Yes</option>
                            </select>
                            </td>
                            <td>{d.checklistQuestion}</td>
                            <td >
                            <input name={"checklistComment"} onChange={this.handleChange} value={d.checklistComment}/>
                            </td>
                            </tr>)

                        })}
            </tbody>
        </table>
     </div>
        <div className={"form-group"}>
            <label>LAD Remarks</label>
            <textarea style={{resize:'none'}} className={"form-control"} cols={2} rows={2} name={"remarks"} />
        </div>
            {TransactionPopup.task()?TransactionPopup.task().includes("Enter Data") ?
                <div style={{textAlign: 'right', width: '100%'}}>
                    <button style={{width: '100px'}} onClick={this.saveCheckList}>Save</button>
                </div>:TransactionPopup.task().includes("CL Initiate Rectification") ?
                    <div style={{textAlign: 'right', width: '100%'}}>
                        <button style={{width: '100px'}} onClick={this.saveCheckList}>Save</button>
                    </div>:'':''
            }
        {/*<PopDetail/>*/}
        </div>
    )
    }
    saveCheckList(){
        let dataCheckList=[]
         this.state.questions.map(d=>{

             switch (d.order) {

                 case"One":
                     dataCheckList.push({
                         checklistQuestion:d.value,
                         checklistAnswer: this.state.answerOne,
                         checklistComment: this.state.commentOne,
                         transactionId:TransactionPopup.id(),
                         code:'LAD'
                     })
                     break
                 case"Two":
                     dataCheckList.push( {
                         checklistQuestion:d.value,
                         checklistAnswer: this.state.answerTwo,
                         checklistComment: this.state.commentTwo,
                         transactionId:TransactionPopup.id(),
                         code:'LAD'
                     })
                     break
                 case"Three":
                     dataCheckList.push({
                         checklistQuestion:d.value,
                         checklistAnswer: this.state.answerThree,
                         checklistComment: this.state.commentThree,
                         transactionId:TransactionPopup.id(),
                         code:'LAD'
                     })
                     break
                 case"Four":
                     dataCheckList.push({
                         checklistQuestion:d.value,
                         checklistAnswer: this.state.answerFour,
                         checklistComment: this.state.commentFour,
                         transactionId:TransactionPopup.id(),
                         code:'LAD'
                     })
                     break
                 case"Five":
                     dataCheckList.push( {
                         checklistQuestion:d.value,
                         checklistAnswer: this.state.answerFive,
                         checklistComment: this.state.commentFive,
                         transactionId:TransactionPopup.id(),
                         code:'LAD'
                     })
                     break
                 case"Six":
                     dataCheckList.push({
                     checklistQuestion:d.value,
                         checklistAnswer: this.state.answerSix,
                         checklistComment: this.state.commentSix,
                         transactionId:TransactionPopup.id(),
                         code:'LAD'
                 })
                     break
                 case"Seven":
                     dataCheckList.push({
                         checklistQuestion:d.value,
                         checklistAnswer: this.state.answerSeven,
                         checklistComment: this.state.commentSeven,
                         transactionId:TransactionPopup.id(),
                         code:'LAD'
                     })
                     break
                 case"Eight":
                     dataCheckList.push({
                         checklistQuestion:d.value,
                         checklistAnswer: this.state.answerEight,
                         checklistComment: this.state.commentEight,
                         transactionId:TransactionPopup.id(),
                         code:'LAD'
                     })
                     break
                 default:
                     break

             }



         })
        /*
          let dataCheckList=[{
             checklistQuestion:this.state.questionOne,
             checklistAnswer: this.state.answerOne,
             checklistComment: this.state.commentOne,
             transactionId:TransactionPopup.id()
         }, {
             checklistQuestion:this.state.questionTwo,
             checklistAnswer: this.state.answerTwo,
             checklistComment: this.state.commentTwo,
             transactionId:TransactionPopup.id()
         }, {
             checklistQuestion: this.state.questionThree,
             checklistAnswer: this.state.answerThree,
             checklistComment: this.state.commentThree,
             transactionId:TransactionPopup.id()
         },{
             checklistQuestion:this.state.questionFour,
             checklistAnswer:this.state.answerFour,
             checklistComment:this.state.commentFour,
             transactionId:TransactionPopup.id()
         }]*/
        console.log("checkList===>",dataCheckList)
        Wait.show('Saving...')
        TxCreateService.saveLadCheckList({dataCheckList}).then(d=>{
            if(d.data.status===1){
                Wait.dismiss()
                //PopDetail.show("STATUS",<span>Saved Successful!</span>,20)
            }
        })
    }

}
export default LADChecklist
