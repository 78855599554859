import * as React from "react";
import Wait from "../../../wait";
import SMDUpdateService from "../../../../../services/smd/smd_update";

export default class LegacyOccupancyInfo extends  React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            owners:[{}],
            page_status:'Loading...',
            allocation:''
        }

        LegacyOccupancyInfo.self = this
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.update = this.update.bind(this)

    }

    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}
    handleChangeFile=e=>{this.setState({ [e.target.name]:e.target.files[0] })}


    componentDidMount() {
        console.log(this.props.parcel)
        LegacyOccupancyInfo.loadData()
    }



    static loadData(){
        LegacyOccupancyInfo.self.setState({
            page_status:''
        })
    }
    update(){
        if (this.state.allocation===''){return}
        Wait.show('Updating...')
        SMDUpdateService.update({
            parcels: this.props.parcel,
            column: 'allocation',
            value: this.state.allocation
        }).then(r  =>{
            Wait.dismiss()
        })
    }
    render() {
        return(<div className={'col col-sm-12'} style={{height:'90%',width:'100%',overflow:'auto'}}>
            <p>{this.state.page_status}</p>
            <table style={{width:'100%',marginTop:'24px'}}>
                <tbody>
                <tr style={{color:'darkcyan'}}>
                   {/* <td>LAD File No</td>
                    <td>Number of Pages</td>*/}
                    <td>Allocation Status</td>
                </tr>
                <tr>
                   {/* <td>
                        <input type={'text'} name={'ladFileNo'} style={{width:'100%'}} defaultValue={this.state.ladFileNo} onChange={this.handleChange}/>
                    </td>
                    <td>
                        <input type={'number'} name={'pageNo'} style={{width:'100%'}} defaultValue={this.state.pageNo} onChange={this.handleChange}/>
                    </td>*/}
                    <td>
                        <select style={{width:'100%'}} name={'allocation'} defaultValue={this.state.allocation} onChange={this.handleChange}>
                            <option value={''}>- Select One -</option>
                            <option>Allocated</option>
                            <option>Converted</option>
                        </select>
                    </td>
                </tr>
                </tbody>
            </table>
            <hr/>
            <button className={'refresh'} onClick={this.update}>update</button>
        </div>)
    }

}