import * as React from "react";
import TransactionPopup from "../../../popups/transacrions/transaction_popup";
import Wait from "../../../popups/wait";
import TransactionTable from "../../../tables/tb_transaction";
import CommonService from "../../../../services/common/common_read";
import MobileReadService from "../../../../services/mobile/mobile_read";
import VillageConfig from "./village_config";
import HamletConfig from "./hamlet_config";
import ProjectUsersConfig from "./users_config";
import QuestionnairesConfig from "./questionnares";
import DataCollection from "./data_collection";
import ProjectPane from "../project_pane";
import BlockConfig from "./block_config";
import PopDetail from "../../../popups/popdetail";
import Message from "../../../popups/transacrions/screen/gro/person/message";

export default class AboutProject extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            output:[],
            project_info:{},
            category:0
        }

        this.loadProjectInfo = this.loadProjectInfo.bind(this)
        this.loadOutput = this.loadOutput.bind(this)
    }

    componentDidMount() {
        if (this.props.data !== null){
            document.getElementById('name').value = this.props.data.ld_no
            document.getElementById('description').value = this.props.data.title
            this.loadProjectInfo()
        }

        this.loadOutput()

    }

    saveProject(){
        let name = document.getElementById('name').value.trim();
        let description = document.getElementById('description').value.trim();
        let output = document.getElementById('output').value.trim();

        if (name.length<1){ PopDetail.show("Warning",<Message faIcon={'harzad'} message={'No Project Name'}/>,30);return }
        if (description.length<1){ PopDetail.show("Warning",<Message faIcon={'harzad'} message={'No Project Description'}/>,30);return }
        if (output.length<1){ PopDetail.show("Warning",<Message faIcon={'harzad'} message={'No Project Output'}/>,30);return }


        try {
            Wait.show("Saving...")
            MobileReadService.createProject({
                name:name,
                description:description,
                output:output
            }).then(result=>{
                console.log(result.data)
                Wait.dismiss()
                TransactionPopup.dismiss()
                TransactionTable.loadData()
            })
        }catch (e) {
            console.log(e)
        }


    }

    render() {
        return(<div style={{overflow:'hidden',height:'100%',marginRight:'16px'}}>

            <div className={'row'} style={{boxShadow:'0 4px 4px #aaa',padding:'8px 16px',backgroundColor:'#efefef'}}>
                <div className={'col col-sm-8'}>
                </div>
                <div className={'col col-sm-4 fa-btn'}>
                    <button style={{float:'right',width:'80px'}} onClick={()=>TransactionPopup.dismiss()}>Close</button>
                </div>
            </div>

            <table style={{width:'60%',margin:'14vh 15%'}}>
                <tbody>
                <tr>
                    <td><label>Project Name : </label></td>
                    <td><input style={{width:'100%'}} type={'text'} id={'name'}/></td>
                </tr>
                <tr>
                    <td style={{verticalAlign:'top'}}><label>Description : </label></td>
                    <td>
                        <textarea style={{width:'100%'}} id={'description'}/>
                    </td>
                </tr>

                <tr>
                    <td style={{verticalAlign:'top'}}><label>Project Output : </label></td>
                    <td>
                        <select id={'output'} style={{padding:'4px',width:'100%'}}>
                            <option value={0}>- Select Output -</option>
                            {this.state.output.map((d,i)=>{
                                return(<option key={i} value={d.id} title={d.description}>{d.name}</option>)
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <hr/>
                        <br/>
                    </td>
                </tr>
                <tr>
                    <td/>
                    <td>
                        <button onClick={()=>this.saveProject()} className={'default'} style={{float:'left' , width:'46%',marginRight:'1%'}}>Save</button>
                        <button onClick={()=>TransactionPopup.dismiss()} className={'default'} style={{float:'right' , width:'46%',marginRight:'1%'}}>Close</button>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>)
    }

    loadOutput() {
        try {
            CommonService.readDictionary("mobile","output").then(result=>{
                try {
                    this.setState({
                        output:result.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    loadProjectInfo(){
        try {
            MobileReadService.readProject(this.props.data.ref_id).then(result=>{
                try {
                    this.setState({
                        project_info:result.data,
                        category:result.data.output.id
                    })
                    document.getElementById("output").value = result.data.output.id

                    this.setProjectOutPut(result.data.output.name)
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    setProjectOutPut(name) {
        switch (name) {
            case 'CCRO':
                ProjectPane.self.setState({
                            project_menu: [
                            {title:'About Project',pane:<AboutProject key={new Date().getMilliseconds()} data={this.props.data}/>},
                            /*{title:'Districts Configuration',pane:<DistrictConfig key={new Date().getMilliseconds()} services={this.props.services}/>},*/
                            {title:'Village Configuration',pane:<VillageConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'Village'}/>},
                            {title:'Hamlets',pane:<HamletConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'Hamlet'}/>},
                            {title:'Project Users',pane:<ProjectUsersConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'Village'}/>},
                            {title:'Questionnaires',pane:<QuestionnairesConfig key={new Date().getMilliseconds()} data={this.props.data} category={this.state.category}/>},
                            {title:'Mobile Parcels',pane:<DataCollection key={new Date().getMilliseconds()} data={this.props.data} init_name={'Village'} category={this.state.category}/>}
                            ]
                })

                break
            default:
                ProjectPane.self.setState({
                            project_menu: [
                            {title:'About Project',pane:<AboutProject key={new Date().getMilliseconds()} data={this.props.data}/>},
                            {title:'Blocks',pane:<BlockConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'Street'}/>},
                            {title:'Project Users',pane:<ProjectUsersConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'District'}/>},
                            {title:'Questionnaires',pane:<QuestionnairesConfig key={new Date().getMilliseconds()} data={this.props.data} category={this.state.category}/>},
                            {title:'Mobile Parcels',pane:<DataCollection key={new Date().getMilliseconds()} data={this.props.data} category={this.state.category}/>}
                            ]
                })
                break
        }
    }
}