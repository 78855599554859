import * as React from "react";
import PrintDocumentPane from "./print_doc";
import TransactionPopup from "../transaction_popup";
import TxCreateService from "../../../../services/transaction/transaction_create";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import Message from "./gro/person/message";
import GeneralSelect from "../../../../utils/GeneralSelect";
import {useState} from "react";
import {array} from "prop-types";
import API from "../../../../services/API";


const SelectDoc=({selected, docId})=>{
    const [documents,documentsSet] =React.useState({
        'value':'docId',
        'label':'documentName',
        'placeholder':'Select Documents To Deliver'
    })
    const[docIds, docIdsSet] = useState([])
    React.useEffect(()=>{
        documentsSet({...documents,endpoint:`transaction/documents/${TransactionPopup.id()}`})
    },[])
    const handleChange = data =>{
        docIdsSet(data);
    }
    const handleClick =(selected,docId)=>{
        if(docIds.length>0){
            let ids=Array();
            docIds.forEach(doc =>ids.push(doc.value))
            let select = ids.join(",");
            console.log("EMMA ID",docId)
            selected(select,docId)
        }
    }

    return (
    <div style={{display:'flex', flexDirection:'space-between', gap:'3em'}}>
        <div style={{width:'100%'}}>
            <GeneralSelect parameters={documents} onChanges={data=>docIdsSet(data)} isMulti={true}/>
        </div>
        <div>
            <button style={{width:"150px"}} onClick={()=>handleClick(selected,docId)}>Generate</button>
        </div>
    </div>
    )
}
export default class ReportsPane extends React.Component{
    static self = null;
    constructor(props) {
        super(props);
        this.state = {
            reports:[],
            docId:null,
            unitId:null,
            base64:'',
            right_pane:<div>Select Report</div>,
            unitTitleList: [],
            documentDetails: null,
            headerTitle: ""
        }
        this.showDocumentAt = this.showDocumentAt.bind(this)
        this.attachGeneratedDoc =this.attachGeneratedDoc.bind(this)
        this.selected =this.selected.bind(this)
        ReportsPane.self = this;
    }

    componentDidMount() {
        let self = this
        setTimeout(()=>{
            self.setState({
                reports:TransactionPopup.self.state.documents.listOfOutgoingDoc
            })
        },1000)


    }
    selected(ids, docId){
        console.log("The returned ids ",ids)
        Wait.show("Generating ...")
        this.setState({docId:docId})
        TxCreateService.generateReport({
            transactionId:TransactionPopup.id(),
            doctTypeId:docId,
            ids
        }).then(r=>{
            try {
                console.log("documenGenerated",r.data)
                if (r.data.status===0){
                    PopDetail.show("Error",<Message faIcon={'harzad'} message={r.data.message}/>,40)
                    Wait.dismiss()
                }else {
                    setTimeout(()=>{
                        try {
                            Wait.dismiss()
                            PopDetail.dismiss()
                            this.setState({
                                base64:r.data.result,
                            })
                            this.setState({right_pane:<PrintDocumentPane base64={r.data.result}/>})
                        }catch (e) {
                            console.log(e)
                        }
                    },2000)}
            }catch (e) {
                console.log(e)
            }
        })
    }
    showDocumentAt(d){
        //documentName
        if(d.documentName.trim() === "RT Covering Letter" || d.documentName.trim()==="Acknowledgement of Delivery"){

            PopDetail.show("Select Documents To Deliver",<SelectDoc selected={this.selected} docId={d.documentId}/>,40)
        }
        else{
            Wait.show("Generating Document....")
            this.setState({docId:d.documentId})

            TxCreateService.generateReport({
                transactionId:TransactionPopup.id(),
                doctTypeId:d.documentId
            }).then(r=>{
                try {
                    console.log("documenGenerated",r.data)
                    if (r.data.status===0){
                        PopDetail.show("Error",<Message faIcon={'harzad'} message={r.data.message}/>,40)
                        Wait.dismiss()
                    }else {
                        setTimeout(()=>{
                            try {
                                Wait.dismiss()
                                this.setState({
                                    base64:r.data.result,
                                })
                                this.setState({right_pane:<PrintDocumentPane base64={r.data.result}/>})
                            }catch (e) {
                                console.log(e)
                            }
                        },2000)}
                }catch (e) {
                    console.log(e)
                }
            })

        }
    }

    showUnitDocumentAt(d,unitId){
        //documentName
            Wait.show("Generating Document....")
            this.setState({docId:d.documentId,
                                unitId: unitId
            })

            TxCreateService.generateReport({
                transactionId:TransactionPopup.id(),
                doctTypeId:d.documentId,
                unitId: unitId
            }).then(r=>{
                try {
                    console.log("documenGenerated",r.data)
                    if (r.data.status===0){
                        PopDetail.show("Error",<Message faIcon={'harzad'} message={r.data.message}/>,40)
                        Wait.dismiss()
                    }else {
                        setTimeout(()=>{
                            try {
                                Wait.dismiss()
                                this.setState({
                                    base64:r.data.result,
                                })
                                this.setState({right_pane:<PrintDocumentPane base64={r.data.result}/>})
                            }catch (e) {
                                console.log(e)
                            }
                        },2000)}
                }catch (e) {
                    console.log(e)
                }
            })
    }

    listUnitTitles(doc){
        API.ax.get("transaction/getUnit-titles/" + TransactionPopup.id()).then(d => {
            if (d.data.status === 1){
                this.setState({unitTitleList: d.data.result})
                this.setState({documentDetails: doc})
                console.log("list st of units",d.data.result)
                this.setState({headerTitle: "PROPERTY/TITLE"})
            }
            else {
                console.log("fail to load units")
            }

        }).catch(e =>
            console.log("unit tittle list error on retrieve ", e)
        )
    }
    render() {
        return(<div >
            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-12'} style={{height:'94vh',paddingRight:'4px',paddingLeft:0}}>

                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Available Reports</b>

                        </div>


                        <div className={'row'} style={{height:'97%'}}>
                            <div className={'col col-sm-3'} style={{padding:0}}>
                                {
                                    TransactionPopup.transaction().name === "Register Unit Title" ?

                                        <div style={{height: '100%'}}>
                                            <div style={{height:'50%', overflow: 'auto'}}>
                                                <div style={{padding:'24px'}}>
                                                    {console.log(this.state.reports,"REPORTj")}
                                                    {this.state.reports?this.state.reports.map((d,i)=>{
                                                        console.log(d,"MAPDOC")
                                                        return(<div key={i}>
                                                            {d.documentName === "Certificate of Unit Title"?
                                                                <span style={{float:'left', paddingLeft: '30px',width:'100%',fontSize:'13px',cursor:'pointer', paddingBottom: '16px'}} onClick={()=>this.listUnitTitles(d)}>
                                                                    <span className={'fa fa-file'} style={{marginRight:'8px'}}/>{d.documentName}
                                                                </span>
                                                                :
                                                                <div>
                                                                 <span style={{float:'left',width:'100%',fontSize:'13px',cursor:'pointer'}} onClick={()=>this.showDocumentAt(d)}>
                                                                    <input type={'radio'} name={'doc'} style={{marginRight:'16px'}}/>
                                                                    <span className={'fa fa-file'} style={{marginRight:'8px'}}/>{d.documentName}
                                                                </span>
                                                                <span style={{float:'right'}}>
                                                                    <span style={{marginLeft:'16px'}} className={'fa fa-paperclip screen-action'} onClick={this.attachGeneratedDoc}>Attach</span>
                                                                </span>
                                                                </div>
                                                            }
                                                            </div>)
                                                          }):''}

                                                        </div>
                                                    </div>
                                            <div style={{height: '50%', overflow: 'auto'}}>
                                               <div style={{padding: '24px'}}>
                                                   <div className={"text-muted pl-1 bg-transparent"} style={{height:'40px',lineHeight:'40px'}}>
                                                       <b>{this.state.headerTitle}</b>
                                                   </div>
                                                   {
                                                       this.state.unitTitleList.map((d,i) =>{
                                                            return (
                                                                <div key={i}>
                                                                    <span style={{float:'left',width:'100%',fontSize:'13px',cursor:'pointer'}} onClick={()=>this.showUnitDocumentAt(this.state.documentDetails, d.unitId)}>
                                                                        <input type={'radio'} name={'doc'} style={{marginRight:'16px'}}/>
                                                                        <span className={'fa fa-file'} style={{marginRight:'8px'}}/>{d.titleNumber == null ? d.registrationNo : d.titleNumber}(Unit Ownership)
                                                                    </span>

                                                                    <span style={{float:'right'}}>
                                                                        <span style={{marginLeft:'16px'}} className={'fa fa-paperclip screen-action'} onClick={this.attachGeneratedDoc}>Attach</span>
                                                                    </span>
                                                                </div>
                                                            )
                                                       })
                                                   }
                                               </div>
                                            </div>
                                        </div>

                                        : <div style={{height:'94%'}}>
                                            <div style={{padding:'24px', overFlow: "auto"}}>
                                                {console.log(this.state.reports,"REPORTj")}
                                                {this.state.reports?this.state.reports.map((d,i)=>{
                                                    console.log(d,"MAPDOC")
                                                    return(<div key={i}>
                                                <span style={{float:'left',width:'100%',fontSize:'13px',cursor:'pointer'}} onClick={()=>this.showDocumentAt(d)}>
                                                    <input type={'radio'} name={'doc'} style={{marginRight:'16px'}}/>
                                                    <span className={'fa fa-file'} style={{marginRight:'8px'}}/>{d.documentName}
                                                </span>

                                                        <span style={{float:'right'}}>
                                                    <span style={{marginLeft:'16px'}} className={'fa fa-paperclip screen-action'} onClick={this.attachGeneratedDoc}>Attach</span>
                                                </span>
                                                    </div>)
                                                }):''}

                                            </div>
                                        </div>
                                }

                            </div>
                            <div className={'col col-sm-9'} style={{background:'#efefef'}}>
                                {this.state.right_pane}
                            </div>
                        </div>

                    </div>
                </div>



            </div>



        </div>)
    }

    attachGeneratedDoc() {
        if (TransactionPopup.transaction().name === 'Register Unit Title' && ( TransactionPopup.task() === "Review Documents" ||  TransactionPopup.task() === "Approve Final Documents")){
            PopDetail.show("Warning",<Message faIcon={'harzad'} message={"Preview version of this document is not supposed to be attached."}/>,40)
        }
        else {
            if (this.state.base64 === '') {

            } else {
                let data={
                    transactionId:TransactionPopup.id(),
                    base64: this.state.base64,
                    documentTypeId: this.state.docId,
                    unitId: this.state.unitId
                }
                console.log(data," DOc Details DOc Details")
                TxCreateService.attachGeneratedReport(data).then(r => {
                        if (r.data.status === 1){
                            PopDetail.show(r.data.result, 40)
                            return PopDetail.dismiss()
                        }else {
                            PopDetail.show("ERROR:",<div><p>{r.data.message}</p></div>, 40)
                        }
                    }
                )
            }
        }

    }

}
