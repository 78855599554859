import * as React from "react";
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import PopDetail from "../../popdetail";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../mobile_popup";
import Wait from "../../wait";
import UgSpatial from "../../../maps/ug_spatial";
import API from "../../../../services/API";
import PopParcel from "../pops/pane_pop_parcel";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import PrintDocumentPane from "../../transacrions/screen/print_doc";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../resource/img/checkbox/checkbox_checked_16.png";
import PaneUploadDraft from "../pops/pane_upload_draft";
import * as LF from "leaflet";
import Basemap from "../../../maps/basemap";
import Message from "../../transacrions/screen/gro/person/message";
import ReportCreateService from "../../../../services/common/reports/report_create";
import {saveAs}  from 'file-saver'
import ReactTable from "react-table";
export default class DataCollectionPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parcels: [],
            layers_list: [],
            current_tab: 0,
            cpage: 1,
            page: 1,
            no_of_records: 50,
            totalpages: 1,
            layer_id: 0,
            rowsfilter: [50, 100, 150, 200, 300],
            stages: ['Mobile Data', 'Rejected', 'GIS Approval', 'ALO Approval', 'Paid', 'Printed', "Draft CCRO", 'LO Rejected'],
            color: ['#ffb', '#f00', '#8f8', '#f80', '#00f', '#080', '#c90', '#999'],
            tabs: [],
            stage: 0,
            doc_id: 0,
            layers: [],
            map: null,
            is_map_visible: 'hidden',
            actions: [
                { name: 'Public Display - Excel', id: 1 },
                { name: 'Download Draft Data - Shapefile', id: 2 },
                { name: 'Ownership Shapefile - Points', id: 33}
            ],
            data_types: [
                { id: 0, name: 'All', total: 0 },
                { id: 1, name: 'Rejected', total: 0 },
                { id: 2, name: 'GIS Approval', total: 0 }
            ],
            description_title: 'Mobile Data Summary',
            hamlets: []
        };

        this.loadParcels = this.loadParcels.bind(this)
        this.loadLocality = this.loadLocality.bind(this)
        this.printReport = this.printReport.bind(this)
        this.toggleCheck = this.toggleCheck.bind(this)
        this.downloadData = this.downloadData.bind(this)
        this.searchByClaimNo = this.searchByClaimNo.bind(this)
        this.loadDataInLayer = this.loadDataInLayer.bind(this)
        this.handleChangeDataType = this.handleChangeDataType.bind(this)

    }

    handleChange = e => { this.setState({ [e.target.name]: e.target.value }); }
    handleChangeFile = e => { this.setState({ coordinate: e.target.files[0] }) }
    handleChangeDataType = e => {
        this.setState({ [e.target.name]: e.target.value });
        this.loadParcels(parseInt(e.target.value))
    }

    loadDataInLayer = e => {
        this.setState({
            [e.target.name]: e.target.value,
            // tabs: [],
            locality: 0
        });
        let layer = e.target.value
        this.state.layers.forEach(it => {
            if (it.id === layer) {
                try {
                    this.state.map.fitBounds(it.layer.getBounds())
                    //this.state.map.setView(it.layer.getBounds().getCenter(),100);
                } catch (e) {
                    console.log(e)
                }
            }
        })
        /*Wait.show('Loading...')
        console.log(layer.replace('mob_layers.', ''))
        MobileReadService.readTranslateByCategorySubCategory('mobile', 'hamlet', layer.replace('mob_layers.', '')).then(r  =>{
            try {
                Wait.dismiss()
                this.setState({
                    hamlets:r.data.results
                })
            }catch (e) {
                console.log(e)
            }
        })*/

        setTimeout(() => {
            this.loadSummary(this.state.current_tab, this.state.layer_id)
            this.loadParcels()
        }, 500)

    }

    searchByClaimNo() {
        let claim = this.state.search_claim
        try {
            Wait.show('Searching...')
            MobileReadService.searchByClaimNo(MobilePopup.id(), claim).then(r => {
                Wait.dismiss()
                let i = [];
                try {
                    r.data.results.map((prop) => {
                        if (prop.stage === this.state.current_tab) {
                            i.push(prop);
                        }
                    })
                    console.log(r.data)
                    this.setState({
                        parcels: i
                    })
                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    loadParcels(tab = this.state.current_tab) {
        try {
            Wait.show("Loading Data..." + this.state.layer_id)

            MobileReadService.readReportSummary(MobilePopup.id(),
                this.state.layer_id.toString().replace('mob_layers.', ''),
                this.state.locality, tab).then(r => {
                    try {
                        this.setState({
                            tabs: r.data.results,
                            current_tab: tab
                        })
                        MobileReadService.readReportTab(MobilePopup.id(), tab, this.state.no_of_records, this.state.page,
                            this.state.layer_id.toString().replace('mob_layers.', ''),
                            this.state.locality,).then(d => {
                                Wait.dismiss()
                                try {
                                    console.log(d.data.results)
                                    console.log("Total Pages:" + d.data.total_pages)
                                    this.setState({
                                        parcels: d.data.results,
                                        totalpages: d.data.total_pages
                                    })
                                } catch (e) {
                                    console.log(e)
                                }
                            })

                        MobileReadService.readReportTab(MobilePopup.id(), tab, API.no_of_records, this.state.page,
                            this.state.layer_id.toString().replace('mob_layers.', ''),
                            this.state.locality,).then(d => {
                                Wait.dismiss()
                                try {
                                    console.log(d.data.results)
                                    this.setState({
                                        parcels: d.data.results
                                    })
                                } catch (e) {
                                    console.log(e)
                                }
                            })


                        UgSpatial.clear()

                        let ly_group = new LF.FeatureGroup();
                        let ly_features = new LF.FeatureGroup();

                        let sat = Basemap.googleSat(),
                            hybrid = Basemap.googleHybrid(),
                            blank = LF.tileLayer('')

                        let map = LF.map('map', {
                            layers: [blank, ly_group, ly_features],
                            attributionControl: false,
                            fullscreenControl: true,
                            maxZoom: 10000
                        }).setView([-6.085936, 35.711995], 4)


                        let stage = 'stage in (' + tab + ')'
                        if (tab === 0) {
                            stage = '1=1'
                        }

                        let ly_vb = UgSpatial.getLayerByName('mob_parcel', 'project_id in (' + MobilePopup.id() + ') and ' + stage).addTo(map)
                        //ly_vb.bringToFront()

                        let baseLayers = {
                            "<span style='color: gray'>Blank</span>": blank,
                            "Satellite": sat,
                            "Hybrid": hybrid
                        }

                        let filter = 'project_id in (' + MobilePopup.id() + ') AND deleted=false'
                        let layername = 'mob_layers'
                        let selected
                        let self = this
                        /**
                        API.ax.get(API.geo_ilmis + '/ows?cql_filter=' + filter + '&service=WFS&version=1.0.0&request=GetFeature&typeName=' + layername + '&outputFormat=application%2Fjson').then(r => {
                            try {
                                let list_layers = []
                                r.data.features.forEach(p => {
                                    let myLayer = LF.geoJSON(p, {
                                        onEachFeature: (ft, ly) => {

                                            let label = LF.marker(ly.getBounds().getCenter(), {
                                                icon: LF.divIcon({
                                                    className: 'bindTooltipBig',
                                                    html: ft.properties.label + ' - ' + ft.id.split('.')[1],
                                                    iconSize: [100, 40]
                                                })
                                            }).addTo(p.properties.boundary ? ly_group : ly_features)
                                            //label.options.pmIgnore = false;
                                        },
                                        color: p.properties.boundary ? '#000' :
                                            p.properties.allowed ? '#080' : '#f00',
                                        weight: p.properties.boundary ? 4 : 3,
                                        zIndex: 1,
                                        fillOpacity: p.properties.allowed ? 0 : 0.2,
                                        //fillColor:p.properties.allowed?'#000':'#f00'
                                    })
                                        .on('dblclick', e => {
                                            this.popDataAt(p.properties)
                                        }).on('click', function (e) {
                                            if (selected) {
                                                e.target.resetStyle(selected)
                                            }
                                            selected = e.layer
                                            selected.bringToFront()
                                            self.setState({ description_title: p.properties.claim_no })
                                            selected.setStyle({ color: '#000', weight: 4 })
                                            map.fitBounds(selected.getBounds())
                                            map.setView(selected.getBounds().getCenter());
                                        }).addTo(p.properties.boundary ? ly_group : ly_features);

                                    if (p.properties.boundary === true) {
                                        list_layers.push({
                                            id: p.id,
                                            label: p.properties.label,
                                            name: p.properties.name,
                                            layer: myLayer
                                        })
                                    }

                                })

                                this.setState({ map: map })

                                let myLayer = LF.geoJSON(r.data.features)
                                try {
                                    map.fitBounds(myLayer.getBounds())
                                } catch (e) {
                                }

                                let overlays = {
                                    'Project Boundaries': ly_group,
                                    'Project Features': ly_features,
                                    'Parcels': ly_vb
                                }

                                UgSpatial.layers = LF.control.layers(baseLayers, overlays, { position: 'bottomleft' }).addTo(map)
                            } catch (e) {
                                console.log(e)
                            }

                        })
                        **/

                    } catch (e) {
                        console.log(e)
                    }
                })
        } catch (r) {

        }
    }
    loadSummary(tab = this.state.current_tab, layer_id = this.state.layer_id) {
        MobileReadService.readReportSummary(MobilePopup.id(),
            layer_id.toString().replace('mob_layers.', ''),
            this.state.locality, this.state.current_tab).then(r => {
                try {
                    this.setState({
                        tabs: r.data.results,
                        current_tab: this.state.current_tab
                    })

                } catch (e) {
                    console.log(e)
                }
            })
    }
    componentDidMount() {
        this.loadSummary()
        this.loadParcels()
        this.loadLocality()
    }

    downloadData() {

        let layer_id = this.state.layer_id.replace('mob_layers.', '')
        let locality = this.state.locality
        let stage = this.state.stage
        let doc_id = this.state.doc_id

        if (layer_id < 1 || layer_id === undefined) {
            alert('No Project Layer')
            return
        }

        if (doc_id < 1 || doc_id === undefined) {
            alert('Select Document Type')
            return
        }

        switch (parseInt(doc_id)) {
            case 1: 
            this.printReport(doc_id, layer_id, locality, stage); 
            return
            case 2:
                this.downloadSHP(layer_id, locality, stage, doc_id);
                return;
            case 33:
                this.downloadSHP(layer_id,locality,stage, doc_id);
                return;
            case 3:
                try {
                    Wait.show('Requesting...')
                    MobileCreateService.askControlNumber({
                        request: 'layer',
                        reference: layer_id,
                        stage: stage,
                        projectId: MobilePopup.id()
                    }).then(r => {
                        Wait.dismiss()
                    });
                } catch (e) {
                    console.log(e)
                }
                return;
        }

        /* let path = API.base_url+`mobile/read/download?project_id=${MobilePopup.id()}&locality_id=${layer_id}`
         PopDetail.download(path)*/
    }

    uploadShapefile() { PopDetail.show("Upload Draft Shapefile", <PaneUploadDraft />, 30) }

    loadLocality() {
        Wait.show("Loading Localities...")
        MobileReadService.mobileLayers(MobilePopup.id(), true).then(d => {
            Wait.dismiss()
            try {
                this.setState({
                    layers_list: d.data.results
                })
            } catch (e) {
                console.log(e)
            }
        })


    }

    popDataAt(d) {
        if (d.stage === 0) {
            PopDetail.show("Warning", <Message faIcon={'harzad'} message={'No GIS Approved'} />, 30)
            return
        }
        PopDetail.show(d.claim_no, <PopParcel parcel={d} updateParcels={(e) => {
             this.loadParcels(e)
             }} />)
    }

    popFullTable(tb_id = 'bottom_table') {
        let btn = document.getElementById('btn_enl')

        if (btn.innerHTML !== 'Show Map') {
            this.setState({
                is_map_visible: 'visible'
            })
            document.getElementById(tb_id).style.position = 'absolute'
            document.getElementById(tb_id).style.zIndex = 10000000
            document.getElementById(tb_id).style.left = '-1vw'
            document.getElementById(tb_id).style.right = 0
            document.getElementById(tb_id).style.bottom = 0
            document.getElementById(tb_id).style.top = 0
            document.getElementById(tb_id).style.height = '100vh'
            btn.innerHTML = 'Show Map'
        } else {
            this.setState({
                is_map_visible: 'hidden'
            })
            document.getElementById(tb_id).style.position = 'absolute'
            document.getElementById(tb_id).style.zIndex = 10000000
            document.getElementById(tb_id).style.left = '-1vw'
            document.getElementById(tb_id).style.right = 0
            document.getElementById(tb_id).style.bottom = 0
            document.getElementById(tb_id).style.top = '60vh'
            document.getElementById(tb_id).style.height = '34vh'
            btn.innerHTML = 'Hide Map'
        }

    }

    printReport(doc_id, layer_id, locality, stage) {
        if (doc_id < 2) {
            //let path = API.base_url+`mobile/create/report?locality=${locality}&stage=${stage}&project_id=${MobilePopup.id()}&document_id=${doc_id}&layer_id=${layer_id}`
            //PopDetail.download(path)

            Wait.show('Downloading...')
            try {
                ReportCreateService.getExcel(`mobile/create/report?locality=${locality}&stage=${stage}&project_id=${MobilePopup.id()}&document_id=${doc_id}&layer_id=${layer_id}`).then(r => {
                    try {
                        Wait.dismiss()
                        const url = window.URL.createObjectURL(new Blob([r.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Public Display.xls'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    } catch (e) {
                        console.log(e)
                    }
                })
            } catch (e) {
                console.log(e)
            }

            return
        }

        Wait.show('Loading Output...')
        MobileCreateService.createOutput(MobilePopup.id(), doc_id, locality).then(r => {
            try {
                Wait.dismiss()
                this.setState({
                    doc_frame: <PrintDocumentPane base64={r.data} />
                })
            } catch (e) {
                console.log(e)
            }
        })

    }
    toggleCheck(i) {
        let doc = document.getElementById('chk_' + i);
        if (doc.src === uncheck) {
            doc.src = check;
            return;
        }
        doc.src = uncheck;
    }
    render() {
        return (<div>
            <SplitterLayout vertical={true}>
                <div>
                    <div className={'row'} style={{ marginBottom: '4px' }}>
                        <div className={'col col-sm-3'} style={{ height: '60vh', paddingRight: '4px', paddingLeft: 0 }}>
                            <div style={{ background: '#fff', padding: '24px', height: '100%', border: '0.5px solid #efefef' }}>
                                <div style={{ height: '40px', lineHeight: '40px' }}>
                                    <b>{this.state.description_title}</b>
                                </div>

                                <div style={{ width: '100%', marginTop: '8px' }}>
                                    <table className={'tb2'}>
                                        <thead>
                                            <tr>
                                                <th>Stage</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.tabs.map((d, i) => {
                                                return (<tr key={i} onClick={() => this.loadParcels(d.name === 'Registered' ? 4 : d.name === 'Draft CCRO' ? 6 : d.name === 'LO Rejected' ? 7 : i)} style={{ cursor: 'pointer', textShadow: '0 0 2px #fff', backgroundColor: this.state.color[i] }}>
                                                    <td>
                                                        <b>{d.name}</b>
                                                    </td>
                                                    <td>
                                                        <span className={'ug-badge-ok'} style={{ float: 'right' }}>{d.count}</span>
                                                    </td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                        <div className={'col col-sm-9'} style={{ height: '60vh', paddingLeft: '4px' }}>
                            <div style={{ background: '#fff', height: '100%', padding: '24px', border: '0.5px solid #efefef' }}>
                                <div className={'row'} style={{ height: '40px' }}>
                                    <div className={'col col-sm-10'} style={{ padding: 0 }}>
                                        <div className={'row'}>
                                            <div className={'col col-sm-4'}>
                                                <select name={'layer_id'} style={{ width: '100%' }} onChange={(e) => this.loadDataInLayer(e)} id={'sp_layer'} >
                                                    <option value={0}>- Choose Project Layer -</option>
                                                    <option value={0}>All Layers</option>
                                                    {this.state.layers_list.map((d, i) => {
                                                        return (<option key={i} value={d.id}>{d.label} - {d.name}</option>)
                                                    })}
                                                </select>
                                            </div>

                                            {/*<div className={'col col-sm-2'}>
                                        <select style={{width:'100%'}} name={'locality'} onChange={this.handleChangeDataType}>
                                            <option value={0}>- Choose Locality -</option>
                                            <option value={0}>All Localities</option>
                                            {this.state.hamlets.map((d,i)=>{
                                                return(<option key={i} value={d.id}>{d.name} - {d.id}</option>)
                                            })}
                                        </select>
                                    </div>*/}

                                            <div className={'col col-sm-3'}>
                                                <select id={'sp_stage'} style={{ width: '100%' }} name={'stage'} onChange={this.handleChange}>
                                                    <option value={0}>- Choose Stage -</option>
                                                    {this.state.tabs.map((d, i) => {
                                                        return (<option key={d.tabId} value={i}>{d.name} - {d.count}</option>)
                                                    })}
                                                </select>
                                            </div>
                                            <div className={'col col-sm-2'}>
                                                <select style={{ width: '100%' }} name={'doc_id'} onChange={this.handleChange}>
                                                    <option value={0}>- Choose Action -</option>
                                                    {this.state.actions.map((d, i) => {
                                                        return (<option key={i} value={d.id}>{d.name}</option>)
                                                    })}
                                                </select>
                                            </div>


                                            <div className={'col col-sm-3'}>
                                                {
                                                    this.state.doc_id==="33"?<a target={"_BLANK"} href={API.base_url+"mobile/read/downloadOwners?project_id="+MobilePopup.id()+"&stage="+this.state.stage+"&layer_id="+this.state.layer_id}><button style={{ width: '100px' }} className={'refresh'}>Submit</button></a>:<button style={{ width: '100px' }} className={'refresh'} onClick={() => this.downloadData()}>Submit</button>
                                                }



                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col col-sm-2'} style={{ padding: 0 }}>
                                        <button style={{ width: '150px' }} className={'submit'} onClick={() => this.uploadShapefile()}>Upload .SHP</button>
                                    </div>

                                </div>
                                {/* <div className={'row'} style={{ height: '97%', justifyContent: "center", alignItems: "center" }}>
                                    <div className="alert alert-danger" style={{ margin: 10 }}>
                                        <p>Map Display Has been disabled due too large amount of information. For parcel information Use the table to retrieve spatial information of the parcel</p>
                                    </div>
                                </div> */}
                                <div>
                                    <div className={'row'} >

                                        <div className={'col col-sm-12'} style={{ padding: 0, height: '70vh', overflow: 'auto' }} id={'bottom_table'}>
                                            <div className={'row'} style={{ paddingLeft: '16px', paddingTop: '16px' }}>
                                                <div className={'col col-sm-12'}>
                                                    <div className="row" style={{ flexDirection: "row" }}>
                                                        <div className="col-sm-3">

                                                            <select className="form-control" name={"page"} onChange={(e) => { this.changePage(e) }}>
                                                                <option value={""} >Choose Page</option>
                                                                {this.lo().map((prop, key) => (
                                                                    <option value={prop} key={key}>Page {prop}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <select className="form-control" name={"no_of_records"} onChange={(e) => { this.changePage(e) }}>
                                                                <option value={""} >rows</option>
                                                                {this.state.rowsfilter.map((prop, key) => (
                                                                    <option value={prop} key={key}>{prop}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-7">
                                                            <div className="row">
                                                                    <div className="col-sm-8">

                                                            <input className="form-control" placeholder={'Search Here'} onChange={this.handleChange} name={'search_claim'} />
                                                                    </div>
                                                                    <div className="col-sm-4">

                                                            <button onClick={this.searchByClaimNo} className={'default'}>Search</button>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-sm-2">

                                                        <b style={{ fontSize: '12px', float: "right", justifySelf:"center", alignItems:"center" }}>{this.state.parcels.length} Parcel/s</b>
                                                        </div> */}

                                                    </div>


                                                </div>
                                                <div className={'col col-sm-2'}>
                                                    {/* <button id={'btn_enl'} style={{ float: 'right', marginRight: '24px' }} className={'default'} onClick={() => this.popFullTable()}>Hide Map</button> */}
                                                </div>
                                                <div className="col col-sm-12">
                                                    {/* <button id="button" onClick={this.download}>Export to EXCEL</button> */}
                                                </div>
                                                {/*
                                                <div className={'row col col-sm-2'} style={{ visibility: this.state.is_map_visible }}>

                                                </div> */}
                                            </div>

                                            <div className={'col col-sm-12'} >
                                                <table ref={r => this.table = r} id='tbb' className={'tb'} style={{ width: '100%', marginTop: '8px', border: '1px solid #ddd' }}>
                                                    <thead>
                                                        <tr>
                                                            <th />
                                                            <th>CLAIM NO</th>
                                                            <th>OWNERS</th>
                                                            <th>STAGE</th>
                                                            <th>OWNERSHIP</th>
                                                            <th>USE</th>
                                                            <th>NORTH</th>
                                                            <th>SOUTH</th>
                                                            <th>EAST</th>
                                                            <th>WEST</th>
                                                            <th>CLAIM DATE</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.parcels.map((d, i) => {
                                                            return (d.stage > 1 ? <tr key={i}>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <img id={'chk_' + (i + 1)} alt={'chk_' + (i + 1)} src={uncheck} onClick={() => this.toggleCheck(i + 1)} />
                                                                </td>
                                                                <td style={{ color: '#008b8b' }} onClick={() => this.popDataAt(d)}><b>{d.claim_no}</b></td>
                                                                <td><b>{d.owners}</b></td>
                                                                <td><b>{d.ownership}</b></td>
                                                                <td><b>{d.use}</b></td>
                                                                <td><b>{d.north}</b></td>
                                                                <td>{d.south}</td>
                                                                <td>{d.east}</td>
                                                                <td>{d.west}</td>
                                                                <td>{d.created_at}</td>
                                                            </tr> :
                                                                <tr key={i} style={{ background: d.stage === 0 ? '#fcc' : '#f00' }}>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        <img id={'chk_' + (i + 1)} alt={'chk_' + (i + 1)} src={uncheck} onClick={() => this.toggleCheck(i + 1)} />
                                                                    </td>
                                                                    <td style={{ color: d.stage === 0 ? '#000' : '#fff' }} onClick={() => this.popDataAt(d)}><b>{d.claim_no}</b></td>
                                                                    <td><b>{d.owners}</b></td>
                                                                    <td><b>{this.state.stages[d.stage]}</b></td>
                                                                    <td><b>{d.ownership}</b></td>
                                                                    <td><b>{d.use}</b></td>
                                                                    <td><b>{d.north}</b></td>
                                                                    <td>{d.south}</td>
                                                                    <td>{d.east}</td>
                                                                    <td>{d.west}</td>
                                                                    <td>{d.created_at}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                {/*
                                    <div className={'col col-sm-12'} style={{ background: '#efefef' }} id={'ramani'} />
                                </div> */}

                            </div>

                        </div>

                    </div>
                </div>

            </SplitterLayout>

        </div>)
    }
    downloadSHP(layer_id, locality, stage,docId) {
        console.log(docId)
        try {
            Wait.show('Creating Shapefile...')
            MobileReadService.downloadSHP(MobilePopup.id(), layer_id, locality, stage,Number(docId)===33?"Owners":"").then(r => {
                Wait.dismiss()
                try {
                    if (r.data.status === 0) {
                        alert(r.data.message)
                        return
                    }
                    Wait.dismiss();
                    //let path = API.base_url+`mobile/read/zip?project_id=${MobilePopup.id()}&layer_id=${layer_id}&locality=${locality}&stage=${stage}`
                    //PopDetail.download(path)
                    if(Number(docId)===33){
                        let p =new Blob([r.data],{type:'application/zip',endings:"native"})
                        // saveAs(p, 'shapefile.zip');
                        const url = window.URL.createObjectURL(p);
                        window.open(url,"blank")
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'shape.zip'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        return

                    }else{
                        try {
                            ReportCreateService.getExcel(`mobile/read/zip?project_id=${MobilePopup.id()}&layer_id=${layer_id}&zipname=${r.data.message}&locality=${locality}&stage=${stage}`).then(r => {
                                try {
                                    Wait.dismiss();
                                    const url = window.URL.createObjectURL(new Blob([r.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'Shapefile.zip'); //or any other extension
                                    document.body.appendChild(link);
                                    link.click();
                                } catch (e) {
                                    console.log(e)
                                }
                            })
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    Wait.show('Downloading...');


                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
    lo() {
        let h = [];
        for (let i = 1; i < this.state.totalpages; i++) {
            h.push(i);
        }
        return h;
    }
    changePage(val) {
        this.setState({ [val.target.name]: val.target.value })
        this.loadParcels(this.state.current_tab)
    }
}
