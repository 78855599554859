import React from "react";

class LadApproval  extends React.Component{
    constructor(props) {
        super(props);
    }
    render() {
        return(<div>
            <span> <span style={{color:'red'}}>*</span>Initial LAD Decision</span>
            <span>LAD Allocation Approval</span>
            <select><option>Accepted</option>
                <option>Rejected</option>
            </select>
        </div>)
    }
}
export default LadApproval
