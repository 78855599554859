import * as React from "react";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import TransactionPopup from "../../../popups/transacrions/transaction_popup";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";

export default class HamletConfig extends React.Component{


    constructor(props) {
        super(props);
        this.state = {
            hamlet_config:[],
            village_config:[]
        }

        this.loadVillageConfig = this.loadVillageConfig.bind(this)
        this.loadData = this.loadData.bind(this)
    }

    componentDidMount() {
        this.loadData()
        this.loadVillageConfig()
        this.setTabAt(0)
    }

    toggleFilters(){
        let div = document.getElementById('div-filter')
        div.style.display = div.style.display === 'none'?'inherit':'none'
    }

    toggleCheck(i){

    }

    setTabAt(tab) {
        this.setState({tab: tab})
    }

    render() {

        return(<div style={{overflow:'hidden',height:'100%',marginRight:'16px'}}>

            <div className={'row'} style={{boxShadow:'0 4px 4px #aaa',padding:'8px 16px',backgroundColor:'#efefef'}}>
                <div className={'col col-sm-8'}>
                    <span className={'fa fa fa-bars'} style={{cursor:'pointer', marginTop:'8px'}} onClick={()=>this.toggleFilters()}/>
                    <input style={{width:'85%',marginLeft:'24px',backgroundColor:'#fff'}} placeholder={'Search here...'}/>
                    <span className={'fa fa fa-plus-circle'} style={{color:'darkcyan', float:'right',cursor:'pointer', margin:'8px'}} onClick={()=>this.toggleFilters()}/>
                </div>
                <div className={'col col-sm-4 fa-btn'}>
                    <button style={{float:'right',width:'80px'}} onClick={()=>TransactionPopup.dismiss()}>Close</button>
                    <button style={{float:'right',width:'80px'}} onClick={()=>this.loadData(0)} className={'refresh'}>Refresh</button>
                    <span className={'fa fa-check'} title={'Approve'} style={{color:'darkcyan', marginRight:'24px'}}/>
                    <span className={'fa fa-times'} title={'Reject'} style={{color:'#DF4A36'}}/>
                    <span className={'fa fa-pencil'} title={'Edit'}/>
                    {/*<span className={'fa fa-trash'} title={'Delete'} style={{color:'#DF4A36'}}/>*/}
                </div>

            </div>

            <div className={'col col-sm-9'} style={{border:'0.8px solid #ccc',display:'none'}} id={'div-filter'}>
                <br/>

                <table style={{width:'100%'}}>
                    <tbody>
                     <tr>
                        <td><label>Village : </label></td>
                        <td>
                            <select className={'select'} id={'sp_village'}>
                                <option key={0}>Select Village</option>
                                {this.state.village_config.map((d,i)=>{
                                    return(<option key={i} value={d.village_id}>{d.name}</option>)
                                })}

                            </select>

                        </td>
                    </tr>
                      <tr>
                        <td><label>{this.props.init_name} Name : </label></td>
                        <td><input style={{width:'100%'}} type={'text'} id={'hm_name'}/></td>
                    </tr>
                     <tr>
                        <td><label>Initial Code : </label></td>
                        <td><input style={{width:'100%'}} type={'text'} id={'hm_code'}/></td>
                    </tr>

                    </tbody>
                </table>

                <br/>

                <hr/>


                <div style={{textAlign:'center', padding:'8px 0 16px 0'}}>
                    <button className={'default'} style={{width:'100px'}} onClick={()=>this.saveHamlet()}>Submit</button>
                    <button className={'default'} style={{width:'100px'}} onClick={()=>this.toggleFilters()}>Close</button>
                </div>

            </div>

            <div className={'row'} style={{marginTop:'1vh'}}>
                <div className={'col col-sm-12'}>
                    <div className={this.state.tab===0?'tab tab-active':'tab'} onClick={()=>this.setTabAt(0)}>
                        <span className={'fa fa-list'}/> {this.props.init_name}s
                    </div>
                    {/*<div className={this.state.tab===1?'tab tab-active':'tab'} onClick={()=>this.setTabAt(1)}>
                        <span className={'fa fa-map'}/>
                    </div>*/}
                    {/*<div className={this.state.tab===2?'tab tab-active':'tab'} onClick={()=>this.setTabAt(2)}>
                        <span className={'fa fa-bar-chart'}/> Statistic
                    </div>*/}

                </div>
            </div>

            <div style={{width:'100%',height:'100%', overflow:'auto'}}>

                <table className={'tb'} style={{border:'1px solid #ccc',width:'98%',margin:'1%'}}>
                    <thead>
                    <tr>
                        <th style={{textAlign:'left',paddingLeft:'8px'}}>
                            <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                        </th>
                        <th>{this.props.init_name.toUpperCase()} NAME</th>
                        <th>{this.props.init_name==='Hamlet'?'VILLAGE':'DISTRICT'} NAME</th>
                        <th>{this.props.init_name.toUpperCase()} CODE</th>
                        <th style={{textAlign:'right'}}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.hamlet_config.map((d,i)=>{
                        return(<tr key={i}>
                            <td>
                                <img id={'chk_'+i} alt={'chk_'+i}  style={{float:'left'}}
                                     src={uncheck} onClick={()=>this.toggleCheck(i)}/>
                                <span style={{float:'right'}}>
                                    {i+1}.
                                </span>
                            </td>
                            <td>{d.village_name}</td>
                            <td>{d.name}</td>
                            <td>{d.code}</td>
                            <td style={{textAlign:'right'}}>
                                <button style={{width:'50px'}} className={'default'}>Edit</button>
                                <button style={{width:'50px'}} className={'default'}>Delete</button>
                            </td>
                        </tr>)
                    })}
                    </tbody>
                </table>

            </div>

        </div>)

    }

    loadVillageConfig(){
        try {
            MobileReadService.readVillageConfig(this.props.data.ref_id).then(r=>{
                try {
                    console.log(r.data)
                    this.setState({
                        village_config:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    saveHamlet() {

        let village_id = document.getElementById('sp_village').value
        let hm_name = document.getElementById('hm_name').value
        let hm_code = document.getElementById('hm_code').value

        try {
            MobileCreateService.configHamlet({
                project_id:this.props.data.ref_id,
                village_id:village_id,
                hm_code:hm_code,
                hm_name:hm_name
            }).then(r=>{
                try {
                    if(r.data.status===1){
                        this.toggleFilters()
                        document.getElementById('hm_name').value = ""
                        document.getElementById('hm_code').value = ""
                        this.loadData()
                    }
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    loadData(){
        try {
            MobileReadService.readHamletConfig(this.props.data.ref_id).then(r=>{
                try {
                    console.log(r.data.results)
                    this.setState({
                        hamlet_config:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }
}