import React, {Component} from 'react';
import TxReadService from "../../../../services/transaction/transaction_read";
import Wait from "../../wait";
import TransactionPopup from "../transaction_popup";
import TxCreateService from "../../../../services/transaction/transaction_create";
import {array} from "prop-types";
import SelectLegacyPlots from "./selectLegacyPlots";
import PopDetail from "../../popdetail";

class SelectPlots extends Component {
    constructor(props) {
        super(props);
        this.state = {
            councils: [],
            localities: [],
            councilId: 0,
            dataToDisplay: [],
            selected:[],
            location: '',
            block: '',
            lotNumber: '',
            lotType: '',
            regPlanNumber: '',
            planNumber: '',
            allocationStatusName: 'Legacy',
            landUseName: '',
            drawingNumber: '',
            legalArea: null,
            areaUnitName: '',
            spatialUnitId: null,
            baunitId: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.councilChange = this.councilChange.bind(this)
        this.getProperties = this.getProperties.bind(this)
        this.pustPlot = this.pustPlot.bind(this)
        this.addSelected = this.addSelected.bind(this)
    }

    componentDidMount() {
        TxReadService.getCouncils(sessionStorage.getItem("username")).then(d => {
            this.setState({councils: d.data.result})
        })

    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
        console.log("data", e.target.value)
    }

    councilChange = e => {
        try {
            this.setState({councilId: e.target.value})
            console.log("council", e.target.value)
            TxReadService.getLocalitiesByCouncilId(e.target.value).then(d => {
                try {
                    if (d.data.status === 1) {
                        this.setState({localities: d.data.result})
                    }
                } catch (e) {

                }
            })
        } catch (e) {
            console.log(e)

        }
    }

    render() {
        return (
            <div>
                <div style={{height: '100%', overflow: 'auto'}} className={'col col-12'}>
                    <br/>
                    <table   style={{width: '100%'}}>
                        <tbody>

                        <tr>
                            <td><span>Council</span></td>
                            <td><span>Location</span></td>
                            <td><span>Block</span></td>
                            <td><span>LOT Number</span></td>
                            <td><span>Reg.Plan Number</span></td>
                            <td><span>Plan Number</span></td>
                        </tr>
                        <tr>
                            <td>
                                <select style={{width: '100%'}} name={'councilId'} onChange={this.councilChange}>
                                    <option value={0}>- Choose Council -</option>
                                    {this.state.councils.map((council) => <option key={council.councilId}
                                                                                  value={council.councilId}>{council.councilName}</option>)}
                                </select>
                            </td>
                            <td>
                                <select style={{width: '100%'}} name={'localityId'} onChange={this.handleChange}>
                                    <option value={0}>- Choose Location -</option>
                                    {this.state.localities.map((d) => <option key={d.localityId}
                                                                              value={d.localityId}>{d.name}</option>)}
                                </select>
                            </td>
                            <td><input style={{width: '100%'}} name={"block"}
                                       onChange={this.handleChange}/></td>
                            <td><input style={{width: '100%'}} name={"lotNumber"} onChange={this.handleChange}/>
                            </td>
                            <td><input style={{width: '100%'}} name={"regPlanNumber"} onChange={this.handleChange}/>
                            </td>
                            <td><input style={{width: '100%'}} name={"planNumber"} onChange={this.handleChange}/>
                            </td>
                            <td colSpan={4}>
                                <button style={{float: 'right'}} onClick={() => this.getProperties()}>Search</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <table className={'tb'} style={{width: '100%'}}>
                    <thead>
                    {
                        this.state.dataToDisplay.length>0?

                            <tr><th><input type={'checkbox'}/></th>
                                <th><span>Council</span></th>
                                <th><span>Location</span></th>
                                <th><span>Block</span></th>
                                <th><span>LOT Number</span></th>
                                <th><span>Reg.Plan Number</span></th>
                                <th><span>Plan Number</span></th>
                                <th><span>Legal Area</span></th>
                                <th><span>Unit</span></th>
                                <th><span>Use</span></th>
                            </tr>:''
                    }
                    </thead>
                    <tbody>
                    {this.state.dataToDisplay.map((d,i)=>{

                            return(<tr key={i}>
                                <td><input type={'checkbox'} onClick={this.pustPlot(d)}/></td>
                                <td>{d.districtName}</td>
                                <td>{d.localityName}</td>
                                <td>{d.blockNumber}</td>
                                <td>{d.lotNumber}</td>
                                <td>{d.regPlanNumber}</td>
                                <td>{d.planNumber}</td>
                                <td>{d.legalArea}</td>
                                <td>{d.areaUnitName}</td>
                                <td>{d.uses}</td>
                            </tr>)
                        }
                    )}

                    </tbody>
                </table>
                {this.state.selected.length>0?
                    <div><button onClick={this.addSelected}>Add Selected Plots</button></div>:''}
            </div>
        );
    }
    pustPlot(d){
        let sPlots=this.state.selected
        if(sPlots.contain(d)){

        }else {
            sPlots.push(d)
        }
    }
    addSelected(){
        SelectLegacyPlots.self.setState({selectedSpatial:this.state.selected})
        PopDetail.dismiss()
    }
    getProperties() {
        try {
            // Wait.show("Loading Spatial...")
            let status = "Legacy"
            //TxReadService.get
            console.log("IINN")

            TxReadService.getCouncilName(this.state.councilId).then(d => {
                this.setState({district: d.data.result})
                console.log("district==>", d.data.result)
            })
            TxReadService.getLocationName(this.state.localityId).then(d => {
                this.setState({location: d.data.result})
                console.log("loaction==>", d.data.result)
            })
            let councilName = this.state.district;
            console.log(councilName)
            let spatialData = {
                districtName: councilName,
                localityName: this.state.location,
                blockNumber: this.state.block,
                lotNumber: this.state.lotNumber,
                regPlanNumber: this.state.regPlanNumber,
                planNumber: this.state.planNumber,
                allocationStatusName: status,
                landUseName: this.state.landUseName,
                drawingNumber: this.state.drawingNumber,
                lotType:this.state.lotType,
                legalArea:this.state.legalArea,
                areaUnitName:this.state.areaUnitName,
                spatialUnitId: this.state.spatialUnitId,
            }
            console.log("Spatial Search", spatialData)
            TxCreateService.createGetSpatailStatus(spatialData).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    this.setState({
                        dataToDisplay: r.data.result

                    })
                } catch (e) {
                    console.log(e)
                }
            })

        } catch (e) {
            console.log(e)
        }


    }
}
export default SelectPlots;
