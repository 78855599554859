import API from "../API";

export default class MobileCreateService {


    static createProject(data) {
        return API.ax.post("mobile/create/project", data)
    }
    static createLayer(formData) {
        return API.ax.post('mobile/create/layers', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).catch(e => { console.log(e); })
    }
    static addRTK(formData){
        return API.ax.post('mobile/create/rtk', formData)
    }

    static configDistrict(param) { return API.ax.post(`admin/mobile/create/council`, param).catch((e) => { console.log(e); }) }
    static configVillage(formData) {
        return API.ax.post(`admin/mobile/create/village`, formData,
            { headers: { 'Authorization': sessionStorage.getItem("token"), 'Content-Type': 'multipart/form-services' } }).catch((e) => { console.log(e); })
    }

    static configHamlet(param) { return API.ax.post(`admin/mobile/create/hamlet`, param).catch((e) => { console.log(e); }) }

    static createUser(param) { return API.ax.post(`mobile/create/user`, param).catch((e) => { console.log(e); }) }


    static configQuest(param) { return API.ax.post(`mobile/create/quest`, param).catch((e) => { console.log(e); }) }

    static createOutput(project_id, ref_id, ref = 0, stage = 'complete') {
        return API.ax.get(`mobile/create/output/${project_id}/${ref_id}/${ref}/${stage}`).catch((e) => { console.log(e); })
    }

    static uploadZip(formData) {
        return API.ax.post(`mobile/create/data`, formData, {
            headers: { 'Authorization': sessionStorage.getItem("token"), 'Content-Type': 'multipart/form-services' }
        }).catch((e) => { console.log(e); })
    }

    static uploadDraft(formData) {
        return API.ax.post('mobile/create/draft', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).catch(e => { console.log(e); })
    }

    static askControlNumber(param) { return API.ax.post(`mobile/create/control_number`, param).catch((e) => { console.log(e); }) }


    static addRemark(param) { return API.ax.post(`mobile/create/remark`, param).catch((e) => { console.log(e); }) }

    static uploadPaymentExcel(formData) {
        return API.ax.post('mobile/create/payments', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).catch(e => { console.log(e); })
    }

    static addSignatory(formData) {
        return API.ax.post('mobile/create/signatory', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).catch(e => { console.log(e); })
    }

    static createTranslate(param) {
        console.log(param)
        return API.ax.post(`mobile/create/translation`, param).catch((e) => { console.log(e); })
    }

    static createRoles(param) {
        return API.ax.post(`mobile/create/roles`, param).catch((e) => { console.log(e); })
    }

    static createHamlet(param) {
        return API.ax.post(`mobile/create/hamlet`, param).catch((e) => { console.log(e); })
    }
}
