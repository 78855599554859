import * as React from "react";
import TxCreateService from "../../../../services/transaction/transaction_create";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import TransactionPopup from "../transaction_popup";
import SummaryPane from "../screen/summary";

export default class PaneAddPerson extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            party:{},
            minor:false,
            deceased:false,
            incapacitated:false,
            occupation:'teacher',
            phoneNumber:'',
            email:'',
            tin:''


        }
        this.askNida = this.askNida.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleCheckedChange = this.handleCheckedChange.bind(this)
        this.savePerson = this.savePerson.bind(this)
    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }
    handleCheckedChange= e =>{
        this.setState({[e.target.name]: e.target.checked});
    }
    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>
            <div className={'row'}>
                <div className={'col col-sm-3'}>
                    * Person Nida Id Number
                </div>
                <div className={'col col-sm-7'}>
                    <input style={{width:'100%'}} id={'txt_nida'}/>
                </div>
                <div className={'col col-sm-2'}>
                    <button style={{width:'100%'}} onClick={this.askNida}>Search</button>
                </div>
            </div>

            <hr/>
            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <img alt={'photo1'} style={{height:'192px',width:'192px',marginRight:'8px',background:'#f7f7f7'}} src={'data:image/png;base64,'+this.state.party.photo}/>
                    <img alt={'signature'} style={{height:'192px',background:'#f7f7f7'}} src={'data:image/png;base64,'+this.state.party.signature}/>
                </div>
            </div>
            <br/>
            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <table style={{width:'100%'}}>
                        <tbody>
                        <tr>
                            <td><span>First Name</span></td>
                            <td><span>Middle Name</span></td>
                            <td><span>Last Name</span></td>
                            <td><span>Minor</span></td>
                        </tr>
                        <tr>
                            <td><label style={{width:'100%'}} >{this.state.party.firstName}</label></td>
                            <td><label style={{width:'100%'}} >{this.state.party.middleName}</label></td>
                            <td><label style={{width:'100%'}} >{this.state.party.surName}</label></td>
                            <td><input type={'checkbox'} name="minor" checked={this.state.minor} onChange={(e)=>this.handleCheckedChange(e)}/></td>
                        </tr>
                        <tr>
                            <td><span>Gender</span></td>
                            <td><span>Occupation</span></td>
                            <td><span>Birth Date</span></td>
                            <td>
                                <span>Incapacitated</span>
                                <span>Deceased</span>
                            </td>
                        </tr>
                        <tr>
                            <td><label style={{width:'100%'}} >{this.state.party.sex}</label></td>
                            <td><input style={{width:'100%'}} name={"occupation"}  onChange={(e)=>this.handleChange(e)}/></td>
                            <td><label style={{width:'100%'}}  >{this.state.party.dateOfBirth}</label></td>
                            <td>
                                <input type={'checkbox'} name="incapacitated" checked={this.state.incapacitated} onChange={(e)=>this.handleCheckedChange(e)}/>
                                <input type={'checkbox'} name="deceased" checked={this.state.deceased} onChange={(e)=>this.handleCheckedChange(e)}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}><span>Citizenship</span></td>
                            <td><span>Occupation</span></td>
                        </tr>
                        <tr><td><label style={{width:'100%'}}  >{this.state.party.nationality}</label></td>
                        </tr>
                        <tr>
                            <td><span>Phone Number</span></td>
                            <td><span>Email</span></td>
                            <td><span>TIN</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width:'100%'}} name={"phoneNumber"} onChange={(e)=>this.handleChange(e)}/></td>
                            <td><input style={{width:'100%'}} name={"email"} onChange={(e)=>this.handleChange(e)}/></td>
                            <td><input style={{width:'100%'}} nama={"tin"} onChange={(e)=>this.handleChange(e)}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.savePerson}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }

    askNida() {
        let nida = document.getElementById('txt_nida').value

        if (nida.trim().length<20){
            return
        }

        try {
            Wait.show("Searching...")
            TxCreateService.verifyNida(nida).
            then(d=>{
                Wait.dismiss()
                try {
                    let data = d.data

                    console.log(data)

                    if(data.party!==undefined){
                        this.setState({
                            party:data.party
                        })
                    }

                }catch (e){
                    Wait.dismiss();
                    console.log(e)
                }
            }).catch((e)=>{
                Wait.dismiss()
            })

        }catch (e) {
            console.log(e)
            Wait.dismiss();

        }
    }


    savePerson(){
        let nida = document.getElementById('txt_nida').value
        let datum = {
            t1:1,
            nida:nida,
            occupation:this.state.occupation,
            phoneNumber:this.state.phoneNumber,
            minor:this.state.minor,
            deceased:this.state.deceased,
            incapacitated:this.state.incapacitated,
            tin:this.state.tin,
            email:this.state.email
        };
        //console.log(datum)
        try {
            Wait.show("Saving...")
            TxCreateService.addPerson(datum).then(d=>{
                Wait.dismiss()
                try {
                    let data = d.data

                    console.log(d.data)
                    if(data.status === 1){

                        console.log(TransactionPopup.persons())
                        let persons = TransactionPopup.persons()
                        persons.push(data.party)
                        TransactionPopup.setState('persons',persons)

                        SummaryPane.self.setState({
                            persons:TransactionPopup.persons()
                        })

                    }
                    PopDetail.dismiss()
                }catch (e) {
                    console.log(e)
                }
                //
            })
        }catch (e){
            console.log(e)
        }
    }
}