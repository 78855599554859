import React, {Component} from 'react';
import TxCreateService from "../../../../services/transaction/transaction_create";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import transaction_popup from "../transaction_popup";

class PaneLinkPayment extends Component {
    constructor(props) {
        super(props);
        this.state={
            billId:props.pending.billPaymentId,
            billNumber:props.pending.pid,
            payCntrNum:props.pending.payCntrNum,
            amountPaid:props.pending.billAmt,
            previousBillNumber:'',
            previousControlNumber:'',
            previousTransactionNo:''

        }
        this.handleChange = this.handleChange.bind(this)
        this.linkPayment = this.linkPayment.bind(this)
    }
    handleChange= e => {
        this.setState({[e.target.name]: e.target.value})
    }
    linkPayment=()=>{
        let paymentInfo={
            billId:this.state.billPaymentId,
            billNumber:this.state.pid,
            payCntrNum:this.state.payCntrNum,
            amountPaid:this.state.billAmt,
            previousBillNumber:this.state.previousBillNumber,
            previousControlNumber:this.state.previousControlNumber,
            previousTransactionNo:this.state.previousTransactionNo,
            transactionId:transaction_popup.id()
        }
        console.log("payment info",paymentInfo)
        TxCreateService.saveLinkPayments(paymentInfo).then(d => {
            console.log("payment dada ", d)

            if (d.data.status === 1) {
                Wait.dismiss()
                PopDetail.dismiss()
            }else {

            }

        })
    }

    render() {
        return (
            <div>
                <div className={'col col-sm-12'} style={{width:'100%'}} >
                   <div><i style={{color:'red'}}>*</i>Previous Bill Number</div>
                   <div><input type={'text'} name={'previousBillNumber'} onChange={this.handleChange}/></div>
                   <div><i style={{color:'red'}}>*</i>Previous Control Number</div>
                   <div><input type={'text'} name={'previousControlNumber'} onChange={this.handleChange}/></div>
                   <div><i style={{color:'red'}}>*</i>Previous Transaction No.</div>
                    <div><input type={'text'} name={'previousTransactionNo'}  onChange={this.handleChange}/></div>
                   <div><button style={{color:'red',cursor:'pointer'}} onClick={this.linkPayment}>Link</button><button style={{cursor:'pointer'}}>Cancel</button></div>
                </div>
            </div>
        );
    }
}

export default PaneLinkPayment;
