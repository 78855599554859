import React,{useState, useEffect} from 'react';
import Header from "./gro/header";
import PopDetail from "../../popdetail";
import AddBillItem from "../pops/pane_add_bill_item";
import SelectUnitPlan from "./selectUnitPlan";
import TxCreateService from "../../../../services/transaction/transaction_create";
import TxReadService from "../../../../services/transaction/transaction_read";
import TransactionPopup from "../transaction_popup";
import API from "../../../../services/API";

function UnitTitles(props) {

    const[unitTitleList, setUnitTitleList] = useState([])
    const [refreshUnit, setRefreshUnit] = useState(0)
    useEffect(() => {
        API.ax.get("transaction/getUnit-titles/" + TransactionPopup.id()).then(d => {
          if (d.data.status === 1){
              setUnitTitleList(d.data.result)
              console.log("list st of units",d.data.result)
          }else{
              console.log("Fail to log units")
          }
        }).catch(e =>
            console.log("unit tittle list error on retrieve ", e)
        )
    },[refreshUnit])


    return (
        <div style={{background:'#fff',width:'100%',padding:'24px',height:'47vh',border:'0.5px solid #efefef', overflow:'auto', marginTop:'4px'}}>
            <Header label={'Unit Titles'} display={true}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetail.show("Select Unit Plan", <SelectUnitPlan refreshUnit = {refreshUnit} setRefreshUnit = {setRefreshUnit}/>,30)}}
                    /><strong> Select Unit Plan</strong>
                </div>

            </Header>
            <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                <thead>
                <tr>
                    <th>UNIT NUMBER/NAME</th>
                    <th>UNIT FACTOR</th>
                    <th>UNIT DETAILS</th>
                    <th>UNIT AREA (SQ.M)</th>
                    <th>FLOOR AREA (SQ.M)</th>
                    <th>REGISTRATION NO.</th>
                    <th>TITTLE NO.</th>
                    <th>TYPE</th>
                    <th>TENURE</th>
                </tr>

                </thead>
                <tbody>
                {unitTitleList == null ?
                    <tr ><td colSpan="9" style={{textAlign: 'center'}}>No Unit's</td></tr>
                    :
                        unitTitleList.map((d,i) => {
                            return(
                                <tr key = {i}>
                                    <td> {d.unitNumbername}</td>
                                    <td> {d.unitFactor}</td>
                                    <td> {d.unitDetails}</td>
                                    <td> {d.unitArea}</td>
                                    <td> {d.floorAre}</td>
                                    <td> {d.registrationNo}</td>
                                    <td> {d.unitNumbername}</td>
                                    <td> Unit</td>
                                    <td> {d.unitNumbername}</td>
                                </tr>
                            )
                        })
                }

                </tbody>
            </table>

        </div>
    );
}

export default UnitTitles;