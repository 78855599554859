import React from "react";

export default class LoadCondition extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <table className={'table'}>
                <thead>
                <tr>
                    <th>NO.</th>
                    <th>TYPE</th>
                    <th>CONDITION</th>
                    <th>CATEGORY</th>
                </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        )
    }
}
