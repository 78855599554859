import API from "../API";
import {loadProgressBar} from "axios-progress-bar";

export default class MobileReadService {

    static readProject(project_id,category='info') {
        return API.ax.get(`mobile/config/read/project/${project_id}/${category}`)
            .catch((e)=>{console.log(e);})
    }

    static downloadSHP(project_id,layer_id,locality,stage,owners='',create=true){
        if (create){
            return API.ax.get(`mobile/read/download${owners}?project_id=${project_id}&layer_id=${layer_id}&locality=${locality}&stage=${stage}`)
                .catch((e)=>{console.log(e);})
        }
        return API.ax.get(`mobile/read/zip?project_id=${project_id}&layer_id=${layer_id}$locality=${locality}&stage=${stage}&zipname=zipname`)
            .catch((e)=>{console.log(e);})
    }

    static readVillageConfig(project_id) {
        return API.ax.get(`mobile/config/read/villages/${project_id}`)
            .catch((e)=>{console.log(e);})
    }

    static readHamletConfig(project_id) {
        return API.ax.get(`mobile/config/read/hamlets/${project_id}`)
            .catch((e)=>{console.log(e);})
    }

    static readUsers(project_id,search='all') {
        return API.ax.get(`mobile/read/users/${project_id}/${search}`)
            .catch((e)=>{console.log(e);})
    }

    static readQuestinnares(category_id,sub_category_id) {
        return API.ax.get(`mobile/read/questionnaires/${category_id}/${sub_category_id}`)
            .catch((e)=>{console.log(e);})
    }
    static readParcelMajirani(pid,project){
        return API.ax.get(`mobile/read/parcel/${pid}/${project}/majirani`)
    }

    static readParcels(project_id,tab) {
        return API.ax.get(`mobile/config/read/parcels/${project_id}/${tab}`)
            .catch((e)=>{console.log(e);})
    }

    static downloadParcelByStage(data) {
        return API.ax.post(`mobile/config/read/download/parcels`,data)
            .catch((e)=>{console.log(e);})
    }

    static projectList(filter=0,search='all',region,title) {
        if (search.length===0){
            search = 'all'
        }
        return API.ax.get(`mobile/read/project-list/${filter}/${search}/${region}/${title}`)
            .catch((e)=>{console.log(e);})
    }

    static mobileLayers(project_id,boundary=false,search_name='all') {
        return API.ax.get(`mobile/read/mobile-layers/${project_id}/${boundary}/${search_name}`)
            .catch((e)=>{console.log(e);})
    }

    static readTranslateByCategorySubCategory(category,sub_category,parent_id=0) {
        return API.ax.get(`mobile/read/translate/${category}/${sub_category}/${parent_id}`).catch((e)=>{console.log(e);})
    }

    static readDataByProjectId(mob_project_id,refresh=0,uid=0,locality_id=0,pageindex=1,noOfRecord=API.no_of_records) {

        console.log(`mobile/read/data/${mob_project_id}/${refresh}/${uid}/${locality_id}/${pageindex}/${noOfRecord}`)
        return API.ax.get(`mobile/read/data/${mob_project_id}/${refresh}/${uid}/${locality_id}/${pageindex}/${noOfRecord}`).catch((e)=>{console.log(e);})
    }

    static readUserData(mob_project_id,uid=0) {
        return API.ax.get(`mobile/read/data/${mob_project_id}/${uid}`).catch((e)=>{console.log(e);})
    }

    static listRegions() {
        return API.ax.get('mobile/read/regions').catch((e)=>{console.log(e);})
    }

    static listDistricts() {
        return API.ax.get('mobile/read/districts').catch((e)=>{console.log(e);})
    }

    static listLocality(project_id,has_data=false) {
        return API.ax.get('mobile/read/locality/'+project_id+'/'+has_data).catch((e)=>{console.log(e);})
    }

    static listWards(dist,pid=0,has_data=false) {
        return API.ax.get('mobile/read/wards/'+pid+'/'+dist+'/list/'+has_data).catch((e)=>{console.log(e);})
    }

    static listStreets(ward,project_id,has_data=false) {
        return API.ax.get('mobile/read/streets/'+ward+'/list/'+project_id+'/'+has_data).catch((e)=>{console.log(e);})
    }


    static readParcelInfo(project_id) {
        return API.ax.get('mobile/read/parcel/'+project_id+'/info').catch((e)=>{console.log(e);})
    }


    static readParcelOwners(project_id) {
        return API.ax.get('mobile/read/parcel/'+project_id+'/owners').catch((e)=>{console.log(e);})
    }

    static searchByClaimNo(project_id,claim){
        return API.ax.get('mobile/read/search/claim/'+project_id+'/'+claim.replace('/','_').replace('/','_').replace('/','_')+'/1').catch((e)=>{console.log(e);})
    }

    static readRemark(project_id) {return API.ax.get('mobile/read/remarks/'+project_id).catch((e)=>{console.log(e);})}

    static readPaymentSummary(project_id) {
        return API.ax.get('mobile/read/summary/payments/'+project_id).catch((e)=>{console.log(e);})
    }

    static readReportProcessing(project_id) {
        return API.ax.get('mobile/read/report/'+project_id+'/processing').catch((e)=>{console.log(e);})
    }

    static readReportSummary(project_id,layer_id=0,locality=0,stage=0) {

        return API.ax.get('mobile/read/report/'+project_id+'/summary/'+layer_id+'/'+locality+'/'+stage).catch((e)=>{console.log(e);})
    }

    static readReportTab(project_id,tabid,noOf,page,layer_id=0,locality=0) {
        let url = 'mobile/read/report/'+project_id+'/'+tabid+'/'+noOf+'/'+page+'/'+layer_id+'/'+locality+'/data'
        console.log(url)
        return API.ax.get(url).catch((e)=>{console.log(e);})
    }

    static readSignatories(project_id) {
        return API.ax.get('mobile/read/signatories/'+project_id).catch((e)=>{console.log(e);})
    }

    static listDownloads(project_id) {
        return API.ax.get('mobile/read/project/'+project_id+'/downloads').catch((e)=>{console.log(e);})
    }

    static listSignatories(project_id) {
        return API.ax.get('mobile/read/signatories/'+project_id+'/designation').catch((e)=>{console.log(e);})
    }

    static readParcelPayments(pid) {
        return API.ax.get('mobile/read/parcel/'+pid+'/payments').catch((e)=>{console.log(e);})
    }

    static readCoordinates(pid){
        return API.ax.get('mobile/read/parcel/'+pid+'/coordinates').catch((e)=>{console.log(e);})
    }

    static readProjectAdmin(pid){
        return API.ax.get('mobile/read/project/'+pid+'/admin').catch((e)=>{console.log(e);})
    }

    static isMobileUser() {
        return API.ax.get('mobile/read/has_mobile').catch((e)=>{console.log(e);})
    }

    static loadUsers(pid){
        return API.ax.get('mobile/read/roles/'+pid).catch((e)=>{console.log(e);})
    }
    static readUserReport(project,fromdate,todate){
        return API.ax.get(`mobile/read/report/${project}/${fromdate}/${todate}/users`).catch((e)=>{console.log(e);})
    }
    static readGeneralReport(project,stage,fromdate,todate){
        return API.ax.get(`mobile/read/report/${project}/${stage}/${fromdate}/${todate}/general`).catch((e)=>{console.log(e);})
    }
}
