import * as React from "react";
import uncheck from "../../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import PopDetail from "../../../../popups/popdetail";
import ParcelInfoPane from "../pane/parcel_info_pane";

export default class TbDataCollection extends React.Component{
    constructor(props) {
        super(props);
        this.toggleCheck = this.toggleCheck.bind(this)
    }
    componentDidMount() {

    }

    toggleCheck(i){

    }

    render() {
        return(<div style={{width:'100%',height:'100%', overflow:'auto'}}>
            <table className={'tb'} style={{border:'1px solid #ccc',width:'98%',margin:'1%'}}>
                <thead>
                <tr>
                    <th style={{textAlign:'left',paddingLeft:'8px'}}>
                        <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                    </th>
                    <th>CLAIM DATE</th>
                    <th>CLAIM NUMBER</th>
                    <th>STAGE</th>
                    <th>HAMLET NAME</th>
                    <th>SPATIAL</th>
                    <th>UKA</th>
                    <th>REG. NO</th>
                </tr>
                </thead>
                <tbody>

                {this.props.data.map((d,i)=>{
                    return(<tr key={i}>
                        <td>
                            <img id={'chk_'+i} alt={'chk_'+i}  style={{float:'left'}}
                                 src={uncheck} onClick={()=>this.toggleCheck(i)}/>
                            <span style={{float:'right'}}>
                                {i+1}.
                            </span>
                        </td>
                        <td>{d.claim_date}</td>
                        <td onClick={()=>PopDetail.show(d.claim_no,
                            <ParcelInfoPane category={this.props.category}
                                key={new Date().getMilliseconds()}
                                project_id={this.props.project_id}
                                data={d} tab={0}/>,d)}
                            style={{color:'#DF4A36'}}><b>{d.claim_no}</b></td>
                        <td>{d.stage}</td>
                        <td>{d.hamlet}</td>
                        <td style={{color:'#DF4A36'}}><b>{d.claim_no}</b>
                        </td>
                        <td>{d.uka}</td>
                        <td>{d.regn}</td>
                    </tr>)
                })}

                </tbody>
            </table>
        </div>)
    }
}