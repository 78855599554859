import React, { useState,useEffect} from 'react'
import API from "../../services/API";
import TransactionPopup from "../../components/popups/transacrions/transaction_popup";
import Header from "../../components/popups/transacrions/screen/gro/header";
import {useBuildingDispatch, useBuildingState} from "./store";
import Wait from "../../components/popups/wait";



export default function ValuationSpatial() {
    const[ spUnits, spUnitsSet ] =useState([])
    const[spStatus, spStatusSet] =useState(false)
    const [messageString,messageStringSet] = useState('')
    const[indexe, indexeSet] = useState(-1)
    const dispatch = useBuildingDispatch()
    const state = useBuildingState();
    const link =()=>{
        const{spatialUnitId } = spUnits[indexe]
        //dispatch({type:"spUnit", payload:{spatialUnitId}}) when supporting multiple spatial units
        dispatch({type:"spUnit", payload:spatialUnitId})
        console.log("state",state)
    }
    useEffect(()=>{
        if(state.valuationSpatialUnitVu > 0 && spUnits[indexe] !== undefined){
            const{ legalArea,areaUnitName }=spUnits[indexe]
            dispatch({type:"totalLandArea", payload:legalArea})
            dispatch({type:"unit", payload:areaUnitName})
        }
    },[state.valuationSpatialUnitVu,spUnits])
    const highlight ={
        fontWeight:'bold',
        cursor:'pointer'
    }
    const unHighlight = {
        cursor:'pointer'
    }
    useEffect(()=>{
        Wait.show("Loading Spatial Units....");
        let txId = TransactionPopup.id()
        API.ax.get(`transaction/transaction-spatial/${txId}`)
            .then(d=>{
                console.log("The API",d.data)
                const{result, status, message} = d.data
                if( status === 1 ){
                    spUnitsSet(result)
                    spStatusSet(true)
                }
                else {
                    messageStringSet( message )
                }
            })
            .catch(e=>console.error("error",e))
        Wait.dismiss();
    },[])
    return(
        <>
            <Header display={true} label={"Spatial Unit"}>
                <div>
                    <i className={'fa fa-plus-circle'}
                       style={{color:'tomato', fontSize:'25px', cusor:'pointer'}}
                       onClick={indexe>-1?link:()=>{}}/> <b>Link</b>
                </div>
            </Header>
            <table  style={{width:'100%'}} className={'tb'}>
                <thead>
                <tr>
                    <th>TYPE</th>
                    <th>DISTRICT</th>
                    <th>LOCATION</th>
                    <th>BLOCK</th>
                    <th>NUMBER</th>
                    <th>VALUATION NO.</th>
                    <th>USE TYPE</th>
                    <th>SURVEY AREA</th>
                    <th>LINKED</th>
                </tr>
                </thead>
                <tbody>
                {
                    spStatus?
                    spUnits.map(({lotType, districtName, localityName, blockNumber, lotNumber, landUseName, legalArea, areaUnitName, valuationNo, spatialUnitId },i)=>(
                        <tr key={i} onClick={()=>{indexeSet(i)}}  style={indexe === i?highlight:unHighlight}>
                            <td>{lotType}</td>
                            <td>{districtName}</td>
                            <td>{localityName}</td>
                            <td>{blockNumber}</td>
                            <td>{lotNumber}</td>
                            <td>{valuationNo}</td>
                            <td>{landUseName}</td>
                            <td>{legalArea} {areaUnitName}</td>
                            <td>{spatialUnitId === state.valuationSpatialUnitVu?"Linked":"Not Linked"}</td>
                        </tr>
                    )):<tr><td colSpan={8} style={{textAlign:'center'}}>{messageString}</td></tr>
                }
                </tbody>
            </table>
        </>
    )
}
