import API from "../API";

export default class CLReadService {
    static getCLApproval(transactionId) {
        return API.ax.get("land/lad-approval/" + transactionId).catch(e => {
            console.log(e);
        })
    }

    static updateLastPayDate() {
        return API.ax.get("land/updateLastPayDate").catch(e => {
            console.log(e);
        })
    }


    //updateLastPayDate
}
