import API from "../API";

export default class MobileDeleteService {

    static deleteOwner(pid){ return API.ax.delete(`mobile/delete/owner/${pid}`).catch((e)=>{console.log(e);})}
    static deleteQuestionnaire(qid){return API.ax.delete(`mobile/delete/question/${qid}`,).catch((e)=>{console.log(e);})}
    static deleteSignatory(sid){return API.ax.delete(`mobile/delete/signatory/${sid}`,).catch((e)=>{console.log(e);})}
    static deleteLayer(sid){return API.ax.delete(`mobile/delete/layer/${sid}`,).catch((e)=>{console.log(e);})}
    static deleteUser(sid){return API.ax.delete(`mobile/delete/user/${sid}`,).catch((e)=>{console.log(e);})}
    static deleteProject(pid){return API.ax.delete(`mobile/delete/project/${pid}`,).catch((e)=>{console.log(e);})}
    static deleteDictionary(pid) {return API.ax.delete(`mobile/delete/dictionary/${pid}`,).catch((e)=>{console.log(e);})}
}