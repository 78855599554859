import * as React from "react";
import PopDetail from "../../popdetail";
import MobilePopup from "../mobile_popup";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import Wait from "../../wait";
import ProjectUsersPane from "../screens/project_users";
import GeneralSelect from "../../../../utils/GeneralSelect";
import MobileUpdateService from "../../../../services/mobile/mobile_update";

const partiesOptions = {
    'endpoint':'mobile/read/users/0/all',
    'value':'id',
    'label':'label',
    'placeholder':'Select User/s'
}

export default class PanAddProjectUser extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            username:'',
            supervisor:false,
            phone:'',
            expire:'',
            layer:0,
            imei:'',
            is_active:true,
            layers:[],
            is_new_user:true,
            lst_users:[]
        }

        this.saveUser = this.saveUser.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.loadProjects = this.loadProjects.bind(this)
        this.showNewUser = this.showNewUser.bind(this)
        this.showExistingUser = this.showExistingUser.bind(this)
        this.generalSelect = this.generalSelect.bind(this)
    }
    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}
    handleChangeFile=e=>{this.setState({ coordinate:e.target.files[0] })}

    componentDidMount() {
        this.loadProjects()
        if(this.props.user.id > 0){
            this.setState({
                name:this.props.user.name,
                username:this.props.user.username,
                phone:this.props.user.phone,
                expire:this.props.user.expire,
                supervisor:this.props.user.design,
                imei:this.props.user.imei,
                is_active:this.props.user.is_active,
            })
        }
    }



    loadProjects(){




    }

    showNewUser(){
        this.setState({
            is_new_user:true
        })

    }


    showExistingUser(){
        this.setState({
            is_new_user:false
        })

    }

    generalSelect(arr){
        this.setState({
            lst_users:arr
        })
    }
    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>



            <div className={'row'}>
                <div className={'col col-sm-12'}>

                    <div className={'col col-sm-12'} style={{display:this.props.edit===true?'none':'hidden'}}>
                        <div className={this.state.is_new_user===true?'tab tab-active':'tab'} onClick={()=>this.showNewUser()}>New User</div>
                        <div className={this.state.is_new_user===false?'tab tab-active':'tab'} onClick={()=>this.showExistingUser()}>Existing Users</div>
                    </div>

                    <div className={'col col-sm-12'} style={{marginTop:'24px', display:this.state.is_new_user===false?'none':'inherit'}}>
                        <table style={{width:'100%'}}>
                            <tbody>
                            <tr style={{height:'24px'}}/>
                            <tr>
                                <td style={{color:'#008b8b'}}>* Full Name</td>
                                <td style={{color:'#008b8b'}}>* Username</td>
                                <td> <span style={{color:'#008b8b'}}>* Phone Number </span></td>
                            </tr>
                            <tr>
                                <td><input defaultValue={this.props.user.name} style={{width:'100%'}} name={'name'} onChange={this.handleChange}/></td>
                                <td><input defaultValue={this.props.user.username} style={{width:'100%'}} name={'username'} onChange={this.handleChange}/></td>
                                <td><input defaultValue={this.props.user.phone} style={{width:'100%'}} name={'phone'} onChange={this.handleChange}/></td>
                            </tr>
                            <tr>
                                <td style={{color:'#008b8b'}}>* Account Expire Date</td>
                                <td style={{color:'#008b8b'}}>* Current Project Layer</td>
                                <td style={{color:'#008b8b'}}>* Password</td>
                            </tr>
                            <tr>
                                <td><input defaultValue={this.props.user.expire} type={'date'} style={{width:'100%'}} name={'expire'} onChange={this.handleChange}/></td>
                                <td>
                                    <select style={{width:'100%'}} name={'layer'} onChange={this.handleChange}>
                                        <option value={0}>- Select Project Layer -</option>
                                        {this.props.layers.map((d,i)=>{
                                            return(d.boundary?<option key={i} value={d.id}>{d.label} - {d.name}</option>:' ')
                                        })}
                                    </select>
                                </td>
                                <td><input defaultValue={''} style={{width:'100%'}} name={'password'} onChange={this.handleChange}/></td>

                            </tr>
                            <tr>
                               {/* <td colSpan={2} style={{color:'#008b8b'}}>* IMEI</td>*/}
                                <td style={{color:'#008b8b'}}>* Is Active</td>
                            </tr>
                            <tr>
                                {/*<td colSpan={2}><input defaultValue={this.props.user.imei} style={{width:'100%'}} name={'imei'} onChange={this.handleChange}/></td>*/}
                                <td><input type={'checkbox'} defaultChecked={this.props.user.is_active} name={'is_active'} style={{marginLeft:'16px'}} onChange={this.handleChange}/></td>
                            </tr>
                            </tbody>
                        </table>


                    </div>

                    <div className={'col col-sm-12'} style={{marginTop:'40px', display:this.state.is_new_user===true?'none':'inherit'}}>
                        <br/>
                        <label style={{color:'#008b8b'}}>Select Existing User/s</label><br/>
                        <GeneralSelect  onChanges={this.generalSelect} parameters={partiesOptions} isMulti={true}/>
                        <br/><br/>


                        <select style={{width:'100%'}} name={'layer_existing'} onChange={this.handleChange}>
                            <option value={0}>- Select Project Layer -</option>
                            {this.props.layers.map((d,i)=>{
                                return(d.boundary?<option key={i} value={d.id}>{d.label} - {d.name}</option>:' ')
                            })}
                        </select>

                        <br/> <br/>
                        <label style={{color:'#008b8b'}}>Update User's Password</label><br/>
                        <input defaultValue={''} style={{width:'100%'}} name={'password2'} onChange={this.handleChange} placeholder={'Password'}/>
                        <br/> <br/>

                    </div>



                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={()=>this.saveUser()}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }



    saveUser(){
        let password = this.state.is_new_user?this.state.password:this.state.password2

        let regularExpression  = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        if( !regularExpression.test(password)) {
            alert("The Password Should contain alphanumeric characters and a special character")
           return
        }
        try {
            if(!this.state.is_new_user){

                let users = []

                this.state.lst_users.forEach(d=>{
                    users.push(d.value)
                })

                let layer = this.state.layer_existing

                if(MobilePopup.project().outputName!=='Utambuzi'){
                    if (layer===undefined||layer<1){
                        alert("No Project Layer")
                        return
                    }
                }

                Wait.show('Changing Project Layer....')
                MobileUpdateService.changeProjectLayer({
                    users: users,
                    layer: layer,
                    project:MobilePopup.id()
                }).then(d  =>{
                    try {
                        Wait.dismiss()
                        if(d.data.status===0){
                            alert(d.data.message)
                            return
                        }
                        ProjectUsersPane.loadData()
                        PopDetail.dismiss()
                    }catch (e) {
                        console.log(e)
                    }
                })
                return;
            }

            let prj_id = MobilePopup.id()
            let name = this.state.name
            let username = this.state.username
            let phone = this.state.phone
            let expire = this.state.expire
            let layer = parseInt(this.state.layer)
            let supervisor = this.state.supervisor
            let is_active = this.state.is_active
            //let imei = this.state.imei
            

            if (name.length<1){
                alert("No Full name")
                return
            }
            if (username.length<1){
                alert("No username")
                return
            }
            if (phone.length<10){
                alert("No Phone")
                return
            }
            if (expire.length<10){
                alert("No Expire Date")
                return
            }
            if (layer<1){
               layer = 0
            }
            /*try {
                if (imei.length<6){
                    alert("Invalid IMEI")
                    return
                }
            }catch (e) {
                alert("Invalid IMEI")
                return
            }*/

            Wait.show("Saving...")

            MobileCreateService.createUser({
                user:this.props.user.id,
                project_id:prj_id,
                name:name,
                username:username,
                phone:phone,
                expire:expire,
                layer:layer,
                supervisor:supervisor,
                is_active:is_active,
                password:password
            }).then(d=>{
                Wait.dismiss()
                if(d.data.status===0){
                    alert(d.data.message)
                    return
                }
                ProjectUsersPane.loadData()
                PopDetail.dismiss()
            })
        }catch (e){
            console.log(e)
        }
    }
}