import API from "../API";

export default class TicCreateService  {

    static saveGnInfo(data) {
        return API.ax.post("tic/gn_info",data).catch(e=>{console.log(e);})
    }
    static saveMatrix(transactionId) {

        return API.ax.get("tic/matrix-record/add-status/"+ transactionId).catch(e => {
            console.log(e);})
    }

    static SaveTxApploval(dataToSend) {
        return API.ax.post("tic/matrix-record/decision-status",dataToSend).catch(e=>{console.log(e);})
    }
}

