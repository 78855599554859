import React, {Component} from 'react';

class DeedTabNav extends Component {
    render() {
        return (
            <div>
                <div style={{ width: '100%' }}>
                    <ul className="nav nav-tabs">

                        {
                            this.props.tabs.map(tab => {
                                const active = (tab === this.props.selected ? 'active' : '' );
                                return (
                                    <li className="nav-item" key={ tab }>
                                        <a className={"nav-link " + active } style={ tab === this.props.selected ? { borderTop: '4px solid #ff6347'} : {}} onClick={ () => this.props.setSelected(tab) }>
                                            { tab }
                                        </a>
                                    </li>
                                );
                            })
                        }

                    </ul>
                    { this.props.children }
                </div>
            </div>
        );
    }
}

export default DeedTabNav;