import * as React from 'react'
import '../../../../../layouts.css'
import logo from '../../../../../resource/img/north.jpg';
import coatArms from '../../../../../resource/img/nembo.png';
import { SpatialMapDisplay, getLU } from './mappane.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SMDReadService from '../../../../services/smd/smd_read.js';
import { QRCodeSVG } from 'qrcode.react';
export default function PrintingLayout({ txId, type, sptype }) {
    const printref = React.useRef()
    const sizes = {
        a1l: [594, 841, 'a1', 'p'],
        a1p: [841, 594, 'a1', 'l'],
        a4l: [210, 297, 'a4', 'p'],
        a4p: [297, 210, 'a4', 'l'],
        a2l: [420, 594, 'a2', 'p'],
        a2p: [594, 420, 'a2', 'l'],
        a3p: [297, 420, 'a3', 'l'],
        a3l: [420, 297, 'a3', 'p'],
        // a3p:[]
    }
    const [wh, setWh] = React.useState(sizes.a1l)
    // const wh = [594, 841]
    const getPages = (type) => {
        if (type === "tp") {
            return 1;
        }
        else {
            return 3
        }
    }
    const printPage = () => {
        const content = document.getElementById('printZone').innerHTML;
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
        <html>
        <head>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
            <style>
            .ilmis_print tr{
                border: 1px solid #000;
            }
            .ilmis_print td{
                border: 1px solid #000;
                padding: 5px;
                color: #000;
                font-size: 16px;
                text-align: center;
                padding: 30px;
            }
            .tc{
                text-align: center;
            }
            .print{
                color: #000;
                letter-spacing: 2px;
            }
            .nu{
                height: 35px;
            }
            .ilmis_print{
                text-transform: uppercase;
                width: 100%;
            }
            .print .col-2, .col-4, .col-1, .col-3{
                padding-left: 1px;
                padding-right: 1px;
            }
            .xc{
                margin-right: 2px;
                margin-left: 2px;
            }
            .bld{
                font-weight: bold;
            }
            .tx1{
                background-color: #fff;
                border: 1px solid #000;
                width: 10px;
                height: 10px;
                border-radius: 5px;
            }
            .bcn td{
                border: 1px solid #000;
            }
            @media print {
                @page {
                size: A1;
                }
                #map {
                max-height: none; /* Ensures map takes full size on print */
                }
            }
            </style>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4" crossorigin="anonymous"></script>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossorigin="" />
        <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js" integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossorigin=""></script>
        </head>
        <body>${content}</body>
        </html>
        `);
        printWindow.document.close();
        printWindow.print();
    }
    return (
        <div>
            <div style={{ marginTop: 100 }}>
                <div className='row'>
                    <div className='col-6'>
                        <select onChange={e => { setWh(sizes[e.target.value]) }} className='form-control'>
                            <option value={'a1p'}>A1 Potrait</option>
                            <option value={'a1l'}>A1 Landscape</option>
                            <option value={'a2l'}>A2 Landscape</option>
                            <option value={'a2p'}>A2 Potrait</option>
                            <option value={'a3p'}>A3 Potrait</option>
                            <option value={'a3l'}>A3 Landscape</option>
                            <option value={'a4p'}>A4 potrait</option>
                            <option value={'a4l'}>A4 Landscape</option>
                        </select>
                        <button onClick={printPage}>Download PDF Document</button>
                    </div>
                </div>
            </div>
            <div ref={printref} id="printZone" style={{ padding: 2 }} className='print'>
                {type === "tp" ? <TpLayout wh={wh} txId={txId} type={type} spType={sptype} /> : <SurveyPlan txId={txId} wh={wh} />}
            </div>
        </div>
    )
}
function TpLayout({ txId, type, spType, wh }) {
    let width = wh[0];
    const [tpData, setTP] = React.useState({ acceptedBy: '', drawnBy: '', land_use: [], tpDrawingNumber: '', title: '', approvedBy: "", preparedBy: '', supervisedBy: '' });
    const [, forceUpdate] = React.useState();
    const updateTp = (tp) => {
        console.log(tp)
        setTP(tp)
        forceUpdate({})
    }
    let height = wh[1];
    return (
        <div style={{ width: width + "mm", height: height + "mm", padding: 3, border: "1px solid black", }} className='is'>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "row", marginBottom: 6 }}>
                <SpatialMapDisplay style={{ display: "inline-block", height: height * 0.9 - 4 + "mm", width: width * 0.8 + "mm", border: '1px solid black' }} txId={txId} sptype={"tp"} updateData={updateTp} type={"tp"} />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: 0.9 * height - 4 + "mm", width: 0.2 * width + "mm", border: '1px solid black', marginLeft: 3 }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingTop: 10 }}>
                        <img src={logo} style={{ height: 70, width: 90 }} />
                    </div>
                    <div>
                        {
                            tpData.land_use.map((prp) => (
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: 20 }}>
                                    <table className='ilmis_print'>
                                        <tr>
                                            <td style={{ width: 50, height: 50, textAlign: "center" }}><b>{getLU(prp.name)}</b></td>
                                        </tr>
                                    </table>
                                    <p>{prp.name}</p>
                                </div>
                            ))
                        }
                    </div>
                    <div>
                        <center>
                            <h4><b>Land use Distribution</b></h4>
                            <table className='ilmis_print' style={{ width: "98%", margin: 3 }}>
                                <tr>
                                    <th style={{ width: "60%" }}>Land Use</th>
                                    <th style={{ textAlign: "center", border: "1px solid #000" }}>Number of plots</th>
                                </tr>
                                {
                                    tpData.land_use.map((prop) => (
                                        <tr>
                                            <td>{prop.name}</td>
                                            <td>
                                                <center>{prop.count}</center>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </table>
                            <h4></h4>

                        </center>
                    </div>
                    <div>
                        <center>
                            <h4><b>ADJOINING DRAWINGS</b></h4>
                            <table className='ilmis_print' style={{ width: "98%", margin: 3 }}>
                                {
                                    [["", "", ""], ["", tpData.tpDrawingNumber, ""], ["", "", ""]].map((prop) => (
                                        <tr>
                                            <td className='nu bld'></td>
                                            <td className='nu blc'>{prop[1]}</td>
                                            <td className='nu bld'></td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </center>
                    </div>
                    <div>
                        <table style={{ width: "98%", margin: 3, textAlign: "center" }} className='ilmis_print'>
                            <tr>
                                <td>PREPARED BY</td>
                            </tr>
                            <tr>
                                <td>{tpData.preparedBy}</td>
                            </tr>
                        </table>
                        <table style={{ width: "98%", margin: 3, textAlign: "center" }} className='ilmis_print'>
                            <tr>
                                <td>QUALITY CHECKED BY</td>
                            </tr>
                            <tr>
                                <td>{tpData.checkedBy}</td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
            <div className='row xc'>
                <div className='col-1 c'>
                    <table className='ilmis_print' style={{ height: wh[1] * 0.097 + "mm", width: "100%" }}>
                        <tr>
                            <td>SCALE</td>
                        </tr>
                        <tr>
                            <td>Drawing number<br />
                                <b>{tpData.tpDrawingNumber}</b></td>
                        </tr>
                    </table>
                </div>
                <div className='col-3 c'>
                    <table className='ilmis_print' style={{ height: wh[1] * 0.097 + "mm", width: "100%" }}>
                        <tr>
                            <td className='nu'>NO</td>
                            <td className='nu'>Revision</td>
                            <td className='nu'>Date</td>
                        </tr>
                        {
                            [0, 0, 0].map((prop) => (
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tr>
                            ))
                        }
                    </table>
                </div>
                <div className='col-4 c'>
                    <table className='ilmis_print' style={{ height: wh[1] * 0.097 + "mm", width: "100%" }}>
                        <tr>
                            <td>{tpData.title}</td>
                        </tr>
                        <tr>
                            <td>MINISTRY OF LANDS HOUSING and housing development<br />
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='col-2 c'>
                    <table className='ilmis_print' style={{ height: wh[1] * 0.097 + "mm", width: "100%" }}>
                        <tr>
                            <td className='nu'>APPROVED BY</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>APPROVED DATE</td>
                        </tr>
                        <tr>
                            <td>{ }</td>
                        </tr>

                    </table>
                </div>
                <div className='col-2 c'>
                    <table className='ilmis_print' style={{ height: wh[1] * 0.097 + "mm", width: "100%" }}>
                        <tr>
                            <td>Supervised by</td>
                        </tr>
                        <tr>
                            <td><b>{tpData.supervisedBy}</b></td>
                        </tr>
                        <tr>
                            <td>accepted By<br />
                                <b>{tpData.acceptedBy}</b></td>
                        </tr>

                        <tr>
                            <td className='nu'>accepted date</td>
                        </tr>

                    </table>
                </div>

                {/* <table className='ilmis_print' style={{ width: "100%", marginTop: 3, height: "80%" }}>
                    <tr>
                        <td className='tc' style={{ width: "8%" }} rowSpan={2}>SCALE: 1:2500</td>
                        <td className='tc' style={{ width: "3%" }}>No</td>
                        <td className='tc' style={{ width: "10%" }}>Revision</td>
                        <td className='tc' style={{ width: "3%" }}>Date</td>
                        <td rowSpan={3} style={{ width: "47%" }} className='tc'>{tpData.title}</td>
                        <td className='tc' style={{ width: "15%" }}>APPROVED BY<br />Kronix</td>
                        <td className='tc' rowSpan={2} style={{ width: "15%" }}>DESIGNED BY</td>
                    </tr>
                    <tr>
                        <td className='nu'></td>
                        <td className='nu'></td>
                        <td className='nu'></td>
                        <td className='nu' rowSpan={4}>NAME</td>
                    </tr>
                    <tr>
                        <td className='tc' rowSpan={3}>
                            Drawing No <br />
                            <b>{tpData.tpDrawingNumber}</b>
                        </td>
                        <td className='nu'></td>
                        <td></td>
                        <td></td>
                        <td>SUPERVISED BY</td>
                    </tr>
                    <tr>
                        <td className='nu'></td>
                        <td></td>
                        <td></td>
                        <td rowSpan={2} style={{ textAlign: "center" }}>MINISTRY OF LANDS HOUSING, HUMAN SETTLMENTS </td>
                        <td className='nu'>CHECKED BY</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='nu'>Supervisor</td>
                    </tr>
                </table> */}
            </div>
        </div>
    )
}

function SurveyPlan({ txId, wh }) {
    let width = wh[0];
    const [spData, setSP] = React.useState({ plotNums: '', crs: 'EPSG:32736', approvedDate: '2023-03-12', rpn: 'L900003', region: 'Dodoma', council: 'Mpwapwa', instruction: 'DOM/1002', locality: 'Area C', drawnBy: '', land_use: [], tpDrawingNumber: '', title: '', approvedBy: "", acceptedBy: '', preparedBy: '', supervisedBy: '', office: { name: '', fax: '', tel: '', website: '', email: '', box: '', postalAddress: '', officePhone: '' } });
    const [, forceUpdate] = React.useState();
    const [coords, setCoords] = React.useState([]);
    const updateTp = (tp) => {
        console.log(tp)
        setSP(tp)
        forceUpdate({})
    }
    React.useEffect(() => {
        SMDReadService.listIPC(txId).then((r) => {
            setCoords(r.data)
        });
        SMDReadService.read(txId).then((r) => {
            // setSP(r.data)
        });
    }, []);
    let height = wh[1];
    return (
        <div>
            <div id="page1" style={{ width: width + "mm", height: height + "mm", padding: 3 }}>
                <div className='row' style={{ alignItems: "center" }}>
                    <div className='col-4'>
                        {spData.office.name}<br />
                        Address: {spData.office.postalAddress}<br />
                        Fax: No. {spData.office.fax}<br />
                        Tel: {spData.office.officePhone}<br />
                        Email: {spData.office.email}<br />
                        Website: {spData.office.website}

                    </div>
                    <div className='col-4'>
                        <center>

                            <img src={coatArms} style={{ height: 100, width: 100 }} />

                            <h6>THE UNITED REPUBLIC OF TANZANIA
                                <br />MINISTRY OF LANDS, HOUSING AND HUMAN SETTLMENTS DEVELOPMENT
                                <br />SURVEY AND MAPPING DIVISION
                                <br />SURVEY PLAN</h6>
                        </center>
                    </div>
                    <div className='col-4'>
                        <div style={{ float: "right" }}>
                            <QRCodeSVG value={'Transaction Number: ' + txId + ', RPN: ' + spData.rpn} height={100} width={100} />
                        </div>
                    </div>
                    <div className='col-12 uppercase'>
                        <center>
                        </center>
                    </div>
                    <div className='col-12 uppercase'>
                        <center>

                            <p><b>SURVEY OF PLOTS NO {spData.plotNums} AT {spData.locality} {spData.council} {spData.region}</b></p>
                        </center>
                    </div>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-4'>
                                <p>Registered Plan Number: <b>{spData.rpn}</b></p>
                            </div>
                            <div className='col-4'>
                                <p>Instruction to Survey No : <b>{spData.instruction}</b></p>
                            </div>
                            <div className='col-4'>
                                <p>Transaction Number : <b>{txId}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={logo} style={{ height: 100, position: "relative", top: 101, float: "right", right: 1, zIndex: 900 }} />
                <SpatialMapDisplay updateD={updateTp} computation={false} style={{ display: "inline-block", height: height * 0.85 - 4 + "mm", width: "100%", border: '1px solid black', backgroundColor: "#fff" }} txId={txId} sptype={"sp"} updateData={updateTp} type={"sp"} />
                <div className='row'>
                    <div className='col-2'>
                    </div>
                    <div className='col-8'>
                        <p>Plan generated from parcels approved on <b>{spData.approvedDate}</b> by {spData.approvedBy}, Surveyor incharge </p>
                        <p>I hereby certify that the survey represented by this plan was carried out in accordance with the survey regulations.</p>
                    </div>
                    <div className='col-2'>
                        <div style={{ width: "100%", height: 20, border: "1px solid #000" }}>
                        </div>
                        <p>
                            {spData.approvedBy}<br />
                            Surveyor incharge
                        </p>
                    </div>
                </div>
            </div>
            <div id="page2" className='uppercase' style={{ width: width + "mm", height: height + "mm", padding: 3 }}>
                <h4>coordinates list (Easting,Northings): TAREF11 / {spData.crs}</h4>
                <div className='row'>
                    {
                        splitArray(coords).map((prop, key) => (

                            <div className='col-4'>
                                <table className='bcn'>
                                    <tr>
                                        <th>IPC Name</th>
                                        <th>X</th>
                                        <th>Y</th>
                                    </tr>
                                    {
                                        prop.map((prop, key) => (
                                            <tr>
                                                <td>{prop.beaconNum}</td>
                                                <td>{Number(prop.xx).toFixed(3)}</td>
                                                <td>{Number(prop.yy).toFixed(3)}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}
function splitArray(array) {
    const length = array.length;
    const chunkSize = Math.ceil(length / 3);
    const result = [];
    let startIndex = 0;

    for (let i = 0; i < 3; i++) {
        const endIndex = Math.min(startIndex + chunkSize, length);
        result.push(array.slice(startIndex, endIndex));
        startIndex = endIndex;
    }

    return result;
}
