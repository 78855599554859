import * as React from "react";
import {styles} from "../record-request";
import API from "../../../../../services/API";
import TransactionPopup from "../../transaction_popup";
import PopDetail from "../../../popdetail";
import BaUnitTitle from "./baUnitTitle";
import DocumentsPane from "../documents";

export default class OccupancyEditInterest extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            todayDate(){
                const today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                const yyyy = today.getFullYear();
                const toDay = yyyy+'-'+mm + '-' + dd;
                return toDay;
            },
            interest:{},
            rrrType:'',
            tenancyTypeName:'',
            fdNumber:'',
            tenureDurationName:'',
            startDate:null,
            endDate:null,
            particulars:'',
            registrationDateTime:null,
            deregistrationDateTime:null,
            consideration:0.00,
            currentMenu:0


        }
        this.handleTerm =this.handleTerm.bind(this)
        this.handleCommencement =this.handleCommencement.bind(this)
        this.handleChange =this.handleChange.bind(this)
        this.saveInterest =this.saveInterest.bind(this)


    }
    componentDidMount() {
        API.ax.get(`/land/interest/edit/${TransactionPopup.id()}`)
            .then(d=>{
                console.log("the result ",d.data. result)
                if(d.data.status === 1){
                   this.setState({interest:d.data.result,
                       rrrType:d.data.result.rrrType,
                       tenancyTypeName:d.data.result.tenancyTypeName,
                       fdNumber:d.data.result.fdNumber,
                       tenureDurationName:d.data.result.tenureDurationName,
                       startDate:d.data.result.startDate,endDate:d.data.result.endDate,
                       particulars:d.data.result.particulars,
                       registrationDateTime:d.data.result.registrationDateTime,
                       deregistrationDateTime:d.data.result.deregistrationDateTime,
                       consideration:d.data.result.consideration

                     })
                }
            })
            .catch(e=>{
                console.log("there ",e)
            })
    }

    saveInterest=()=>{
        let interestDto={
            ...this.state.interest,
            rrrType:this.state.rrrType,
            tenancyTypeName:this.state.tenancyTypeName,
            fdNumber:this.state.fdNumber,
            tenureDurationName:this.state.tenureDurationName,
            startDate:this.state.startDate,
            endDate:this.state.endDate,
            particulars:this.state.particulars,
            registrationDateTime:this.state.registrationDateTime,
            deregistrationDateTime:this.state.deregistrationDateTime,
            consideration:this.state.consideration
        }

console.log("Interest dto ---- ===>",interestDto)

        API.ax.post("/land/interestedit",interestDto)
            .then(d=>{
                const{status, result} = d.data
                if(status === 1){
                    console.log("Interest ==>",d.data.result)
                    //addInterestSet(false)
                }
            })
            .catch(e=>{
                console.log(e)
            })
        PopDetail.dismiss()


    }
    handleTerm =e=>{
        this.setState({tenureDurationName:e.target.value})

    }
    handleCommencement=e=>{

        if(this.state.tenureDurationName !== null && this.state.tenureDurationName !== "custom"){
            let date = new Date(e.target.value);
            let year = date.getFullYear();
            let month = date.getMonth();
            let day = date.getDate();
            console.log("YEAR ==>",year)

            let sum = year + parseInt(this.state.tenureDurationName);
            let endDate = sum+"-"+month+"-"+day;
            this.setState({startDate:date,endDate:new Date(endDate)})
        }

    }
    handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }

    render() {

        return(<div>

            <table style={styles.table}>
                <tbody>
                <tr>
                    <td>Type</td>
                    <td>Tenancy Type</td>
                    <td>FD Number</td>
                </tr>
                <tr>
                    <td><input type={"text"} style={styles.table} readOnly={true} defaultValue={this.state.rrrType} name={"rrrType"} onClick={this.handleChange}/></td>
                    <td><select style={styles.table} name={"tenancyTypeName"} defaultValue={this.state.tenancyTypeName} onClick={this.handleChange} >
                        <option value={""}/>
                        <option value={"Single Occupancy"}>Single Occupancy</option>
                        <option value={"Joint Occupancy"}>Joint Occupancy</option>
                        <option value={"Occupancy in Common in Equal Share"}>Occupancy in Common in Equal Share"</option>
                        <option value={"Occupancy in Common in Unequal Share"}>Occupancy in Common in Unequal Share</option>
                    </select>
                    </td>
                    <td><input type={"text"} style={styles.table} name={"fdNumber"}  defaultValue={this.state.fdNumber} onClick={this.handleChange}/></td>
                </tr>
                <tr>
                    <td>Term</td>
                    <td>Commencement Date</td>
                    <td>End Date</td>
                </tr>
                <tr>
                    <td><select name={"tenureDurationName"} defaultValue={this.state.tenureDurationName} onChange={this.handleTerm} style={styles.table}>
                        <option value={""}/>
                        <option value={"custom"}>Custom</option>
                        <option value={"5"}>Five(5) Years</option>
                        <option value={"99"}>Ninety Nine (99) Years</option>
                        <option value={"66"}>Sixty Six (66) Years</option>
                        <option value={"33"}>Thirty Three (33) Years</option>
                    </select></td>
                    <td>
                        <input type={"date"} name={"startDate"} defaultValue={this.state.startDate} onChange={this.handleCommencement} style={styles.table} onClick={this.handleChange}/>
                    </td>
                    <td>
                        {
                            this.state.tenureDurationName === null || this.state.tenureDurationName !== "custom" ?<input type={"text"} value={this.state.endDate} readOnly={true} name={"endDate"} style={styles.table} />
                                : <input type={"date"} name={"endDate"}  value={this.state.endDate} style={styles.table} />
                        }
                    </td>
                </tr>
                <tr>
                    <td>Particulars</td>
                </tr>
                <tr>
                    <td colSpan={3}><textarea cols={3} rows={3} style={{...styles.table,...styles.txArea}} name={"particulars"} defaultValue={this.state.particulars} onClick={this.handleChange} /></td>
                </tr>
                <tr>
                    <td>Registration Date/Time</td>
                    <td>Deregistration Date</td>
                    <td>Consideration</td>
                </tr>
                <tr>
                    <td><input type={"text"} name={"registrationDateTime"} readOnly={true} defaultValue={this.state.registrationDateTime} style={styles.table}  /></td>
                    <td><input type={"text"} name={"deregistrationDateTime"} readOnly={true} defaultValue={this.state.deregistrationDateTime} style={styles.table}  /></td>
                    <td><input type={"number"} min={0} defaultValue={this.state.consideration} name={"consideration"} style={styles.table} onClick={this.handleChange} /></td>
                </tr>
                </tbody>
            </table>
            <div className={"row"} style={styles.detailsHeader}>
                <div  style={this.state.currentMenu === 0?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{this.setState({currentMenu:0})}}><label style={styles.headerLabels}>Properties/Title</label></div>
                <div  style={this.state.currentMenu === 1?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{this.setState({currentMenu:1})}}><label style={styles.headerLabels}>Documents</label></div>
            </div>
            <div style={this.state.currentMenu === 0?styles.show:styles.hide}>
                <BaUnitTitle heading={false}/>
            </div>
            <div style={this.state.currentMenu === 1?styles.show:styles.hide}>
                <DocumentsPane/>
            </div>
            <button onClick={this.saveInterest}>Save</button>
        </div>)
    }
}
