import React,{useState,useEffect} from 'react';
import {useForm} from "react-hook-form";
import TxReadService from "../../../../services/transaction/transaction_read";
import TransactionPopup from "../transaction_popup";
import API from "../../../../services/API";
import TxCreateService from "../../../../services/transaction/transaction_create";
import PopDetails from "../../../../componentss/popdetails";
import PopDetail from "../../popdetail";
import Message from "./gro/person/message";

function SelectUnitPlan(props) {

    const{register,handleSubmit} = useForm()
    const[planId,setPlanId] = useState([])

    useEffect(() => {
            API.ax.get("transaction/getPlanID/" + TransactionPopup.id()).then(d => {
                setPlanId(d.data.result)
            console.log("Unit plan id for register unit title",d)
        }).catch(e => {
                console.log("plan id's error", e)
            })
    },[])
    const details = data => {
        const dataToSend = {
            planID:data.planID,
           transactionId:TransactionPopup.id()

        }
        API.ax.post("transaction/save-tx-unit-plan", dataToSend).then(d => {
            console.log("plan id saved", d)
            if (d.data.status === 1){
                if(props.refreshUnit === 0){
                    props.setRefreshUnit(1)
                }else {
                    props.setRefreshUnit(0)
                }
                PopDetails.dismiss()
            }
            if (d.data.status === 0){
                PopDetail.dismiss()
                PopDetail.show("Error",<Message faIcon={"hazard"} message={d.data.message}/>,40)
            }


        }).catch(e => {
            console.log("save plan Id error", e)
        })
    }
    return (
        <div>
           <form onSubmit={handleSubmit(details)}>
               <label>*Type<br/>
                   <select className={"select"} style={{width: "100%", marginRight: "500px"}} name={"planID"} ref={register({required: true})} >
                       <option value={"pokop"}/>
                       {planId.map((d,i)=>{
                           return(<option key={i} value={d}>{d}</option>)
                       })}
                   </select>
               </label>
               <br/>
               <button type={'submit'} style={{width:'90px',paddingBottom:'.3rem'}}>Save</button>
           </form>
        </div>
    );
}

export default SelectUnitPlan;