import * as React from "react";
import API from "../services/API";
import Wait from "../components/popups/wait";

function NidaValidation({ data, nin, close, owner }) {
    const [nida, setNida] = React.useState({ firstName: '', middleName: '', surName: '', sex: '', dateofbirth: '' });
    React.useEffect(() => {
        if (/^[a-zA-Z]+$/.test(nin)) {
            setNida({ firstName: "Invalid" })
        }
        else {
            Wait.show("Loading NIDA Information please wait");
            var isnin = nin.replace(/\s/g, '');
            isnin = isnin.replace(/-/g, '');
            // isnin = isnin.replace("-", "");
            // alert(isnin)
            API.ax.post('transaction/verify-nida', isnin).then((r) => {
                Wait.dismiss();
                if (r.data.party !== undefined) {
                    setNida(r.data.party)
                }
                else {
                    setNida({ firstName: 'INVALID', middleName: 'INVALID', surName: 'INVALID', sex: '', dateofbirth: '' })
                }
            }).catch((e) => {
                Wait.dismiss();
            });
        }

    }, [nin]);
    const useNida = () => {
        document.getElementById("fullname"+owner).value = nida.firstName + " " + nida.middleName + " " + nida.surName;
        document.getElementById("jinsia"+owner).value = nida.sex;
        close.update();
    }
    return <div onClick={() => { }} style={{ display: "block", position: "fixed", width: "100%", height: "100%", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255,255,255,0.5)", zIndex: 23309090909877687 }}>
        <div style={{ margin: '20%', marginTop: 10 }}>
            <div style={{ backgroundColor: "#fff", padding: 10 }}>

                <table className="table table-bordered">
                    <tr>
                        <th>INFO</th>
                        <th>SITE DATA</th>
                        <th>NIDA DATA</th>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td><div style={{ display: "flex", flexDireciton: "row", justifyContent: "space-between" }}><div>{data.fullname}</div> <button className={'btn btn-info'} onClick={useNida}>Use Nida Names</button></div></td>
                        <td>{nida.firstName} {nida.middleName} {nida.surName}</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>{data.jinsia}</td>
                        <td>{nida.sex}</td>
                    </tr>
                    <tr>
                        <td>Photo</td>
                        <td><img style={{ width: 200 }} src={"data:img/png;base64, " + data.dp_photo} /></td>
                        <td><img style={{ width: 200 }} src={"data:img/png;base64, " + nida.photo} /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan={2}><button className={'btn btn-prmary'} onClick={close.close}>Close</button></td>
                    </tr>
                </table>
            </div>

        </div>
    </div>;
}

export default NidaValidation;
