import React from "react";
import MobileCreateService from "../../../../../services/mobile/mobile_create";
import Wait from "../../../wait";
import MobilePopup from "../../mobile_popup";
import GeneralSelect from "../../../../../utils/GeneralSelect";
import MobileReadService from "../../../../../services/mobile/mobile_read";
import uncheck from "../../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import PopDetail from "../../../popdetail";
import MobileUpdateService from "../../../../../services/mobile/mobile_update";

const partiesOptions = {
        'endpoint':'user/users',
        'value':'userId',
        'label':'userName',
        'placeholder':'Select User/s'
    }

export default class ProjectInfo extends React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            projectName:'',
            outputName:'',
            projectAuthority:'',
            projectLocation:'',
            projectCode:'',
            reason:''
        }


        ProjectInfo.self = this

        this.handleChange = this.handleChange.bind(this)
        this.generalSelect = this.generalSelect.bind(this)
        this.updateProjectInfo = this.updateProjectInfo.bind(this)
        ProjectInfo.loadProjects = ProjectInfo.loadProjects.bind(this)
    }

    handleChange= e =>{ this.setState({[e.target.name]: e.target.value}); }

    componentDidMount() {
        ProjectInfo.loadProjects()
    }

    static loadProjects(){
        Wait.show('Loading Projects...')
        try {
            document.getElementById('tr_loading_info').style.visibility='visible'
            ProjectInfo.self.setState({projects:[]})

            MobileReadService.projectList(MobilePopup.id()).then(d=>{
                document.getElementById('tr_loading_info').style.visibility='hidden'
                Wait.dismiss()
                try {
                    let data = d.data.results[0]
                    ProjectInfo.self.setState({
                        projectName:data.projectName,
                        outputName:data.outputName,
                        projectAuthority:data.projectAuthority,
                        projectLocation:data.projectLocation,
                        projectCode:data.projectCode
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    generalSelect(arr){
        this.setState({
            lst_users:arr
        })
    }

    updateProjectInfo(){
        if (this.state.reason.length<5){
            alert('No / Weak Update Reason..')
            return
        }
        try {
            Wait.show('Updating...')
            MobileUpdateService.updateProjectInfo({
                projectCode: this.state.projectCode,
                outputName:this.state.outputName,
                projectAuthority:this.state.projectAuthority,
                projectLocation:this.state.projectLocation,
                projectId: MobilePopup.id(),
                reason:this.state.reason
            }).then(r  =>{
                ProjectInfo.loadProjects()
            })
        }catch (e) {
            console.log(e)
        }
    }

    render() {
        return(<div style={{padding:'24px',width:'100%'}}>

            <table style={{width:'100%'}}>

                <tbody>

                <tr>
                    <td>PROJECT NAME</td>
                    <td>PROJECT OUTPUT</td>
                    <td>PROJECT AUTHORITY</td>
                    <td>PROJECT LOCATION</td>
                    <td>CODE</td>
                </tr>
                <tr>
                    <td>
                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.projectName} name={'projectName'} onChange={this.handleChange}/>
                    </td>
                    <td>
                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.outputName} name={'outputName'} onChange={this.handleChange}/>
                    </td>
                    <td>
                        <select style={{width:'100%'}} defaultValue={this.state.projectAuthority} name={'projectAuthority'} onChange={this.handleChange}>
                            <option value={''}>- Select One -</option>
                            <option value={'Halmashauri ya Jiji'}>City Council</option>
                            <option value={'Halmashauri ya Manispaa'}>Municipal Council</option>
                            <option value={'Halmashauri ya Wilaya'}>District Council</option>
                            <option value={'Halmashauri ya Town'}>Town Council</option>
                        </select>
                    </td>
                    <td>
                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.projectLocation} name={'projectLocation'} onChange={this.handleChange}/>
                    </td>
                    <td>
                        <input type={'text'} style={{width:'100%'}} defaultValue={this.state.projectCode} name={'projectCode'} onChange={this.handleChange}/>
                    </td>
                </tr>
                <tr>
                    <td>If Update</td>
                </tr>
                <tr>
                    <td colSpan={5}>
                        <textarea placeholder={'State Reason for Update...'} style={{width:'100%'}} name={'reason'} onChange={this.handleChange}/>
                    </td>
                </tr>

                <tr>
                    <td colSpan={5}>
                        <button onClick={this.updateProjectInfo}>Update</button>
                    </td>
                </tr>
                <tr id={'tr_loading_info'}>
                    <td colSpan={7} style={{fontSize:'18px',padding:'24px'}}>Loading.....</td>
                </tr>
                </tbody>
            </table>
        </div>)
    }
}