import React from "react";
import CLReadService from "../../../../services/cl/cl_read";
import TransactionPopup from "../transaction_popup";
import PopDetail from "../../popdetail";
import PaneApplySmdApproval from "../pops/pane_apply_smd_approval";
import smd_read from "../../../../services/smd/smd_read";

class SmdApproval  extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            decision:'Accepted',
            smdSigningOfficer:'',
            applyBtn:<div></div>
        }
        this.handleChange =this.handleChange.bind(this)
    }
    componentDidMount() {

        smd_read.getSmdApproval(TransactionPopup.id()).then(d=>{
            if(d.data.status===1){
                this.setState({decision:d.data.result.rejectionType,smdSigningOfficer:d.data.result.username})
            }
        })
        TransactionPopup.task()==='Approve Transaction'?
            this.setState({applyBtn:<span><span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'} onClick={()=>PopDetail.show('Apply Approve ',<PaneApplySmdApproval decision={this.state.decision}/>,40)}/> Apply</span>}):<></>
    }

    handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }
    render() {
        return(<div>
            <div className={'row'}>
                <div className={'col-sm-4'}><h3>SMD Division Approval</h3></div>
                <div className={'col-sm-2'}>
                    <span> <span style={{color:'red'}}>*</span>Final SMD Decision</span>
                    <select defaultValue={this.state.decision} onClick={this.handleChange}>
                        <option>Accepted</option>
                        <option>Rejected</option>
                    </select>
                </div>
                <div className={'col-sm-2'}>

                    <span>SMD Signing Officer</span>
                    <input name={'smdSigningOfficer'} value={sessionStorage.getItem('username')}/>
                </div>
                <div className={'col-sm-4'} style={{float:'left'}}>
                <span>
                <span style={{marginLeft:'16px'}} className={'fa fa-check-circle-o screen-action'} onClick={this.validateTask} />Validate
                </span>
                    {this.state.applyBtn}
                </div>

            </div>
        </div>)
    }
}
export default SmdApproval