import React from "react";
import Wait from "../../../wait";
import API from "../../../../../services/API";
import wait from "../../../wait";
import {useForm} from "react-hook-form";
import TransactionPopup from "../../transaction_popup";


export const EditPropertyTitle = (props) =>{

    const{register,handleSubmit}= useForm()


    const submitHandler = data=>{
            Wait.show("Saving ...")

            const dataToSend = {
                titleNumber: data.titleNumber,
                transactionId: TransactionPopup.id()
            }
            console.log("title number",dataToSend)

            API.ax.post(`/transaction/add-title-number`, dataToSend)
                .then(d=>{

                    console.log("api status return",d)
                    if(d.status === 201){
                        console.log('Title changed Successful')
                        wait.dismiss()
                    }else {
                        wait.dismiss()
                        console.log("the result ", d)
                    }
                })
                .catch(e=>{
                    console.log("there ",e)
                })

        Wait.dismiss()
    }

    return(<div>
            <form onSubmit={handleSubmit(submitHandler)}>
                <div style={{display:"flex", padding:"5px"}}>
                    <input name={"titleNumber"} ref={register({required:true})} type={"text"} style={{width:"50%"}} placeholder={"Enter Legacy Title Number to Add"}/>
                    <button style={{width:"150px"}} type={'submit'}>Add</button>
                </div>
            </form>
    </div>)
}
