import  React, { useState, useEffect } from "react";
import PopDetail from "../../popdetail";
import Header from "./gro/header";
import TransactionPopup from "../transaction_popup";
import API from "../../../../services/API";
import Message from "./gro/person/message";
import PaneProcessPayment from "../pops/pane_process_payment";
import Wait from "../../wait";
import AddBillItem from "../pops/pane_add_bill_item";
import PaneLinkPayment from "../pops/pane_link_payment";
export default function PaymentPane({writable}){
    const txId = TransactionPopup.id();
    const[bills,billsSet] = useState([])
    const[bill,billSet] = useState({})
    const[items,itemsSet] = useState([])
    const [itemAdd,itemAddSet] =useState({'pid':0,total:0})
    const[payer,payerSet] = useState([])
    const[index,indexSet] = useState(-1)
    const[currentTab,currentTabSet] = useState(1)
    const [pendingBill,pendingBillSet] =useState({})
    useEffect(()=>{
        console.log("the tx id ",txId)
        API.ax.get(`finance/get-bills/${txId}`)
            .then(d=>{
                console.log("PAYMENTS =>",d)
                if(d.data.status === 0){
                    console.log("Paymentsss",d.data.message)
                }
                if (d.data.status===1){
                    const{result} = d.data;
                    console.log("bill", result)
                    billsSet(result)
                }

            })
    },[])
    useEffect(()=>{
        if(index !== -1){
            billSet(bills[index].bill)
            itemsSet(bills[index].items)
        }
    },[index])
  const handleCalculateFee = () =>{

        try{
            console.log("the tx id ",txId)
            Wait.show("Generating Bill...")
            API.ax.get(`finance/make-bills/${txId}`)
                    .then(d=>{
                    console.log("PAYMENTS==>",d.data)
                    if(d.data.status === 0){
                        Wait.dismiss()
                        PopDetail.show("Error",<Message faIcon={'harzad'} message={d.data.message}/>,25)
                    }
                    else if(d.data.status === 1){
                        console.log(d.data)
                        console.log("data ",d.data)
                        //if(d.data.result[0].payer.length === 0){
                           // PopDetail.show("Error",<Message faIcon={'harzad'} message={"Please add Party to the Transaction before Proceeding"}/>,25)
                        /*}else {*/
                                const{result} = d.data;
                                console.log("Calculated Bill", result)
                                billsSet(result)
                            Wait.dismiss()

                        //isaya
                          //  PopDetail.show("New Payment",<BillPage billResult={d.data.result[index].bill} itemResult={d.data.result[index].items} payer={d.data.result[index].payer}/>)


                            //PopDetail.show("New Payment",<BillPage/>)
                            //handleCalculateFee
                       // }
                    }
                })
        }catch (e) {
            console.error(e)
        }

    }
const handleRemoval=()=>{
    try {
        console.log("REMOVE BILL", pendingBill)

        API.ax.get(`finance/remove-bill/${pendingBill.billPaymentId}`)
            .then(d => {
                console.log(d.data)
                if (d.data.status === 0) {
                    PopDetail.show("Error", <Message faIcon={'harzad'} message={d.data.message}/>, 25)
                } else if (d.data.status === 1) {
                    const {result} = d.data;
                    console.log("bill", result)
                    billsSet(result)
                } else {

                    //PopDetail.show("New Payment",<BillPage/>)
                    //handleCalculateFee
                }
            })
    }catch (e) {
        console.log(e)
    }
    }
const  handleCheckGepgStatus=()=>{
    try {
console.log("GEPG STATUS")
        Wait.show("Check GEPG  Bill Status ...")
        API.ax.get(`finance/gepg-status/${pendingBill.billId}`)
            .then(d => {
                console.log(d.data)

                if (d.data.status === 1) {
                    billsSet([])
                    const{result} = d.data;
                    console.log("bill", result)
                    billsSet(result)
                    Wait.dismiss()
                    PopDetail.show("Status",<Message faIcon={'info'} message={d.data.message}/>,25)
                }
            })
    }catch (e) {

    }
}
const handleSubmitToGepg=()=>{
        try{
            Wait.show("Submitting Bill To GEPG ...")
            API.ax.get(`finance/post-bill/${txId}`)
                .then(d=>{
                    console.log(d.data)
                    if(d.data.status === 0){
                        Wait.dismiss()
                        PopDetail.show("Error",<Message faIcon={'harzad'} message={d.data.message}/>,25)
                    }
                    else if(d.data.status === 1){
                        console.log(d.data)
                        Wait.dismiss()
                            PopDetail.show("Info",<Message faIcon={'info'} message={d.data.message}/>,25)

                        }else {

                           // PopDetail.show("New Payment",<BillPage/>)
                            //handleCalculateFee
                        }
                    })

        }catch (e) {
            console.error(e)
        }

}
    return(
        <div className={''} style={{height:'92vh', padding:'.1rem'}} >

            <div className={'row'} style={{height:'40vh',marginBottom:'4px', margin:0}}>

                    <div className={'col col-sm-12'} style={{background:'#fff',height:'100%',padding:'1.5rem',border:'0.5px solid #efefef', overflow:'auto'}}>
                        <Header display={writable} label={'Payments'}>
                            <div style={{float:'right'}}>
                                <span>
                                    <i className={'fa fa-plus-circle'}
                                       style={{color:'tomato', fontSize:'25px', cusor:'pointer'}}
                                       onClick={handleCalculateFee}
                                       /*onClick={index >-1 ?handleCalculateFee:()=>{}}*//> <b>New</b>
                                </span>
                                <span>
                                    <i className={'fa fa-edit'}
                                       style={{color:'tomato', fontSize:'25px', cusor:'pointer'}}
                                       onClick={()=>{}}/> <b>Edit</b>
                                </span>
                                <span>
                                    <i className={'fa fa-trash'}
                                       style={{color:'tomato', fontSize:'25px', cusor:'pointer'}}
                                       onClick={handleRemoval}/> <b>Remove</b>
                                </span>
                                <span>
                                    <i className={'fa fa-external-link'}
                                       style={{color:'tomato', fontSize:'25px', cusor:'pointer'}}
                                       onClick={handleSubmitToGepg}/> <b>Submit GePG</b>
                                </span>
                                <span>
                                    <i className={'fa fa-rotate-left'}
                                       style={{color:'tomato', fontSize:'25px', cusor:'pointer'}}
                                       onClick={handleCheckGepgStatus}/> <b>Check GePG Status</b>
                                </span>
                                {TransactionPopup.transaction().taskName==="Wait for Initial Payment"|| TransactionPopup.transaction().taskName==="Wait for Initial Payments" || TransactionPopup.transaction().taskName==="Wait for Final Payment" || TransactionPopup.transaction().taskName==="Wait for Final Payments" ?
                                    <span>
                                    <i className={'fa fa-check green-color'}
                                       style={{color: 'lightgreen',fontSize:'25px', cusor:'pointer'}}
                                       onClick={()=>{PopDetail.show("Process Payment",<PaneProcessPayment pending={pendingBill}/>,70)}}/> <b>Process Payment</b>
                                </span>:""}
                                <span></span>
                            {TransactionPopup.transaction().taskName==="Wait for Initial Payment"|| TransactionPopup.transaction().taskName==="Wait for Initial Payments" || TransactionPopup.transaction().taskName==="Wait for Final Payment" || TransactionPopup.transaction().taskName==="Wait for Final Payments" ?
                                <span>
                                    <i className={'fa fa-chain red-color'}
                                       style={{color: 'red',fontSize:'25px', cusor:'pointer'}}
                                       onClick={()=>{PopDetail.show("Link Payment",<PaneLinkPayment pending={pendingBill}/>,70)}}/> <b>Link Payment</b>
                                </span>:""}
                            </div>
                        </Header>
                        <table className={'tb'} style={{width:'100%'}}>
                            <thead>
                            <tr>
                                <th>BILL NUMBER</th>
                                <th>CONTROL NUMBER</th>
                                <th>SUBMITTED DATE</th>
                                <th>STATUS</th>
                                <th>DUE DATE</th>
                                <th>PAYER</th>
                                <th>PAYER PHONE</th>
                                <th>AMOUNT</th>
                                <th>AMOUNT PAID</th>
                                <th>PAID DATE</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bills.map(({bill},i)=>{
                                return(
                                <tr key={i} onClick={()=>{indexSet(i);if(bill.status ==="Pending"){pendingBillSet(bill) ;itemAddSet({});}}}>
                                <td>{bill.pid}</td>
                                <td>{bill.payCntrNum}</td>
                                <td>{bill.submitdate}</td>
                                <td>{bill.status}</td>
                                <td>{bill.billExpDate}</td>
                                <td>{bill.pyrName}</td>
                                <td>{bill.pyrCellNum}</td>
                                <td>{bill.billAmt}</td>
                                <td>{bill.paidAmt}</td>
                                <td>{bill.receiptDate}</td>

                            </tr>)})}
                            </tbody>
                            <tbody>

                            </tbody>
                        </table>
                    </div>
            </div>

            <div className={'row'} style={{height:'6vh', margin:0}}>

                <div className={'col col-sm-12'} style={{ height:'100%',background:'#fff', padding:'.2rem'}}>
                    <div className={currentTab === 1?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{currentTabSet(1)}}>
                        Bill Items
                    </div>
                    <div className={currentTab === 2?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{currentTabSet(2)}}>
                        + Item
                    </div>
                    <div className={currentTab === 3?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{currentTabSet(3)}}>
                        General
                    </div>
                    <div className={currentTab === 4?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{currentTabSet(4)}}>
                        GEPG
                    </div>
                </div>
            </div>
            <div className={'row'} style={{height:'46vh', margin:0}}>
                <div className={'col col-sm-12'} style={{height:'100%',background:'#fff',padding:'1.5rem', overflow:'auto',border:'0.5px solid #efefef'}}>
                <div style={currentTab===1?{}:{display:'none'}}>
                    <table className={'tb'} style={{width:'100%'}}>
                        <thead>
                        <tr>
                            <th>FEE TYPE</th>
                            <th>CALCULATED</th>
                            <th>AMOUNT</th>
                            <th>CALCULATED DATE</th>
                            <th>SP SUBCODE</th>
                            <th>ACCOUNT</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((d,i)=>{
                            return(
                                <tr key={i}>
                                    <td>{d.feeType}</td>
                                    <td>{d.calculated}</td>
                                    <td>{d.billItemAmt}</td>
                                    <td>{d.entryDatetime}</td>
                                    <td>{d.gfsCode}</td>
                                    <td>{d.account}</td>
                                </tr>
                            )
                        })
                        }
                        </tbody>
                    </table>
                </div>
                    <div style={currentTab===2?{}:{display:'none'}}>
                        <AddBillItem/>
                    </div>
                <div style={currentTab === 3?{}:{display:'none'}} >
                    <table style={{width:'100%'}}>
                        <tbody>
                        <tr>
                            <td><label htmlFor={"transactionNo"}>Transaction No</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"transactionNo"} id={"transactionNo"} readOnly={true} /></td>
                            <td><label htmlFor={"amount"}>Amount</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"amount"} id={"amount"} readOnly={true}/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={"billNumber"}>Bill Number</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"billNumber"} id={"billNumber"}  readOnly={true}/></td>
                            <td><label htmlFor={"amountPaid"}>Amount Paid</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"amountPaid"} id={"amountPaid"} readOnly={true}/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={"controlNumber"}>Control Number</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"controlNumber"} id={"controlNumber"} readOnly={true}/></td>
                            <td><label htmlFor={"submittedDate"}>Submitted Date</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"submittedDate"} id={"submittedDate"} readOnly={true}/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={"erv"}>ERV</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"erv"} id={"erv"} readOnly={true}/></td>
                            <td><label htmlFor={"paymentType"}>Payment Type</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"paymentType"} id={"paymentType"} readOnly={true}/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={"payer"}>Payer</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"payer"} id={"payer"} readOnly={true}/></td>
                            <td><label htmlFor={"dueDate"}>Due Date</label></td>
                            <td><input style={{width: '100%'}}  type={'text'} name={"dueDate"} id={"dueDate"} readOnly={true}/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={"payDate"}>Pay Date</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"payDate"} id={"payDate"} readOnly={true}/></td>
                            <td><label htmlFor={"payerPhone"}>Payer Phone</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"payerPhone"} id={"payerPhone"} readOnly={true}/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={"status"}>Status</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"status"} id={"status"} readOnly={true}/></td>
                            <td><label htmlFor={"payerEmail"}>Payer Email</label></td>
                            <td><input style={{width: '100%'}} type={'text'} name={"payerEmail"} id={"payerEmail"} readOnly={true}/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor={"exempt"}>Exempt</label></td>
                            <td><input type={"checkbox"} style={{width: '100%', height:'45px'}} name={"status"} id={"status"} /></td>
                            <td><label htmlFor={"description"}>Description</label></td>
                            <td><textarea rows={3} style={{width: '100%', resize:'none', float:'left'}} name={"description"} id={"description"} readOnly={true}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={currentTab === 4?{}:{display:'none'}} >
                    <table style={{width:'100%'}} className={'tb'}>
                            <thead>
                            <tr>
                                <th>GEPG TRANSACTION</th>
                                <th>GEPG RECEIPT</th>
                                <th>TRANSACTION DATE</th>
                                <th>PAYER</th>
                                <th>PAYER PHONE</th>
                                <th>AMOUNT PAID</th>
                                <th>PSP NAME</th>
                                <th>PSP RECEIPT</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bill &&
                            <tr>
                                <td>{bill.payRefId}</td>
                                <td>{bill.pspReceipt}</td>
                                <td>{bill.receiptDate}</td>
                                <td>{bill.pyrName}</td>
                                <td>{bill.pyrCellNum}</td>
                                <td>{bill.paidAmt}</td>
                                <td>{bill.pspName}</td>
                                <td>{bill.pspReceipt}</td>
                            </tr>}
                            </tbody>
                        </table>
                </div>
            </div>
            </div>
        </div>)

}
