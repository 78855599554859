import API from "../API";
export default class TxCreateService{

    static verifyNida(data){
        return API.ax.post(`transaction/verify-nida`,data).catch(e=>{console.log(e);})
    }
    static addPerson(data){
        return API.ax.post(`transaction/add-person`,data).catch(e=>{console.log(e);})
    }
    static addParameters(data){
        return API.ax.post(`transaction/project`,data).catch(e=>{console.log(e);})
    }
    static addComment(data){
        return API.ax.post(`transaction/add-comment`,data).catch(e=>{console.log(e);})
    }
    static editComment(data){
        return API.ax.post(`transaction/edit-comment`,data).catch(e=>{console.log(e);})
    }
    static addDocument(data){
        return API.ax.post(`transaction/add-document`,data).catch(e=>{console.log(e);})
    }
    static addDeed(data){
        return API.ax.post(`transaction/add-deed`,data).catch(e=>{console.log(e);})
    }
    static generateReport(data){
        return API.ax.post(`transaction/task-report`,data).catch(e=>{console.log(e);})
    }
    static saveTransaction(data) {
        return API.ax.post(`transaction/save`,data).catch(e=>{console.log(e);})
    }
    static createTransaction(data) {
        return API.ax.post(`transaction/create-transaction`,data).catch(e=>{console.log(e);})
    }
    static completeTask(data){
        console.log(data)
        return API.ax.post(`transaction/task-finalize`,data).catch(e=>{console.log(e);})
    }
    static searchTransactions(searchDTO) {
        return API.ax.post(`transaction/search-transaction`,searchDTO).catch(e=>{console.log(e);})
    }
    static createGetProperties(data){
        return API.ax.post(`land/get-properties`,data).catch(e=>{console.log(e);})
    }
    static selectedPlotsForDemandNotice(data){
        return API.ax.post(`land/get-properties`,data).catch(e=>{console.log(e);})
    }
    static calculateBatchRent(data) {
        return API.ax.post("land/compute-rent-batch",data).catch(e=>{console.log(e);})
    }
    static saveLadCheckList(dataCheckList) {
        return API.ax.post("land/lad-checklist",dataCheckList).catch(e=>{console.log(e);})
    }
    static attachGeneratedReport(data) {
        return API.ax.post("transaction/attach-generated-doc",data).catch(e=>{console.log(e);})
    }
    static createGetSpatailStatus(data){
        return API.ax.post("land/get-spatial-status",data).catch(e=>{console.log(e);})
    }
    static createGetSpatail(param) {
        console.log("IPO",param)
        return API.ax.post("land/get-spatial",param).catch(e=>{console.log(e);})
    }
    static saveInterest(data) {
        return API.ax.post("land/interest",data).catch(e=>{console.log(e);})
    }

    static saveCondition(data) {
        return API.ax.post("land/condition",data).catch(e=>{console.log(e);})
    }

    static validatingTask(data) {
        return API.ax.post("transaction/task-validator",data).catch(e=>{console.log(e);})
    }

    static saveSpatialUnitTransaction(data) {
        return API.ax.post("transaction/transaction-spatial",data).catch(e=>{console.log(e);})
    }

    static saveBaunitTransaction(baunit) {
        return API.ax.post("transaction/transaction-baunit",baunit).catch(e=>{console.log(e);})
    }

    static searchTransaction(state) {
        return API.ax.post("transaction/transaction-search",state).catch(e=>{console.log(e);})
    }
    static invalidateToken(tkn) {
        return API.ax.post("auth/token-invalidate",tkn).catch(e=>{console.log(e);})
    }

    static createPartialBill(dataParam) {
        return API.ax.post("land/partial-payment",dataParam).catch(e=>{console.log(e);})
    }

    static sendControlNumbers(param) {
        console.log(param)
        return API.ax.post("finance/bill-cancellation",param).catch(e=>{console.log(e);})
    }


    static saveProcessPayment(paymentInfo) {
        console.log(paymentInfo)
        return API.ax.post("finance/process-payment",paymentInfo).catch(e=>{console.log(e);})
    }

    static removeBaunit(dataToRemove) {
        return API.ax.post("transaction/remove-baunit-transaction",dataToRemove).catch(e=>{console.log(e);})
    }

    static removeSpatialUnit(spatialToRemove) {
        return API.ax.post("transaction/remove-spatial-transaction",spatialToRemove).catch(e=>{console.log(e);})
    }
    static reassign(dataToSend) {
        return API.ax.post("transaction/actions",dataToSend).catch(e=>{console.log(e);})
    }

    static getPaidBill(dataToSend) {
        return API.ax.post("finance/paid-bill",dataToSend).catch(e=>{console.log(e);})
    }

    static saveTransactionPaidBill(dataToSave) {
        return API.ax.post("transaction/paid-bill",dataToSave).catch(e=>{console.log(e);})
    }

    static saveBaunitTransactionTransfer(baunitDataTo) {
        return API.ax.post("transaction/transaction-baunit-transfer",baunitDataTo).catch(e=>{console.log(e);})

    }

    static saveTransferParameters(valuationDto) {
        return API.ax.post("transaction/transaction-transfer-parameters",valuationDto).catch(e=>{console.log(e);})
    }


    static getTransactions(param) {
        return API.ax.post("transaction/advance-search-transaction",param).catch(e=>{console.log(e);})
    }

    static createBaUnitTransaction(data) {
        return API.ax.post("land/baunit_transaction",data).catch(e=>{console.log(e);})
    }

    static getAdditionalItemAmount(data) {
        return API.ax.post("finance/calculate-additional-bill-item",data).catch(e=>{console.log(e);})
    }

    static saveAdditionalBillItemAmount(data) {
        return API.ax.post("finance/save-Additional-bill-item",data).catch(e=>{console.log(e);})
    }

    static saveRectificationParameter(datam) {
        return API.ax.post("transaction/rectification-parameter",datam).catch(e=>{console.log(e);})
    }

    static saveLinkPayments(paymentInfo) {
        return API.ax.post("finance/link-payment",paymentInfo).catch(e=>{console.log(e);})
    }
}
