import React, {Component} from 'react';
import DeedTabNav from "../objects/deedTabNav";
import DeedTab from "../objects/deedTab";
import Official from "../objects/official-object";
import TransactionPopup from "../../transaction_popup";
import API from "../../../../../services/API";
import TxReadService from "../../../../../services/transaction/transaction_read";

class UnitTittle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected : 'Parameters',
            parameterValue: "",
            parameterStatus: 1
        }

        this.setSelected = this.setSelected.bind(this)
        this.saveParam = this.saveParam.bind(this)
        // this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    componentDidMount() {
       TxReadService.getUnitTittleParameter(TransactionPopup.id()).then( d => {
           // console.log("unit tittle received parameter" , d.data.result.parameterValue)
           if(d.data.status == 1){
               this.setState({
                   parameterValue: d.data.result.parameterValue
               })
           }else {
              this.setState({
                  parameterStatus: 0
              })
           }
       }).catch( e => {
           console.log("error on fetching parameter of units ", e)
       })
    }

    setSelected = (tab) => {
        this.setState({
            selected: tab
        });
    }

    saveParam = () =>{
        const unitTitleParamToSend = {
            transactionId: TransactionPopup.id(),
            parameterCode: "Unit Title",
            parameterValue: this.state.parameterValue.toString(),
            details: "Parameter for number of unit in this transaction"
        }
        API.ax.post('/transaction/unit-title-parameter', unitTitleParamToSend).then(d => {
                    console.log("unit title parameter", d.data)
        }).catch(e => {
            console.log("save Parameter error" + e)
        })
    }




    render() {
        return (
            <div>
                <DeedTabNav tabs={['Parameters', 'Transaction Objects']} selected={ this.state.selected } setSelected={ this.setSelected }>
                    <DeedTab isSelected={ this.state.selected === 'Parameters' }>
                        <div>

                            <div style={{height:'100%'}} className={'col col-12'}>
                                <div className={'row'}>
                                    <div className={'col col-sm-8'}>
                                            <table style={{width:'60%'}}>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <label className={'ml-2 col-sm'}>Number of Units<br/>
                                                            <input style={{width:'100%'}} type="number" defaultValue={this.state.parameterStatus === 0 ? "" : this.state.parameterValue } onChange={e => {this.setState({parameterValue: e.target.value})}} step={"any"} min={1} name={"units"} />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr  style={{textAlign:'bottom',width:'100%',padding: '5px 0px 0px 0px'}}>
                                                    <button className={'float-right'} onClick={() => this.saveParam()} style={{width:'60px', height: '40px', padding: '0px 0px 0px 0px'}}>Save</button>
                                                </tr>
                                                </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DeedTab>
                    <DeedTab isSelected={ this.state.selected === 'Transaction Objects' }>
                        {this.state.selected === 'Transaction Objects' ?  <Official writable={this.props.writable} /> : ''}
                    </DeedTab>
                </DeedTabNav>
            </div>
        );
    }

}

export default UnitTittle;