import React from 'react';

import UgSpatial from "../../components/maps/ug_spatial";
import * as LF from "leaflet";
import Basemap from "../../components/maps/basemap";
import PopDetail from "../../components/popups/popdetail";
import PopDetailsTwo from "../../components/popups/PopDetailsTwo";
import "leaflet.utm"
import PaneInfo from "../../components/popups/transacrions/pops/pane_info";
import Common from "../../utils/common";
import CommonCreateService from "../common/common_create";
import Wait from "../../components/popups/wait";

export default class SpatialPreviewPane extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            title:'Spatial Preview',
            description_title:'Quality Checking',
            map:null,
            pass:false
        }
        this.loadSHP = this.loadSHP.bind(this)
        this.downloadParent = this.downloadParent.bind(this)
    }

    componentDidMount() {
        try {
            UgSpatial.clear('ramani_preview')

            let ly_group = new LF.FeatureGroup();
            let sat = Basemap.googleSat(),
                hybrid = Basemap.googleHybrid(),
                blank = LF.tileLayer('')

            let map = LF.map('ramani_preview', {
                layers: [blank, ly_group],
                attributionControl: false,
                fullscreenControl: true,
                maxZoom:10000
            }).setView([-6.085936, 35.711995], 4)

            let baseLayers = {
                "<span style='color: gray'>Blank</span>": blank,
                "Satellite": sat,
                "Hybrid": hybrid
            }

            this.setState({
                map:map,
                ly_group:ly_group,
            })


            let filter =  'DWITHIN(geom,'+ this.props.centroid+',1000,meters)'//'transaction_id='+TransactionPopup.id()

            let ly_vb = UgSpatial.getLayerByName('valuation_block',filter).addTo(map)
            let ly_tp = UgSpatial.getLayerByName('tp_spatial_unit').addTo(map)
            let ly_sp = UgSpatial.getLayerByName('spatial_unit').addTo(map)

            console.log(ly_tp)

            let overlays = {
                'Valuation Blocks':ly_vb,
                'TP Drawing':ly_tp,
                'Spatial Unit':ly_sp,
                'Uploaded Layer':ly_group,
            }

            LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)
            setTimeout(e=>{ this.loadSHP()},500)

        }catch (e){
            console.log(e)
        }
    }

    downloadParent(){
        //console.log()

        //2124846
        try{
            Wait.show('Downloading...')
            CommonCreateService.downloadShapefile({
                layer_name: this.props.layer_name,
                list: this.props.validator.lst_affected
             }).then(rr =>{
                 CommonCreateService.getFile('common/create/get/file/' + rr.data).then(r  =>{
                     try{
                         try{
                             Wait.dismiss()
                             const url = window.URL.createObjectURL(new Blob([r.data]));
                             const link = document.createElement('a');
                             link.href = url;
                             link.setAttribute('download', 'parent.zip'); //or any other extension
                             document.body.appendChild(link);
                             link.click();
                         }catch (e) {
                             console.log(e)
                         }

                     }catch (e) {}
                 })
            })
        }catch (e) {
            console.log(e)
        }
    }

    render() {
        return(<div className={'col sol-sm-12'}>
            <div className={'row'} style={{marginBottom:'4px'}}>
                <div className={'col col-sm-3'} style={{height:'68vh',background:'#fff',overflow:'auto',paddingRight:'4px',paddingLeft:0}}>
                    <div style={{padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div>
                            <b>Required Shapefile Columns</b>
                            <table className={'tb'} style={{width:'100%'}}>
                                <thead>
                                <tr>
                                    <th>Column Name</th>
                                    <th>Quality</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.shp_columns.map((d,i)=>{
                                    return(<tr key={i}>
                                        <td>{d}</td>
                                        <td style={{textAlign:'right'}}>
                                            <span className={this.state[d]==='check'?'ug-badge-ok':'ug-badge-not'}>
                                                <span className={this.state[d]==='check'?'fa fa-check':'fa fa-times'}/>
                                                <span id={'sp_'+i} style={{display:'none'}}>{this.state[d]}</span>
                                            </span>
                                        </td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>



                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'68vh',paddingLeft:'4px'}}>
                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                        <div className={'row'} style={{height:'40px',lineHeight:'40px'}}>
                            <div className={'col col-sm-12'}>
                                <span className={this.props.validator.is_poly?'ug-badge-ok':'ug-badge-not'} title={this.props.validator.poly}>
                                    <span className={this.props.validator.is_poly?'fa fa-check':'fa fa-times'} style={{marginRight:'8px'}}/>
                                    <span>Is Polygon</span>
                                </span>


                                <span style={{marginLeft:'16px'}} className={this.props.validator.d_wgs_1984?'ug-badge-ok':'ug-badge-not'} title={this.props.validator.proj}>
                                    <span className={this.props.validator.d_wgs_1984?'fa fa-check':'fa fa-times'} style={{marginRight:'8px'}}/>
                                    <span>Is WGS 84 UTM</span>
                                </span>
                                <span style={{marginLeft:'16px'}} className={this.props.validator.is_overlaps?'ug-badge-ok':'ug-badge-not'} title={this.props.validator.hint}>
                                    <span className={this.props.validator.is_overlaps?'fa fa-check':'fa fa-times'} style={{marginRight:'8px'}}/>
                                    <span>Overlapping</span>
                                </span>
                                <span style={{marginLeft:'16px'}} className={this.props.validator.pre_layer?'ug-badge-ok':'ug-badge-not'} title={this.props.validator.hint}>
                                    <span className={this.props.validator.pre_layer?'fa fa-check':'fa fa-times'} style={{marginRight:'8px'}}/>
                                    <span>{this.props.validator.pre_layer_name}</span>
                                    {/*,display:this.props.validator.pre_layer_name===''?'none':'inherit'*/}
                                </span>
                            </div>
                        </div>
                        <div className={'row'} style={{height:'80%'}}>
                            <div className={'col col-sm-12'} style={{background:'#efefef'}} id={'ramani_preview'}/>
                        </div>
                        <div className={'row'}>
                            <div className={'col col-sm-12'} style={{color:'#f00'}}>
                                {this.props.validator.hint.map((d,i)=>{
                                    return(<span key={i}>{d}, </span>)
                                })}
                            </div>
                        </div>
                        <div className={'row'} style={{borderTop:'1px solid #ccc',paddingTop:'4px'}}>
                            <div className={'col col-sm-3'}/>
                            <div className={'col col-sm-9'} style={{textAlign:'right'}}>
                                <button className={'refresh'} onClick={this.downloadParent} style={{visibilty:this.props.validator.lst_affected.length>0?'visible':'hidden'}}>
                                    <span className={'fa fa-download'}/>Download Parent
                                </button>
                                <button className={'submit'} style={{visibility:this.state.pass===true?'visible':this.props.label==='station'?'visible':'hidden'}} onClick={()=>this.props.upload()}>Submit</button>
                                <button onClick={()=>this.props.ispop2?PopDetailsTwo.dismiss():PopDetail.dismiss()}>Close</button>
                            </div>
                        </div>
                    </div>

                </div>


            </div>


        </div>)
    }


    loadSHP() {
        let self = this
        try {

            this.props.validator.hint_point.forEach(it=>{
                let a = 0
                try {
                    var item = LF.utm({x: it.x, y: it.y, zone: 36, band: 'M'});
                    var coord = item.latLng();

                    console.log(coord)

                    LF.marker(coord,{
                        icon:LF.divIcon({
                            className:'bindTooltipBigRed',
                            html:it.message,
                            iconSize:[100,40]
                        })
                    })
                        .bindPopup("<b>"+it.message+"</b><br />( Not Qualified )").openPopup().addTo(self.state.ly_group)
                }catch (e) {
                    console.log(e)
                }
            })

        }catch (e) {
            console.log(e)
        }

        try{
            let ly = LF.geoJson(this.props.json,{
                onEachFeature:(f,l)=>{
                    this.showSpatial(self,f,l)
                },
                weight:4,
                fillColor:'transparent'
            }).addTo( self.state.ly_group)

            self.props.shp_columns.forEach((it,i)=>{
                if (document.getElementById('sp_'+i).innerHTML!=='check'){
                    self.setState({
                        pass:false
                    })
                }
            })

            ly.bringToFront()

            try {
                self.state.map.fitBounds(ly.getBounds())
            }catch (e) {
                console.log(e)
            }

            let pass = 0
            if (self.state.pass===true){pass++}
            if (self.props.validator.is_poly===true){pass++}
            if (self.props.validator.d_wgs_1984===true){pass++}

            if (self.props.validator.is_overlaps===true){pass++}
            if (self.props.validator.pre_layer===true){pass++}

            console.log(self.props.validator)

            self.setState({
                pass:pass===5
            })

        }catch(e){
            this.props.json.then(dd=>{
                try {
                    let ly = LF.geoJson(dd,{
                        onEachFeature:(f,l)=>{
                            this.showSpatial(self,f,l)
                        },
                        weight:4,
                        fillColor:'transparent'
                    }).addTo( self.state.ly_group)

                    self.props.shp_columns.forEach((it,i)=>{
                        if (document.getElementById('sp_'+i).innerHTML!=='check'){
                            self.setState({
                                pass:false
                            })
                        }
                    })

                    ly.bringToFront()

                    try {
                        self.state.map.fitBounds(ly.getBounds())
                    }catch (e) {
                        console.log(e)
                    }

                    let pass = 0
                    if (self.state.pass===true){pass++}
                    if (self.props.validator.is_poly===true){pass++}
                    if (self.props.validator.d_wgs_1984===true){pass++}
                    if (self.props.validator.is_overlaps===true){pass++}
                    if (self.props.validator.pre_layer===true){pass++}

                    self.setState({
                        pass:pass===5
                    })

                }catch (e) {
                    console.log(e)
                }
            })
        }
        //return


    }

    showSpatial(self,f, l) {
        try {
            let out = [];
            if (f.properties) {
                for (let key in f.properties) {
                    self.setState({
                        [key]:self.props.shp_columns.includes(key)?'check':'times',
                        pass:true
                    })
                    out.push(key + ": " + f.properties[key]);
                }

                //l.bindPopup(out.join("<br/>"));
            }

            function whenClicked(e){
                PopDetailsTwo.show('Info',<PaneInfo data={out}/>)
            }
            l.on({
                click:whenClicked
            });

            console.log(l.getBounds().getCenter())
            LF.marker(l.getBounds().getCenter(),{
                icon:LF.divIcon({
                    className:'bindTooltipBig',
                    html:f.properties[self.props.label]?f.properties[self.props.label]:'',
                    iconSize:[100,40]
                })
            }).addTo(self.state.ly_group)


            let color = self.props.validator.lst_poly.includes(f.properties[self.props.label])?'#901':f.properties[self.props.label]?'#00ff00':'#901'
            //let fillColor = self.props.validator.lst_poly.includes(f.properties[self.props.label])?'transparent':'#901'


            l.setStyle({
                color:color,
            })

        }catch (e) {
            console.log(e)
        }
    }
}