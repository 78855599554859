import * as React from 'react'

export default function KTab({ heads, body }) {
    const [activeTab, setActiveTab] = React.useState(0);
    return (
        <div className='row'>
            <div className='col-md-12' style={{ flexDirection: "row", width: "100%" }}>
                {
                    heads.map((prop, key) => (
                        <div onClick={() => { setActiveTab(key) }} className={activeTab === key ? 'ktabactive tab' : 'ktab tab'}>
                            {prop}
                        </div>
                    ))
                }
            </div>
            <div className='col-md-12 mt-4'>
                {
                    body[activeTab]
                }
            </div>
        </div>
    )
}