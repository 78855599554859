import React, { useState, useEffect } from 'react';
import Header from "../gro/header";
import PopDetail from "../../../popdetail";
import Wait from "../../../wait";
import TransactionPopup from "../../transaction_popup";
import TxCreateService from "../../../../../services/transaction/transaction_create";
import SummaryPane from "../summary";

const NewComment = ({ handleSave }) =>{
    const[comment, commentSet] = useState()
    return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
        <br/>

        <div className={'row'}>
            <div className={'col col-sm-12'}>
                <span>* Comment Text</span>
                <textarea style={{width:'100%', resize:'none'}} name={"comment"} rows={10} onChange={(e)=>{commentSet(e.target.value)}}/>
            </div>
        </div>
        <hr/>
        <div style={{textAlign:'right',width:'100%'}}>
            <button style={{width:'100px'}} onClick={()=>{handleSave(comment)}}>Save</button>
            <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
        </div>
    </div>)
}
const EditComment = ({data, handleUpdate, index}) =>{

    const[commentUpdate, commentUpdateSet] = useState('')
    const innerUpdate = datas =>{
    const newUpdate ={...data,comment:datas}
    handleUpdate(newUpdate,index)
    }

    return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
        <br/>

        <div className={'row'}>
            <div className={'col col-sm-12'}>
                <span>* Comment Text</span>
                <textarea style={{width:'100%', resize:'none'}} defaultValue={data.comment} name={"comment"} rows={10} onChange={(e)=>{commentUpdateSet(e.target.value)}}/>
            </div>
        </div>
        <hr/>
        <div style={{textAlign:'right',width:'100%'}}>
            <button style={{width:'100px'}} onClick={()=>innerUpdate(commentUpdate)}>Save</button>
            <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
        </div>
    </div>)
}/*
const RemoveComment = () =>{

}*/
export default function ProcessingComments({coment, writable}) {
    const [comments,commentsSet] = useState([])
    const[selected, selectedSet] = useState(-1)
    const [comment, commentSet] = useState({})

    const highlight ={
        fontWeight:'bold'
    }
    useEffect(()=>{
       commentsSet(coment)
    },[ coment ])
    useEffect(()=>{
        if(comments.length>0 && selected !== -1){
            let commentz = comments[selected];
            commentSet(commentz)
        }
    },[ selected ])

    useEffect(()=>{
        const{taskName,userName}=comment
        if(taskName === TransactionPopup.task() && userName === sessionStorage.getItem('username')){
            //let the selected to be the one clicked
        }else{
            selectedSet(-1)
        }
    },[comment])

    const handleUpdate = (commentUpdate,index )=>{
        try {
            let tempArray = [ ...comments];
            Wait.show("Updating...")
            TxCreateService.editComment(commentUpdate).then(d=>{
                tempArray[index] =commentUpdate
                commentsSet(tempArray)
                Wait.dismiss()
                PopDetail.dismiss()
            })
        }catch (e){
            console.log(e)
        }

    }

    const handleSave = comment =>{
        try {
            let tempArray = [ ...comments];
            Wait.show("Saving...")
            let commentToSave={
                transactionFk:TransactionPopup.id(),
                taskId:TransactionPopup.taskId(),
                taskName:TransactionPopup.task(),
                comment:comment,
                userName:sessionStorage.getItem('username')
            }
            TxCreateService.addComment(commentToSave).then(d=>{
                tempArray.push(d.data)
                commentsSet(tempArray)
                Wait.dismiss()
                PopDetail.dismiss()
            })
        }catch (e){
            console.log(e)
        }
    }
    return(
            <div style={{background:'#fff',width:'100%',padding:'24px',height:'47vh',border:'0.5px solid #efefef', overflow:'auto', marginTop:'4px'}}>
                <Header label={'Processing Comments'} display={writable}>
                    <div>
                        <i
                            className={'fa fa-plus-circle'}
                            style={{color:'tomato', fontSize:'25px'}}
                            onClick={()=>{PopDetail.show("New Comment", <NewComment handleSave={handleSave}/>,60)}}
                        /><strong> New</strong>
                    </div>
                    <div>
                        <i
                            className={'fa fa-edit'}
                            style={{color:'tomato', fontSize:'25px'}}
                            onClick={selected >-1?()=>{PopDetail.show("Edit Task",<EditComment data={comment} handleUpdate={handleUpdate} index={selected}/>,60)}:()=>{}}
                        /><strong> Edit</strong>
                    </div>
                    <div>
                        <i
                            className={'fa fa-trash'}
                            style={{color:'tomato', fontSize:'25px'}}
                        /><strong> Remove</strong>
                    </div>
                </Header>
                <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                    <thead>
                    <tr>
                        <th>DATE ADDED</th>
                        <th>COMMENT</th>
                        <th>USER</th>
                        <th>TASK</th>
                    </tr>

                    </thead>
                    <tbody>
                    {comments!=null ? comments.map((d,i)=>{
                        return(
                            <tr key={i} style={ selected === i?{...highlight, cursor:'pointer'}:{cursor:'pointer'}} onClick={()=>{selectedSet(i)}}>
                                <td>{d.creationDate}</td>
                                <td>{d.comment}</td>
                                <td>{d.userName}</td>
                                <td>{d.taskName}</td>
                            </tr>
                        )
                    }):<tr key={6} ><td colSpan="4" style={{textAlign:'center'}}>No Comments</td></tr>}
                    </tbody>
                </table>

            </div>
    )
}