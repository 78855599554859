import React, {useEffect} from 'react'
import ComputationAnalysis from "./computationAnalysis";
import MainBuilding from "./MainBuilding";
import ValuationSpatial from "./ValuationSpatial";
import ValuationParty from "./ValuationParty";
import {useBuildingDispatch} from "./store";
import CompensationAllowance from "./compensation-allowance";


const tabs  = ['Computation and Analysis','Buildings','Spatial Units','Right Holders']
const tabsComp  = ['Computation and Analysis','Compensation Allowance','Buildings','Spatial Units','Right Holders']

export default function GeneralValuation({purpose, isComp}) {
    const[ currentTabId, currentTabIdSet ] = React.useState(0)
    const[ currentTab, currentTabSet ] = React.useState(<ComputationAnalysis/>)
    const dispatch = useBuildingDispatch()
    useEffect(()=>{
        dispatch({type:"reset"})
        dispatch({type:"purposeOfValuation",payload:purpose})
    },[])
    const tabPanels =[
        <ComputationAnalysis/>,
        <MainBuilding/>,
        <ValuationSpatial/>,
        <ValuationParty/>,
    ]
    const tabPanelsComp =[
        <ComputationAnalysis/>,
        <CompensationAllowance/>,
        <MainBuilding/>,
        <ValuationSpatial/>,
        <ValuationParty/>,
    ]
   const setTabAt=(psn)=>{
       currentTabIdSet(psn);
       currentTabSet(isComp?tabPanelsComp[psn]:tabPanels[psn])

    }
    const tabToUse = !isComp?tabs:tabsComp

    return(

        <div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
        <br/>

        <div className={'row'}>
            <div className={'col col-sm-12'}>
                {tabToUse.map((d,i)=>{
                    return( <div key={i} onClick={()=>setTabAt(i)} className={currentTabId===i? 'tab tab-active no-bg':'tab no-bg'}
                                 style={{width:"auto",minWidth:'100px'}}>
                        {d}
                    </div>)
                })}
            </div>

            <div className={'col col-sm-12'}>
                {currentTab}
            </div>
        </div>
    </div>
    )
}
