import React, {Component} from 'react';
import TicCreateService from "../../../../../services/tic/tic_read";
import TransactionPopup from "../../transaction_popup";
import TicReadService from "../../../../../services/tic/tic_read";

class InvestorInfo extends Component {
    constructor(props) {
        super(props);
        this.state={
            companyincorporationnumber:"",
            companyname:"",
            shareholderinfo:[],
            contact:"",

        }

    }
    componentDidMount() {
        TicReadService.getShareholderInfo(TransactionPopup.id()).then(d=>{
            console.log("shareholder info",d.data)
            if(d.data.status===1){
                this.setState({shareholderinfo:d.data.result})
            }else {

            }
        })
        TicReadService.getInvestorInfo(TransactionPopup.id()).then(d=>{
            console.log("investor info",d.data)
            if(d.data.status===1){
                this.setState({companyincorporationnumber:d.data.result.companyincorporationnumber,companyname:d.data.result.companyname})
            }else {

            }
        })
    }

    render() {
        return (
            <div>
                <table style={{width:'100%'}}>
                    <tbody>
                    <tr style={{color:'#888'}}>
                        <td>Incorporation No</td>
                        <td>Company Name</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.companyincorporationnumber} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.companyname} readOnly={true}/></td>
                    </tr>
                    {this.state.shareholderinfo.map((d,i)=>{

                        return(<tr key={i}>
                            <td>{d.shareholder}</td>
                            <td>{d.shareholderNationality}</td>
                        </tr>)
                    })}

                    </tbody>
                </table>
            </div>
        );
    }
}

export default InvestorInfo;