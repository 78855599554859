import API from "../API";

export default class SMDUpdateService{

    static update(data) {
        return API.ax.put("smd/update/spatial",data).catch(e=>{console.log(e);})
    }


    static updateRecordsBaunit(formData) {
        return API.ax.post("land/updateBaunit",formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static updateRecords(formData) {
        return API.ax.post("smd/update/spatial-update",formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }
    static updateLastPayDate(formData) {
        return API.ax.get("land/updateLastPayDate").catch(e=>{console.log(e);})
    }

    static updateRecordsBaunitSpatial(formData) {
        return API.ax.post("land/updateBaunitSpatial",formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }


    static updateBaunit() {
        return API.ax.get("transaction/removal").catch(e=>{console.log(e);})
    }
}
