import * as React from "react";
import PopDetail from "../popdetail";
import SMDCreateService from "../../../services/smd/smd_create";

export default class AdminUploadSpatial extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            coordinate:''
        }
        this.uploadFile = this.uploadFile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
    }
    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }
    handleChangeFile=e=>{
        this.setState({ coordinate:e.target.files[0] })
    }
    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>

            <div className={'row'}>
                <div className={'col col-sm-12'}>

                    <div className={'col col-sm-12'}>
                        <table style={{width:'100%'}}>
                            <tbody>

                            <tr>
                                <td colSpan={3}>*Rough Coordinates</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><input type={'file'} name={'coordinate'}
                                                       onChange={this.handleChangeFile}
                                                       accept={".zip,.ZIP"}
                                                       style={{width:'100%'}}/></td>
                            </tr>

                            </tbody>
                        </table>


                    </div>

                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={()=>this.uploadFile()}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }



    uploadFile(){
        try {
            //Wait.show("Saving...")

            const formData = new FormData()
            formData.append('coordinate',this.state.coordinate)
            /*formData.append('extra',JSON.stringify({
                txId:TransactionPopup.id(),
                surveyId:this.state.surveyId
            }))*/
            SMDCreateService.uploadRegions(formData).then(d=>{
                console.log(d.data)
                /*Wait.dismiss()
                if (d.data.status===1){
                    PopDetail.dismiss()
                }
                PopDetail.dismiss()
                SurveyInstructionPane.loadData()*/
            })
        }catch (e){
            console.log(e)
        }
    }
}