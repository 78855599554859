import * as React from "react";
import check from "../../../resource/img/checkbox/checkbox_checked_16.png"
import uncheck from"../../../resource/img/checkbox/checkbox_unchecked_16.png"
import ProjectPane from "../pane/mobile/project_pane";
import MobilePopup from "../popups/mobile/mobile_popup";
import MobileReadService from "../../services/mobile/mobile_read";
import PopDetail from "../popups/popdetail";
import MobileDeleteService from "../../services/mobile/mobile_delete";
import EditProjectPane from "../popups/mobile/pops/edit_mobile_project";

export default class ProfessionTable extends React.Component{

    static self;
    constructor(props) {
        super(props);

        this.state = {
            projects:[]
        }

        ProfessionTable.self = this
        ProfessionTable.loadData = ProfessionTable.loadData.bind(this)
    }

    componentDidMount() {
        ProfessionTable.loadData()
    }

    openTransactionAt(project,psn){
        if (project.screens===undefined||project.screens===null||project.screens==='[]'){
            alert('No Project Screens')
            return
        }
        MobilePopup.show({
            project:project,
            is_new:false
        },<ProjectPane
            title={project.projectName}
            data={project}
            key={new Date().getMilliseconds()}/>)

        MobilePopup.show({
            projectId:project.id,
            projectProjectId:project.projectId,
            outputId:project.outputId,
            outputName:project.outputName,
            projectName:project.projectName,
            is_new:false,
            screens:project.screens
        },<ProjectPane
            title={project.projectName +" - "+project.outputName}
            key={new Date().getMilliseconds()}/>)

    }


    static loadData(){
        try {
            document.getElementById('tr_loading').style.visibility='visible'
            ProfessionTable.self.setState({projects:[]})

            MobileReadService.projectList().then(d=>{
                PopDetail.dismiss()
                document.getElementById('tr_loading').style.visibility='hidden'
                try {
                    ProfessionTable.self.setState({
                        projects:d.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    toggleCheck(i){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            return;
        }
        doc.src=uncheck;
    }

    noHandler(){
        PopDetail.dismiss()
    }

    deleteProject(id){
        PopDetail.dismiss()
        MobileDeleteService.deleteProject(id).then(r => {
            try {

                console.log(r.data)
                ProfessionTable.loadData()
            }catch (e) {
                console.log(e)
            }
        })
    }

    delete(d){
        console.log(d)
        //PopDetail.show("sadfsdf","sadfsd")
        PopDetail.confirm('Confirm Delete?',d.projectName +' - '+d.outputName+' we be removed, Are you sure ?',this.deleteProject,d.id,this.noHandler,30)
    }

    render() {
        return(<div style={{width:'100%',height:'100%',overflow:'hidden'}}>
            <div style={{height:'6vh'}} className={'row'}>
                <div className={'col col-sm-3'}>
                    <div className={'col col-sm-12'} style={{lineHeight:'6.5vh',color:'#484848',fontFamily:'oxygen-bold'}}>
                        Active Projects
                    </div>
                </div>
                <div className={'col col-sm-9'} style={{marginTop:'1vh'}}>
                    <button onClick={()=>window.location.reload()} className={'default'} style={{float:'right',marginRight:'16px'}}>Close</button>
                    <button onClick={()=>ProfessionTable.loadData()} style={{float:'right',marginRight:'16px'}}>Refresh</button>
                    <input style={{width:'70%',float:'right',marginRight:'8px'}} placeholder={'Search here.....'}/>
                </div>
            </div>

            <div className={'row'} style={{width:'99.9%',margin:'4px',border:'1px solid #dddd',height:'82vh',borderTop:'1px solid #efefef',overflow:'auto'}}>
                <table className={'tb'} style={{width:'100%'}}>
                    <thead>
                    <tr>
                        <th>
                            <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                        </th>
                        <th>ID</th>
                        <th>FIRST NAME</th>
                        <th>MIDDLE NAME</th>
                        <th>LAST NAME</th>
                        <th>GENDER</th>
                        <th>PROFESSION</th>
                        <th>ACTION</th>
                        {/*<th>TASK</th>
                        <th>ASSIGNEE</th>
                        <th>ROLE</th>
                        <th>SCREENS</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.projects.map((d,i)=>{
                            //let spatial_psn = d.transaction==='Survey Request'?2:4
                            return(<tr key={i}>
                                <td style={{textAlign:'center'}}>
                                    <img id={'chk_'+(i+1)} alt={'chk_'+(i+1)} src={uncheck} onClick={()=>this.toggleCheck(i+1)}/>
                                </td>
                                <td onClick={()=>this.openTransactionAt(d,0)} style={{color:'#DF4A36'}}>{d.id}</td>
                                <td onClick={()=>this.openTransactionAt(d,0)} style={{color:'#DF4A36'}}>{d.projectName}</td>
                                <td onClick={()=>this.openTransactionAt(d,1)} style={{color:'#DF4A36'}}>{d.outputName}</td>
                                <td onClick={()=>this.openTransactionAt(d,1)} style={{color:'#DF4A36'}}>{d.projectAuthority}</td>
                                <td onClick={()=>this.openTransactionAt(d,0)} style={{color:'#DF4A36'}}>{d.projectLocation}</td>
                                <td>{d.submitted}</td>
                                <td>
                                    <button className={'submit'} onClick={()=>PopDetail.show('Edit '+d.projectName,<EditProjectPane project={d}/>)}>
                                        <span className={'fa fa-pencil'}/>Edit
                                    </button>
                                    <button onClick={()=>this.delete(d)}>
                                        <span className={'fa fa-trash'}/>{d.status==='Active'?'Freeze':'Activate'}
                                    </button>

                                </td>
                                {/*<td>{d.status}</td>
                                <td>{d.task}</td>
                                <td>{d.assignee}</td>
                                <td>{d.role}</td>
                                <td>{JSON.stringify(d.screens)}</td>*/}
                            </tr>)
                        })
                    }
                    <tr id={'tr_loading'}>
                        <td colSpan={15} style={{fontSize:'18px',padding:'24px'}}>Loading.....</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={'row'} style={{height:'5vh',lineHeight:'5vh'}}>
                <div className={'col col-sm-2'}>

                </div>
                <div className={'col col-sm-6'} style={{textAlign:'center'}}>
                    Footer
                </div>
                <div className={'col col-sm-4'}>

                </div>
            </div>
        </div>)
    }
}