import * as React from "react";


import * as LF from "leaflet";
import UgSpatial from "../../../../maps/ug_spatial";
import Basemap from "../../../../maps/basemap";
import API from "../../../../../services/API";

import "leaflet.utm"
import Wait from "../../../wait";

export default class LegacyMap extends  React.Component{
    static self = null
    static map = null

    constructor(props) {
        super(props);
        this.state = {
            tp_drawings: [],
            layers: [],
            page_status:'Loading...'
        }

        LegacyMap.self = this
    }

    componentDidMount() {

        UgSpatial.clear("map2")

        let ly_group = new LF.FeatureGroup();
        let sat = Basemap.googleSat(),
            hybrid = Basemap.googleHybrid(),
            blank = LF.tileLayer('')

        let map = LF.map('map2', {
            layers: [blank, ly_group],
            attributionControl: false,
            fullscreenControl: true,
            maxZoom:10000
        }).setView([-6.085936, 35.711995], 4)



        let id = this.props.parcel.toString().replace('[','').replace(']','')
        let filter = '1=1'//'spatial_unit_id in('+id+')'
        let layername = 'spatial_unit'

        Wait.show('Loading Spatial...')
        API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
            Wait.dismiss()
            try {
                r.data.features.forEach(p=>{
                    LF.geoJSON(p,{
                        onEachFeature:(ft,ly)=>{
                            let label = LF.marker(ly.getBounds().getCenter(),{
                                icon:LF.divIcon({
                                    className:'bindTooltipBig',
                                    html:ft.properties.label,
                                    iconSize:[100,40]
                                })
                            }).addTo(map)
                        },
                        coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                        color: '#888',weight:p.properties.boundary?4:3,zIndex:1, fillOpacity: 0
                    }).addTo(map);

                    if (id.split(',').includes(p.id.replace(layername+'.',''))){
                        let myLayer = LF.geoJSON(p,{
                            onEachFeature:(ft,ly)=>{
                                let label = LF.marker(ly.getBounds().getCenter(),{
                                    icon:LF.divIcon({
                                        className:'bindTooltipBig',
                                        html:ft.id.replace(layername+'.',''),
                                        iconSize:[100,40]
                                    })
                                }).addTo(map)
                            },
                            coordsToLatLng: function (coords) {return LF.utm({x: coords[0], y: coords[1], zone: 36, band: 'M'}).latLng();},
                            color: '#f00',weight:p.properties.boundary?4:3,zIndex:1, fillOpacity: 0
                        }).addTo(map);
                        try{ map.fitBounds(myLayer.getBounds())}catch (e) { }
                    }
                })
            }catch (e) {
                console.log(e)
            }
        })

    }







    render() {
        return(<div style={{height:'90%',width:'100%',background:'#000'}}>
            <div className={'col col-sm-12'} style={{background:'#efefef',height:'100%'}} id={'map2'}>

            </div>
        </div>)
    }

}