import * as React from "react";
import TransactionPopup from "../transaction_popup";
import PopDetail from "../../popdetail";
import SMDCreateService from "../../../../services/smd/smd_create";

export default class PaneApplySmdApproval extends React.Component{
    constructor(props) {
        super(props);
        this.acceptLadDecision=this.acceptSmdDecision.bind(this)
    }

    render() {
        return(<div>
            <div className={'row'}>
                <h3>By Clicking OK button you accept</h3>
                <button onClick={this.acceptSmdDecision}>OK</button>
            </div>
        </div>)
    }
    acceptSmdDecision=()=>{
        let datam={
            transactionId:TransactionPopup.id(),
            username:sessionStorage.getItem('username'),
            decision:this.props.decision,
            taskName:TransactionPopup.task(),
            taskId:TransactionPopup.taskId()

        }

        console.log("smd approval",datam)
        SMDCreateService.saveSmdAcceptance(datam).then(d=>{
        })
        PopDetail.dismiss()
    }
}