import * as React from "react"

import AboutProject from "./project_pane/about_project";
import VillageConfig from "./project_pane/village_config";
import HamletConfig from "./project_pane/hamlet_config";
import QuestionnairesConfig from "./project_pane/questionnares";
import DataCollection from "./project_pane/data_collection";
import ProjectUsersConfig from "./project_pane/users_config";

export default class ProjectPane extends React.Component{

    static project_menu = []
    static self
    constructor(props) {
        super(props);
        this.state = {
            project_menu:[
                {title:'About Project',pane:<AboutProject key={new Date().getMilliseconds()} data={this.props.data}/>},
                            {title:'District Configuration',pane:<VillageConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'District'}/>},
                            {title:'Streets',pane:<HamletConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'Street'}/>},
                            {title:'Project Users',pane:<ProjectUsersConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'District'}/>},
                            {title:'Questionnaires',pane:<QuestionnairesConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'District'}/>},
                            {title:'Mobile Parcels',pane:<DataCollection key={new Date().getMilliseconds()} data={this.props.data} init_name={'District'}/>}
            ],
            current_menu:0,
            subtitle:'About Project'
        }

        ProjectPane.project_menu = [
            {title:'About Project',pane:<AboutProject key={new Date().getMilliseconds()} data={this.props.data}/>},
            /*{title:'Districts Configuration',pane:<DistrictConfig key={new Date().getMilliseconds()} services={this.props.services}/>},*/
            {title:'Village Configuration',pane:<VillageConfig key={new Date().getMilliseconds()} data={this.props.data} init_name={'Village'}/>},
            {title:'Hamlets',pane:<HamletConfig key={new Date().getMilliseconds()} data={this.props.data}/>},
            {title:'Project Users',pane:<ProjectUsersConfig key={new Date().getMilliseconds()} data={this.props.data}/>},
            {title:'Questionnaires',pane:<QuestionnairesConfig key={new Date().getMilliseconds()} data={this.props.data}/>},
            {title:'Mobile Parcels',pane:<DataCollection key={new Date().getMilliseconds()} data={this.props.data}/>}

        ]

        ProjectPane.self = this
    }

    componentDidMount() {
        this.loadProjectDetail()
        this.openPaneAt(0)
    }

    loadProjectDetail(){
        try {

            //console.log(this.props.services)
            //MobileReadService.readProject(this.props.services.ref_id)

        }catch (e) {
            console.log(e)
        }
    }

    openPaneAt(paneId){
        try {
            this.setState({
                current_pane:this.state.project_menu[paneId].pane,
                current_menu:paneId,
                subtitle:ProjectPane.project_menu[paneId].title
            })
        }catch (e) {
            console.log(e)
        }
    }
    render() {
        return(<div style={{ height:'100%'}} className={'row'}>

            <div className={'col col-sm-12'} >
                <div className={'row'} style={{height:'87vh'}}>
                    <div className={'col col-sm-2'} style={{float:'left'}}>

                        <div style={{color:'darkcyan',margin:'16px',fontWeight:900}}>
                            <span>{this.props.data !==null?this.props.data.ld_no:'New Project'} </span>
                        </div>
                        <br/>
                        <br/>
                        {this.state.project_menu.map((d,i)=>{
                            if (this.props.data === null){
                                if (i>0){return null}
                            }

                            return(<div key={i}>
                                <div className={this.state.current_menu===i?'left-menu left-menu-active':'left-menu'}
                                     style={{borderBottom:'1px solid #ccc'}} onClick={()=>this.openPaneAt(i)}>
                                    <span className={'fa fa-angle-double-right'}/>
                                    <span>{d.title}</span>
                                </div>

                            </div>)
                        })}
                    </div>

                    <div className={'col col-sm-10'}
                         style={{float:'left',borderLeft:'0.5px solid #ccc',
                             height:'100%',padding:0,border:'1px solid #efefef',background:'#fff'}}>
                        {this.state.current_pane}
                    </div>

                </div>

            </div>

        </div>)
    }
}