import React from "react";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../resource/img/checkbox/checkbox_checked_16.png";
import PopDetail from "../../popdetail";
import PopLegacy from "../pops/pane_pop_legacy";
import SMDReadService from "../../../../services/smd/smd_read";
import Wait from "../../wait";

export default class LADLegacy extends React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            spatial:[],
            parcels:[]
        }
        LADLegacy.self = this
        this.toggleCheck = this.toggleCheck.bind(this)
        this.pushParcel = this.pushParcel.bind(this)
        this.submit = this.submit.bind(this)
    }

    toggleCheck(i,d){
        let doc = document.getElementById('chk_l_'+i);

        if (doc.src===uncheck) {
            doc.src = check;
            this.pushParcel(d)
            return;
        }
        doc.src=uncheck;
        this.removeParcel(d)
    }

    pushParcel(d){
        let arr = this.state.parcels
        if (!arr.includes(d) && d!==0){arr.push(d)}
        this.setState({parcels:arr})
    }
    removeParcel(d){
        let arr = this.state.parcels

        for (let i = arr.length - 1; i >= 0; i--) {
            if (arr[i] === d) {
                arr.splice(i, 1);
            }
        }

        this.setState({
            parcels:arr
        })

    }

    componentDidMount() {
        LADLegacy.loadData()
    }

    static loadData(){
        try{
            Wait.show('Loading Legacy...')
            SMDReadService.readParcelWithStatus().then(r => {
                try {
                    Wait.dismiss()
                    LADLegacy.self.setState({
                        spatial:r.data.result
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    submit(){
        let parcels = this.state.parcels
        if (parcels.length<1){
            return
        }
        PopDetail.show('LAD Legacy',<PopLegacy parcel={parcels}/>)
    }
    render() {
        return(<div style={{width:'100%',height:'100%',background:'#fff',overflow:'hidden'}}>
            <div style={{height:'6.5vh'}} className={'row'}>
                <div className={'col col-sm-3'}>
                    <div className={'col col-sm-12'} style={{lineHeight:'6.5vh',color:'#484848',fontFamily:'oxygen-bold'}}>
                        Select Legacy Data
                    </div>

                </div>
                <div className={'col col-sm-8'} style={{marginTop:'1vh'}}>
                    <input style={{width:'70%',float:'right',marginRight:'8px'}} placeholder={'Search here.....'}/>
                </div>
                <div className={'col col-sm-1'} style={{float:'right'}}>
                    <button onClick={LADLegacy.loadData}>Refresh</button>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col col-sm-12'} style={{background:'linear-gradient(#0f6674,#0f6674cc,#0f6674)',borderRadius:'24px',display:this.state.parcels.length>0?'inherit':'none'}}>
                    {this.state.parcels.map((d,i)=>{
                        return(<div key={i} className={'span_data'} onClick={()=>this.removeParcel(d)}>
                            <span style={{marginLeft:'8px'}}>{d}</span>
                            <span className={'fa fa-times'} style={{margin:'0 8px'}}/>
                        </div>)
                    })}
                    <button onClick={()=>this.submit()} className={'refresh'} style={{float:'right',marginTop:'4px',marginRight:'16px'}}>Submit</button>
                </div>
            </div>

            <div className={'row'} style={{width:'99.9%',margin:'4px',border:'1px solid #dddd',height:'72vh',borderTop:'1px solid #efefef',overflow:'auto'}}>
                <table className={'tb'} style={{width:'100%'}}>
                    <thead>
                    <tr>
                        <th style={{textAlign:'center'}}>
                            {/*<img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>*/}
                        </th>
                        <th>SPATIAL ID</th>
                        <th>LOT TYPE</th>
                        <th>USE</th>
                        <th>AREA</th>
                        <th>DISTRICT</th>
                        <th>COUNCIL</th>
                        <th>WARD</th>
                        <th>BLOCK</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.spatial.map((d,i)=>{
                            return(<tr key={i}>
                                <td style={{textAlign:'center'}}>
                                    <img id={'chk_l_'+(i+1)} alt={'chk_'+(i+1)} src={uncheck} onClick={()=>this.toggleCheck(i+1,d.id)}/>
                                </td>
                                <td onClick={()=>this.toggleCheck(i+1,d.id)} style={{color:'#008b8b'}}>{d.id}</td>
                                <td>{d.lot_type}</td>
                                <td>{d.use}</td>
                                <td>{d.area}</td>
                                <td>{d.district}</td>
                                <td>{d.council}</td>
                                <td>{d.ward}</td>
                                <td>{d.block}</td>
                            </tr>)
                        })
                    }
                    {/*<tr id={'tr_loading'}>
                        <td colSpan={15} style={{fontSize:'18px',padding:'24px'}}>Loading.....</td>
                    </tr>*/}
                    </tbody>
                </table>
            </div>
            <div className={'row'} style={{height:'5vh',lineHeight:'5vh'}}>
                <div className={'col col-sm-2'}>

                </div>
                <div className={'col col-sm-6'} style={{textAlign:'center'}}>
                    {/*Footer*/}
                </div>
                <div className={'col col-sm-4'}>

                </div>
            </div>
        </div>)
    }
}