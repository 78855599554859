import  React, {useEffect, useState} from "react";
import {useBuildingDispatch, useBuildingState} from "./store";
import {useOutBuildingDispatch, useOutBuildingState} from "./outer-buildings";

export default function OutBuilding(){
    const state = useBuildingState()
    const dispatch = useBuildingDispatch()

    const outBuildingState = useOutBuildingState()
    const outBuildingDispatch = useOutBuildingDispatch()

    const{
        number,
        area,
        constructionRate,
        reducedValue,
        reducingFactor,
        depreciationPercentage,
        reducedArea,
        deprecatedValue,
        incompletenessPercentage,
        comments
    }=outBuildingState

    const [readonly, readonlySet] = useState(false);
    useEffect(()=>{

        if(readonly){
            outBuildingDispatch({ type: "fields",field:"constructionRate", payload:constructionRate });
        }

    },[readonly])

    useEffect(()=>{
        readonlySet(state.useReducingFactor?true:false)
    },[state.useReducingFactor])

    useEffect(()=>{
            let reducedAreas = parseFloat(area) *((100-parseFloat(reducingFactor))/100)
        if (isNaN(reducedAreas)) {
            reducedAreas= 0
        }
        console.log("reducedAreas",reducedAreas)
        outBuildingDispatch({ type: "fields",field:"reducedArea", payload:reducedAreas });


    },[area,reducingFactor])
    useEffect(()=>{
            let reduced = parseFloat(reducedArea) * parseFloat(constructionRate)
        if (isNaN(reduced)) {
            reduced= 0
        }
        console.log("reduced",reduced)
        outBuildingDispatch({ type: "fields",field:"reducedValue", payload:reduced });


    },[constructionRate,reducedArea])
    useEffect(()=>{

            let deprecatedV = parseFloat(reducedValue) *((100-(parseFloat(depreciationPercentage) + parseFloat(incompletenessPercentage)))/100)
        if (isNaN(deprecatedV)) {
            deprecatedV= 0
        }
        console.log("deprecatedV",deprecatedV)
        outBuildingDispatch({ type: "fields",field:"deprecatedValue", payload:deprecatedV });


    },[depreciationPercentage,incompletenessPercentage,reducedValue])



    const handlesubmit=()=>{
        dispatch({type:"outBuildings",payload:{...state,valuationOuterBuildingId:null}})
    }

    const handleChange=(e)=>{
        const { name, value } = e.target;
        outBuildingDispatch({ type: "fields",field:name, payload:value });
    }

        return(
            <div className={""}>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#number" className={"control-label" }>Number</label>
                            <input
                                id="#number"
                                placeholder="Number"
                                name ="number"
                                type="number"
                                min="1"
                                max="50"
                                className={"form-control"}
                                value={number}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#area" className={"control-label" }>Area</label>
                            <input
                                id="#area"
                                placeholder="Area"
                                type={"number"}
                                name ="area"
                                value={area}
                                className={"form-control"}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#constructionRate" className={"control-label" }>Construction Rate</label>
                            <input
                                id="#constructionRate"
                                placeholder="Construction Rate"
                                name ="constructionRate"
                                type={"number"}
                                readOnly={readonly}
                                className={"form-control"}
                                value={constructionRate}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#reducingValue" className={"control-label" }>Reduced Value (TSH)</label>
                            <input
                                id="#reducingValue"
                                placeholder="Reducing Value"
                                readOnly={true}
                                type={"text"}
                                name ="reducedValue"
                                value={reducedValue}
                                className={"form-control"}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#reducingFactor" className={"control-label" }>Reducing Factor</label>
                            <input
                                id="#reducingFactor"
                                placeholder="Reducing Factor"
                                name ="reducingFactor"
                                className={"form-control"}
                                type={"number"}
                                min={0}
                                max={100}
                                value={reducingFactor}
                                onChange={handleChange}
                            />

                        </div>
                    </div>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#depreciationPercentage" className={"control-label" }>Depreciation Percentage</label>
                            <input
                                id="#depreciationPercentage"
                                placeholder="Depreciation Percentage"
                                type={"number"}
                                name ="depreciationPercentage"
                                className={"form-control"}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#reducedArea" className={"control-label" }>Reduced Area (sqm)</label>
                            <input
                                id="#reducedArea"
                                placeholder="Reduced Area"
                                readOnly
                                type={"text"}
                                value={reducedArea}
                                name ="reducedArea"
                                className={"form-control"}
                                onChange={handleChange}
                            />

                        </div>
                    </div>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#deprecatedValue" className={"control-label" }>Deprecated Value (TSH)</label>
                            <input
                                id="#deprecatedValue"
                                placeholder="Depretcated Value"
                                type={"text"}
                                readOnly
                                value={deprecatedValue}
                                name ="deprecatedValue"
                                className={"form-control"}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-6"}>
                        <div className={"form-group"}>
                            <label htmlFor="#incopletenessPercentage" className={"control-label" }>Incopleteness Percentage</label>
                            <input
                                id="#incopletenessPercentage"
                                placeholder="Incompleteness Percentage"
                                type={"number"}
                                name ="incompletenessPercentage"
                                className={"form-control"}
                                value={incompletenessPercentage}
                                onChange={handleChange}
                            />

                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <div className={"form-group"}>
                            <label htmlFor="#comment" className={"control-label" }>Comment</label>
                            <textarea
                                id="#comment"
                                placeholder="Comment"
                                name ="comments"
                                style ={{resize:'none'}}
                                rows={6}
                                className={"form-control"}
                                value={comments}
                                onChange={handleChange}
                            />

                        </div>
                    </div>
                </div>
                <button style={{width:'100px'}} onClick={handlesubmit}>save</button>
            </div>
        )

}