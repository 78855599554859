import React, {Component} from 'react';
import InvestorInfo from "./objects/investorInfo";
import LandInfo from "./objects/landInfo";
import ProjectInfo from "./objects/projectInfo";
import transaction_popup from "../transaction_popup";
import GnInfo from "./objects/gnInfo";
import TransactionPopup from "../transaction_popup";
import Wait from "../../wait";
import TicCreateService from "../../../../services/tic/tic_create";
import wait from "../../wait";
import PopDetail from "../../popdetail";

class ApplicationInfo extends Component {
    constructor(props) {
        super(props);
        this.state={
        }
        this.saveMatrix=this.saveMatrix.bind(this)
    }
    saveMatrix=()=>{
            Wait.show("Matrix saving","...........saving ... . .")
            TicCreateService.saveMatrix(TransactionPopup.id()).then(d=>{
                if(d.data.status===1){
                   PopDetail.show("Success",<div>Saved</div>)
                    wait.dismiss()
                }else {
                    wait.dismiss()
                }


            })


    }

    render() {
        return (<div className={'row'}>
            <div className={'col col-sm-6'} style={{marginBottom:'4px'}}>

                <div className={''} style={{height:'47vh', overflow:'auto'}}>

                    <div style={{background:'#fff',height:'100%',paddingRight:'10px',paddingLeft:'20px',paddingTop:'5px',border:'0.5px solid #efefef'}}>

                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Land Info</b>
                        </div>
                        <LandInfo/>
                        <hr/>
                        {console.log("TASKKKKKK====="+transaction_popup.task())}
                        {transaction_popup.task()==='Receive GN Information'?
                        <GnInfo/>
                        :transaction_popup.task()==='Prepare Matrix Report'?
                        <div>
                            <b>Matrix Report==></b>
                        <button onClick={this.saveMatrix}>Add This Application To Matrix Report</button>
                        </div>
                                :''}
                        <br/>
                    <hr/>
                    </div>
                </div>
                <div style={{height:'40px',lineHeight:'40px'}}>
                    <b>Project Info</b>
                </div>
                <ProjectInfo/>

            </div>

            <div className={'col col-sm-6'} style={{ background:'#fff', height:'94vh',border:'0.5px solid #efefef'}}>
                <div style={{height:'40px',lineHeight:'40px'}}>
                    <b>Investor Info</b>
                </div>

                <InvestorInfo/>
            </div>
        </div>);
    }
}

export default ApplicationInfo;