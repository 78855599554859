import * as React from "react";
import PopDetail from "./../components/popups/popdetail";
import Purpose from "./valuation-tabs/purpose";
import Header from "../components/popups/transacrions/screen/gro/header";
import {useState, useEffect} from "react";
import TransactionPopup from "../components/popups/transacrions/transaction_popup";
import {useBuildingState, useValuationDispatch, useValuationState} from "./valuation-tabs/store";
import API from "../services/API";
import Wait from "../components/popups/wait";
import {useBuildingDispatch} from "./valuation-tabs/store";
import GeneralValuation from "./valuation-tabs/generalValuation";
import {commaSeparate} from "../utils/today";
export default function ValuationPane({writable}){
    const state = useBuildingState()
    const dispatch = useBuildingDispatch()
    const valuationDispatch = useValuationDispatch()
    const valuationState =useValuationState()
    const{valuations}=valuationState;
    const[isComp, isCompSet] = useState(false)
    const[ready,readySet] = useState(false)
    const[vPurpose,vPurposeSet] = useState('')
    const[show,showSet] = useState(false)
    const[current,currentSet] = useState(-1)

    const update =(datum)=>{
       //if(datum !== undefined) {
            console.log("the current data ",datum)
            dispatch({type: "newState", payload: {...datum, isReadOnly: true}})
            showSet(true)
            if (datum.valuationPurpose === "Compensation") {
                isCompSet(true)
            } else {
                isCompSet(false)
                //let obj = purpose.find(o => o.label === datum.valuationPurpose);
                //let {label} = obj;
                vPurposeSet(datum.valuationPurpose)
            }
        //}
    }
    useEffect(() => {
            const{transaction}=TransactionPopup.state()
            isCompSet(transaction.name === "Compensation Valuation" || transaction.name ==="Approve Compensation Valuation")
            }, []);
    useEffect(()=>{
        readySet(true)},[valuationState])
    useEffect(()=>{
        Wait.show("Loading Valuation Units")
        const txId = TransactionPopup.id()
        API.ax.get(`/valuation/valuation-units/${txId}`)
            .then(d=>{
                Wait.dismiss()
                const{result, status} = d.data
                if(status === 1){
                    console.log("the result ", result)
                    valuationDispatch({type:"addValuations", payload:result})
                }else{
                    readySet(false)
                }
            })
            .catch(e=>{
                console.log(e)
                Wait.dismiss()
            })
    },[])
    useEffect(()=>{
        console.log("the comp",isComp)
    },[isComp])
        return(

            <div style={{fontSize:'13px'}}>
            <div className={'row'} style={{marginBottom:'4px'}}>

                <div className={'col col-sm-12'} style={{height:'35vh',paddingRight:'4px'}}>

                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>
                        <Header display={writable} label={'Valuations'}>

                            <div>
                                <i className={'fa fa-plus-circle'}
                                   style={{color:'tomato', fontSize:'25px'}}
                                   onClick={()=>PopDetail.show('Select Valuation Purpose',<Purpose isComp={isComp}/>,40)} />
                                   <strong>Add</strong>
                            </div>
                                <div>
                                <i className={'fa fa-edit'}
                                      style={{color:'tomato', fontSize:'25px'}}
                                      onClick={()=>{}} />
                                      <strong>Edit</strong>
                            </div>
                             <div>
                                <i
                                    className={'fa fa-trash'}
                                    style={{color:'tomato', fontSize:'25px'}}
                                    onClick={()=>{}} /><strong>Remove</strong>
                            </div>


                        </Header>

                        <table style={{width:'100%'}} className={'tb'}>
                            <thead>
                            <tr>
                                <th>VID</th>
                                <th>PURPOSE OF VALUATION</th>
                                <th>PROPERTY CATEGORY</th>
                                <th>LAND VALUE</th>
                                <th>TOTAL LAND AREA</th>
                                <th>TOTAL CURRENT</th>
                                <th>PARCELS</th>
                                <th>RIGHT HOLDERS</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                ready?valuations.map((d,i)=>
                                    <tr key={i} onClick={()=>{update(d); currentSet(i);}} style={current === i?{fontWeight:'bold'}:{}}>
                                        <td>{d.valuationUnitId}</td>
                                        <td>{d.valuationPurpose}</td>
                                        <td>{d.propertyCategory}</td>
                                        <td>{commaSeparate(d.landValue)}</td>
                                        <td>{commaSeparate(d.totalLandArea)} {d.unit}</td>
                                        <td>{commaSeparate(d.totalCurrentMarketValue)}</td>
                                        <td>{d.valuationSpatialUnitVu.length}</td>
                                        <td>{d.valuationPartyVu.length}</td>
                                    </tr>
                                ):<tr><td colSpan={8} style={{textAlign:'center'}} >No Valuation Units Yet</td></tr>
                            }

                            </tbody>
                        </table>

                    </div>
                </div>


            </div>

            <div className={'row'} style={{marginTop:'4px'}}>

                <div className={'col col-sm-12'} style={{height:'59vh',marginLeft:'15px',paddingRight:'4px',background:'#fff'}}>
                    {show&& <GeneralValuation purpose={vPurpose} isComp={isComp}/>}
                </div>

            </div>

        </div>
        )
}