import React, {Component} from 'react';
import TransactionPopup from "../transaction_popup";
import PopDetail from "../../popdetail";
import ValReadService from "../../../../services/valuation/val_read";
import PaneApplyPvuApproval from "../pops/pane_apply_pvu_approval";

class ValuationApproval extends Component {
    constructor(props) {
        super(props);
        this.state={
            decision:'Accepted',
            pvuSigningOfficer:'',
            applyBtn:<div></div>
        }
        this.handleChange =this.handleChange.bind(this)
    }
    componentDidMount() {

        ValReadService.getPVUApproval(TransactionPopup.id()).then(d=>{
            if(d.data.status===1){
                this.setState({decision:d.data.result.rejectionType,pvuSigningOfficer:d.data.result.username})
            }
        })

        if(TransactionPopup.task()==='Approve Valuation Reports'){
            this.setState({applyBtn:<span><span style={{marginLeft:'16px'}} className={'fa fa-plus-circle screen-action'} onClick={()=>PopDetail.show('Apply Approve ',<PaneApplyPvuApproval decision={this.state.decision}/>,30)}/> Apply</span>})
        }
    }

    handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }
    render() {
        return(<div>
            <div className={'row'}>
                <div className={'col-sm-4'}><h3>PVU  Approval</h3></div>
                <div className={'col-sm-2'}>
                    <span> <span style={{color:'red'}}>*</span>Final PVU Decision</span>
                    <select defaultValue={this.state.decision} onClick={this.handleChange}>
                        <option>Accepted</option>
                        <option>Rejected</option>
                    </select>
                </div>
                <div className={'col-sm-2'}>

                    <span>PVU Signing Officer</span>
                    <input name={'pvuSigningOfficer'} value={sessionStorage.getItem('username')} />
                </div>
                <div className={'col-sm-4'} style={{float:'left'}}>
                <span>
                <span style={{marginLeft:'16px'}} className={'fa fa-check-circle-o screen-action'} onClick={this.validateTask} />Validate
                </span>
                    {this.state.applyBtn}
                </div>
            </div>
        </div>)
    }
}
export default ValuationApproval;
