import * as React from "react";
import PopDetail from "../../popdetail";
import Wait from "../../wait";
import TransactionPopup from "../transaction_popup";
import TPReadService from "../../../../services/tp/tp_read";
import AddTpInfoPane from "./add/add_tp_info";


export default class TpInfoPane extends React.Component{
    static self = null
    constructor(props) {
        super(props);

        this.state = {
            tp_drawings:[]
        }
        TpInfoPane.self = this
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
    }
    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }
    handleChangeFile=e=>{
        this.setState({ [e.target.name]:e.target.files[0] })
    }

    componentDidMount() {
        TpInfoPane.loadData()
    }

    static ids(){
        return TpInfoPane.self.state.ids
    }

    static  loadData(){
        try {
            Wait.show("Loading TP...")
            TPReadService.tpList(TransactionPopup.id()).then(r => {
                Wait.dismiss()
                try {

                    let ids = ''
                    TpInfoPane.self.setState({tp_drawings:r.data.result})
                    r.data.result.forEach(dt=>{
                        ids+=','+dt.id
                    })

                    TpInfoPane.self.setState({
                        ids:ids.toString().substring(1)
                    })

                    console.log(TpInfoPane.ids())
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>
                <div className={'col col-sm-12'} style={{height:'89vh'}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>TP Drawings</b>

                            <div style={{float:'right'}}>
                                <span style={{marginLeft:'16px',cursor:'pointer'}}>
                                    <span className={'fa fa-plus-circle screen-action'}
                                          onClick={()=>PopDetail.show('Add TP Info',<AddTpInfoPane/>)} />New
                                </span>
                            </div>
                        </div>

                        <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #f7f7f7'}}>
                            <thead>
                            <tr>
                                <th>TP NUMBER</th>
                                <th>REGION</th>
                                <th>DISTRICT</th>
                                <th>COUNCIL</th>
                                <th>BASEMAP</th>
                                <th>DESIGNED BY</th>
                                <th>DRAWN BY</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.tp_drawings.map((d,i)=>{
                                return(<tr key={i}>
                                    <td>{d.tpDrawingNumber}</td>
                                    <td>{d.region}</td>
                                    <td>{d.district}</td>
                                    <td>{d.council}</td>
                                    <td>{d.basemap}</td>
                                    <td>{d.designedBy}</td>
                                    <td>{d.drawnBy}</td>
                                </tr>)
                            })}

                            </tbody>
                        </table>

                    </div>
                </div>

            </div>


        </div>)
    }


}