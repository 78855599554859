import * as React from "react";
import TxReadService from "../../../services/transaction/transaction_read";
import Wait from "../wait";
import SummaryPane from "./screen/summary";
import DocumentPane from "./screen/documents";
import ReportsPane from "./screen/reports";
import CompletePane from "./screen/complete";
import ReviewPane from "./screen/review";
import TransactionTable from "../../tables/tb_transaction";
import TxCreateService from "../../../services/transaction/transaction_create";
import PaymentPane from "./screen/payment";
import PopDetail from "../popdetail";
import BpmnViewer from "../../bpmn/BpmnViewer";
import Properties from "./screen/properties";
import TpSpatialUnitPane from "./screen/spatial-unit/tp_spatial_unit";
import PrintDocumentsPane from "./screen/print_documents";
import TpInfoPane from "./screen/tp_info";
import ValBlockSpatialUnitPane from "./screen/spatial-unit/val_block_spatial_unit";
import ValuationPage from "../../../componentss/valuationPage";
import TitleRent from "./screen/title_rent";
import CompensationRatesUpload from "./screen/compensation_rates_upload";
import ConditionsPage from "./screen/gro/conditionsPage"
import LADChecklist from "./screen/gro/ladChecklist";
import RTUChecklist from "./screen/gro/rtuChecklist";
import PropertyGRO from "./screen/gro/property_gro";
import SpatialUnitScreen from "./screen/spatial-unit/spatial_unit";
import InstructionToSurvey from "./screen/instruction_to_survey";
import SurveyInstructionPane from "./screen/spatial-unit/surver_instruction";
import PropertyPane from "./screen/spatial-unit/propertyPane";
import Persons from "./screen/gro/person/persons";
import SelectPlot from "./screen/selectPlot";
import SpatialUnit from "./screen/spatialUnit";
import RecordRequest from "./screen/record-request";
import Interest from "./screen/gro/occupancy-interest";
import CancelControlNumber from "./screen/cancel-control-number";
import GROMap from "./screen/spatial-unit/gro_map";
import ConfirmPlotsStatus from "./screen/confirmPlotsStatus";
import ConfirmedPlotsStatus from "./screen/confirmedPlotsStatus";
import OfficialSearchProperty from "./screen/official-search-property";
import SelectLegacyPlots from "./screen/selectLegacyPlots";
import PaneControlNumberSelection from "./pops/pane_control_number_selection";
import Verification from "./screen/verification";
import AmendSpatialScreen from "./screen/spatial-unit/amendment";
import TpAmendSpatialUnitPane from "./screen/spatial-unit/tp_amend_spatial_unit";
import ScreenInterest from "./screen/screen-interest";
import SpatialUnitMaintanance from "./screen/maintanance/spatial-unit";

import UnitPlan from "./screen/unit_plan/unitPlan";
import UnitTitles from "./screen/unitTitles";
import Decision from "./screen/decicion";
import TpChangeOfUse from "./screen/tpChangeOfUse";
import ApplicationInfo from "./screen/applicationInfo";
import Status from "./screen/status";
import NewTpSpatialUnitPane from "./screen/spatial-unit/new_tp_spatial_unit";
import LegacySubdivision from "./screen/legacy-subdivision";


export default class TransactionPopup extends React.Component {

    static self = null;
    constructor(props) {
        super(props);

        this.state = {
            transaction_no: '',
            transactionIds: [],
            transaction: {},
            screens: [],
            screensComps: [],
            currentTabId: 0,
            nextTask: {
                question: '',
                options: []
            },
            documents: {
                listOfDoc: [],
                listOfRequiredDoc: [],
                listOfOutgoingDoc: [],
            }


        }

        TransactionPopup.self = this;
        this.getTransactionContents = this.getTransactionContents.bind(this)
        this.saveTransaction = this.saveTransaction.bind(this)
        this.popCamundda = this.popCamundda.bind(this)
        this.showScreenAt = this.showScreenAt.bind(this)
    }



    /*********************************/
    static state() { return TransactionPopup.self.state }
    static setState(state_name, state_value) {
        try {
            TransactionPopup.self.setState({ [state_name]: state_value })
        } catch (e) {
            console.log(e)
        }
    }
    static transaction() { return TransactionPopup.state().transaction }
    static id() { return TransactionPopup.state().transaction.txId }
    static definitionID() { return TransactionPopup.state().transaction.txDefId }
    /*********************************/

    static persons() { return TransactionPopup.state().persons }
    static parameters() { return TransactionPopup.state().parameters }
    static assignee() { return TransactionPopup.state().assignee }
    static task() { return TransactionPopup.state().transaction.taskName }
    static taskId() { return TransactionPopup.state().transaction.taskId }
    static clear() {
        TransactionPopup.self.state = null
    }



    static show(transaction) {
        console.log(transaction)

        document.getElementById('div-popup').style.display = 'inherit'

        try {
            setTimeout(() => {
                TransactionPopup.setState('tx', transaction)
                TransactionPopup.setState('transaction_no',
                    transaction.isNew === false ?
                        <span>Transaction No . <span style={{ color: '#DF4A36', marginRight: '24px' }}>{transaction.txNo}</span></span> :
                        <span>{transaction.name}</span>)
                TransactionPopup.setState('persons', [])
                TransactionPopup.setState('transaction', transaction)


                //TransactionPopup.setState('transaction',transaction)

                try {

                    Wait.show("Loading Contents...")
                    if (transaction.isNew) {
                        TransactionPopup.createNewTransaction(transaction)
                    } else {

                        TxReadService.transactionContents(transaction.txId).then(r => {
                            Wait.dismiss()
                            //console.log(r.data)
                            this.cacheData(r)
                        })
                    }

                } catch (e) {
                    console.log(e)
                }
            }, 500)
        } catch (e) {
            console.log(e)
        }



    }
    getTransactionContents(txId) {

        /*let self=this
        TxReadService.acknowledgementReceipt(txId).then(d => {
            console.log(d.data)
            self.setState({
                pdfData:<embed style={{height:'100%', width:'100%'}} src={"data:application/pdf;base64, "+d.data}/>,
                base64:d.data
            })
            console.log("self",self.state.pdfData)
        });*/
    }

    static dismiss() {
        document.getElementById('div-popup').style.display = 'none';
        TransactionPopup.setState("currentTabId", 0)
        TransactionPopup.setState("screens", [])
        TransactionPopup.setState("screensComps", [])
    }

    popCamundda() {

        PopDetail.show(this.state.transaction.name, <BpmnViewer bpmnfile={(this.state.transaction.name).replaceAll(' ', '')} currentTask={TransactionPopup.state().transaction.taskKey} />)
        console.log("the key is ", TransactionPopup.state().transaction.taskKey)
    }

    render() {
        return (<div className={'popup'} id={'div-popup'}>
            <div style={{ height: '6vh', background: '#fff', borderBottom: '0.5px solid #efefef' }}>
                <div className={'col col-sm-9'} style={{ float: 'left' }}>
                    <div style={{ height: '6vh', lineHeight: '6vh', cursor: 'pointer' }} className={'col col-10'} onClick={() => this.popCamundda()}>
                        {this.state.transaction_no}{this.state.transaction.name} / {this.state.transaction.taskName}
                    </div>
                </div>
                <div className={'col col-sm-3'} style={{ textAlign: 'right', float: 'right', height: '6vh', lineHeight: '6vh' }}>
                    <span style={{ padding: '8px', color: '#fff', fontWeight: 'bold', cursor: 'pointer', marginRight: '4px', background: '#DF4A36' }} onClick={() => this.saveTransaction(false)} >Save</span>
                    <span style={{ padding: '8px', fontWeight: 'bold', cursor: 'pointer', marginRight: '4px' }} onClick={() => this.saveTransaction(true)} className={'default'}>Save & Close</span>
                    <span style={{ padding: '8px', fontWeight: 'bold', cursor: 'pointer' }} onClick={TransactionPopup.dismiss} className={'default'}>Cancel</span>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col col-sm-2'} style={{ paddingRight: 0 }}>
                    {
                        this.state.screens.map((d, i) => {
                            return (<div key={i} style={this.state.currentTabId === i ? selected : {}} className={'btn-left-menu'} onClick={() => this.showScreenAt(i, d.screenName)}>
                                {d.screenName}
                            </div>)
                        })
                    }

                </div>

                <div className={'col col-sm-10'} style={{ height: '94vh', paddingLeft: 0 }}>
                    {
                        //this.state.current_screen
                        this.state.screensComps.map((d, i) => {
                            return (<div key={i} className={this.state.currentTabId === i ? 'show' : 'hide'}>{d}</div>)
                        })
                    }
                </div>

            </div>


        </div>)
    }

    saveTransaction(close = true) {
        try {
            TransactionPopup.setState("currentTabId", 0)
            TransactionPopup.setState("screens", [])
            TransactionPopup.setState("screensComps", [])
            let data = {
                // txId:TransactionPopup.state().transaction.txId
                txId: TransactionPopup.id()
            }

            console.log("TRANSACTION ID==> ", data)
            Wait.show("Saving...")
            TxCreateService.saveTransaction(data).then(r => {
                Wait.dismiss()
                TransactionTable.loadData()
                console.log(r)
                if (close) { TransactionPopup.dismiss() }
            })
        } catch (e) {
            console.log(e)
        }
    }

    static loadScreenAt(d) {

        console.log(TransactionPopup.state().transaction)


        return d.screenName === "Summary" ? <SummaryPane writable={d.writable} /> :
            d.screenName === "Decision" ? <Decision /> :
                d.screenName === "Amendment" ? <TpAmendSpatialUnitPane /> :
                    d.screenName === "Change of Use" ? <TpChangeOfUse writable={true} /> :
                        d.screenName === "Application Info" ? <ApplicationInfo writable={true} /> :
                            d.screenName === "Records Requests" ? <RecordRequest writable={d.writable} /> :
                                d.screenName === "Documents" ? <DocumentPane writable={d.writable} /> :
                                    d.screenName === "Print Documents" ? <PrintDocumentsPane /> :
                                        d.screenName === "Reports" ? <ReportsPane /> :
                                            d.screenName === "Instruction To Survey" ? <InstructionToSurvey /> :
                                                d.screenName === "Review" ? <ReviewPane transaction={TransactionPopup.state().transaction} /> :
                                                    d.screenName === 'Map' ? <GROMap transaction={TransactionPopup.state().transaction} /> :
                                                        d.screenName === 'Spatial Units' ? TransactionPopup.state().transaction.name === 'First Parcel Registration' ? <SpatialUnitScreen type={'smd_spatial'} transaction={TransactionPopup.state().transaction} /> : <SpatialUnit writable={d.writable} /> :
                                                            d.screenName === 'Spatial Upload' ? <SpatialUnitScreen type={'smd_spatial'} transaction={TransactionPopup.state().transaction} /> :
                                                                d.screenName === 'Tp Spatial Upload' ? <TpSpatialUnitPane type={'tp_spatial'} transaction={TransactionPopup.state().transaction} /> :
                                                                    d.screenName === 'TP Spatial' ? TransactionPopup.state().transaction.name === 'TP Drawing Registration'?<NewTpSpatialUnitPane type={'unapproved_new_tp_spatial'} transaction={TransactionPopup.state().transaction} />: <TpSpatialUnitPane type={'tp_spatial'} transaction={TransactionPopup.state().transaction} /> :
                                                                        d.screenName === 'TP Info' ? <TpInfoPane /> :
                                                                            d.screenName === 'Valuations' ? <ValuationPage writable={d.writable} /> :
                                                                                d.screenName === 'Print Registered TP' ? <TpSpatialUnitPane key={new Date().getMilliseconds()} /> :
                                                                                    d.screenName === 'Print Registered Map' ? <SpatialUnitScreen screen={'print'} type={'smd_spatial'} transaction={TransactionPopup.state().transaction} /> :
                                                                                        d.screenName === 'Persons' ? <Persons writable={d.writable} /> :
                                                                                            d.screenName === 'Survey Instruction' ? <SurveyInstructionPane /> :
                                                                                                d.screenName === 'Payments' ? <PaymentPane writable={d.writable} /> :
                                                                                                    d.screenName === 'Valuation Block Spatial' ? <ValBlockSpatialUnitPane key={new Date().getMilliseconds()} /> :
                                                                                                        d.screenName === 'Property' ? TransactionPopup.state().transaction.name === 'Grant Right of Occupancy' ? <PropertyGRO /> :
                                                                                                            TransactionPopup.state().transaction.name === 'Land Rent Assessment' ? <PropertyPane /> : <PropertyPane /> :
                                                                                                            d.screenName === 'Property GRO' ? TransactionPopup.state().transaction.name === 'Official Search' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Change of Address' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Change of Name' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Change of Signature' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Title Data Maintenance' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Lease Right of Occupancy' ||
                                                                                                                TransactionPopup.state().transaction.name === 'TIC Lease Right of Occupancy' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Caveat' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Rectification by LAD' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Notice of Deposit' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Withdraw of Deposit' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Caveat' ||
                                                                                                                TransactionPopup.state().transaction.name === 'Reissue Lost or Damaged Certificate' ? <OfficialSearchProperty writable={d.writable} /> : <PropertyGRO /> :
                                                                                                                d.screenName === 'Properties' ? <Properties /> :
                                                                                                                    d.screenName === 'Compensation Rates Upload' ? <CompensationRatesUpload /> :
                                                                                                                        d.screenName === 'Title//Rent' ? <TitleRent /> :
                                                                                                                            d.screenName === 'Conditions' ? <ConditionsPage /> :
                                                                                                                                d.screenName === 'Interests' ? TransactionPopup.state().transaction.name === 'Title Data Maintenance' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'Lease Right of Occupancy' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'TIC Lease Right of Occupancy' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'Grant Leasehold' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'TIC Grant Leasehold' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'Legal Personal Representative of Right of Occupancy' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'Notice of Deposit' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'Withdraw of Deposit' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'Caveat' ||
                                                                                                                                    TransactionPopup.state().transaction.name === 'Assent to Bequest of Right of Occupancy' ? <ScreenInterest writable={d.writable} /> : <Interest /> :
                                                                                                                                    d.screenName === 'LAD Checklist' ? <LADChecklist /> :
                                                                                                                                        d.screenName === 'RTU CheckList' ? <RTUChecklist /> :
                                                                                                                                            d.screenName === 'Select plot' ? <SelectPlot /> :
                                                                                                                                                d.screenName === "Completion" ? <CompletePane /> :
                                                                                                                                                    d.screenName === "Cancellation" ? <CancelControlNumber writable={d.writable} /> :
                                                                                                                                                        d.screenName === "Valuation" ? <ValuationPage writable={d.writable} /> :
                                                                                                                                                            d.screenName === "Select Plots" ? <SelectLegacyPlots writable={d.writable} /> :
                                                                                                                                                                d.screenName === "Spatial Unit" ? <SpatialUnitMaintanance writable={d.writable} /> :
                                                                                                                                                                d.screenName === "Subdivision" ? <LegacySubdivision writable={d.writable} /> :
                                                                                                                                                                    d.screenName === "Confirm Plots Status" ? <ConfirmPlotsStatus writable={d.writable} /> :
                                                                                                                                                                        d.screenName === "Confirmed Plots Status" ? <ConfirmedPlotsStatus writable={d.writable} /> :
                                                                                                                                                                            d.screenName === "Control Number Selection" ? <PaneControlNumberSelection /> :
                                                                                                                                                                                d.screenName === "Unit Plan" ? <UnitPlan /> :
                                                                                                                                                                                    d.screenName === "Unit Titles" ? <UnitTitles /> :
                                                                                                                                                                                        d.screenName === "Status" ? <Status /> : ''

    }

    static setScreenComps(screens) {
        setTimeout(() => {
            let screensComps = []
            screens.map(d => {
                screensComps.push(TransactionPopup.loadScreenAt(d))
            })
            TransactionPopup.self.setState({
                screensComps: screensComps
            })
        }, 500)
    }


    static cacheData(r) {
        try {
            TransactionPopup.setState('transaction', {
                txId: r.data.txId,
                isNew: false,
                name: r.data.name,
                taskId: r.data.taskId,
                taskKey: r.data.taskKey,
                taskName: r.data.taskName,
                txDefId: r.data.txDefId,
                txNo: r.data.txNo,
            })

            TransactionPopup.setState('screens', r.data.screens)
            TransactionPopup.setState('nextTask', r.data.nextTask)
            TransactionPopup.setState('documents', r.data.documents)
            TransactionPopup.setState('spatialUnit', r.data.spatialUnit)
            TransactionPopup.setState('summary', r.data.summary)
            TransactionPopup.setState('objs', r.data.objs)

            TransactionPopup.setScreenComps(r.data.screens)

            console.log("COMENTS", r.data.summary)
            setTimeout(() => {
                SummaryPane.setState('persons', r.data.summary.persons)
                SummaryPane.setState('appSummary', r.data.summary.appSummary)
                SummaryPane.setState('parameters', r.data.summary.parameters)
                SummaryPane.setState('processingComments', r.data.summary.processingComments)
            }, 1000)


        } catch (e) {
            console.log(e)

        }

    }

    static createNewTransaction(transaction) {
        TxReadService.newTransactionScreens(transaction.txDefId).then(r => {
            Wait.dismiss()
            try {
                console.log(r.data)


                let data = {
                    txDefinition: transaction.txDefId,
                    isNew: true,
                }

                Wait.show("Saving...")
                TxCreateService.createTransaction(data).then(r => {
                    Wait.dismiss()
                    //console.log(r.data)
                    this.cacheData(r)

                    TransactionTable.loadData()


                    //TransactionPopup.self.setState({screens: r.data.screens,})
                    //TransactionPopup.setScreenComps(r.data.screens)

                })

            } catch (e) {
                console.log(e)
            }
        })
    }

    showScreenAt(i, screenName) {
        this.setState({ currentTabId: i })

        console.log(screenName)
        switch (screenName) {
            case 'Valuation Block Spatial':
                ValBlockSpatialUnitPane.loadData()
                break
            case 'Map':
                GROMap.loadSpatial()
                break
            case 'TP Spatial':
            case 'Tp Spatial Upload':
            case 'Print Registered TP':
                TpSpatialUnitPane.loadData(screenName)
                break
            case 'Amendment':
                TpAmendSpatialUnitPane.loadData(screenName)
                break
            case 'Survey Instruction':
                SurveyInstructionPane.loadData()
                break
            case 'Spatial Upload':
            case 'Print Registered Map':
                SpatialUnitScreen.loadData()
                break
            case 'Spatial Units':
                console.log(TransactionPopup.state().transaction.name)
                if (TransactionPopup.state().transaction.name === 'First Parcel Registration') {
                    SpatialUnitScreen.loadData()
                }
                break
        }
    }
}


function getLandRentObjects() {

    return TxReadService.getLandRentObjs(TransactionPopup.id()).then(r => {
        console.log(r)
        return r
    })



}
const selected = { cursor: 'pointer', borderLeft: '5px solid #DF4A36', background: '#ffffff', fontWeight: 'bold' }
