import React from "react";
import ProjectConfig from "./project_admin/project_config";
import ProjectInfo from "./superuser/project_info";
import MobileDictionaryPane from "./superuser/mobile_dictionary";

export default class Superuser extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            tabs:['Project Info','Project Config','Dictionary','Entities'],
            screens:[<ProjectInfo/>,<ProjectConfig/>,<MobileDictionaryPane/>],
            tab:0,
            current_tab:<ProjectInfo/>
        }

        this.setTabAt = this.setTabAt.bind(this)
    }

    setTabAt(i){
        this.setState({
            tab:i,
            current_tab:this.state.screens[i]
        })
    }

    render() {
        return(<div className={'row'} style={{background:'#fff',height:'100%'}}>
            <div className={'col col-sm-12'} style={{height:'100%'}}>
                <div style={{width:'100%',float:'left'}}>
                    {this.state.tabs.map((d,i)=>{
                        return(<div className={this.state.tab===i?'tab tab-active':'tab'} key={i} onClick={()=>this.setTabAt(i)}>
                            {d}
                        </div>)
                    })}
                </div>
                <div style={{width:'100%',float:'left'}}>
                    {this.state.current_tab}
                </div>

            </div>
        </div>)
    }
}