import * as React from "react";
import MobileReadService from "../../../../../services/mobile/mobile_read";
import Wait from "../../../wait";
import PopDetail from "../../../popdetail";
import Message from "../../../transacrions/screen/gro/person/message";
import MobileUpdateService from "../../../../../services/mobile/mobile_update";

export default class PaneParcelInfo extends  React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            tp_drawings:[],
            layers:[],
            occupancys:[],
            uses:[],
            page_status:'Loading....',
            cont_phone:'',
            cont_person:'',
            wanufaika:''
        }

        PaneParcelInfo.self = this
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)

        this.update = this.update.bind(this)
    }

    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}
    handleChangeFile=e=>{this.setState({ [e.target.name]:e.target.files[0] })}

    componentDidMount() {
        PaneParcelInfo.loadData()
    }


    update(){
        try {
            Wait.show('Updating...')
            MobileUpdateService.updateParcelInfo({
                contactPerson: this.state.cont_person,
                contactPhone: this.state.cont_phone,
                kaskazini: this.state.north,
                kusini: this.state.south,
                mashariki: this.state.east,
                magharibi: this.state.west,
                matumizi: this.state.use.split('_')[0],
                aina: this.state.occupancy.split('_')[0],
                maendelezo: this.state.development,
                maelezo: this.state.comment,
                nyumba: this.state.house,
                wanufaika: this.state.wanufaika,
                pid: this.props.parcel.id
             }).then(r =>{
                 Wait.dismiss()
                 if(r.data.status===0){
                     PopDetail.show("Warning",<Message faIcon={'harzad'} message={r.data.message}/>,30)
                 }
            })
        }catch (e) {
            console.log(e)
        }

    }



    static loadData(){
        try {
            MobileReadService.readParcelInfo(PaneParcelInfo.self.props.parcel.id).then(r => {
                try {
                    let data = r.data

                    console.log(data)
                    PaneParcelInfo.self.setState({
                        claim_no: data.claim_no,
                        uka: data.uka,
                        comment: data.comment,
                        cont_person: data.cont_person,
                        cont_phone: data.cont_phone,
                        date: data.date,
                        house:data.house,
                        development: data.development,
                        east: data.east,
                        north:data.north,
                        occupancy:data.occupancy,
                        paras: data.paras,
                        reg_no: data.reg_no,
                        south: data.south,
                        stage: data.stage,
                        status:data.status,
                        street:data.street,
                        use: data.use,
                        uses: data.uses,
                        occupancys:data.occupancys,
                        wanufaika:data.wanufaika,
                        ward: data.ward,
                        west:data.west,
                        page_status:''
                    })

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    render() {
        return(<div className={'col col-sm-12'} style={{height:'90%',overflow:'auto'}}>
            <p>{this.state.page_status}</p>
            <table style={{width:'100%',marginTop:'24px'}}>
                <tbody>
                <tr>
                    <td style={{color:'darkcyan'}}>Para surveyor</td>
                    <td style={{color:'darkcyan'}}>Claim Date</td>
                    <td style={{color:'darkcyan'}}>Claim Number</td>
                    <td style={{color:'darkcyan'}}>Registration Number</td>
                </tr>
                <tr>
                    <td><b>{this.state.paras}</b></td>
                    <td><b>{this.state.date}</b></td>
                    <td><b>{this.state.claim_no} - {this.state.uka}</b></td>
                    <td><b>{this.state.reg_no}</b></td>
                </tr>

                <tr>
                    <td style={{color:'darkcyan'}}>Ward</td>
                    <td style={{color:'darkcyan'}}>Street</td>
                    <td style={{color:'darkcyan'}}>Contact Person Name / Topology</td>
                    <td style={{color:'darkcyan'}}>Contact Person Phone / Wanufaika</td>

                </tr>
                <tr>
                    <td><b>{this.state.ward}</b></td>
                    <td><b>{this.state.street}</b></td>
                    <td><input style={{width:'100%'}} type={'text'} name={'cont_person'} defaultValue={this.state.cont_person} onChange={this.handleChange}/></td>
                    <td><input style={{width:'100%'}} type={'text'} name={'cont_phone'} defaultValue={this.state.cont_phone} onChange={this.handleChange}/></td>
                </tr>
                <tr>
                    <td style={{color:'darkcyan'}}>North</td>
                    <td style={{color:'darkcyan'}}>South</td>
                    <td style={{color:'darkcyan'}}>East</td>
                    <td style={{color:'darkcyan'}}>West</td>
                </tr>
                <tr>
                    <td><input style={{width:'100%'}} name={'north'} defaultValue={this.state.north} onChange={this.handleChange}/></td>
                    <td><input style={{width:'100%'}} name={'south'} defaultValue={this.state.south} onChange={this.handleChange}/></td>
                    <td><input style={{width:'100%'}} name={'east'} defaultValue={this.state.east} onChange={this.handleChange}/></td>
                    <td><input style={{width:'100%'}} name={'west'} defaultValue={this.state.west} onChange={this.handleChange}/></td>
                </tr>

                <tr>
                    <td style={{color:'darkcyan'}}>Use</td>
                    <td style={{color:'darkcyan'}}>Occupancy</td>
                    <td style={{color:'darkcyan'}}>Development</td>
                    <td style={{color:'darkcyan'}}>House</td>
                </tr>
                <tr>
                    <td>
                        <select  style={{width:'100%'}} name={'use'} defaultValue={this.state.use} onChange={this.handleChange}>
                            <option>{this.state.use}</option>
                            {this.state.uses.map((d,i)=>{
                                return(<option key={i}>{d}</option>)
                            })}
                        </select>
                    </td>
                    <td>
                        <select  style={{width:'100%'}} name={'occupancy'} defaultValue={this.state.occupancy} onChange={this.handleChange}>
                            <option>{this.state.occupancy}</option>
                            {this.state.occupancys.map((d,i)=>{
                                return(<option key={i}>{d}</option>)
                            })}
                        </select>
                    </td>
                    <td><input style={{width:'100%'}} name={'development'} defaultValue={this.state.development} onChange={this.handleChange}/></td>
                    <td><input style={{width:'100%'}} name={'house'} defaultValue={this.state.house} onChange={this.handleChange}/></td>
                </tr>
                <tr>
                    <td style={{color:'darkcyan'}} colSpan={4}>Others Party</td>
                </tr>
                <tr>
                    <td style={{color:'darkcyan'}} colSpan={4}><textarea style={{width:'100%'}} name={'wanufaika'} defaultValue={this.state.wanufaika} onChange={this.handleChange}/></td>
                </tr>

                <tr>
                    <td style={{color:'darkcyan'}} colSpan={4}>Comment</td>
                </tr>
                <tr>
                    <td style={{color:'darkcyan'}} colSpan={4}><textarea style={{width:'100%'}} name={'comment'} defaultValue={this.state.comment} onChange={this.handleChange}/></td>
                </tr>
                <tr>
                    <td/>
                    <td>
                        <button onClick={this.update}>Update</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>)
    }

}