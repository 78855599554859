import * as React from "react";
import Wait from "../../wait";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import MobilePopup from "../mobile_popup";
import PopDetail from "../../popdetail";
export default class PaneUploadDraft extends React.Component{

    constructor(props) {
        super(props);
        this.onCoordChange = this.onCoordChange.bind(this)
        this.submitCoordinates= this.submitCoordinates.bind(this)
    }

    onCoordChange = (e) => {e.preventDefault();this.setState({coordinate:e.target.files[0]})}

    submitCoordinates(){
        let coord = this.state.coordinate

        if (coord===undefined){
            alert('No File')
            return
        }

        try {
            Wait.show('Saving....')

            const formData = new FormData()
            formData.append('coordinate',this.state.coordinate)
            formData.append('extra',JSON.stringify({
                project_id:MobilePopup.id()
            }))

            MobileCreateService.uploadDraft(formData).then(r => {
                try {

                    Wait.dismiss()
                    console.log(r.data)
                    PopDetail.dismiss()
                    alert(r.data.message+' - ' +r.data.count + ' parcels from GIS Approval')
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }

    }
    render() {
        return(<div style={{textAlign:'center'}} className={'col col-sm-12'}>
            <br/>
            <br/>
            <input type={'file'} onChange={this.onCoordChange} accept={".zip, .ZIP"}/>
            <br/>
            <br/>
            <button onClick={()=>this.submitCoordinates()}>Submit</button>
        </div>)
    }
}