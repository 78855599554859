import * as React from "react";
import Wait from "../../wait";
import MobilePopup from "../mobile_popup";
import PopDetail from "../../popdetail";
import Message from "../../transacrions/screen/gro/person/message";
import MobileUpdateService from "../../../../services/mobile/mobile_update";
import MobileProjectLayerPane from "../screens/project_layers";
export default class PaneUpdateLayer extends React.Component{

    constructor(props) {
        super(props);
        this.onCoordChange = this.onCoordChange.bind(this)
        this.submitCoordinates= this.submitCoordinates.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    onCoordChange = (e) => {e.preventDefault();this.setState({coordinate:e.target.files[0]})}
    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}

    submitCoordinates(){
        let coord = this.state.coordinate

        if (coord===undefined){
            PopDetail.show("Warning",<Message faIcon={'warning'} message={'No File'}/>,30)
            return
        }

        try {
            Wait.show('Saving....')

            const formData = new FormData()
            formData.append('coordinate',this.state.coordinate)
            formData.append('extra',JSON.stringify({
                project_id:MobilePopup.id(),
                layer_id:this.props.layer.id,
                layerName:this.state.name,
                layerLabel:this.state.label
            }))

            MobileUpdateService.updateLayer(formData).then(r => {
                try {
                    Wait.dismiss()
                    PopDetail.dismiss()
                    MobileProjectLayerPane.loadData()
                    PopDetail.show("Info",<Message faIcon={'info'} message={r.data.count + ' Layers from GIS Approval'}/>,30)
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }

    }
    render() {
        return(<div className={'col col-sm-12'}>
            <br/>
            <br/>
            <table style={{width:'100%'}}>
                <tbody>
                <tr>
                    <td><span style={{color:'darkcyan'}}>Layer Name : </span></td>
                    <td>
                        <input name={'name'} defaultValue={this.props.layer.name} style={{width:'100%'}}/>
                    </td>
                </tr>
                <tr>
                    <td><span style={{color:'darkcyan'}}>Layer Label : </span></td>
                    <td>
                        <input name={'label'} defaultValue={this.props.layer.label} style={{width:'100%'}}/>
                    </td>
                </tr>
                <tr>
                    <td><span style={{color:'darkcyan'}}>Shapefile : </span></td>
                    <td>
                        <input type={'file'} onChange={this.onCoordChange} accept={".zip, .ZIP"} style={{width:'100%'}}/>
                    </td>
                </tr>
                <tr>
                    <td/>
                    <td>
                        <button onClick={()=>this.submitCoordinates()}>Submit</button>
                    </td>
                </tr>
                </tbody>
            </table>

            <br/>
            <br/>

        </div>)
    }
}