import TransactionPopup from "../transaction_popup";
import * as React from "react";
import TPCreateService from "../../../../services/tp/tp_create";
import Wait from "../../wait";

export default class PreviewPrintDocument extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            doc_frame:<div style={{padding:'24px'}}>No Document</div>,
            size:'A0',
            orientation:'Portrait',
            zoom:0
        }

        this.loadTPDocument = this.loadTPDocument.bind(this)
    }
    componentDidMount() {
        this.loadTPDocument()
    }

    loadTPDocument(){
        Wait.show('Creating Document....')
        this.setState({
            doc_frame:<div style={{padding:'24px'}}>Loading... {this.state.size} x {this.state.orientation}</div>
        })
        TPCreateService.createDocument({
            size:this.state.size,
            orientation:this.state.orientation,
            zoom:this.state.zoom,
            type:this.props.type
        }).then(d=>{
            Wait.dismiss()
            try {
                console.log(d)
                this.setState({
                    doc_frame:<embed style={{height:'100%', width:'100%'}} src={"data:application/pdf;base64, "+d.data}/>
                })
            }catch (e) {
                console.log(e)
            }
        })
    }

    render() {
        return(<div className={'row'} style={{height:'100%'}}>
            <div className={'col col-sm-3'} style={{height:'100%'}}>
                <div style={{background:'#fff',padding:'20px',height:'100%',border:'0.5px solid #efefef'}}>
                    <div style={{display:TransactionPopup.task().startsWith('Print')?'inherit':'none'}}>
                        <div>
                            <b>Paper Size</b>
                        </div>
                        <div>
                            <select style={{width:'100%'}} onChange={(e)=>{
                                let value = e.target.value
                                setTimeout(()=>{
                                    this.setState({
                                        size:value
                                    })
                                    this.loadTPDocument()
                                },500)
                            }}>
                                <option value={'A0'}>- Select Paper Size  </option>
                                <option>A0</option>
                                <option>A1</option>
                                <option>A2</option>
                                <option>A3</option>
                                <option>A4</option>
                            </select>
                        </div>
                        <div style={{marginTop:'24px'}}>
                            <b>Paper Orientation</b>
                        </div>
                        <div>
                            <select style={{width:'100%'}} onChange={(e)=>{
                                let value = e.target.value
                                setTimeout(()=>{
                                    this.setState({
                                        orientation:value
                                    })
                                    this.loadTPDocument()
                                },500)
                            }} >
                                <option value={'Portrait'}>- Select Orientation  </option>
                                <option>Portrait</option>
                                <option>LandScape</option>
                            </select>
                        </div>

                        <div style={{marginTop:'24px'}}>
                            <b>Map Zoom</b>
                        </div>
                        <div>
                            <select style={{width:'100%'}} name={'paper'} onChange={(e)=>{
                                let value = e.target.value
                                setTimeout(()=>{
                                    this.setState({
                                        zoom:value
                                    })
                                    this.loadTPDocument()
                                },500)
                            }}>
                                <option value={1}>- Select Zoom  </option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                            </select>
                        </div>


                        <br/>
                        <div>
                            <b>Select Layers</b>
                        </div>
                        <br/>
                        <div>
                            <span><input type={'checkbox'}/> Parcels</span><br/>
                            <span><input type={'checkbox'}/> Buildings</span><br/>
                            <span><input type={'checkbox'}/> Contours</span><br/>
                            <span><input type={'checkbox'}/> Point</span><br/>
                            <span><input type={'checkbox'}/> Ponds</span><br/>
                            <span><input type={'checkbox'}/> Power Lines</span><br/>
                            <span><input type={'checkbox'}/> Railways</span><br/>
                            <span><input type={'checkbox'}/> Rivers</span><br/>
                            <span><input type={'checkbox'}/> Roads</span><br/>
                        </div>

                    </div>

                </div>
            </div>

            <div className={'col col-sm-9'} style={{height:'100%',paddingLeft:'4px'}}>

                <div className={'row'} style={{background:'#fff',height:'90%',border:'0.5px solid #efefef'}}>
                    <div className={'col col-sm-12'} style={{height:'40px',lineHeight:'40px'}}>
                        <b>Document</b>

                    </div>

                    <div className={'col col-sm-12'} style={{height:'92%',border:'1px solid #ccc',padding:0}}>

                        <div className={'col col-sm-12'} style={{background:'#efefef',padding:0,float:'left',height:'100%'}} id={'ramani'}>
                            {this.state.doc_frame}
                        </div>

                    </div>

                </div>
            </div>
        </div>)
    }
}