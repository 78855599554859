import * as React from "react";
import PrintDocPane from "./print_doc";
export default class PrintDocumentsPane extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            data: {
                listOfDoc: [
                    {docName:'Report 01'},
                    {docName:'Report 02'},
                    {docName:'Report 03'},
                    {docName:'Report 04'}
                ]
            },
            current_doc: {},
            doc_frame: <PrintDocPane/>
        }

        this.showDocumentAt = this.showDocumentAt.bind(this)
    }

    componentDidMount() {

    }

    render() {
        return(<div className={'col col-sm-12'} style={{height:'94vh',paddingRight:'4px',paddingLeft:0}}>

            <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>

                <div className={'row'} style={{height:'97%'}}>
                    <div className={'col col-sm-3'} style={{padding:0}}>
                        <div style={{padding:'24px'}}>
                            <span  style={{marginBottom:'16px'}}>Select Document To Print</span>
                            {this.state.data.listOfDoc.map((d,i)=>{
                                return(<div key={i} style={{width:'100%',fontSize:'13px',cursor:'pointer'}} onClick={()=>this.showDocumentAt(d)}>

                                    <div>
                                        <input type={'radio'} name={'doc'} style={{marginRight:'16px'}}/>
                                        <span className={'fa fa-file'} style={{marginRight:'8px'}}/>{d.docName}
                                    </div>
                                    <div>{d.tag}</div>
                                </div>)
                            })}

                            <hr/>

                            {/*<div style={{marginTop:'16px'}}>Select District</div>
                            <div>
                                <select style={{width:'100%'}}>
                                    <option>--</option>
                                </select>
                            </div>
                            <div style={{marginTop:'16px'}}>Select Village/Ward</div>
                            <div>
                                <select style={{width:'100%'}}>
                                    <option>--</option>
                                </select>
                            </div>

                            <div style={{marginTop:'16px'}}>Select Hamlet/Street</div>
                            <div>
                                <select style={{width:'100%'}}>
                                    <option>--</option>
                                </select>
                            </div>
                            <hr/>*/}
                            <div style={{textAlign:"center"}}>
                                <button>Generate PDF</button>
                            </div>

                        </div>

                    </div>
                    <div className={'col col-sm-9'} style={{background:'#efefef'}}>
                        {this.state.doc_frame}
                    </div>
                </div>

            </div>
        </div>)
    }

    showDocumentAt(d) {
        console.log(d)
        this.setState({
            current_doc:d
        })
    }
}