import React, {useState, useEffect} from 'react'
import {styles} from "../../components/popups/transacrions/screen/record-request";
import Header from "../../components/popups/transacrions/screen/gro/header";
import PopDetailsTwo from "../../components/popups/PopDetailsTwo";
import {useBuildingDispatch, useBuildingState} from "./store";

export const purify= data=>{
    return isNaN(data)?0:data
}
/*Crops*/

const Crop =()=>{
    const dispatch= useBuildingDispatch()
    const[cropType,cropTypeSet]=useState('');const[area,areaSet]=useState(0);
    const[quantity,quantitySet]=useState(0);const[cropValue,cropValueSet]=useState(0);
    const[growthRate,growthRateSet]=useState(0);const[totalCropsValue,totalCropsValueSet]=useState(0);

    useEffect(()=>{
        if(area===0){
            totalCropsValueSet(purify(quantity)*purify(cropValue)*purify(growthRate)*0.01)
        }else{
            totalCropsValueSet(purify(area)*purify(cropValue)*purify(growthRate)*0.01)
        }
    },[quantity,cropValue,growthRate,area])

    const saveCrop=()=>{
        const data ={valuationCropsId:null,cropType,area,quantity,cropValue,growthRate,totalCropsValue}
        dispatch({type:"crop",payload:data})
        PopDetailsTwo.dismiss()
    }
    return(
        <>
            <table style={styles.table}>
                <tbody>
                    <tr>
                        <td>Crops Type</td>
                        <td>Area</td>
                    </tr>
                    <tr>
                        <td><input type={"text"} name={"cropType"} style={styles.table} value={cropType} onChange={e=>cropTypeSet(e.target.value)}/></td>
                        <td><input type={"number"} name={"area"} style={styles.table} value={area} placeholder={"Enter area in acres"} onChange={e=>areaSet(parseFloat(e.target.value))}/></td>
                    </tr>
                    <tr>
                        <td>Quantity</td>
                        <td>Crop Value</td>
                    </tr>
                    <tr>
                        <td><input type={"number"} name={"quantity"} style={styles.table} value={quantity} onChange={e=>quantitySet(parseFloat(e.target.value))}/></td>
                        <td><input type={"number"} name={"cropValue"} style={styles.table} value={cropValue} onChange={e=>cropValueSet(parseFloat(e.target.value))}/></td>
                    </tr>
                    <tr>
                        <td>Growth Rate</td>
                        <td>Total Crops Value</td>
                    </tr>
                    <tr>
                        <td><input type={"number"} name={"growthRate"} style={styles.table} value={growthRate} onChange={(e)=>growthRateSet(parseFloat(e.target.value))}/></td>
                        <td><input type={"number"} name={"totalCropsValue"} style={styles.table} value={totalCropsValue} onChange={e=>totalCropsValueSet(parseFloat(e.target.value))}/></td>
                    </tr>
                <tr>
                    <td colSpan={2}>
                        <button style={{float:"right"}} className={"default"} onClick={PopDetailsTwo.dismiss}>Cancel</button>
                        <button style={{float:"right"}} onClick={saveCrop}>Save</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )
}
const Crops =()=>{
    const state = useBuildingState()
    const{ valuationCrops:crops}=state
    return(
        <div style={{margin:0, padding:'.5rem'}}>
            <Header heading={false} display={true} label={"Crops"}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetailsTwo.show("New Crop",<Crop/>,40)}}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetailsTwo.show("Edit Crop",<Crop/>,40)}}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{}}
                    /><strong> Remove</strong>
                </div>
            </Header>
            <table className={"tb"} style={styles.table}>
                <thead>
                    <tr>
                        <th>CROPS TYPE</th>
                        <th>AREA</th>
                        <th>QUANTITY</th>
                        <th>CROP VALUE</th>
                        <th>GROWTH RATE</th>
                        <th>TOTAL CROP VALUE</th>
                    </tr>
                </thead>
                <tbody>
                {
                    crops.map(({cropType,area,quantity,cropValue,growthRate,totalCropsValue},i)=>
                    <tr key={i}>
                        <td>{cropType}</td>
                        <td>{area}</td>
                        <td>{quantity}</td>
                        <td>{cropValue}</td>
                        <td>{growthRate}</td>
                        <td>{totalCropsValue}</td>
                    </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    )
}

/*Graves*/
const Grave =()=>{
    const dispatch = useBuildingDispatch()
    const[deceasedName,deceasedNamSet]=useState('');const[details,detailsSet]=useState('');
    const[xCoordinate,xCoordinateSet]=useState(0);const[yCoordinate,yCoordinateSet]=useState(0);
    const[removalCost,removalCostSet]=useState(0);const[transportCost,transportCostSet]=useState(0);
    const[reburnial,reburnialSet]=useState(0);const[religiousBeliefCost,religiousBeliefCostSet]=useState(0);
    const[condolences,condolencesSet]=useState(0);const[total,totalSet]=useState(0);


    useEffect(()=>{
        totalSet(purify(removalCost)+purify(transportCost)+purify(reburnial)+purify(religiousBeliefCost)+purify(condolences))
    },[removalCost,transportCost,reburnial,religiousBeliefCost,condolences])
    const saveGrave =()=>{
       const data={valuationGraveId:null,deceasedName,xCoordinate,yCoordinate,details,removalCost,reburnial,transportCost,religiousBeliefCost,condolences,total}
        dispatch({type:"grave",payload:data})
        PopDetailsTwo.dismiss()
    }

    return(
        <>
            <table style={styles.table}>
                <tbody>
                    <tr>
                        <td>Deceased Name</td>
                        <td>Details</td>
                    </tr>
                    <tr>
                        <td><input type={"text"} name={"deceasedName"}  style={styles.table} value={deceasedName} onChange={(e)=>deceasedNamSet(e.target.value)}/></td>
                        <td><input type={"text"} name={"details"} style={styles.table} value={details} onChange={(e)=>detailsSet(e.target.value)}/></td>
                    </tr>
                    <tr>
                        <td>X Coordinate</td>
                        <td>Y Coordinate</td>
                    </tr>
                    <tr>
                        <td><input type={"number"} placeholder={"Enter Grave's Latitude"} name={"xCoordinate"} style={styles.table} value={xCoordinate} onChange={e=>xCoordinateSet(parseFloat(e.target.value))}/></td>
                        <td><input type={"number"} placeholder={"Enter Grave's Longitude"} name={"yCoordinate"} style={styles.table} value={yCoordinate} onChange={e=>yCoordinateSet(parseFloat(e.target.value))}/></td>
                    </tr>
                    <tr>
                        <td>Removal Cost</td>
                        <td>Transport Cost</td>
                    </tr>
                    <tr>
                        <td><input type={"number"} name={"removalCost"} style={styles.table} value={removalCost} onChange={e=>removalCostSet(parseFloat(e.target.value))}/></td>
                        <td><input type={"number"} name={"transportCost"} style={styles.table} value={transportCost} onChange={e=>transportCostSet(parseFloat(e.target.value))}/></td>
                    </tr>
                    <tr>
                        <td>Reburnial</td>
                        <td>Religious Belief Cost</td>
                    </tr>
                    <tr>
                        <td><input type={"number"} name={"reburnial"} style={styles.table} value={reburnial} onChange={e=>reburnialSet(parseFloat(e.target.value))}/></td>
                        <td><input type={"number"} name={"religiousBeliefCost"} style={styles.table} value={religiousBeliefCost} onChange={e=>religiousBeliefCostSet(parseFloat(e.target.value))}/></td>
                    </tr>
                    <tr>
                        <td>Condolences</td>
                        <td>Total</td>
                    </tr>
                    <tr>
                        <td><input type={"number"} name={"condolences"} style={styles.table} value={condolences} onChange={e=>condolencesSet(parseFloat(e.target.value))}/></td>
                        <td><input type={"number"} name={"total"} style={styles.table} value={total} onChange={e=>totalSet(parseFloat(e.target.value))} readOnly={true}/></td>
                    </tr>
                <tr>
                    <td colSpan={2}>
                        <button style={{float:'right'}} className={"default"} onClick={()=>PopDetailsTwo.dismiss()}>Cancel</button>
                        <button style={{float:'right'}} onClick={saveGrave}>Save</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )
}
const Graves =()=>{
    const state = useBuildingState()
    const dispatch = useBuildingDispatch()
    const[selected,selectedSet] = useState(-1)
    const{valuationGraves: graves}=state


    return(
        <div style={{margin:0, padding:'.5rem'}}>
            <Header heading={false} display={true} label={"Graves"}>
                <div>
                    <i
                        className={'fa fa-plus-circle'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetailsTwo.show("New Grave",<Grave/>,50)}}
                    /><strong> New</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-edit'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{PopDetailsTwo.show("Edit Grave",<Grave/>,50)}}
                    /><strong> Edit</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{}}
                    /><strong> Remove</strong>
                </div>
            </Header>
            <table className={"tb"} style={styles.table}>
                <thead>
                    <tr>
                        <th>DECEASED NAME</th>
                        <th>DETAILS</th>
                        <th>X COORDINATE</th>
                        <th>Y COORDINATE</th>
                        <th>REMOVAL</th>
                        <th>TRANSPORTATION</th>
                        <th>REBURNIAL</th>
                        <th>CONDOLENCES</th>
                        <th>RELIGIOUS BELIEF</th>
                        <th>TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                {
                    graves.map(({deceasedName,details,xCoordinate,yCoordinate,removalCost,transportCost,reburnial,religiousBeliefCost,condolences,total},i)=>
                        <tr key={i} onClick={()=>selectedSet(i)}>
                            <td>{deceasedName}</td>
                            <td>{details}</td>
                            <td>{xCoordinate}</td>
                            <td>{yCoordinate}</td>
                            <td>{removalCost}</td>
                            <td>{transportCost}</td>
                            <td>{reburnial}</td>
                            <td>{religiousBeliefCost}</td>
                            <td>{condolences}</td>
                            <td>{total}</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    )
}

export default function CompensationAllowance() {
    const[currentMenu,currentMenuSet] = useState(0)
    const state = useBuildingState()
    const dispatch = useBuildingDispatch()
    const{
        unexhaustedImprovementsAmount,
        netProfit,
        profitMonths,
        otherValues,
        notesAllowance,
        unexhaustedImprovementsType,
        lostOfProfit,
        totalAllowance,
        noOfRooms,
        months,
        transportationAllowanceAmount,
        totalCropsValue,
        accommodationAllowance,
        disturbancePercentage,
        totalGraveCosts,
        disturbanceAllowance,
        totalCompensation,
        marketRentalPerMonth,
        landValue,
        totalDepreciatedReplacementCost,
        totalCurrentMarketValue,
        valuationGraves:graves,
        valuationCrops:crops
    }= state

    const handleChange=(e)=>{
        const { name, value } = e.target;
        dispatch({ type: name, payload:value });
        console.log("state", state)
    }
    const handleChangeNumber=(e)=>{
        const { name, value } = e.target;
        dispatch({ type: name, payload:parseFloat(value) });
        console.log(name,state[name])
    }
    useEffect(()=>{
        let accommodationAllowances =purify(noOfRooms)*purify(marketRentalPerMonth)*purify(months)
        dispatch({type:"accommodationAllowance",payload:accommodationAllowances})
    },[noOfRooms,marketRentalPerMonth,months])
    useEffect(()=>{
        let lostProfit = purify(netProfit)*purify(profitMonths)
        dispatch({type:"lostOfProfit",payload:lostProfit})
    },[netProfit,profitMonths])
    useEffect(()=>{
        let disturbances = (purify(landValue)+purify(totalDepreciatedReplacementCost)+purify(totalCropsValue))*purify(disturbancePercentage)*0.01
        dispatch({type:"disturbanceAllowance",payload:disturbances})
    },[totalCropsValue,disturbancePercentage,landValue,totalDepreciatedReplacementCost])
    useEffect(()=>{
        let allowances = purify(accommodationAllowance)+purify(disturbanceAllowance)+purify(lostOfProfit)+purify(transportationAllowanceAmount)
        dispatch({type:"totalAllowance",payload:allowances})
    },[accommodationAllowance,disturbanceAllowance,lostOfProfit,transportationAllowanceAmount])
    useEffect(()=>{
        let totalCompensations =purify(totalCurrentMarketValue)+purify(totalCropsValue)+purify(totalAllowance)+purify(otherValues)+purify(totalGraveCosts)
        dispatch({type:"totalCompensation",payload:totalCompensations})
    },[totalCurrentMarketValue,totalCropsValue,totalAllowance,otherValues,totalGraveCosts])
    useEffect(()=>{
        //total
        let sum = 0
        graves.forEach(({total})=>{
            sum+=total
        })
        dispatch({type:"totalGraveCosts",payload:sum})
    },[graves])
    useEffect(()=>{
        console.log("crops",crops)
        //totalCropsValue
        let sum =0
        crops.forEach(({totalCropsValue})=>{
            sum +=totalCropsValue
        })
        dispatch({type:"totalCropsValue",payload:sum})
    },[crops])
    return(
        <div style={{}}>
            <table style={styles.table}>
                <tbody>
                    <tr>
                        <td>Unexhauseted Improvements Amount</td>
                        <td>Net Profit</td>
                        <td>Profit Months</td>
                        <td>Other Values</td>
                        <td>Notes</td>
                    </tr>
                <tr>
                    <td><input type={"number"} style={styles.table} name={"unexhaustedImprovementsAmount"} defaultValue={purify(unexhaustedImprovementsAmount)} onChange={handleChangeNumber}/></td>
                    <td><input type={"number"} style={styles.table} name={"netProfit"} defaultValue={purify(netProfit)} onChange={handleChangeNumber}/></td>
                    <td><input type={"number"} style={styles.table} name={"profitMonths"} defaultValue={purify(profitMonths)} onChange={handleChangeNumber}/></td>
                    <td><input type={"number"} style={styles.table} name={"otherValues"} defaultValue={purify(otherValues)} onChange={handleChangeNumber}/></td>
                    <td rowSpan={9}><textarea rows={9} style={{...styles.table, ...styles.txArea}} name={"notesAllowance"} defaultValue={notesAllowance} onChange={handleChange}/></td>
                </tr>
                <tr>
                    <td>Unexhausted Improvements Type</td>
                    <td>Lost of Profit</td>
                    <td>Total Allowance</td>
                    <td>No of Rooms</td>
                </tr>
                <tr>
                    <td><input type={"text"} style={styles.table} name={"unexhaustedImprovementsType"} defaultValue={unexhaustedImprovementsType} onChange={handleChange}/></td>
                    <td><input type={"number"} style={styles.table} name={"lostOfProfit"} defaultValue={purify(lostOfProfit)} onChange={handleChangeNumber}/></td>
                    <td><input type={"number"} style={styles.table} name={"totalAllowance"} value={purify(totalAllowance)} onChange={handleChangeNumber} readOnly={true}/></td>
                    <td><input type={"number"} name={"noOfRooms"} defaultValue={purify(noOfRooms)} onChange={handleChangeNumber}/></td>
                </tr>
                <tr>
                    <td>Months</td>
                    <td>Transportation Allowance Amount</td>
                    <td>Total Crops Value</td>
                    <td>Accommodation Allowance</td>
                </tr>
                <tr>
                    <td><input type={"number"} style={styles.table} name={"months"} defaultValue={purify(months)} onChange={handleChangeNumber}/></td>
                    <td><input type={"number"} style={styles.table} name={"transportationAllowanceAmount"} defaultValue={purify(transportationAllowanceAmount)} onChange={handleChangeNumber}/></td>
                    <td><input type={"number"} style={styles.table} name={"totalCropsValue"} value={purify(totalCropsValue)} onChange={handleChangeNumber} readOnly={true}/></td>
                    <td><input type={"number"} style={styles.table} name={"accommodationAllowance"} value={purify(accommodationAllowance)} onChange={handleChangeNumber} readOnly={true}/></td>
                </tr>
                <tr>
                    <td>Disturbance, %</td>
                    <td>Total Grave Value</td>
                    <td>Disturbance Allowance</td>
                    <td>Total Compensation Value</td>
                </tr>
                <tr>
                    <td><input type={"number"} style={styles.table} name={"disturbancePercentage"} defaultValue={purify(disturbancePercentage)} onChange={handleChangeNumber}/></td>
                    <td><input type={"number"} style={styles.table} name={"totalGraveCosts"} value={purify(totalGraveCosts)} onChange={handleChangeNumber} readOnly={true}/></td>
                    <td><input type={"number"} style={styles.table} name={"disturbanceAllowance"} defaultValue={purify(disturbanceAllowance)} onChange={handleChangeNumber} readOnly={true}/></td>
                    <td><input type={"number"} style={styles.table} name={"totalCompensation"} value={purify(totalCompensation)} onChange={handleChangeNumber} readOnly={true}/></td>
                </tr>
                <tr>
                    <td>Market Rental per Month</td>
                </tr>
                <tr>
                    <td><input type={"number"} style={styles.table} name={"marketRentalPerMonth"} defaultValue={marketRentalPerMonth} onChange={handleChangeNumber}/></td>
                </tr>
                </tbody>
            </table>
            <div className={"row"} style={styles.detailsHeader}>
                <label style={currentMenu === 0?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(0)}}>Crops</label>
                <label style={currentMenu === 1?styles.headerLabelsHighlight:styles.headerLabels} onClick={()=>{currentMenuSet(1)}}>Graves</label>
            </div>
            <div style={currentMenu === 0?styles.show:styles.hide}>
                <Crops/>
            </div>
            <div style={currentMenu === 1?styles.show:styles.hide}>
                <Graves/>
            </div>
        </div>
    )

}