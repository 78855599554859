import React from "react";
import MobileCreateService from "../../../../../services/mobile/mobile_create";
import PopDetail from "../../../popdetail";
import MobileDictionaryPane from "./mobile_dictionary";
export default class AddDictionaryPane extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            parent:0
        }
        this.handleChange = this.handleChange.bind(this)
        this.submit = this.submit.bind(this)

    }

    handleChange= e =>{ this.setState({[e.target.name]: e.target.value}); }

    submit(){
        try {
            let category = this.state.category
            let subCategory = this.state.sub_category
            let name = this.state.name
            let parentId = this.state.parent
            let description = this.state.description

            let param = {
                category: category,
                subCategory: subCategory,
                parentId: parentId,
                name: name,
                description: description
            }

            MobileCreateService.createTranslate(param).then (r =>{
                try {
                    if (r.data.status===1){
                        PopDetail.dismiss()
                        MobileDictionaryPane.loadData()
                    }else{
                        console.log(r.data)
                    }
                }catch (e) {
                    console.log(r)
                }
            })


        }catch (e) {
            console.log(e)
        }

    }

    render() {
        return(<div className={'col col-sm-12'}>
            <table style={{width:'100%'}}>
                <tbody>
                <tr>
                    <td style={{width:'50%'}}>Category</td>
                    <td style={{width:'50%'}}>Sub Category</td>
                </tr>
                <tr>
                    <td>
                        <input style={{width:'100%'}} name={'category'} onChange={this.handleChange}/>
                    </td>
                    <td>
                        <input style={{width:'100%'}} name={'sub_category'} onChange={this.handleChange}/>
                    </td>
                </tr>
                <tr>
                    <td style={{width:'50%'}}>Name</td>
                    <td style={{width:'50%'}}>Parent ID</td>
                </tr>
                <tr>
                    <td>
                        <input style={{width:'100%'}} name={'name'} onChange={this.handleChange}/>
                    </td>
                    <td>
                        <input style={{width:'100%'}} type={'number'} defaultValue={0} name={'parent'} onChange={this.handleChange}/>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>Description</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <textarea rows={5} style={{width:'100%'}} name={'description'} onChange={this.handleChange}/>
                    </td>
                </tr>

                <tr>
                    <td colSpan={2}>
                        <button onClick={this.submit}>Submit</button>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>)
    }
}