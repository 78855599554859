import * as React from "react";

import PopDetail from "../../popdetail";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../mobile_popup";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../resource/img/checkbox/checkbox_checked_16.png";
import Wait from "../../wait";
import MobileDeleteService from "../../../../services/mobile/mobile_delete";

export default class MobileLocalityPane extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            question_config:[],
            question_categ:[],
            boundaries:[],
            current_tab_id:0,
            tab:0
        }

        this.loadProjectLayers = this.loadProjectLayers.bind(this)
        this.loadData = this.loadData.bind(this)
        this.deleteHandler = this.deleteHandler.bind(this)
    }

    componentDidMount() {
        this.loadProjectLayers()
        //this.loadData();
    }

    saveQuest(){
        let categ = document.getElementById('sp_category').value
        let quest = document.getElementById('txt_quest').value
        let answer = document.getElementById('sp_answer').value
        let req = document.getElementById('req').checked
        let opt = document.getElementById('txt_opt').value

        try {
            MobileCreateService.configQuest({
                category:MobilePopup.outputId(),
                sub_category: categ,//this.state.current_tab_id,
                question: quest,
                answer_format: answer,
                answer_options:opt,
                compulsory: req
            }).then (r =>{
                try {
                    document.getElementById('txt_quest').value = ""
                    document.getElementById('sp_answer').value = ""
                    document.getElementById('req').checked = false
                    document.getElementById('txt_opt').value =""
                    document.getElementById('txt_opt').style.display="none"

                    this.toggleFilters()
                    this.loadProjectLayers()
                    this.loadData(this.state.current_tab_id)
                }catch (e) {
                    console.log(e)
                }
            })


        }catch (e) {
            console.log(e)
        }

    }


    toggleFilters(){
        let div = document.getElementById('div-filter')
        div.style.display = div.style.display === 'none'?'inherit':'none'
    }

    toggleCheck(i){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            return;
        }
        doc.src=uncheck;
    }

    setTabAt(tab,current_tab_id) {

        setTimeout(()=>{
            this.setState({tab: tab,current_tab_id:current_tab_id})
        },300)
        this.loadData(current_tab_id)
    }

    render() {
        return(<div style={{overflow:'hidden',height:'100%',marginRight:'16px',background:'#fff'}}>

            <div className={'row'} style={{boxShadow:'0 4px 4px #aaa',padding:'8px 16px',backgroundColor:'#efefef'}}>
                <div className={'col col-sm-8'}>
                    <span className={'fa fa fa-bars'} style={{cursor:'pointer', marginTop:'8px'}} onClick={()=>this.toggleFilters()}/>
                    <input style={{width:'85%',marginLeft:'24px',backgroundColor:'#fff'}} placeholder={'Search here...'}/>
                    <span className={'fa fa fa-plus-circle'} style={{color:'#008b8b', float:'right',cursor:'pointer', margin:'8px'}} onClick={()=>this.toggleFilters()}/>
                </div>
                <div className={'col col-sm-4 fa-btn'}>
                    {/* <button style={{float:'right',width:'80px'}} onClick={()=>TransactionPopup.dismiss()}>Close</button>
                    <button style={{float:'right',width:'80px'}} onClick={()=>this.loadData(0)} className={'refresh'}>Refresh</button>
                    <span className={'fa fa-check'} title={'Approve'} style={{color:'#008b8b', marginRight:'24px'}}/>*/}
                </div>

            </div>

            <div className={'col col-sm-9'} style={{border:'0.5px solid #ccc',display:'none'}} id={'div-filter'}>
                <br/>
                <table style={{width:'100%'}}>
                    <tbody>
                    <tr>
                        <td style={{verticalAlign:'top'}}><label>Category : </label></td>
                        <td>
                            <select id={'sp_category'} className={'select'} onChange={this.checkAnswer} defaultValue={this.state.current_tab_id}>
                                <option value={''}>- Select One -</option>
                                {this.state.question_categ.map((d,i)=>{
                                    return(<option key={i} value={d.id}>{d.name}</option>)
                                })}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign:'top'}}><label>Question : </label></td>
                        <td><textarea id={'txt_quest'} style={{width:'100%'}}/></td>
                    </tr>
                    <tr>
                        <td><label>Answer Format : </label></td>
                        <td>
                            <select id={'sp_answer'} className={'select'} onChange={this.checkAnswer}>
                                <option value={''}>- Select One -</option>
                                <option value={'text'}>Text</option>
                                <option value={'number'}>Number</option>
                                <option value={'date'}>Date</option>
                                <option value={'bool'}>True/False</option>
                                <option value={'select'}>DropDown</option>
                                <option value={'checkbox'}>CheckBox</option>
                                <option value={'radio'}>Radio Button</option>
                                <option value={'image'}>Image</option>
                                <option value={'scan'}>Scanned Document</option>
                            </select>
                        </td>
                    </tr>
                    <tr style={{display:'none'}} id={'tr_opt'}>
                        <td style={{verticalAlign:'top'}}><label>Options : </label></td>
                        <td>
                            <textarea id={'txt_opt'} style={{width:'100%'}} rows={5}/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{verticalAlign:'top'}}><label>Is Compulsory : </label></td>
                        <td>
                            <input id={'req'} type={'radio'} name={'required'} style={{marginRight:'8px'}}/><label>Yes</label><br/>
                            <input type={'radio'} name={'required'} style={{marginRight:'8px'}}/><label>No</label>
                        </td>
                    </tr>

                    </tbody>
                </table>
                <hr/>


                <div style={{textAlign:'center', padding:'8px 0 16px 0'}}>
                    <button className={'default'} style={{width:'100px'}} onClick={()=>this.saveQuest()}>Submit</button>
                    <button className={'default'} style={{width:'100px'}} onClick={()=>this.toggleFilters()}>Close</button>
                </div>

            </div>

            <div className={'row'} style={{marginTop:'1vh'}}>
                <div className={'col col-sm-12'}>

                    {this.state.boundaries.map((d,i)=>{
                        return(<div key={d.id}  className={this.state.tab===i?'tab tab-active':'tab'} style={{width:'200px'}} onClick={()=>this.setTabAt(i,d.id)} title={d.label}>
                            <span className={'fa fa-map'}/> {d.label}
                        </div>)
                    })}

                </div>
            </div>

            <div style={{border:'1px solid #ccc',width:'100%',height:'70vh', overflow:'auto'}}>
                <table className={'tb'} style={{width:'98%',margin:'1%'}}>
                    <thead>
                    <tr>
                        <th style={{textAlign:'left',paddingLeft:'8px'}}>
                            <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                        </th>
                        <th>LOCALITY CODE</th>
                        <th>LOCALITY NAME</th>
                        <th>DESCRIPTION</th>
                        <th style={{textAlign:'right'}}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.question_config.map((d,i)=>{
                        return(<tr key={i} style={{verticalAlign:'top'}}>
                            <td>
                                <img id={'chk_'+i} alt={'chk_'+i}  style={{float:'left'}}
                                     src={uncheck} onClick={()=>this.toggleCheck(i)}/>
                                <span style={{float:'right'}}>
                                {i+1}.
                            </span>
                            </td>
                            <td>{d.category}</td>
                            <td>{d.question}</td>
                            <td>{d.answer}</td>

                            <td style={{textAlign:'right'}}>
                                <button style={{width:'50px'}} className={'default'}>Edit</button>
                                <button style={{width:'50px'}} className={'default'} onClick={()=>this.delete(d)}>Delete</button>
                            </td>
                        </tr>)
                    })}


                    </tbody>
                </table>
            </div>

        </div>)
    }

    loadData(current_tab_id=0){

        try {

            Wait.show("Loading....")
            MobileReadService.readQuestinnares(MobilePopup.outputId(),current_tab_id).then(result=>{
                try {
                    Wait.dismiss()
                    this.setState({
                        question_config:result.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    noHandler(){
        PopDetail.dismiss()
    }

    delete(question){
        PopDetail.confirm(question.question,'Confirm Delete '+question.question +' ?',this.deleteHandler,question.id,this.noHandler)
    }

    deleteHandler(qid){
        try {
            Wait.show('Removing...')
            MobileDeleteService.deleteQuestionnaire(qid).then(r => {
                console.log(r)
                Wait.dismiss()
                PopDetail.dismiss()
                this.loadProjectLayers()
                this.loadData()
            })
        }catch (e) {
            console.log(e)
        }
    }



    loadProjectLayers() {
        try {

            MobileReadService.mobileLayers(MobilePopup.id()).then(result=>{
                try {
                    console.log(result.data.results)

                    this.setState({
                        boundaries:result.data.results
                    })
                    /*let index = 0
                    let i = 0
                    result.data.results.forEach(d=>{
                        if (index===0){
                            if (d.questions.length>0){
                                index = i
                            }
                        }
                        i++
                    })
                    this.setTabAt(0,result.data.results[index].id)*/
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    checkAnswer(e) {
        switch (e.target.value) {
            case 'select':
            case 'checkbox':
            case 'radio':
                document.getElementById('tr_opt').style.display='table-row'
                break
            default:
                document.getElementById('tr_opt').style.display='none'
                break
        }



    }
}