import * as React from "react";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../mobile_popup";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import MobileSummaryPane from "../screens/mobile_summary";

export default class PaneAddSignatory extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            councils:[],
            signatory:[],
            reference:'council',
            wards:[]
        }

        this.handleChange = this.handleChange.bind(this)
        this.savePerson = this.savePerson.bind(this)
        this.loadLocality = this.loadLocality.bind(this)
        this.loadWards = this.loadWards.bind(this)
        this.handleChangeFile = this.handleChangeFile.bind(this)
    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
        if (e.target.name==='design'){

            document.getElementById('tr_ward0').style.display='none'
            document.getElementById('tr_ward1').style.display='none'
            this.setState({reference:'council'})

            if (MobilePopup.project().outputName==="CCRO"){

                if (!(e.target.value).endsWith('_ALO')){
                    document.getElementById('tr_ward0').style.display='table-row'
                    document.getElementById('tr_ward1').style.display='table-row'
                    this.setState({reference:'ward_village'})
                }
            }



            /*if (arr.includes(e.target.value)){
                document.getElementById('tr_ward0').style.display='none'
                document.getElementById('tr_ward1').style.display='none'
                this.setState({reference:'council'})
            }else{
                document.getElementById('tr_ward0').style.display='table-row'
                document.getElementById('tr_ward1').style.display='table-row'
                this.setState({reference:'ward_village'})
            }*/
        }


        if (e.target.name==='council'){
            this.loadWards()
        }
    }
    handleChangeFile=e=>{this.setState({ [e.target.name]:e.target.files[0] })}

    componentDidMount() {this.loadLocality()}

    loadWards(){
        try {
            setTimeout(()=>{
                MobileReadService.listWards(this.state.council,MobilePopup.id()).then(r=>{
                    try {
                        this.setState({
                            wards:r.data.results
                        })
                    }catch (e) {
                        console.log(e)
                    }
                })
            },500)
        }catch (e) {
            console.log(e)
        }
    }

    loadLocality() {
        try {
            Wait.show("Loading Localities...")
            MobileReadService.listLocality(MobilePopup.id()).then(r => {
                Wait.dismiss()
                try {
                    this.setState({
                        councils:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }

        try {
            Wait.show("Loading Localities...")
            MobileReadService.listSignatories(MobilePopup.id()).then(r => {
                Wait.dismiss()
                try {
                    this.setState({
                        signatory:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    render() {
        return(<div style={{height:'100%',overflow:'auto'}} className={'col col-12'}>
            <br/>
            <div className={'row'}>
                <div className={'col col-sm-12'}>
                    <table style={{width:'100%'}}>
                        <tbody>
                        <tr>
                            <td><span>Full Name</span></td>
                            <td><span>Phone Number</span></td>
                            <td><span>Designation</span></td>
                        </tr>
                        <tr>
                            <td><input style={{width:'100%'}} name={'fullname'} onChange={this.handleChange}/></td>
                            <td><input style={{width:'100%'}} name={'phone'}  onChange={this.handleChange}/></td>
                            <td>
                                <select style={{width:'100%'}} name={"design"} onChange={(e)=>this.handleChange(e)}>
                                    <option value={''}>- Select One -</option>
                                    {this.state.signatory.map((d,i)=>{
                                        return(<option key={i} value={d.id+'_'+d.design}>{d.name} - {d.design}</option>)
                                    })}
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={3}><span>District Council</span></td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <select id={'council'} style={{width:'100%'}} name={"council"} onChange={(e)=>this.handleChange(e)}>
                                    <option value={0}>- Select One -</option>
                                    {this.state.councils.map((d,i)=>{
                                        return(<option key={i} value={d.id}>{d.name} - {d.id}</option>)
                                    })}
                                </select>
                            </td>
                        </tr>
                        <tr id={'tr_ward0'} style={{display:'none'}}>
                            <td colSpan={3}><span>Ward / Village</span></td>
                        </tr>
                        <tr id={'tr_ward1'} style={{display:'none'}}>
                            <td colSpan={3}>
                                <select style={{width:'100%'}} name={"ward"} onChange={(e)=>this.handleChange(e)}>
                                    <option value={0}>- Select One -</option>
                                    {this.state.wards.map((d,i)=>{
                                        return(<option key={i} value={d.id}>{d.name}</option>)
                                    })}
                                </select>
                            </td>

                        </tr>
                        <tr>
                            <td colSpan={3}><span>Signature</span></td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <input type={'file'} style={{width:'100%'}} accept={".png,.PNG"}
                                       name={'sign'} onChange={this.handleChangeFile}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.savePerson}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>
        </div>)
    }



    savePerson(){

        try {

            let design = this.state.design
            let council = parseInt(this.state.council)
            let ward = parseInt(this.state.ward)
            let name = this.state.fullname

            if (design===''||design===undefined||design===null){
                alert('Invalid Designation')
                return
            }

            if (council<1){
                alert('Invalid District Council')
                return
            }

            if (this.state.reference==='ward_village'){
                if (ward<1 || isNaN(ward)){
                    alert('Invalid Ward/Village')
                    return
                }

                council = ward
            }

            if (this.state.sign===''||this.state.sign===undefined||this.state.sign===null){
                alert('Invalid Signature')
                return
            }

            const formData = new FormData()
            formData.append('sign',this.state.sign)
            formData.append('extra',JSON.stringify({
                fullname:name,
                phone:this.state.phone,
                design:design.split('_')[0],
                locality:council,
                project:MobilePopup.id(),
                reference:this.state.reference
            }))

            Wait.show("Saving...")
            MobileCreateService.addSignatory(formData).then(d=>{
                Wait.dismiss()
                try {
                    console.log(d)
                    MobileSummaryPane.loadprojectList()
                    let data = d.data
                    //console.log(d.data)
                    if(data.status === 0){
                        alert(data.message)
                    }
                    PopDetail.dismiss()
                    MobileSummaryPane.loadprojectList()
                }catch (e) {
                    console.log(e)
                }
                //
            })
        }catch (e){
            console.log(e)
        }
    }
}