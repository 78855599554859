import React from 'react';
import './App.css';
import Wait from "./apps/components/popups/wait";

import logo from './resource/img/nembo.png';
import Dashboard from "./apps/components/containers/dashboard";
import API from "./apps/services/API";
import Common from "./apps/utils/common";
import AdminLayout from "./apps/components/containers/admin_layout";
import PopDetail from "./apps/components/popups/popdetail";
import PopDetailsTwo from "./apps/components/popups/PopDetailsTwo";
import { BuildingProvider, ValuationProvider } from "./apps/componentss/valuation-tabs/store";
import IdleTimer from "./IdleTimer";
import { PasswordReset, PasswordResetRequest } from "./apps/components/popups/uma/password-reset";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { loadProgressBar } from "axios-progress-bar";

export default class App extends React.Component {


    componentDidMount() {


        loadProgressBar()

        const fpPromise = FingerprintJS.load()
            ; (async () => {
                const fp = await fpPromise
                const result = await fp.get()
                Common.xAuth = result.visitorId;
                sessionStorage.setItem("x-auth", result.visitorId)
            })()

        if (sessionStorage.getItem("token")) {
            const timer = new IdleTimer({
                timeout: 6000 * 5, //expire after 300 seconds
                onTimeout: () => {
                    this.setState({ isTimeout: true })
                    Dashboard.logout();
                },
                onExpired: () => {
                    //do something if expired on load
                    this.setState({ isTimeout: true });
                    Dashboard.logout();
                }
            });
            return () => {
                timer.cleanUp();
            };
        }
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });

    }

    constructor(props) {
        super(props);
        this.state = {
            isTimeout: false,
            display_pane: 'Loading...',
            error_message: '',
            numPages: null,
            pageNumber: 1,
            tr_password: 'table-row',
            is_reset: false,
            login: 'Login'
        }
        this.loginUser = this.loginUser.bind(this);
        this.showForgetPassword = this.showForgetPassword.bind(this)
    }

    loginUser(e) {
        e.preventDefault();
        let self = this;

        let username = this.username.value.trim();
        let password = this.password.value.trim();

        sessionStorage.setItem("Action", "")
        if (username.length < 1) {
            self.setState({ error_message: 'Username is Required' })
            return;
        }

        if (this.state.is_reset === false) {
            if (password.length < 1) {
                self.setState({ error_message: 'Password is Required' })
                return;
            }
            // PopDetail.show("Password Reset",<PasswordReset user={username}/>,65)
            try {

                Wait.show("Logging in...")
                self.setState({ error_message: '' })


                if (username === sessionStorage.getItem('loginname')) {


                } else {

                    sessionStorage.setItem('loginname', username)

                }

                API.login(username, password, Common.xAuth).then((d => {
                    try {
                        if (d.data.status === 1) {
                            sessionStorage.setItem('username', username);
                            sessionStorage.setItem('token', 'Mlhhsd ' + d.data.token);
                            sessionStorage.setItem('department', d.data.department);
                            sessionStorage.setItem("login", "true")
                            Common.token = sessionStorage.getItem('token')

                            window.location.reload();

                        }
                        else if (d.data.status === 2) {
                            Wait.dismiss()
                            PopDetail.show("Password Reset", <PasswordReset user={username} />, 65)
                        }
                        else {
                            console.log(e)
                            if (username === sessionStorage.getItem('loginname')) {

                                self.setState({ error_message: d.data.message })
                                Wait.dismiss();
                            }
                        }
                    } catch (e) {
                        console.log(e)

                        if (username === sessionStorage.getItem('loginname')) {
                            self.setState({ error_message: 'Invalid Login, Try again' })
                            Wait.dismiss();
                        }
                        self.setState({ error_message: 'Invalid Login, Try again' })
                        Wait.dismiss();
                    }

                }));

            } catch (e) {
                console.log(e);
            }
        } else {
            sessionStorage.setItem('username', username);
            PopDetail.show('Reset Password', <PasswordResetRequest user={username} />, 60)
        }

    }

    showForgetPassword() {
        this.setState({
            tr_password: 'none',
            login: 'Reset Password',
            is_reset: true
        })
    }
    render() {
        console.log("TimeOunt", this.state.isTimeout)
        return (

            /*<div>{this.state.isTimeout ? "Timeout" : "Active"}</div>*/
            <ValuationProvider>
                <BuildingProvider>
                    <div>
                        {
                            sessionStorage.getItem("login") === null ? <div className={'login-pane'}>
                                <div style={{ width: '98%', textAlign: 'center' }}>

                                    <div style={{ height: '20vh' }} />

                                    <div style={{
                                        textAlign: 'center', padding: '16px 28px', boxShadow: '0 0 10px #aaa',
                                        borderRadius: '4px', display: 'table', margin: 'auto', background: '#fff', paddingBottom: '4vh',
                                    }}>
                                        <form onSubmit={(e) => this.loginUser(e)}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: '2vh 0 1vh' }}>
                                                            <img src={logo} style={{ height: '10vh' }} alt={'Logo'} />

                                                            <p style={{ color: '#000' }}><br />
                                                                The Ministry of Lands,<br />Housing and Human Settlements Developments
                                                            </p>
                                                            <h5>
                                                                <b>Tanzania ILMIS</b>
                                                            </h5>
                                                            <p style={{ color: '#DF4A36' }}>{this.state.error_message}</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type={'text'} className={'input'} style={{ width: '80%', textAlign: 'center' }}
                                                                placeholder={'Username'} name={'username'} ref={(c) => this.username = c} autoComplete={'off'} />
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: '4px' }} />
                                                    <tr id={'tr_password'} style={{ display: this.state.tr_password }}>
                                                        <td>
                                                            <input type={'password'} className={'input'} style={{ width: '80%', textAlign: 'center' }}
                                                                placeholder={'Password'} name={'password'} ref={(c) => this.password = c} autoComplete={'off'} />
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: '4px' }} />
                                                    <tr>
                                                        <td >
                                                            <input id={'btn_login'} className={'btn'}
                                                                style={{ padding: '6px', width: '50%' }} type={'submit'} value={this.state.login} />
                                                        </td>
                                                    </tr>
                                                    <tr style={{ display: this.state.tr_password }}>
                                                        <td style={{ fontSize: '12px', paddingTop: '1vh', cursor: 'pointer', color: 'darkcyan' }}>
                                                            <span onClick={() => this.showForgetPassword()}>Forget Password</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>
                                </div>
                                <Wait />
                            </div> :
                                <div>
                                    {/*Layouts*/}
                                    <Dashboard />
                                    <AdminLayout />

                                    {/*POPS*/}
                                    <PopDetail />
                                    <PopDetailsTwo />
                                    <Wait />

                                </div>
                        }
                    </div>
                </BuildingProvider>
            </ValuationProvider>
        )
    }

}
