import React, {useCallback,useEffect,useState} from 'react';
import {styles} from "../record-request";
import Header from "../gro/header";
import {useForm} from "react-hook-form";
import API from "../../../../../services/API";
import PopDetail from "../../../popdetail";
import TransactionPopup from "../../transaction_popup";
import NewPhase from "./newPhase";
import EditPhase from "./editPhase";
import UnitsAdd from "./unitsAdd";


export default function UnitPlan () {

    const{register,handleSubmit}=useForm()
    const[checked,setChecked] = useState(false)
    const[plotList,setPlotList] = useState([])
    const[unitPlan,setUnitPlan] = useState({
        planId: "",
        planNameNumber: "",
        noOfPhases: "",
        noOfUnits: "",
        plot: "",
        unitplanId: ""
    })
    const[phaseId,setPhaseId]= useState("")
    const[phases,setPhases] = useState([]);
    const [ready,setReady] = useState(false)
    const [handleDeleteChange,setHandleDeleteChange] = useState(0)
    const [unitPlanId, setUnitPlanId] = useState("")
    const [unitPlanRefresh,setUnitPlanRefresh] = useState(0)

    const submitUnitPlan = data => {

        // checked === false ? un
        let unitTotalNo;
        let approxTotal;
        let numberOfPhases;

        // eslint-disable-next-line no-unused-expressions
        checked === true || data.totalNumberOfUnit === "" ? unitTotalNo = 0 : unitTotalNo = data.totalNumberOfUnit;
        // eslint-disable-next-line no-unused-expressions
        checked === true || data.approxTotalValue === "" ? approxTotal = 0.0000 : approxTotal = data.approxTotalValue;
        // eslint-disable-next-line no-unused-expressions
        checked === false ? numberOfPhases = 0 : numberOfPhases = data.numberOfPhases;
       let dataToSend = {
           planNumber: data.planNumber,
           numberOfPhases: numberOfPhases,
           totalNumberOfUnit: unitTotalNo,
           approxTotalValue: approxTotal,
           spatialUnitId: data.spatialUnitId,
           phasedPlan: checked,
           transactionID: TransactionPopup.id()
       }

       console.log("data to send", dataToSend);

       API.ax.post("/rt/create/create-unit-plan",dataToSend).then(d =>{
           console.log(d);
          if (unitPlanRefresh === 0){
              setUnitPlanRefresh(1)
          }else{
              setUnitPlanRefresh(0)
          }
       })
       .catch(e => {
               console.log(e);
           })
       console.log(data + "checked" + checked)

   }

    const checkList = () =>
    {
        console.log("check list sta")
        API.ax.get(`/rt/plot/${TransactionPopup.id()}`).then(d => {
            setPlotList(d.data.result)
            console.log("Plot List"+JSON.stringify(plotList))
        })
    }


   useEffect(() =>{
       loadUnitPlan()
   },[])

    useEffect( () => {
        loadUnitPlan()
    },[unitPlanRefresh])

    const loadUnitPlan = () =>{
        API.ax.get(`/rt/unit-plan/${TransactionPopup.id()}`).then(d => {
            setUnitPlan({
                planId: d.data.planId,
                planNameNumber: d.data.planNameNumber,
                noOfPhases: d.data.noOfPhases,
                noOfUnits: d.data.noOfUnits,
                plot: d.data.plot,
                approxTotalValue:d.data.approxTotalValue,
                unitplanId: d.data.unitplanId,
            })
            setUnitPlanId(d.data.unitplanId)

            d.data.isPhased === true ? setChecked(true) : setChecked(false)
            console.log("unit plan details", d, "checkbox status",  checked)
        })
        TransactionPopup.task() === "Check and Enter Data" ?
            setReady(false) : setReady(true)
    }

    useEffect(() => {
        if (unitPlan.unitplanId !== "") {
            API.ax.get(`/rt/get-phases/${unitPlan.unitplanId}`).then(da => {
                    console.log("list of phases " + JSON.stringify(da.data))
                    if (da.data.status === 1){
                        setPhases(da.data.result)
                        setUnitPlanId(unitPlan.unitplanId)
                    }
                }
            ).catch( e => {
                console.log(e)
            })
        }
        else {
            console.log("State Unit plan not updated yet")
        }
    }, [unitPlan.unitplanId,handleDeleteChange])

    function moneyFormat(price, sign = 'Tshs ') {
        const pieces = parseFloat(price).toFixed(2).split('')
        let ii = pieces.length - 3
        while ((ii-=3) > 0) {
            pieces.splice(ii, 0, ',')
        }
        return sign + pieces.join('')
    }




    return (
            <div>
                <div className={'row text-muted'} >
                    <div className={'col-sm-6'} style={styles.phases}>
                <form onSubmit={handleSubmit(submitUnitPlan)} style={{padding: '0.5rem'}}>
                    <h5>Unit Plan</h5>
                         <div className={'row'}>
                             <label className={'col-sm'}>Plan ID<br/>
                                 <input style={{width:'100%'}} type="text" readOnly={true} defaultValue={unitPlan.planId} name={'planId'} ref={register} />
                             </label>
                             <label className={'ml-2 col-sm'}>* Plan Name/Number<br/>
                                 <input style={{width:'100%' }} type="text" readOnly={ready} defaultValue={unitPlan.planNameNumber} name={'planNumber'} ref={register({required: "true"})} />
                             </label>
                         </div>
                          <div className={'row'}>
                              <label className={'col-sm'}>Phased Plan<br/>
                                  <input
                                      id={'phasedPlan'}
                                      style={{width:'25px', height:'25px'}}
                                      onChange={(e) =>{ e.target.checked?setChecked(true):setChecked(false)}}
                                      checked={checked}
                                      type={'checkbox'}
                                      name={"phasedPlan"}
                                      ref={register}
                                      readOnly={ready}
                                  />
                              </label>
                              <label className={'ml-2 col-sm'}>Number of Phases<br/>
                                  <input style={{width:'100%' }} type="number" readOnly={!checked} defaultValue={unitPlan.noOfPhases} name = {"numberOfPhases"} ref={register} />
                              </label>
                          </div>
                          <div className={'row'}>
                              <label className={'col-sm'}>Total Number of Units<br/>
                                  <input style={{width:'100%' }}  type="number" readOnly={TransactionPopup.task() === "Check and Enter Data" ? checked : ready} defaultValue={unitPlan.noOfUnits} name={"totalNumberOfUnit"} ref={register} />
                              </label>
                              <label className={'ml-2 col-sm'}>Approximate Total Value<br/>
                                  <input style={{width:'100%'}} type="number" readOnly={TransactionPopup.task() === "Check and Enter Data" ? checked : ready}  defaultValue={unitPlan.approxTotalValue} step={"any"} min={0}  name={"approxTotalValue"} ref={register} />
                              </label>
                          </div>
                          <div className={'row'}>
                              <label className={'col-sm-6'}>Plot<br/>
                                  {
                                      TransactionPopup.task() === "Check and Enter Data" ?
                                      <select className={"select"} style={{width: "100%"}} name={"spatialUnitId"} onClick={checkList} ref={register} >
                                          <option  disabled >Select</option>
                                          {plotList.map((d,i)=>{
                                              return(<option key={i} value={d.spatialUnitID}>{d.localityName+"-"+d.blockNumber+"-"+d.lotNumber}</option>)
                                          })}
                                      </select>:
                                      <input style={{width:'100%'}} type="text" defaultValue={unitPlan.plot} step={"any"} readOnly={ready} name={"approxTotalValue"} ref={register} />
                                  }

                              </label>
                          </div>
                    {
                        TransactionPopup.task() === "Check and Enter Data" ?
                            <button type={'submit'} style={{width:'85px',paddingBottom:'.2.5rem'}}>Save</button> : ""
                    }
                </form>
                    </div>
                    <div className={'col-sm-6'}>
                        {console.log("jjjjjjjjjjjjjj" + JSON.stringify(phases))}
                        <div style={styles.phases}>
                            <Header display={true}>
                                <div>
                                    <b>Phases</b>
                                </div>
                                <div style={{display: checked == false ? "none" : "inherit"}}>
                                    <i
                                        className={'fa fa-plus pr-1'}
                                        style={{color:'tomato', fontSize:'25px'}}
                                        onClick={()=>{
                                            let submit;
                                            PopDetail.show("New Phase",<NewPhase unitPlanId = {unitPlan.unitplanId} handleChange = {setPhases}/>,60)
                                        }}
                                    /><strong>New</strong>
                                </div>
                                <div style={{display: checked == false ? "none" : "inherit"}}>
                                    <i
                                        className={'fa fa-pencil pr-1'}
                                        style={{color:'tomato', fontSize:'25px'}}
                                        onClick={()=>{
                                            let submit;
                                            PopDetail.show("Edit Phase",<EditPhase planPhaseId = {phaseId}/>,60)
                                        }}
                                    /><strong>Edit</strong>
                                </div>
                                <div style={{display: checked == false ? "none" : "inherit"}}>
                                    <i
                                        className={'fa fa-trash mr-1'}
                                        style={{color:'tomato', fontSize:'25px'}}
                                        onClick={()=>{
                                            API.ax.delete(`/rt/delete-phase/${phaseId}`).then(d => {
                                                console.log("deleted Phase" + d)
                                                setHandleDeleteChange(handleDeleteChange + 1)
                                            }).catch(e => {
                                                console.log(e)
                                            })
                                        }}
                                    /><strong>Remove</strong>
                                </div>
                            </Header>
                            <div style={styles.content}>
                                <table className={'tb'} style={styles.table}>
                                    <thead>
                                    <tr>
                                        <th>PHASE NUMBER/NAME</th>
                                        <th>TOTAL NUMBER OF UNIT</th>
                                        <th>PHASE VALUE,TSH</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {phases == null ? "" :
                                        phases.map((d,i) => {
                                        return(<tr key={i} onClick={() => {setPhaseId(d.planPhaseId)}}>
                                            <td>{d.phaseNameNumber}</td>
                                            <td>{d.totalUnits}</td>
                                            <td>{moneyFormat(d.phaseValue)}</td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                    <Unit phases = {phases} unitPlanId = {unitPlanId} phaseId = {phaseId} phasedPlan = {checked}/>
            </div>
        );
}


const Unit = (props) => {

    const [unit,setUnit] = React.useState();
    const [unitId, setUnitId] = React.useState();
    const [refreshUnit, setRefreshUnit] = React.useState(0)
    useEffect(() => {
        if ( props.phaseId !== ""){
            API.ax.get(`/rt/get-units/${props.phaseId}`).then(da => {
                if (da.data.status === 1){
                    console.log("list of units " , da.data.result)
                    setUnit(da.data.result)
                }
            })
        }
        else {
            console.log("phase ID is empty")
        }

    }, [props.phaseId])

    useEffect(() => {
        getAllUnitsByUnitPlanId()
    }, [refreshUnit])

    useEffect(() => {
        getAllUnitsByUnitPlanId()
    }, [props.unitPlanId])

    const getAllUnitsByUnitPlanId = () => {
        if ( props.phasedPlan === false){
            API.ax.get(`/rt/get-units-by-planId/${props.unitPlanId}`).then(da => {
                if (da.data.status === 1){
                    console.log("list of units " , da.data.result)
                    setUnit(da.data.result)
                }
            }).catch(e => {
                console.log("unit error by unitPlanId", e)
            })
        }
        else {
            console.log("unit plan is not phased")
        }
    }

    return (
        <div style={styles.main}>
            <Header display={true}>
                <div>
                    <b>Units</b>
                </div>
                <div>
                    <i
                        className={'fa fa-plus pr-1'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{
                            let submit;
                            PopDetail.show("New Phase",<UnitsAdd refreshUnit = {refreshUnit} setRefreshUnit = {setRefreshUnit} phases = {props.phases} unitPlanId = {props.unitPlanId}/>,80)
                        }}
                    /><strong>Add</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash pr-1'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={
                            ()=>{
                                API.ax.delete(`/rt/delete-unit/${unitId}`).then(d => {
                                    console.log("deleted unit" + d.data)
                                }).catch(e => {
                                    console.log(e)
                                })
                            }
                        }
                    /><strong>Remove</strong>
                </div>
                <div>
                    <i
                        className={'fa fa-trash mr-1'}
                        style={{color:'tomato', fontSize:'25px'}}
                        onClick={()=>{
                            let submit;
                            // PopDetail.show("Edit Interest",<OccupancyEditInterest/>,80)
                        }}
                    /><strong>Remove All</strong>
                </div>
            </Header>
            <div style={styles.content}>
                <table className={'tb'} style={styles.table}>
                    <thead>
                    <tr>
                        <th>UNIT NUMBER/NAME</th>
                        <th>UNIT FACTOR</th>
                        <th>PHASE</th>
                        <th>UNIT AREA(SQ.M)</th>
                        <th>FLOOR AREA(SQ.M)</th>
                        <th>UNIT DETAILS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {unit == null ? "" :
                            unit.map((d,i) => {
                                return (
                                    <tr key={i} onClick={() => {setUnitId(d.unitId)}}>
                                        <td>{d.unitNumbername}</td>
                                        <td>{d.unitFactor}</td>
                                        <td>{d.phaseNameNumber}</td>
                                        <td>{d.unitArea}</td>
                                        <td>{d.floorAre}</td>
                                        <td>{d.unitDetails}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
