import React, {Component} from 'react';
import TransactionPopup from "../../transaction_popup";
import TicReadService from "../../../../../services/tic/tic_read";

class ProjectInfo extends Component {
    constructor(props) {
        super(props);
        this.state={
            certificateofincentivesno:"",
            projectname:"",
            subsector:"",
            sector:"",
            economicactivity:""
        }

    }

    componentDidMount() {

        TicReadService.getProjectInfo(TransactionPopup.id()).then(d=>{
            console.log("Project info",d.data)
            if(d.data.status==1){
                this.setState({certificateofincentivesno:d.data.result.certificateofincentivesno,projectname:d.data.result.projectname,subsector:d.data.result.subsector,sector:d.data.result.sector,economicactivity:d.data.result.economicactivity})
            }else {

            }
        })
    }
    render() {
        return (
            <div>
                <table style={{width:'100%'}}>
                    <tbody>
                    <tr style={{color:'#888'}}>
                        <td>Project Name</td>
                        <td>Incentive No</td>
                        <td>Sector</td>
                    </tr>
                    <tr>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.projectname} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.certificateofincentivesno} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.sector} readOnly={true}/></td>
                    </tr>
                    <tr style={{color:'#888'}}>
                        <td>Sub-Sector</td>
                        <td>Investment Value</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.subsector} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.economicactivity} readOnly={true}/></td>
                        <td><input type={'text'} style={{width:'100%'}}  readOnly={true}/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ProjectInfo;