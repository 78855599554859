import * as React from "react";

import PopDetail from "../../popdetail";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../mobile_popup";
import Wait from "../../wait";
import UgSpatial from "../../../maps/ug_spatial";
import API from "../../../../services/API";
import PopParcel from "../pops/pane_pop_parcel";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../resource/img/checkbox/checkbox_checked_16.png";
import * as LF from "leaflet";
import Basemap from "../../../maps/basemap";
import Message from "../../transacrions/screen/gro/person/message";
import PaneUploadDraft from "../pops/pane_upload_draft";
import ReportCreateService from "../../../../services/common/reports/report_create";
import PanAddProjectLayer from "../pops/pane_add_project_layer";

export default class UtambuziData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        }

        this.loadLocality = this.loadLocality.bind(this)
        this.toggleCheck = this.toggleCheck.bind(this)
        this.downloadData = this.downloadData.bind(this)

        this.download = this.download.bind(this)
    }

    handleChange = e => { this.setState({ [e.target.name]: e.target.value }); }
    handleChangeFile = e => { this.setState({ coordinate: e.target.files[0] }) }


    download(d) {

    }

    loadLocality() {

        UgSpatial.clear()

        Wait.show("Loading Localities...")
        MobileReadService.mobileLayers(MobilePopup.id()).then(d => {
            Wait.dismiss()
            try {
                this.setState({
                    layers_list: d.data.results
                })


                UgSpatial.clear()

                let ly_group = new LF.FeatureGroup();
                let sat = Basemap.googleSat(),
                    hybrid = Basemap.googleHybrid(),
                    blank = LF.tileLayer('')

                let map = UgSpatial.map == null ? LF.map('map', {
                    layers: [blank, ly_group],
                    attributionControl: false,
                    fullscreenControl: true,
                    maxZoom: 10000
                }).setView([-6.085936, 35.711995], 4) : UgSpatial.map


                let baseLayers = {
                    "<span style='color: gray'>Blank</span>": blank,
                    "Satellite": sat,
                    "Hybrid": hybrid
                }

                let overlays = {
                    //'Grids':grid,
                    'Mobile layers': ly_group,

                }

                UgSpatial.layers = LF.control.layers(baseLayers, overlays, { position: 'bottomleft' }).addTo(map)

                let filter = 'project_id in (' + MobilePopup.id() + ') AND deleted=false'
                let layername = 'mob_utambuzi'
                let self = this




                API.ax.get(API.geo_ilmis + '/ows?cql_filter=' + filter + '&service=WFS&version=1.0.0&request=GetFeature&typeName=' + layername + '&outputFormat=application%2Fjson').then(r => {
                    try {
                        let data = []
                        r.data.features.forEach(p => {

                            console.log(p.id)
                            console.log(p.properties)

                            data.push({
                                id: p.id.split('.')[1],
                                created_by: p.properties.created_by,
                                created_time: p.properties.created_time,
                                description: p.properties.description
                            })
                            LF.geoJSON(p, {
                                onEachFeature: (ft, ly) => {
                                    LF.marker(ly.getBounds().getCenter(), {
                                        icon: LF.divIcon({
                                            className: 'bindTooltipBig',
                                            html: ft.properties.description + ' - ' + ft.properties.created_by,
                                            iconSize: [100, 40]
                                        })
                                    }).addTo(ly_group)
                                },
                                color: p.properties.stage === 0 ? '#ffb' : p.properties.stage === 1 ? '#f00' : '#080', weight: p.properties.boundary ? 4 : 3, zIndex: 1, fillOpacity: 0
                            }).addTo(ly_group);
                        })

                        let myLayer = LF.geoJSON(r.data.features)

                        try { map.fitBounds(myLayer.getBounds()) } catch (e) { }



                        this.setState({
                            data: data
                        })
                    } catch (e) {
                        console.log(e)
                    }
                })

            } catch (e) {
                console.log(e)
            }
        })


    }

    downloadData() {
        let layer_id = this.state.layer_id.replace('mob_layers.', '')
        //let stage = this.state.stage
        let doc_id = this.state.doc_id

        if (layer_id < 1 || layer_id === undefined) {
            alert('No Project Layer')
            return
        }

        if (doc_id < 1 || doc_id === undefined) {
            alert('Select Document Type')
        }
    }

    componentDidMount() {
        this.loadLocality()



    }

    popDataAt(d) {
        if (d.stage === 0) {
            PopDetail.show("Warning", <Message faIcon={'harzad'} message={'No GIS Approved'} />, 30)
            return
        }
        PopDetail.show(d.claim_no, <PopParcel parcel={d} />)
    }

    popFullTable(tb_id = 'bottom_table') {
        let btn = document.getElementById('btn_enl')

        if (btn.innerHTML !== 'Show Map') {
            document.getElementById(tb_id).style.position = 'absolute'
            document.getElementById(tb_id).style.zIndex = 10000000
            document.getElementById(tb_id).style.left = '-1vw'
            document.getElementById(tb_id).style.right = 0
            document.getElementById(tb_id).style.bottom = 0
            document.getElementById(tb_id).style.top = 0
            document.getElementById(tb_id).style.height = '100vh'
            btn.innerHTML = 'Show Map'
        } else {
            document.getElementById(tb_id).style.position = 'absolute'
            document.getElementById(tb_id).style.zIndex = 10000000
            document.getElementById(tb_id).style.left = '-1vw'
            document.getElementById(tb_id).style.right = 0
            document.getElementById(tb_id).style.bottom = 0
            document.getElementById(tb_id).style.top = '60vh'
            document.getElementById(tb_id).style.height = '34vh'
            btn.innerHTML = 'Hide Map'
        }

    }

    toggleCheck(i) {
        let doc = document.getElementById('chk_' + i);
        if (doc.src === uncheck) {
            doc.src = check;
            return;
        }
        doc.src = uncheck;
    }


    render() {
        return (<div >

            <div className={'row'} style={{ marginBottom: '4px' }}>
                <div className={'col col-sm-3'} style={{ height: '60vh', paddingRight: '4px', paddingLeft: 0 }}>
                    <div style={{ background: '#fff', padding: '24px', height: '100%', border: '0.5px solid #efefef' }}>
                        <div style={{ height: '40px', lineHeight: '40px' }}>
                            <b>{this.state.description_title}</b>
                        </div>

                        <div style={{ width: '100%', marginTop: '8px' }}>


                        </div>

                    </div>
                </div>

                <div className={'col col-sm-9'} style={{ height: '60vh', paddingLeft: '4px' }}>
                    <div style={{ background: '#fff', height: '100%', padding: '24px', border: '0.5px solid #efefef' }}>

                        <div className={'row'} style={{ height: '40px', width: '100%', textAlign: 'right' }}>



                            <div className={'col col-sm-12'}>

                                <span style={{ cursor: 'pointer', marginRight: '24px', float: 'right' }}
                                    onClick={() => this.downloadSHP()}>
                                    <span style={{ marginLeft: '16px' }} className={'fa fa-download screen-action'} />
                                    Download Draft
                                </span>

                                <span style={{ cursor: 'pointer', marginRight: '24px', float: 'right' }}
                                    onClick={() => PopDetail.show("Upload Draft", <PaneUploadDraft />, 30)}>
                                    <span style={{ marginLeft: '16px' }} className={'fa fa-upload screen-action'} />
                                    Upload Draft
                                </span>
                            </div>
                        </div>

                        <div className={'row'} style={{ height: '97%' }}>
                            <div className={'col col-sm-12'} style={{ background: '#efefef' }} id={'ramani'} />
                        </div>

                    </div>

                </div>

            </div>
            <div className={'row'}>

                <div className={'col col-sm-12'} style={{ background: '#fff', padding: 0, height: '34vh', overflow: 'auto' }} id={'bottom_table'}>

                    <div className={'row'} style={{ paddingLeft: '16px', paddingTop: '16px', paddingBottom: '16px' }}>
                        <div className={'col col-sm-3'}>

                            <b style={{ fontSize: '12px' }}>{this.state.parcels !== undefined ? this.state.parcels.length : 0} Parcel/s</b>
                            <input placeholder={'Search Here'} style={{ width: '50%', marginLeft: '16px' }} onChange={this.handleChange} name={'search_claim'} />

                        </div>
                        <div className={'col col-sm-1'}>
                            <button onClick={this.searchByClaimNo} className={'default'}>Search</button>
                        </div>
                        <div className={'row col col-sm-6'} style={{ visibility: this.state.is_map_visible }}>
                            <div className={'col col-sm-9'}>
                                <select name={'layer_id'} style={{ width: '100%' }} id={'sp_layer'} >
                                    <option value={-1}>- Choose Stage -</option>
                                    <option value={0}>Mobile Data</option>
                                    <option value={1}>Rejected</option>
                                    <option value={2}>GIS Approval</option>
                                </select>
                            </div>
                            {/*<div className={'col col-sm-3'}>
                                <select style={{width:'100%'}} name={'stage'} onChange={this.handleChangeDataType}>
                                    <option value={0}>- Choose Stage -</option>

                                </select>
                            </div>
                            <div className={'col col-sm-3'}>
                                <select style={{width:'100%'}} name={'doc_id'} onChange={this.handleChange}>
                                    <option value={0}>- Choose Action -</option>

                                </select>
                            </div>*/}
                            <div className={'col col-sm-3'}>
                                <button style={{ width: '100px' }} className={'refresh'} onClick={() => this.downloadData()}>Submit</button>
                            </div>
                        </div>
                        <div className={'col col-sm-2'}>
                            <button id={'btn_enl'} style={{ float: 'right', marginRight: '24px' }} className={'default'} onClick={() => this.popFullTable()}>Hide Map</button>
                        </div>
                    </div>



                    <div className={'col col-sm-12'}>

                        <div style={{ height: '60vh', overflow: 'auto' }}>
                            <table className={'tb'} style={{ width: '100%', marginTop: '8px', border: '1px solid #ddd' }}>
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Description</th>
                                        <th>Created By</th>
                                        <th>Created Time</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.data.map((d, i) => {
                                        return (<tr>
                                            <td>{i + 1}.</td>
                                            <td>{d.description}</td>
                                            <td>{d.created_by}</td>
                                            <td>{d.created_time.replace('T', ' ').split('.')[0]}</td>
                                        </tr>)
                                    })}


                                </tbody>
                            </table>
                        </div>

                        <div className={'row'} style={{ height: '5vh', paddingTop: '8px', borderTop: '1px solid #ccc' }}>
                            <div className={'col col-sm-2'}>

                            </div>
                            <div className={'col col-sm-6'} style={{ textAlign: 'center' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <button className={'default'} style={{ visibility: this.state.page > 0 ? 'visible' : 'hidden' }} onClick={() => this.loadDataAt(-1)}>Back</button>
                                    <input style={{ width: '100px', marginLeft: '16px', textAlign: 'center', marginRight: '16px' }} readOnly={true}
                                        value={(this.state.page + 1) + '/' + this.state.total_pages} />
                                    <button className={'default'} style={{ visibility: this.state.page !== this.state.total_pages ? 'visible' : 'hidden' }} onClick={() => this.loadDataAt(1)}>Next</button>
                                </div>
                            </div>
                            <div className={'col col-sm-4'}>

                            </div>
                        </div>

                    </div>
                </div>


            </div>



        </div>)
    }


    downloadSHP(layer_id = 0, locality = 0, stage = 0) {
        try {
            Wait.show('Creating Shapefile...')
            MobileReadService.downloadSHP(MobilePopup.id(), layer_id, locality, stage).then(r => {
                Wait.dismiss()

                try {

                    Wait.show('Downloading...')
                    try {
                        ReportCreateService.getExcel(`mobile/read/zip?project_id=${MobilePopup.id()}&layer_id=${layer_id}&locality=${locality}&stage=${stage}&zipname=${r.data.message}`).then(r => {
                            try {
                                Wait.dismiss()
                                const url = window.URL.createObjectURL(new Blob([r.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', 'Shapefile.zip'); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                            } catch (e) {
                                console.log(e)
                            }
                        })
                    } catch (e) {
                        console.log(e)
                    }

                } catch (e) {
                    console.log(e)
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

}