import * as React from "react";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import TxCreateService from "../../../../services/transaction/transaction_create";
import PropertyGRO from "../screen/gro/property_gro";
import TransactionPopup from "../transaction_popup";
import RTReadService from "../../../../services/rt/rt_read";
import PopDetailsTwo from "../../PopDetailsTwo";
import wait from "../../wait";

export default class PaneSearchPropertyGro extends React.Component{
    static self = null

    constructor(props) {
        super(props);
        this.getProperties= this.getProperties.bind(this)

        this.state = {
            dataToDisplay:[],
            arr:[],
            ids:[],
            baunitIds:[],
            district:'',
            council:'',
            location:'',
            block:'',
            lotNumber:'',
            lotType:'',
            regPlanNumber:'',
            planNumber:'',
            allocationStatusName:'Vacant',
            landUseName:'',
            drawingNumber:'',
            legalArea:null,
            areaUnitName:'',
            regionName:'',
            spatialUnitId:null,
            baunitId:'',
            baunitObj:{},
           // titleStatus:'Active',
        }
        this.handleChange =this.handleChange.bind(this)
        this.pushProperty =this .pushProperty.bind(this)
        this.saveProperty =this .saveProperty.bind(this)
        PaneSearchPropertyGro.self = this
    }

    handleChange= e =>{
        this.setState({[e.target.name]: e.target.value});
    }



    render() {
        return (<div style={{height: '100%', overflow: 'auto'}} className={'col col-12'}>
            <br/>
            <table style={{width: '100%'}}>
                <tbody>

                <tr>
                    <td><span>District</span></td>
                    <td><span>Council</span></td>
                    <td><span>Location</span></td>
                    <td><span>Block</span></td>
                    <td><span>LOT Number</span></td>
                    <td><span>Reg.Plan Number</span></td>
                    <td><span>Plan Number</span></td>
                </tr>
                <tr>
                    <td><input style={{width: '100%'}} name={"district"}
                               onChange={(e) => this.handleChange(e)}/></td>
                    <td><input style={{width: '100%'}} name={"council"}
                               onChange={(e) => this.handleChange(e)}/></td>
                    <td><input style={{width: '100%'}} name={"location"}
                               onChange={(e) => this.handleChange(e)}/></td>
                    <td><input style={{width: '100%'}} name={"block"}
                               onChange={(e) => this.handleChange(e)}/></td>
                    <td><input style={{width: '100%'}} name={"lotNumber"} onChange={(e) => this.handleChange(e)}/>
                    </td>
                    <td><input style={{width: '100%'}} name={"regPlanNumber"} onChange={(e) => this.handleChange(e)}/>
                    </td>
                    <td><input style={{width: '100%'}} name={"planNumber"} onChange={(e) => this.handleChange(e)}/>
                    </td>
                    <td colSpan={4}><button style={{float:'right'}} onClick={() => this.getProperties()}>Search</button></td>
                </tr>
                </tbody>
            </table>
            <hr/>
            <div className={'col col-sm-12'}>
                <table  className={'tb'} style={{width: '100%'}}>
                    <thead>
                    <tr>
                        <th>DISTRICT</th>
                        <th>LOCATION</th>
                        <th>BLOCK</th>
                        <th>LOT NUMBER</th>
                        <th>PLAN NUMBER</th>
                        <th>USE</th>
                        <th>AREA</th>
                        <th>UNIT</th>
                        <th>DRAWING NUMBER</th>
                        <th>SELECT</th>

                    </tr>
                    </thead>
                    <tbody>
                    {this.state.dataToDisplay.map((d,i)=>{
                        return(<tr key={i}>
                            <td>{d.districtName}</td>
                            <td>{d.localityName}</td>
                            <td>{d.blockNumber}</td>
                            <td>{d.lotNumber}</td>
                            <td>{d.regPlanNumber}</td>
                            <td>{d.landUseName}</td>
                            <td>{d.legalArea}</td>
                            <td>{d.areaUnitName}</td>
                            <td>{d.drawingNumber}</td>
                            <td><input type={'checkbox'}  onClick={()=>this.pushProperty(d)}/></td>

                        </tr>)}
                    )}

                    </tbody>

                </table>
            </div>
            <br/>
            <hr/>
            <br/>


            <hr/>
            <div style={{textAlign:'right',width:'100%'}}>
                <button style={{width:'100px'}} onClick={this.saveProperty}>Save</button>
                <button style={{width:'100px'}} onClick={PopDetail.dismiss} className={'default'}>Close</button>
            </div>

        </div>)

    }



    getProperties(){
        try {
            Wait.show("Loading Spatial...")
            let status="Vacant"
            if(TransactionPopup.transaction().name==="Grant Right of Occupancy"){
                status="Vacant"
            }else if(TransactionPopup.transaction().name==="Variation of Right of Occupancy"){
                status="Allocated"
            }else if(TransactionPopup.transaction().name==="Convert Legacy Right of Occupancy"){
                status="Converted"
            }else if(TransactionPopup.transaction().name==="Transfer Right of Occupancy"){
                status="Allocated"
            } else if(TransactionPopup.transaction().name==="Surrender Right of Occupancy"){
            status="Allocated"
            }else if(TransactionPopup.transaction().name==="Renew Right of Occupancy"){
                status="Allocated"
            }
            else if(TransactionPopup.transaction().name === "Replace Title Certificate"){
                status = "Legacy"
            }else if(TransactionPopup.transaction().name==="Rectification by LAD"){
                status="Allocated"
            }
            else if(TransactionPopup.transaction().name==="Rectification by Registrar"){
                status="Allocated"
            }else if(TransactionPopup.transaction().name==="TIC Grant Right of Occupancy"){
                status="Vacant"
            }

            TxCreateService.createGetSpatail({
                districtName:this.state.district,
                localityName:this.state.location,
                blockNumber:this.state.block,
                lotNumber:this.state.lotNumber,
                lotType:this.state.lotType,
                regPlanNumber:this.state.regPlanNumber,
                planNumber:this.state.planNumber,
                allocationStatusName:status,
                landUseName:this.state.landUseName,
                drawingNumber:this.state.drawingNumber,
                legalArea:this.state.legalArea,
                areaUnitName:this.state.areaUnitName,
                regionName:this.state.regionName,
                spatialUnitId:this.state.spatialUnitId,
                baunitId:this.state.baunitId,
                transactionId:TransactionPopup.id()
            }).then(r => {
                Wait.dismiss()
                try {
                    console.log(r)
                    if(r.data.status===1){
                        this.setState({
                            dataToDisplay:r.data.result

                        })
                    }else{
                        PopDetailsTwo.show("Error",<span>{r.data.message}</span>,25)
                    }

                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }

    }
    pushProperty(d) {
       // console.log("STATUS","++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
       // console.log("TRANSACTION NAME",TransactionPopup.transaction().name)
        if(TransactionPopup.transaction().name==="Grant Right of Occupancy"){
            if (d.allocationStatusName==='Vacant') {
                let ar = this.state.arr
                ar.push(d)
                this.setState({arr:ar})
                let idss = this.state.ids
                idss.push(d.spatialUnitId)
                this.setState({ids:idss})
                let baunitidss=this.state.baunitIds
                baunitidss.push(d.baunitId)
                this.setState({baunitIds:baunitidss})
                if(this.state.baunitId===''){
                    this.setState({baunitId:d.baunitId})
                }
            }
        }
        if(TransactionPopup.transaction().name==="TIC Grant Right of Occupancy"){
            if (d.allocationStatusName==='Vacant') {
                let ar = this.state.arr
                ar.push(d)
                this.setState({arr:ar})
                let idss = this.state.ids
                idss.push(d.spatialUnitId)
                this.setState({ids:idss})
                let baunitidss=this.state.baunitIds
                baunitidss.push(d.baunitId)
                this.setState({baunitIds:baunitidss})
                if(this.state.baunitId===''){
                    this.setState({baunitId:d.baunitId})
                }
            }
        }
        if(TransactionPopup.transaction().name==="Rectification by LAD"){
            if (d.allocationStatusName==='Allocated') {
                let ar = this.state.arr
                ar.push(d)
                this.setState({arr:ar})
                let idss = this.state.ids
                idss.push(d.spatialUnitId)
                this.setState({ids:idss})
                let baunitidss=this.state.baunitIds
                baunitidss.push(d.baunitId)
                this.setState({baunitIds:d.baunitId})
                if(this.state.baunitId===''){
                    this.setState({baunitId:d.baunitId})
                }
            }
        }
        if(TransactionPopup.transaction().name==="Rectification by Registrar"){
            if (d.allocationStatusName==='Allocated') {
                let ar = this.state.arr
                ar.push(d)
                this.setState({arr:ar})
                let idss = this.state.ids
                idss.push(d.spatialUnitId)
                this.setState({ids:idss})
                let baunitidss=this.state.baunitIds
                baunitidss.push(d.baunitId)
                this.setState({baunitIds:d.baunitId})
                if(this.state.baunitId===''){
                    this.setState({baunitId:d.baunitId})
                }
            }
        }
        if(TransactionPopup.transaction().name==="Surrender Right of Occupancy") {
                let ar = this.state.arr
                ar.push(d)
            if (this.state.baunitId === '') {
                this.setState({baunitId: d.baunitId})
            }
            console.log("PUSHING D",d)
                this.setState({arr: ar})
                let idss = this.state.ids
            console.log("PUSHING SPATIAL ID",d.spatialUnitId)
                idss.push(d.spatialUnitId)
                this.setState({ids: idss})
                let baunitidss = this.state.baunitIds
            console.log("PUSHING BAUNIT ID",d.baunitId)
                baunitidss.push(d.baunitId)
                this.setState({baunitIds: baunitidss})

        }
        if(TransactionPopup.transaction().name==="Renew Right of Occupancy") {
            let ar = this.state.arr
            ar.push(d)
            if (this.state.baunitId === '') {
                this.setState({baunitId: d.baunitId})
            }
            console.log("PUSHING D",d)
            this.setState({arr: ar})
            let idss = this.state.ids
            console.log("PUSHING SPATIAL ID",d.spatialUnitId)
            idss.push(d.spatialUnitId)
            this.setState({ids: idss})
            let baunitidss = this.state.baunitIds
            console.log("PUSHING BAUNIT ID",d.baunitId)
            baunitidss.push(d.baunitId)
            this.setState({baunitIds: baunitidss})

        }
        if(TransactionPopup.transaction().name==="Variation of Right of Occupancy"){
            console.log("Variation of Right of Occupancy ======>")
            if (d.allocationStatusName === 'Allocated'){
                let arr = [d]
                let data = {
                    transactionId: TransactionPopup.id(),
                    spatialUnitIds: [d.spatialUnitId]
                }
                console.log("Spatial To save",data)

                TxCreateService.saveSpatialUnitTransaction(data).then(d => {

                })

                let baunit = {
                    transactionId: TransactionPopup.id(),
                    baunitIds: this.state.baunitIds
                }
                console.log("BaUnit To save",baunit)


                TxCreateService.saveBaunitTransaction(baunit).then(d => {

                })

                RTReadService.getBaunit(d.baunitId).then(r => {
                    // Wait.dismiss()
                    try {
                        console.log("BAUNIT=====>", r.data.result)
                        this.setState({
                            baunitObj: r.data.result
                        })
                        PropertyGRO.self.setState({spatial: arr, baunitId: d.baunitId, baunit: this.state.baunitObj})
                        console.log(arr)
                    } catch (e) {
                        console.log(e)
                    }
                })
                console.log(PropertyGRO.self.state.spatial)
            }
        }
        if(TransactionPopup.transaction().name==="Convert Legacy Right of Occupancy"){
            console.log("Convert Legacy Right of Occupancy ======>")
            if (d.allocationStatusName === 'Converted'){
                let arr = [d]
                let data = {
                    transactionId: TransactionPopup.id(),
                    spatialUnitIds: [d.spatialUnitId]
                }
                console.log("Spatial To save",data)

                TxCreateService.saveSpatialUnitTransaction(data).then(d => {

                })
                let baunit = {
                    transactionId: TransactionPopup.id(),
                    baunitId: d.baunitId
                }
                console.log("BaUnit To save",baunit)
                TxCreateService.saveBaunitTransaction(baunit).then(d => {

                })

                RTReadService.getBaunit(d.baunitId).then(r => {
                    // Wait.dismiss()
                    try {
                        console.log("BAUNIT=====>", r.data.result)
                        this.setState({
                            baunitObj: r.data.result
                        })
                        PropertyGRO.self.setState({spatial: arr, baunitId: d.baunitId, baunit: this.state.baunitObj})
                        console.log(arr)
                    } catch (e) {
                        console.log(e)
                    }
                })
                console.log(PropertyGRO.self.state.spatial)
            }
        }
        if(TransactionPopup.transaction().name==="Transfer Right of Occupancy"){
            console.log("TRANSACTION NAME",TransactionPopup.transaction().name)
            if (d.allocationStatusName==='Allocated') {
                let ar = this.state.arr
                ar.push(d)
                this.setState({arr:ar})
                let idss = this.state.ids
                idss.push(d.spatialUnitId)
                this.setState({ids:idss})
                let baunitidss=this.state.baunitIds
                console.log("BAUNIT ID",d.baunitId)
                baunitidss.push(d.baunitId)
                this.setState({baunitIds:baunitidss})
            }

        }

        if(TransactionPopup.transaction().name==="Replace Title Certificate"){
            console.log("TRANSACTION NAME")
            console.log("Allocated status"+ d.allocationStatusName)
            if (d.allocationStatusName==='Legacy') {
                console.log("Allocated status"+ d.allocationStatusName)
                let ar = this.state.arr
                ar.push(d)
                this.setState({arr:ar})
                let idss = this.state.ids
                idss.push(d.spatialUnitId)
                this.setState({ids:idss})
                let baunitidss=this.state.baunitIds
                console.log("BAUNIT ID",d.baunitId)
                baunitidss.push(d.baunitId)
                console.log("idsss for baunit", baunitidss)
                this.setState({baunitIds:baunitidss})
                if(this.state.baunitId===''){
                    this.setState({baunitId:d.baunitId})
                }
            }

        }
    }

    saveProperty() {
        if (TransactionPopup.transaction().name === "Grant Right of Occupancy") {


            wait.show("Saving....")
    try{
            let data = {
                transactionId: TransactionPopup.id(),
                spatialUnitIds: this.state.ids
            }
            console.log(data)
            TxCreateService.saveSpatialUnitTransaction(data).then(d => {
                if (d.data.status === 1) {
                    console.log("SPATIAL UNIT Result==---",d)
                    setTimeout(() => {
                        let baunitDataTo = {
                            transactionId: TransactionPopup.id(),
                            baunitIds: this.state.baunitId
                        }
                        console.log("BaUnit To save", baunitDataTo)
                        TxCreateService.saveBaunitTransaction(baunitDataTo).then(d => {
                            console.log("BAUNIT Result==---",d)
                            wait.dismiss()
                            PopDetail.dismiss()
                        })
                    }, 2000)
                } else {
                    PopDetailsTwo.show("Error", <span>{d.data.message}</span>)
                    return
                }
            })

            console.log("BAUNIT +To Save==>", this.state.baunitId)

            RTReadService.getBaunit(this.state.baunitIds[0]).then(r => {
                try {
                    if (r.data.status === 1) {
                        console.log("BAUNIT=====>", r.data.result)
                        this.setState({
                            baunitObj: r.data.result
                        })

                        PropertyGRO.self.setState({
                            spatial: this.state.arr,
                            baunit: r.data.result
                        })
                    }

                    //console.log(this.state.arr)
                } catch (e) {
                    console.log(e)
                }
            })
            console.log(PropertyGRO.self.state.spatial)
        }catch (e) {

}
        }
        if (TransactionPopup.transaction().name === "TIC Grant Right of Occupancy") {


            wait.show("Saving....")
            try{
                let data = {
                    transactionId: TransactionPopup.id(),
                    spatialUnitIds: this.state.ids
                }
                console.log(data)
                TxCreateService.saveSpatialUnitTransaction(data).then(d => {
                    if (d.data.status === 1) {
                        console.log("SPATIAL UNIT Result==---",d)
                        setTimeout(() => {
                            let baunitDataTo = {
                                transactionId: TransactionPopup.id(),
                                baunitIds: this.state.baunitId
                            }
                            console.log("BaUnit To save", baunitDataTo)
                            TxCreateService.saveBaunitTransaction(baunitDataTo).then(d => {
                                console.log("BAUNIT Result==---",d)
                                wait.dismiss()
                                PopDetail.dismiss()
                            })
                        }, 2000)
                    } else {
                        PopDetailsTwo.show("Error", <span>They are not intersect </span>)
                        return
                    }
                })

                console.log("BAUNIT +To Save==>", this.state.baunitId)

                RTReadService.getBaunit(this.state.baunitIds[0]).then(r => {
                    try {
                        if (r.data.status === 1) {
                            console.log("BAUNIT=====>", r.data.result)
                            this.setState({
                                baunitObj: r.data.result
                            })

                            PropertyGRO.self.setState({
                                spatial: this.state.arr,
                                baunit: r.data.result
                            })
                        }

                        //console.log(this.state.arr)
                    } catch (e) {
                        console.log(e)
                    }
                })
                console.log(PropertyGRO.self.state.spatial)
            }catch (e) {

            }
        }
        if(TransactionPopup.transaction().name === "Transfer Right of Occupancy"){

            wait.show("Saving....")
            try{


                            let baunitDataTo = {
                                transactionId: TransactionPopup.id(),
                                baunitIds: this.state.baunitId
                            }
                            console.log("BaUnit To save", baunitDataTo)
                            TxCreateService.saveBaunitTransactionTransfer(baunitDataTo).then(d => {
                                Wait.dismiss()
                            })




                console.log("BAUNIT +To Save==>", this.state.baunitId)
                wait.show("Reading occupancy....")
                RTReadService.getBaunit(this.state.baunitId).then(r => {

                    try {
                        if (r.data.status === 1) {

                            console.log("BAUNIT=====>", r.data.result)
                            this.setState({
                                baunitObj: r.data.result
                            })

                            PropertyGRO.self.setState({
                                spatial: this.state.arr,
                                baunit: r.data.result
                            })
                        }

                        //console.log(this.state.arr)
                    } catch (e) {
                        console.log(e)
                    }
                })
                console.log(PropertyGRO.self.state.spatial)
            }catch (e) {

            }
        }
        /*if(TransactionPopup.transaction().name === "Rectification by LAD"){

            wait.show("Saving....")
            try{


                let baunitDataTo = {
                    transactionId: TransactionPopup.id(),
                    baunitIds: this.state.baunitId
                }
                console.log("BaUnit To save rectification", baunitDataTo)
                TxCreateService.saveBaunitTransactionTransfer(baunitDataTo).then(d => {
                    if(d.data.status==1){
                        this.setState({arr:d.data.result})
                    }else {

                    }
                    Wait.dismiss()
                })




                console.log("BAUNIT +To Save==>", this.state.baunitId)
                wait.show("Reading occupancy....")
                RTReadService.getBaunit(this.state.baunitId).then(r => {

                    try {
                        if (r.data.status === 1) {

                            console.log("BAUNIT=====>", r.data.result)
                            this.setState({
                                baunitObj: r.data.result
                            })



                            PropertyGRO.self.setState({
                                spatial: this.state.arr,
                                baunit: r.data.result
                            })
                        }

                        //console.log(this.state.arr)
                    } catch (e) {
                        console.log(e)
                    }
                })
                console.log(PropertyGRO.self.state.spatial)
            }catch (e) {

            }
        }*/

        if(TransactionPopup.transaction().name === "Surrender Right of Occupancy"){

            wait.show("Saving....")
            try{

                let data = {
                    transactionId: TransactionPopup.id(),
                    spatialUnitIds: this.state.ids
                }
                console.log("spatials===>",data)
                TxCreateService.saveSpatialUnitTransaction(data).then(d => {

                })

                let baunitDataTo = {
                    transactionId: TransactionPopup.id(),
                    baunitIds: this.state.baunitId
                }
                console.log("BaUnit To save", baunitDataTo)
                TxCreateService.saveBaunitTransactionTransfer(baunitDataTo).then(d => {
                    Wait.dismiss()
                })




                console.log("BAUNIT +To Save==>", this.state.baunitId)
                wait.show("Reading occupancy....")
                RTReadService.getBaunit(this.state.baunitId).then(r => {

                    try {
                        if (r.data.status === 1) {

                            console.log("BAUNIT=====>", r.data.result)
                            this.setState({
                                baunitObj: r.data.result
                            })

                            PropertyGRO.self.setState({
                                spatial: this.state.arr,
                                baunit: r.data.result
                            })
                        }

                        //console.log(this.state.arr)
                    } catch (e) {
                        console.log(e)
                    }
                })
                console.log(PropertyGRO.self.state.spatial)
            }catch (e) {

            }
        }
        if(TransactionPopup.transaction().name === "Renew Right of Occupancy"){

            wait.show("Saving....")
            try{

                let data = {
                    transactionId: TransactionPopup.id(),
                    spatialUnitIds: this.state.ids
                }
                console.log("spatials===>",data)
                TxCreateService.saveSpatialUnitTransaction(data).then(d => {
                })

                let baunitDataTo = {
                    transactionId: TransactionPopup.id(),
                    baunitIds: this.state.baunitId
                }
                console.log("BaUnit To save", baunitDataTo)
                TxCreateService.saveBaunitTransactionTransfer(baunitDataTo).then(d => {
                    Wait.dismiss()
                })




                console.log("BAUNIT +To Save==>", this.state.baunitId)
                wait.show("Reading occupancy....")
                RTReadService.getBaunit(this.state.baunitId).then(r => {

                    try {
                        if (r.data.status === 1) {

                            console.log("BAUNIT=====>", r.data.result)
                            this.setState({
                                baunitObj: r.data.result
                            })

                            PropertyGRO.self.setState({
                                spatial: this.state.arr,
                                baunit: r.data.result
                            })
                        }

                        //console.log(this.state.arr)
                    } catch (e) {
                        console.log(e)
                    }
                })
                PopDetail.dismiss()
                console.log(PropertyGRO.self.state.spatial)
            }catch (e) {

            }
        }

        if(TransactionPopup.transaction().name === "Replace Title Certificate"){

            wait.show("Saving....")
            try{

                let data = {
                    transactionId: TransactionPopup.id(),
                    spatialUnitIds: this.state.ids
                }
                console.log("spatials===>",data)
                TxCreateService.saveSpatialUnitTransaction(data).then(d => {
                    console.log("saving spatial data", d)
                })

                let baunitDataTo = {
                    transactionId: TransactionPopup.id(),
                    baunitIds: this.state.baunitId
                }
                console.log("BaUnit To save", baunitDataTo)
                TxCreateService.saveBaunitTransactionTransfer(baunitDataTo).then(d => {
                    console.log("saving baunit data", d)
                    Wait.dismiss()
                })




                console.log("BAUNIT +To Save==>", this.state.baunitId)
                wait.show("Reading occupancy....")
                RTReadService.getBaunit(this.state.baunitId).then(r => {

                    try {
                        if (r.data.status === 1) {

                            console.log("BAUNIT=====>", r.data.result)
                            this.setState({
                                baunitObj: r.data.result
                            })

                            PropertyGRO.self.setState({
                                spatial: this.state.arr,
                                baunit: r.data.result
                            })
                        }

                        //console.log(this.state.arr)
                    } catch (e) {
                        console.log(e)
                    }
                })
                console.log(PropertyGRO.self.state.spatial)
            }catch (e) {

            }
        }

        PopDetail.dismiss()
    }

}
