import * as React from "react";
import TransactionPopup from "../transaction_popup";
import Persons from "./gro/person/persons";
import ProcessingComments from "./objects/processingComments";
import API from "../../../../services/API";
import SPUnit from "./objects/spUnit";
import Title from "./objects/title";
import ProjectParameters from "./objects/projectParameter";
import CompProjectParameters from "./objects/compProjectParameters";
import Official from "./objects/official-object";
import Transfer from "./objects/transfer";
import Maintenance from "./objects/maintenace";
import DeedTittle from "./objects/deedTittle";
import RectificationParameter from "./objects/rectificationParameter";
import UnitTittle from "./unit_plan/unitTittle";
import UnitTransferObject from "./unit_plan/unitTransferObject";

export default class SummaryPane extends React.Component{
    static self = null
    constructor(props) {
        super(props);
        this.state = {
            comments:[],
            persons:[],
            varification:false,
            appSummary: {},
            parameters: {},
            processingComments: [],
            data: {
                persons: []
            },
            txObj:'',
            txParameter:'',
            txBack:[],
            isTxObj:false,
            isParameter:false,
            currentTab:1
        }

        SummaryPane.self = this
    }

    static state(){
        try {
            return SummaryPane.self.state
        }catch (e) {
            return {}
        }
    }
    static verify(){
        return SummaryPane.state().varification
    }
    static persons(){
        try {
            return SummaryPane.self.state
        }catch (e) {
            return []
        }
    }
    static processingComments(){
        try {
            return SummaryPane.state().processingComments
        }catch (e) {
            return []
        }
    }
    static setState(state_name,state_value){
        try {
            SummaryPane.self.setState({ [state_name]:state_value})
            TransactionPopup.self.setState({ [state_name]:state_value})
        }catch (e) {
            //console.log(e)
        }
    }

    componentDidMount() {


        setTimeout(()=>{
            const{transaction}=TransactionPopup.state()
            let tasks = transaction.name
            console.log("the tasks ",tasks)
            let obScreen =TransactionPopup.self.state.objs
            console.log("the object ",obScreen)

            if(obScreen){
                obScreen.objectScreens.map(d=>{
                    if(d.screen === "Summary"){
                        console.log("object za summary==>",d.object)
                        let txO = d.object === "spatial"?<SPUnit writable={this.props.writable} />:
                            d.object ==="title"?<Title/>:d.object==="interest"?"Interest Object":d.object ==="official"?<Official writable={this.props.writable}/>: d.object ==="project"?<ProjectParameters txId={TransactionPopup.self.state.transaction.txId} writable={this.props.writable}/>:
                                d.object ==="projectParameter"?<CompProjectParameters txId={TransactionPopup.self.state.transaction.txId} writable={this.props.writable}/>:d.object ==="maintenance"?<Maintenance writable={this.props.writable}/> : d.object === "deed" ? <DeedTittle writable={this.props.writable}/> :
                                    d.object === "unit" ? <UnitTittle writable={this.props.writable}/> :  d.object === "unit Transfer" ? <UnitTransferObject writable={this.props.writable}/> : ''
                        /*this.setState({"txObj":txO})
                         if(typeof(txO) === "object"){
                            this.setState({isTxObj:true})
                            this.setState({"txObj":txO})
                        }*/if(d.object==="official") {
                                this.setState({isTxObj:true})
                                this.setState({"txObj":<Official writable={this.props.writable}/>})
                        }else if(d.object ==="rectification"){
                             this.setState({isParameter:true})
                             this.setState({"txParameter":<RectificationParameter/>})
                         }else{
                            this.setState({isParameter:false})
                            this.setState({"txObj":"This transaction does not have additional parameters."})
                        }
                        if(d.object ==="transfer"){
                            this.setState({isParameter:true})
                            this.setState({"txParameter":<Transfer/>})
                        }

                        }
                    })
            }else{
                this.setState({isTxObj:false})
                this.setState({"txObj":"This transaction does not have additional parameters."})
            }
        },1500)

        API.ax.get("transaction/transaction-object/4")
            .catch(e => {
                console.error("object error",e)
            })
            .then(d => {
                console.log("object response", d)
            })

        if(TransactionPopup.persons().length>0){
            this.setState({"varification":true})
        }
    }

    render() {
        let {isTxObj,isParameter}=this.state
     //let objj=<div></div>

        return(
            <div className={'row'}>
            <div className={'col col-sm-6'} style={{marginBottom:'4px'}}>

                <div className={''} style={{height:'47vh', overflow:'auto'}}>

                    <div style={{background:'#fff',height:'100%',paddingRight:'10px',paddingLeft:'20px',paddingTop:'5px',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>Application Summary</b>
                        </div>

                        <table style={{width:'100%'}}>
                            <tbody>
                            <tr style={{color:'#888'}}>
                                <td>Transaction No</td>
                                <td>Office</td>
                                <td>Re-Lodge Transaction</td>
                            </tr>
                            <tr>
                                <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.appSummary.transactionNo} readOnly={true}/></td>
                                <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.appSummary.office} readOnly={true}/></td>
                                <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.appSummary.relodge} readOnly={true}/></td>
                            </tr>
                            <tr style={{color:'#888'}}>
                                <td>Submitted</td>
                                <td>Trans. Status</td>
                                <td>Lodged</td>
                            </tr>
                            <tr>
                                <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.appSummary.submitted} readOnly={true}/></td>
                                <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.appSummary.transStatus} readOnly={true}/></td>
                                <td><input type={'text'} style={{width:'100%'}} defaultValue={this.state.appSummary.lodge} readOnly={true}/></td>
                            </tr>
                            </tbody>
                        </table>
                        <br/>
                        <div >
                            <div>
                                {
                                    (isTxObj && isParameter)?
                                        <div>
                                    <div className={'col col-sm-12'} style={{ height:'100%',background:'#fff', padding:'.2rem'}}>
                                    <div className={this.state.currentTab === 1?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{this.setState({currentTab:1})}}>
                                    Paramater
                                    </div>
                                    <div className={this.state.currentTab === 2?'tab no-bg tab-active':'tab no-bg'} onClick={()=>{this.setState({currentTab:2})}}>
                                    Transaction Object
                                    </div>
                                    </div>
                                    <div style={this.state.currentTab===1?{ display:'block',margin:0}:{display:'none'}}>
                                    {/*   {this.state.txParameter}*/}
                                    <div>{this.state.txParameter}</div>
                                    </div>
                                    <div style={this.state.currentTab===2?{display:'block',margin:0}:{display:'none'}}>
                                    {/*  {this.state.txObj}*/}
                                    <div>{this.state.txObj}</div>
                                    </div>
                                        </div>:isTxObj?<div className={'col col-sm-12'} style={{ height:'100%',background:'#fff', padding:'.2rem'}}>{this.state.txObj}</div>:isParameter?<div className={'col col-sm-12'} style={{ height:'100%',background:'#fff', padding:'.2rem'}}>{this.state.txParameter}</div>:''
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <ProcessingComments coment={SummaryPane.processingComments()} writable={this.props.writable}/>

            </div>

            <div className={'col col-sm-6'} style={{ background:'#fff', height:'94vh',border:'0.5px solid #efefef'}}>
                <Persons writable={this.props.writable}/>
            </div>
        </div>)
    }
}
