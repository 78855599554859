import * as React from "react";
import logo from '../../../resource/img/nembo.png';
import TransactionTable from "../tables/tb_transaction";
import Wait from "../popups/wait";
import TransactionPopup from "../popups/transacrions/transaction_popup";
import Dashboard from "./dashboard"
import TxReadService from "../../services/transaction/transaction_read";
import PopDetail from "../popups/popdetail";
import {PasswordResetRequest} from "../popups/uma/password-reset";
import PaneAddAction from "../popups/transacrions/pops/pane_add_action";
import PopPendingTransactions from "../popups/transacrions/pops/reports/tracking/pending_transactions";
import PopPendingTransactionsSummary from "../popups/transacrions/pops/reports/tracking/pending_transactions_summary";
import CompletedTasksByUser from "../popups/transacrions/pops/reports/tracking/competed_by_user";
import PaneSearchPropertyBatch from "../popups/transacrions/pops/pane_search_property_batch";
import PrintDocumentPane from "../popups/transacrions/screen/print_doc";
import API from "../../services/API";
import ReportParameters from "../popups/transacrions/pops/reports/report_parameters";
import SpatialUpades from "../popups/transacrions/pops/spatialUpades";
import PopFinanceTransactions from "../popups/transacrions/pops/reports/tracking/finance_transactions";

export default class BaseLayout extends React.Component{

    static current_menu = 0
    constructor(props) {
        super(props);

        this.state = {
            menu_transaction_list:[],
            menu_report_list:[],
            menu_action_list:[],
            menu:[{title:'Tds',icon:'',name:'Add d',}],
            menu_report:[],

            left_menu:[
                {title:'Common', access:true, submenu:[
                    {title:'All Transactions',TransactionTable:''},
                    {title:'Active Transactions',pane:''},
                    {title:'Assigned to Me',pane:''},
                    {title:'Submitted Today',pane:''},
                    {title:'Submitted in a Week',pane:''},
                    {title:'Registered',pane:''},
                    {title:'Rejected',pane:''},
                    {title:'Postponed',pane:''}
                    ]},
                {title:'By Task', access:true, submenu:[{title:'Pending Request',pane:'Loading...'}]},
                {title:'By Transaction', access:true, submenu:[{title:'First Parcel Registration',pane:'Loading...'}]},
            ],
            i:0,
            j:0,
            dataToLoad:'tome'//'all'
        }

        this.showAddSubmenuAt = this.showAddSubmenuAt.bind(this)
        this.showLeftMenuAt = this.showLeftMenuAt.bind(this)
        this.setMenuAt = this.setMenuAt.bind(this)
        this.showAddReportAt = this.showAddReportAt.bind(this)
        this.showAddSubmenuReportAt = this.showAddSubmenuReportAt.bind(this)

        this.showTransactionData = this.showTransactionData.bind(this)
        this.loadTransactionList = this.loadTransactionList.bind(this)
        this.openTransactionPaneAt = this.openTransactionPaneAt.bind(this)
        this.openRepotPaneAt = this.openRepotPaneAt.bind(this)
    }


    openTransactionPaneAt(d) {
        let transaction = {
            txDefId:d.id,
            name:d.name,
            objs:d.objects,
            isNew:true,
            taskName:'Receive Application',
        }
        TransactionPopup.show(transaction)

    }

    componentDidMount() {
        try {
            this.loadTransactionList()
        }catch (e){
            console.log(e)
        }
    }

    showAddSubmenuAt(i,transactions){
        this.setState({menu:transactions})

        document.getElementById('pop-new').style.display ='inherit'
        document.getElementById('pop-new-report').style.display ='none'


        let top = document.getElementById('tp-mn-'+i).getBoundingClientRect().top -55
        document.getElementById('pop-submenu').style.top =top+'px'
        BaseLayout.current_menu = i
    }

    setMenuAt(trans){

        document.getElementById('pop-new-report').style.display ='none'
        TransactionPopup.show(trans)

    }

    showLeftMenuAt(i,j,submenu){

        this.state.menu.forEach((d,ii)=>{ if(ii!==i){document.getElementById('sub-menu-'+ii).style.display = 'none';}});

        let div_submenu = document.getElementById('sub-menu-'+i);

        if(!submenu){
            div_submenu.style.display = div_submenu.style.display==='inherit'?'none':'inherit';
        }


    }


    render() {
        return(
            <div className={'base-container'} id={'base-container'}>

            <div className={'col col-sm-12 base-toolbar'}>

                <div className={'col col-sm-2'} style={{float:'left'}}>
                    <span className={'fa fa-navicon'} style={{marginRight:'16px',fontSize:'20px'}}/>
                    <img src={logo} alt={'logo'} style={{height:'4vh',marginRight:'16px',marginTop:'-1.5vh'}}/>
                    <span style={{fontFamily:'oxygen-bold',fontSize:'20px'}}>ILMIS</span>

                </div>

                <div id={'btn-new'} className={'btn-new'} style={{float:'left',padding:'0px 8px'}} onClick={()=>this.showAddSubmenuAt(0,this.state.menu_transaction_list)}>
                    <span className={'fa fa-plus'} style={{fontSize:'18px',marginRight:'8px'}}/>
                    <b>New Transaction</b>
                    <span className={'fa fa-caret-down'} style={{fontSize:'18px',margin:'0 8px'}}/>
                </div>

                {//sessionStorage.getItem("Action")==="1"?
                    <div id={'btn-action'} className={'btn-new'} style={{float:'left',marginLeft:'16px',padding:'0px 8px'}} onClick={()=>{PopDetail.show("ACTION",<PaneAddAction/>,30)}}>
                        <span className={'fa fa-pencil'} style={{fontSize:'18px',marginRight:'8px'}}/>
                        <b>Action</b>
                        <span className={'fa fa-caret-down'} style={{fontSize:'18px',margin:'0 8px'}}/>
                    </div>
                    // :''
                }

                <div id={'btn-report'} className={'btn-new'} style={{float:'left',marginLeft:'16px',padding:'0px 8px'}} onClick={()=>this.showAddReportAt(0,[])}>
                    <span className={'fa fa-file-o'} style={{fontSize:'18px',marginRight:'8px'}}/>
                    <b>Reports</b>
                    <span className={'fa fa-caret-down'} style={{fontSize:'18px',margin:'0 8px'}}/>
                </div>

                <div style={{float:'right',marginLeft:'48px'}}>
                    <span style={{cursor:"pointer"}}
                          onClick={sessionStorage.getItem('username') !== null?()=>PopDetail.show("Password Reset Request",<PasswordResetRequest user={sessionStorage.getItem('username')}/>,50):()=>{}}>{sessionStorage.getItem('username')}</span>
                    <span onClick={()=>Dashboard.logout()} className={'fa fa-power-off'}
                          style={{fontSize:'18px',cursor:'pointer',marginLeft:'16px'}}/>
                </div>


                <div id={'btn-report'} className={'btn-new'} style={{float:'right',marginLeft:'16px',padding:'0px 8px'}}
                     onClick={()=>this.showPropertySearch()}>
                    <span className={'fa fa-search'} style={{fontSize:'18px',marginRight:'24px'}}/>
                    <b>Property Search</b>

                </div>


            </div>
            {/*------------------------------------------------------*/}

            <div className={'col col-sm-2 base-body-left'}>
                {
                    this.state.left_menu.map((d,i)=>{

                        return(<div key={'left-menu-'+i} style={{display:d.access?'inherit':'none'}}>

                                <div key={'menu-'+i} className={'btn-left-menu'} onClick={()=>this.showLeftMenuAt(i,0,false)}>
                                    <span className={'fa fa-angle-double-right'}/>

                                    <b>{d.title}</b>
                                </div>

                                <div id={'sub-menu-' + i} style={{display: i===0?'inherit':'none',padding:'8px',margin:'2px 0',backgroundColor:'#fff'}}>
                                    {d.submenu.map((s, j) => {
                                        return (/*<div key={'sub-menu-' + j} className={'sub-menu'} onClick={()=>this.showLeftMenuAt(i,j,true)}>*/
                                            <div key={'sub-menu-' + j} className={'sub-menu'} onClick={()=>this.showTransactionData(s.title)}>
                                            <span className={'fa fa-angle-double-right'} style={{marginRight:'8px'}}/>
                                            <span>{s.title} </span>
                                            {/*<span className={'ug-badge-light'}>0</span>*/}
                                        </div>)
                                    })}
                                </div>
                        </div>)
                    })
                }
            </div>

            {/*------------------------------------------------------*/}

            <div className={'col col-sm-10 base-body-right'} >
                {console.log(this.state.dataToLoad)}
                <TransactionTable children={this.state.dataToLoad}/>
            </div>


            {/*-----------------------POP Transaction Menu-------------------------------*/}
            <div onMouseLeave={()=>document.getElementById('pop-new').style.display='none'}
                 className={'col col-sm-4'} style={{position:'absolute',display:'none',minWidth:'400px',textAlign:'left',left:'17vw',top:'6vh',padding:0,zIndex:4}} id={'pop-new'}>

                <div className={'col col-sm-6'} style={{background:'#f7f7f7',boxShadow:'0 0 10px #888',float:'left',padding:0}}>
                    {
                        this.state.menu_transaction_list.map((d,i)=>{
                            return(<div key={i} className={'btn-left-menu'} id={'tp-mn-'+i} onMouseEnter={()=>this.showAddSubmenuAt(i,d.transactions)}>
                                <b>{d.transactionType}<span className={'fa fa-angle-right'} style={{marginLeft:'16px',float:'right'}}/></b>
                            </div>)
                        })
                    }
                </div>
                <div className={'col col-sm-6'} id={'pop-submenu'} style={{float:'left',background:'#fff',padding:0}} >
                    <div className={'nowrap'} id={'sub-submenu'} style={{display:'inherit',boxShadow:'0 0 10px #888'}}>
                        {
                            this.state.menu.map((d,i)=>{

                                return(<div key={i} onClick={()=> this.openTransactionPaneAt(d) }>{d.name}</div>)
                            })
                        }
                        <br/>
                    </div>

                </div>

            </div>

                {/*-----------------------POP Report Menu-------------------------------*/}
                <div onMouseLeave={()=>document.getElementById('pop-new-report').style.display='none'}
                     className={'col col-sm-4'}
                     style={{position:'absolute',display:'none',minWidth:'400px',textAlign:'left',left:'17vw',top:'6vh',padding:0,zIndex:4}} id={'pop-new-report'}>

                    <div className={'col col-sm-6'} style={{background:'#f7f7f7',boxShadow:'0 0 10px #888',float:'left',padding:0}}>
                        {
                            this.state.menu_report_list.map((d,i)=>{
                                return(<div key={i} className={'btn-left-menu'} id={'tp-mn-rpt-'+i} onMouseEnter={()=>this.showAddSubmenuReportAt(i,d.reports)}>
                                    <b>{d.name}<span className={'fa fa-angle-right'} style={{marginLeft:'16px',float:'right'}}/></b>
                                </div>)
                            })
                        }
                    </div>
                    <div className={'col col-sm-6'} id={'pop-submenu-report'} style={{float:'left',background:'#fff',padding:0}} >
                        <div className={'nowrap'} id={'sub-submenu'} style={{display:'inherit',boxShadow:'0 0 10px #888'}}>
                            {
                                this.state.menu_report.map((d,i)=>{

                                    return(<div key={i} onClick={()=> this.openRepotPaneAt(d) }>{d.name}</div>)
                                })
                            }
                            <br/>
                        </div>

                    </div>

                </div>



            {/***** Waiting Pane*****/}

            <TransactionPopup/>




        </div>
        )
    }

    static show(){ document.getElementById('base-container').style.display='inherit' }








    /*------------------ New Work -----------------*/
    loadTransactionList() {
        try {
            Wait.show("Loading Transactions...")
            TxReadService.transactionList().then(d=>{
                Wait.dismiss()
                try {
                    sessionStorage.setItem("screens",JSON.stringify(d.data.screens))
                    this.setState({menu_transaction_list:d.data.transactions})
                    this.setState({menu_report_list:d.data.reports})
                    this.setState({menu_action_list:d.data.actions})
                }catch (e){
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }


    showTransactionData(title) {

        let searchName =title==="All Transactions"?"all":
            title==="Active Transactions"?"active":
            title==="Assigned to Me"?"tome":
            title==="Submitted Today"?"subToday":
            title==="Submitted in a Week"?"subWeek":
            title==="Registered"?"registered":
            title==="Rejected"?"rejected":
            title==="Postponed"?"postponed":'all'

        setTimeout(()=>{this.setState({ dataToLoad:searchName
        })},1000)

        TransactionTable.loadData()
    }

    showAddReportAt(i, reports) {

        let left = document.getElementById('btn-report').getBoundingClientRect().left

        this.setState({menu:reports})



        document.getElementById('pop-new').style.display ='none'
        document.getElementById('pop-new-report').style.display ='inherit'
        document.getElementById('pop-new-report').style.left =left+'px'
        BaseLayout.current_menu = i


    }



    showAddSubmenuReportAt(i,reports){
        this.setState({menu_report:reports})

        document.getElementById('pop-new').style.display ='none'
        document.getElementById('pop-new-report').style.display ='inherit'


        let top = document.getElementById('tp-mn-rpt-'+i).getBoundingClientRect().top -55
        document.getElementById('pop-submenu-report').style.top =top+'px'
        BaseLayout.current_menu = i
    }

    openRepotPaneAt(d) {
        console.log(d.name)
        switch (d.name) {
            case 'Pending Transactions Details':
                PopDetail.show(d.name,<PopPendingTransactions/>,60)
                break
            case 'Pending Transactions Summary':
                PopDetail.show(d.name,<PopPendingTransactionsSummary/>)
                break
            case 'Completed Tasks by User':
                PopDetail.show(d.name,<CompletedTasksByUser/>)
                break
            case 'Transactions Summary':
                PopDetail.show(d.name,<CompletedTasksByUser/>)
                break
            case 'RTU Report':
                PopDetail.show("RTU General Report",<ReportParameters transactionName={'Grant Right of Occupancy'} reportName={'RTU General Report'} department={'RTU'}/>)
                break
            case 'Registration Progress':
                break
            case 'Registration Progress Details':
                break
            case 'Survey Report':
                PopDetail.show("Survey Report",<ReportParameters transactionName={'Legacy Data Spatial SMD'} reportName={'Survey Report'} department={'SMD'}/>)
                break
            case 'TP Report':
                PopDetail.show("TP Report",<ReportParameters transactionName={'Legacy Data Spatial TP'} reportName={'TP Report'} department={'TP'}/>)
                break
            case 'TIC MATRIX Report':
                PopDetail.show("TIC MATRIX Report",<ReportParameters transactionName={'TIC Process Land Form 1'} reportName={'Matrix Report'} department={'CL'}/>)
                break
            case 'Withdrawn Transactions':
                break
            case 'Staff List':
                break
            case 'Postponed Transactions':
                break
            case 'Payments':
                PopDetail.show(d.name,<PopFinanceTransactions/>,60)
                break

            case 'System User Manual':
                let userManualBase64=API.ax.get("/transaction/user-manual").then(d=>{
                    if(d.data.status===1){
                        console.log("user manual",d.data.result)
                        return d.data.result
                    }else {
                        return ""
                    }

                })
                //console.log("user manual",userManualBase64)
                PopDetail.show("USER MANUAL",<PrintDocumentPane base64={userManualBase64}/>,80)
                break
        }
    }

    showPropertySearch() {

        let transaction = {
            txDefId:87,
            objs:[],
            isNew:true,
            taskName:'Receive Application',
            name:'Property Search'

        }
        TransactionPopup.setState('tx',transaction)
        //TransactionPopup.show(transaction)


        PopDetail.show("Search Property",<PaneSearchPropertyBatch is_shotcut={true}/>)
    }
}
