import * as React from 'react'
import ParcelsPane from '../../../pane/mobile/cro_pane/parceltab';
import PopDetail from '../../popdetail';
import SurveyTab from '../../../pane/mobile/cro_pane/surveytab';
import {TownPlanTab} from '../../../pane/mobile/cro_pane/tp';
import DataExctraction from '../../../pane/mobile/cro_pane/dataextraction';
import MobileReadService from '../../../../services/mobile/mobile_read';
import Wait from '../../wait';
import MobilePopup from '../mobile_popup';
import TPCreateService from '../../../../services/tp/tp_create';
import MobileCreateService from '../../../../services/mobile/mobile_create';
export default function CRODataCollection({tabIndex=0}) {
    const project = MobilePopup.id();
    const [activeTab, setActive] = React.useState(0);
    const [tabHeads, setTabHeads] = React.useState(["Parcel Data", "Survey Information", "TP Information"]);
    const [layers, setLayers] = React.useState([])
    const [curLayer, setCurLayer] = React.useState('all');
    const onLayerChange = (e) => {
        setCurLayer(e.target.value)
    }

    React.useEffect(() => {
        Wait.show("Please Wait ...")
        // MobileReadService.mobileLayers(project, true, 'all').then(r => {
        //     setLayers(r.data.results)
        // })
        Wait.dismiss()
    }, [])
    return (
        <div>
            <div>
                <GetTab tab={tabIndex} curLayer={curLayer} />
            </div>
        </div>
    )
}

function RTKUploadForm({ layers }) {
    const onSubmit = (e) => {
        e.preventDefault();
        var l = document.forms.iif
        var lo= new FormData(l)
        MobileCreateService.addRTK(lo).then((r)=>{
            alert("RTK Data uploaded");
            PopDetail.dismiss();
        })
    }
    return (
        <div>
            <form onSubmit={onSubmit} id="iif">
                <div className='alert alert-warning'><i className='fa fa-exclamation-triangle'></i>&nbsp;Shapefile Format ESPG:4326. </div>
                <label>Layer <font color="#f00">*</font></label>
                <select className='form-control'>
                    <option value={""}>Choose Layer</option>
                    {
                        layers.map((prop, key) => (
                            <option value={prop.id}>{prop.name}</option>
                        ))
                    }
                </select>
                <br />
                <label>Shapefile <font color="#f00">*</font></label>
                <input accept='.zip' type="file" className='form-control ' />
                <br />
                <button type='submit'>Upload Shapefile</button>
            </form>
        </div>
    )
}

function GetTab({ tab, curLayer }) {
    switch (tab) {
        case 0:
            return <ParcelsPane layer={curLayer} />
        case 1:
            return <SurveyTab layer={curLayer} />
        case 2:
            return <TownPlanTab layer={curLayer} />
        default:
            return <TownPlanTab layer={curLayer} />
    }
}
