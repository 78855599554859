import API from "../API";
import 'axios-progress-bar/dist/nprogress.css'
import TransactionPopup from "../../components/popups/transacrions/transaction_popup";

export default class TxReadService {
    static transactionList() {
        return API.ax.get("transaction/list").catch(e => { console.log(e); })
    }

    static transactionDefinition(tid) {
        return API.ax.get(`transaction/definition/${tid}`).catch(e => { console.log(e); })
    }

    static newTransactionScreens(txDefId) {
        return API.ax.get(`transaction/new-transaction-screens/${txDefId}`).catch(e => { console.log(e); })
    }

    static allTransactions(searchby = 'tome', pageindex = 1, noOfRecord = API.no_of_records) {
        return API.ax.get(`transaction/all/${searchby}/${pageindex}/${noOfRecord}`).catch(e => { console.log(e); })
    }

    static acknowledgementReceipt(tid) {
        return API.ax.get("vauation-tabs/acknowledgementReceiptDoc/" + tid).catch(e => { console.log(e); })
    }

    static transactionContents(txId) {
        return API.ax.get("transaction/transaction-contents/" + txId)
            .catch(error => {
                if (error.response) {
                    console.log(error.response.status);
                }
            })
    }

    static getUsers(txId) {

        return API.ax.get("transaction/get-users/" + txId).catch(e => { console.log(e); })
    }

    static getProjects() {
        return API.ax.get("transaction/project/list").catch(e => { console.log(e); })
    }
    static getProjectInfo(pid) {
        return API.ax.get(`transaction/project/${pid}/info`).catch(e => { console.log(e); })
    }

    static getPartTypes() {
        return API.ax.get("/land/party-type").catch(e => { console.log(e); })
    }
    static getRoles() {
        return API.ax.get("/land/party-role").catch(e => { console.log(e); })
    }

    static getLandRentObjs(txId) {
        return API.ax.get("land/landrentobject/" + txId).catch(e => { console.log(e); })
    }
    static calculateRent(baUnitId, txId) {
        return API.ax.get("land/assessment/" + baUnitId + "/" + txId).catch(e => { console.log(e); })
    }
    static readBatchPropertiesRent(txId) {
        return API.ax.get("land/get-batch-properties/" + txId).catch(e => { console.log(e); })
    }
    static readComputedBatchRent(txId) {
        return API.ax.get("land/batch-computed-assessment/" + txId).catch(e => { console.log(e); })
    }
    static sendBillToGepg(txId) {

        return API.ax.get("finance/post-bill/" + txId).catch(e => { console.log(e); })

    }

    static createBillPayment(txId) {
        return API.ax.get("finance/get-bill/" + txId).catch(e => { console.log(e); })
    }

    static generateRentInvoice(txId) {
        return API.ax.get("land/generate-invoice/" + txId).catch(e => { console.log(e); })
    }


    static getCompRates(txId) {

        return API.ax.get("valuation/get-csv-rates/" + txId).catch(e => { console.log(e); })
    }



    static getRegions() {
        return API.ax.get("land/regions/").catch(e => { console.log(e); })

    }

    static getDistrictsByRegId(value) {
        return API.ax.get("land/districts/" + value).catch(e => { console.log(e); })
    }

    static getCouncilsByDistrictId(value) {
        return API.ax.get("land/councils/" + value).catch(e => { console.log(e); })
    }

    static getLocalitiesByCouncilId(value) {
        return API.ax.get("land/locality/" + value).catch(e => { console.log(e); })
    }

    static getWardsByCouncilId(value) {
        return API.ax.get("land/wards/" + value).catch(e => { console.log(e); })
    }

    static getHamletsByWardId(value) {
        return API.ax.get("land/hamlets/" + value).catch(e => { console.log(e); })
    }

    static getSubwardsByWardId(value) {
        return API.ax.get("land/subwards/" + value).catch(e => { console.log(e); })
    }


    static getConditions(id) {
        return API.ax.get("land/conditions/" + id).catch(e => { console.log(e); })
    }

    static getInterest(id) {
        return API.ax.get("land/interest/" + id).catch(e => { console.log(e); })
    }

    static findBaunitIdDetails(baunitId) {
        return API.ax.get("land/baunit/" + baunitId).catch(e => { console.log(e); })
    }

    static getAttachedDocts(txId) {
        return API.ax.get("transaction/documents/" + txId).catch(e => { console.log(e); })
    }

    static getSpatialUnitTransaction(txId) {
        return API.ax.get("transaction/transaction-spatial/" + txId).catch(e => { console.log(e); })
    }

    static getBaunitTransaction(txId) {
        return API.ax.get("transaction/transaction-baunit/" + txId).catch(e => { console.log(e); })
    }

    static getCouncilName(councilId) {
        return API.ax.get("land/councils-by-id/" + councilId).catch(e => { console.log(e); })
    }

    static getLocationName(localityId) {
        return API.ax.get("land/councils-by-locationId/" + localityId).catch(e => { console.log(e); })
    }

    static getCouncils(item) {
        return API.ax.get("land/councils-by-region-username/" + item).catch(e => { console.log(e); })
    }

    static getSpatialUnitTransactionStatus(txId) {
        return API.ax.get("transaction/get-legacy-spatial/" + txId).catch(e => { console.log(e); })
    }

    static getTransactionPaidBill(txId) {
        return API.ax.get("transaction/get-paid-bill/" + txId).catch(e => { console.log(e); })
    }

    static getTransferParameters(id) {
        return API.ax.get("transaction/transaction-get-transfer-parameters/" + id).catch(e => { console.log(e); })
    }

    static getTaskHistory(transactionId) {
        return API.ax.get("transaction/task/" + transactionId).catch(e => { console.log(e); })
    }

    static readComputedRent(txId) {
        return API.ax.get("land/rent/" + txId).catch(e => { console.log(e); })
    }

    static readPropertyTitle(txId) {
        return API.ax.get("land/rent_property/" + txId).catch(e => { console.log(e); })
    }

    static getFeeTypes() {
        return API.ax.get("finance/fee-types").catch(e => { console.log(e); })
    }

    static getRectificationParameter(id) {
        return API.ax.get("transaction/rectification-parameters/" + id).catch(e => { console.log(e); })
    }

    static getUnitTittleParameter(id) {
        return API.ax.get("transaction/unit-tittle-parameter/" + id).catch(e =>
            console.log("unit tittle error on retrieve ", e)
        )

    }
    static readTransactionsByProjectAndType(project, type) {
        return API.ax.get(`transaction/listbyProject/${project}/${type}`)
    }
}
