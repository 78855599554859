import API from "../API";

export default class TicReadService {

    static getShareholderInfo(transactionId) {
        return API.ax.get("tic/shareholder_info/" + transactionId).catch(e => {
            console.log(e);
        })
    }
    static getInvestorInfo(transactionId) {
        return API.ax.get("tic/investor_info/" + transactionId).catch(e => {
            console.log(e);
        })
    }

    static getLandInfo(transactionId) {
        return API.ax.get("tic/land_info/" + transactionId).catch(e => {
            console.log(e);
        })
    }
    static getProjectInfo(transactionId) {
        return API.ax.get("tic/project_info/" + transactionId).catch(e => {
            console.log(e);
        })
    }

    static getGnInfo(transactionId) {
        return API.ax.get("tic/gn_info/" + transactionId).catch(e => {
            console.log(e);
        })
    }
}