/*
* Do not Edit this file
*
* */
import React, { useEffect, useState } from "react";
import Viewer from "bpmn-js/lib/NavigatedViewer";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import API from "../../services/API";
import {bpmn} from './workflows/bpmns'


function BpmnViewer({bpmnfile,currentTask}) {
    const [diagram, diagramSet] = useState("");
    const container = document.getElementById("container");
    useEffect(() => {
        if (diagram.length === 0) {
            console.log("the file",bpmnfile)
            let dia = bpmnfile === "GrantRightofOccupancy"?bpmn.GrantRightOfOccupancy:
                      bpmnfile === "CompensationValuation"?bpmn.CompensationValuation:
                      bpmnfile === "ConductGeneralValuation"?bpmn.ConductGeneralValuation:
                      bpmnfile === "ApproveGeneralValuation"?bpmn.ApproveGeneralValuation:
                      bpmnfile === "ApproveCompensationValuation"?bpmn.ApproveCompensationValuation:
                      bpmnfile === "TPDrawingRegistrationProjectType"?bpmn.TPDrawingRegistrationProjectType:
                      bpmnfile === "ValuationBlocksRatesManagement"?bpmn.ValuationBlocksRatesManagement:
                      bpmnfile === "FirstParcelRegistration"?bpmn.FirstParcelRegistration:
                      bpmnfile === "OfficialSearch"?bpmn.OfficialSearch:
                      bpmnfile === "TransferRightofOccupancy"?bpmn.TransferRO:
                      bpmnfile === "MortgageRightofOccupancy"?bpmn.SubsequentRegistration:
                      bpmnfile === "RenewRightofOccupancy"?bpmn.SubsequentRegistrationCL:
                      bpmnfile === "SurrenderRightofOccupancy"?bpmn.SubsequentRegistrationCL:
                      bpmnfile === "TitleDataMaintenance"?bpmn.TitleDataMaintenance:
                      bpmnfile === "SurveyRequest"?bpmn.SurveyRequest:'';
            diagramSet(dia)
        }
    }, [diagram,bpmnfile]);
    if (diagram.length > 0) {
        const viewer = new Viewer({
            container,
            keyboard: {
                bindTo: document
            },
            additionalModules:[
                {
                    selectionVisuals:["type", function(){}]
                }
            ]
        });
        viewer
            .importXML(diagram)
            .then(({ warnings }) => {
                if (warnings.length) {
                    console.log("Warnings", warnings);
                }

                const elementRegistry = viewer.get("elementRegistry");

                const graphicsFactory = viewer.get("graphicsFactory");

                function setColor(element, stroke, fill) {
                    let businessObject = element.businessObject;

                    businessObject.di.set("stroke", stroke);
                    businessObject.di.set("fill", fill);

                    let gfx = elementRegistry.getGraphics(element);

                    let type = element.waypoints ? "connection" : "shape";

                    graphicsFactory.update(type, element, gfx);
                }
                const element = elementRegistry.get(currentTask);

                setColor(element, "yellow", "#DF4A36");
            })
            .catch((err) => {
                console.log("error", err.message);
            });
    }

    return (
        <div>
            <div
                id="container"
                style={{
                    height: "550px",
                    width: "auto",
                    margin: "auto"
                }}
            />
        </div>
    );
}
export default BpmnViewer;