import API from "../API";

export default class SMDCreateService{

    static addSurveyInstructions(data){
        return API.ax.post(`smd/create/instruction`,data).catch(e=>{console.log(e);})
    }

    static uploadRoughCoordinates(formData) {
        return API.ax.post('smd/create/rough-coordinates',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static uploadSpatial(formData) {
        return API.ax.post('smd/create/layer',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static uploadNewSpatial(formData) {
        return API.ax.post('smd/create/create-new-spatial',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static saveXY(formData){
        return API.ax.post('smd/create/upload-csv-xy-coordinates',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }
    static combine(data){
        return API.ax.post(`smd/create/combine/${data}`).catch(e=>{console.log(e);})
    }


    static uploadRegions(formData) {
        return API.ax.post('smd/create/regions',formData,{
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }).catch(e=>{console.log(e);})
    }

    static attachInstruction(param) {
        return API.ax.post(`smd/create/attach/sr`,param).catch(e=>{console.log(e);})
    }


    static saveSmdAcceptance(data) {
        return API.ax.post("smd/create/smdApproval",data).catch(e=>{console.log(e);})
    }

    static addSurveyInstructionTransaction(dataToSend) {
        return API.ax.post("smd/create/survey-instruction-transaction",dataToSend).catch(e=>{console.log(e);})
    }
}