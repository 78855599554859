import React, {Component} from 'react';
import TxCreateService from "../../../../../services/transaction/transaction_create";
import TransactionPopup from "../../transaction_popup";
import TxReadService from "../../../../../services/transaction/transaction_read";
import Wait from "../../../wait";
import wait from "../../../wait";

class Transfer extends Component {
    constructor(props) {
        super(props);

        this.state={
            "consideration":'',
            "propertyValue":'',
        }

        this.handleChange =this.handleChange.bind(this)
        this.saveValuation =this.saveValuation.bind(this)
    }

    handleChange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }

    componentDidMount() {

            Wait.show("Load parameters","Load parameters . . . ")
        try {
            TxReadService.getTransferParameters(TransactionPopup.id()).then(r => {
                console.log("transfer parameters " + r)
                if (r.data.status===1) {
                    console.log("transfer parameters " + r)
                    console.log("transfer data " + r.data.consideration)
                    console.log("transfer data " + r.data.propertyValue)
                    this.setState({
                        "consideration": r.data.consideration,
                        "propertyValue": r.data.propertyValue
                    })
                    wait.dismiss()
                }else {
                    wait.dismiss()
                }


            })
        }catch (e) {
            console.log(e)
        }
    }
    saveValuation=()=>{
        let valuationDto={
            "consideration":this.state.consideration,
            "propertyValue":this.state.propertyValue,
            "txId":TransactionPopup.id()
        }
        TxCreateService.saveTransferParameters(valuationDto).then(d=>{

        })
    }

    render() {
        return (
            <div>
<h2>Parameters</h2>
                <div style={{width:'50%',float:"left"}}>
                   <span style={{color:'red'}}>*</span>Consideration
                    <input style={{width:'50%',paddingLeft:'5px',height:'20px'}} name={'consideration'} defaultValue={this.state.consideration} onChange={this.handleChange} />
                    <span style={{color:'red'}}>*</span>Property Value
                    <input style={{width:'50%',paddingLeft:'5px',height:'20px'}} name={'propertyValue'} defaultValue={this.state.propertyValue} onChange={this.handleChange}/>
                    <span style={{width:'100%', backgroundColor:'red' ,color:'white',cursor:'pointer'}} onClick={this.saveValuation}>Save</span>
                </div>
            </div>
        );
    }
}

export default Transfer;
