import React, {Component} from 'react';
import PopDetails from "../../../../componentss/popdetails";
import SearchReceiptDetails from "./pane_search_receipt_details";
import TxReadService from "../../../../services/transaction/transaction_read";
import TransactionPopup from "../transaction_popup";


class PaneControlNumberSelection extends Component {
    static self = null;
    constructor(props) {
        super(props);

        this.state={
          receiptDetails:null
        }
        PaneControlNumberSelection.self = this;
        this.searchCtrolNo= this.searchCtrolNo.bind(this)
    }

    componentDidMount() {
        TxReadService.getTransactionPaidBill(TransactionPopup.id()).then(d=>{

            if(d.data.status===1){
                this.setState({receiptDetails:d.data.result})
            }else {

            }
        })
    }

    searchCtrolNo=()=>{
        PopDetails.show("Search Receipt Details",<SearchReceiptDetails/>,60)
    }




    render() {
        return (
            <div>
                <div>
                    <table className={'tb2'} style={{width:'100%'}}><thead><th>Bill Details</th><th></th><th>{this.state.receiptDetails?"":<button onClick={this.searchCtrolNo}>Search control No/Receipt No</button>}</th></thead>
                    <tbody>
                    <tr style={{backgroundColor:"gray",color:'white'}}>
                        <td><b>Control Number</b>:</td><td><b>{this.state.receiptDetails?this.state.receiptDetails['controlNo']:""}</b></td><td></td>
                    </tr>
                    <tr style={{color:'gray'}}>
                        <td><b>GEPG Reference</b>:</td><td><b>{this.state.receiptDetails?this.state.receiptDetails['gepgRef']:""}</b></td><td></td>
                    </tr>
                    <tr style={{backgroundColor:"gray",color:'white'}}>
                        <td><b>Receipt No</b>:</td><td><b>{this.state.receiptDetails?this.state.receiptDetails['receiptNo']:""}</b></td><td></td>
                    </tr>
                    <tr style={{color:'gray'}}>
                        <td><b>Payer</b>:</td><td><b>{this.state.receiptDetails?this.state.receiptDetails['payer']:""}</b></td><td></td>

                    </tr>
                    <tr style={{backgroundColor:"gray",color:'white'}}>
                        <td><b>Amount</b>:</td><td><b>{this.state.receiptDetails?this.state.receiptDetails['amount']:""}</b></td><td></td>

                    </tr>
                    <tr style={{color:'gray'}}>
                        <td><b>Bill Reference</b>:</td><td><b>{this.state.receiptDetails?this.state.receiptDetails['billNumber']:""}</b></td><td></td>

                    </tr>
                    <tr style={{backgroundColor:"gray",color:'white'}}>
                        <td><b>Transaction No</b>:</td><td><b>{this.state.receiptDetails?this.state.receiptDetails['transactionNo']:""}</b></td><td></td>

                    </tr>
                    <tr style={{color:'gray'}}>
                        <td><b>Paid Date</b>:</td><td>{this.state.receiptDetails?this.state.receiptDetails['payDate']:""}</td><td></td>

                    </tr>
                    </tbody></table>
                </div>
                <PopDetails/>
            </div>
        );
    }
}

export default PaneControlNumberSelection;
