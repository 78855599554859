import API from "../API";

export default class CommonCreateService {

    static createPreviewSHP(formData) {return API.ax.post(`common/create/preview/shp`,formData,{
        headers: { 'Content-Type': 'multipart/form-services'},
        onUploadProgress: function(progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log(percentCompleted)
        },
    }).catch((e)=>{console.log(e);})}

    static createQueue(formData) {return API.ax.post(`common/create/queue`,formData,{
        headers: { 'Content-Type': 'multipart/form-services'},
        onUploadProgress: function(progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log(percentCompleted)
        },
    }).catch((e)=>{console.log(e);})}

    static downloadShapefile(formData) { return API.ax.post(`common/create/download/shp`,formData).catch((e)=>{console.log(e);})}

    static getFile(url) {

        return API.ax.get(url, {
            responseType: 'blob',
            'Content-Type': 'application/octet-stream', // or Content-type: "application/vnd.ms-excel"
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            },
            onDownloadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted)
            }
        }).catch((e)=>{console.log(e);})
    }

    static saveForm(data){
        return API.ax.post('common/form/save',data,{
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}
