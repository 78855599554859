import React from "react";
import TxCreateService from "../../../../services/transaction/transaction_create";
import Wait from "../../wait";
import PopDetailsTwo from "../../PopDetailsTwo";
import TransactionPopup from "../transaction_popup";
import PaneControlNumberSelection from "./pane_control_number_selection";
import PopDetails from "../../../../componentss/popdetails";

export default class SearchReceiptDetails extends React.Component
{

    constructor(props) {
        super(props);
        this.state= {
            enteredNumber: '',
            type: 'controlNo',
            message: '',
            paidBill: null
        }



        this.getBillDetails= this.getBillDetails.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.savePaidBill = this.savePaidBill.bind(this)
    }
    getBillDetails=()=>{
        let dataToSend={
            enteredNumber:this.state.enteredNumber,
            type:this.state.type,
        }
            console.log("data to get paid bill",dataToSend)
        Wait.show("Getting Paid Bill.... ")
        TxCreateService.getPaidBill(dataToSend).then(d=>{
            if(d.data.status===1){
                Wait.dismiss()
                this.setState({paidBill:d.data.result})
            }else {
                Wait.dismiss()
                this.setState({message:d.data.message})
                PopDetailsTwo.show("Warning",<div><p>{this.state.message}</p></div>,20)
            }
        })


    }
    handleChange=e=>{
console.log("change value",e.target.value)
        this.setState({[e.target.name]:e.target.value})
    }
    savePaidBill(){
        let dataToSave={
            transactionId:TransactionPopup.id(),
            billPaymentId:this.state.paidBill.billPaymentId
        }
        Wait.show("Saving .....")
        TxCreateService.saveTransactionPaidBill(dataToSave).then(d=>{

            if(d.data.status===1){
                PaneControlNumberSelection.self.setState({receiptDetails:this.state.paidBill})
                Wait.dismiss()
                PopDetails.dismiss()
            }else {
                Wait.dismiss()
                PopDetails.dismiss()
            }
        })

    }

    render() {


        return (
            <div >
                <div style={{float:"left",width:'100%'}}>

                <input  name={ "enteredNumber"} placeholder={'Enter Number'} onChange={this.handleChange}/>
                <select name={"type"} onChange={this.handleChange}>
                    <option value={'controlNo'}>Control No</option>
                    <option value={'receiptNo'}>Receipt No</option>
                </select>
                <button onClick={this.getBillDetails}>Search</button>
            </div>
                {this.state.paidBill?<div style={{float:"left",width:'100%'}}>
                    <div style={{float:"left",width:'50%'}}><table>
                        <thead><tr><th>Bill</th><th></th></tr></thead>
                        <tbody>
                        <tr><td>Control Number:</td><td>{this.state.paidBill.controlNo}</td></tr>
                        <tr><td>GEPG Reference:</td><td>{this.state.paidBill.gepgRef}</td></tr>
                        <tr><td>Receipt Number:</td><td>{this.state.paidBill.receiptNo}</td></tr>
                        <tr><td>Payer :</td><td>{this.state.paidBill.payer}</td></tr>
                        <tr><td>Amount Paid:</td><td>{this.state.paidBill.amount}</td></tr>
                        <tr><td>Received From:</td><td>{this.state.paidBill.via}</td></tr>
                        <tr><td>Bill Reference:</td><td>{this.state.paidBill.billNumber}</td></tr>
                        <tr><td>Transaction Number:</td><td>{this.state.paidBill.transactionNo}</td></tr>
                        <tr><td>Paid Date:</td><td>{this.state.paidBill.payDate}</td></tr>
                    </tbody>
                     </table>
                    </div>
                    <div  style={{float:"left",width:'50%'}}>
                    <table><thead><tr><th>Items</th><th>Amount</th></tr></thead>
                        <tbody>{this.state.paidBill.items.map((item,i)=>{
                            return(<tr key={i}><td>{item.name}</td><td>{item.amount}</td></tr>)
                        })}</tbody></table></div>

                </div>:""}
                {this.state.paidBill?<div  style={{float:"left",width:'100%'}}><hr/></div>:""}
                {this.state.paidBill?<div style={{float:"left",width:'100%'}}><button onClick={this.savePaidBill}>Add</button><button>close</button></div>:""}
            </div>
        );
    }

}
