import React, {useState} from "react";
import TransactionPopup from "../../transaction_popup";
import API from "../../../../../services/API";
import PopDetail from "../../../popdetail";
import {styles} from "../record-request";
import Wait from "../../../wait";

export default class TheRelatedInterest extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            relatedInterest:[],
            selectedInterest:{}
        }
        this.addInterest =this.addInterest.bind(this)
        this.saveSelectedInterest =this.saveSelectedInterest.bind(this)
    }

    componentDidMount() {
        API.ax.get(`/land/interest-related/${TransactionPopup.id()}`)
            .then(d=>{
                console.log("RELATED INTEREST===>",d)
                if( d.data.status === 1){
                this.setState({relatedInterest:d.data.result})
                }
                TransactionPopup.dismiss()
            })
            .catch(e=>{
                console.log(e)
            })
    }
    addInterest=(d)=>{

this.setState({selectedInterest:d})
}
    saveSelectedInterest=()=>{
        const dataSend={
            transactionId:TransactionPopup.id(),
            relatedInterest: this.state.selectedInterest,
        }
        console.log("SELECTED INTEREST",dataSend)
        Wait.show("Saving Related Interest....")
        API.ax.post("/land/interest/related",dataSend)
            .then(d=>{
                const{status, result} = d.data
                if(status === 1){
                    Wait.dismiss()
                   // interestSet(result)
                    //addInterestSet(false)
                }
            })
            .catch(e=>{
                console.log(e)
            })
        PopDetail.dismiss()


    }

    render() {
        return (
            <div>
                <table className={'tb'} style={styles.table}>
                    <thead>
                    <tr><th></th>
                        <th>INTEREST</th>
                        <th>TENANCY TYPE</th>
                        <th>TERM</th>
                        <th>COMMENCEMENT DATE</th>
                        <th>END DATE</th>
                        <th>DOCUMENT</th>
                        <th>TITLES</th>
                        <th>STATUS</th>
                        <th>FD NUMBER</th>
                        <th>IID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.relatedInterest.map((d,i) => {
                            return (<tr key={i}>
                                <td><input type={'checkbox'} onClick={()=>this.addInterest(d)}/></td>
                                <td>{d.rrrType}</td>
                                <td>{d.tenancyType}</td>
                                <td>{d.term}</td>
                                <td>{d.startDate}</td>
                                <td>{d.endDate}</td>
                                <td>{d.document}</td>
                                <td>{d.titles}</td>
                                <td>{d.status}</td>
                                <td>{d.fdNumber}</td>
                                <td>{d.iid}</td>
                            </tr>)
                        })

                    }
                    </tbody>
                </table>
                <div><button onClick={this.saveSelectedInterest}>Save</button></div>
            </div>
        )
    }
}




