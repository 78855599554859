import * as React from "react";
import MobileReadService from "../../../../../services/mobile/mobile_read";
import Wait from "../../../wait";
import PopDetail from "../../../popdetail";
import Message from "../../../transacrions/screen/gro/person/message";
import MobileDeleteService from "../../../../../services/mobile/mobile_delete";
import MobileUpdateService from "../../../../../services/mobile/mobile_update";
import PopDetailsTwo from "../../../PopDetailsTwo";
import NidaValidation from "../../../../../componentss/nidavalidation";

export default class PaneOwnersInfo extends React.Component {
  static self = null;
  constructor(props) {
    super(props);
    this.state = {
      owners: [],
      page_status: "Loading...",
      inst_name: "",
      inst_typ: "",
      inst_sign1: "",
      inst_design1: "",
      inst_sign2: "",
      inst_design2: "",
      cheti: undefined,
      reg_date: "",
      viewImage: false,
      viewId: false,
      nin: "",
      currentOwner: "",
      viewNin: false,
      ownerData: {}
    };

    PaneOwnersInfo.self = this;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.update = this.update.bind(this);
    this.updateInstitution = this.updateInstitution.bind(this);
  }

  handleChange = (e) => {
    // alert(e.target.name)
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChangeFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  componentDidMount() {
    PaneOwnersInfo.loadData();
  }

  update(id) {
    try {
      Wait.show("Updating...");
      MobileUpdateService.updateParcelOwner({
        id: id,
        pid: this.props.parcel.id,
        fullname: document.getElementById("fullname" + id).value,
        jinsia: document.getElementById("jinsia" + id).value,
        dob: document.getElementById("dob" + id).value,
        marital: document.getElementById("marital" + id).value,
        nation: document.getElementById("nation" + id).value,
        city: document.getElementById("city" + id).value,
        idno: document.getElementById("idcard" + id).value,
        acquire: document.getElementById("acquire" + id).value,
        disabled: document.getElementById("disabled" + id).value,
        phone: document.getElementById("phone" + id).value,
        email: document.getElementById("email" + id).value,
        slp: document.getElementById("slp" + id).value,
      }).then((r) => {
        Wait.dismiss();
        if (r.data.status === 0) {
          PopDetail.show(
            "Warning",
            <Message faIcon={"harzad"} message={r.data.message} />,
            30
          );
        }

        try {
          let pic = document.getElementById("pic" + id).files[0];
          let id_pic = document.getElementById("id" + id).files[0];
          //let sign = document.getElementById('sign'+id).files[0]

          if (pic === undefined) {
            pic = null;
          }
          if (id_pic === undefined) {
            id_pic = null;
          }
          //if (sign===undefined){ sign=null }

          const formData = new FormData();
          formData.append("picture", pic);
          formData.append("id_photo", id_pic);
          //formData.append('sign',sign)

          formData.append(
            "extra",
            JSON.stringify({
              id: id,
            })
          );

          if ((null === pic) === id_pic) {
            return;
          }
          Wait.show("Uploading Pictures...");
          MobileUpdateService.updatePictures(formData).then((r) => {
            try {
              console.log(r);
              Wait.dismiss();
              PaneOwnersInfo.loadData();
            } catch (e) {
              console.log(e);
            }
          });
        } catch (e) {
          console.log(e);
        }
      });
    } catch (e) {}
  }

  deleteOwner(id) {
    Wait.show("Deleting...");
    try {
      MobileDeleteService.deleteOwner(id).then((r) => {
        try {
          console.log(r.data);
          Wait.dismiss();
          if (r.data.status === 0) {
            alert(r.data.message);
            return;
          }
          PopDetail.dismiss();
        } catch (e) {
          console.log(e);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  delete(d) {
    PopDetail.confirm(
      "Futa Mmiliki",
      d.fullname + " Ataondolewa kwenye kanzi data",
      this.deleteOwner,
      d.id,
      this.noHandler,
      30
    );
  }

  noHandler() {
    PopDetail.dismiss();
  }
  static loadData() {
    try {
      MobileReadService.readParcelOwners(
        PaneOwnersInfo.self.props.parcel.id
      ).then((r) => {
        try {
          PaneOwnersInfo.self.setState({
            owners: r.data.owners,
            page_status: "",
            inst_name: r.data.inst_name,
            inst_typ: r.data.inst_typ,
            inst_sign1: r.data.inst_sign1,
            inst_design1: r.data.inst_design1,
            inst_sign2: r.data.inst_sign2,
            inst_design2: r.data.inst_design2,
            cheti: r.data.cheti,
            reg_date: r.data.reg_date,
          });
        } catch (e) {
          console.log(e);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  updateOwner() {}
  updateInstitution() {
    try {
      let inst_name = this.state.inst_name.toString().trim();
      let inst_type = this.state.inst_type.toString().trim();
      let inst_sign1 = this.state.inst_sign1.toString().trim();
      let inst_design1 = this.state.inst_design1.toString().trim();
      let inst_sign2 = this.state.inst_sign2.toString().trim();
      let inst_design2 = this.state.inst_design2.toString().trim();
      let cheti = this.state.cheti;
      let reg_date = this.state.reg_date;

      if (inst_name.length < 2) {
        PopDetailsTwo.show(
          "Warning",
          <Message
            faIcon={"harzad"}
            message={"No Institution Name"}
            action={1}
          />,
          30
        );
        return;
      }
      if (inst_type.length < 2) {
        PopDetailsTwo.show(
          "Warning",
          <Message
            faIcon={"harzad"}
            message={"No Institution Type"}
            action={1}
          />,
          30
        );
        return;
      }
      if (inst_sign1.length < 2) {
        PopDetailsTwo.show(
          "Warning",
          <Message faIcon={"harzad"} message={"No Signatory 1"} action={1} />,
          30
        );
        return;
      }
      if (inst_design1.length < 2) {
        PopDetailsTwo.show(
          "Warning",
          <Message faIcon={"harzad"} message={"No Designation 1"} action={1} />,
          30
        );
        return;
      }
      if (inst_sign2.length < 2) {
        PopDetailsTwo.show(
          "Warning",
          <Message faIcon={"harzad"} message={"No Signatory 2"} action={1} />,
          30
        );
        return;
      }
      if (inst_design2.length < 2) {
        PopDetailsTwo.show(
          "Warning",
          <Message faIcon={"harzad"} message={"No Designation 2"} action={1} />,
          30
        );
        return;
      }
      if (reg_date.length < 2) {
        PopDetailsTwo.show(
          "Warning",
          <Message
            faIcon={"harzad"}
            message={"No Registration Date"}
            action={1}
          />,
          30
        );
        return;
      }

      if (cheti === undefined || cheti === null) {
        PopDetailsTwo.show(
          "Warning",
          <Message
            faIcon={"harzad"}
            message={"No Institution Registration Certificate"}
            action={1}
          />,
          30
        );
        return;
      }

      const formData = new FormData();
      formData.append("cheti", cheti);

      formData.append(
        "extra",
        JSON.stringify({
          parcel: this.props.parcel.id,
          inst_name: inst_name,
          inst_type: inst_type,
          inst_sign1: inst_sign1,
          inst_design1: inst_design1,
          inst_sign2: inst_sign2,
          inst_design2: inst_design2,
          reg_date: reg_date,
        })
      );

      Wait.show("Updating Parcel...");
      MobileUpdateService.updateInstitution(formData).then((r) => {
        Wait.dismiss();
        PaneOwnersInfo.loadData();
      });

      console.log(formData);
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    return (
      <div
        className={"col col-sm-12"}
        style={{ height: "90%", width: "100%", overflow: "auto" }}
      >
        <p>{this.state.page_status}</p>
        {this.state.viewNin ? (
          <NidaValidation
            nin={this.state.nin}
            owner={this.state.currentOwner}
            data={this.state.ownerData}
            close={{
              close: () => {
                this.setState({ viewNin: false });
              },
              update: () => {
                this.update(this.state.currentOwner);
              },
            }}
          />
        ) : null}
        {this.props.parcel.occupancy.includes("Taasisi") ? (
          <table style={{ width: "100%", marginTop: "24px" }}>
            <tbody>
              <tr>
                <td style={{ color: "darkcyan" }}>Jina la Taasisi</td>
                <td style={{ color: "darkcyan" }}>Aina ya Taasisi</td>
              </tr>
              <tr>
                <td>
                  <input
                    type={"text"}
                    style={{ width: "100%" }}
                    name={"inst_name"}
                    onChange={this.handleChange}
                    defaultValue={this.state.inst_name}
                  />
                </td>
                <td>
                  <select
                    style={{ width: "100%" }}
                    name={"inst_type"}
                    onChange={this.handleChange}
                    defaultValue={this.state.inst_type}
                  >
                    <option value={""}>- Chagua Moja -</option>
                    <option>Private Institution</option>
                    <option>Government Institution</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td style={{ color: "darkcyan" }}>
                  Jina la Msimamizi wa Kwanza
                </td>
                <td style={{ color: "darkcyan" }}>
                  Cheo Cha Msimamizi wa Kwanza
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type={"text"}
                    style={{ width: "100%" }}
                    name={"inst_sign1"}
                    onChange={this.handleChange}
                    defaultValue={this.state.inst_sign1}
                  />
                </td>
                <td>
                  <input
                    type={"text"}
                    style={{ width: "100%" }}
                    name={"inst_design1"}
                    onChange={this.handleChange}
                    defaultValue={this.state.inst_design1}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ color: "darkcyan" }}>Jina la Msimamizi wa Pili</td>
                <td style={{ color: "darkcyan" }}>
                  Cheo Cha Msimamizi wa Pili
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type={"text"}
                    style={{ width: "100%" }}
                    name={"inst_sign2"}
                    onChange={this.handleChange}
                    defaultValue={this.state.inst_sign2}
                  />
                </td>
                <td>
                  <input
                    type={"text"}
                    style={{ width: "100%" }}
                    name={"inst_design2"}
                    onChange={this.handleChange}
                    defaultValue={this.state.inst_design2}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ color: "darkcyan" }}>Tarehe ya Usajili</td>
                <td style={{ color: "darkcyan" }}>Cheti Cha Usajili</td>
              </tr>
              <tr>
                <td>
                  <input
                    type={"date"}
                    style={{ width: "100%" }}
                    name={"reg_date"}
                    onChange={this.handleChange}
                    defaultValue={this.state.reg_date}
                  />
                </td>
                <td>
                  <input
                    type={"file"}
                    style={{ width: "100%" }}
                    name={"cheti"}
                    onChange={this.handleChangeFile}
                    accept={".pdf,.PDF"}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <button
                    className={"refresh"}
                    onClick={this.updateInstitution}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table style={{ width: "100%", marginTop: "24px" }}>
            <tbody>
              <tr>
                {this.state.owners.map((d, i) => {
                  return (
                    <td key={i}>
                      <table style={{ marginRight: "24px" }}>
                        <tbody>
                          <tr>
                            <td />
                            <td>
                              {/* <NidaValidation
                                nidaid={"fullname" + d.id}
                                idss={"ninview" + i}
                                nin={d.id_no}
                                data={d}
                                close={{
                                  close: () => {
                                    document.getElementById(
                                      "ninview" + i
                                    ).style.display = "none";
                                  },
                                  update: () => {
                                    this.update(d.id);
                                  },
                                }}
                              /> */}
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div
                                        id={"dp_" + i}
                                        onClick={() => {
                                          showHideImage("dp_" + i, false);
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          position: "fixed",
                                          top: 0,
                                          left: 0,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          zIndex: 100000000000000000,
                                          backgroundColor: "#fff",
                                          display: "none",
                                        }}
                                      >
                                        <img
                                          onClick={() => {}}
                                          src={
                                            "data:image/png;base64, " +
                                            d.dp_photo
                                          }
                                          style={{ height: "380px" }}
                                          alt={""}
                                        />
                                        {/* <img src={'data:image/png;base64, ' + d.dp_photo} style={{ height: '380px', width: "auto", border: "2px solid black" }} alt={''} /> */}
                                      </div>
                                      <img
                                        onClick={() => {
                                          showHideImage("dp_" + i, true);
                                        }}
                                        src={
                                          "data:image/png;base64, " + d.dp_photo
                                        }
                                        style={{ height: "80px" }}
                                        alt={""}
                                      />
                                    </td>
                                    <td>
                                      <div
                                        id={"id_" + i}
                                        onClick={() => {
                                          showHideImage("id_" + i, false);
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          position: "fixed",
                                          top: 0,
                                          left: 0,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          zIndex: 100000000000000000,
                                          backgroundColor: "#fff",
                                          display: "none",
                                        }}
                                      >
                                        <img
                                          src={
                                            "data:image/png;base64, " +
                                            d.id_path
                                          }
                                          style={{
                                            height: "380px",
                                            width: "auto",
                                            border: "2px solid black",
                                          }}
                                          alt={""}
                                        />
                                      </div>
                                      <img
                                        onClick={() => {
                                          showHideImage("id_" + i, true);
                                        }}
                                        src={
                                          "data:image/png;base64, " + d.id_path
                                        }
                                        style={{ height: "80px" }}
                                        alt={""}
                                      />
                                      {/* <img onClick={() => { showHideImage('id_' + i, false) }} src={'data:image/png;base64, ' + d.id_path} style={{ height: '80px' }} alt={''} /> */}
                                    </td>
                                    {/*<td>
                                                        <img src={'data:image/png;base64, '+d.dp_sign} style={{height:'80px'}} alt={''}/>
                                                    </td>*/}
                                  </tr>
                                  <tr>
                                    <td style={{ color: "darkcyan" }}>
                                      Picture
                                      <br />
                                      <input
                                        type={"file"}
                                        style={{ width: "100px" }}
                                        id={"pic" + d.id}
                                        onChange={this.handleChangeFile}
                                        accept={".png,.PNG"}
                                      />
                                    </td>
                                    <td style={{ color: "darkcyan" }}>
                                      Id
                                      <br />
                                      <input
                                        type={"file"}
                                        style={{ width: "100px" }}
                                        id={"id" + d.id}
                                        onChange={this.handleChangeFile}
                                        accept={".png,.PNG"}
                                      />
                                    </td>
                                    {/* <td style={{color:'darkcyan'}}>Signature<br/>
                                                        <input type={'file'} style={{width:'100px'}} id={'sign'+d.id} onChange={this.handleChangeFile} accept={".png,.PNG"}/>
                                                    </td>*/}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>Mhusika ni :</td>
                            <td>
                              <input
                                style={{ width: "100%" }}
                                name={"mhusika"}
                                defaultValue={d.mhusika}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "darkcyan" }}>Full Name :</td>
                            <td>
                              <input
                                style={{ width: "100%" }}
                                id={"fullname" + d.id}
                                defaultValue={d.fullname}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "darkcyan" }}>Gender :</td>
                            <td>
                              <input
                                style={{ width: "100%" }}
                                id={"jinsia" + d.id}
                                defaultValue={d.jinsia}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "darkcyan" }}>
                              Date of Birth :
                            </td>
                            <td>
                              <input
                                type={"date"}
                                style={{ width: "100%" }}
                                id={"dob" + d.id}
                                defaultValue={d.dob}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "darkcyan" }}>
                              Identification Card :
                            </td>
                            <td>
                              <span>
                                <input
                                  type={"text"}
                                  name="idcardno"
                                  style={{ width: "80%" }}
                                  id={"idcard" + d.id}
                                  defaultValue={d.id_no}
                                  onChange={this.handleChange}
                                />
                              </span>
                              <span>
                                <div
                                  onClick={() => {
                                    this.setState({
                                      nin: d.id_no,
                                      currentOwner: d.id,
                                      viewNin: true,
                                      ownerData: d
                                    });
                                  }}
                                  style={{
                                    padding: 3,
                                    backgroundColor: "#00897f",
                                    display: "inline-block",
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                  }}
                                >
                                  <i
                                    className="fa fa-eye"
                                    style={{ color: "#fff" }}
                                  ></i>
                                </div>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "darkcyan" }}>
                              Identification Card Type :
                            </td>
                            <td>
                              <input
                                type={"text"}
                                style={{ width: "100%" }}
                                id={"idcard" + d.id}
                                defaultValue={d.id_type}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "darkcyan" }}>Marital :</td>
                            <td>
                              <input
                                style={{ width: "48%" }}
                                id={"marital" + d.id}
                                defaultValue={d.marital}
                                onChange={this.handleChange}
                              />
                              <input
                                placeholder={"Nationality"}
                                style={{ width: "48%", float: "right" }}
                                id={"nation" + d.id}
                                defaultValue={d.nation}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td style={{ color: "darkcyan" }}>City :</td>
                            <td>
                              <input
                                style={{ width: "48%" }}
                                id={"city" + d.id}
                                defaultValue={d.city}
                                onChange={this.handleChange}
                              />
                              <input
                                placeholder={"Acquire"}
                                style={{ width: "48%", float: "right" }}
                                id={"acquire" + d.id}
                                defaultValue={d.acquire}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "darkcyan" }}>Disabled :</td>
                            <td>
                              <input
                                style={{ width: "100%" }}
                                id={"disabled" + d.id}
                                defaultValue={d.disabled}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td style={{ color: "darkcyan" }}>Phone No :</td>
                            <td>
                              <input
                                style={{ width: "48%" }}
                                id={"phone" + d.id}
                                defaultValue={d.phone}
                                onChange={this.handleChange}
                              />
                              <input
                                type={"email"}
                                placeholder={"Email"}
                                style={{ width: "48%", float: "right" }}
                                id={"email" + d.id}
                                defaultValue={d.email}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: "darkcyan" }}>S.L.P :</td>
                            <td>
                              <input
                                style={{ width: "100%" }}
                                id={"slp" + d.id}
                                defaultValue={d.slp}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                          {/*<tr>
                                    <td>Aina ya Kitambulisho :</td>
                                    <td><input style={{width:'100%'}} name={'id_type'} defaultValue={d.id_type}/></td>
                                </tr>
                                <tr>
                                    <td>Namba ya Kitambulisho :</td>
                                    <td><input style={{width:'100%'}} name={'id_no'} defaultValue={d.id_no}/></td>
                                </tr>*/}

                          <tr>
                            <td />
                            <td>
                              <button
                                className={"refresh"}
                                onClick={() => this.update(d.id)}
                              >
                                Update
                              </button>
                              <button onClick={() => this.delete(d)}>
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

function showHideImage(image, show) {
  document.getElementById(image).style.display = show ? "flex" : "none";
}
