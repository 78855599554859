import React from 'react';
import Title from "./title";
import DeedTabNav from "./deedTabNav";
import DeedTab from "./deedTab";
import Official from "./official-object";
import GeneralSelect from "../../../../../utils/GeneralSelect";
import PopDetailsTwo from "../../../PopDetailsTwo";
import PopDetail from "../../../popdetail";
import TransactionPopup from "../../transaction_popup";
import Wait from "../../../wait";
import TxCreateService from "../../../../../services/transaction/transaction_create";
import LegacyDocumentsPane from "../../../mobile/pops/legacy/legacy_documents";
import DocumentsPane from "../documents";
import {AddOfficialDetails} from "./official-object"


const options ={
    'endpoint':'transaction/deed-types',
    'value':'deedTypeId',
    'label':'name',
    'placeholder':'Select Deed Type'
}

class DeedTittle extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            selected : 'Deed',
            deedTypeId:'',
        }

        this.setSelected = this.setSelected.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.addDeed = this.addDeed.bind(this)

    }

    setSelected = (tab) => {
        this.setState({
            selected: tab
        });
    }

    handleSelectChange = event =>{
        this.setState({"deedTypeId": event.value})
        console.log('deedType', this.state.deedTypeId+event.value)
    }

    render() {
        return (
            <div>
                <DeedTabNav tabs={['Deed', 'Transaction Objects']} selected={ this.state.selected } setSelected={ this.setSelected }>
                    <DeedTab isSelected={ this.state.selected === 'Deed' }>
                        <div>

                            <div style={{height:'100%'}} className={'col col-12'}>
                                <div className={'row'}>
                                    <div className={'col col-sm-9'}>
                                        <table style={{width:'80%'}}>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <span>Deed Type</span>
                                                    <GeneralSelect onChanges={this.handleSelectChange} parameters={options}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <div className={'col col-sm-2'} style={{textAlign:'bottom',width:'100%',padding: '50px 0px 0px 0px'}}>
                                            <button style={{width:'60px', height: '40px', padding: '0px 0px 0px 0px'}} onClick={this.addDeed}>Save</button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </DeedTab>
                    <DeedTab isSelected={ this.state.selected === 'Transaction Objects' }>
                        {this.state.selected === 'Transaction Objects' ?  <Official writable={this.props.writable} /> : ''}
                    </DeedTab>
                </DeedTabNav>
            </div>
        );
    }

    addDeed(){

        let data = {
            transactionId: TransactionPopup.id(),
            deedTypeId: this.state.deedTypeId
        }

        try {
            Wait.show("Saving...")
            console.log('data to save', data)
            TxCreateService.addDeed(data).then(d=>{
                Wait.dismiss()
            })
        }catch (e){
            console.log(e)
        }
    }
}

export default DeedTittle;

