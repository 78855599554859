import * as React from "react";

import PopDetail from "../../popdetail";
import MobileReadService from "../../../../services/mobile/mobile_read";
import MobilePopup from "../mobile_popup";
import Wait from "../../wait";
import UgSpatial from "../../../maps/ug_spatial";
import API from "../../../../services/API";
import PopParcel from "../pops/pane_pop_parcel";
import uncheck from "../../../../../resource/img/checkbox/checkbox_unchecked_16.png";
import check from "../../../../../resource/img/checkbox/checkbox_checked_16.png";
import * as LF from "leaflet";
import Basemap from "../../../maps/basemap";
import Message from "../../transacrions/screen/gro/person/message";

export default class DataProcessingPane extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            parcels:[],
            layers:[],
            layers_list:[],
            page:0,
            stages:['Mobile Data','Rejected','GIS Approval','ALO Approval','Registered','Printed','','',''],
            total_pages:1,
            current_tab:this.props.tab,
            actions: [
                {name:'Request Control Number',id:3}
            ],
            tabs:[],
            description_title:'Data Processing'
        }

        this.loadParcels = this.loadParcels.bind(this)
        this.loadLocality = this.loadLocality.bind(this)
        this.toggleCheck = this.toggleCheck.bind(this)
        this.downloadData = this.downloadData.bind(this)
        this.searchByClaimNo = this.searchByClaimNo.bind(this)
        this.loadDataInLayer = this.loadDataInLayer.bind(this)
        this.handleChangeDataType = this.handleChangeDataType.bind(this)
        this.download = this.download.bind(this)
        this.loadDataAt = this.loadDataAt.bind(this)
    }

    handleChange= e =>{this.setState({[e.target.name]: e.target.value});}
    handleChangeFile=e=>{this.setState({ coordinate:e.target.files[0] })}
    handleChangeDataType=e=>{this.loadParcels(parseInt(e.target.value))}

    download(d){

    }

    loadDataInLayer=e=> {
        this.setState({[e.target.name]: e.target.value});

        let layer = e.target.value

        try {
            this.state.list_layers.forEach(it=>{

                if(parseInt(it.id.split('.')[1])===parseInt(layer)){

                    console.log(it.layer.getBounds())

                    try {
                        this.state.map.fitBounds(it.layer.getBounds())
                        //this.state.map.setView(it.layer.getBounds().getCenter(),100);
                    }catch (e) {
                        console.log(e)
                    }
                }
            })
        }catch (e) {
            console.log(e)
        }

    }

    searchByClaimNo(){
        let claim = this.state.search_claim
        try {
            Wait.show('Searching...')
            MobileReadService.searchByClaimNo(MobilePopup.id(),claim).then(r => {
                Wait.dismiss()
                try {
                    console.log(r.data)
                    this.setState({
                        parcels:r.data.results
                    })
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    loadLocality() {
        Wait.show("Loading Localities...")
        MobileReadService.mobileLayers(MobilePopup.id()).then(d => {
            Wait.dismiss()
            try {
                this.setState({
                    layers_list:d.data.results
                })


                UgSpatial.clear()

                let ly_group = new LF.FeatureGroup();
                let ly_features = new LF.FeatureGroup();
                let sat = Basemap.googleSat(),
                    hybrid = Basemap.googleHybrid(),
                    blank = LF.tileLayer('')

                let map = UgSpatial.map==null?LF.map('map', {
                    layers: [blank, ly_group,ly_features],
                    attributionControl: false,
                    fullscreenControl: true,
                    maxZoom:10000
                }).setView([-6.085936, 35.711995], 4):UgSpatial.map


                let baseLayers = {
                    "<span style='color: gray'>Blank</span>": blank,
                    "Satellite": sat,
                    "Hybrid": hybrid
                }






                let filter = 'project_id in ('+MobilePopup.id()+')'
                let layername = 'mob_layers'
                let self = this


                API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                    try {
                        //console.log(r.data.features)
                        r.data.features.forEach(p=>{
                            LF.geoJSON(p,{
                                onEachFeature:(ft,ly)=>{
                                    let label = LF.marker(ly.getBounds().getCenter(),{
                                        icon:LF.divIcon({
                                            className:'bindTooltipBig',
                                            html:ft.properties.label+' - '+ft.id.split('.')[1],
                                            iconSize:[100,40]
                                        })
                                    }).addTo(p.properties.boundary?ly_group:ly_features)
                                    //label.options.pmIgnore = false;
                                },
                                color: p.properties.boundary?'#000':
                                    p.properties.allowed?'#080':'#f00',weight:p.properties.boundary?4:3,zIndex:1, fillOpacity: p.properties.allowed?0:0.2,
                                //fillColor:p.properties.allowed?'#000':'#f00'
                            }).addTo(p.properties.boundary?ly_group:ly_features);
                        })

                        let myLayer = LF.geoJSON(r.data.features)

                        try{ map.fitBounds(myLayer.getBounds())}catch (e) {}
                    }catch (e) {
                        console.log(e)
                    }
                })

                layername = 'mob_parcel'
                let selected

                API.ax.get(API.geo_ilmis+'/ows?cql_filter='+filter+'&service=WFS&version=1.0.0&request=GetFeature&typeName='+layername+'&outputFormat=application%2Fjson').then(r=>{
                    try {
                        //console.log(r.data.features)
                        r.data.features.forEach(p=>{
                            LF.geoJSON(p,{
                                color: 'transparent',weight:3,zIndex:2, fillOpacity: 0
                            }).on('dblclick',e=>{
                                console.log(e)
                                this.popDataAt(p.properties)
                            }).on('click', function (e) {
                                if (selected) {e.target.resetStyle(selected)}
                                selected = e.layer
                                selected.bringToFront()
                                self.setState({description_title:p.properties.claim_no})
                                selected.setStyle({color: '#000', weight:4})
                                map.fitBounds(selected.getBounds())
                                //map.setView(selected.getBounds().getCenter());
                            }).addTo(p.properties.boundary?ly_group:ly_features);


                        })
                    }catch (e) {
                        console.log(e)
                    }
                })

                let ly_vb = UgSpatial.getLayerByName('mob_parcel','project_id in ('+MobilePopup.id()+') and stage in ('+this.state.current_tab+')').addTo(map)
                ly_vb.bringToFront()


                let overlays = {
                    'Project Boundaries':ly_group,
                    'Project Features':ly_features,
                    'Parcels':ly_vb
                }


                UgSpatial.layers = LF.control.layers(baseLayers,overlays, {position: 'bottomleft'}).addTo(map)

            }catch (e) {
                console.log(e)
            }
        })


    }

    downloadData() {
        let layer_id = this.state.layer_id.replace('mob_layers.', '')
        //let stage = this.state.stage
        let doc_id = this.state.doc_id

        if (layer_id < 0 || layer_id === undefined) {
            alert('No Project Layer')
            return
        }

        if (doc_id < 1 || doc_id === undefined) {
            alert('Select Document Type')
        }
    }

    componentDidMount() {
        this.loadLocality()
        this.loadParcels()

    }

    popDataAt(d){
        if (d.stage===0){
            PopDetail.show("Warning",<Message faIcon={'harzad'} message={'No GIS Approved'}/>,30)
            return
        }
        PopDetail.show(d.claim_no,<PopParcel parcel={d}/>)
    }

    popFullTable(tb_id='bottom_table'){
        let btn = document.getElementById('btn_enl')

        if (btn.innerHTML!=='Show Map'){
            document.getElementById(tb_id).style.position = 'absolute'
            document.getElementById(tb_id).style.zIndex = 10000000
            document.getElementById(tb_id).style.left = '-1vw'
            document.getElementById(tb_id).style.right = 0
            document.getElementById(tb_id).style.bottom = 0
            document.getElementById(tb_id).style.top = 0
            document.getElementById(tb_id).style.height = '100vh'
            btn.innerHTML='Show Map'
        }else{
            document.getElementById(tb_id).style.position = 'absolute'
            document.getElementById(tb_id).style.zIndex = 10000000
            document.getElementById(tb_id).style.left = '-1vw'
            document.getElementById(tb_id).style.right = 0
            document.getElementById(tb_id).style.bottom = 0
            document.getElementById(tb_id).style.top = '60vh'
            document.getElementById(tb_id).style.height = '34vh'
            btn.innerHTML='Hide Map'
        }

    }

    toggleCheck(i){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            return;
        }
        doc.src=uncheck;
    }

    loadDataAt(psn){
        this.setState({
            page:this.state.page+psn
        })
        this.loadParcels()
    }

    loadParcels(tab=this.state.current_tab){
        try {
            Wait.show("Loading Data...")
            MobileReadService.readReportSummary(MobilePopup.id()).then(r => {
                try {
                    this.setState({
                        tabs:r.data.results,
                        current_tab:tab
                    })
                    MobileReadService.readReportTab(MobilePopup.id(),tab,API.no_of_records,this.state.page).then(d=>{
                        Wait.dismiss()
                        try{
                            console.log(d.data)
                            this.setState({
                                parcels:d.data.results,
                                total_pages:d.data.total_pages
                            })
                        }catch (e) {
                            console.log(e)
                        }
                    })
                }catch (e) {
                    console.log(e)
                }
            })


        }catch (e) {
            console.log(e)
        }
    }


    render() {
        return(<div >

            <div className={'row'} style={{marginBottom:'4px'}}>
                <div className={'col col-sm-3'} style={{height:'60vh',paddingRight:'4px',paddingLeft:0}}>
                    <div style={{background:'#fff',padding:'24px',height:'100%',border:'0.5px solid #efefef'}}>
                        <div style={{height:'40px',lineHeight:'40px'}}>
                            <b>{this.state.description_title}</b>
                        </div>

                        <div style={{width:'100%', marginTop:'8px'}}>


                        </div>

                    </div>
                </div>

                <div className={'col col-sm-9'} style={{height:'60vh',paddingLeft:'4px'}}>
                    <div style={{background:'#fff',height:'100%',padding:'24px',border:'0.5px solid #efefef'}}>

                        <div className={'row'} style={{height:'40px'}}>
                            <b>Data at Stage {this.state.current_tab}</b>
                        </div>

                        <div className={'row'} style={{height:'97%'}}>
                            <div className={'col col-sm-12'} style={{background:'#efefef'}} id={'ramani'}/>
                        </div>

                    </div>

                </div>

            </div>
            <div className={'row'}>

                <div className={'col col-sm-12'} style={{background:'#fff',padding:0,height:'34vh',overflow:'auto'}} id={'bottom_table'}>

                    <div className={'row'} style={{paddingLeft:'16px',paddingTop:'16px',paddingBottom:'16px'}}>
                        <div className={'col col-sm-3'}>

                            <b style={{fontSize:'12px'}}>{this.state.parcels!==undefined?this.state.parcels.length:0} Parcel/s</b>
                            <input placeholder={'Search Here'}  style={{width:'50%',marginLeft:'16px'}} onChange={this.handleChange} name={'search_claim'}/>

                        </div>
                        <div className={'col col-sm-1'}>
                            <button onClick={this.searchByClaimNo} className={'default'}>Search</button>
                        </div>
                        <div className={'row col col-sm-6'} style={{visibility:this.state.is_map_visible}}>
                            <div className={'col col-sm-3'}>
                                <select name={'layer_id'} style={{width:'100%'}} onChange={(e)=>this.loadDataInLayer(e)} id={'sp_layer'} >
                                    <option value={0}>- Choose Project Layer -</option>
                                    {this.state.layers_list.map((d,i)=>{
                                        return(<option key={i} value={d.id}>{d.label} - {d.name}</option>)
                                    })}
                                </select>
                            </div>
                            <div className={'col col-sm-3'}>
                                <select style={{width:'100%'}} name={'stage'} onChange={this.handleChangeDataType}>
                                    <option value={0}>- Choose Stage -</option>
                                    {this.state.tabs.map((d,i)=>{
                                        return(<option key={i} value={d.tabId}>{d.name} - {d.count}</option>)
                                    })}
                                </select>
                            </div>
                            <div className={'col col-sm-3'}>
                                <select style={{width:'100%'}} name={'doc_id'} onChange={this.handleChange}>
                                    <option value={0}>- Choose Action -</option>
                                    {this.state.actions.map((d,i)=>{
                                        return(<option key={i} value={d.id}>{d.name}</option>)
                                    })}
                                </select>
                            </div>
                            <div className={'col col-sm-3'}>
                                <button style={{width:'100px'}} className={'refresh'} onClick={()=>this.downloadData()}>Submit</button>
                            </div>
                        </div>
                        <div className={'col col-sm-2'}>
                            <button id={'btn_enl'} style={{float:'right',marginRight:'24px'}} className={'default'} onClick={()=>this.popFullTable()}>Hide Map</button>
                        </div>
                    </div>

                    {/*<div className={'row'}>
                        <div className={'col col-sm-10'} style={{paddingBottom:'8px',position:'stick'}}>
                            {this.state.tabs.map((d,i)=>{
                                return(<div key={i} className={this.state.current_tab===i?'tab tab-active':'tab'} onClick={()=>this.loadParcels(i)}>
                                    <b>{d.name}</b>
                                    <span style={{float:'right'}}>{d.count}</span>
                                </div>)
                            })}
                        </div>
                    </div>*/}

                    <div className={'col col-sm-12'}>

                        <div style={{height:'60vh',overflow:'auto'}}>
                            <table className={'tb'} style={{width:'100%', marginTop:'8px',border:'1px solid #ddd'}}>
                                <thead>
                                <tr>
                                    <th/>
                                    <th>CLAIM NO</th>
                                    <th>CLAIM DATE</th>
                                    <th>OWNERS</th>
                                    <th>STAGE</th>
                                    <th>STREET/HAMLET</th>
                                    <th>WARD/VILLAGE</th>
                                    <th>OWNERSHIP</th>
                                    <th>USE</th>
                                    <th>SQM</th>
                                    <th style={{display:this.state.current_tab>2?'table-cell':'none'}}>Initial</th>
                                    <th style={{display:this.state.current_tab>2?'table-cell':'none'}}>Land Rent</th>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.parcels.map((d,i)=>{
                                    return(d.stage>1?<tr key={i} title={d.comment} id={'tr_'+d.id}>
                                            <td style={{textAlign:'center'}}>
                                                <img id={'chk_'+(i+1)} alt={'chk_'+(i+1)} src={uncheck} onClick={()=>this.toggleCheck(i+1)}/>
                                            </td>
                                            <td style={{color:'#008b8b'}} onClick={()=>this.popDataAt(d)}><b>{d.claim_no}</b></td>
                                            <td>{d.created_at}</td>
                                                <td><b>{d.owners}</b></td>
                                                <td><b>{d.stage}</b></td>
                                                <td><b>{d.street}</b></td>
                                                <td><b>{d.subward} - {d.ward} - {d.layer}</b></td>
                                            <td><b>{d.occupancy}</b></td>
                                            <td><b>{d.use}</b></td>
                                            <td>{d.ukubwa}</td>
                                            <td style={{display:this.state.current_tab>2?'table-cell':'none'}}>
                                                {/*{d.init_amount} - <b>{d.init_cno}</b>*/}
                                            </td>
                                            <td style={{display:this.state.current_tab>2?'table-cell':'none'}}>
                                                {/*{d.rent_amount} - <b>{d.rent_cno}</b>*/}
                                            </td>
                                        </tr>:
                                            <tr key={i} title={d.comment} id={'tr_'+d.id} style={{background:d.stage===0?'#fcc':'#f00'}}>
                                                <td style={{textAlign:'center'}}>
                                                    <img id={'chk_'+(i+1)} alt={'chk_'+(i+1)} src={uncheck} onClick={()=>this.toggleCheck(i+1)}/>
                                                </td>
                                                <td style={{color:d.stage===0?'#000':'#fff'}} onClick={()=>this.popDataAt(d)}><b>{d.claim_no}</b></td>
                                                <td>{d.created_at}</td>
                                                <td><b>{d.owners}</b></td>
                                                <td><b>{this.state.stages[d.stage]}</b></td>
                                                <td><b>{d.occupancy}</b></td>
                                                <td><b>{d.use}</b></td>
                                                <td>{d.ukubwa}</td>
                                                {/*<td style={{display:this.state.current_tab>2?'table-cell':'none'}}>
                                                    {d.init_amount} - <b>{d.init_cno}</b>
                                                </td>
                                                <td style={{display:this.state.current_tab>2?'table-cell':'none'}}>
                                                    {d.rent_amount} - <b>{d.rent_cno}</b>
                                                </td>*/}
                                            </tr>

                                    )
                                })}
                                </tbody>
                            </table>
                        </div>

                        <div className={'row'} style={{height:'5vh', paddingTop:'8px',borderTop:'1px solid #ccc'}}>
                            <div className={'col col-sm-2'}>

                            </div>
                            <div className={'col col-sm-6'} style={{textAlign:'center'}}>
                                <div style={{textAlign:'center'}}>
                                    <button className={'default'} style={{visibility:this.state.page>0?'visible':'hidden'}} onClick={()=>this.loadDataAt(-1)}>Back</button>
                                    <input style={{width:'100px',marginLeft:'16px',textAlign:'center',marginRight:'16px'}} readOnly={true}
                                           value={(this.state.page+1)+'/'+this.state.total_pages}/>
                                    <button className={'default'} style={{visibility:this.state.page!==this.state.total_pages?'visible':'hidden'}} onClick={()=>this.loadDataAt(1)}>Next</button>
                                </div>
                            </div>
                            <div className={'col col-sm-4'}>

                            </div>
                        </div>

                    </div>
                </div>


            </div>



        </div>)
    }
}