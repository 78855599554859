import * as React from "react";
import MobileCreateService from "../../../../services/mobile/mobile_create";
import MobilePopup from "../mobile_popup";
import Wait from "../../wait";
import PopDetail from "../../popdetail";
import MobileSummaryPane from "../screens/mobile_summary";
export default class PaneUploadExcel extends React.Component{

    constructor(props) {
        super(props);
        this.handleChangeFile = this.handleChangeFile.bind(this)
        this.upload = this.upload.bind(this)
    }

    handleChangeFile=e=>{this.setState({ [e.target.name]:e.target.files[0] })}

    upload(){
        if (this.state.csv===undefined){
            return
        }

        try {
            const formData = new FormData()
            formData.append('csv',this.state.csv)
            formData.append('project',MobilePopup.id())

            Wait.show('Uploading...')
            MobileCreateService.uploadPaymentExcel(formData).then(r => {
                try {
                    Wait.dismiss()
                    if (r.data.status===0){
                        alert(r.data.message)
                        return
                    }
                    PopDetail.dismiss()
                    MobileSummaryPane.loadprojectList()
                }catch (e) {
                    console.log(e)
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    render() {
        return(<div style={{textAlign:'center'}} className={'col col-sm-12'}>
            <br/>
            <input type={'file'} style={{width:'100%'}} accept={".xls"}
                   name={'csv'} onChange={this.handleChangeFile}/><br/><br/>
            <button onClick={this.upload}>Upload</button>
        </div>)
    }
}