import * as React from "react";
import Moment from 'moment';
import check from "../../../resource/img/checkbox/checkbox_checked_16.png"
import uncheck from"../../../resource/img/checkbox/checkbox_unchecked_16.png"
import TxReadService from "../../services/transaction/transaction_read";
import TransactionPopup from "../popups/transacrions/transaction_popup";
import ProjectPane from "../pane/mobile/project_pane";
import PopDetail from "../popups/popdetail";
import Message from "../popups/transacrions/screen/gro/person/message";
import wait from "../popups/wait";
import TxCreateService from "../../services/transaction/transaction_create";
import TaskHistory from "../popups/transacrions/pops/pane_task_history";
import PaneAdvanceTransactionSearch from "../popups/transacrions/pops/pane_advance_transaction_search";

export default class TransactionTable extends React.Component{

    static self;
    constructor(props) {
        super(props);
        let today = new Date(),

            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        this.state = {
            todayDate: today,
            currentDate: date,
            transactions:[],
            allTransactions:[],
            search:false,
            page:0,
            total_pages:1,
            searchValue:'',
            searchCriteria:'transactionNumber'
        }

        TransactionTable.self = this
        TransactionTable.loadData = TransactionTable.loadData.bind(this)
        TransactionTable.handleSearch = TransactionTable.handleSearch.bind(this)
        TransactionTable.handleSearchCriteria = TransactionTable.handleSearchCriteria.bind(this)
        TransactionTable.handleSearchButton= TransactionTable.handleSearchButton.bind(this)
        this.loadDataAt = this.loadDataAt.bind(this)
    }

    componentDidMount() {
        TransactionTable.loadData()
    }

    openTransactionAt(d,psn){
        if(d.assignee!==sessionStorage.getItem('username')) {
            PopDetail.show("Warning",<Message faIcon={'harzad'} message={'YOU ARE NOT AUTHORIZED'}/>,30)
            wait.dismiss()
        } else {

            TransactionPopup.show({
                txDefId: d.definitionId,
                name: d.transactionName,
                isNew: false,
                taskId: d.taskId,
                taskName: d.taskName,
                txNo: d.transactionNo,
                txId: d.transactionId,
                taskKey: d.taskKey
            }, <ProjectPane
                title={d.title}
                data={d}
                key={new Date().getMilliseconds()}/>)
        }
    }
    static handleSearch(e) {

    TransactionTable.self.setState({searchValue: e.target.value})
    console.log("Search", TransactionTable.self.state.searchValue)
    //  TransactionTable.self.setState({search: true})
    console.log("Search", TransactionTable.self.state.search)


       /* if (e.charCode === 13) {
            console.log("Search ob key up", TransactionTable.self.state.search)
            TransactionTable.loadData()
            TransactionTable.self.setState({searchValue: ''})
            TransactionTable.self.setState({search: false})
        }*/
    }
        static handleSearchCriteria(e){

                TransactionTable.self.setState({searchCriteria:e.target.value})
                console.log("Search Criteria",TransactionTable.self.state.searchCriteria)
                //TransactionTable.self.setState({searchCriteria:true})
                console.log("Search",TransactionTable.self.state.searchCriteria)



    }
    static handleSearchButton(e) {
        setTimeout(()=>{
            TransactionTable.self.setState({search: true})
            console.log("Search Button activated", TransactionTable.self.state.search)
            TransactionTable.loadData()/*
            TransactionTable.self.setState({searchValue: ''})*/
            TransactionTable.self.setState({search: false})
            /*TransactionTable.self.setState({searchCriteria:''})*/
        },1000)


    }

    loadDataAt(psn){
        this.setState({
            page:(this.state.page+psn)
        })
        TransactionTable.loadData()
    }

    static loadData(){

        try {
            TransactionPopup.setState('transactionIds',[])
            document.getElementById('tr_loading').style.visibility='visible'
            TransactionTable.self.setState({transactions:[]})
            if(TransactionTable.self.state.search){
                let searchDTO={
                    searchCriteria:TransactionTable.self.state.searchCriteria,
                    searchValue: TransactionTable.self.state.searchValue
                }
                console.log("Search DTO",searchDTO)
                console.log("Search Loading")
                TxCreateService.searchTransactions(searchDTO).then(d=>{
                    document.getElementById('tr_loading').style.visibility='hidden'
                    try {

                        TransactionTable.self.setState({
                            allTransactions:d.data.results
                        })

                        let searchby=this.props.children
                        console.log(searchby)
                        if(searchby==='all'){
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions
                            })
                        }
                        if(searchby==='active'){

                            TransactionTable.self.setState({
                                transactions:d.data.results
                            })
                        }
                        if(searchby==="tome"){
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    return s.assignee===sessionStorage.getItem('username')
                                })
                            })
                        }
                        if(searchby==='subToday'){

                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    //return console.log(s.submitted.trim())
                                    console.log(s.submitted ===Moment(new Date).format('YYYY-MM-DD'))
                                    console.log('Recieved',s.submitted)
                                    console.log('compareTo',Moment().format('YYYY-MM-DD'))
                                    return  s.submitted ===Moment(this.state.todayDate).format('YYYY-MM-DD')
                                })
                            })
                        }
                        if(searchby==='subWeek'){
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    //return console.log(s.submitted.trim())

                                    let diff = this.state.todayDate.getDate() - this.state.todayDate.getDay() + (this.state.todayDate.getDay() === 0 ? -6 : 1);
                                    let lastWeek=new Date();
                                    lastWeek.setDate(diff)

                                    console.log(s.submitted >= Moment(lastWeek).format('YYYY-MM-DD'))
                                    console.log('Recieved',s.submitted)
                                    console.log('compareTo',Moment(lastWeek).format('YYYY-MM-DD'))
                                    return  s.submitted >= Moment(lastWeek).format('YYYY-MM-DD')
                                })
                            })
                        }
                        if(searchby==='registered'){
                            // console.log(this.state.allTransactions)
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    console.log(s.status==='Registered')
                                    console.log(s.status)

                                    return s.status==='Registered'
                                })
                            })
                        }
                        if(searchby==='rejected'){
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    return s.status==='Rejected'
                                })
                            })

                        }
                        if(searchby==='postponed'){

                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    return s.status==='Postponed'
                                })
                            })
                        }

                    }catch (e) {
                        console.log(e)
                    }
                })
            }else {
                let searchby=this.props.children
                console.log(searchby)

                TxReadService.allTransactions(searchby,TransactionTable.self.state.page).then(d=>{
                    document.getElementById('tr_loading').style.visibility='hidden'
                    try {

                        TransactionTable.self.setState({
                            total_pages:d.data.total_pages,
                            allTransactions:d.data.results,
                            transactions:d.data.results
                        })
                        /*if(searchby==='all'){
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions
                            })
                        }
                        if(searchby==='active'){
                            TransactionTable.self.setState({
                                transactions:d.data.results
                            })
                        }
                        if(searchby==="tome"){
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    return s.assignee===sessionStorage.getItem('username')
                                })
                            })
                        }*/

                        if(searchby==='subToday'){

                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    //return console.log(s.submitted.trim())
                                    console.log(s.submitted ===Moment("2020-11-07").format('YYYY-MM-DD'))
                                    console.log('Recieved',s.submitted)
                                    console.log('compareTo',Moment("2020-11-07").format('YYYY-MM-DD'))
                                    return  s.submitted ===Moment(this.state.todayDate).format('YYYY-MM-DD')
                                })
                            })
                        }
                        if(searchby==='subWeek'){
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    //return console.log(s.submitted.trim())

                                    let diff = this.state.todayDate.getDate() - this.state.todayDate.getDay() + (this.state.todayDate.getDay() === 0 ? -6 : 1);
                                    let lastWeek=new Date();
                                    lastWeek.setDate(diff)

                                    console.log(s.submitted >= Moment(lastWeek).format('YYYY-MM-DD'))
                                    console.log('Recieved',s.submitted)
                                    console.log('compareTo',Moment(lastWeek).format('YYYY-MM-DD'))
                                    return  s.submitted >= Moment(lastWeek).format('YYYY-MM-DD')
                                })
                            })
                        }
                        if(searchby==='registered'){
                            // console.log(this.state.allTransactions)
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    console.log(s.status==='Registered')
                                    console.log(s.status)

                                    return s.status==='Registered'
                                })
                            })
                        }
                        if(searchby==='rejected'){
                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    return s.status==='Rejected'
                                })
                            })

                        }
                        if(searchby==='postponed'){

                            TransactionTable.self.setState({
                                transactions:this.state.allTransactions.filter(s=>{
                                    return s.status==='Postponed'
                                })
                            })
                        }

                    }catch (e) {
                        console.log(e)
                    }
                })
            }


        }catch (e) {
            console.log(e)
        }
    }

    toggleCheck(i,id){
        let doc = document.getElementById('chk_'+i);
        if (doc.src===uncheck){
            doc.src=check;
            console.log("TX ID",id)
            console.log("ids",)
            let selectedTx=TransactionPopup.state().transactionIds
            console.log("Selected",selectedTx)
           selectedTx.push(id)
           TransactionPopup.setState('transactionIds',selectedTx)

         //   console.log("ACTIONS",   PaneAddAction.)
            return;
        }
        doc.src=uncheck;
    }

    render() {
        return(<div style={{width:'100%',height:'100%',overflow:'hidden'}}>
            <div style={{height:'6vh'}} className={'row'}>
                <div className={'col col-sm-3'}>
                    <div className={'col col-sm-12'} style={{lineHeight:'6.5vh',color:'#484848',fontFamily:'oxygen-bold'}}>
                        Active Transactions
                    </div>
                </div>
                <div className={'col col-sm-9'} style={{marginTop:'1vh'}}>
                    <button onClick={()=>window.location.reload()} className={'default'} style={{float:'right',marginRight:'10px'}}>Close</button>
                    <button onClick={()=>TransactionTable.loadData()} style={{float:'right',marginRight:'16px'}}>Refresh</button>
                    <button onClick={TransactionTable.handleSearchButton} style={{float:'right',marginRight:'16px'}}>Search</button>
                    <select name={'searchCriteria'} defaultValue={TransactionTable.self.searchCriteria} style={{width:'30%',float:'right'}} onChange={TransactionTable.handleSearchCriteria}>
                        <option value={''}/>
                        <option value={'transactionNumber'} > Transaction Number</option>
                        <option value={'transactionName'} > Transaction Name</option>
                        <option value={'currentTask'}> Task Name</option>
                        <option value={'nationalId'}> NIDA ID</option>
                        <option value={'lotNumber'} > Lot Number</option>
                        <option value={'blockNumber'}> Block Number</option>
                        <option value={'localityName'}> Location</option>
                        <option value={'regPlanNumber'}> Reg Plan Number</option>
                        <option value={'username'}> Officer Name</option>
                    </select>

                    <input name={'searchValue'} style={{width:'30%',float:'right',marginRight:'5px'}}  onChange={TransactionTable.handleSearch} placeholder={'Enter data to search.....'}/>
                    {/*<button onClick={()=>PopDetail.show("ADVANCE SEARCH",<PaneAdvanceTransactionSearch/>)}>Advance Search</button>

*/}


                </div>
            </div>

            <div className={'row'} style={{width:'99.9%',margin:'4px',border:'1px solid #dddd',height:'82vh',borderTop:'1px solid #efefef',overflow:'auto'}}>
                <table className={'tb'} style={{width:'100%'}}>
                    <thead>
                    <tr>
                        <th>
                            <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                        </th>
                        <th style={{position:'sticky', left:0,zIndex:2}}>TRANSACTION NO</th>
                        <th>LD NUMBER</th>
                        <th>PROPERTY/TITLE</th>
                        <th>SUBMITTED</th>
                        <th>APPLICANT</th>
                        <th>STATUS</th>
                        <th>TRANSACTION</th>
                        <th>TASK</th>
                        <th>PAY-STATUS</th>
                        <th>TASK CREATED</th>
                        <th>ASSIGNEE</th>
                        <th>ROLE</th>
                        <th>ASSIGNOR</th>
                        <th>TASK UPDATED</th>
                        <th>SPATIAL UNIT</th>
                        <th>PARTY</th>
                        <th>
                            <img id={'chk_0'} alt={'chk_0'} src={uncheck} onClick={()=>this.toggleCheck(0)}/>
                        </th>
                        <th>TASK HISTORY</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.transactions.map((d,i)=>{
                            //let spatial_psn = d.transaction==='Survey Request'?2:4
                            return(<tr key={i}>
                                <td style={{textAlign:'center', backgroundColor:'white'}}>
                                    <img id={'chk_'+(i+1)} alt={'chk_'+(i+1)} src={uncheck} onClick={()=>this.toggleCheck(i+1,d.transactionId)}/>
                                </td>
                                <td onClick={()=>this.openTransactionAt(d,0)} style={{color:'#DF4A36',position:'sticky', backgroundColor:'white', left:'0',zIndex:1}}>{d.transactionNo}</td>
                                <td onClick={()=>this.openTransactionAt(d,1)} style={{color:'#DF4A36'}}>{d.ldNumber}</td>
                                <td onClick={()=>this.openTransactionAt(d,0)} style={{color:'#DF4A36'}}>{d.property}</td>
                                <td>{d.submitted}</td>
                                <td>{d.applicant}</td>
                                <td>{d.status}</td>
                                <td>{d.transactionName}</td>
                                <td>{d.taskName}</td>
                                <td  style={{color:'#DF4A36'}}>{d.paymentStatus}</td>
                                <td>{d.taskCreated}</td>
                                <td>{d.assignee}</td>
                                <td>{d.role}</td>
                                <td>{d.submitter}</td>
                                <td>{d.taskUpdated}</td>
                                <td  style={{color:'#DF4A36'}}>{d.spatial}</td>
                                <td>{d.party}</td>
                                <td style={{textAlign:'center', backgroundColor:'white'}}>
                                <img id={'chk_'+(i+1)} alt={'chk_'+(i+1)} src={uncheck} onClick={()=>this.toggleCheck(i+1,d.transactionId)}/>
                            </td>
                                <td onClick={()=>PopDetail.show("TASK HISTORY FOR "+d.transactionName+"  TRANSACTION NO.:  "+d.transactionNo,<TaskHistory transaction={d}/>)} style={{color:'#f43',cursor:'pointer'}}><a>History</a></td>
                            </tr>)
                        })
                    }
                    <tr id={'tr_loading'}>
                        <td colSpan={15} style={{fontSize:'18px',padding:'24px'}}>Loading.....</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={'row'} style={{height:'5vh'}}>
                <div className={'col col-sm-2'}>

                </div>
                <div className={'col col-sm-6'} style={{textAlign:'center'}}>
                    <div style={{textAlign:'center'}}>


                        <button className={'default'} style={{visibility:this.state.page>0?'visible':'hidden'}} onClick={()=>this.loadDataAt(-1)}>Back</button>
                        <span>Total Transactions {this.state.transactions.length}</span><input style={{width:'100px',marginLeft:'16px',textAlign:'center',marginRight:'16px'}} readOnly={true}
                               value={(this.state.page+1)+'/'+(this.state.total_pages+1)}/>
                        <button className={'default'} style={{visibility:this.state.page!==this.state.total_pages?'visible':'hidden'}} onClick={()=>this.loadDataAt(1)}>Next</button>

                    </div>
                </div>
                <div className={'col col-sm-4'}>

                </div>
            </div>
        </div>)
    }
}
