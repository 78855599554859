import React,{useEffect,useState} from 'react';
import {useForm} from "react-hook-form";
import API from "../../../../../services/API";
import PopDetail from "../../../popdetail";

function EditPhase({planPhaseId}) {

    const{register,handleSubmit} = useForm()
    const [phase,setPhase] = useState([])
    const details = data => {
        let dataToSend = {
            ...data,
            planPhaseId: planPhaseId
        }
        API.ax.post("/rt/create/edit-phase",dataToSend).then(d => {
            console.log("phase Edited" + JSON.stringify(d.data))
        }).catch(e => {
            console.log(e)
        })
       PopDetail.dismiss()
    }
    useEffect(() => {
        API.ax.get(`/rt/phase/${planPhaseId}`).then(d => {
            console.log("phasessssssssssssssssssssssssss" + JSON.stringify(d.data) )
            setPhase(d.data)
        }).catch(e => {
            console.log(e)
        })
    },[])


    return (
        <div>
            <form onSubmit={handleSubmit(details)}>
                <label>*Phase Number/Name<br/>
                    <input type={"text"} name={"phaseNumberName"} defaultValue={phase.phaseNameNumber} style={{width: "400px"}} ref={register({required: true})} />
                </label>

                <br/>
                <label>Total Number of Unit<br/>
                    <input type={"number"} name={"totalNumberOfUnit"} defaultValue={phase.totalUnits} style={{width: "400px"}} ref={register({required: true})}/>
                </label>

                <br/>
                <label>Phase Value, TSh<br/>
                    <input type={"currency"} name={"phaseValue"} defaultValue={phase.phaseValue} style={{width: "400px"}} ref={register({required: true})}/>
                </label>
                <br/>
                <button type={'submit'} style={{width:'90px',paddingBottom:'.3rem'}}>Save</button>
                {/*<input  style={{width:'85px',paddingBottom:'.2.5rem'}}>Cancel</input>*/}
            </form>
        </div>
    );
}

export default EditPhase;